import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CoolButton from 'app/components/button/Button';
import InputField from 'app/components/input-field/InputField';
import { createPartnerExternal, reset, updatePartnerExternal, getPartnerExternal } from 'app/entities/keiken-pay/keiken-pay-partner-external-reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { PartnerExternalDocumentType, IKeikenPayPartnerExternal, PartnerExternalStatus } from 'app/shared/model/KeikenPay/KeikenPayPartnerExternal';
import { SocialMediaType } from 'app/shared/model/KeikenPay/social-media-account';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { IRootState } from 'app/shared/reducers';
import _, { forEach, isEmpty, isNull } from 'lodash';
import * as React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { fetchActivitySubCategories, fetchListActivitySubCategories } from 'app/entities/activity-sub-category/activity-sub-category-reducer';
import { IActivitySubCategory } from 'app/shared/model/activity-sub-category';
import { Row, Button, Col } from 'reactstrap';
import { IActivityCategory } from 'app/shared/model/activity-category';
import { fetchActivityCategories } from 'app/entities/activity-category/activity-category-reducer';
import { IAddress } from 'app/shared/model/address.model';
import { fetchAddresses } from 'app/entities/address/address-reducer';
import classnames from 'classnames';
import { getEconomicActivity } from 'app/entities/economic-activity/economic-activity-reducer';
import { EconomicActivity } from 'app/shared/model/KeikenPay/EconomicActivity';
import StringUtils from 'app/util/StringUtils';

export interface IKeikenPayPartnerExternalManageProps
  extends StateProps,
  DispatchProps,
  RouteComponentProps<{ id?: string }, any, { partnerExternal: any; isEdit: any } | any> { }

export interface IKeikenPayPartnerExternalState {
  partnerExternal?: IKeikenPayPartnerExternal;
  isErrors?: { [key: string]: boolean | undefined };
  fullAddress?: string;
  isEdit?: boolean;
  isValids?: { [key: string]: boolean | undefined };
  cpfMask?: string;
  cnpjMask?: string;
  loading: boolean;
}

export class KeikenPayPartnerExternalManage extends React.Component<IKeikenPayPartnerExternalManageProps, IKeikenPayPartnerExternalState> {
  constructor(props) {
    super(props);
    this.state = {
      partnerExternal: {
        document: undefined,
        documentType: PartnerExternalDocumentType.CNPJ,
        phone: undefined,
        partnerExternalStatus: PartnerExternalStatus.DISABLED,
        orderPriority: 5,
        address: {
          street: undefined,
          number: undefined,
          zipcode: undefined,
          district: undefined,
          complement: undefined
        }
      },
      loading: false,
      cpfMask: '999.999.999-99',
      cnpjMask: '99.999.999/9999-99',
      isErrors: {
        document: undefined,
        documentType: undefined
      },
      isValids: {
        document: undefined,
        documentType: undefined
      },
      fullAddress: '',
      isEdit: true
    };
  }

  private validateForm = (): boolean => {
    const partnerExternal = this.state.partnerExternal;

    let result = true;
    let validActivityCategory = true;
    let errorActivityCategory = false;
    let validActivitySubCategory = true;
    let errorActivitySubCategory = false;
    let validDocument = true;
    let errorDocument = false;
    let validAddress = true;
    let errorAddress = false;
    let validPrincipalActivity = true;
    let errorPrincipalActivity = false;

    if (partnerExternal?.partnerExternalStatus == PartnerExternalStatus.ENABLED) {
      if (partnerExternal?.document === '' || partnerExternal?.document == null) {
        result = false;
        validDocument = false;
        errorDocument = true;
        toast.error('Documento não pode ser vazio');
      }
      if (isEmpty(partnerExternal?.address?.street)) {
        result = false;
        validAddress = false;
        errorAddress = true;
        toast.error('Endereço não pode ser vazio');
      }
      if (partnerExternal.image == null || partnerExternal.coverImage == null) {
        result = false;
        toast.error('Imagem não pode ser vazia ');
      }

      if (partnerExternal.principalActivity === undefined) {
        result = false;
        validPrincipalActivity = false;
        errorPrincipalActivity = true;
        toast.error('Cnae não pode ser vazio');
      }

      if (isEmpty(partnerExternal.activityCategories)) {
        result = false;
        validActivityCategory = false;
        errorActivityCategory = true;
        toast.error('Categoria não pode ser vazia');
      }

      if (isEmpty(partnerExternal.activitySubCategories)) {
        result = false;
        validActivitySubCategory = false;
        errorActivitySubCategory = true;
        toast.error('Sub categoria não pode ser vazia');
      }
    }

    this.setState({
      isErrors: { ...this.state.isErrors, ['document']: errorDocument, ['principalActivity']: errorPrincipalActivity, ['address']: errorAddress, ['activityCategory']: errorActivityCategory, ['subCategory']: errorActivitySubCategory },
      isValids: { ...this.state.isValids, ['document']: validDocument, ['principalActivity']: validPrincipalActivity, ['address']: validAddress, ['activityCategory']: validActivityCategory, ['subCategory']: validActivitySubCategory }
    });

    return result;
  };
  componentDidMount() {
    if (this.props.location.state != null && this.props.location.state.partnerExternal != null) {
      this.setState({
        partnerExternal: this.props.location.state.partnerExternal
      });
    }
    if (this.props.location.state != null && this.state.isEdit != null) {
      this.setState({
        isEdit: this.props.location.state.isEdit
      });
    }
    if (this.props.match.params?.id != null && this.props.match.params.id != 'new') {
      this.props.getPartnerExternal(this.props.match.params.id);
    } else {
      this.setState({
        isEdit: false
      });
    }
  }

  componentWillReceiveProps(newProps: IKeikenPayPartnerExternalManageProps) {
    if (
      newProps.updatePartner == HttpRequestStatus.ERROR ||
      newProps.createPartner == HttpRequestStatus.ERROR
    ) {
      this.setState({ loading: false });
    }
    if (newProps.updatePartner == HttpRequestStatus.SUCCESS) {
      this.props.reset();
      toast.success('Parceiro atualizado com sucesso', {
        onClose: () => {
          this.props.reset();
          this.props.history.goBack();
          this.setState({ loading: false });
        },
        autoClose: 4000
      });
    }
    if (newProps.createPartner == HttpRequestStatus.SUCCESS) {
      this.props.reset();
      toast.success('Parceiro criado com sucesso!', {
        onClose: () => {
          this.props.reset();
          this.props.history.goBack();
          this.setState({ loading: false });
        },
        autoClose: 4000
      });
    }
    if (newProps.partnerExternal != null) {
      this.setState({ partnerExternal: newProps.partnerExternal });
      this.props.reset();
    }
  }

  private handleCreate = () => {
    const partnerExternal = this.state.partnerExternal;
    partnerExternal.document = StringUtils.unmaskText(partnerExternal.document);
    this.props.createPartnerExternal(partnerExternal);
  };

  private handleUpdate = () => {
    const partnerExternal = this.state.partnerExternal;
    partnerExternal.document = StringUtils.unmaskText(partnerExternal.document);
    this.props.updatePartnerExternal(partnerExternal);
  };

  private handleSave = () => {
    if (this.state.loading == true || !this.validateForm()) {
      return;
    }
    this.setState({ loading: true }, () => {
      if (this.state.isEdit == false) {
        this.handleCreate();
      } else {
        this.handleUpdate();
      }
    });
  };
  private handleChangeType = event => {
    const partnerExternal = this.state.partnerExternal;
    let newStatus = partnerExternal.partnerExternalStatus;
    newStatus = event?.target.value;
    this.setState(prevState => ({ partnerExternal: { ...prevState.partnerExternal, partnerExternalStatus: newStatus } }));
  }

  private handleChangeDocumentType = event => {
    const partnerExternal = this.state.partnerExternal;
    let newDocument = partnerExternal.documentType;
    newDocument = event?.target?.value;
    this.setState(prevState => ({ partnerExternal: { ...prevState.partnerExternal, documentType: newDocument } }));
  }

  private handleTextChanged = (text: string, event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      partnerExternal: { ...this.state.partnerExternal, [text]: event.target.value },
      isErrors: { ...this.state.isErrors, [text]: false },
      isValids: { ...this.state.isValids, [text]: false }
    });
  }

  private handleSocialMedia = (type: SocialMediaType, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let socialMediaAccounts = this.state.partnerExternal.socialMediaAccounts ?? [];
    // add
    if (!socialMediaAccounts.some(it => it.type == type)) {
      socialMediaAccounts.push({
        type,
        url: value
      });
    } else {
      // update
      socialMediaAccounts = socialMediaAccounts.map(it => {
        if (it.type == type) {
          return {
            type,
            url: value
          };
        }
        return it;
      });
    }
    this.setState({
      partnerExternal: {
        ...this.state.partnerExternal,
        socialMediaAccounts
      }
    });
  }

  private handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const newImage = this.state.partnerExternal.image || {};
      const contentType = selectedFile.type;
      newImage.contentType = contentType;
      newImage.fileName = selectedFile.name;
      newImage.imageUrl = undefined;
      newImage.file = ev.target.result.split(',')[1];
      this.setState(prevState => ({ partnerExternal: { ...prevState.partnerExternal, image: newImage } }));
    };
    reader.readAsDataURL(selectedFile);
  };

  private handleCoverImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const newImage = this.state.partnerExternal.coverImage || {};
      const contentType = selectedFile.type;
      newImage.contentType = contentType;
      newImage.fileName = selectedFile.name;
      newImage.imageUrl = undefined;
      newImage.file = ev.target.result.split(',')[1];
      this.setState(prevState => ({ partnerExternal: { ...prevState.partnerExternal, coverImage: newImage } }));
    };
    reader.readAsDataURL(selectedFile);
  };

  mapAddressToString = (item: IAddress) => {
    const firstSeparator = ', ';
    const secondSeparator = ' - ';
    let stringMapped = '';
    stringMapped += item.street ? item.street : '';
    stringMapped += item.number ? firstSeparator + item.number : '';
    stringMapped += item.city ? secondSeparator + item.city.name : '';
    stringMapped = stringMapped.replace(/^\s-\s/, '');
    return stringMapped;
  };

  handleAddressSelected = (item: IAddress) => {
    const fullAddress = this.mapAddressToString(item);

    this.props.hideModal();

    this.setState(prevState => ({
      partnerExternal: { ...prevState.partnerExternal, address: item },
      fullAddress,
      isErrors: { ...this.state.isErrors, ['address']: false },
      isValids: { ...this.state.isValids, ['address']: false }
    }));
  };

  handleOnAddressPressed = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['street'], ['number'], ['district'], ['city'], ['zipcode']],
      title: translate('partner.register.labels.modal.address'),
      waitToPerformRequest: true,
      action: this.props.fetchAddresses,
      placeholderInput: translate('partner.register.placeholders.modal.address'),
      stateAction: 'addresses',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleAddressSelected(item)
    });
  };

  handleEconomicActivitySelected = (item: EconomicActivity) => {
    this.setState(prevState => ({
      partnerExternal: { ...prevState.partnerExternal, principalActivity: item },
      isErrors: { ...this.state.isErrors, ['principalActivity']: false },
      isValids: { ...this.state.isValids, ['principalActivity']: false }
    }));

    this.props.hideModal();
  }
  private handleEconomicActivityList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['text'], ['code']],
      title: 'CNAES',
      waitToPerformRequest: true,
      action: this.props.getEconomicActivity,
      placeholderInput: 'Lista de CNAES',
      stateAction: 'economicActivity',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleEconomicActivitySelected(item)
    });
  };

  private handleSecondaryActivityList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['code'], ['text']],
      title: 'Lista de atividades secundárias',
      action: this.props.getEconomicActivity,
      placeholderInput: 'Lista de atividades secundárias',
      stateAction: 'economicActivity',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleSecondaryActivitySelected(item)
    });
  };

  private handleSecondaryActivitySelected = (item: EconomicActivity) => {
    const partner = this.state.partnerExternal;

    if (partner.secondaryActivities == null) {
      partner.secondaryActivities = Array<EconomicActivity>();
    }

    if (partner.secondaryActivities.filter(it => it === item).length === 0) {
      partner.secondaryActivities.push(item);
      this.setState({
        partnerExternal: { ...this.state.partnerExternal, ...partner }
      });
    }
    this.props.hideModal();
  };

  private deleteSecondaryActivity = (secondaryActivity?: EconomicActivity) => {
    const partner = this.state.partnerExternal;

    partner.secondaryActivities = partner.secondaryActivities
      ? partner.secondaryActivities.filter(it => it.code !== secondaryActivity.code)
      : Array<EconomicActivity>();

    this.setState({
      partnerExternal: { ...this.state.partnerExternal.secondaryActivities, ...partner }
    });
  };

  private handleCategoryList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: 'Categoria',
      action: this.props.fetchActivityCategories,
      placeholderInput: 'Adicionar categoria',
      stateAction: 'activityCategories',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleCategorySelected(item)
    });
  };
  private handleCategorySelected = (item: IActivityCategory) => {
    const partnerExternal = this.state.partnerExternal;

    if (partnerExternal.activityCategories == null) {
      partnerExternal.activityCategories = Array<IActivityCategory>();
    }

    if (!partnerExternal.activityCategories.some(it => it.id === item.id)) {
      partnerExternal.activityCategories.push(item);
      this.setState({
        partnerExternal,
        isErrors: { ...this.state.isErrors, ['activityCategory']: false },
        isValids: { ...this.state.isValids, ['activityCategory']: false }
      });
    }
    this.props.hideModal();
  };

  private deleteCategory = (category?: IActivityCategory) => {
    const partnerExternal = this.state.partnerExternal;
    partnerExternal.activityCategories = partnerExternal.activityCategories?.filter?.(it => it.id !== category.id) ?? [];
    this.setState({ partnerExternal });
  };

  private handleSubCategoryList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: translate('categoryManage.labels.search'),
      action: this.props.fetchActivitySubCategories,
      idToSearch: this.state.partnerExternal?.activityCategories?.map(it => it.id) ?? [],
      placeholderInput: translate('editActivity.placeholders.searchSubCategory'),
      stateAction: 'activitySubCategories',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleSubCategorySelected(item)
    });
  };
  private handleSubCategorySelected = (item: IActivitySubCategory) => {
    const partner = this.state.partnerExternal;

    if (partner.activitySubCategories == null) {
      partner.activitySubCategories = Array<IActivitySubCategory>();
    }

    if (partner.activitySubCategories.filter(it => it.id === item.id).length === 0) {
      partner.activitySubCategories.push(item);
      this.setState({
        partnerExternal: { ...this.state.partnerExternal.activitySubCategories, ...partner },
        isErrors: { ...this.state.isErrors, ['subCategory']: false },
        isValids: { ...this.state.isValids, ['subCategory']: false }
      });
    }
    this.props.hideModal();
  };

  private deleteSubCategory = (subCategory?: IActivitySubCategory) => {
    const partner = this.state.partnerExternal;

    partner.activitySubCategories = partner.activitySubCategories
      ? partner.activitySubCategories.filter(it => it.id !== subCategory.id)
      : Array<IActivitySubCategory>();

    this.setState({
      partnerExternal: { ...this.state.partnerExternal.activityCategories, ...partner }
    });
  };

  private renderCategoryList = (category: IActivityCategory): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <label >{category.name}</label>
        <Button className={'partner-external-delete-button'} onClick={this.deleteCategory.bind(this, category)}>
          <FontAwesomeIcon icon={'trash-alt'} className={'marginR1'} />
          <Translate contentKey={'categoryList.buttons.delete'} />
        </Button>
      </div>
    );
  };
  private renderSubCategoryList = (subCategory: IActivitySubCategory): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <label>{subCategory.name}</label>
        <Button className={'partner-external-delete-button'} onClick={this.deleteSubCategory.bind(this, subCategory)}>
          <FontAwesomeIcon icon={'trash-alt'} className={'marginR1'} />
          <Translate contentKey={'categoryList.buttons.delete'} />
        </Button>
      </div>
    );
  };
  private renderImageToShow = (): JSX.Element => {
    if (this.state.partnerExternal.image == null) {
      return (
        <div>
          <label className={'partner-external-text-label'}>
            <Translate contentKey={'categoryManage.labels.noneImage'} />
          </label>
        </div>
      );
    }
    return (
      <div>
        {this.state.partnerExternal.image.imageUrl ? (
          <img src={this.state.partnerExternal.image ? this.state.partnerExternal.image.imageUrl : ''} />
        ) : (
            <img src={`data:image/jpeg;base64,${this.state.partnerExternal.image.file}`} />
          )}
      </div>
    );
  };

  private renderCoverImageToShow = (): JSX.Element => {
    if (this.state.partnerExternal.coverImage == null) {
      return (
        <div>
          <label className={'partner-external-text-label'}>
            <Translate contentKey={'categoryManage.labels.noneImage'} />
          </label>
        </div>
      );
    }
    return (
      <div>
        {this.state.partnerExternal.coverImage.imageUrl ? (
          <img src={this.state.partnerExternal.coverImage ? this.state.partnerExternal.coverImage.imageUrl : ''} />
        ) : (
            <img src={`data:image/jpeg;base64,${this.state.partnerExternal.coverImage.file}`} />
          )}
      </div>
    );
  };

  private renderSecondaryActivityList = (secondaryActivityList?: EconomicActivity): JSX.Element => {
    return (
      <div className={'partner-external-action-column-container'}>
        <p className="partner-external-text-list ">
          {(secondaryActivityList?.code ?? '') + ' - ' + (secondaryActivityList?.text ?? '')}
        </p>
        <Button className={'partner-external-delete-button'} onClick={this.deleteSecondaryActivity.bind(this, secondaryActivityList)}>
          <FontAwesomeIcon icon={'trash-alt'} className={'marginR1'} />
          <Translate contentKey={'categoryList.buttons.delete'} />
        </Button>
      </div>
    );
  };

  private changeDocumentMask(text?: string) {
    const partnerExternal = this.state.partnerExternal;

    if (partnerExternal.documentType === 'CPF') {
      text = StringUtils.addCpfMask(this.state.partnerExternal.document);
    }
    if (partnerExternal.documentType === 'CNPJ') {
      text = StringUtils.addCnpjMask(this.state.partnerExternal.document);
    }
    return text;
  }

  render() {
    const changeMask = this.state.partnerExternal.documentType == PartnerExternalDocumentType.CPF ? this.state.cpfMask : this.state.cnpjMask;

    return (
      <Row>
        <Col>
          <div className={'form-container'}>
            <div className={'title-screen-container'}>
              <div onClick={() => this.props.history.goBack()}>
                <FontAwesomeIcon className={'back-arrow-icon'} icon={'arrow-left'} />
              </div>
              <label className={'partner-external-text-list'}>
                <Translate contentKey={this.state.isEdit ? 'keikenPayPartnerExternalManage.title.edit' : 'keikenPayPartnerExternalManage.title.create'} />
              </label>
            </div>

            {/* nome e nome fantasia */}
            <div className={'input-container'}>
              <InputField
                title={translate('keikenPayPartnerExternalManage.placeholders.name')}
                onChange={this.handleTextChanged.bind(this, 'name')}
                inputClassName={''}
                error={this.state.isErrors['name']}
                valid={this.state.isValids['name']}
                value={this.state.partnerExternal.name ?? ''}
                placeholder={'keikenPayPartnerExternalManage.placeholders.name'}
              />
            </div>
            <div className={'input-container'}>
              <InputField
                title={translate('keikenPayPartnerExternalManage.placeholders.fantasyName')}
                onChange={this.handleTextChanged.bind(this, 'fantasyName')}
                inputClassName={''}
                error={this.state.isErrors['fantasyName']}
                valid={this.state.isValids['fantasyName']}
                value={!!this.state.partnerExternal.fantasyName ? this.state.partnerExternal.fantasyName : ''}
                placeholder={'keikenPayPartnerExternalManage.placeholders.fantasyName'}
              />
            </div>

            {/*documentos*/}
            <div className={'input-container'}>
              <label className={'partner-register-input-label'}>
                Tipo de documento
            </label>
              <select onChange={this.handleChangeDocumentType} value={this.state.partnerExternal?.documentType ?? ''} className={'partner-register-select'}>
                {Object.values(PartnerExternalDocumentType).map((item, index) => (
                  <option value={item} key={item + index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className={'input-container'}>
              <InputField
                title={translate('keikenPayPartnerExternalManage.placeholders.document')}
                onChange={this.handleTextChanged.bind(this, 'document')}
                error={this.state.isErrors['document']}
                valid={this.state.isValids['document']}
                inputClassName={''}
                isMaskRequired
                maskFormat={changeMask}
                value={this.state.partnerExternal?.document ?? ''}
                placeholder={'keikenPayPartnerExternalManage.placeholders.document'}
              />
            </div>
            {/* email e phone*/}
            <div className={'input-container'}>
              <InputField
                title={translate('keikenPayPartnerExternalManage.placeholders.email')}
                onChange={this.handleTextChanged.bind(this, 'email')}
                inputClassName={''}
                value={!!this.state.partnerExternal.email ? this.state.partnerExternal.email : ''}
                placeholder={'keikenPayPartnerExternalManage.placeholders.email'}
              />
            </div>
            <div className={'input-container'}>
              <InputField
                title={translate('keikenPayPartnerExternalManage.placeholders.phone')}
                onChange={this.handleTextChanged.bind(this, 'phone')}
                inputClassName={''}
                value={this.state.partnerExternal.phone ?? ''}
                placeholder={'keikenPayPartnerExternalManage.placeholders.phone'}
              />
            </div>

            {/*endereço */}
            <div className={'input-container'} onClick={this.handleOnAddressPressed}>
              <InputField
                title={translate('keikenPayPartnerExternalManage.placeholders.address')}
                inputClassName={'partner-external-input-read-only'}
                value={(this.state.partnerExternal?.address?.street ?? '') + ' ' + (this.state.partnerExternal?.address?.number ?? '')}
                error={this.state.isErrors['address']}
                valid={this.state.isValids['address']}
                placeholder={'keikenPayPartnerExternalManage.placeholders.address'}
                readOnly
                onClick={this.handleOnAddressPressed}
              />
              <div className="category-manage-inner-icon">
                <FontAwesomeIcon className={'partner-external-address-search-icon'} icon={'search'} />
              </div>
            </div>

            {/* categorias e subCategorias*/}
            <div className={'input-container'}>
              <div className={'category-manage-inner-addon'} onClick={this.handleCategoryList}>
                <InputField
                  title={translate('keikenPayPartnerExternalManage.placeholders.category')}
                  inputClassName={'partner-external-input-read-only'}
                  value={''}
                  error={this.state.isErrors['activityCategory']}
                  valid={this.state.isValids['activityCategory']}
                  placeholder={'keikenPayPartnerExternalManage.placeholders.category'}
                  readOnly
                  onClick={this.handleCategoryList}
                />

                <div className={'category-manage-inner-icon'}>
                  <FontAwesomeIcon className={'category-manage-inner-icon'} icon={'search'} />
                </div>
              </div>
              <div className={''}>
                {this.state.partnerExternal.activityCategories != null &&
                  this.state.partnerExternal.activityCategories.map((item, index) => (
                    <div className={'partner-external-categories-sub-categories '} >{this.renderCategoryList(item)}</div>
                  ))}
              </div>
            </div>
            <div className={'input-container'}>
              <div className={'category-manage-inner-addon'} onClick={this.handleSubCategoryList}>
                <InputField
                  title={translate('keikenPayPartnerExternalManage.placeholders.subCategory')}
                  inputClassName={'partner-external-input-read-only'}
                  value={''}
                  error={this.state.isErrors['subCategory']}
                  valid={this.state.isValids['subCategory']}
                  placeholder={'keikenPayPartnerExternalManage.placeholders.subCategory'}
                  readOnly
                  onClick={this.handleSubCategoryList}
                />

                <div className={'category-manage-inner-icon'}>
                  <FontAwesomeIcon className={'category-manage-inner-icon'} icon={'search'} />
                </div>
              </div>
            </div>
            <div className={'t'}>
              {this.state.partnerExternal.activitySubCategories != null &&
                this.state.partnerExternal.activitySubCategories.map((item, index) => (
                  <div className={'partner-external-categories-sub-categories'}>{this.renderSubCategoryList(item)}</div>
                ))}
            </div>

            {/* principalActivity secondaryActivity*/}
            <div className={'input-container'}>
              <div className={'category-manage-inner-addon'} onClick={this.handleEconomicActivityList}>
                <InputField
                  title={'Lista de CNES'}
                  inputClassName={'partner-external-input-read-only'}
                  value={(this.state.partnerExternal?.principalActivity?.code ?? '') + '-' + (this.state.partnerExternal?.principalActivity?.text ?? '')}
                  placeholder={'keikenPayPartnerExternalManage.placeholders.principalActivity'}
                  error={this.state.isErrors['principalActivity']}
                  valid={this.state.isValids['principalActivity']}
                  readOnly
                  onClick={this.handleEconomicActivityList}
                />
              </div>
            </div>

            <div className={'input-container'}>
              <div className={'category-manage-inner-addon'} onClick={this.handleSecondaryActivityList}>
                <InputField
                  title={'Lista de atividades secundárias'}
                  inputClassName={'partner-external-input-read-only'}
                  value={''}
                  error={this.state.isErrors['secundaryActivity']}
                  valid={this.state.isValids['secundaryActivity']}
                  placeholder={'keikenPayPartnerExternalManage.placeholders.secondaryActivity'}
                  readOnly
                  onClick={this.handleSecondaryActivityList}
                />

                <div className={'category-manage-inner-icon'}>
                  <FontAwesomeIcon className={'category-manage-inner-icon'} icon={'search'} />
                </div>
              </div>
            </div>
            <div className={''}>
              {this.state.partnerExternal.secondaryActivities != null &&
                this.state.partnerExternal.secondaryActivities.map(item => (
                  <div className={'partner-external-categories-sub-categories'}>{this.renderSecondaryActivityList(item)}</div>
                ))}
            </div>

            {/*imagens*/}
            <div className={'category-manage-image-container'}>
              <p className="partner-external-text">Imagem</p>
              {this.renderImageToShow()}
              <div className={'category-manage-button-add-image'}>
                <input
                  id={'myFileInput'}
                  className={'edit-activity-step1-image-input'}
                  onChange={this.handleImage}
                  accept={'image/*'}
                  type={'file'}
                />
                <CoolButton
                  onClick={() => document.getElementById('myFileInput').click()}
                  title={'imagem'}
                />
              </div>
            </div>
            <div className={'partner-external-image'}>
              <p className="partner-external-text">Imagem de capa</p>
              {this.renderCoverImageToShow()}
              <div className={'category-manage-button-add-image'}>
                <input
                  id={'myFileInputCover'}
                  className={'edit-activity-step1-image-input'}
                  onChange={this.handleCoverImage}
                  accept={'image/*'}
                  type={'file'}
                />
                <CoolButton
                  onClick={() => document.getElementById('myFileInputCover').click()}
                  title={'imagem de capa'}
                />
              </div>
            </div>

            <div className={'input-container'}>
              <InputField
                title={'Ordem de prioridade'}
                onChange={this.handleTextChanged.bind(this, 'orderPriority')}
                inputClassName={''}
                type={'number'}
                value={this.state.partnerExternal?.orderPriority}
                placeholder={'Ordem de prioridade'}
              />
            </div>

            {/*redes sociais */}
            <div className={'input-container'}>
              {[SocialMediaType.FACEBOOK, SocialMediaType.INSTAGRAM, SocialMediaType.WEBSITE].map(type => (
                <InputField value={this.state.partnerExternal?.socialMediaAccounts?.filter?.(it => it?.type == type)?.[0]?.url ?? ''}
                  title={type}
                  inputClassName={''}
                  onChange={this.handleSocialMedia.bind(this, type)}
                  placeholder={'keikenPayPartnerExternalManage.placeholders.socialMedia'}
                />
              ))}
            </div>

            {/*status*/}
            <div className={'partner-register-input-divisor-selector'}>
              <label className={'partner-register-input-label'}>
                <Translate contentKey={'categoryManage.labels.status'} />
              </label>
              <select onChange={this.handleChangeType} value={this.state.partnerExternal?.partnerExternalStatus ?? ''} className={'partner-register-select'}>
                {Object.values(PartnerExternalStatus).map((item, index) => (
                  <option value={item} key={item + index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className={'submit-button'}>
              <CoolButton
                loading={this.state.loading}
                onClick={this.handleSave}
                title={this.state.isEdit ? 'Editar parceiro' : 'Criar parceiro'} />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  updatePartner: storeState.keikenPayPartnerExternal.updatePartnerExternal,
  createPartner: storeState.keikenPayPartnerExternal.createPartnerExternal,
  partnerExternal: storeState.keikenPayPartnerExternal.partnerExternal
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  fetchActivityCategories,
  fetchListActivitySubCategories,
  fetchAddresses,
  fetchActivitySubCategories,
  reset,
  getPartnerExternal,
  getEconomicActivity,
  updatePartnerExternal,
  createPartnerExternal
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeikenPayPartnerExternalManage);
