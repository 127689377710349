
import React, { useEffect, useState } from 'react';
import { ICustomerActivity, ICustomerActivityFunctions } from 'app/shared/model/customer-activity.model';
import { IRootState } from 'app/shared/reducers';
import StringUtils from 'app/util/StringUtils';
import { getPaginationItemsNumber, JhiPagination, Translate, translate } from 'react-jhipster';
import SimpleTableList from '../simple-table-list/simple-table-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Button, Col } from 'reactstrap';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { getCustomerActivities, resetCustomerActivitiesStatus } from 'app/entities/customer/customer-redux';
import { connect } from 'react-redux';
import { ICustomer } from 'app/shared/model/customer/customer';
import { deleteRenewalActivitySchedule, fetchRenewalActivitySchedule, reset as resetRenewal } from 'app/entities/renewal-activity-schedule/renewal-activity-schedule-redux';
import { IRenewalActivitySchedule } from 'app/shared/model/renewal-activity-schedule';
import _ from 'lodash';
import { defaultWeeklyOptions } from 'app/modules/application-admin/schedule-manage/activity-schedule-confirm-modal/activity-schedule-confirm-modal';
import { useHistory } from 'react-router-dom';
import { formatDate, formatLocalDate } from 'app/shared/util/date-utils';

interface Props extends StateProps, DispatchProps {
  customer: ICustomer;
  renderActionColumn: (item: ICustomerActivity, index: number) => JSX.Element | string;
  selectedId?: number;
  reload?: boolean;
}

function TableCustomerActivity(props: Props) {

  const [state, setState] = useState({
    pageRequest: {
      page: 0,
      size: 5
    },
    pageRenewal: undefined
  });

  useEffect(() => {
    if (props.updateTokenUsedStatus === HttpRequestStatus.SUCCESS) {
      props.getCustomerActivities(props.customer?.id, state.pageRequest);
    }
  }, [props.updateTokenUsedStatus]);

  useEffect(() => {
    if (props.customerActivitiesStatus == HttpRequestStatus.SUCCESS) {
      props.resetCustomerActivitiesStatus();
    }
  }, [props.customerActivitiesStatus]);

  useEffect(() => {
    if (props.statusRenewal == HttpRequestStatus.SUCCESS) {
      setState(prev => ({
        ...prev,
        pageRenewal: props.pageRenewal
      }));
      props.resetRenewal();
    } else if (props.statusRenewal == HttpRequestStatus.ERROR) {
      props.resetRenewal();
    }
  }, [props.statusRenewal]);

  useEffect(() => {
    if (props.customer?.id) {
      props.getCustomerActivities(props.customer?.id, state.pageRequest);
      props.fetchRenewalActivitySchedule({ page: 0, size: 20 }, {
        'customer.id': props.customer.id
      });
    }
  }, [props.customer, props.reload]);

  useEffect(() => {
    if (props.statusDelete == HttpRequestStatus.SUCCESS) {
      props.fetchRenewalActivitySchedule({ page: 0, size: 20 }, {
        'customer.id': props.customer.id
      });
      props.resetRenewal();
    } else if (props.statusDelete == HttpRequestStatus.ERROR) {
      props.resetRenewal();
    }
  }, [props.statusDelete]);

  const handleTransformToTableContent = (content?: ICustomerActivity[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: ICustomerActivity, index: number) => {
      const date = ICustomerActivityFunctions.getScheduleDate(item);
      const weekDay = ICustomerActivityFunctions.getScheduleWeekDay(item);
      const partnerName = ICustomerActivityFunctions.getPartnerName(item);

      const billingValue = StringUtils.currencyPtBr(item?.billing?.value);
      const subsidyValue = StringUtils.currencyPtBr(item?.billing?.billingCoParticipation?.subsidyValue);

      const statusColor = item?.status == 'APPROVED' ? 'text-success' : item?.status == 'WAITING_APPROVE' ? 'text-warning' : 'text-danger';
      const status = <p className={statusColor}>{item?.status?.toString?.()}</p> ?? '';

      const billingStatusColor = item?.billing?.status == 'PAID' ? 'text-success' : item?.billing?.status == 'WAITING' ? 'text-warning' : 'text-danger';
      const billingStatus = <p className={billingStatusColor}>{item?.billing?.status?.toString?.()}</p> ?? '';

      result.push([
        partnerName,
        weekDay,
        date,
        status,
        billingStatus,
        item?.billing?.paymentMethod ?? '',
        item?.billing?.paymentProviderId ?? '',
        billingValue,
        subsidyValue,
        props.renderActionColumn(item, index)
      ]);
    });
    return result;
  };

  const handlePagination = activePage => {
    const object = state.pageRequest;
    object.page = activePage - 1;
    setState(prev => ({ ...prev, pageRequest: object }));
    if (props.customer?.id) {
      props.getCustomerActivities(props.customer?.id, object);
    }
  };

  const history = useHistory();
  const handleEditRenewal = (renewal: IRenewalActivitySchedule | undefined) => {
    history.push({
      pathname: `/application-admin/renewal-activity-schedule/${renewal?.id}`,
      state: {
        renewal,
        edit: true
      }
    });
  };

  const handleDeleteRenewal = (item: IRenewalActivitySchedule) => {
    props.deleteRenewalActivitySchedule(item.id);
  };

  const renderActionRenewalColumn = (renewal: IRenewalActivitySchedule | undefined): JSX.Element => {
    return (
      <div className={'action-column-container'}>
        <Button color="info" className={'centered-content margin1'} onClick={() => handleEditRenewal(renewal)}>
          <FontAwesomeIcon icon={'eye'} />
        </Button>
        <Button color="danger" className={'centered-content margin1'} onClick={() => handleDeleteRenewal(renewal)}>
          <FontAwesomeIcon icon={'trash'} />
        </Button>
      </div>
    );
  };

  const handleTransformPresetRenewalToTableContent = (content?: IRenewalActivitySchedule[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IRenewalActivitySchedule) => {
      const dayOfWeek = translate(`renewalList.daysOfWeek.${item?.dayOfWeek}`);
      const startAt = item?.startAt?.substring(0, 5);
      const interval = item?.intervalWeek != null ?
        _.find(defaultWeeklyOptions, ['value', item?.intervalWeek])?.name || '' : '';

      const lastRenewal = item?.lastScheduleDate ? formatLocalDate(item.lastScheduleDate) : '';
      const customerPaymentMethod = item.presetPaymentMethodsToCharge?.customerPaymentMethod;
      const paymentInfo =
        !customerPaymentMethod?.lastFourNumbers ? '' :
          `${customerPaymentMethod.brand} - ${customerPaymentMethod.lastFourNumbers}`;

      const groupId = item?.presetPaymentMethodsToCharge?.coParticipationGroup?.id;
      const nameGroup = item?.presetPaymentMethodsToCharge?.coParticipationGroup?.nameGroup;
      const groupActivityCategoryId = item?.presetPaymentMethodsToCharge?.coParticipationGroupActivityCategory?.id;
      const activityCategoryName = item?.presetPaymentMethodsToCharge?.coParticipationGroupActivityCategory?.activityCategory?.name;

      const coParticipationInfo = !groupId && !nameGroup && !groupActivityCategoryId && !activityCategoryName ? '' :
        `${groupId} - ${nameGroup} / ${groupActivityCategoryId} - ${activityCategoryName}`;

      const statusColor = item?.status == 'ENABLED' ? 'text-success' : 'text-danger';
      const status = <p className={statusColor}>{translate(`renewalList.status.${item?.status}`)}</p> ?? '';

      result.push([
        item?.activity?.partnerPlace?.fantasyName || '',
        lastRenewal,
        dayOfWeek || '',
        startAt || '',
        interval || '',
        status || '',
        paymentInfo,
        coParticipationInfo,
        renderActionRenewalColumn(item)]);
    });
    return result;
  };

  const tableContent: Array<Array<string | JSX.Element>> = handleTransformToTableContent(props.customerActivities?.content);
  const indexSelected = props.customerActivities?.content?.findIndex(it => it.id == props.selectedId);

  const tableContentPageRenewal: Array<Array<string | JSX.Element>> = handleTransformPresetRenewalToTableContent(state?.pageRenewal?.content);
  return (
    <div>
      <SimpleTableList
        rows={tableContent}
        columNameKeys={translate('scheduleManage.tableHeaders')}
        emptyTableMessage={''}
        indexSelected={indexSelected}
      />

      {tableContent.length > 0 && (
        <Row className={'justify-content-center'}>
          <JhiPagination
            items={
              props.customerActivities ? getPaginationItemsNumber(props.customerActivities.totalElements, props.customerActivities.size) : 0
            }
            activePage={props.customerActivities ? props.customerActivities.number : 0}
            onSelect={handlePagination}
            maxButtons={5}
          />
        </Row>
      )}

      {/* {state?.pageRenewal?.content && (<> */}
      <h1>Renovações de atividades</h1>
      <SimpleTableList
        rows={tableContentPageRenewal}
        columNameKeys={translate('customerManage.customerActivity.presetRenewal.tableHeaders')}
        emptyTableMessage={''}
      />
      {/* </>)} */}
    </div>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  customerActivities: storeState.customer?.customerActivities,
  customerActivitiesStatus: storeState.customer?.customerActivitiesStatus,
  pageRenewal: storeState.renewalActivitySchedule.page,
  statusRenewal: storeState.renewalActivitySchedule.getRenewalActivityScheduleList,
  statusDelete: storeState.renewalActivitySchedule.deleteRenewalActivitySchedule,
  updateTokenUsedStatus: storeState.customerActivity.updateTokenUsedByAdminStatus
});

const mapDispatchToProps = {
  getCustomerActivities,
  resetCustomerActivitiesStatus,
  fetchRenewalActivitySchedule,
  deleteRenewalActivitySchedule,
  resetRenewal
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableCustomerActivity);
