import { IKeikenPayPartnerExternal } from './KeikenPayPartnerExternal';
import { RefundAccountType } from './RefundAccountType';
import { CustomerRefundAccount } from './CustomerRefundAccount';
import { IImage } from '../image';
import { KeikenPayFrequency } from './KeikenPayFrequency';
import { ICustomer } from '../customer/customer';
import { BillingStatus } from '../billing/billing';

export class KeikenPayInvoice {
  id?: number;
  price?: number;
  subsidyValue?: number;
  subsidyValueWithFee?: number;
  valueFee?: number;
  enrolmentFee?: number;
  maintenanceFee?: number;
  status?: KeikenPayInvoiceStatus;
  frequency?: KeikenPayFrequency;
  quantity?: number;
  image?: IImage;
  auxUserImages?: IImage[];
  auxImage?: IImage;
  partnerExternal?: IKeikenPayPartnerExternal;
  refundAccount?: CustomerRefundAccount;
  customer?: ICustomer;
  description?: string;
  billingCoParticipation?: BillingCoParticipation;
  descriptionAI?: string;
  statusAI?: string;
}

export interface KeikenPayUpdateDTO {
  id: number;
  description: string;
  billingCoParticipation: {
    id: number;
    date: string;
  };
  image: IImage;
  auxImage: IImage;
  auxUserImages: IImage[];
}

export enum BillingCoParticipationType {
  ACTIVITY_SCHEDULE = 'ACTIVITY_SCHEDULE',
  KEIKEN_PAY_INVOICE = 'KEIKEN_PAY_INVOICE'
}
export interface BillingCoParticipation {
  id?: number;
  date?: string;
  subsidyValue?: string;
  type?: BillingCoParticipationType;
  billing?: {
    status?: BillingStatus;
  };
  coParticipationGroup: {
    id?: number;
    nameGroup?: string;
  };
  coParticipationGroupActivityCategory: {
    id?: number;
    activityCategory?: {
      id?: number;
      name?: string;
    };
  };
}

export enum KeikenPayInvoiceStatus {
  WAITING = 'WAITING',
  IN_ANALYSIS = 'IN_ANALYSIS',
  AUTHORIZED = 'AUTHORIZED',
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
  DENIED = 'DENIED'
}
