import React, { useCallback } from 'react';
import _ from 'lodash';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { IActivitySchedule } from 'app/shared/model/activity-schedule/activity-schedule';
import { IActivity } from 'app/shared/model/activity';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pt-br';
import { toast } from 'react-toastify';

// TODO: apply i18n
const defaultMessages = {
  date: 'Data',
  time: 'Hora',
  event: 'Evento',
  allDay: 'Dia Todo',
  week: 'Semana',
  work_week: 'Eventos',
  day: 'Dia',
  month: 'Mês',
  previous: 'Anterior',
  next: 'Próximo',
  yesterday: 'Ontem',
  tomorrow: 'Amanhã',
  today: 'Hoje',
  agenda: 'Agenda',
  noEventsInRange: 'Não há eventos no período.',
  showMore: total => '+' + total + ' mais'
};

const localizer = momentLocalizer(moment);

interface PartnerActivityScheduleCalendarProps {
  activities: IActivity[];
  onSelectEvent: (activity: IActivity, activitySchedule: IActivitySchedule) => void;
}

interface EventItemCalendarActivitySchedule {
  id: number;
  title: string;
  start: Date;
  end: Date;
  activitySchedule: IActivitySchedule;
  activity: IActivity;
}

const PartnerActivityScheduleCalendar = ({ activities, onSelectEvent }: PartnerActivityScheduleCalendarProps) => {

  const events = _.flatMap(activities, activity =>
    activity?.activitySchedules?.map?.(schedule => ({
      id: schedule.id,
      title: activity?.name ?? '',
      start: new Date(schedule.date),
      end: moment(schedule.date).add((activity?.duration ?? 50) as number, 'minutes').toDate(),
      activitySchedule: schedule,
      activity
    })
    )) || [];

  function handleOnSelect(eventItem: EventItemCalendarActivitySchedule) {
    if (eventItem?.activitySchedule?.status === 'SCHEDULED') {
      return;
    }

    if (eventItem?.activitySchedule?.status === 'PRE_SCHEDULE_BY_PARTNER') {
      return toast.error('Horário já pré-agendado pelo parceiro.');
    }
    onSelectEvent(eventItem.activity, eventItem?.activitySchedule);
  }

  if (_.isEmpty(events)) {
    return <div />;
  }

  const customDayPropGetter = useCallback(event => {
    if (event?.activitySchedule?.status === 'SCHEDULED') {
      return {
        style: { backgroundColor: 'grey', borderColor: 'grey' }
      };
    }

    if (event?.activitySchedule?.status === 'PRE_SCHEDULE_BY_PARTNER') {
      return {
        style: { backgroundColor: '#FFD700', borderColor: '#FFD700' }
      };
    }
  }, []);

  return (
    <div>
      <Calendar
        eventPropGetter={customDayPropGetter}
        messages={defaultMessages}
        formats={{
          agendaDateFormat: 'DD/MM ddd',
          weekdayFormat: 'dddd'
        }}
        defaultView={Views.WEEK}
        localizer={localizer}
        events={events}
        onSelectEvent={handleOnSelect}
        startAccessor="start"
        endAccessor="end"
        style={{ width: 1200, height: 800 }}
        popupOffset={30}
        showMultiDayTimes
      />
    </div>
  );
};

export default PartnerActivityScheduleCalendar;
