import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';

export const ACTION_TYPES = {
  DELETE_ACCOUNT_FINISH: 'deleteAccount/DELETE_ACCOUNT_FINISH',
  RESET: 'deleteAccount/RESET'
};

const initialState = {
  deleteStatus: HttpRequestStatus.NOOP
};

export type DeleteAccountState = Readonly<typeof initialState>;

// Reducer

export default (state: DeleteAccountState = initialState, action): DeleteAccountState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.DELETE_ACCOUNT_FINISH):
      return { ...state, deleteStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.DELETE_ACCOUNT_FINISH):
      return { ...initialState, deleteStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.DELETE_ACCOUNT_FINISH):
      return { ...initialState, deleteStatus: HttpRequestStatus.SUCCESS };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/account/customer/finish';

// Actions
export const deleteAccountFinish = (deleteKey: string) => ({
  type: ACTION_TYPES.DELETE_ACCOUNT_FINISH,
  payload: axios.delete(`${apiUrl}/${deleteKey}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
