import './header.scss';

import React from 'react';
import { Translate } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, NavbarBrand, Collapse } from 'reactstrap';

import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand, Partner } from './header-components';

import { RouteComponentProps } from 'react-router';
import './header.css';

import { withRouter } from 'react-router-dom';

export interface IHeaderProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
  isAuthenticated: boolean;
  isAdmin: boolean;
}

export interface IHeaderState {
  menuOpen: boolean;
}

export class Header extends React.Component<IHeaderProps, IHeaderState> {
  handlePartnerClicked = () => {
    this.props.history.push('/partner-register');
  };

  render() {
    const { isAuthenticated, isAdmin } = this.props;

    return (
      <div className="header__container ">
        {/* <LoadingBar className="loading-bar" /> */}
        <Brand />
        <div className="header__container--items">
          <Home />
          <Partner onClick={this.handlePartnerClicked} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(Header);
