import axios from 'axios';
import { HttpRequestStatus } from '../../shared/model/enum/HttpRequestStatus';
import { FAILURE, REQUEST, SUCCESS } from '../../shared/reducers/action-type.util';

export const ACTION_TYPES = {
  INIT_RESET_PASSWORD: 'INIT_RESET_PASSWORD',
  RESET: 'RESET'
};

const initialState = {
  initNewPasswordStatus: HttpRequestStatus.NOOP
};

export type IUserState = Readonly<typeof initialState>;

export default (state: IUserState = initialState, action: any): IUserState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.INIT_RESET_PASSWORD):
      return { ...initialState, initNewPasswordStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.INIT_RESET_PASSWORD):
      return { ...initialState, initNewPasswordStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.INIT_RESET_PASSWORD):
      return { ...initialState, initNewPasswordStatus: HttpRequestStatus.SUCCESS };
    case ACTION_TYPES.RESET:
      return {
        ...state
      };
    default:
      return initialState;
  }
};

const apiUrl = '/api';

export const initNewPassword = (email: string) => ({
  type: ACTION_TYPES.INIT_RESET_PASSWORD,
  payload: axios.post(`${apiUrl}/account/forgot-password/init`, { email })
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
