import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import Header from 'app/shared/layout/header/header';
import { IRootState } from 'app/shared/reducers';
import { getProfile } from 'app/shared/reducers/application-profile';
import {
  getAdminSession,
  getApplicationAdminSession,
  getPartnerSession,
  getSession,
  getCustomerSession,
  clearAuthentication
} from 'app/shared/reducers/authentication';
import { setLocale } from 'app/shared/reducers/locale';
import jwt_decode from 'jwt-decode';
import React from 'react';
import { Storage } from 'react-jhipster';
import { connect } from 'react-redux';
import { translate } from 'react-jhipster';
import { HashRouter as Router, RouteComponentProps } from 'react-router-dom';
import { toast, ToastContainer, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import ModalRoot from './components/modal/ModalRoot';
import HeaderApplicationAdmin from './shared/layout/header-application/header-application';
import HeaderCustomer from './shared/layout/header-customer/header-customer';
import HeaderPartner from './shared/layout/header-partner/header-partner';

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

export interface IAppProps extends StateProps, DispatchProps, RouteComponentProps {}

export class App extends React.Component<IAppProps> {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.redirectUrl) {
      toast.success(translate('login.messages.redirect'), {
        autoClose: 3000,
        onClose: () => {
          this.props.clearAuthentication(null);
          window.location.href = newProps.redirectUrl;
        }
      });
    }
  }

  componentDidMount() {
    const token = Storage.session.get(AUTH_TOKEN_KEY) || Storage.local.get(AUTH_TOKEN_KEY);

    if (!!token) {
      const decodeJwt = jwt_decode(token);
      if (decodeJwt.auth === AUTHORITIES.ADMIN) {
        this.props.getAdminSession();
        return;
      }

      if (decodeJwt.auth === AUTHORITIES.PARTNER) {
        this.props.getPartnerSession();
        return;
      }

      if (decodeJwt.auth === AUTHORITIES.APPLICATION_ADMIN) {
        this.props.getApplicationAdminSession();
        return;
      }

      if (decodeJwt.auth === AUTHORITIES.CUSTOMER) {
        this.props.getCustomerSession();
        return;
      }
    }

    this.props.getProfile();
  }

  renderPartnerLayout() {
    return (
      <ErrorBoundary>
        <HeaderPartner />
      </ErrorBoundary>
    );
  }

  renderApplicationAdminLayout() {
    return (
      <ErrorBoundary>
        <HeaderApplicationAdmin />
      </ErrorBoundary>
    );
  }

  renderCustomerLayout() {
    return (
      <ErrorBoundary>
        <HeaderCustomer />
      </ErrorBoundary>
    );
  }

  render() {
    const paddingTop = '3%';
    return (
      <Router>
        <div className="app-container">
          <ModalRoot />
          <ToastContainer
            position={toast.POSITION.TOP_LEFT as ToastPosition}
            className="toastify-container"
            toastClassName="toastify-toast"
          />
          {!this.props.isPartner &&
            !this.props.isApplicationAdmin && (
              <ErrorBoundary>
                <Header isAuthenticated={this.props.isAuthenticated} isAdmin={this.props.isAdmin} />
              </ErrorBoundary>
            )}

          {this.props.isPartner && this.renderPartnerLayout()}

          {this.props.isApplicationAdmin && this.renderApplicationAdminLayout()}

          {this.props.isCustomer && this.renderCustomerLayout()}

          <div className="container-fluid view-container" id="app-view-container">
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = ({ authentication, applicationProfile, locale }: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  isPartner: authentication.isPartner,
  isApplicationAdmin: authentication.isApplicationAdmin,
  isCustomer: authentication.isCustomer,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
  redirectUrl: authentication.redirectUrl
});

const mapDispatchToProps = {
  setLocale,
  getSession,
  getProfile,
  getAdminSession,
  getPartnerSession,
  getApplicationAdminSession,
  getCustomerSession,
  clearAuthentication
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
