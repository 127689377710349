import React from 'react';
import { Modal } from 'reactstrap';
import './activityModal.css';
import { ICustomerActivity } from 'app/shared/model/customer-activity.model';
import moment from 'moment';
import { translate } from 'react-jhipster';
import Button from '../button/Button';

export interface IActivityModalProps {
  config: {
    customerActivity: ICustomerActivity;
    onClose: () => void;
    onPressButton: (id: number) => void;
  };
}

export class ActivityModal extends React.Component<IActivityModalProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const activityTitle: string =
      this.props.config.customerActivity != null &&
      this.props.config.customerActivity.activitySchedule != null &&
      this.props.config.customerActivity.activitySchedule.activity != null &&
      this.props.config.customerActivity.activitySchedule.activity.activitySubCategories[0] != null
        ? this.props.config.customerActivity.activitySchedule.activity.activitySubCategories[0].name
        : '';
    const customerName: string =
      this.props.config.customerActivity != null &&
      this.props.config.customerActivity.customer != null &&
      this.props.config.customerActivity.customer.name != null
        ? this.props.config.customerActivity.customer.name
        : '';
    const imageUrl: string =
      this.props.config.customerActivity != null &&
      this.props.config.customerActivity.activitySchedule != null &&
      this.props.config.customerActivity.activitySchedule.activity != null &&
      this.props.config.customerActivity.activitySchedule.activity.image != null
        ? this.props.config.customerActivity.activitySchedule.activity.image.imageUrl
        : '';
    const date: string =
      this.props.config.customerActivity != null && this.props.config.customerActivity.activitySchedule != null
        ? moment(this.props.config.customerActivity.activitySchedule.date).format('DD/MM/YYYY HH:mm')
        : '';
    return (
      <Modal isOpen className={'activity-main-container'}>
        <div className={'activity-image-container'}>
          <div className={'activity-cross-container'} onClick={() => this.props.config.onClose()}>
            <img src={'./content/images/close-white.png'} className={'cross-img'} />
          </div>
          <img src={imageUrl !== '' ? imageUrl : './content/images/default-customer-activity.png'} className={'customer-activity-img'} />
        </div>
        <div className={'activity-title-container'}>
          <label className={'customer-activity-title'}>{activityTitle}</label>
        </div>
        <div className={'activity-line'} />
        <div className={'activity-list-container'}>
          <div className={'activity-icon-label-container'}>
            <img src={'./content/images/activity-details/user_grassgreen.png'} className={'icon-list-img'} />
            <label className={'activity-icon-label-text'}>{customerName}</label>
          </div>
          <div className={'activity-icon-label-container'}>
            <img src={'./content/images/activity-details/duration_grassgreen.png'} className={'icon-list-img'} />
            <label className={'activity-icon-label-text'}>{date}</label>
          </div>
          <div className={'customer-activity-button-container'}>
            <Button
              onClick={() => this.props.config.onPressButton(this.props.config.customerActivity.id!)}
              outerClassName={'partner-dashboard-token-button'}
              title={translate('partnerDashboard.buttons.next')}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default ActivityModal;
