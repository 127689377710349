import React, { useEffect, useState } from 'react';
import InputField from 'app/components/input-field/InputField';
import { IRootState } from 'app/shared/reducers';
import { Translate, translate } from 'react-jhipster';
import { Button as RButton, Input, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { createCompanyExternalBilling, updateCompanyExternalBilling, getCompanyExternalBillings } from 'app/entities/companyBillings/company-billing-reducer';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BillingCompanyPaymentMethod, IBilling } from 'app/shared/model/billing/billing';

interface CreateCompanyBillingDTO {
  transactionDate: string;
  referenceDate: string;
  scheduledDate: string;
  paymentMethod: BillingCompanyPaymentMethod;
}

interface CreateExternalBillingDTO {
  description: string;
  billingCompany: CreateCompanyBillingDTO;
  paymentProviderId: string;
  date: string;
  value: number;
  discountAmount: number;
  keikenTax: number;
  partnerProfit: number;
}

const initialExternalBilling: CreateExternalBillingDTO = {
  description: '',
  billingCompany: {
    transactionDate: '',
    referenceDate: '',
    scheduledDate: '',
    paymentMethod: BillingCompanyPaymentMethod.NONE
  },
  paymentProviderId: '',
  date: '',
  value: 0,
  discountAmount: 0,
  keikenTax: 0,
  partnerProfit: 0
};

type ICompanyReportAddProps = StateProps & DispatchProps & {
  companyId: number;
  isEdit?: boolean;
  billingToEdit?: IBilling;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
};

const CompanyCreateBillingExternal = (props: ICompanyReportAddProps) => {
  const {
    createExternalBilling,
    companyId,
    creteExternalBillingStatus,
    billingToEdit,
    isEdit,
    updateCompanyBilling,
    isModalOpen,
    setIsModalOpen,
    updateExternalBillingStatus,
    getExternalBillings
  } = props;

  const [error, setError] = useState<boolean>(false);
  const [externalBilling, setExternalBilling] = useState<CreateExternalBillingDTO>(initialExternalBilling);

  useEffect(() => {
    if (isEdit && billingToEdit) {
      setExternalBilling({
        description: billingToEdit?.description || '',
        billingCompany: {
          transactionDate: billingToEdit?.billingCompany.transactionDate || '',
          referenceDate: billingToEdit?.billingCompany.referenceDate || '',
          scheduledDate: billingToEdit?.billingCompany.scheduledDate || '',
          paymentMethod: billingToEdit?.billingCompany.paymentMethod || BillingCompanyPaymentMethod.NONE
        },
        paymentProviderId: billingToEdit?.paymentProviderId || '',
        date: billingToEdit?.date || '',
        value: billingToEdit?.value || 0,
        discountAmount: billingToEdit?.discountAmount || 0,
        keikenTax: billingToEdit?.keikenTax || 0,
        partnerProfit: billingToEdit?.partnerProfit || 0
      });
    } else {
      setExternalBilling(initialExternalBilling);
    }
  }, [isEdit, billingToEdit]);

  useEffect(() => {
    if (creteExternalBillingStatus === HttpRequestStatus.SUCCESS) {
      toast.success(translate('companyManage.messages.createReportSuccess'));
      setIsModalOpen(false);
      setExternalBilling(initialExternalBilling);
      getExternalBillings(companyId, 0, 10);
    }
  }, [creteExternalBillingStatus]);

  useEffect(() => {
    if (updateExternalBillingStatus === HttpRequestStatus.SUCCESS) {
      toast.success(translate('companyManage.messages.updateReportSuccess'));
      setIsModalOpen(false);
      setExternalBilling(initialExternalBilling);
      getExternalBillings(companyId, 0, 10);
    }
  }, [updateExternalBillingStatus]);

  const handleBillingExternal = () => {
    const { value, discountAmount, keikenTax, partnerProfit } = externalBilling;
    if (value - discountAmount - keikenTax - partnerProfit !== 0) {
      toast.error('Vallores não somam o valor da compra');
      return;
    }
    if (isEdit) {
      updateCompanyBilling(companyId, billingToEdit.id, externalBilling);
    } else {
      createExternalBilling(companyId, {...externalBilling, date: externalBilling.billingCompany.referenceDate});
    }
  };

  return (
    <>
      <RButton className={'centered-content marginB2'} onClick={() => setIsModalOpen(!isModalOpen)}>
        <FontAwesomeIcon icon={isModalOpen == true ? 'times' : 'check'} className={'marginR1'} />{' '}
        <Translate contentKey={'companyManage.newBillingExternal.title'} />
      </RButton>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} className="activity-company-modal">
        <ModalHeader toggle={() => setIsModalOpen(false)}/>
        <ModalBody>
          <div className={'input-container'}>
            <InputField
              isTextArea
              value={externalBilling.description}
              onChange={event => setExternalBilling({...externalBilling, description: event.target.value})}
              error={error}
              title={translate('companyManage.newBillingExternal.description')}
              inputClassName={''}
              maxLength={255}
              type={'text'}
              placeholder={'companyManage.newBillingExternal.descPlaceholder'}
              />
          </div>

          <div className={'input-container'}>
            <label className={'label'}>{translate('companyManage.newBillingExternal.date')}</label>
            <Input
              type="date"
              value={moment(externalBilling.billingCompany.referenceDate).format('YYYY-MM-DD')}
              onChange={e => {
                const value = e.target.value;
                setExternalBilling({...externalBilling, billingCompany: {...externalBilling.billingCompany, referenceDate: moment(value).toISOString()} });
              }}
            />
          </div>

          <div className={'input-container'}>
            <label className={'label'}>{translate('companyManage.newBillingExternal.scheduleDate')}</label>
            <Input
              type="date"
              value={moment(externalBilling.billingCompany.scheduledDate).format('YYYY-MM-DD')}
              onChange={e => {
                const value = e.target.value;
                setExternalBilling({...externalBilling, billingCompany: {...externalBilling.billingCompany, scheduledDate: moment(value).toISOString()} });
              }}
            />
          </div>

          <div className={'input-container'}>
            <label className={'label'}>{translate('companyManage.newBillingExternal.transactionDate')}</label>
            <Input
              type="date"
              value={moment(externalBilling.billingCompany.transactionDate).format('YYYY-MM-DD')}
              onChange={e => {
                const value = e.target.value;
                setExternalBilling({...externalBilling, billingCompany: {...externalBilling.billingCompany, transactionDate: moment(value).toISOString()} });
              }}
            />
          </div>

          <div className={'input-container'}>
            <InputField
              value={externalBilling.paymentProviderId}
              onChange={event => setExternalBilling({...externalBilling, paymentProviderId: event.target.value})}
              error={error}
              title={translate('companyManage.newBillingExternal.providerTitle')}
              inputClassName={''}
              maxLength={255}
              type={'text'}
              placeholder={'companyManage.newBillingExternal.providerPlaceholder'}
            />
          </div>
          <Row>
            <Col>
              <InputField
                value={externalBilling.value}
                onChange={event => setExternalBilling({...externalBilling, value: event.target.value as any})}
                error={error}
                title={translate('companyManage.newBillingExternal.value')}
                inputClassName={''}
                type={'number'}
              />
            </Col>
            <Col>
              <InputField
                value={externalBilling.partnerProfit}
                onChange={event => setExternalBilling({...externalBilling, partnerProfit: event.target.value as any})}
                error={error}
                title={translate('companyManage.newBillingExternal.repasse')}
                inputClassName={''}
                type={'number'}
              />
            </Col>
            <Col>
              <InputField
                value={externalBilling.discountAmount}
                onChange={event => setExternalBilling({...externalBilling, discountAmount: event.target.value as any})}
                error={error}
                title={translate('companyManage.newBillingExternal.discount')}
                inputClassName={''}
                type={'number'}
              />
            </Col>
            <Col>
              <InputField
                value={externalBilling.keikenTax}
                onChange={event => setExternalBilling({...externalBilling, keikenTax: event.target.value as any})}
                error={error}
                title={translate('companyManage.newBillingExternal.take')}
                inputClassName={''}
                type={'number'}
              />
            </Col>
          </Row>
          <fieldset style={{marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'space-between'}}>
            <label>{translate('companyManage.newBillingExternal.method.title')}</label>
            <select
              style={{width: '80%'}}
              onChange={(e: any) => setExternalBilling({...externalBilling, billingCompany: {...externalBilling.billingCompany, paymentMethod: e.target.value}})}
              value={externalBilling.billingCompany.paymentMethod}
            >
              <option value={BillingCompanyPaymentMethod.NONE}>{translate('companyManage.newBillingExternal.method.external')}</option>
              <option value={BillingCompanyPaymentMethod.PIX}>{translate('companyManage.newBillingExternal.method.pix')}</option>
              <option value={BillingCompanyPaymentMethod.BANK_SLIP}>{translate('companyManage.newBillingExternal.method.bankSlip')}</option>
            </select>
          </fieldset>
        </ModalBody>
        <ModalFooter>
          <RButton style={{backgroundColor: '#f44336'}} className={'cancelButton'} onClick={() => setIsModalOpen(false)}>
            {translate('companyManage.newBillingExternal.cancel')}
          </RButton>
          <RButton style={{backgroundColor: '#2196f3'}} className={'approveButton'} onClick={handleBillingExternal}>
           {isEdit ?
            translate('companyManage.newBillingExternal.edit') : translate('companyManage.newBillingExternal.save')}
          </RButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  creteExternalBillingStatus: storeState.companyBilling.createCompanyExternalBillingStatus,
  updateExternalBillingStatus: storeState.companyBilling.updateCompanyExternalBillingStatus
});

const mapDispatchToProps = {
  createExternalBilling: createCompanyExternalBilling,
  updateCompanyBilling: updateCompanyExternalBilling,
  getExternalBillings: getCompanyExternalBillings
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps)(CompanyCreateBillingExternal);
