import { ActivityPlan } from './activity-plan';
import { IActivity } from './activity';

export interface IPriceTable {
  id?: number;
  name?: string;
  status?: IPriceTableStatus;
  type?: IPriceTableType;
  priceTableActivities?: IPriceTableActivity[];
}

export interface IPriceTableActivity {
  id?: number;
  price?: number;
  activity?: IActivity;
  activityPlan?: ActivityPlan;
  priceTable?: IPriceTable;

  // table edit
  priceWithFee?: number;
  priceActivity?: number;
  valid?: boolean;
  partnerName?: string;
  type?: string;
  isNew?: boolean;
  selected?: boolean;
}

export class IPriceTableActivityUtils {
  static getPartnerFantasyName = (it: IPriceTableActivity) => {
    const root = it.activity ?? it.activityPlan;
    return root?.partnerPlace?.fantasyName ?? '';
  }
  static getActivityName = (it: IPriceTableActivity) => {
    const root = it.activity ?? it.activityPlan;
    return root?.name ?? '';
  }
  static getType = (it: IPriceTableActivity) => {
    return it.activity ? 'activity' : 'activityPlan';
  }
  static getActivityPrice = (it: IPriceTableActivity) => {
    const root = it.activity ?? it.activityPlan;
    return root?.price;
  }
  static getActivityPriceWithFee = (it: IPriceTableActivity) => {
    const root = it.activity ?? it.activityPlan;
    return root?.priceWithFee;
  }
}

export enum IPriceTableStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

export enum IPriceTableType {
  REGULAR = 'REGULAR',
  DEFAULT = 'DEFAULT'
}
