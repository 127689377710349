import { IActivityCategory } from '../activity-category';
import { IActivitySubCategory } from '../activity-sub-category';
import { IAddress } from '../address.model';
import { IImage } from '../image';
import { EconomicActivity } from './EconomicActivity';
import { SocialMediaAccount } from './social-media-account';

export enum PartnerExternalDocumentType {
  CPF = 'CPF',
  CNPJ = 'CNPJ'
}

export enum PartnerExternalStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

export interface IKeikenPayPartnerExternal {
  id?: number;
  registrationNumber?: string;
  document?: string;
  documentType?: PartnerExternalDocumentType;
  type?: string;
  openingDate?: string;
  name?: string;
  fantasyName?: string;
  companySize?: string;
  legalNature?: string;
  email?: string;
  crp?: string;
  cref?: string;
  crn?: string;
  phone?: string;
  status?: string;
  principalActivity?: EconomicActivity;
  secondaryActivities?: EconomicActivity[];
  address?: IAddress;
  activityCategories?: IActivityCategory[];
  activitySubCategories?: IActivitySubCategory[];
  partnerExternalStatus?: PartnerExternalStatus;
  socialMediaAccounts?: SocialMediaAccount[];
  image?: IImage;
  coverImage?: IImage;
  orderPriority?: number;
}
