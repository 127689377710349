import { ICity } from './city.model';
import { IGeoPoint } from './geo-point';

export interface IAddress {
  id?: number;
  street?: string;
  number?: string;
  zipcode?: string;
  district?: string;
  complement?: string;
  geolocated?: boolean;
  geoPoint?: IGeoPoint;
  city?: ICity;
}

export const defaultValue: Readonly<IAddress> = {
  geolocated: false
};

export class IAddressUtils {
  static mapAddressToString = (item: IAddress) => {
    if (!item?.street) {
      return '';
    }
    const firstSeparator = ', ';
    const secondSeparator = ' - ';
    let stringMapped = '';
    stringMapped += item.street ? item.street : '';
    stringMapped += item.number ? firstSeparator + item.number : '';
    stringMapped += item.city ? secondSeparator + item.city.name : '';
    stringMapped += item?.city?.state?.acronym ? secondSeparator + item.city.state.acronym : '';
    stringMapped = stringMapped.replace(/^\s-\s/, '');
    return stringMapped;
  };
}
