import React, { useState } from 'react';
import './pagination.scss';

interface Props {

    currentP: number;
    totalPages: number;
    onSelect: (page: number) => void;

}

export const PaginationTable = ({ currentP, totalPages, onSelect }: Props) => {
    const [currentPage, setCurrentPage] = useState(currentP);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            onSelect(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            onSelect(currentPage + 1);
        }
    };

    const handlePageSelect = (page: number) => {
        setCurrentPage(page);
        onSelect(page);
    };

    return (
        <div className="pagination-container">
            <div
                onClick={handlePrevious}
                className={`pagination-button ${currentPage > 1 ? '' : 'disabled'}`}
            >
                <img src="content/images/arrowLeftBlack.png" alt="left arrow" />
            </div>
            {[...Array(totalPages)].slice(Math.max(0, currentPage - 1), currentPage + 3).map((_, index) => {
                const page = currentPage + index;
                if (page > totalPages) return null;
                return (
                    <div
                        key={page}
                        onClick={() => handlePageSelect(page)}
                        className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                    >
                        <span>{page}</span>
                    </div>
                );
            })}
            {totalPages > currentPage + 3 && <span className="ellipsis">...</span>}
            <div
                onClick={() => handlePageSelect(totalPages)}
                className="pagination-button"
            >
                <span>{totalPages}</span>
            </div>
            <div
                onClick={handleNext}
                className={`pagination-button ${currentPage < totalPages ? '' : 'disabled'}`}
            >
                <img src="content/images/arrowRightBlack.png" alt="right arrow" />
            </div>
        </div>
    );
};
