import axios from 'axios';

import { REQUEST, SUCCESS, FAILURE } from '../../../app/shared/reducers/action-type.util';
import { HttpRequestStatus } from '../../../app/shared/model/enum/HttpRequestStatus';
import { IKeikenTransactionWithdraw } from '../../shared/model/keiken-transaction';
import qs from 'qs';

export const ACTIONS_TYPES = {
    GET_WITHDRAWS_TRANSACTIONS: 'keiken-transaction/GET_WITHDRAWS_TRANSACTIONS',
    POST_BILLING_TRANSACTIONS: 'keiken-transaction/POST_BILLING_TRANSACTIONS'
};

const initialState = {
    withdrawsTransactions: [] as IKeikenTransactionWithdraw[] | null,
    getWithdrawsTransactionsStatus: HttpRequestStatus.NOOP,
    postBillingTransactionsStatus: HttpRequestStatus.NOOP
};

export type KeikenTransactionState = Readonly<typeof initialState>;

// Reducer
export default (state: KeikenTransactionState = initialState, action): KeikenTransactionState => {
    switch (action.type) {
        case REQUEST(ACTIONS_TYPES.GET_WITHDRAWS_TRANSACTIONS):
            return {
                ...state,
                getWithdrawsTransactionsStatus: HttpRequestStatus.ONGOING
            };
        case FAILURE(ACTIONS_TYPES.GET_WITHDRAWS_TRANSACTIONS):
            return {
                ...state,
                getWithdrawsTransactionsStatus: HttpRequestStatus.ERROR
            };
        case SUCCESS(ACTIONS_TYPES.GET_WITHDRAWS_TRANSACTIONS):
            return {
                ...state,
                getWithdrawsTransactionsStatus: HttpRequestStatus.SUCCESS,
                withdrawsTransactions: action.payload.data
            };
        case REQUEST(ACTIONS_TYPES.POST_BILLING_TRANSACTIONS):
            return {
                ...state,
                postBillingTransactionsStatus: HttpRequestStatus.ONGOING
            };
        case FAILURE(ACTIONS_TYPES.POST_BILLING_TRANSACTIONS):
            return {
                ...state,
                postBillingTransactionsStatus: HttpRequestStatus.ERROR
            };
        case SUCCESS(ACTIONS_TYPES.POST_BILLING_TRANSACTIONS):
            return {
                ...state,
                postBillingTransactionsStatus: HttpRequestStatus.SUCCESS
            };
        default:
            return state;
    }
};

export const getWithdrawsTransactions = (dateTime: string) => {
    const queryParams = qs.stringify(
        {
            dateTime
        },
        { addQueryPrefix: true }
    );

    const url = '/api/keiken-transaction/withdraw-by-month' + queryParams;

    return {
        type: ACTIONS_TYPES.GET_WITHDRAWS_TRANSACTIONS,
        payload: axios.get<IKeikenTransactionWithdraw>(url)
    };
};

export const postBillingTransactions = (billingId: number) => {
    const url = `/api/keiken-transaction/${billingId}/init`;

    return {
        type: ACTIONS_TYPES.POST_BILLING_TRANSACTIONS,
        payload: axios.post(url)
    };
};
