export interface SocialMediaAccount {
    id?: number;
    url?: string;
    type?: SocialMediaType;
}

export enum SocialMediaType {
    TWITTER= 'TWITTER',
    INSTAGRAM = 'INSTAGRAM',
    FACEBOOK = 'FACEBOOK',
    LINKEDIN = 'LINKEDIN',
    WEBSITE = 'WEBSITE'
}
