import _ from 'lodash';
import { ISystemFee } from 'app/shared/model/system-fee.model';
import { SystemFeeType } from 'app/shared/model/enum/SystemFeeType';

class NumberUtils {
  isValidNumber(n: any) {
    if (n === '') {
      return false;
    }
    return !isNaN(n);
  }

  systemFeeCalculate(systemFee?: ISystemFee, value?: number): string {
    if (systemFee == null) {
      return `R$ ${value}`;
    }

    if (value == null || isNaN(Number(value))) {
      return 'R$ 0,00';
    }

    switch (systemFee.type!) {
      case SystemFeeType.PERCENTAGE:
        value = (value * 100) / (100 - systemFee.percentage);
        value = value + 0.7;
        value = (value * 100) / (100 - 5.49);
        break;
      case SystemFeeType.FIXED:
        value = value + systemFee.fixed;
        value = value + 0.7;
        value = (value * 100) / (100 - 5.49);
        break;
      case SystemFeeType.FIXED_AND_PERCENTAGE:
        value = (value * 100) / (100 - systemFee.percentage) + systemFee.fixed;
        value = value + 0.7;
        value = (value * 100) / (100 - 5.49);
        break;
      default:
        break;
    }

    return String(`R$ ${value.toFixed(2)}`);
  }
}

export default new NumberUtils();
