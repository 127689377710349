import { IActivity } from './activity';
import { IActivityCategory } from './activity-category';
import { IActivitySubCategory } from './activity-sub-category';
import { ICustomer } from './customer/customer';
import { PresetPaymentMethodsToCharge } from './preset-payment-methods-to-charge';

export enum PresetRenewalActivityScheduleStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  PRE_SCHEDULE_BY_PARTNER = 'PRE_SCHEDULE_BY_PARTNER',
  PRESET_PAYMENT_METHOD_MISSING = 'PRESET_PAYMENT_METHOD_MISSING'
}

export interface IRenewalActivitySchedule {
  id?: number;
  customer?: ICustomer;
  activity?: IActivity;
  dayOfWeek?: string;
  lastScheduleDate?: string;
  startAt?: string;
  intervalWeek?: number;
  status?: string;
  presetPaymentMethodsToCharge: PresetPaymentMethodsToCharge;
  activityCategory?: IActivityCategory;
  activitySubCategory?: IActivitySubCategory;
}
