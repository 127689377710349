export enum SystemFeeType {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED',
  FIXED_AND_PERCENTAGE = 'FIXED_AND_PERCENTAGE'
}

export enum DiscountFeeType {
  FINAL_PRICE = 'FINAL_PRICE',
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED',
  FIXED_AND_PERCENTAGE = 'FIXED_AND_PERCENTAGE'
}
