import { HIDE_MODAL, SHOW_MODAL } from './modal-action-types';
import { IAction, IActionFor } from 'app/util/redux-interface';
import { IModalRequest } from 'app/shared/model/modal-action';

const initialState = {
  type: null,
  props: {}
};

export type ModalState = Readonly<typeof initialState>;

function modalReducer(state: ModalState = initialState, action: IActionFor<IModalRequest>) {
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        ...state,
        type: action.payload.type,
        props: action.payload.props
      };
    }

    case HIDE_MODAL: {
      return initialState;
    }

    default:
      return state;
  }
}

export default modalReducer;
