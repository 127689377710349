import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GreenButton from 'app/components/button/Button';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { MODAL_TYPE_DETAILS_ACTIVITY } from 'app/components/modal/modal-types';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { approveActivity, generateActivitySchedules, getNotApprovedActivities, reset } from 'app/entities/activity/activity';
import { approvePartner, reset as resetPartner } from 'app/entities/partner/partner-reducer';
import { IActivity } from 'app/shared/model/activity';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { IRootState } from 'app/shared/reducers';
import _, { debounce } from 'lodash';
import * as React from 'react';
import { getPaginationItemsNumber, JhiPagination, translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Row } from 'reactstrap';
import '../../home/home.css';
import { IPriceTableActivity } from 'app/shared/model/price-table';

export interface IApproveActivitiesProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IApproveActivitiesState {
  pageRequest: IPageRequest;
  activities?: IActivity[];
  activityPage?: IPage<IActivity>;
  searchText?: string;
  partnerEmail?: string;
  isApproving: boolean;
}

export class ApproveActivities extends React.Component<IApproveActivitiesProps, IApproveActivitiesState> {
  constructor(props) {
    super(props);
    this.state = {
      pageRequest: {
        page: 0,
        size: 20
      },
      partnerEmail: '',
      isApproving: false
    };

    this.performAction = debounce(this.performAction, 500);
  }

  componentDidMount() {
    this.props.getNotApprovedActivities(this.state.pageRequest);
  }

  componentWillReceiveProps(newProps: IApproveActivitiesProps) {
    if (newProps.statusNotApprovedActivities === HttpRequestStatus.SUCCESS) {
      this.setState({
        activities: newProps.notApprovedActivities.content,
        activityPage: newProps.notApprovedActivities
      });
      this.props.reset();
    }

    if (newProps.statusApproveActivity === HttpRequestStatus.SUCCESS) {
      this.props.reset();
      toast.success(translate('approveActivities.labels.success'));
      this.props.getNotApprovedActivities(this.state.pageRequest);
      setTimeout(
        () =>
          this.setState({
            isApproving: false
          }),
        500
      );
    }

    if (newProps.approvePartnerError == true) {
      toast.error('Falha ao aprovar parceiro');
      this.props.resetPartner();
    }
    if (newProps.approvePartnerSuccess == true) {
      toast.success('Sucesso ao aprovar parceiro');
      this.props.resetPartner();
    }

    if (newProps.statusApproveActivity === HttpRequestStatus.ERROR) {
      this.props.reset();
      this.setState({
        isApproving: false
      });
    }
  }

  private performAction = (text: string) => {
    this.props.getNotApprovedActivities(this.state.pageRequest, this.state.partnerEmail);
  };

  private handlePagination = activePage => {
    const object = this.state.pageRequest;
    object.page = activePage - 1;
    this.setState({ pageRequest: object });
    this.props.getNotApprovedActivities(object);
  };

  private handleTransformToTableContent = (content?: IActivity[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];

    content.map((item: IActivity) => {
      result.push([
        item.name,
        item.partnerPlace != null ? item.partnerPlace.fantasyName : '',
        item.activityCategories != null ? _.truncate(item.activityCategories.map(it => it.name).join(','), { length: 80 }) : '',
        item.activitySubCategories != null ? _.truncate(item.activitySubCategories.map(it => it.name).join(','), { length: 80 }) : '',
        item.price != null ? `R$ ${String(item.price.toFixed(2).replace('.', ','))}` : translate('approveActivities.labels.free'),
        this.renderActionColumn(item)
      ]);
    });

    return result;
  };

  private handleApproveActivity = (activity?: IActivity, items?: IPriceTableActivity[]) => {
    if (this.state.isApproving) {
      return;
    }
    this.setState({
      isApproving: true
    });
    this.props.approveActivity(activity.id, items);
  };

  private handleApprovePartner = (partnerPlaceId?: number) => {
    if (partnerPlaceId == null) {
      toast.error('identificador do parceiro faltando');
      return;
    }
    this.props.approvePartner(partnerPlaceId);
  }

  private handleShowDetailsActivity = (activity: IActivity) => {
    this.props.showModal(MODAL_TYPE_DETAILS_ACTIVITY, {
      activity,
      onClose: this.props.hideModal,
      onApprovePartner: () => this.handleApprovePartner(activity?.partnerPlace?.id),
      onSave: (items: IPriceTableActivity[]) =>
        this.handleApproveActivity(activity, items)
    });
  };

  private renderActionColumn = (activity: IActivity | undefined): JSX.Element => {
    return (
      <div className={'action-column-container'}>
        {/* <Button className={'centered-content'} onClick={this.handleApproveActivity.bind(this, activity)}>
          <FontAwesomeIcon icon={'check'} className={'marginR1'} />
          <Translate contentKey={'approveActivities.buttons.approve'} />
        </Button> */}
        <Button className={'centered-content marginL2'} onClick={this.handleShowDetailsActivity.bind(this, activity)}>
          <FontAwesomeIcon icon={'search'} className={'marginR1'} />
          <Translate contentKey={'approveActivities.buttons.details'} />
        </Button>
      </div>
    );
  };

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state.activities);
    return (
      <div className={'partner-place-list-container'}>

        <div className="button-and-input-container">
          <GreenButton
            onClick={this.props.generateActivitySchedules}
            title={
              this.props.generateActivitySchedulesStatus !== HttpRequestStatus.ONGOING
                ? translate('approveActivities.buttons.generateHours')
                : translate('approveActivities.buttons.generateHoursLoading')
            }
            buttonLabelClassName={'header-big-select-image-button-label'}
            outerClassName={'header-big-select-image-button-background-outer-class-name'}
            innerClassName={'header-select-image-button-background-inner-class-name'}
          />

          <input
            className="search-by-partner-email-input"
            type="text"
            placeholder={translate('approveActivities.labels.seachByPartnerEmail')}
            onChange={event => {
              this.setState({ partnerEmail: event.target.value });
              this.performAction(event.target.value);
            }}
          />
        </div>

        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('approveActivities.tableHeaders')}
          emptyTableMessage={
            this.props.statusNotApprovedActivities !== HttpRequestStatus.ONGOING ? translate('approveActivities.labels.emptyList') : ''
          }
        />
        {tableContent.length > 0 && (
          <Row className={'justify-content-center'}>
            <JhiPagination
              items={
                this.state.activityPage ? getPaginationItemsNumber(this.state.activityPage.totalElements, this.state.activityPage.size) : 0
              }
              activePage={this.state.activityPage ? this.state.activityPage.number : 0}
              onSelect={this.handlePagination}
              maxButtons={5}
            />
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  statusNotApprovedActivities: storeState.activities.getNotApprovedActivities,
  statusApproveActivity: storeState.activities.approveActivity,
  generateActivitySchedulesStatus: storeState.activities.generateActivitySchedulesStatus,
  notApprovedActivities: storeState.activities.data,
  approvePartnerError: storeState.partners.approvePartnerError,
  approvePartnerSuccess: storeState.partners.approvePartnerSuccess
});

const mapDispatchToProps = { generateActivitySchedules, getNotApprovedActivities, approveActivity, reset, showModal, hideModal, resetPartner, approvePartner };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveActivities);
