export enum NotificationType {
  SCHEDULE_EDITED = 'SCHEDULE_EDITED',
  SCHEDULE_CREATED = 'SCHEDULE_CREATED',
  SCHEDULE_CANCELED = 'SCHEDULE_CANCELED',
  SCHEDULE_CREATED_EXPIRED = 'SCHEDULE_CREATED_EXPIRED',
  SCHEDULE_EDITED_EXPIRED = 'SCHEDULE_EDITED_EXPIRED',
  ENROLMENT_PROCESSING = 'ENROLMENT_PROCESSING',
  ENROLMENT_APPROVED = 'ENROLMENT_APPROVED',
  ENROLMENT_DENIED = 'ENROLMENT_DENIED',
  BILLING_PROCESSING = 'BILLING_PROCESSING',
  BILLING_APPROVED = 'BILLING_APPROVED',
  BILLING_DENIED = 'BILLING_DENIED',
  BILLING_REVERTED = 'BILLING_REVERTED',
  KEIKEN_PAY_STATUS = 'KEIKEN_PAY_STATUS',
  CUSTOMER_ACTIVITY_IN_TIME_TO_START = 'CUSTOMER_ACTIVITY_IN_TIME_TO_START',
  OTHER = 'OTHER'
}
