import React from 'react';
import { KeikenPayInvoice } from '../../../../../shared/model/KeikenPay/KeikenPayInvoice';
import { formatDate } from '../../../../../shared/util/date-utils';
import './menus.scss';

interface Props {

    data: KeikenPayInvoice;

}

export const Subsidy = ({ data }: Props) => {

    return(
        <>
            <div className="details-container">
                <div className="details-column">
                    <span>tipo:</span>
                    <div className="details-content">{data.billingCoParticipation?.type}</div>
                </div>
                <div className="details-column">
                    <span>Data:</span>
                    <div className="details-content">{formatDate(data.billingCoParticipation?.date)}</div>
                </div>
            </div>
            <div className="details-container">
                <div className="details-column">
                    <span>benefício:</span>
                    <div className="details-content">{data.billingCoParticipation?.coParticipationGroup.nameGroup}</div>
                </div>
                <div className="details-column">
                    <span>categoria:</span>
                    <div className="details-content">{data.billingCoParticipation?.coParticipationGroupActivityCategory.activityCategory?.name}</div>
                </div>
            </div>
        </>
    );
};
