import * as React from 'react';
// import { PhotoshopPicker } from 'react-color';
import { SketchPicker } from 'react-color';

const ColorPicker = ({ value, onChange }) => {
  const [color, setColor] = React.useState<string>(value ?? '#bbb');
  const handleChangeColor = (colorInput, event) => {
    setColor(colorInput?.hex);
    onChange?.(colorInput.hex);
  };
  return (
    <SketchPicker
      color={color ?? '#000'}
      disableAlpha
      presetColors={['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b']}
      onChange={handleChangeColor}
      onChangeComplete={handleChangeColor} />
  );
};

export default ColorPicker;
