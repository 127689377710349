import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForPage } from 'app/util/redux-interface';
import { KeikenPayInvoice, KeikenPayInvoiceStatus, KeikenPayUpdateDTO } from 'app/shared/model/KeikenPay/KeikenPayInvoice';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';

export const ACTION_TYPES = {
  GET_KEIKEN_PAY_INVOICE: 'keiken-pay/GET_KEIKEN_PAY_INVOICE',
  GET_KEIKEN_PAY_INVOICES: 'keiken-pay/GET_KEIKEN_PAY_INVOICES',
  UPDATE_KEIKEN_PAY_INVOICE_STATUS: 'keiken-pay/UPDATE_KEIKEN_PAY_INVOICE_STATUS',
  UPDATE_KEIKEN_PAY_INVOICE: 'keiken-pay/UPDATE_KEIKEN_PAY_INVOICE',
  RESET: 'keiken-pay/RESET'
};

const initialState = {
  getKeikenPaySuccess: false,
  getKeikenPayError: false,
  page: null,
  invoice: null,
  getInvoiceStatus: HttpRequestStatus.NOOP,
  updateInvoiceStatus: HttpRequestStatus.NOOP
};

export type IKeikenPayState = Readonly<typeof initialState>;

// Reducer
export default (state: IKeikenPayState = initialState, action: IActionForPage<KeikenPayInvoice>): IKeikenPayState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_KEIKEN_PAY_INVOICES):
      return { ...initialState };
    case FAILURE(ACTION_TYPES.GET_KEIKEN_PAY_INVOICES):
      return { ...initialState, getKeikenPayError: true };
    case SUCCESS(ACTION_TYPES.GET_KEIKEN_PAY_INVOICES):
      return { ...initialState, getKeikenPaySuccess: true, page: action.payload.data };
    case REQUEST(ACTION_TYPES.GET_KEIKEN_PAY_INVOICE):
      return { ...initialState, getInvoiceStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.GET_KEIKEN_PAY_INVOICE):
      return { ...initialState, getInvoiceStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.GET_KEIKEN_PAY_INVOICE):
      return { ...initialState, getInvoiceStatus: HttpRequestStatus.SUCCESS, invoice: action.payload.data };
    case REQUEST(ACTION_TYPES.UPDATE_KEIKEN_PAY_INVOICE_STATUS):
      return { ...initialState, updateInvoiceStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.UPDATE_KEIKEN_PAY_INVOICE_STATUS):
      return { ...initialState, updateInvoiceStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.UPDATE_KEIKEN_PAY_INVOICE_STATUS):
      return { ...initialState, updateInvoiceStatus: HttpRequestStatus.SUCCESS, invoice: action.payload.data };
    case REQUEST(ACTION_TYPES.UPDATE_KEIKEN_PAY_INVOICE):
      return { ...initialState, updateInvoiceStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.UPDATE_KEIKEN_PAY_INVOICE):
      return { ...initialState, updateInvoiceStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.UPDATE_KEIKEN_PAY_INVOICE):
      return { ...initialState, updateInvoiceStatus: HttpRequestStatus.SUCCESS, invoice: action.payload.data };
    case ACTION_TYPES.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

const apiUrl = '/api/keiken-pay-invoice';

export const getKeikenPayInvoices = (queryParams?: any) => {
  const params = {
    size: queryParams?.size ?? 20,
    page: queryParams?.page ?? 0,
    sort: 'id,desc',
    ['customer.id']: queryParams.customerId,
    ['customer.name']: queryParams.name,
    ['customer.user.email']: queryParams?.email,
    ['status']: queryParams?.status
  };
  return {
    type: ACTION_TYPES.GET_KEIKEN_PAY_INVOICES,
    payload: axios.get(`${apiUrl}`, { params })
  };
};
export const getKeikenPayInvoice = (id: number) => {
  return {
    type: ACTION_TYPES.GET_KEIKEN_PAY_INVOICE,
    payload: axios.get(`${apiUrl}/${id}`)
  };
};

export const updateKeikenPayInvoiceStatus = (id: number, status: KeikenPayInvoiceStatus, reason: string | undefined, shouldPay: boolean | undefined, customPixKey: string | undefined) => {
  return {
    type: ACTION_TYPES.UPDATE_KEIKEN_PAY_INVOICE_STATUS,
    payload: axios.put(`${apiUrl}/${id}/status`, { id, status, reason, shouldPay, customPixKey})
  };
};

export const updateKeikenPayInvoice = (keikenPayInvoice: KeikenPayUpdateDTO) => {
  return {
    type: ACTION_TYPES.UPDATE_KEIKEN_PAY_INVOICE,
    payload: axios.put(`${apiUrl}/${keikenPayInvoice.id}`, keikenPayInvoice)
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
