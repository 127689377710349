import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { ICompanyReport } from 'app/shared/model/company';

export const ACTION_TYPES = {
  GET_COMPANY_REPORTS: 'companyReport/GET_COMPANY_REPORTS',
  CREATE_COMPANY_REPORT: 'companyReport/POST_COMPANY_REPORT',
  DELETE_COMPANY_REPORT: 'companyReport/DELETE_COMPANY_REPORT',
  RESET: 'companyReport/RESET'
};

export interface ICompanyReportState {
  readonly getCompanyReportStatus: HttpRequestStatus;
  readonly createCompanyReportStatus: HttpRequestStatus;
  readonly deleteCompanyReportStatus: HttpRequestStatus;
  readonly companyReports: IPage<ICompanyReport>;
}

const initialState: ICompanyReportState = {
  // status
  getCompanyReportStatus: HttpRequestStatus.NOOP,
  createCompanyReportStatus: HttpRequestStatus.NOOP,
  deleteCompanyReportStatus: HttpRequestStatus.NOOP,
  // contents
  companyReports: null
};

// Reducer
export default (state: ICompanyReportState = initialState, action: any): ICompanyReportState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_COMPANY_REPORTS):
      return { ...state, getCompanyReportStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.GET_COMPANY_REPORTS):
      return { ...state, getCompanyReportStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.GET_COMPANY_REPORTS):
      return { ...state, getCompanyReportStatus: HttpRequestStatus.SUCCESS, companyReports: action.payload.data };
    case REQUEST(ACTION_TYPES.CREATE_COMPANY_REPORT):
      return { ...state, createCompanyReportStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.CREATE_COMPANY_REPORT):
      return { ...state, createCompanyReportStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.CREATE_COMPANY_REPORT):
      return { ...state, createCompanyReportStatus: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.DELETE_COMPANY_REPORT):
      return { ...state, deleteCompanyReportStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.DELETE_COMPANY_REPORT):
      return { ...state, deleteCompanyReportStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.DELETE_COMPANY_REPORT):
      return { ...state, deleteCompanyReportStatus: HttpRequestStatus.SUCCESS };
    case ACTION_TYPES.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

const apiUrl = '/api/companies';

export const getCompanyReports = (page: IPageRequest, companyId: number) => ({
  type: ACTION_TYPES.GET_COMPANY_REPORTS,
  payload: axios.get(`${apiUrl}/${companyId}/company-reports?size=${page.size}&page=${page.page}`)
});

export const createCompanyReport = (companyId: number, payload: any) => ({
  type: ACTION_TYPES.CREATE_COMPANY_REPORT,
  payload: axios.post(`${apiUrl}/${companyId}/company-reports`, payload)
});

export const deleteCompanyReport = (companyId: number, companyReportId: number) => ({
  type: ACTION_TYPES.DELETE_COMPANY_REPORT,
  payload: axios.delete(`/api/company-report/${companyReportId}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
