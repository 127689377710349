import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import './forgot-password.css';
import { IRootState } from 'app/shared/reducers';
import InputField from 'app/components/input-field/InputField';
import { Translate, translate } from 'react-jhipster';
import Button from 'app/components/button/Button';
import { handlePasswordResetInit, reset } from '../../account/password-reset/password-reset.reducer';
import StringUtils from 'app/util/StringUtils';
export interface IForgotPasswordProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IForgotPasswordState {
  email?: string;

  errors?: IForgotPasswordError;
}

export interface IForgotPasswordError {
  emailError?: boolean;
}

export class ForgotPassword extends React.Component<IForgotPasswordProps, IForgotPasswordState> {
  constructor(props: IForgotPasswordProps) {
    super(props);

    this.state = {
      errors: {}
    };
  }

  validateFields = () => {
    let allFieldsAreValid = true;
    if (!StringUtils.isEmailValid(this.state.email)) {
      allFieldsAreValid = false;
      this.setState(prevState => ({ ...prevState, errors: { ...prevState.errors, emailError: true } }));
    }
    return allFieldsAreValid;
  };

  componentWillUnmount() {
    this.props.reset();
  }

  handleSubmit = () => {
    if (!this.validateFields()) {
      return;
    }

    this.props.handlePasswordResetInit(this.state.email);
  };

  handleEmailChanged = (text: string, propertyToChange: string, errorToChange?: string) => {
    this.setState(prevState => ({ ...prevState, email: text }));
  };

  render() {
    return (
      <div className={'forgot-password-container'}>
        <img src="content/images/forgot-password/background.png" className={'forgot-password-banner'} />

        <div className={'forgot-password-center-modal'}>
          <div className={'forgot-password-header'}>
            <label className={'label-title'}>
              <Translate contentKey="password.title" />
            </label>
            <label className={'label-text'}>
              <Translate contentKey="password.input.text" />
            </label>
          </div>

          <div className={'login-input-field-container mb-5'}>
            <img className={'login-input-field-img'} src={'content/images/ic-email-grey.png'} />

            <form>
              <InputField
                placeholder={'password.input.email'}
                value={!!this.state.email ? this.state.email : ''}
                error={this.state.errors.emailError}
                onChange={event => this.handleEmailChanged(event.target.value, 'email', 'emailError')}
                inputClassName={'input'}
              />
            </form>
          </div>

          <Button onClick={this.handleSubmit} title={translate('password.button.submit')} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = { handlePasswordResetInit, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
