import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { getPaginationItemsNumber, JhiPagination, translate, Translate } from 'react-jhipster';
import { Row, Button } from 'reactstrap';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { debounce } from 'lodash';
import { MODAL_TYPE_WARNING } from 'app/components/modal/modal-types';
// import './partner-place-list.scss';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { IActivityCategory } from 'app/shared/model/activity-category';
import { fetchAllActivityCategories, deleteActivityCategory, reset } from 'app/entities/activity-category/activity-category-reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import InputField from 'app/components/input-field/InputField';
import { toast } from 'react-toastify';

export interface ICategoryListProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface ICategoryListState {
  pageRequest: IPageRequest;
  categories?: IActivityCategory[];
  categoryPage?: IPage<IActivityCategory>;
  searchText?: string;
}

export class ICategoryList extends React.Component<ICategoryListProps, ICategoryListState> {
  constructor(props) {
    super(props);
    this.state = {
      pageRequest: {
        page: 0,
        size: 20
      }
    };
    this.performAction = debounce(this.performAction, 500);
  }

  componentDidMount() {
    this.props.fetchAllActivityCategories(this.state.pageRequest);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.getCategoryList === HttpRequestStatus.SUCCESS) {
      this.setState({
        categories: newProps.page.content,
        categoryPage: newProps.page
      });
      this.props.reset();
    }

    if (newProps.deleteCategory === true) {
      this.props.fetchAllActivityCategories(this.state.pageRequest);
      this.props.hideModal();
      this.props.reset();
    }
  }

  private performAction = (text: string) => {
    this.props.fetchAllActivityCategories(null, text);
  };

  private handlePagination = activePage => {
    const object = this.state.pageRequest;
    object.page = activePage - 1;
    this.setState({ pageRequest: object });
    this.props.fetchAllActivityCategories(object);
  };

  private handleTransformToTableContent = (content?: IActivityCategory[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IActivityCategory) => {
      result.push([
        item.name,
        item.status,
        item.type,
        this.renderActionColumn(item)
      ]);
    });
    return result;
  };

  private handleDeleteCategory = (category: IActivityCategory | undefined) => {
    this.props.showModal(MODAL_TYPE_WARNING, {
      title: translate('categoryList.modal.message', { categoryName: category.name }),
      confirmButtonText: 'categoryList.buttons.delete',
      cancelCallback: this.handleOnWarningCallbackPressed,
      confirmCallback: () => this.handleOnConfirmCallbackPressed(category)
    });
  };

  handleOnWarningCallbackPressed = () => {
    this.props.hideModal();
  };

  handleOnConfirmCallbackPressed = (category: IActivityCategory | undefined) => {
    this.props.deleteActivityCategory(category.id);
  };

  private handleEditCategory = (category: IActivityCategory | undefined) => {
    this.props.history.push({
      pathname: 'category-manage',
      state: {
        activityCategory: category,
        editCategory: true
      }
    });
  };

  private handleCreateCategory = () => {
    this.props.history.push({
      pathname: 'category-manage',
      state: {
        editCategory: false
      }
    });
  };

  private handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState(
      {
        searchText: value
      },
      () => this.performAction(this.state.searchText)
    );
  };

  private renderActionColumn = (category: IActivityCategory | undefined): JSX.Element => {
    return (
      <div className={'action-column-container'}>
        <Button className={'centered-content'} onClick={this.handleEditCategory.bind(this, category)}>
          <FontAwesomeIcon icon={'check'} className={'marginR1'} />
          <Translate contentKey={'categoryList.buttons.edit'} />
        </Button>
        <Button className={'centered-content marginL2'} onClick={this.handleDeleteCategory.bind(this, category)}>
          <FontAwesomeIcon icon={'trash'} className={'marginR1'} />
          <Translate contentKey={'categoryList.buttons.delete'} />
        </Button>
      </div>
    );
  };

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state.categories);
    return (
      <div className={'partner-place-list-container'}>
        <div className="subcategories-list-search-container">
          <div onClick={this.handleCreateCategory} className={'subcategories-list-button-display-container'}>
            <img className="partner-dashboard-img" src="content/images/ic-plus-grassgreen.png" />
            <label className={'partner-dashboard-button-label'}>{translate('categoryList.buttons.create')}</label>
          </div>
          <div className={'subcategories-list-input-display'}>
            <InputField
              title={translate('categoryList.placeHolders.search')}
              onChange={this.handleChanged}
              inputClassName={''}
              value={!!this.state.searchText ? this.state.searchText : ''}
              placeholder={'categoryList.placeHolders.search'}
            />
          </div>
        </div>
        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('categoryList.tableHeaders')}
          emptyTableMessage={this.props.getCategoryList !== HttpRequestStatus.ONGOING ? translate('categoryList.labels.emptyList') : ''}
        />
        {tableContent.length > 0 && (
          <Row className={'justify-content-center'}>
            <JhiPagination
              items={
                this.state.categoryPage ? getPaginationItemsNumber(this.state.categoryPage.totalElements, this.state.categoryPage.size) : 0
              }
              activePage={this.state.categoryPage ? this.state.categoryPage.number : 0}
              onSelect={this.handlePagination}
              maxButtons={5}
            />
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  page: storeState.activityCategories.page,
  getCategoryList: storeState.activityCategories.getCategoryList,
  deleteCategory: storeState.activityCategories.deleteCategory
});

const mapDispatchToProps = { fetchAllActivityCategories, deleteActivityCategory, showModal, hideModal, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ICategoryList);
