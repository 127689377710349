import React from 'react';
import './openMenuItem.css';
import classnames from 'classnames';

export interface IOpenMenuItemProps {
  title: string;
  isPressed?: boolean;
  image: string;
  onPress?: () => void;
  showOnlyIcon?: boolean;
}

export class OpenMenuItem extends React.Component<IOpenMenuItemProps, {}> {
  mapBackgroundClassName = () => {
    if (!!this.props.isPressed) {
      return classnames('open-menu-item-container', 'open-menu-item-container-clicked');
    }

    return 'open-menu-item-container';
  };

  render() {
    return (
      <div className={this.mapBackgroundClassName()} onClick={() => (!!this.props.onPress ? this.props.onPress() : void 0)}>
        {!this.props.showOnlyIcon && (
          <div className={'open-menu-item-title-and-image-container'}>
            <img src={this.props.image} />
            <label className={'open-menu-item-title'}>{this.props.title}</label>
          </div>
        )}
        {this.props.showOnlyIcon && (
          <div className={'open-menu-item-title-and-image-container'}>
            <img src={this.props.image} />
          </div>
        )}
        {this.props.isPressed && <div className={'open-menu-item-selected-bar'} />}
      </div>
    );
  }
}

export default OpenMenuItem;
