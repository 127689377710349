import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { EconomicActivity } from 'app/shared/model/KeikenPay/EconomicActivity';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForPage } from 'app/util/redux-interface';
import axios from 'axios';

export const ACTION_TYPES = {
    GET_ECONOMIC_ACTIVITY: 'economic-activity/GET_ECONOMIC_ACTIVITY',
    RESET: 'economic-activity/RESET'
  };

const initialState = {
    page: null,
    getEconomicActivity: HttpRequestStatus.NOOP
};

export type IEconomicActivityState = Readonly<typeof initialState>;

export default (state: IEconomicActivityState = initialState, action: IActionForPage<EconomicActivity>): IEconomicActivityState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.GET_ECONOMIC_ACTIVITY):
            return {...initialState, getEconomicActivity: HttpRequestStatus.ONGOING};
        case FAILURE(ACTION_TYPES.GET_ECONOMIC_ACTIVITY):
            return {...initialState, getEconomicActivity: HttpRequestStatus.ERROR};
        case SUCCESS(ACTION_TYPES.GET_ECONOMIC_ACTIVITY):
            return {...initialState, getEconomicActivity: HttpRequestStatus.SUCCESS, page: action.payload.data};
        case ACTION_TYPES.RESET: return {...initialState};
        default:
          return state;
    }
  };

  const apiUrl = '/api/economic-activities';

  export const getEconomicActivity = (queryParams?: any, text?: string) => {
      const params = {
          text,
          size: queryParams?.size ?? 20,
          page: queryParams.page ?? 0
      };
      return {
          type: ACTION_TYPES.GET_ECONOMIC_ACTIVITY,
          payload: axios.get(`${apiUrl}`, { params })
      };
  };

  export const reset = () => ({
    type: ACTION_TYPES.RESET
  });
