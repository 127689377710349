import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity, IActionForPage } from 'app/util/redux-interface';
import { IBank } from 'app/shared/model/bank-account';

export const ACTION_TYPES = {
  GET_BANKS: 'banks/GET_BANKS',
  RESET: 'banks/RESET'
};

const initialState = {
  getBankSuccess: false,
  getBankError: false,
  page: null
};

export type IBankState = Readonly<typeof initialState>;

// Reducer
export default (state: IBankState = initialState, action: IActionForPage<IBank>): IBankState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_BANKS):
      return {
        ...initialState
      };
    case FAILURE(ACTION_TYPES.GET_BANKS):
      return {
        ...initialState,
        getBankError: true
      };
    case SUCCESS(ACTION_TYPES.GET_BANKS):
      return {
        ...initialState,
        getBankSuccess: true,
        page: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/banks';

export const getBanks = (page?: any) => {
  const params = {
    size: page != null ? page.size : 20,
    page: page != null ? page.page : 0
  };
  return {
    type: ACTION_TYPES.GET_BANKS,
    payload: axios.get(`${apiUrl}`, { params })
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
