import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import './forgot-password-finish.css';
import { IRootState } from 'app/shared/reducers';
import InputField from 'app/components/input-field/InputField';
import { Translate, translate } from 'react-jhipster';
import Button from 'app/components/button/Button';
import { handlePasswordResetFinish, reset } from '../../account/password-reset/password-reset.reducer';
import StringUtils from 'app/util/StringUtils';
import { text } from '@fortawesome/fontawesome-svg-core';

export interface IForgotPasswordFinishProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ key: string }, any, { from: any } | any> {}

export interface IForgotPasswordFinishState {
  key?: string;
  password?: string;
  confirmPassword?: string;
  errors?: IForgotPasswordFinishError;
}

export interface IForgotPasswordFinishError {
  passwordError?: boolean;
}

export class ForgotPasswordFinish extends React.Component<IForgotPasswordFinishProps, IForgotPasswordFinishState> {
  constructor(props: IForgotPasswordFinishProps) {
    super(props);

    this.state = {
      key: this.props.match.params.key,
      errors: {}
    };
  }

  validateFields = () => {
    let allFieldsAreValid = true;

    if (
      StringUtils.isStringInvalid(this.state.password) ||
      this.state.password.length < 4 ||
      this.state.password !== this.state.confirmPassword
    ) {
      allFieldsAreValid = false;
      this.setState(prevState => ({ ...prevState, errors: { ...prevState.errors, passwordError: true } }));
    }

    return allFieldsAreValid;
  };

  componentWillUnmount() {
    this.props.reset();
  }

  handleSubmit = () => {
    if (!this.validateFields()) {
      return;
    }
    const result = this.props.handlePasswordResetFinish(this.state.key, this.state.confirmPassword);
  };

  handlePasswordChanged = (newText: string, propertyToChange: string, errorToChange?: string) => {
    this.setState(prevState => ({ ...prevState, password: newText }));
  };

  handleConfirmPasswordChanged = (newText: string) => {
    this.setState(prevState => ({ ...prevState, confirmPassword: newText }));
  };

  render() {
    const { location, resetPasswordSuccess } = this.props;
    const { from } = location.state || { from: { pathname: '/login', search: location.search } };

    if (resetPasswordSuccess) {
      return <Redirect to={from} />;
    }
    return (
      <div className={'login-container'}>
        <img src="content/images/forgot-password/background.png" className={'forgot-password-finish-banner'} />

        <div className={'login-center-modal'}>
          <div className={'forgot-password-finish-header'}>
            <label className={'label-title'}>
              <Translate contentKey="reset.finish.title" />
            </label>
            <label className={'label-text'}>
              <Translate contentKey="reset.finish.text" />
            </label>
          </div>

          <div className={'login-input-field-container'}>
            <img className={'login-input-field-img'} src={'content/images/ic-lock-grey.png'} />

            <form>
              <InputField
                placeholder={'reset.finish.fields.password'}
                value={!!this.state.password ? this.state.password : ''}
                error={this.state.errors.passwordError}
                onChange={event => this.handlePasswordChanged(event.target.value, 'password', 'passwordError')}
                inputClassName={'input'}
                type={'password'}
              />
            </form>
          </div>

          <div className={'login-input-field-container mb-5 mt-4'}>
            <img className={'login-input-field-img'} src={'content/images/ic-lock-grey.png'} />

            <InputField
              placeholder={'reset.finish.fields.new_password'}
              onChange={event => this.handleConfirmPasswordChanged(event.target.value)}
              error={this.state.errors.passwordError}
              value={!!this.state.confirmPassword ? this.state.confirmPassword : ''}
              inputClassName={'input'}
              type={'password'}
            />
          </div>

          <Button onClick={this.handleSubmit} title={translate('password.button.submit')} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ passwordReset }: IRootState) => ({
  resetPasswordSuccess: passwordReset.resetPasswordSuccess
});
const mapDispatchToProps = { handlePasswordResetFinish, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordFinish);
