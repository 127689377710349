import * as React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { getPaginationItemsNumber, JhiPagination, translate, Translate, getUrlParameter } from 'react-jhipster';
import { Row, Col, Button } from 'reactstrap';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { debounce } from 'lodash';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StringUtils from 'app/util/StringUtils';
import { getKeikenBankAccounts, reset as resetKeikenBankAccounts } from 'app/entities/bank-account/bank-account-reducer';
import { getBillings, reset } from 'app/entities/billing/billing.reducer';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import { BillingStatus, IBilling } from 'app/shared/model/billing/billing';
import { formatDate, formatTimestamp } from 'app/shared/util/date-utils';
import './billings-list.scss';
import { IKeikenBankAccount } from 'app/shared/model/billing/keiken-bank-accounts';

export interface IBillingsListProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IBillingsListState {
  pageRequest?: IPageRequest;
  billings?: IPage<IBilling>;
  keikenBankAccounts?: IKeikenBankAccount[];
  filter?: {
    status?: BillingStatus
  };
}

export class IBillingsList extends React.Component<IBillingsListProps, IBillingsListState> {
  filesInput = null;
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      pageRequest: {
        page: 0,
        size: 20
      }
    };
    this.performAction = debounce(this.performAction, 500);
  }

  private performAction = () => {
    this.props.getBillings(this.state.pageRequest, this.state.filter);
    const params = qs.stringify({ ...this.state.pageRequest, ...this.state.filter }, { skipNulls: true });
    this.props.history.push({ search: params });
  };

  componentDidMount() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { page, size } = params;
    const { status } = params;

    this.setState({
      pageRequest: {
        page,
        size
      },
      filter: { status }
    }, () => {
      this.performAction();
    });

    this.props.getKeikenBankAccounts();
  }

  componentWillReceiveProps(newProps: IBillingsListProps) {
    if (newProps.billings != null) {
      this.setState({
        billings: newProps.billings
      }, () => {
        this.props.reset();
      });
    }
    if (newProps.keikenBankAccounts != null) {
      this.setState({
        keikenBankAccounts: newProps.keikenBankAccounts
      }, () => {
        this.props.resetKeikenBankAccounts();
      });
    }
  }

  private handleOnSelectFilterStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    const value = event.target.value as BillingStatus | 'NULL';
    this.setState({
      filter: {
        ...this.state.filter,
        status: value != 'NULL' ? value : undefined
      }
    }, () => this.performAction());
  };

  private handlePagination = activePage => {
    const pageRequest = this.state.pageRequest;
    pageRequest.page = activePage - 1;
    this.setState({
      pageRequest
    }, () => {
      this.performAction();
    });
  };

  private handleTransformToTableContent = (content?: IBilling[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IBilling) => {
      const customer = item.customer?.name;
      const date = formatTimestamp(item?.date);
      const name = item?.activityPlan != null ? item.activityPlan?.name : item.activitySchedules?.[0]?.activity?.name;
      const partnerPlace = item?.activityPlan != null ? item.activityPlan?.partnerPlace : item.activitySchedules?.[0]?.activity?.partnerPlace;
      const fantasyName = partnerPlace?.fantasyName;
      result.push([
        customer,
        fantasyName,
        date,
        item.paymentMethod,
        StringUtils.currencyPtBr(item?.value),
        StringUtils.currencyPtBr(item?.billingCoParticipation?.subsidyValue ?? 0),
        item?.status,
        item?.activityPlan != null ? 'Plano' : 'Agendamento',
        name,
        this.renderActionColumn(item)
      ]);
    });
    return result;
  };

  private handleEdit = (billing: IBilling | undefined) => {
    this.props.history.push({
      pathname: `billings/${billing.id}`,
      state: {
        billing,
        edit: true
      }
    });
  };

  private renderActionColumn = (billing: IBilling | undefined): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <Button color="info" className={'centered-content margin1'} onClick={this.handleEdit.bind(this, billing)}>
          <FontAwesomeIcon icon={'eye'} />
          {/* <Translate contentKey={'billingsList.buttons.details'} /> */}
        </Button>
      </div>
    );
  };

  render() {
    const billingStatusOptions = [
      BillingStatus.CREATED,
      BillingStatus.WAITING,
      BillingStatus.PAID,
      BillingStatus.NOT_PAID,
      BillingStatus.REVERTED
    ];
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state?.billings?.content ?? []);
    return (
      <div className={'partner-place-list-container'}>
        <div className={'table-search-container'}>
          {/* <div onClick={() => { }} className={'subcompanies-list-button-display-container'}>
            <img className={'partner-dashboard-img'} src={'content/images/ic-plus-grassgreen.png'} />
            <label className={'partner-dashboard-button-label'}>{translate('billingsList.buttons.create')}</label>
          </div> */}
          <Row>
            <Col>
              <p>{'accont'}</p>
              <p>{'balance'}</p>
              <p>{'incoming'}</p>
              <p>{'outcoming'}</p>
            </Col>
            {this.state?.keikenBankAccounts?.map?.((it, index) => {
              return (
                <Col key={`keiken-bank-${it.id}`} className="values">
                  <p>{it.type}</p>
                  <p >{StringUtils.currencyPtBr(it?.balance)}</p>
                  <p className="incoming">{it?.incoming != null ? StringUtils.currencyPtBr(it?.incoming) : 'n/a'}</p>
                  <p className="outgoing">{it?.outgoing != null ? StringUtils.currencyPtBr(it?.outgoing) : 'n/a'}</p>
                </Col>
              );
            })}
          </Row>
        </div>
        <Row>
          <Col className="filters">
            <div>
              <span className="label">Filtrar por Status</span>
              <select name="billing-filter-status" onChange={this.handleOnSelectFilterStatus} value={this.state.filter.status}>
                <option value={'NULL'}>Todos</option>
                {billingStatusOptions.map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))}
              </select>
            </div>
          </Col>
        </Row>

        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('keikenPayList.tableHeaders')}
          emptyTableMessage={translate('keikenPayList.labels.emptyList')}
          textOverflow
        />
        {tableContent.length > 0 && (
          <Row className={'justify-content-center'}>
            <JhiPagination
              items={
                this.state.billings
                  ? getPaginationItemsNumber(this.state.billings.totalElements, this.state.billings.size)
                  : 0
              }
              activePage={this.state.billings ? this.state.billings.number : 1}
              onSelect={this.handlePagination}
              maxButtons={5}
            />
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  billings: storeState.billing.billings,
  status: storeState.billing.status,
  keikenBankAccounts: storeState.bankAccounts.keikenBankAccounts
});

const mapDispatchToProps = {
  reset,
  showModal,
  hideModal,
  getBillings,
  getKeikenBankAccounts,
  resetKeikenBankAccounts
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IBillingsList);
