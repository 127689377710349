import { ICustomerActivity } from '../../../app/shared/model/customer-activity.model';
import { HttpRequestStatus } from '../../../app/shared/model/enum/HttpRequestStatus';
import { IPageRequest } from '../../../app/shared/model/page.model';
import { FAILURE, REQUEST, SUCCESS } from '../../../app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from '../../../app/util/redux-interface';
import axios from 'axios';

export const ACTION_TYPES = {
  UPDATE_TOKEN_USED_BY_ADMIN: 'customerActivity/UPDATE_TOKEN_USED_BY_ADMIN',
  GET_CUSTOMER_ACTIVITY_ME: 'customerActivity/GET_CUSTOMER_ACTIVITY_ME',
  GET_CUSTOMER_ACTIVITY_BY_TOKEN_ID: 'customerActivity/GET_CUSTOMER_ACTIVITY_BY_TOKEN_ID',
  GET_CUSTOMER_ACTIVITY_TO_APPROVE: 'customerActivity/GET_CUSTOMER_ACTIVITY_TO_APPROVE',
  GET_CUSTOMER_ACTIVITY_APPROVED: 'customerActivity/GET_CUSTOMER_ACTIVITY_APPROVED',
  CHANGE_STATUS: 'customerActivity/CHANGE_STATUS',
  USE_CUSTOMER_ACTIVITY_TOKEN: 'customerActivity/USE_CUSTOMER_ACTIVITY_TOKEN',
  RESCHEDULE_ACTIVITY_SCHEDULE: 'customerActivity/RESCHEDULE_ACTIVITY_SCHEDULE',
  DELETE_CUSTOMER_ACTIVITY: 'customerActivity/DELETE_CUSTOMER_ACTIVITY',
  RESET: 'customerActivity/RESET'
};

const initialState = {
  getCustomerActivityByTokenStatus: HttpRequestStatus.NOOP,
  changeStatus: HttpRequestStatus.NOOP,
  deleteStatus: HttpRequestStatus.NOOP,
  changeScheduleStatus: HttpRequestStatus.NOOP,
  useCustomerActivityTokenStatus: HttpRequestStatus.NOOP,
  getCustomerActivityToApproveStatus: HttpRequestStatus.NOOP,
  getCustomerActivityApprovedStatus: HttpRequestStatus.NOOP,
  updateTokenUsedByAdminStatus: HttpRequestStatus.NOOP,
  customerActivities: null,
  customerActivity: null
};

export type ICustomerActivityState = Readonly<typeof initialState>;

// Reducer
export default (
  state: ICustomerActivityState = initialState,
  action: IActionForResponseEntity<ICustomerActivity>
): ICustomerActivityState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_BY_TOKEN_ID):
      return {
        ...initialState,
        getCustomerActivityByTokenStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_BY_TOKEN_ID):
      return {
        ...initialState,
        getCustomerActivityByTokenStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_BY_TOKEN_ID):
      return {
        ...initialState,
        getCustomerActivityByTokenStatus: HttpRequestStatus.SUCCESS,
        customerActivity: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_TO_APPROVE):
      return {
        ...initialState,
        getCustomerActivityToApproveStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_TO_APPROVE):
      return {
        ...initialState,
        getCustomerActivityToApproveStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_TO_APPROVE):
      return {
        ...initialState,
        getCustomerActivityToApproveStatus: HttpRequestStatus.SUCCESS,
        customerActivities: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_APPROVED):
      return {
        ...initialState,
        getCustomerActivityApprovedStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_APPROVED):
      return {
        ...initialState,
        getCustomerActivityApprovedStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_APPROVED):
      return {
        ...initialState,
        getCustomerActivityApprovedStatus: HttpRequestStatus.SUCCESS,
        customerActivities: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_ME):
      return {
        ...initialState,
        getCustomerActivityApprovedStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_ME):
      return {
        ...initialState,
        getCustomerActivityApprovedStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_ME):
      return {
        ...initialState,
        getCustomerActivityApprovedStatus: HttpRequestStatus.SUCCESS,
        customerActivities: action.payload.data
      };
    case REQUEST(ACTION_TYPES.CHANGE_STATUS):
      return {
        ...initialState,
        changeStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.CHANGE_STATUS):
      return {
        ...initialState,
        changeStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.CHANGE_STATUS):
      return {
        ...initialState,
        changeStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.USE_CUSTOMER_ACTIVITY_TOKEN):
      return {
        ...initialState,
        useCustomerActivityTokenStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.USE_CUSTOMER_ACTIVITY_TOKEN):
      return {
        ...initialState,
        useCustomerActivityTokenStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.USE_CUSTOMER_ACTIVITY_TOKEN):
      return {
        ...initialState,
        useCustomerActivityTokenStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.RESCHEDULE_ACTIVITY_SCHEDULE):
      return {
        ...initialState,
        changeScheduleStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.RESCHEDULE_ACTIVITY_SCHEDULE):
      return {
        ...initialState,
        changeScheduleStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.RESCHEDULE_ACTIVITY_SCHEDULE):
      return {
        ...initialState,
        changeScheduleStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.DELETE_CUSTOMER_ACTIVITY):
      return {
        ...state,
        deleteStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.DELETE_CUSTOMER_ACTIVITY):
      return {
        ...state,
        deleteStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.DELETE_CUSTOMER_ACTIVITY):
      return {
        ...state,
        deleteStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.UPDATE_TOKEN_USED_BY_ADMIN):
      return {
        ...state,
        updateTokenUsedByAdminStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_TOKEN_USED_BY_ADMIN):
      return {
        ...state,
        updateTokenUsedByAdminStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_TOKEN_USED_BY_ADMIN):
      return {
        ...state,
        updateTokenUsedByAdminStatus: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/customer-activities';

export const getCustomerActivityMe = () => ({
  type: ACTION_TYPES.GET_CUSTOMER_ACTIVITY_ME,
  payload: axios.get(`${apiUrl}/me`)
});

export const getCustomerActivityByToken = (token: string) => ({
  type: ACTION_TYPES.GET_CUSTOMER_ACTIVITY_BY_TOKEN_ID,
  payload: axios.get(`${apiUrl}/find-by-token?token=${token}`)
});

export const getCustomerActivityToApprove = (page: IPageRequest) => ({
  type: ACTION_TYPES.GET_CUSTOMER_ACTIVITY_TO_APPROVE,
  payload: axios.get(`${apiUrl}/not-approved?size=${page.size}&page=${page.page}`)
});

export const getCustomerActivityApproved = (page: IPageRequest) => ({
  type: ACTION_TYPES.GET_CUSTOMER_ACTIVITY_APPROVED,
  payload: axios.get(`${apiUrl}/approved?size=${page.size}&page=${page.page}`)
});

export const changeStatus = (customer: ICustomerActivity) => ({
  type: ACTION_TYPES.CHANGE_STATUS,
  payload: axios.put(`${apiUrl}/change-status`, customer)
});

export const deleteCustomerActivity = (customerActivity: ICustomerActivity) => ({
  type: ACTION_TYPES.DELETE_CUSTOMER_ACTIVITY,
  payload: axios.delete(`${apiUrl}/${customerActivity.id}`)
});

export const useCustomerActivityToken = (customerActivityId: number) => ({
  type: ACTION_TYPES.USE_CUSTOMER_ACTIVITY_TOKEN,
  payload: axios.post(`${apiUrl}/use-token`, { id: customerActivityId })
});

export const updateTokenUsedByAdmin = (customerActivityId: number, tokenUsed: boolean) => ({
  type: ACTION_TYPES.UPDATE_TOKEN_USED_BY_ADMIN,
  payload: axios.put(`${apiUrl}/token-used/admin`, { id: customerActivityId, tokenUsed })
});

export const rescheduleActivitySchedules = (customerActivityId: number, activityScheduleId: number) => ({
  type: ACTION_TYPES.RESCHEDULE_ACTIVITY_SCHEDULE,
  payload: axios.put(`api/mobile/customer-activities/${customerActivityId}/reschedule-activity/${activityScheduleId}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
