import * as React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { getPaginationItemsNumber, JhiPagination, Translate, translate } from 'react-jhipster';
import { Row, Col, Button } from 'reactstrap';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { debounce, max } from 'lodash';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchCustomerList, resetCustomer } from 'app/entities/customer/customer-redux';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import './customers-list.scss';
import { CustomerStatus, ICustomer } from 'app/shared/model/customer/customer';
import InputField from 'app/components/input-field/InputField';

export interface ICustomerListProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface ICustomerListState {
  pageRequest?: IPageRequest;
  customers?: IPage<ICustomer>;
  filter?: {
    email?: string,
    cpf?: string,
    status?: CustomerStatus,
    blocked?: 'BLOCKED'
  };
}

export class ICustomerList extends React.Component<ICustomerListProps, ICustomerListState> {
  filesInput = null;
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      pageRequest: {
        page: 0,
        size: 20
      }
    };
    this.performAction = debounce(this.performAction, 500);
  }

  private performAction = () => {
    this.props.fetchCustomerList(this.state.pageRequest, this.state.filter?.email, this.state.filter?.cpf, 'id,desc',
      this.state.filter?.status, this.state.filter?.blocked);
    const params = qs.stringify({ ...this.state.pageRequest, ...this.state.filter }, { skipNulls: true });
    this.props.history.push({ search: params });
  };

  componentDidMount() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { page, size } = params;

    this.setState({
      pageRequest: {
        page: page ?? this.state.pageRequest.page,
        size: size ?? this.state.pageRequest.size
      },
      filter: {}
    }, () => {
      this.performAction();
    });

  }

  componentWillReceiveProps(newProps: ICustomerListProps) {
    if (newProps.customers != null) {
      this.setState({
        customers: newProps.customers
      }, () => {
        this.props.resetCustomer();
      });
    }
  }

  private handlePagination = (activePage = 1) => {
    const pageRequest = this.state.pageRequest;
    pageRequest.page = max([activePage - 1, 0]);
    this.setState({
      pageRequest
    }, () => {
      this.performAction();
    });
  };
  private handleFilterCustomerStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    const value = event.target.value as CustomerStatus | 'NULL';
    this.setState({
      filter: {
        ...this.state.filter,
        status: value != 'NULL' ? value : undefined
      }
    }, () => this.performAction());
  };

  private handleFilterCustomerBlocked = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    const value = event.target.value as 'BLOCKED' | 'NULL';
    this.setState({
      filter: {
        ...this.state.filter,
        blocked: value != 'NULL' ? value : undefined
      }
    }, () => this.performAction());
  };

  private handleTransformToTableContent = (content?: ICustomer[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: ICustomer) => {
      const customer = item?.name;
      const email = item?.user?.email;
      const phone = item?.phones?.[0]?.number;
      const companyName = item?.company?.name ?? translate('customerList.labels.withoutCompany');
      result.push([
        customer,
        email,
        phone,
        companyName,
        this.renderActionColumn(item)
      ]);
    });
    return result;
  };

  private handleView = (customer: ICustomer | undefined) => {
    this.props.history.push({
      pathname: `customers/${customer.id}`,
      state: {
        customer
      }
    });
  };

  private renderActionColumn = (customer: ICustomer | undefined): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <Button color="info" className={'centered-content margin1'} onClick={this.handleView.bind(this, customer)}>
          <FontAwesomeIcon icon={'eye'} />
        </Button>
      </div>
    );
  };

  private handleChangeFilter = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    event.preventDefault();
    const value = event.target.value;
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        [key]: value
      }
    }, () => this.performAction());
  }

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state?.customers?.content ?? []);
    return (
      <div className={'partner-place-list-container'}>
        <Row style={{ marginBottom: 20, marginTop: 60 }}>
          <Col md="3">
            <InputField
              title={translate('customerList.filter.nameOrEmail')}
              onChange={e => this.handleChangeFilter(e, 'email')}
              inputClassName={''}
              value={this?.state?.filter?.email ?? ''}
              placeholder={'customerList.filter.nameOrEmail'}
            />
          </Col>
          <Col md="3">
            <InputField
              title={translate('customerList.filter.cpf')}
              onChange={e => this.handleChangeFilter(e, 'cpf')}
              inputClassName={''}
              value={this?.state?.filter?.cpf ?? ''}
              placeholder={'customerList.filter.cpf'}
            />
          </Col>
          <Col md="3">
            <label className={'partner-register-input-label'}>
                Status
            </label>
            <select onChange={this.handleFilterCustomerStatus} value={this.state.filter.status} className={'select-filter'}>
                <option value={'NULL'}>Todos</option>
                {Object.values(CustomerStatus).map((item, index) => (
                  <option value={item} key={item + index}>
                    {translate(`customerList.status.${item}`)}
                  </option>
                ))}
            </select>
          </Col>
          <Col md="3">
            <label className={'partner-register-input-label'}>
              Blqueados
            </label>
            <select onChange={this.handleFilterCustomerBlocked} value={this.state.filter.blocked} className={'select-filter'}>
              <option value={'NULL'}>Todos</option>
              <option value={'BLOCKED'}>
                Bloqueados NO-SHOW
              </option>
            </select>
          </Col>
        </Row>

        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('customerList.tableHeaders')}
          emptyTableMessage={this.props.status !== HttpRequestStatus.ONGOING ? translate('customerList.labels.emptyList') : ''}
        />
        {
          tableContent.length > 0 && (
            <Row className={'justify-content-center'}>
              <JhiPagination
                items={
                  this.state.customers
                    ? getPaginationItemsNumber(this.state.customers.totalElements, this.state.customers.size)
                    : 0
                }
                activePage={this.state.customers ? this.state.customers.number : 1}
                onSelect={this.handlePagination}
                maxButtons={10}
              />
            </Row>
          )
        }
      </div >
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  customers: storeState.customer.page,
  status: storeState.customer.getCustomers
});

const mapDispatchToProps = {
  resetCustomer,
  showModal,
  hideModal,
  fetchCustomerList
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ICustomerList);
