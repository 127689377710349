import React from 'react';

export const splitStringAtLines = (a: string, b: string) => {
  if (!b) {
    return a;
  }
  return (
    <>
      <>{a}</>
      <br />
      <>{b}</>
    </>
  );
};
