import React, { ChangeEvent } from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './partner-edit-login.css';
import InputField from 'app/components/input-field/InputField';
import { toast } from 'react-toastify';
import StringUtils from 'app/util/StringUtils';
import Button from 'app/components/button/Button';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { getPartnerPerfil, reset, savePartnerPerfil } from 'app/entities/partner/partner-reducer';
import { IPartnerUpdatePerfil } from 'app/shared/model/partner';
import moment from 'moment';

export interface IPartnerEditLoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IPartnerEditLoginState {
  errors?: IPartnerEditLoginError;
  valids?: IPartnerEditLoginValid;
  partner: IPartnerUpdatePerfil;
}

export interface IPartnerEditLoginError {
  nameError?: boolean;
  emailError?: boolean;
  birthDateError?: boolean;
  cpfError?: boolean;
}

export interface IPartnerEditLoginValid {
  nameValid?: boolean;
  emailValid?: boolean;
  birthDateValid?: boolean;
  cpfValid?: boolean;
}

export class PartnerEditLogin extends React.Component<IPartnerEditLoginProps, IPartnerEditLoginState> {
  constructor(props: IPartnerEditLoginProps) {
    super(props);

    this.state = {
      errors: {},
      partner: {},
      valids: {}
    };
  }

  componentDidMount() {
    this.props.getPartnerPerfil();
  }

  componentWillReceiveProps(newProps: IPartnerEditLoginProps) {
    if (newProps.getPartnerPerfilSuccess && newProps.partner) {
      const partner = {
        id: newProps.partner.id,
        name: newProps.partner.name,
        email: newProps.partner.user.email,
        cpf: newProps.partner.cpf,
        birthDate: !!newProps.partner.birthDate ? moment(newProps.partner.birthDate).format('DD/MM/YYYY') : undefined
      } as IPartnerUpdatePerfil;

      this.setState({ partner });
      this.mapValidFields(partner);
      this.props.reset();
      return;
    }

    if (newProps.savePartnerPerfilSuccess) {
      toast.success(translate('partnerEditLogin.messages.saveWithSuccess'), {
        autoClose: 2000
      });
      this.props.reset();
      this.props.getPartnerPerfil();
      return;
    }
  }

  mapValidFields = (partner: IPartnerUpdatePerfil) => {
    const fieldsValid: IPartnerEditLoginValid = {
      nameValid: true,
      emailValid: true,
      cpfValid: !!partner.cpf,
      birthDateValid: !!partner.birthDate
    };

    this.setState({ valids: fieldsValid });
  };

  handleTextChanged = (text: string, propertyToChange: string, errorToChange?: string, validToChange?: string) => {
    if (!!errorToChange) {
      this.setState(prevState => ({
        partner: { ...prevState.partner, [propertyToChange]: text },
        errors: { ...prevState.errors, [errorToChange]: false },
        valids: { ...prevState.valids, [validToChange]: false }
      }));
      return;
    }

    this.setState(prevState => ({ partner: { ...prevState.partner, [propertyToChange]: text } }));
  };

  handleBackButtonPressed = () => {
    this.props.history.replace(`/partner/partner-dashboard`);
  };

  handleConfirmButtonPressed = () => {
    if (!this.validateFields()) {
      return;
    }

    const updatePartner = { ...this.state.partner };
    updatePartner.birthDate = moment(this.state.partner.birthDate, 'DD/MM/YYYY').toDate();

    this.props.savePartnerPerfil(updatePartner);
  };

  handleCpfChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const cpf = event.target.value;
    this.setState(prevState => ({
      partner: { ...prevState.partner, cpf },
      errors: { ...prevState.errors, cpfError: false },
      valids: { ...prevState.valids, cpfValid: false }
    }));
  };

  validateFields = () => {
    let allFieldsIsValid = true;

    const fieldsValid: IPartnerEditLoginValid = {
      nameValid: true,
      emailValid: true,
      birthDateValid: true
    };

    if (StringUtils.isStringInvalid(this.state.partner.name)) {
      allFieldsIsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, nameError: true } }));
      fieldsValid.nameValid = false;
    }

    if (!StringUtils.isEmailValid(this.state.partner.email)) {
      allFieldsIsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, emailError: true } }));
      fieldsValid.emailValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.partner.birthDate) || !moment(this.state.partner.birthDate, 'DD/MM/YYYY').isValid()) {
      allFieldsIsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, birthDateError: true } }));
      fieldsValid.birthDateValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.partner.cpf)) {
      allFieldsIsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, cpfError: true } }));
      fieldsValid.cpfValid = false;
    }

    this.setState({ valids: fieldsValid });

    return allFieldsIsValid;
  };

  render() {
    return (
      <div className={'partner-edit-login-account-container'}>
        <label className={'partner-place-bank-account-header'}>{translate('partnerEditLogin.labels.header')}</label>

        <div className={'row row-input-container'}>
          <div className={'col-md-12'}>
            <InputField
              title={translate('partnerEditLogin.labels.name')}
              onChange={event => this.handleTextChanged(event.target.value, 'name', 'nameError', 'nameValid')}
              error={this.state.errors.nameError}
              valid={this.state.valids.nameValid}
              value={!!this.state.partner.name ? this.state.partner.name : null}
              inputClassName={'partner-edit-perfil-input'}
              placeholder={'partnerEditLogin.labels.name'}
            />
          </div>
        </div>

        <div className={'row row-input-container'}>
          <div className={'col-md-12 mt-3'}>
            <InputField
              title={translate('partner.register.labels.birthDate')}
              error={this.state.errors.birthDateError}
              valid={this.state.valids.birthDateValid}
              onChange={event => this.handleTextChanged(event.target.value, 'birthDate', 'birthDateError', 'birthDateValid')}
              inputClassName={'partner-edit-perfil-input'}
              value={!!this.state.partner.birthDate ? this.state.partner.birthDate.toString() : ''}
              placeholder={'partner.register.placeholders.birthDate'}
              maskFormat={'99/99/9999'}
              isMaskRequired
            />
          </div>
        </div>

        <div className={'row row-input-container'}>
          <div className={'col-md-12 mt-3'}>
            <InputField
              onChange={this.handleCpfChanged}
              inputClassName={'partner-edit-perfil-input'}
              title={translate('partner.register.labels.cpf')}
              value={this.state.partner.cpf}
              error={this.state.errors.cpfError}
              valid={this.state.valids.cpfValid}
              placeholder={'partner.register.placeholders.cpf'}
              maskFormat={'999.999.999-99'}
              isMaskRequired
            />
          </div>
        </div>

        <div className={'row row-input-container mt-3'}>
          <div className={'col-md-12'}>
            <InputField
              title={translate('partnerEditLogin.labels.email')}
              onChange={event => this.handleTextChanged(event.target.value, 'email', 'emailError', 'emailValid')}
              error={this.state.errors.emailError}
              valid={this.state.valids.emailValid}
              value={!!this.state.partner.email ? this.state.partner.email : null}
              inputClassName={'partner-edit-perfil-input'}
              placeholder={'partnerEditLogin.labels.email'}
            />
          </div>
        </div>

        <div className={'partner-edit-login-buttons-container'}>
          <Button
            onClick={this.handleBackButtonPressed}
            outerClassName={'partner-edit-login-back-button-background-outer-class-name'}
            buttonLabelClassName={'partner-edit-login-back-button-label'}
            title={translate('partnerEditLogin.buttons.back')}
          />

          <Button onClick={this.handleConfirmButtonPressed} title={translate('partnerEditLogin.buttons.save')} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = storeState => ({
  getPartnerPerfilSuccess: storeState.partners.getPartnerPerfilSuccess,
  savePartnerPerfilSuccess: storeState.partners.savePartnerPerfilSuccess,
  partner: storeState.partners.partner
});

const mapDispatchToProps = { getPartnerPerfil, reset, savePartnerPerfil };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PartnerEditLogin)
);
