import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import { Row, Col, Button } from 'reactstrap';
import GradientButton from 'app/components/button/Button';
import { Translate, translate } from 'react-jhipster';
import './activity-plan.scss';
import { toast } from 'react-toastify';
import { ActivityPlan, ActivityPlaceType, ActivityPlanRenewingPeriod } from 'app/shared/model/activity-plan';
import { getActivityPlanById, reset, approveActivityPlan } from 'app/entities/activity-plan/activity-plan.reducer';
import { getPriceTableByActivityPlan, reset as resetPriceTable } from 'app/entities/price-table/price-table-redux';
import _ from 'lodash';
import InputField from 'app/components/input-field/InputField';
import ActivityCompaniesModal from 'app/components/activityCompaniesModal';
import { IPriceTableActivity } from 'app/shared/model/price-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApprovePriceTableActivities from 'app/components/ApprovePriceTableActivities/ApprovePriceTableActivities';
import { IActivityCategory } from 'app/shared/model/activity-category';
import { fetchActivityCategories } from 'app/entities/activity-category/activity-category-reducer';
import { fetchActivitySubCategories } from 'app/entities/activity-sub-category/activity-sub-category-reducer';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { IActivitySubCategory } from 'app/shared/model/activity-sub-category';

export interface IActivityPlanManageProps
  extends StateProps,
  DispatchProp,
  RouteComponentProps<any, any, { activityPlan: ActivityPlan } | any> { }

export interface IActivityPlanManageState {
  activityPlan?: ActivityPlan;
  updating: boolean;
  priceTableActivities?: IPriceTableActivity[];
}

export class IActivityPlanManage extends React.Component<IActivityPlanManageProps, IActivityPlanManageState> {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      activityPlan: {}
    };
  }

  componentDidMount() {
    if (this.props.location.state?.invoice != null) {
      this.setState({
        activityPlan: this.props.location.state.activityPlan
      }, () => {
        this.props.getPriceTableByActivityPlan(this.state.activityPlan.id);
      });
    } else {
      if (this.props.match.params?.id != null) {
        this.props.getActivityPlanById(this.props.match.params?.id);
        this.props.getPriceTableByActivityPlan(this.props.match.params?.id);
      }
    }
  }

  fetchActivityPlanByMatchParamId = () => {
    this.props.getActivityPlanById(this.props.match.params?.id);
    this.props.getPriceTableByActivityPlan(this.props.match.params?.id);
  }

  componentWillReceiveProps(newProps: IActivityPlanManageProps) {
    if (newProps.getActivityPlanStatus == HttpRequestStatus.SUCCESS) {
      this.setState({
        activityPlan: newProps.activityPlan
      });
      this.props.reset();
    }
    if (newProps.updateActivityPlanStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Atualizado com sucesso!');
      this.props.reset();
      this.setState({
        activityPlan: newProps.activityPlan,
        updating: false
      }, () => {
        this.props.history.replace('/application-admin/activity-plan');
      });
    } else if (newProps.updateActivityPlanStatus === HttpRequestStatus.ERROR) {
      this.props.reset();
      this.setState({ updating: false }, () => this.fetchActivityPlanByMatchParamId());
      toast.error('Falha ao atualizar');
    }
    if (newProps.priceTableActivitiesList !== null) {
      this.setState({
        priceTableActivities: newProps.priceTableActivitiesList
      });
      this.props.resetPriceTable();
    }
  }

  render() {
    const activityPlan = this.state.activityPlan;
    return (
      <div className={'form-container'}>
        <div className={'title-screen-container'}>
          <div onClick={() => this.props.history.goBack()}>
            <FontAwesomeIcon className={'back-arrow-icon'} icon={'arrow-left'} />
          </div>
          <label className={'faq-manage-title'}>
            {/* <Translate contentKey={this.state.editFaq ? 'faqManage.labels.editFaq' : 'faqManage.labels.createFaq'} /> */}
          </label>
        </div>
        <Row>
          <Col md="6">
            <ActivityPlanDetails
              activityPlan={activityPlan}
              toSubmit={this.props.approveActivityPlan}
              priceTableActivities={this.state.priceTableActivities}
              showModal={this.props.showModal}
              hideModal={this.props.hideModal}
              fetchActivityCategories={this.props.fetchActivityCategories}
              fetchListActivitySubCategories={this.props.fetchActivitySubCategories}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const ActivityPlanDetails = (props: {
  activityPlan: ActivityPlan,
  toSubmit: (activityPlan: ActivityPlan) => void,
  priceTableActivities?: IPriceTableActivity[],
  showModal?: (type?: any, prop?: any) => void;
  hideModal?: (type?: any, prop?: any) => void;
  fetchActivityCategories?: () => void;
  fetchListActivitySubCategories?: () => void;
}) => {

  const [activityPlan, setActivityPlan] = React.useState<ActivityPlan>();
  const [isCompaniesModalOpen, setIsCompaniesModalOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.activityPlan != null) {
      setActivityPlan(prevState => ({
        ...prevState,
        ..._.clone(props.activityPlan)
      }));
    }
  }, [props.activityPlan]);

  React.useEffect(() => {
    if (props.priceTableActivities != null) {
      setActivityPlan(prevState => ({
        ...prevState,
        priceTableActivities: _.clone(props.priceTableActivities)
      }));
    }
  }, [props.priceTableActivities]);

  const handleCategoryList = () => {
    props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: 'Categoria',
      action: props.fetchActivityCategories,
      placeholderInput: 'Adicionar categoria',
      stateAction: 'activityCategories',
      cancelCallback: () => props.hideModal(),
      saveCallback: (item: any) => handleCategorySelected(item)
    });
  };

  const handleCategorySelected = (item: IActivityCategory) => {

    if (activityPlan.activityCategories == null) {
      activityPlan.activityCategories = Array<IActivityCategory>();
    }

    if (!activityPlan.activityCategories.some(it => it.id === item.id)) {
      setActivityPlan(prevState => ({
        ...prevState,
        activityCategories: [...prevState.activityCategories, item]
      }));
    }
    props.fetchListActivitySubCategories();
    props.hideModal();
  };

  const deleteCategory = (category?: IActivityCategory) => {
      const removedCategories = activityPlan.activityCategories?.filter?.(it => it.id !== category.id) ?? [];
      setActivityPlan(prevState => ({
        ...prevState,
        activityCategories: removedCategories
      }));
      props.fetchListActivitySubCategories?.();
    };
  const renderCategoryList = (category: IActivityCategory) => {
      return (
        <div className={'action-column-container-flex'}>
          <label >{category.name}</label>
          <Button className={'partner-external-delete-button'} onClick={() => deleteCategory(category)}>
            <FontAwesomeIcon icon={'trash-alt'} className={'marginR1'} />
            <Translate contentKey={'categoryList.buttons.delete'} />
          </Button>
        </div>
      );
    };

  const handleSubCategoryList = () => {
    props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: 'SubCategoria',
      action: props.fetchListActivitySubCategories,
      idToSearch: activityPlan.activityCategories?.map(it => it.id) ?? [],
      placeholderInput: 'Adicionar sub categoria',
      stateAction: 'activitySubCategories',
      cancelCallback: () => props.hideModal(),
      saveCallback: (item: any) => handleSubCategorySelected(item)
    });
  };

  const handleSubCategorySelected = (item: IActivitySubCategory) => {

    if (activityPlan.activitySubCategories == null) {
      activityPlan.activitySubCategories = Array<IActivitySubCategory>();
    }

    if (activityPlan.activitySubCategories.filter(it => it.id === item.id).length === 0) {
      setActivityPlan(prevState => ({
        ...prevState,
        activitySubCategories: [...prevState.activitySubCategories, item]
      }));
    }

    props.hideModal();
  };
  const deleteSubCategory = (subCategory?: IActivitySubCategory) => {
    const removedSubCategories = activityPlan.activitySubCategories?.filter?.(it => it.id !== subCategory.id) ?? [];
    setActivityPlan(prevState => ({
      ...prevState,
      activitySubCategories: removedSubCategories
    }));
  };

const renderSubCategoryList = (subCategory: IActivitySubCategory) => {
    return (
      <div className={'action-column-container-flex'}>
        <label >{subCategory.name}</label>
        <Button className={'partner-external-delete-button'} onClick={() => deleteSubCategory(subCategory)}>
          <FontAwesomeIcon icon={'trash-alt'} className={'marginR1'} />
          <Translate contentKey={'categoryList.buttons.delete'} />
        </Button>
      </div>
    );
  };
  const handleChangedPrice = (value: number) => {
    setActivityPlan(prevState => ({
      ...prevState,
      price: value,
      priceWithFee: 0 // todo
    }));
  };

  const handleChanged = (fieldKey: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    setActivityPlan(prevState => ({
      ...prevState,
      [fieldKey]: value
    }));
  };

  const handleSubmit = () => {
    const newActivityPlan: ActivityPlan = _.cloneDeep(activityPlan);
    let canSubmit = false;
    newActivityPlan.priceTableActivities.forEach((element, index) => {
      if (element.price >= element.priceWithFee) {
        canSubmit = true;
      }
      element.priceWithFee = activityPlan.priceTableActivities[index].priceWithFee ? activityPlan.priceTableActivities[index].priceWithFee : activityPlan.priceWithFee;
    });
    !canSubmit ? props?.toSubmit && props.toSubmit(newActivityPlan) : toast.error('Tabela de preços erradas');
  };
  const handleChangedBoolean = (fieldKey: string) => {
    setActivityPlan(prevState => ({
      ...prevState,
      [fieldKey]: !prevState[fieldKey]
    }));
  };

  const imageUrl = activityPlan?.image?.imageUrl ?? './content/images/default-customer-activity.png';
  return (
    <div>
      <Row className={'activity-plan-input-container'}>
        <img src={imageUrl} className={'customer-activity-img'} />
      </Row >
      <Row className={'activity-plan-input-container'}>
        <Col>
          <InputField
            title={translate('activityPlan.placeholders.name')}
            onChange={e => handleChanged('name', e)}
            inputClassName={''}
            value={activityPlan?.name}
            placeholder={'activityPlan.placeholders.name'}
          />
        </Col>
        <Col>
          <InputField
            title={translate('activityPlan.placeholders.fantasyName')}
            inputClassName={''}
            value={activityPlan?.partnerPlace?.fantasyName}
            readOnly
            placeholder={'activityPlan.placeholders.fantasyName'}
          />
        </Col>
      </Row>
      <Row className={'activity-plan-input-container'}>
        <Col>
          <p>{translate('activityPlan.placeholders.type')}</p>
          <select
            // @ts-ignore
            onChange={e => handleChanged('type', e)}
            value={activityPlan?.type}
            className={'partner-register-select'}
          >
            {Object.values(ActivityPlaceType).map((it, index) => (
              <option value={it} key={`activity-plan-type-${it}-${index}`}>{it}</option>
            ))}
          </select>
        </Col>
        <Col>
          <p>{translate('activityPlan.placeholders.period')}</p>
          <select
            // @ts-ignore
            onChange={e => handleChanged('period', e)}
            value={activityPlan?.period}
            className={'partner-register-select'}
          >
            {[ActivityPlanRenewingPeriod.MONTH_PERIOD].map((it, index) => (
              <option value={it} key={`activity-plan-period-${it}-${index}`}>{it}</option>
            ))}
          </select>
        </Col>
      </Row>
      {activityPlan?.type === ActivityPlaceType.TO_CUSTOMER && (
         <Row className={'activity-plan-input-container'}>
             <Col>
                <InputField
                  title={'Descrição do tipo do plano'}
                  inputClassName={''}
                  value={activityPlan?.typeDescription}
                  onChange={e => handleChanged('typeDescription', e)}
                  placeholder={'Descrição do tipo da atividade'}
                />
           </Col>
         </Row>
      )}
      <Row className={'activity-plan-input-container'}>
        <Col>
          <InputField
            title={translate('activityPlan.placeholders.description')}
            inputClassName={'activity-plan-description-input'}
            value={activityPlan?.description}
            onChange={e => handleChanged('description', e)}
            isTextArea
            placeholder={'activityPlan.placeholders.description'}
          />
        </Col>
      </Row>
      <Row className={'activity-plan-input-container'}>
        <Col>
          <div className={'edit-activity-step1-checkbox-container'} onClick={() => handleChangedBoolean('approved')}>
            <input checked={activityPlan?.approved} type={'radio'} onChange={() => { }} />
            <label className={'edit-activity-step1-checkbox-label'}>{translate('activityPlan.placeholders.approved')}</label>
          </div>
        </Col>
        <Col>
          <div className={'edit-activity-step1-checkbox-container'} onClick={() => handleChangedBoolean('isEnrolmentNeeded')}>
            <input checked={activityPlan?.isEnrolmentNeeded} type={'radio'} onChange={() => { }} />
            <label className={'edit-activity-step1-checkbox-label'}>{'Matrícula necessária'}</label>
          </div>
        </Col>
        <Col>
          <div className={'edit-activity-step1-checkbox-container'} onClick={() => handleChangedBoolean('enabledB2b')}>
            <input checked={activityPlan?.enabledB2b} type={'radio'} onChange={() => { }} />
            <label className={'edit-activity-step1-checkbox-label'}>{translate('activityPlan.placeholders.enabledB2b')}</label>
          </div>
        </Col>
      </Row>
      <Row className={'activity-plan-input-container'}>
        <Col>
          <InputField
            title={translate('activityPlan.placeholders.price')}
            inputClassName={''}
            onChange={(e, value, floatValue) => handleChangedPrice(floatValue)}
            value={activityPlan?.price}
            placeholder={'activityPlan.placeholders.price'}
            isCurrency
          />
        </Col>
        <Col>
          <InputField
            title={translate('activityPlan.placeholders.timeRecurrenceOff')}
            inputClassName={''}
            onChange={e => handleChanged('minTimeTurnRecurrenceOff', e)}
            value={activityPlan?.minTimeTurnRecurrenceOff ?? '0'}
            placeholder={'activityPlan.placeholders.timeRecurrenceOff'}
          />
        </Col>
        {/* <Col>
          <InputField
            title={translate('activityPlan.placeholders.priceWithFee')}
            inputClassName={''}
            value={activityPlan?.priceWithFee}
            readOnly
            placeholder={'activityPlan.placeholders.priceWithFee'}
            isCurrency
          />
        </Col> */}
      </Row>
      {/* <Row className={'activity-plan-input-container'}>
        <Col>
          <InputField
            title={translate('activityPlan.placeholders.enrolmentFee')}
            inputClassName={''}
            value={activityPlan?.enrolmentFee ?? 0}
            readOnly
            placeholder={'activityPlan.placeholders.enrolmentFee'}
            isCurrency
          />
        </Col>
        <Col>
          <InputField
            title={translate('activityPlan.placeholders.maintenanceFee')}
            inputClassName={''}
            value={activityPlan?.maintenanceFee ?? 0}
            readOnly
            placeholder={'activityPlan.placeholders.maintenanceFee'}
            isCurrency
          />
        </Col>
      </Row> */}
      <div className={'input-container'}>
        <div className={'category-manage-inner-addon'} onClick={handleCategoryList}>
          <InputField
            inputClassName={'partner-external-input-read-only'}
            title={translate('keikenPayPartnerExternalManage.placeholders.category')}
            value={''}
            placeholder={'keikenPayPartnerExternalManage.placeholders.category'}
            readOnly
            onClick={handleCategoryList}
          />
          <div className={'category-manage-inner-icon'}>
            <FontAwesomeIcon className={'category-manage-inner-icon'} icon={'search'} />
          </div>
        </div>
        <div className={''}>
            {activityPlan?.activityCategories != null &&
              activityPlan?.activityCategories.map((item, index) => (
              <div className={'partner-external-categories-sub-categories '} key={index}>{renderCategoryList(item)}</div>
            ))}
        </div>
      </div>
      <div className={'input-container'}>
        <div className={'category-manage-inner-addon'} onClick={handleSubCategoryList}>
          <InputField
            inputClassName={'partner-external-input-read-only'}
            title={translate('keikenPayPartnerExternalManage.placeholders.subCategory')}
            value={''}
            placeholder={'keikenPayPartnerExternalManage.placeholders.subCategory'}
            readOnly
            onClick={handleSubCategoryList}
          />
          <div className={'category-manage-inner-icon'}>
            <FontAwesomeIcon className={'category-manage-inner-icon'} icon={'search'} />
          </div>
        </div>
        <div className={''}>
            {activityPlan?.activitySubCategories != null &&
              activityPlan?.activitySubCategories.map((item, index) => (
              <div className={'partner-external-categories-sub-categories '} key={index}>{renderSubCategoryList(item)}</div>
            ))}
        </div>
      </div>
      <div className={'input-container'}>
        <Button style={{backgroundColor: '#455561'}} onClick={() => setIsCompaniesModalOpen(true)}>
          {translate('activityPlan.manage.allowedCompanies')}
        </Button>
        <div style={{color: 'GrayText', padding: '0 6px'}}>
          {translate('activityPlan.manage.compDescription')}
        </div>
        <ActivityCompaniesModal
          activityCompanies={activityPlan?.allowedCompanies ?? []}
          setActivityCompanies={companies => {
            setActivityPlan(prevState => ({
              ...prevState,
              allowedCompanies: companies
            }));
          }}
          isModalOpen={isCompaniesModalOpen}
          setIsModalOpen={setIsCompaniesModalOpen}
        />
      </div>
      {activityPlan?.allowedCompanies?.length > 0 && (
        <>
          <p style={{ fontWeight: 'bold' }}>
            {'Empresas selecionadas: '}
          </p>
          <div className="selected-companies">
            {activityPlan?.allowedCompanies?.map(item => (
              <div className="company-tag" key={item.id}>{item.name}</div>
            ))}
          </div>
        </>
        )}
      <div className={'activity-plan-input-container'}>
        <p style={{ fontWeight: 'bold' }}>
          {'preços b2b: '}
        </p>
        <ApprovePriceTableActivities
          priceTableActivities={activityPlan?.priceTableActivities ?? []}
          priceActivity={activityPlan?.price}
          priceWithFee={activityPlan?.priceWithFee}
          onChange={(items: IPriceTableActivity[]) => {
            setActivityPlan(prevState => ({
              ...prevState,
              priceTableActivities: items
            }));
          }}
        />
      </div>
      <div className={'marginT15'}>
        <GradientButton onClick={handleSubmit} title={translate('companyManage.buttons.save')} />
      </div>
    </div>
  );
};

const mapStateToProps = (root: IRootState) => ({
  getActivityPlanStatus: root.activityPlans.getActivityPlanStatus,
  updateActivityPlanStatus: root.activityPlans.updateActivityPlanStatus,
  activityPlan: root.activityPlans.activityPlan,
  priceTableActivitiesList: root.priceTable.priceTableActivitiesList
});

const mapDispatchToProps = {
  reset,
  getActivityPlanById,
  approveActivityPlan,
  getPriceTableByActivityPlan,
  fetchActivityCategories,
  fetchActivitySubCategories,
  showModal,
  hideModal,
  resetPriceTable
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProp = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IActivityPlanManage);
