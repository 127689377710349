export interface IBankAccount {
  id?: number;
  accountOwner?: string;
  agency?: string;
  number?: string;
  owner?: boolean;
  ownerIdentificationNumber?: string;
  bank?: IBank;
  bankAccountType?: BankAccountType;
}

export interface IBank {
  id?: number;
  name?: string;
}

export enum BankAccountType {
  SAVING_ACCOUNT = 'SAVING_ACCOUNT',
  CHECKING_ACCOUNT = 'CHECKING_ACCOUNT'
}
