import React, { useEffect, useState } from 'react';
import { faCreditCard, faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { getActivityPlans } from 'app/entities/activity-plan/activity-plan.reducer';
import { getActivityPlanPriceComposite, reset as resetPrice } from 'app/entities/billing/billing.reducer';
import { reset as resetCustomerPlan, createFutureChargeBilling, updateCustomerActivityPlan } from 'app/entities/customer-activity-plan/customer-activity-plan-redux';
import { IPresetRenewalActivityPlan } from 'app/shared/model/billing/preset-renewal-activity-plan';
import { ICustomerActivityPlan, PresetRenewalActivityPlan } from 'app/shared/model/customer-activity-plan';
import { CustomerActivityStatus } from 'app/shared/model/enum/CustomerActivityStatus';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IRootState } from 'app/shared/reducers';
import { formatLocalDate } from 'app/shared/util/date-utils';
import moment from 'moment';
import { translate } from 'react-jhipster';
import { TailSpin } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { Button, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
import { createRenewalActivityPlanFromCustomerPlan, deleteRenewalActivityPlan, fetchRenewalActivityPlan, reset as resetRenewal, updatePresetPaymentToRenewalActivityPlan } from 'app/entities/renewal-activity-plan/renewal-activity-plan-redux';
import { splitStringAtLines } from 'app/shared/util/components-utils';
import { IPage } from 'app/shared/model/page.model';
import { getCustomerPaymentMethods, IPaymentMethodCardItem, putUpdateCustomerAndPlanStatus, resetCustomer } from 'app/entities/customer/customer-redux';
import { toast } from 'react-toastify';
import PaymentMethodCardItems from './payment-methods-card-itens';
import { PresetPaymentMethodsToCharge } from 'app/shared/model/preset-payment-methods-to-charge';
import { SelectOptionsDropdown } from '../../schedule-manage/activity-schedule-confirm-modal/activity-schedule-confirm-modal';
import { IActivitySubCategory } from 'app/shared/model/activity-sub-category';
import { IActivityCategory } from 'app/shared/model/activity-category';
import { faEdit, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import '../customers-manage.scss';
import { CustomerStatus } from 'app/shared/model/customer/customer';

interface EditCustomerActivityPlanModalProps extends StateProps, DispatchProps {
  isOpen: boolean;
  customerActivityPlan: ICustomerActivityPlan;
  onClose: () => void;
  onConfirm: () => void;
}

const EditCustomerActivitModalPlan: React.FC<EditCustomerActivityPlanModalProps> = props => {
  const {
    isOpen,
    customerActivityPlan,
    onClose,
    onConfirm
  } = props;

  const [autoRenewal, setAutoRenewal] = useState<boolean>();
  const [status, setStatus] = useState<CustomerActivityStatus>();
  const [startPeriodDate, setStartPeriodDate] = useState<string>();
  const [endPeriodDate, setEndPeriodDate] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [newPesetRenewal, setNewPresetRenewal] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethodCardItem[]>([]);

  const [activityCategory, setActivityCategory] = useState<IActivityCategory>();
  const [activitySubCategory, setActivitySubCategory] = useState<IActivitySubCategory>();

  useEffect(() => {
    if (customerActivityPlan != null) {
      setAutoRenewal(customerActivityPlan.autoRenewal);
      setStatus(customerActivityPlan.status);
      setStartPeriodDate(customerActivityPlan.startPeriodDate);
      setEndPeriodDate(customerActivityPlan.endPeriodDate);
      setActivityCategory(customerActivityPlan.activityCategory);
      setActivitySubCategory(customerActivityPlan.activitySubCategories?.[0]);
      fetchRenewals();
    }
  }, [customerActivityPlan, isOpen]);

  const [state, setState] = useState({
    pageRequest: {
      page: 0,
      size: 5
    },
    selectedRenewal: undefined as IPresetRenewalActivityPlan,
    pageRenewals: undefined as IPage<IPresetRenewalActivityPlan>
  });

  useEffect(() => {
    if (props.paymentMethods) {
      setPaymentMethods(props.paymentMethods);
      props.resetCustomer();
      setLoading(false);
    }
  }, [props.paymentMethods]);

  useEffect(() => {
    if (props.updateStatus == HttpRequestStatus.SUCCESS) {
      props.resetCustomerPlan();
      setLoading(false);
      handleClose();
      onConfirm();
    } else if (props.updateStatus == HttpRequestStatus.ERROR) {
      setLoading(false);
      props.resetCustomerPlan();
    }
  }, [props.updateStatus]);

  useEffect(() => {
    if (props.statusRenewalDelete == HttpRequestStatus.SUCCESS) {
      fetchRenewals();
      props.resetRenewal();
      setLoading(false);
    } else if (props.statusRenewalDelete == HttpRequestStatus.ERROR) {
      props.resetRenewal();
      setLoading(false);
    }
  }, [props.statusRenewalDelete]);

  useEffect(() => {
    if (props.statusUpdatePayment == HttpRequestStatus.SUCCESS) {
      fetchRenewals();
      props.resetRenewal();
      setPaymentMethods(null);
      setState(prev => ({
        ...prev,
        selectedRenewal: undefined
      }));
      setLoading(false);
    } else if (props.statusUpdatePayment == HttpRequestStatus.ERROR) {
      props.resetRenewal();
      setLoading(false);
    }
  }, [props.statusUpdatePayment]);

  useEffect(() => {
    if (props.statusCreatePresetRenewal == HttpRequestStatus.SUCCESS) {
      toast.success('Sucesso ao criar renovação');
      fetchRenewals();
      setPaymentMethods(null);
      setLoading(false);
      setNewPresetRenewal(false);
      setState(prev => ({
        ...prev,
        selectedRenewal: undefined
      }));
      props.resetRenewal();
    } else if (props.statusCreatePresetRenewal == HttpRequestStatus.ERROR) {
      toast.error('Falha ao criar renovação');
      setLoading(false);
      setNewPresetRenewal(false);
      props.resetRenewal();
    }
  }, [props.statusCreatePresetRenewal]);

  useEffect(() => {
    if (props.statusRenewal == HttpRequestStatus.SUCCESS) {
      setState(prev => ({
        ...prev,
        pageRenewals: props.pagePlans
      }));
      props.resetRenewal();
    } else if (props.statusRenewal == HttpRequestStatus.ERROR) {
      props.resetRenewal();
    }
  }, [props.statusRenewal]);

  const history = useHistory();
  useEffect(() => {
    if (props.statusFutureCharge == HttpRequestStatus.SUCCESS) {
      setLoading(false);
      const billing = props.customerActivityPlanFutureCharge?.billing;
      toast.success('Criado com sucesso! Redirecionando para compra!', {
        onClose: () => {
          handleClose();
          history.push({
            pathname: `/application-admin/billings/${billing.id}`
          });
        }
      });
      props.resetCustomerPlan();
    } else if (props.statusFutureCharge == HttpRequestStatus.ERROR) {
      setLoading(false);
      props.resetCustomerPlan();
    }
  }, [props.statusFutureCharge]);

  useEffect(() => {
    if (props.updateCustomerAndPlanStatus == HttpRequestStatus.SUCCESS) {
      setLoading(false);
      toast.success('Atualizado com sucesso!', {
        onClose: () => {
          handleClose();
        }
      });
      props.resetCustomer();
    }
  }, [props.updateCustomerAndPlanStatus]);

  function handleConfirm() {
    if (loading) return;

    setLoading(true);
    props.updateCustomerActivityPlan({
      id: customerActivityPlan.id,
      autoRenewal,
      startPeriodDate: moment(startPeriodDate).add(12, 'h').toISOString(),
      endPeriodDate: moment(endPeriodDate).add(12, 'h').toISOString(),
      status
    } as ICustomerActivityPlan);
  }

  function handleOnClickRenewal() {
    setAutoRenewal(prevState => !prevState);
  }

  function handleClose() {
    onClose();
    setAutoRenewal(null);
    setStatus(null);
    setStartPeriodDate(null);
    setEndPeriodDate(null);
    setLoading(null);
    setPaymentMethods(null);
    setState(prev => ({
      ...prev,
      pageRenewals: undefined,
      selectedRenewal: undefined
    }));
    setNewPresetRenewal(false);
  }

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  function handleOnSelect(item: CustomerActivityStatus) {
    toggle();
    if (item == CustomerActivityStatus.PAYMENT_OVERDUE) {
      return toast.warn('Não é possivel alterar o status para Inadimplente, remova/adicione a pendência para atualizar para esse status');
    }
    setStatus(item);
  }

  function fetchRenewals() {
    if (props.customerActivityPlan?.customer?.id != null) {
      props.fetchRenewalActivityPlan({ page: 0, size: 20 }, {
        ['customer.id']: props.customerActivityPlan?.customer?.id,
        ['activityPlan.id']: props.customerActivityPlan?.activityPlan?.id
      });
    }
  }

  const handleDeleteRenewalItem = (item: IPresetRenewalActivityPlan) => {
    props.deleteRenewalActivityPlan(item.id);
  };

  const handleEditRenewalPaymentMethods = (item: IPresetRenewalActivityPlan) => {
    setState(prev => ({
      ...prev,
      selectedRenewal: prev.selectedRenewal == item ? undefined : item
    }));

    setPaymentMethods(null);

    props.getCustomerPaymentMethods(customer.id, customerActivityPlan?.activityCategory?.id);
  };

  const renderRenewalActionColumn = (item: IPresetRenewalActivityPlan) => {
    return (
      <div>
        <Button color="warning" className={'centered-content margin1'} onClick={() => handleEditRenewalPaymentMethods(item)}>
          <FontAwesomeIcon icon={faCreditCard as IconProp} />
        </Button>
        <Button color="danger" className={'centered-content margin1'} onClick={() => handleDeleteRenewalItem(item)}>
          <FontAwesomeIcon icon={faTrashAlt as IconProp} />
        </Button>
      </div>
    );
  };

  const handleTransformPresetRenewalPlanToTableContent = (content?: IPresetRenewalActivityPlan[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map?.((item: IPresetRenewalActivityPlan, index: number) => {
      const partnerName = item?.activityPlan?.partnerPlace?.fantasyName;
      const planName = item?.activityPlan?.name;
      const lastRenewalDate = item?.lastRenewalDate ? formatLocalDate(item.lastRenewalDate) : '';

      const activityPlanStatus = item?.status?.toString?.() ?? '';

      const customerPaymentMethod = item?.presetPaymentMethodsToCharge?.customerPaymentMethod;
      const paymentInfo =
        !customerPaymentMethod?.lastFourNumbers ? '-' :
          `${customerPaymentMethod.brand}, final: ${customerPaymentMethod.lastFourNumbers}`;

      const groupId = item?.presetPaymentMethodsToCharge?.coParticipationGroup?.id;
      const nameGroup = item?.presetPaymentMethodsToCharge?.coParticipationGroup?.nameGroup;
      const groupActivityCategoryId = item?.presetPaymentMethodsToCharge?.coParticipationGroupActivityCategory?.id;
      const activityCategoryName = item?.presetPaymentMethodsToCharge?.coParticipationGroupActivityCategory?.activityCategory?.name;

      const coParticipationInfo = !groupId && !nameGroup && !groupActivityCategoryId && !activityCategoryName ? '' :
        splitStringAtLines(`${groupId}-${nameGroup}`, `${groupActivityCategoryId}-${activityCategoryName}`);

      result.push([
        planName,
        lastRenewalDate,
        activityPlanStatus,
        paymentInfo,
        coParticipationInfo,
        renderRenewalActionColumn(item)
      ]);
    });
    return result;
  };

  const handleOnSelectedPaymentMethod = (presetPaymentMethodsToCharge: PresetPaymentMethodsToCharge) => {
    setState(prev => ({
      ...prev,
      selectedRenewal: {
        ...prev.selectedRenewal,
        presetPaymentMethodsToCharge
      }
    }));
  };

  const handleNewRenewal = () => {
    setNewPresetRenewal(true);
    setLoading(true);
    props.getCustomerPaymentMethods(customer.id, customerActivityPlan?.activityCategory?.id);
  };

  const handleCreatePresetRenewal = (item: PresetPaymentMethodsToCharge) => {
    if (!activityCategory?.id) {
      toast.error('Selecione uma categoria');
      return;
    }
    if (!activitySubCategory?.id) {
      toast.error('Selecione uma Sub-Categoria');
      return;
    }
    const presetRenewal: PresetRenewalActivityPlan = {
      activityPlan: customerActivityPlan.activityPlan,
      activityCategory,
      activitySubCategory,
      presetPaymentMethodsToCharge: item
    };
    props.createRenewalActivityPlanFromCustomerPlan(customerActivityPlan.id, presetRenewal);
    setLoading(true);
  };

  const handleCreateFutureCharge = () => {
    const enableds = state.pageRenewals?.content?.filter?.(it => it.status == 'ENABLED');
    if (!enableds) {
      toast.error('Não encontrada renovação ativa');
      return;
    }
    if (enableds.length > 1) {
      toast.error('Existe mais que uma renovação ativa');
      return;
    }
    const presetRenewal = state.pageRenewals?.content?.find(it => it.status == 'ENABLED');

    props.createFutureChargeBilling(customerActivityPlan.id, presetRenewal.id);
    setLoading(true);
  };

  const handleModalConfirmButton = () => {
    const presetPayment = state?.selectedRenewal?.presetPaymentMethodsToCharge;
    if (
      !presetPayment?.customerPaymentMethod &&
      !presetPayment?.coParticipationGroupActivityCategory
    ) {
      toast.warn('Faltando meio de pagamento!');
      return;
    }

    if (newPesetRenewal) {
      handleCreatePresetRenewal(presetPayment);
      return;
    }

    if (!state?.selectedRenewal?.id || !customer?.id) {
      toast.warn('informações faltando');
      return;
    }
    props.updatePresetPaymentToRenewalActivityPlan(
      state?.selectedRenewal?.id,
      customer?.id,
      presetPayment
    );
    setLoading(true);
  };

  const handleModalCancelButton = () => {
    setState(prev => ({
      ...prev,
      selectedRenewal: undefined,
      pageRenewals: undefined
    }));
    setPaymentMethods(null);
    setNewPresetRenewal(false);
    fetchRenewals();
  };

  const handleUpdateCustomerAndPlanStatus = (customerId?: number, planId?: number, customerStatus?: CustomerStatus) => {
    props.putUpdateCustomerAndPlanStatus({
      id: customerId,
      customerActivityPlanId: planId,
      status: customerStatus
    });
    setLoading(true);
  };

  const customer = customerActivityPlan?.customer;
  const activityPlan = customerActivityPlan?.activityPlan;
  const partnerPlace = customerActivityPlan?.activityPlan?.partnerPlace;
  const indexSelected = state.pageRenewals?.content?.findIndex?.(it => it?.id == state.selectedRenewal?.id);
  const tableContentPageRenewal: Array<Array<string | JSX.Element>> = handleTransformPresetRenewalPlanToTableContent(state?.pageRenewals?.content);
  return (
    <Modal
      className="modal-lg"
      backdrop
      isOpen={isOpen}
      toggle={handleClose}
    >
      <ModalHeader toggle={handleClose}>{translate('activityPlanCheckout.label.title')}</ModalHeader>
      <ModalBody>
        <Container fluid>
          <Row>
            <Col>
              <Row>
                {'Parceiro: ' + partnerPlace?.fantasyName ?? ''}
              </Row>
              <Row>
                {'Atividade: ' + activityPlan?.name ?? ''}
              </Row>
              <Row>
                {'Cliente: ' + customer?.name ?? ''}
              </Row>
            </Col>
            <Col>
              <Button disabled={loading == true} color="success" onClick={handleCreateFutureCharge}>
                <FontAwesomeIcon icon={faFileInvoiceDollar as IconProp} className="mr-2" size="lg" />
                {translate('activityPlanCheckout.modal-confirm.createFutureCharge')}
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <Label>Início da vigência</Label>
              <Input
                type="date"
                defaultValue={moment(startPeriodDate).format('YYYY-MM-DD')}
                value={moment(startPeriodDate).format('YYYY-MM-DD')}
                onChange={e => {
                  const value = e.target.value;
                  setStartPeriodDate(moment(value).format('YYYY-MM-DD'));
                }}
              />
            </Col>
            <Col>
              <Label>Fim da vigência</Label>
              <Input
                type="date"
                defaultValue={moment(endPeriodDate).format('YYYY-MM-DD')}
                value={moment(endPeriodDate).format('YYYY-MM-DD')}
                onChange={e => {
                  const value = e.target.value;
                  setEndPeriodDate(moment(value).format('YYYY-MM-DD'));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={'category-manage-inner-addon__radio'}>
                <input checked={autoRenewal} onClick={handleOnClickRenewal} type={'radio'} />
                <label className={'company-manage-checkbox-label'}>
                  {translate(`activityPlanCheckout.modal-confirm.renewal.${autoRenewal ?? false}`)}
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Status</Label>
              <Dropdown direction="right" color="light" isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>
                  {status ?? 'Status'}
                </DropdownToggle>
                <DropdownMenu >
                  {Object.values(CustomerActivityStatus)?.map((item, index) =>
                    <DropdownItem key={`cp-dropdown-edit-${item}-${index}`} eventkey={index} onClick={() => handleOnSelect(item)}>
                      {item}
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col>
              {customerActivityPlan?.status == CustomerActivityStatus.PAYMENT_OVERDUE ? (
                <>
                  {'Remover pendência'}
                  <Button id={`remove-customer-activity-pendecy`} color="danger" className={'centered-content margin1'} onClick={() => handleUpdateCustomerAndPlanStatus(customer?.id, customerActivityPlan?.id, CustomerStatus.REGULAR)}>
                    <FontAwesomeIcon icon={faTrashAlt as IconProp} />
                  </Button>
                </>
                ) : (
                  <>
                    {'Adicionar Pendência'}
                    <Button id={`add-customer-activity-pendecy`} color="danger" className={'centered-content margin1'} onClick={() => handleUpdateCustomerAndPlanStatus(customer?.id, customerActivityPlan?.id, CustomerStatus.PAYMENT_OVERDUE)}>
                      <FontAwesomeIcon icon={faEdit as IconProp} />
                    </Button>
                  </>
                )
              }
            </Col>
          </Row>
          <Row>
            <Col>
              {state?.pageRenewals?.content && (<>
                <Row>
                  <Col className="d-flex align-items-baseline">
                    <Button color="success" className={'centered-content margin3'} onClick={handleNewRenewal}>
                      <FontAwesomeIcon icon={'plus'} />
                    </Button>
                    <h3>Renovações</h3>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex align-items-baseline">
                    <SimpleTableList
                      rows={tableContentPageRenewal}
                      columNameKeys={translate('activityPlanCheckout.modal-confirm.renewal.tableHeaders')}
                      emptyTableMessage={''}
                      indexSelected={indexSelected}
                    />
                  </Col>
                </Row>
              </>)}
            </Col>
          </Row>
          <Row>
            {indexSelected != -1 && paymentMethods == null && (
              <TailSpin color="black" height={30} width={30} />
            )}
            {(indexSelected != -1 || newPesetRenewal) && (
              <>
                <Row>
                  <PaymentMethodCardItems
                    items={paymentMethods}
                    selected={state.selectedRenewal?.presetPaymentMethodsToCharge}
                    onSelected={handleOnSelectedPaymentMethod}
                  />
                </Row>
                <Row className="ml-2">
                  {loading
                    ? <div className="ml-2"><TailSpin color="black" height={30} width={30} /></div>
                    : (
                      <Col>
                        {newPesetRenewal && (
                          <Row>
                            <Col md="6">
                              <SelectOptionsDropdown<IActivityCategory>
                                title="Categoria"
                                nameKey="name"
                                value={activityCategory}
                                options={customerActivityPlan?.activityPlan?.activityCategories ?? []}
                                onSelect={setActivityCategory}
                              />
                              <div className="mt-2 mb-2">
                                <SelectOptionsDropdown<IActivitySubCategory>
                                  title="Sub-Categoria"
                                  nameKey="name"
                                  value={activitySubCategory}
                                  options={customerActivityPlan?.activityPlan?.activitySubCategories ?? []}
                                  onSelect={setActivitySubCategory}
                                />
                              </div>
                            </Col>
                          </Row>
                        )}
                        <Row className="mt-2">
                          <Col md="9">
                            <Button className="mr-2" color="success" onClick={handleModalConfirmButton}>
                              {newPesetRenewal
                                ? translate('activityPlanCheckout.modal-confirm.confirmNewPreset')
                                : translate('activityPlanCheckout.modal-confirm.confirmPayment')}
                            </Button>
                          </Col>
                          <Col md="3">
                            <Button color="danger" onClick={handleModalCancelButton}>
                              {loading ? <TailSpin color="white" height={30} width={30} /> : translate('activityPlanCheckout.modal-confirm.cancel')}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                </Row>
              </>
            )}
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button disabled={state.selectedRenewal != null} color="success" onClick={handleConfirm}>
          {loading ? <TailSpin color="white" height={30} width={30} /> : translate('activityPlanCheckout.modal-confirm.confirm')}
        </Button>{' '}
        <Button disabled={state.selectedRenewal != null} color="danger" onClick={handleClose}>
          {loading ? <TailSpin color="white" height={30} width={30} /> : translate('activityPlanCheckout.modal-confirm.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  priceCompositeStatus: storeState.billing.statusActivityPlanPriceComposite,
  priceComposite: storeState.billing.priceComposite,
  statusFutureCharge: storeState.customerActivityPlan.statusFutureCharge,
  customerActivityPlanFutureCharge: storeState.customerActivityPlan.customerActivityPlan,
  billing: storeState.billing.billing,
  activityPlansPage: storeState.activityPlans.page,
  updateStatus: storeState.customerActivityPlan.updateStatus,
  statusRenewal: storeState.renewalActivityPlan.status,
  statusRenewalDelete: storeState.renewalActivityPlan.statusDelete,
  statusUpdatePayment: storeState.renewalActivityPlan.statusUpdatePayment,
  statusCreatePresetRenewal: storeState.renewalActivityPlan.statusCreatePresetRenewal,
  paymentMethods: storeState.customer.paymentMethods,
  pagePlans: storeState.renewalActivityPlan.page as IPage<IPresetRenewalActivityPlan>,
  updateCustomerAndPlanStatus: storeState.customer.updateCustomerStatus
});
const mapDispatchToProps = {
  getActivityPlanPriceComposite,
  getActivityPlans,
  updateCustomerActivityPlan,
  resetCustomerPlan,
  resetPrice,
  fetchRenewalActivityPlan,
  deleteRenewalActivityPlan,
  updatePresetPaymentToRenewalActivityPlan,
  createRenewalActivityPlanFromCustomerPlan,
  resetRenewal,
  getCustomerPaymentMethods,
  resetCustomer,
  createFutureChargeBilling,
  putUpdateCustomerAndPlanStatus
};
type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomerActivitModalPlan);
