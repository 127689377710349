
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/fontawesome-free-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPriceTableActivity } from 'app/shared/model/price-table';
import StringUtils from 'app/util/StringUtils';
import _ from 'lodash';
import CurrencyInput from 'react-currency-input';
import { translate } from 'react-jhipster';
import { Badge, Table } from 'reactstrap';

interface Props {
  priceTableActivities: IPriceTableActivity[];
  onChange: (priceTableActivities: IPriceTableActivity[]) => void;
  priceActivity: number;
  priceWithFee: number;
}

const ApprovePriceTableActivities: React.FC<Props> = props => {

  const [priceTableActivities, setPriceTableActivities] = React.useState<IPriceTableActivity[]>();
  const [auxNumberPriceTableActivities, setAuxNumberPriceTableActivities] = React.useState<number>(0);
  React.useEffect(() => {
    if (props.priceTableActivities != null) {
      setPriceTableActivities(props.priceTableActivities);
    }
  }, [props.priceTableActivities]);

  const handleChangePrice = (index: number, value: number) => {
    const newPriceTableActivities: IPriceTableActivity[] = _.cloneDeep(priceTableActivities);
    newPriceTableActivities[index].price = value;
    setPriceTableActivities(newPriceTableActivities);
    props?.onChange?.(newPriceTableActivities);
  };

  const handleChangePriceWithFee = (index: number, value: number) => {
    const newPriceTableActivities: IPriceTableActivity[] = _.cloneDeep(priceTableActivities);
    newPriceTableActivities[index].priceWithFee = value;
    setPriceTableActivities(newPriceTableActivities);
    props?.onChange?.(newPriceTableActivities);
  };

  const handleChangeValues = (value: number) => {
    const newPriceTableActivities = priceTableActivities?.map?.(prevItem => {
      const newValue = prevItem.price + value;
      prevItem.price = newValue;
      return prevItem;
    });
    setPriceTableActivities(newPriceTableActivities);
    props?.onChange?.(newPriceTableActivities);
  };

  const handleChangeValuesWithFee = (value: number) => {
    const newPriceTableActivities = priceTableActivities?.map?.(prevItem => {
      const newValue = prevItem.priceWithFee ? prevItem.priceWithFee + value : value;
      prevItem.priceWithFee = newValue;
      return prevItem;
    });
    setPriceTableActivities(newPriceTableActivities);
    props?.onChange?.(newPriceTableActivities);
  };

  return (
    <Table Table className="table table-striped table-bordered table-responsive d-table" >
      <thead>
        <tr>
          {translate('activityPlan.manage.table.headers')?.map?.((name: string, index: number) => (
            <th className={'item-max-width'} key={`table-price-header-${index}`}>
              {name}
              {(index == 2) && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div onClick={() => handleChangeValues(-1)} className={'icon-change-values'}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft as IconProp} />{'    '}
                  </div>
                  <div onClick={() => handleChangeValues(+1)} className={'icon-change-values'}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight as IconProp} />{' '}
                  </div>
                </div>
              )}
              {(index == 3) && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div onClick={() => handleChangeValuesWithFee(-1)} className={'icon-change-values'}>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft as IconProp} />{'    '}
                  </div>
                  <div onClick={() => handleChangeValuesWithFee(+1)} className={'icon-change-values'}>
                    <FontAwesomeIcon icon={faArrowAltCircleRight as IconProp} />{' '}
                  </div>
                </div>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {priceTableActivities?.map?.((it, index) => {
          const tableName = it?.priceTable?.name ?? 'N/A';
          const valid = _.gt(it.price, props.priceActivity) && _.lt(it.price, it.priceWithFee);
          return (
            <tr key={`table-price-row-${index}`} style={{ width: '80%', backgroundColor: index % 2 ? 'white' : '#e7e9ea' }}>
              <td>
                {it?.isNew == true && (<><Badge className="badge-success break">{'Novo'}</Badge><br /></>)}
                {tableName}
              </td>
              <td>{StringUtils.currencyPtBr(props.priceActivity)}</td>
              <td>
                <CurrencyInput
                  style={valid != true ? { borderColor: 'red' } : {}}
                  autoComplete={'off'}
                  onChangeEvent={(e, masked, floatValue) => handleChangePrice(index, floatValue)}
                  value={it.price}
                  decimalSeparator={','}
                  thousandSeparator={'.'}
                  prefix={'R$ '}
                />
              </td>
              <td style={it.priceWithFee == null ? { borderWidth: 1, borderColor: 'red' } : {}}>
                <CurrencyInput
                  style={valid != true ? { borderColor: 'red' } : {}}
                  autoComplete={'off'}
                  onChangeEvent={(e, masked, floatValue) => handleChangePriceWithFee(index, floatValue)}
                  value={it.priceWithFee}
                  decimalSeparator={','}
                  thousandSeparator={'.'}
                  prefix={'R$ '}
                />
              </td>
              <td style={{ color: valid == true ? '#00c87f' : 'red', fontWeight: 'bold' }}>
                {StringUtils.currencyPtBr(_.subtract(it?.priceWithFee, it.price))}
                <br />
                {_.multiply(100, _.divide(_.subtract(it?.priceWithFee, it.price), it?.priceWithFee)).toFixed(2) + '%'}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ApprovePriceTableActivities;
