import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { IPartnerPlace } from 'app/shared/model/partner-place.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IPageRequest } from 'app/shared/model/page.model';
import qs from 'qs';
import { filter } from 'lodash';
import { IPartnerPlacePaymentInfo } from '../../shared/model/partner-place.model';

export const ACTION_TYPES = {
  GET_PARTNER_PLACE_PERFIL: 'partnerPlaces/GET_PARTNER_PLACE_PERFIL',
  GET_PARTNER_PLACE_BY_ID: 'partnerPlaces/GET_PARTNER_PLACE_BY_ID',
  GET_PARTNER_PLACE_WALLET_BY_PARTNER_PLACE_ID: 'partnerPlaces/GET_PARTNER_PLACE_WALLET_BY_PARTNER_PLACE_ID',
  UPDATE_PARTNER_PLACE_PERFIL: 'partnerPlaces/UPDATE_PARTNER_PLACE_PERFIL',
  GET_PARTNER_PLACE_SYSTEM_FEE: 'partnerPlaces/GET_PARTNER_PLACE_SYSTEM_FEE',
  FETCH_PARTNER_PLACES: 'partnerPlaces/FETCH_PARTNER_PLACES',
  FORCE_UPDATE_INDEX_PARTNER_PLACE: 'partnerPlaces/FORCE_UPDATE_INDEX_PARTNER_PLACE',
  GET_PARTNERS_PAYMENT: 'partnerPlaces/GET_PARTNERS_PAYMENT',
  POST_PARTNER_PLACE_PAYMENT: 'partnerPlaces/POST_PARTNER_PLACE_PAYMENT',
  RESET: 'partnerPlaces/RESET'
};

const initialState = {
  // status
  getPartnerPlaceById: HttpRequestStatus.NOOP,
  getPartnerPlaces: HttpRequestStatus.NOOP,
  getPartnerPlaceSystemFeeStatus: HttpRequestStatus.NOOP,
  updateIndexStatus: HttpRequestStatus.NOOP,
  walletStatus: HttpRequestStatus.NOOP,
  getPartnerPlacePerfilLoading: false,
  getPartnerPlacePerfilError: false,
  getPartnerPlacePerfilSuccess: false,
  updatePartnerPlacePerfilLoading: false,
  updatePartnerPlacePerfilError: false,
  updatePartnerPlacePerfilSuccess: false,
  getPartnersPaymentStatus: HttpRequestStatus.NOOP,
  postPartnerPlacePaymentStatus: HttpRequestStatus.NOOP,
  // contents
  partnerPlacePerfil: null,
  partnerPlaceSystemFee: null,
  partnerPlacesPage: null,
  page: null,
  partnerPlace: null,
  wallet: undefined,
  partnersPayment: null
};

export type IPartnerPlaceState = Readonly<typeof initialState>;

// Reducer
export default (state: IPartnerPlaceState = initialState, action: IActionForResponseEntity<IPartnerPlace>): IPartnerPlaceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PARTNER_PLACES):
      return {
        ...initialState,
        getPartnerPlaces: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_PARTNER_PLACES):
      return {
        ...initialState,
        getPartnerPlaces: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_PARTNER_PLACES):
      return {
        ...initialState,
        getPartnerPlaces: HttpRequestStatus.SUCCESS,
        partnerPlacesPage: action.payload.data,
        page: action.payload.data
      };
    case REQUEST(ACTION_TYPES.FORCE_UPDATE_INDEX_PARTNER_PLACE):
      return {
        ...state,
        updateIndexStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FORCE_UPDATE_INDEX_PARTNER_PLACE):
      return {
        ...state,
        updateIndexStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FORCE_UPDATE_INDEX_PARTNER_PLACE):
      return {
        ...state,
        updateIndexStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.GET_PARTNER_PLACE_BY_ID):
      return {
        ...initialState,
        getPartnerPlaceById: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_PARTNER_PLACE_BY_ID):
      return {
        ...initialState,
        getPartnerPlaceById: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_PARTNER_PLACE_BY_ID):
      return {
        ...initialState,
        getPartnerPlaceById: HttpRequestStatus.SUCCESS,
        partnerPlace: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_PARTNER_PLACE_WALLET_BY_PARTNER_PLACE_ID):
      return {
        ...initialState,
        walletStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_PARTNER_PLACE_WALLET_BY_PARTNER_PLACE_ID):
      return {
        ...initialState,
        walletStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_PARTNER_PLACE_WALLET_BY_PARTNER_PLACE_ID):
      return {
        ...initialState,
        walletStatus: HttpRequestStatus.SUCCESS,
        wallet: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_PARTNER_PLACE_PERFIL):
      return {
        ...initialState,
        getPartnerPlacePerfilLoading: true
      };
    case FAILURE(ACTION_TYPES.GET_PARTNER_PLACE_PERFIL):
      return {
        ...initialState,
        getPartnerPlacePerfilError: true
      };
    case SUCCESS(ACTION_TYPES.GET_PARTNER_PLACE_PERFIL):
      return {
        ...initialState,
        getPartnerPlacePerfilSuccess: true,
        partnerPlacePerfil: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_PARTNER_PLACE_SYSTEM_FEE):
      return {
        ...initialState,
        getPartnerPlaceSystemFeeStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_PARTNER_PLACE_SYSTEM_FEE):
      return {
        ...initialState,
        getPartnerPlaceSystemFeeStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_PARTNER_PLACE_SYSTEM_FEE):
      return {
        ...initialState,
        getPartnerPlaceSystemFeeStatus: HttpRequestStatus.SUCCESS,
        partnerPlaceSystemFee: action.payload.data
      };
    case REQUEST(ACTION_TYPES.UPDATE_PARTNER_PLACE_PERFIL):
      return {
        ...initialState,
        updatePartnerPlacePerfilLoading: true
      };
    case FAILURE(ACTION_TYPES.UPDATE_PARTNER_PLACE_PERFIL):
      return {
        ...initialState,
        updatePartnerPlacePerfilError: true
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PARTNER_PLACE_PERFIL):
      return {
        ...initialState,
        updatePartnerPlacePerfilSuccess: true
      };
    case REQUEST(ACTION_TYPES.GET_PARTNERS_PAYMENT):
      return {
        ...initialState,
        getPartnersPaymentStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_PARTNERS_PAYMENT):
      return {
        ...initialState,
        getPartnersPaymentStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_PARTNERS_PAYMENT):
      return {
        ...initialState,
        getPartnersPaymentStatus: HttpRequestStatus.SUCCESS,
        partnersPayment: action.payload.data
      };
    case REQUEST(ACTION_TYPES.POST_PARTNER_PLACE_PAYMENT):
      return {
        ...initialState,
        postPartnerPlacePaymentStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.POST_PARTNER_PLACE_PAYMENT):
      return {
        ...initialState,
        postPartnerPlacePaymentStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.POST_PARTNER_PLACE_PAYMENT):
      return {
        ...initialState,
        postPartnerPlacePaymentStatus: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/partner-places';

export const getPartnerPlacePerfil = () => ({
  type: ACTION_TYPES.GET_PARTNER_PLACE_PERFIL,
  payload: axios.get(`${apiUrl}/perfil`)
});

export const getPartnerPlaceById = (id: number) => ({
  type: ACTION_TYPES.GET_PARTNER_PLACE_BY_ID,
  payload: axios.get(`${apiUrl}/${id}`)
});

export const getPartnerPlaceWalletByPartnerPlaceId = (id: number) => ({
  type: ACTION_TYPES.GET_PARTNER_PLACE_WALLET_BY_PARTNER_PLACE_ID,
  payload: axios.get(`${apiUrl}/${id}/wallet/logs`)
});

export const fetchPartnerPlaces = (
  page: IPageRequest,
  filters?: {
    name?: string;
    approved?: string;
    email?: string;
  }
) => {
  if (filters?.approved) {
    filters['partners.approved'] = filters.approved == 'all' ? undefined : filters.approved;
    filters['approved'] = undefined;
  }

  const params = {
    page: page?.page ?? 0,
    size: page?.size ?? 20,
    ...filters
  };

  return {
    type: ACTION_TYPES.FETCH_PARTNER_PLACES,
    payload: axios.get(apiUrl, { params })
  };
};

export const fetchPartnerPlacesFantasyName = (page: IPageRequest, fantasyName?: string) => ({
  type: ACTION_TYPES.FETCH_PARTNER_PLACES,
  payload: fantasyName
    ? axios.get(`${apiUrl}?fantasyName=${fantasyName}&?size=${page.size}&page=${page.page}`)
    : axios.get(`${apiUrl}?size=${page.size}&page=${page.page}`)
});

export const getPartnerPlaceSystemFee = () => ({
  type: ACTION_TYPES.GET_PARTNER_PLACE_SYSTEM_FEE,
  payload: axios.get(`${apiUrl}/system-fee`)
});

export const savePartnerPlace = (partnerPlace: IPartnerPlace) => ({
  type: ACTION_TYPES.UPDATE_PARTNER_PLACE_PERFIL,
  payload: axios.put(`${apiUrl}`, partnerPlace)
});

export const forceUpdateIndexPartnerPlace = (partnerPlaceId: number) => ({
  type: ACTION_TYPES.FORCE_UPDATE_INDEX_PARTNER_PLACE,
  payload: axios.put(`/api/search/partner-places/${partnerPlaceId}/update`),
  meta: {
    successMessage: 'Atualizado com sucesso!'
  }
});

export const getPartnersPayment = (page: number, dateTime: string) => {
  const params = qs.stringify({ dateTime });

  return ({
    type: ACTION_TYPES.GET_PARTNERS_PAYMENT,
    payload: axios.get(`/api/partner-places/balances?${params}&size=1000`)
  });
};

export const postPartnerPlacePayment = (partnerPaymentsDTO: IPartnerPlacePaymentInfo[]) => ({
  type: ACTION_TYPES.POST_PARTNER_PLACE_PAYMENT,
  payload: axios.post(`/api/partner-places/payment`, partnerPaymentsDTO)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
