import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { INewActivity } from '../../shared/model/new-activity.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IPageRequest } from 'app/shared/model/page.model';
import { IPriceTableActivity } from 'app/shared/model/price-table';
import { IActivity } from 'app/shared/model/activity';

export const ACTION_TYPES = {
  GENERATE_ACTIVITY_SCHEDULES: 'activities/GENERATE_ACTIVITY_SCHEDULES',
  CREATE_ACTIVITY: 'activities/CREATE_ACTIVITY',
  REQUEST_ACTIVITY: 'activities/REQUEST_ACTIVITY',
  REQUEST_ALL_ACTIVITY: 'activities/REQUEST_ALL_ACTIVITY',
  REQUEST_DETAILS: 'activities/REQUEST_DETAILS',
  DELETE_ACTIVITY: 'activities/DELETE_ACTIVITY',
  UPDATE_ACTIVITY: 'activities/UPDATE_ACTIVITY',
  NOT_APPROVED_ACTIVITIES: 'activities/NOT_APPROVED_ACTIVITIES',
  APPROVE_ACTIVITY: 'activities/APPROVE_ACTIVITY',
  RESET: 'activities/RESET',
  REQUEST_ACTIVITY_ACTIVES_HISTORY: 'activities/REQUEST_ACTIVITY_ACTIVES_HISTORY',
  REQUEST_ACTIVITY_FOR_PARTNER_PLACE_TO_CUSTOMER: 'activities/REQUEST_ACTIVITY_FOR_PARTNER_PLACE_TO_CUSTOMER',
  RESET_ACTIVITIES_STATUS: 'activities/RESET_ACTIVITIES_STATUS'
};

export interface IActivityState {
  readonly getNotApprovedActivities: HttpRequestStatus;
  readonly approveActivity: HttpRequestStatus;
  readonly generateActivitySchedulesStatus: HttpRequestStatus;
  readonly getActivitiesStatus: HttpRequestStatus;
  readonly createActivityLoading: boolean;
  readonly createActivitySuccess: boolean;
  readonly createActivityError: boolean;
  readonly updateActivitySuccess: boolean;
  readonly updateActivityLoading: boolean;
  readonly updateActivityError: boolean;
  readonly deleteActivitySuccess: boolean;
  readonly data: any;
  readonly activities: any;
  readonly details: any;
  readonly historyActivity: any;
  readonly page: any;
}

const initialState: IActivityState = {
  getNotApprovedActivities: HttpRequestStatus.NOOP,
  approveActivity: HttpRequestStatus.NOOP,
  generateActivitySchedulesStatus: HttpRequestStatus.NOOP,
  getActivitiesStatus: HttpRequestStatus.NOOP,
  createActivityLoading: false,
  createActivitySuccess: false,
  createActivityError: false,
  updateActivitySuccess: false,
  updateActivityLoading: false,
  updateActivityError: false,
  deleteActivitySuccess: false,
  data: null,
  activities: null,
  details: null,
  historyActivity: null,
  page: null
};

export default (state: IActivityState = initialState, action: IActionForResponseEntity<any>): IActivityState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_ACTIVITY):
      return {
        ...state,
        createActivityLoading: true,
        createActivitySuccess: false,
        createActivityError: false
      };
    case FAILURE(ACTION_TYPES.CREATE_ACTIVITY):
      return {
        ...state,
        createActivitySuccess: false,
        createActivityLoading: false,
        createActivityError: true
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACTIVITY):
      return {
        ...state,
        createActivitySuccess: true,
        createActivityLoading: false,
        createActivityError: false
      };
    case REQUEST(ACTION_TYPES.GENERATE_ACTIVITY_SCHEDULES):
      return {
        ...state,
        generateActivitySchedulesStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GENERATE_ACTIVITY_SCHEDULES):
      return {
        ...state,
        generateActivitySchedulesStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GENERATE_ACTIVITY_SCHEDULES):
      return {
        ...state,
        generateActivitySchedulesStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.UPDATE_ACTIVITY):
      return {
        ...state,
        updateActivityLoading: true
      };
    case FAILURE(ACTION_TYPES.UPDATE_ACTIVITY):
      return {
        ...state,
        updateActivitySuccess: false,
        updateActivityLoading: false,
        updateActivityError: true
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ACTIVITY):
      return {
        ...state,
        updateActivityError: false,
        updateActivityLoading: false,
        updateActivitySuccess: true
      };
    case REQUEST(ACTION_TYPES.REQUEST_ACTIVITY):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.REQUEST_ACTIVITY):
      return {
        ...state
      };
    case SUCCESS(ACTION_TYPES.REQUEST_ACTIVITY):
      return {
        ...state,
        data: action.payload?.data
      };
    case REQUEST(ACTION_TYPES.REQUEST_ALL_ACTIVITY):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.REQUEST_ALL_ACTIVITY):
      return {
        ...state
      };
    case SUCCESS(ACTION_TYPES.REQUEST_ALL_ACTIVITY):
      return {
        ...state,
        activities: action.payload?.data
      };
    case REQUEST(ACTION_TYPES.REQUEST_ACTIVITY_FOR_PARTNER_PLACE_TO_CUSTOMER):
      return {
        ...state,
        getActivitiesStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.REQUEST_ACTIVITY_FOR_PARTNER_PLACE_TO_CUSTOMER):
      return {
        ...state,
        getActivitiesStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.REQUEST_ACTIVITY_FOR_PARTNER_PLACE_TO_CUSTOMER):
      return {
        ...state,
        getActivitiesStatus: HttpRequestStatus.SUCCESS,
        page: action.payload?.data
      };
    // HISTORY
    case REQUEST(ACTION_TYPES.REQUEST_ACTIVITY_ACTIVES_HISTORY):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.REQUEST_ACTIVITY_ACTIVES_HISTORY):
      return {
        ...state
      };
    case SUCCESS(ACTION_TYPES.REQUEST_ACTIVITY_ACTIVES_HISTORY):
      return {
        ...state,
        historyActivity: action.payload?.data
      };
    // HISTORY
    case REQUEST(ACTION_TYPES.REQUEST_DETAILS):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.REQUEST_DETAILS):
      return {
        ...state
      };
    case SUCCESS(ACTION_TYPES.REQUEST_DETAILS):
      return {
        ...state,
        details: action.payload?.data
      };
    case REQUEST(ACTION_TYPES.DELETE_ACTIVITY):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.DELETE_ACTIVITY):
      return {
        ...state
      };
    case SUCCESS(ACTION_TYPES.DELETE_ACTIVITY):
      return {
        ...state,
        deleteActivitySuccess: true
      };
    case REQUEST(ACTION_TYPES.NOT_APPROVED_ACTIVITIES):
      return {
        ...state,
        getNotApprovedActivities: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.NOT_APPROVED_ACTIVITIES):
      return {
        ...state,
        getNotApprovedActivities: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.NOT_APPROVED_ACTIVITIES):
      return {
        ...state,
        getNotApprovedActivities: HttpRequestStatus.SUCCESS,
        data: action.payload?.data
      };
    case REQUEST(ACTION_TYPES.APPROVE_ACTIVITY):
      return {
        ...state,
        approveActivity: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.APPROVE_ACTIVITY):
      return {
        ...state,
        approveActivity: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.APPROVE_ACTIVITY):
      return {
        ...state,
        approveActivity: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET_ACTIVITIES_STATUS:
      return {
        ...state,
        getActivitiesStatus: undefined
      };
    case ACTION_TYPES.RESET:
      return initialState;
    default:
      return state;
  }
};

const apiUrl = '/api/activities';

export const generateActivitySchedules = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.GENERATE_ACTIVITY_SCHEDULES,
    payload: axios.post(`${apiUrl}/generate-activity-schedules`)
  });
};

export const createNewActivity = (newActivity: INewActivity) => dispatch => {
  dispatch({
    type: ACTION_TYPES.CREATE_ACTIVITY,
    payload: axios.post(apiUrl, newActivity, { timeout: 900000 })
  });
};

export const updateActivity = (activity: INewActivity) => ({
  type: ACTION_TYPES.UPDATE_ACTIVITY,
  payload: axios.put(apiUrl, activity)
});

export const getPartnerActivities = () => ({
  type: ACTION_TYPES.REQUEST_ACTIVITY,
  payload: axios.get<IActivity[]>(apiUrl + '/partner-place')
});

export const getActivitiesToPriceTable = () => ({
  type: ACTION_TYPES.REQUEST_ALL_ACTIVITY,
  payload: axios.get(apiUrl)
});

export const getActivityActivesHistory = () => ({
  type: ACTION_TYPES.REQUEST_ACTIVITY_ACTIVES_HISTORY,
  payload: axios.get(apiUrl + '/partner-place/actives/history')
});

export const getNotApprovedActivities = (page: IPageRequest, partnerEmail?: string) => {
  const partnerEmailParam = partnerEmail ? `&partnerEmail=${partnerEmail}` : '';

  return {
    type: ACTION_TYPES.NOT_APPROVED_ACTIVITIES,
    payload: axios.get(`${apiUrl}/not-approved?size=${page.size}&page=${page.page}${partnerEmailParam}`)
  };
};

export const approveActivity = (id: number, priceTableActivities: IPriceTableActivity[]) => ({
  type: ACTION_TYPES.APPROVE_ACTIVITY,
  payload: axios.put(apiUrl + `/approve/${id}`, { priceTableActivities })
});

export const getActivityDetails = (id: Number) => ({
  type: ACTION_TYPES.REQUEST_DETAILS,
  payload: axios.get(apiUrl + '/' + id + '/partner-place')
});

export const getActivityByPartnerPlaceToCustomer = (partnerPlaceId: Number, customerId: Number) => {
  return {
    type: ACTION_TYPES.REQUEST_ACTIVITY_FOR_PARTNER_PLACE_TO_CUSTOMER,
    payload: axios.get(`${apiUrl}/partner-places/${partnerPlaceId}/customers/${customerId}`)
  };
};

export const deleteActivity = (id: Number) => ({
  type: ACTION_TYPES.DELETE_ACTIVITY,
  payload: axios.delete(`${apiUrl}/${id}`)
});

export const reset = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.RESET
  });
};

export const resetActivitiesStatus = () => ({
  type: ACTION_TYPES.RESET_ACTIVITIES_STATUS
});
