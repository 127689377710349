import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT, APP_DATE_FORMAT, APP_TIMESTAMP_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const formatDate = (date, format = APP_DATE_FORMAT) => moment(date).format(format);

export const formatLocalDate = date => moment(date).format(APP_LOCAL_DATE_FORMAT);

export const formatTimestamp = (date, format = APP_TIMESTAMP_FORMAT) => moment(date).format(format);
