import { ICompany } from './company';
import { IActivitySubCategory } from './activity-sub-category';
import { IImage } from './image';
import { ActivityCategoryState } from 'app/entities/activity-category/activity-category-reducer';

export enum ActivityCategoryStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

export enum ActivityCategoryType {
  REGULAR = 'REGULAR',
  CHALLENGE = 'CHALLENGE'
}

export interface IActivityCategory {
  id?: number;
  name?: string;
  companies?: ICompany[];
  image?: IImage;
  status?: ActivityCategoryStatus;
  type?: ActivityCategoryType;
  activitySubCategories?: IActivitySubCategory[];
  groupNumbers?: number;
  groupNames?: string[];
  color?: string;
}
