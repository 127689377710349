import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { ISystemFee } from 'app/shared/model/system-fee.model';

export const ACTION_TYPES = {
  INSERT_SYSTEM_FEE: 'systemFee/INSERT_SYSTEM_FEE',
  UPDATE_SYSTEM_FEE: 'systemFee/UPDATE_SYSTEM_FEE',
  RESET: 'systemFee/RESET'
};

const initialState = {
  saveSystemFee: HttpRequestStatus.NOOP,
  systemFee: null
};

export type ISystemFeeState = Readonly<typeof initialState>;

export default (state: ISystemFeeState = initialState, action: IActionForResponseEntity<ISystemFee>): ISystemFeeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.INSERT_SYSTEM_FEE):
      return {
        ...initialState,
        saveSystemFee: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.INSERT_SYSTEM_FEE):
      return {
        ...initialState,
        saveSystemFee: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.INSERT_SYSTEM_FEE):
      return {
        ...initialState,
        saveSystemFee: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.UPDATE_SYSTEM_FEE):
      return {
        ...initialState,
        saveSystemFee: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_SYSTEM_FEE):
      return {
        ...initialState,
        saveSystemFee: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_SYSTEM_FEE):
      return {
        ...initialState,
        saveSystemFee: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/system-fee';

export const insertSystemFee = (systemFee: ISystemFee, partnerPlaceId: number) => ({
  type: ACTION_TYPES.INSERT_SYSTEM_FEE,
  payload: axios.post(`${apiUrl}`, { fixed: systemFee.fixed, percentage: systemFee.percentage, type: systemFee.type, partnerPlaceId })
});

export const updateSystemFee = (systemFee: ISystemFee) => ({
  type: ACTION_TYPES.INSERT_SYSTEM_FEE,
  payload: axios.put(`${apiUrl}`, systemFee)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
