import React, { useEffect, useState } from 'react';
import Button from 'app/components/button/Button';
import InputField from 'app/components/input-field/InputField';
import { IRootState } from 'app/shared/reducers';
import { Translate, translate } from 'react-jhipster';
import { Button as RButton, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { createCompanyReport, getCompanyReports } from 'app/entities/company-report/company-report-reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';

type ICompanyReportAddProps = StateProps & DispatchProps & {
  companyId: number;
};

const CompanyReportAdd = ({createReport, companyId, creteStatus, getReports}: ICompanyReportAddProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [companyReport, setCompanyReport] = useState({
    reportDate: new Date(),
    url: ''
  });

  useEffect(() => {
    if (creteStatus === HttpRequestStatus.SUCCESS) {
      toast.success(translate('companyManage.messages.createReportSuccess'));
      setShow(false);
      setCompanyReport({
        reportDate: new Date(),
        url: ''
      });
      getReports({ page: 0, size: 10 }, companyId);
    }
  }, [creteStatus]);

  const handleSaveNewReport = () => {
    createReport(companyId, {
      reportDate: moment(companyReport.reportDate).toISOString(),
      url: companyReport.url,
      company: {
        id: companyId
      }
    });
  };

  return (
    <div className={'input-container'}>
    <RButton className={'centered-content marginB2'} onClick={() => setShow(prev => !prev)}>
      <FontAwesomeIcon icon={show == true ? 'times' : 'check'} className={'marginR1'} />{' '}
      <Translate contentKey={'companyManage.buttons.addReport'} />
    </RButton>
    {show == true && (
      <>
        <div className={'input-container'}>
          <InputField
            value={companyReport.url}
            onChange={event => setCompanyReport({...companyReport, url: event.target.value})}
            error={error}
            title={translate('companyManage.labels.report')}
            inputClassName={''}
            maxLength={255}
            type={'text'}
            placeholder={'companyManage.placeholders.reportUrl'}
          />
        </div>
        <div className={'input-container'}>
          <label className={'label'}>{translate('companyManage.labels.reportDate')}</label>
          <Input
            type="date"
            value={moment(companyReport.reportDate).format('YYYY-MM-DD')}
            onChange={e => {
              const value = e.target.value;
              setCompanyReport({...companyReport, reportDate: moment(value).toDate() });
            }}
          />
        </div>
        <div className={'marginT15'}>
          <Button onClick={handleSaveNewReport} title={translate('companyManage.buttons.save')} />
        </div>
      </>
    )}
  </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  creteStatus: storeState.companyReport.createCompanyReportStatus
});

const mapDispatchToProps = {
  createReport: createCompanyReport,
  getReports: getCompanyReports
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps)(CompanyReportAdd);
