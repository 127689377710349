import React from 'react';
import { Translate } from 'react-jhipster';
import { Navbar, Nav, NavItem, Collapse } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { Brand } from '../header/header-components';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import './header-customer.scss';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';

export interface IHeaderCustomerProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IHeaderCustomerState {
  isVisible?: boolean;
}

export class HeaderCustomer extends React.Component<IHeaderCustomerProps, IHeaderCustomerState> {
  private handleLogout = () => {
    this.props.history.replace('/logout');
  };

  render() {
    return (
      <div id={'app-header'}>
        <LoadingBar className={'loading-bar'} />
        <Navbar dark expand={'sm'} fixed={'top'} className={'jh-navbar'}>
          <div id={'header-customer-session'} className={'header-customer-session'}>
            <div id={'header-customer-brand'} className={'header-customer-brand'}>
              <Brand />
            </div>
            <label className={'customer-logout-label'} onClick={this.handleLogout}>
              <Translate contentKey={'application-admin.header.labels.logout'} />
            </label>
          </div>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderCustomer)
);
