import { ICoParticipationCustomer } from 'app/shared/model/CoParticipation/ICoParticipationCustomer';
import { ICoParticipationGroupActivityCategory } from 'app/shared/model/CoParticipation/ICoParticipationGroupActivityCategory';
import classnames from 'classnames';
import * as React from 'react';
import { translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';
import RenewalItemRow from './RenewalItemRow';
import './CoParticipationCustomerToRenewal.scss';
import StringUtils from 'app/util/StringUtils';
interface CoParticipationCustomerToRenewalProps {
  item: ICoParticipationCustomer;
  coParticipationGroupActivityCategory?: ICoParticipationGroupActivityCategory;
  onClick?: (item: ICoParticipationGroupActivityCategory | null) => void;
  onSubmit?: (item: ICoParticipationGroupActivityCategory) => void;
}

const CoParticipationCustomerToRenewal: React.FC<CoParticipationCustomerToRenewalProps> = ({
  item,
  coParticipationGroupActivityCategory,
  onClick,
  onSubmit
}) => {

  if (item == null) {
    return (
      <div>
        {translate('renewalManage.coParticipationCustomer.notFound')}
      </div>
    );
  }

  const handleSelectGroupActivityCategory = (groupActivityCategory: ICoParticipationGroupActivityCategory) => {
    onClick?.(groupActivityCategory?.id != coParticipationGroupActivityCategory?.id ? groupActivityCategory : null);
  };

  const handleOnSubmit = () => {
    onSubmit?.(coParticipationGroupActivityCategory);
  };

  return (
    <div>
      <RenewalItemRow
        title={translate('renewalManage.coParticipationCustomer.segment')}
        value={item?.coParticipationSegment?.nameSegment}
      />
      {item.groups.map(it => (
        <div key={`groups-${it.id}`} className={'renewal-group-box'}>
          <RenewalItemRow
            title={translate('renewalManage.coGroup.name')}
            value={it.nameGroup}
          />
          <RenewalItemRow
            title={translate('renewalManage.coGroup.maxSpendingGroup')}
            value={it.maxSpendingGroup}
          />
          {it.annualLimit && (
            <RenewalItemRow
              title={translate('renewalManage.coGroup.annualLimit')}
              value={it.annualLimit}
            />
          )}
          {it.maxSpendingPerPurchase && (
            <RenewalItemRow
              title={translate('renewalManage.coGroup.maxSpendingPerPurchase')}
              value={it.maxSpendingPerPurchase}
            />
          )}
          {it.activityCategories.map(groupCategory => (
            <div
              key={`groupCategory-${groupCategory?.id}`}
              className={classnames('renewal-activity-category-box', coParticipationGroupActivityCategory?.id == groupCategory?.id ? 'renewal-activity-category-box-active' : '')}
              onClick={() => handleSelectGroupActivityCategory(groupCategory)}>
              <Row>
                <Col md="1">
                  <input checked={coParticipationGroupActivityCategory?.id == groupCategory.id} onChange={() => {
                    handleSelectGroupActivityCategory(groupCategory);
                  }} type={'radio'} />
                </Col>
                <Col md="7">
                  <span className={'renewal-label-title'}>
                    {groupCategory?.activityCategory?.name ?? ''}{': '}
                  </span>
                </Col>
                <Col md="4">
                  <span className={'renewal-label-value'}>
                    {StringUtils.currencyPtBr(groupCategory?.maxSpendingCategory) ?? ''}
                  </span>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      ))}
      {onSubmit != null && (
        <button onClick={handleOnSubmit}>
          {'Atualizar meio de pagamento'}
        </button>
      )}
    </div>
  );
};

export default CoParticipationCustomerToRenewal;
