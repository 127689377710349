import React, { useCallback, useEffect, useState } from 'react';
import { ICustomerActivity, ICustomerActivityFunctions } from 'app/shared/model/customer-activity.model';
import { IRootState } from 'app/shared/reducers';
import StringUtils from 'app/util/StringUtils';
import { getPaginationItemsNumber, JhiPagination, translate } from 'react-jhipster';
import SimpleTableList from '../simple-table-list/simple-table-list';
import { Row, Button, Col } from 'reactstrap';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { connect } from 'react-redux';
import { getCustomerActivityPlan, reset as resetCustomerActivityPlan } from 'app/entities/customer-activity-plan/customer-activity-plan-redux';
import { ICustomerActivityPlan, PresetRenewalActivityPlan } from 'app/shared/model/customer-activity-plan';
import { ICustomer } from 'app/shared/model/customer/customer';
import { deleteRenewalActivityPlan, fetchRenewalActivityPlan, reset as resetRenewal, updatePresetPaymentToRenewalActivityPlan } from 'app/entities/renewal-activity-plan/renewal-activity-plan-redux';
import { formatDate } from 'app/shared/util/date-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { IPresetRenewalActivityPlan } from 'app/shared/model/billing/preset-renewal-activity-plan';
import PaymentMethodCardItems from 'app/modules/application-admin/customers-manage/components/payment-methods-card-itens';
import { getCustomerPaymentMethods, IPaymentMethodCardItem, resetCustomer } from 'app/entities/customer/customer-redux';
import { PresetPaymentMethodsToCharge } from 'app/shared/model/preset-payment-methods-to-charge';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { IPage } from 'app/shared/model/page.model';
interface Props extends StateProps, DispatchProps {
  customer: ICustomer;
  renderActionColumn: (item: ICustomerActivity, index: number) => JSX.Element | string;
  selectedId?: number;
  reload?: boolean;
}

function TableCustomerActivityPlan(props: Props) {

  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethodCardItem[]>();
  const [state, setState] = useState({
    pageRequest: {
      page: 0,
      size: 5
    },
    pagePlans: undefined,
    selectedRenewal: undefined as IPresetRenewalActivityPlan
  });

  useEffect(() => {
    if (props.status == HttpRequestStatus.SUCCESS) {
      props.resetCustomerActivityPlan();
    }
  }, [props.status]);

  useEffect(() => {
    if (props.statusRenewal == HttpRequestStatus.SUCCESS) {
      setState(prev => ({
        ...prev,
        pagePlans: props.pagePlans
      }));
      props.resetRenewal();
    } else if (props.statusRenewal == HttpRequestStatus.ERROR) {
      props.resetRenewal();
    }
  }, [props.statusRenewal]);

  useEffect(() => {
    if (props.statusRenewalDelete == HttpRequestStatus.SUCCESS) {
      if (props.customer?.id != null) {
        props.fetchRenewalActivityPlan({ page: 0, size: 20 }, {
          ['customer.id']: props.customer.id
        });
        props.getCustomerActivityPlan({
          ['customer.id']: props.customer?.id,
          page: state?.pageRequest?.page ?? 0,
          size: state?.pageRequest?.size ?? 10
        });
      }
      props.resetRenewal();
    } else if (props.statusRenewalDelete == HttpRequestStatus.ERROR) {
      props.resetRenewal();
    }
  }, [props.statusRenewalDelete]);

  useEffect(() => {
    if (props.customer?.id) {
      props.getCustomerActivityPlan({
        ['customer.id']: props.customer?.id,
        page: state?.pageRequest?.page ?? 0,
        size: state?.pageRequest?.size ?? 10,
        sort: 'id,desc'
      });
      fetchRenewals();
    }
  }, [props.customer, props.reload]);

  useEffect(() => {
    if (props.paymentMethods) {
      setPaymentMethods(props.paymentMethods);
      props.resetCustomer();
      setPaymentLoading(false);
    }
  }, [props.paymentMethods]);

  useEffect(() => {
    if (props.statusUpdatePayment == HttpRequestStatus.SUCCESS) {
      fetchRenewals();
      setPaymentMethods(null);
      setState(prev => ({
        ...prev,
        selectedRenewal: undefined
      }));
      props.resetRenewal();
      setPaymentLoading(false);
    } else if (props.statusUpdatePayment == HttpRequestStatus.ERROR) {
      props.resetRenewal();
      setPaymentLoading(false);
    }
  }, [props.statusUpdatePayment]);

  const fetchRenewals = () => {
    if (props.customer?.id != null) {
      props.fetchRenewalActivityPlan({ page: 0, size: 20 }, {
        ['customer.id']: props.customer?.id
      });
    }
  };

  const handleDeleteRenewalItem = (item: IPresetRenewalActivityPlan) => {
    props.deleteRenewalActivityPlan(item.id);
    setPaymentMethods(null);
  };

  const handleEditRenewalItem = (item: IPresetRenewalActivityPlan) => {
    setState(prev => ({
      ...prev,
      selectedRenewal: item
    }));
    setPaymentLoading(true);
    setPaymentMethods(null);
  };

  const renderRenewalActionColumn = (item: IPresetRenewalActivityPlan) => {
    return (
      <div>
        <Button color="warning" className={'centered-content margin1'} onClick={() => handleEditRenewalItem(item)}>
          <FontAwesomeIcon icon={faEdit as IconProp} />
        </Button>
        <Button color="danger" className={'centered-content margin1'} onClick={() => handleDeleteRenewalItem(item)}>
          <FontAwesomeIcon icon={faTrashAlt as IconProp} />
        </Button>
      </div>
    );
  };

  const handleTransformPresetRenewalPlanToTableContent = (content?: IPresetRenewalActivityPlan[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IPresetRenewalActivityPlan, index: number) => {
      const partnerName = item?.activityPlan?.partnerPlace?.fantasyName;
      const planName = item?.activityPlan?.name;
      const lastRenewalDate = item?.lastRenewalDate ? formatDate(item.lastRenewalDate) : '';

      const statusColor = item?.status == 'ENABLED' ? 'text-success' : 'text-danger';
      const status = <p className={statusColor}>{item?.status?.toString?.()}</p> ?? '';
      const billingStatus = item?.billing?.status?.() ?? '';

      const customerPaymentMethod = item.presetPaymentMethodsToCharge?.customerPaymentMethod;
      const paymentInfo =
        !customerPaymentMethod?.lastFourNumbers ? '' :
          `${customerPaymentMethod.brand} - ${customerPaymentMethod.lastFourNumbers}`;

      const groupId = item?.presetPaymentMethodsToCharge?.coParticipationGroup?.id;
      const nameGroup = item?.presetPaymentMethodsToCharge?.coParticipationGroup?.nameGroup;
      const groupActivityCategoryId = item?.presetPaymentMethodsToCharge?.coParticipationGroupActivityCategory?.id;
      const activityCategoryName = item?.presetPaymentMethodsToCharge?.coParticipationGroupActivityCategory?.activityCategory?.name;

      const coParticipationInfo = !groupId && !nameGroup && !groupActivityCategoryId && !activityCategoryName ? '' :
        `${groupId} - ${nameGroup} / ${groupActivityCategoryId} - ${activityCategoryName}`;

      result.push([
        partnerName,
        planName,
        lastRenewalDate,
        status,
        paymentInfo,
        coParticipationInfo,
        renderRenewalActionColumn(item)
      ]);
    });
    return result;
  };

  const handleTransformToTableContent = (content?: ICustomerActivityPlan[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: ICustomerActivityPlan, index: number) => {
      const [start, end] = ICustomerActivityPlan.getPeriodDates(item);
      const partnerName = ICustomerActivityPlan.getPartnerName(item);
      const title = ICustomerActivityPlan.getTitle(item);

      const billingValue = StringUtils.currencyPtBr(item?.billing?.value);
      const subsidyValue = StringUtils.currencyPtBr(item?.billing?.billingCoParticipation?.subsidyValue);

      const statusColor = item?.status == 'APPROVED' ? 'text-success' : item?.status == 'WAITING_APPROVE' ? 'text-warning' : 'text-danger';
      const status = <p className={statusColor}>{item?.status?.toString?.()}</p> ?? '';

      const billingStatusColor = item?.billing?.status == 'PAID' ? 'text-success' : item?.billing?.status == 'WAITING' ? 'text-warning' : 'text-danger';
      const billingStatus = <p className={billingStatusColor}>{item?.billing?.status?.toString?.()}</p> ?? '';

      let paymentInfo = item?.billing?.paymentMethod;
      if (item?.billing?.paymentProviderId) {
        paymentInfo += ' ' + item?.billing?.paymentProviderId;
      }
      result.push([
        partnerName,
        title,
        start,
        end,
        status,
        paymentInfo,
        billingValue,
        subsidyValue,
        billingStatus,
        props.renderActionColumn(item, index)
      ]);
    });
    return result;
  };

  const handlePagination = activePage => {
    const object = state.pageRequest;
    object.page = activePage - 1;
    setState(prev => ({ ...prev, pageRequest: object }));
    if (props.customer?.id) {
      props.getCustomerActivityPlan({
        ['customer.id']: props.customer?.id,
        page: object.page,
        size: object.size
      });
    }
  };

  const handleOnSelectedPaymentMethod = (item: PresetPaymentMethodsToCharge) => {
    const pagePlans = state.pagePlans as IPage<PresetRenewalActivityPlan>;
    pagePlans['content'] = pagePlans.content?.map?.(it => {
      if (state.selectedRenewal?.id == it.id) {
        return {
          ...it,
          presetPaymentMethodsToCharge: item
        };
      }
      return it;
    });

    setState(prev => ({
      ...prev,
      pagePlans,
      selectedRenewal: {
        ...prev.selectedRenewal,
        presetPaymentMethodsToCharge: item
      }
    }));
  };

  const handleUpdatePresetPaymentToRenewalActivityPlan = () => {
    if (!state.selectedRenewal?.id || !props.customer?.id || (
      !state.selectedRenewal?.presetPaymentMethodsToCharge?.customerPaymentMethod &&
      !state.selectedRenewal?.presetPaymentMethodsToCharge?.coParticipationGroupActivityCategory
    )) {
      toast.warn('informações faltando');
      return;
    }
    setPaymentLoading(true);
    props.updatePresetPaymentToRenewalActivityPlan(
      state.selectedRenewal?.id,
      props.customer?.id,
      state.selectedRenewal?.presetPaymentMethodsToCharge
    );
  };

  const handleCancelUpdatePaymentMethods = () => {
    setState(prev => ({
      ...prev,
      selectedRenewal: undefined
    }));
    setPaymentMethods(null);
  };

  const tableContent: Array<Array<string | JSX.Element>> = handleTransformToTableContent(props.customerActivityPlans?.content ?? []);
  const tableContentPageRenewal: Array<Array<string | JSX.Element>> = handleTransformPresetRenewalPlanToTableContent(state?.pagePlans?.content ?? []);
  const indexSelected = props.customerActivityPlans?.content?.findIndex?.(it => it.id == props.selectedId) ?? -1;
  const indexRenewalSelected = state.pagePlans?.content?.findIndex?.(it => state.selectedRenewal?.id != null && it.id == state.selectedRenewal?.id) ?? -1;

  useEffect(() => {
    if (props.customer?.id && state.selectedRenewal?.id != null && indexRenewalSelected != -1) {
      props.getCustomerPaymentMethods(props.customer.id, state.selectedRenewal?.activityCategory?.id);
    }
  }, [indexRenewalSelected]);

  useEffect(() => {
    if (!paymentLoading && paymentMethods?.length === 0) {
      toast.error('Esse usuário não possui nenhum método de pagamento cadastrado.');
    }
  }, [props.paymentMethodsStatus === 2]);

  const renderButtons = useCallback(() => {

    if (!paymentLoading && paymentMethods?.length === 0 || !paymentMethods) {
      return <p style={{ color: 'red' }}>Esse usuário não possui nenhum método de pagamento cadastrado.</p>;
    }

    return (
      <>
        <Button className="mr-2" color="success" onClick={handleUpdatePresetPaymentToRenewalActivityPlan}>
          {paymentLoading ? <TailSpin color="white" height={20} width={20} /> : translate('activityPlanCheckout.modal-confirm.confirmPayment')}
        </Button>
        <Button color="danger" onClick={handleCancelUpdatePaymentMethods}>
          {paymentLoading ? <TailSpin color="white" height={20} width={20} /> : translate('activityPlanCheckout.modal-confirm.cancel')}
        </Button>
      </>
    );
  }, [paymentLoading, paymentMethods, props.paymentMethodsStatus, state]);

  return (
    <div>
      <SimpleTableList
        rows={tableContent}
        columNameKeys={translate('customerManage.customerActivityPlan.tableHeaders')}
        emptyTableMessage={''}
        indexSelected={indexSelected}
      />

      {tableContent.length > 0 && (
        <Row className={'justify-content-center'}>
          <JhiPagination
            items={
              props.customerActivityPlans ? getPaginationItemsNumber(props.customerActivityPlans.totalElements, props.customerActivityPlans.size) : 0
            }
            activePage={props.customerActivityPlans ? props.customerActivityPlans.number : 0}
            onSelect={handlePagination}
            maxButtons={7}
          />
        </Row>
      )}

      {state?.pagePlans?.content && (<>
        <Row>
          <h1>Renovações de planos</h1>
        </Row>
        <SimpleTableList
          rows={tableContentPageRenewal}
          columNameKeys={translate('customerManage.customerActivityPlan.presetRenewal.tableHeaders')}
          emptyTableMessage={''}
          indexSelected={indexRenewalSelected}
        />
      </>)}

      {indexRenewalSelected != -1 && (
        <>
          <PaymentMethodCardItems
            items={paymentMethods}
            selected={state.selectedRenewal?.presetPaymentMethodsToCharge}
            onSelected={handleOnSelectedPaymentMethod}
          />
          {renderButtons()}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  customerActivityPlans: storeState.customerActivityPlan?.page,
  status: storeState.customerActivityPlan?.status,
  statusRenewal: storeState.renewalActivityPlan.status,
  statusRenewalDelete: storeState.renewalActivityPlan.statusDelete,
  pagePlans: storeState.renewalActivityPlan.page,
  paymentMethods: storeState.customer.paymentMethods,
  paymentMethodsStatus: storeState.customer.paymentMethodsStatus,
  statusUpdatePayment: storeState.renewalActivityPlan.statusUpdatePayment
});

const mapDispatchToProps = {
  getCustomerActivityPlan,
  resetCustomerActivityPlan,
  fetchRenewalActivityPlan,
  deleteRenewalActivityPlan,
  getCustomerPaymentMethods,
  updatePresetPaymentToRenewalActivityPlan,
  resetCustomer,
  resetRenewal
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableCustomerActivityPlan);
