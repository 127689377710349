import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './new-activity-step3.css';
import { INewActivity } from 'app/shared/model/new-activity.model';
import { hideModal, showModal } from '../../../../components/modal/modal-action';
import { IDayOfWeek } from 'app/shared/model/day-of-week';
import { ITimeSlotStep3Header } from 'app/shared/model/time-slots';
import classnames from 'classnames';
import Button from 'app/components/button/Button';
import InputField from 'app/components/input-field/InputField';
import _ from 'lodash';
import NumberUtils from 'app/util/NumberUtils';
import { ISystemFee } from 'app/shared/model/system-fee.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { getPartnerPlaceSystemFee, reset } from 'app/entities/partner-place/partner-place-redux';

export interface INewActivityStep3Props extends StateProps, DispatchProps {
  object: INewActivity;
  backButtonHandler: (newActivity: INewActivity) => void;
  saveButtonHandler: (newActivity: INewActivity) => void;
}

export interface INewActivityStep3State {
  newActivity: INewActivity;
  daysOfWeek: IDayOfWeek[];
  timeSlots?: ITimeSlotStep3Header[];
  partnerPlaceSystemFee?: ISystemFee;
}

export class NewActivityStep3 extends React.Component<INewActivityStep3Props, INewActivityStep3State> {
  constructor(props) {
    super(props);

    const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

    this.state = {
      newActivity: props.object,
      daysOfWeek: this.mapDaysOfWeek(days),
      timeSlots: this.mapTimeSlots(days, props.object)
    };
  }

  componentDidMount() {
    this.props.getPartnerPlaceSystemFee();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.getPartnerPlaceSystemFeeStatus === HttpRequestStatus.SUCCESS) {
      this.setState({
        partnerPlaceSystemFee: newProps.partnerPlaceSystemFee
      });
      this.props.reset();
    }
  }

  mapDaysOfWeek = (days: string[]) => {
    return Array(8)
      .fill(null)
      .map((item, index) => {
        if (index === 0) {
          return {};
        }

        return {
          name: translate(`newActivity.daysOfWeek.name${index}`),
          value: days[index - 1]
        };
      });
  };

  mapTimeSlots = (days: string[], activity: INewActivity) => {
    if (!!!activity.presetActivitySchedules || activity.presetActivitySchedules.length === 0) {
      return null;
    }

    const uniqueTimes = _.uniqBy(activity.presetActivitySchedules, it => {
      return it.startAt;
    });

    const newItems = uniqueTimes.map(it => {
      const items = Array(7)
        .fill(null)
        .map((item, index) => {
          return {
            dayOfWeek: { value: days[index] },
            enabled:
              activity.presetActivitySchedules.filter(preset => preset.startAt === it.startAt && preset.dayOfWeek === days[index]).length >
              0
          };
        });

      return {
        time: it.startAt,
        timeSlots: items
      };
    });

    return newItems;
  };

  handleBackButtonPressed = () => {
    this.props.backButtonHandler(this.state.newActivity);
  };

  handleSaveButtonPressed = () => {
    this.props.saveButtonHandler(this.state.newActivity);
  };

  render() {
    return (
      <div className={'new-activity-step3-container'}>
        <div className={'new-activity-step3-input-divisor'}>
          <InputField
            title={translate('newActivity.labels.activityName')}
            value={this.state.newActivity.name}
            inputClassName={'new-activity-step3-input'}
            readOnly
          />
        </div>

        <div className={'new-activity-step3-input-divisor'}>
          <InputField
            title={translate('newActivity.labels.activityCategory')}
            value={
              this.state.newActivity.activityCategories != null
                ? _.truncate(this.state.newActivity.activityCategories.map(it => it.name).join(','), { length: 80 })
                : ''
            }
            inputClassName={'new-activity-step3-input'}
            readOnly
          />
        </div>

        <div className={'new-activity-step3-input-divisor'}>
          <InputField
            title={translate('newActivity.labels.activitySubCategory')}
            value={
              this.state.newActivity.activitySubCategories != null
                ? _.truncate(this.state.newActivity.activitySubCategories.map(it => it.name).join(','), { length: 80 })
                : ''
            }
            inputClassName={'new-activity-step3-input'}
            readOnly
          />
        </div>

        <div className={'new-activity-step3-input-divisor'}>
          <InputField
            title={translate('newActivity.labels.teacher')}
            value={this.state.newActivity.teacher}
            inputClassName={'new-activity-step3-input'}
            readOnly
          />
        </div>

        <div className={classnames('new-activity-step3-input-divisor', 'new-activity-step3-input-divisor-text-area')}>
          <InputField
            title={translate('newActivity.labels.description')}
            value={this.state.newActivity.description}
            inputClassName={'new-activity-step3-input-textarea'}
            readOnly
            isTextArea
          />
        </div>

        <div className={'new-activity-step3-input-price-and-slot-divisor'}>
          <div className={'new-activity-step3-input-price-and-slot-container'}>
            <InputField
              title={translate('newActivity.labels.price')}
              value={!!this.state.newActivity.price ? this.state.newActivity.price : translate('newActivity.messages.free')}
              inputClassName={'new-activity-step3-input'}
              isCurrency={!!this.state.newActivity.price}
              readOnly
            />
          </div>

          <div className={'new-activity-step3-input-price-and-slot-container'}>
            <InputField
              title={translate('newActivity.labels.priceActivity')}
              value={
                !!this.state.newActivity.price
                  ? NumberUtils.systemFeeCalculate(this.state.partnerPlaceSystemFee, Number(this.state.newActivity.price))
                  : translate('newActivity.messages.free')
              }
              inputClassName={'new-activity-step3-input'}
              readOnly
            />
          </div>
        </div>

        <div className={'new-activity-step3-input-duration-divisor'}>
          <div className={'new-activity-step3-input-price-and-slot-container'}>
            <InputField
              title={translate('newActivity.labels.slot')}
              value={!!this.state.newActivity.slots ? this.state.newActivity.slots.toString() : translate('newActivity.messages.unlimited')}
              inputClassName={'new-activity-step3-input'}
              readOnly
            />
          </div>
        </div>

        <div className={'new-activity-step3-input-duration-divisor'}>
          <InputField
            title={translate('newActivity.labels.duration')}
            value={
              !!this.state.newActivity.duration
                ? this.state.newActivity.duration.toString() + translate('newActivity.messages.minutes')
                : translate('newActivity.messages.notFilled')
            }
            inputClassName={'new-activity-step3-input'}
            readOnly
          />
        </div>

        {!!this.state.timeSlots &&
          this.state.timeSlots.length > 0 && (
            <div className={'new-activity-step3-input-calendar-divisor'}>
              <div className={'new-activity-step3-row'}>
                {
                  <div className={'new-activity-step3-calendar-days-of-week'}>
                    {this.state.daysOfWeek.map((item, index) => {
                      return (
                        <div
                          className={
                            index === 0
                              ? classnames(
                                  'new-activity-step3-calendar-days-of-week-item',
                                  'new-activity-step3-calendar-days-of-week-item-without-border-top'
                                )
                              : 'new-activity-step3-calendar-days-of-week-item'
                          }
                          key={`daysOfWeek${index}`}
                        >
                          {index === 0 && <div className={'new-activity-step3-calendar-green-line'} />}
                          {index !== 0 && <label className={'new-activity-step3-calendar-days-of-week-item-label'}>{item.name}</label>}
                        </div>
                      );
                    })}
                  </div>
                }
                <div className={'new-activity-step3-calendar-container'}>
                  {this.state.timeSlots.map((item, index) => {
                    return (
                      <div key={`timeSlots${index}`} className={'new-activity-step3-calendar-time-container'}>
                        <div className={'new-activity-step3-calendar-time-header'}>
                          <label className={'new-activity-step3-calendar-time-header-label'}>{item.time}</label>
                          <div className={'new-activity-step3-calendar-green-line'} />
                        </div>

                        {item.timeSlots.map((timeSlotItem, timeSlotIndex) => {
                          return (
                            <div
                              key={`timeSlotItem${timeSlotIndex}`}
                              className={
                                timeSlotIndex === item.timeSlots.length - 1
                                  ? classnames(
                                      'new-activity-step3-calendar-time-item-container',
                                      'new-activity-step3-calendar-time-item-container-border-bottom'
                                    )
                                  : 'new-activity-step3-calendar-time-item-container'
                              }
                            >
                              {!!timeSlotItem.enabled && (
                                <div className={'new-activity-step3-calendar-time-item-background'}>
                                  <img className={'new-activity-step3-calendar-item-image'} src={'content/images/ic-check-grey.png'} />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={'new-activity-step3-calendar-green-bar'} />
            </div>
          )}

        <div className={'new-activity-step3-button-container'}>
          <label className={'new-activity-step3-back-button-label'} onClick={this.handleBackButtonPressed}>
            {translate('newActivity.buttons.back')}
          </label>

          <Button
            onClick={this.props.createActivityLoading || this.props.createActivitySuccess ? () => undefined : this.handleSaveButtonPressed}
            title={translate('newActivity.buttons.finish')}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = storeState => ({
  createActivityLoading: storeState.activities.createActivityLoading,
  createActivitySuccess: storeState.activities.createActivitySuccess,
  getPartnerPlaceSystemFeeStatus: storeState.partnerPlaces.getPartnerPlaceSystemFeeStatus,
  partnerPlaceSystemFee: storeState.partnerPlaces.partnerPlaceSystemFee
});

const mapDispatchToProps = { showModal, hideModal, reset, getPartnerPlaceSystemFee };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewActivityStep3);
