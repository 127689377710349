import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import './delete-account.css';
import Button from 'app/components/button/Button';
import { deleteAccountFinish, reset } from '../delete-account.reducer';
import { IRootState } from 'app/shared/reducers';
import { toast, ToastOptions } from 'react-toastify';
import { Translate } from 'react-jhipster';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { TailSpin } from 'react-loader-spinner';

export interface IDeleteAccountProps extends StateProps, DispatchProps, RouteComponentProps<{ deleteKey: string } | any> { }

export interface IDeleteAccountState {
  deleteKey?: string;
  loading?: boolean;
  error?: boolean;
}
export class DeleteAccount extends React.Component<IDeleteAccountProps, IDeleteAccountState> {
  constructor(props: IDeleteAccountProps) {
    super(props);

    this.state = {
      deleteKey: this.props.match?.params?.deleteKey,
      loading: false,
      error: false
    };
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleOnSubmit(key: string) {
    this.setState({ loading: true });
    this.props.deleteAccountFinish(key);
  }

  componentWillReceiveProps(newProps: IDeleteAccountProps) {
    if (newProps.deleteStatus == HttpRequestStatus.SUCCESS) {
      this.props.reset();
      this.setState({ loading: false });
      toast.success('Conta deletada com sucesso.', {
        onClose: () => { window.location.href = 'https://www.keiken.app'; },
        autoClose: 3000
      });
    } else if (newProps.deleteStatus == HttpRequestStatus.ERROR) {
      this.setState({ loading: false });
      this.props.reset();
      toast.warn('Falha ao solicitar a exclusão da conta', {
        autoClose: 3000
      });
    }
  }
  render() {
    const { deleteKey, loading, error } = this?.state;
    const textTranslateKey = error == true ?
      'deleteAccount.error' :
      deleteKey == null ?
        'deleteAccount.warningError' :
        'deleteAccount.warning';
    const titleTranslateKey = deleteKey == null ?
      'deleteAccount.titleError' :
      'deleteAccount.title';
    return (
      <div className={'delete-account-container'}>
        <img src="content/images/forgot-password/background.png" className={'delete-account-banner'} />

        <div className={'delete-account-center-modal'}>

          <div className={'delete-account-header'}>
            <label className={'delete-label-title'}>
              <Translate contentKey={titleTranslateKey} />
            </label>
          </div>
          <div>
            <label className="warningLabel">
              <Translate contentKey={textTranslateKey} />
            </label>
          </div>
          {deleteKey != undefined && error == false ? (
            <div className="buttonDeleteAccount">
              {loading == true ? (
                <div style={{ marginBottom: 20 }}>
                  <TailSpin height="30" width="30" color="#00c87f" />
                </div>
              ) : (
                  <Button onClick={() => this.handleOnSubmit(deleteKey)} title={'Excluir conta'} />
                )}
            </div>
          ) : (
              <div className="contactSupportContainer">
                <a className="contactSupport" href="https://linktr.ee/keikenapp" >
                  <Translate contentKey={'deleteAccount.contactSupport'} />
                </a>
              </div>
            )}
          {deleteKey != undefined && loading == false && error == false && (
            <a className="cancelLabel" href="https://keiken.app" >
              <Translate contentKey={'deleteAccount.cancelLabel'} />
            </a>
          )}

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  deleteStatus: state?.deleteAccount?.deleteStatus
});

const mapDispatchToProps = {
  deleteAccountFinish,
  reset
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAccount);
