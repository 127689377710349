import * as React from 'react';

const RenewalItemRow = ({ title, value }) => {
  return (
    <p>
      <span className={'renewal-label-title'}>
        {title ?? ''}{': '}
      </span>
      <span className={'renewal-label-value'}>
        {value ?? ''}
      </span>
    </p>
  );
};

export default RenewalItemRow;
