import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { IActivity } from 'app/shared/model/activity';
import qs from 'qs';

export const ACTION_TYPES = {
  REQUEST_ACTIVITIES_PAGE: 'adminActivity/REQUEST_ACTIVITIES_PAGE',
  UPDATE_ACTIVITY: 'adminActivity/UPDATE_ACTIVITY',
  GET_ACTIVITY_BY_ID: 'adminActivity/GET_ACTIVITY',
  RESET: 'adminActivity/RESET'
};

export interface IActivityAdminState {
  readonly getActivityPageStatus: HttpRequestStatus;
  readonly getActivityStatus: HttpRequestStatus;
  readonly updateActivityStatus: HttpRequestStatus;
  readonly activity: IActivity;
  readonly activityPage: IPage<IActivity>;
}

const initialState: IActivityAdminState = {
  getActivityPageStatus: HttpRequestStatus.NOOP,
  getActivityStatus: HttpRequestStatus.NOOP,
  updateActivityStatus: HttpRequestStatus.NOOP,
  activity: null,
  activityPage: null
};

export default (state: IActivityAdminState = initialState, action: IActionForResponseEntity<any>): IActivityAdminState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.REQUEST_ACTIVITIES_PAGE):
        return {
          ...state,
          getActivityPageStatus: HttpRequestStatus.ONGOING
        };
    case FAILURE(ACTION_TYPES.REQUEST_ACTIVITIES_PAGE):
      return {
        ...state,
        getActivityPageStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.REQUEST_ACTIVITIES_PAGE):
      return {
        ...state,
        getActivityPageStatus: HttpRequestStatus.SUCCESS,
        activityPage: action.payload?.data
      };
    case REQUEST(ACTION_TYPES.GET_ACTIVITY_BY_ID):
      return {
        ...state,
        getActivityStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_ACTIVITY_BY_ID):
      return {
        ...state,
        getActivityStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_ACTIVITY_BY_ID):
      return {
        ...state,
        getActivityStatus: HttpRequestStatus.SUCCESS,
        activity: action.payload?.data
      };
    case REQUEST(ACTION_TYPES.UPDATE_ACTIVITY):
      return {
        ...state,
        updateActivityStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_ACTIVITY):
      return {
        ...state,
        updateActivityStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ACTIVITY):
      return {
        ...state,
        updateActivityStatus: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
    }
};

const apiUrl = '/api/activities';

export const getActiveActivities = (page: IPageRequest, search?: string, searchEmail?: string) => {
  let queryParams = qs.stringify(
    page,
    { skipNulls: true }
  );
  if (search) {
    queryParams = queryParams + '&search=' + search;
  }
  if (searchEmail) {
    queryParams = queryParams + '&email=' + searchEmail;
  }
  return {
    type: ACTION_TYPES.REQUEST_ACTIVITIES_PAGE,
    payload: axios.get<IPage<IActivity>>(apiUrl + '/active?' + queryParams)
  };
};

export const getActivityById = (id: string) => ({
  type: ACTION_TYPES.GET_ACTIVITY_BY_ID,
  payload: axios.get<IActivity[]>(apiUrl + '/' + id)
});

export const updateActivity = (activity: IActivity) => ({
  type: ACTION_TYPES.UPDATE_ACTIVITY,
  payload: axios.put(apiUrl + '/admin', activity)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
