import React from 'react';
import './simple-cards-list.scss';
import Button from 'app/components/button/Button';
import { isMobile } from 'react-device-detect';
import { translate } from 'react-jhipster';

export interface ISimpleCardsListProps {
  emptyTableMessage?: string;
  columNameKeys: string[];
  rows?: Array<Array<string | JSX.Element>>;
  textOverflow?: boolean;
  links?: Array<string | JSX.Element>;
  ignore?: number[];
  limit?: number;
}

export class SimpleCardsList extends React.Component<ISimpleCardsListProps, {}> {
  constructor(props) {
    super(props);
  }

  private renderCells = (row: Array<string | JSX.Element>, rowKey: string): JSX.Element[] => {
    const result: JSX.Element[] = [];
    row.map((item: string | JSX.Element, index: number) => {
      if (!this.props.ignore.includes(index)) {
        result.push(
          <div
            key={`${rowKey}[cell-${index}]`}
            className={`card-text item-max-width ${this.props.textOverflow && index === 2 ? 'text-overflow' : ''}`}
          >
            <p>{this.props.columNameKeys[index]}</p>
            <h4>{item}</h4>
          </div>
        );
      }
    });

    return result;
  };

  private renderEmptyContentMessage = () => {
    return (
      <li className={'row-container'}>
        <span>{this.props.emptyTableMessage != null ? this.props.emptyTableMessage : ''}</span>
      </li>
    );
  };

  private renderSlideBtns = id => {
    return (
      <div className={'sliders-container'}>
        <Button
          onClick={() => {
            this.scrollElement(id, 'left');
          }}
          outerClassName={'slider left'}
        />
        <Button
          onClick={() => {
            this.scrollElement(id, 'right');
          }}
          outerClassName={'slider right'}
        />
      </div>
    );
  };

  private scrollElement = (id, direction = 'right') => {
    document.getElementById(id).scroll({
      left: direction === 'left' ? document.getElementById(id).scrollLeft - 500 : document.getElementById(id).scrollLeft + 500,
      behavior: 'smooth'
    });
  };

  render() {
    return (
      <div className={'cards-container'} id={'cards-container'}>
        {this.props.rows != null && this.props.rows.length !== 0 && !isMobile && this.props.limit > 2
          ? this.renderSlideBtns('cards-container')
          : ''}
        {this.props.rows != null && this.props.rows.length !== 0
          ? this.props.rows.map(
              (row: Array<string | JSX.Element>, index: number) =>
                index < this.props.limit ? (
                  <div className={'activities-card'} key={`row-${index}`}>
                    {this.renderCells(row, `row-${index}`)}
                    {
                      <Button
                        outerClassName={'button-default-outer button'}
                        onClick={() => {
                          // @ts-ignore
                          window.open(this.props.links[index].props.href);
                        }}
                        title={translate('activity-active.buttonLabel')}
                      />
                    }
                  </div>
                ) : (
                  ''
                )
            )
          : this.renderEmptyContentMessage()}
      </div>
    );
  }
}

export default SimpleCardsList;
