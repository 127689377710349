import React from 'react';
import { KeikenPayInvoice } from '../../../../../shared/model/KeikenPay/KeikenPayInvoice';
import StringUtils from '../../../../../util/StringUtils';
import './menus.scss';

interface Props {

    data: KeikenPayInvoice;

}

export const Requested = ({ data }: Props) => {

    return(
        <>
            <div className="details-container">
                <div className="details-column">
                    <span>valor solicitado:</span>
                    <div className="details-content">{StringUtils.currencyPtBr(data.price)}</div>
                </div>
                <div className="details-column">
                    <span>reembolso:</span>
                    <div className="details-content">{StringUtils.currencyPtBr(data.subsidyValue)}</div>
                </div>
                <div className="details-column">
                    <span>valor subsidiado:</span>
                    <div className="details-content">{StringUtils.currencyPtBr(data.subsidyValueWithFee)}</div>
                </div>
            </div>
            <div className="details-container">
                <div className="details-column">
                    <span>taxas:</span>
                    <div className="details-content">{StringUtils.currencyPtBr(data.valueFee)}</div>
                </div>
                <div className="details-column">
                    <span>manutencão:</span>
                    <div className="details-content">{StringUtils.currencyPtBr(data.maintenanceFee)}</div>
                </div>
                <div className="details-column">
                    <span>matricula:</span>
                    <div className="details-content">{StringUtils.currencyPtBr(data.enrolmentFee)}</div>
                </div>
            </div>
            <div className="details-container">
                <div className="details-column">
                    <span>quantidade:</span>
                    <div className="details-content">{data.quantity}</div>
                </div>
                <div className="details-column">
                    <span>frequencia:</span>
                    <div className="details-content">{data.frequency}</div>
                </div>
            </div>
            <div className="details-container">
                <div className="details-column">
                    <span>Status da AI:</span>
                    <div className="details-content">{data.statusAI}</div>
                </div>
                <div className="details-column">
                    <span>Descrição da AI:</span>
                    <div className="details-content-wide">{data.descriptionAI}</div>
                </div>
            </div>
        </>
    );
};
