import React from 'react';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { translate } from 'react-jhipster';
import { IBilling } from 'app/shared/model/billing/billing';
import moment from 'moment';
import StringUtils from 'app/util/StringUtils';

interface BillingsTableProps {
  billings: IBilling[];
}

const CompanyBillingsTable = ({ billings }: BillingsTableProps) => {

  const handleTransformToTableContent = (): string[][] => {
    if (billings == null || billings?.length === 0) {
      return [];
    }
    const result: string[][] = [];
    billings.map(item => {
      const keikentransaction = item.keikenTransactions[0];
      const { createDate, processDate, value, status } = keikentransaction;
      result.push([
        moment(createDate).format('DD/MM/YYYY HH:mm:ss'),
        moment(processDate).format('DD/MM/YYYY HH:mm:ss'),
        StringUtils.currencyPtBr(value),
        status
      ]);
    });
    return result;
  };

  return (
    <div className="componentContainer">
      <h1 className="tableTitle">{translate('companyManage.table.title')}</h1>
      <SimpleTableList
        columNameKeys={translate('companyManage.table.headers')}
        rows={handleTransformToTableContent()}
        emptyTableMessage={translate('companyManage.table.empty')}
        textOverflow
      />
    </div>
  );
};

export default CompanyBillingsTable;
