import React, { useEffect } from 'react';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { JhiPagination, getPaginationItemsNumber, translate } from 'react-jhipster';
import moment from 'moment';
import { Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { getCompanyReports, deleteCompanyReport } from 'app/entities/company-report/company-report-reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ReportsTableProps extends StateProps, DispatchProps {
  companyId: number;
}

const CompanyReportsTable = ({ reports, deleteReport, companyId, getReports, deleteReportStatus }: ReportsTableProps) => {
  const [ currentPage, setCurrentPage ] = React.useState<number>(0);

  useEffect(() => {
    if (companyId) {
      getReports({ page: currentPage, size: 5 }, companyId);
    }
  }, [companyId, currentPage]);

  useEffect(() => {
    if (deleteReportStatus === HttpRequestStatus.SUCCESS) {
      getReports({ page: 0, size: 5 }, companyId);
    }
  }, [deleteReportStatus]);

  const handlePagination = (page: number) => {
    setCurrentPage(page - 1);
  };

  const renderActionColumn = (reportId: number): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <Button color="info" className={'centered-content margin1'} onClick={() => deleteReport(companyId, reportId)}>
          <FontAwesomeIcon icon={faTrashAlt as IconProp} />
        </Button>
      </div>
    );
  };

  const handleTransformToTableContent = (): string[][] => {
    if (reports == null) {
      return [];
    }
    const result = [];
    reports.content.map(item => {
      const { url, reportDate, id  } = item;
      result.push([
        url,
        moment(reportDate).format('DD/MM/YYYY'),
        renderActionColumn(id)
      ]);
    });
    return result;
  };

  return (
    <div>
      <h1 className="tableTitle">{translate('companyManage.reportTable.title')}</h1>
      <SimpleTableList
        columNameKeys={translate('companyManage.reportTable.headers')}
        rows={handleTransformToTableContent()}
        emptyTableMessage={translate('companyManage.reportTable.empty')}
        textOverflow
      />
      <Row className={'justify-content-center'}>
        <JhiPagination
          items={
            reports
              ? getPaginationItemsNumber(reports.totalElements, reports.size)
              : 0
          }
          activePage={reports ? reports.number : 1}
          onSelect={handlePagination}
          maxButtons={5}
        />
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  getReportsStatus: storeState.companyReport.getCompanyReportStatus,
  deleteReportStatus: storeState.companyReport.deleteCompanyReportStatus,
  reports: storeState.companyReport.companyReports
});

const mapDispatchToProps = {
  getReports: getCompanyReports,
  deleteReport: deleteCompanyReport
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyReportsTable);
