import * as React from 'react';
import { Button, Modal } from 'reactstrap';
import './select-option-modal.css';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { IPageRequest } from 'app/shared/model/page.model';
import { debounce } from 'lodash';

export interface ISelectOptionModalState {
  data?: any;
  displayProperty: [string];
  separator?: [string];
  title: [string];
  placeholderInput?: string;
  action?: (page?: IPageRequest, name?: any, idToSearch?: number) => void;
  cancelCallback: () => void;
  saveCallback: (data: any) => void;
  requestObject?: IPageRequest;
  infiniteScrolling?: boolean;
  search?: string;
  loadingMore?: boolean;
  idToSearch?: number;
  hideInput?: boolean;
  waitToPerformRequest?: boolean;
}

export interface ISelectOptionModalProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
  config: {
    stateAction?: string;
  };
}

class SelectOptionModal extends React.Component<ISelectOptionModalProps, ISelectOptionModalState> {
  listContent;
  constructor(props) {
    super(props);

    const requestObject: IPageRequest = {
      page: 0,
      size: 20
    };

    this.state = {
      data: props.config?.data ?? [],
      title: props.config?.title ?? '',
      displayProperty: props.config?.displayProperty,
      separator: props.config?.separator,
      cancelCallback: props.config?.cancelCallback,
      saveCallback: props.config?.saveCallback,
      action: props.config?.action,
      placeholderInput: props.config?.placeholderInput,
      requestObject,
      infiniteScrolling: props.config?.infiniteScrolling,
      idToSearch: props.config?.idToSearch,
      hideInput: props.config?.hideInput,
      waitToPerformRequest: props.config?.waitToPerformRequest
    };

    this.performAction = debounce(this.performAction, 500);
    this.listContent = React.createRef();
  }

  componentDidMount() {
    if (!!this.state.infiniteScrolling) {
      this.listContent.addEventListener('scroll', () => {
        if (this.listContent.scrollTop + this.listContent.clientHeight >= this.listContent.scrollHeight) {
          this.handleGetMoreItems();
        }
      });
    }
  }

  componentWillMount() {
    if (this.state.waitToPerformRequest) {
      return;
    }
    if (this.state.action) {
      if (!!this.state.idToSearch) {
        this.state.action(this.state.requestObject, null, this.state.idToSearch);
        return;
      }
      this.state.action(this.state.requestObject, null);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.data) {
      this.setState({ data: newProps.data });
      return;
    }
    if (newProps.page) {
      this.setState({
        data: this.state.loadingMore ? this.state.data!.concat(newProps.page.content) : newProps.page.content,
        loadingMore: false
      });
      return;
    }
  }

  mapItemName = (item: {}) => {
    let name = '';
    const separator = this.state.separator ? this.state.separator : ' - ';
    this.state.displayProperty.forEach((param: any) => {
      if (name) {
        name = name + separator;
      }

      let object = item;
      param.forEach(it => {
        let o = { ...object };
        it.split('.').forEach(s => {
          o = o[s];
        });

        object = object[it] ? object[it] : o != null ? o : '';
        if (typeof object === 'object') {
          object = object['name'] ? object['name'] : '';
        }
      });

      name = name + object;
    });

    name = name.replace(/\s?-?\s?\s?-\s?$/, '');
    name = name.replace(/\s-\s\s?-\s/, ' - ');
    return name;
  };

  handleOnChangeEvent = (item: { name: string; checked: boolean }) => {
    const newData = this.state.data;

    newData.map((dataItem: any) => {
      if (dataItem === item) {
        const newObject = dataItem;
        newObject.checked = !dataItem.checked;
        return newObject;
      }
      return dataItem;
    });

    this.setState({ data: newData });
  };

  public handleOnChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    this.setState({ search: text });
    this.performAction(text);
  };

  handleGetMoreItems = () => {
    const updateRequestObject = { ...this.state.requestObject, page: this.state.requestObject.page + 1 };
    this.setState({
      requestObject: updateRequestObject,
      loadingMore: true
    });

    if (!!this.state.idToSearch) {
      this.state.action(updateRequestObject, this.state.search, this.state.idToSearch);
      return;
    }

    this.state.action(updateRequestObject, this.state.search);
  };

  performAction = (text: string) => {
    const updateRequestObject = this.state.requestObject;
    updateRequestObject.page = 0;
    this.setState({
      requestObject: updateRequestObject
    });

    const isSearchCompayModal = this.state.placeholderInput === translate('editActivity.placeholders.searchCompany');
    const hasSomethingToSearch = this.state.search != undefined && this.state.search != '';
    const hasIdToSearch = this.state.idToSearch != null;

    // in search COMPANY modal, we gotta send the param like this {name: nameOfTheCompanyWeAreSearching}, others are just the text variable

    if (isSearchCompayModal && hasSomethingToSearch && hasIdToSearch) {
      this.state.action(updateRequestObject, { name: this.state.search }, this.state.idToSearch);
      return;
    }

    if (isSearchCompayModal && hasSomethingToSearch) {
      this.state.action(updateRequestObject, { name: this.state.search });
      return;
    }

    if (hasIdToSearch) {
      this.state.action(updateRequestObject, text, this.state.idToSearch);
      return;
    }

    this.state.action(updateRequestObject, text);
  };

  render() {
    return (
      <Modal isOpen className={'select-option-modal-card-style'}>
        <div className={'select-option-modal-content-container'}>
          <div>
            <label className={'select-option-modal-title-style'}>{this.state.title}</label>
          </div>

          {this.state.action &&
            !this.state.hideInput && (
              <div className={'select-option-modal-search-input-container'}>
                <input
                  value={this.state.search ? this.state.search : ''}
                  onChange={this.handleOnChangeText}
                  maxLength={200}
                  placeholder={this.state.placeholderInput ? this.state.placeholderInput : ''}
                  className={'select-option-modal-search-input'}
                />
              </div>
            )}

          <div
            className={'select-option-modal-div-style'}
            ref={div => {
              this.listContent = div;
            }}
          >
            {this.state.data.map((item: any, index: number) => (
              <div className={'select-option-modal-item-container'} key={index}>
                <label onClick={this.state.saveCallback.bind(this, item)} className={'select-option-modal-label'}>
                  {this.mapItemName(item)}
                </label>
              </div>
            ))}
          </div>

          <div className={'select-option-modal-buttons-container'}>
            <label onClick={this.state.cancelCallback} className={'button'}>
              <Translate contentKey={'entity.action.back'}>Cancel</Translate>
            </label>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (storeState: IRootState, ownProps: {
  config: {
    stateAction?: string;
  }
}) => ({
  page: ownProps.config.stateAction ? storeState[ownProps.config.stateAction].page : null,
  data: ownProps.config.stateAction ? storeState[ownProps.config.stateAction].data : null
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectOptionModal);
