import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal, ListGroup, Alert, ListGroupItem, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Container } from 'reactstrap';
import { ICustomer } from 'app/shared/model/customer/customer';
import { translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { IActivityCategory } from 'app/shared/model/activity-category';
import { IActivitySubCategory } from 'app/shared/model/activity-sub-category';
import { getActivityPlans } from 'app/entities/activity-plan/activity-plan.reducer';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { ICoParticipationCustomer } from 'app/shared/model/CoParticipation/ICoParticipationCustomer';
import { IPriceComposite } from 'app/shared/model/billing/price-composite';
import { getActivityPlanPriceComposite, reset as resetPrice } from 'app/entities/billing/billing.reducer';
import StringUtils from 'app/util/StringUtils';
import { TailSpin } from 'react-loader-spinner';
import { ActivityPlan } from 'app/shared/model/activity-plan';
import { IPartnerPlace } from 'app/shared/model/partner-place.model';
import { SelectOptionsDropdown } from '../../schedule-manage/activity-schedule-confirm-modal/activity-schedule-confirm-modal';
import { IPresetRenewalActivityPlan } from 'app/shared/model/billing/preset-renewal-activity-plan';
import PaymentMethodCardItems from './payment-methods-card-itens';
import { getCustomerPaymentMethods, IPaymentMethodCardItem, resetCustomer } from 'app/entities/customer/customer-redux';
import { PresetPaymentMethodsToCharge } from 'app/shared/model/preset-payment-methods-to-charge';

interface ActivityPlanCheckoutConfirmModalProps extends StateProps, DispatchProps {
  isOpen: boolean;
  customer: ICustomer;
  partnerPlace: IPartnerPlace;
  onClose: () => void;
  onConfirm: (
    categoryId: number,
    subCategoryId: number,
    intervalWeek: number,
    presetPaymentMethodsToCharge: PresetPaymentMethodsToCharge,
    priceComposite: IPriceComposite,
    withoutPayment: boolean,
    activityPlanId?: number,
    renewal?: IPresetRenewalActivityPlan
  ) => void;
}

const ActivityPlanCheckoutConfirmModal: React.FC<ActivityPlanCheckoutConfirmModalProps> = props => {
  const {
    isOpen,
    customer,
    partnerPlace,
    activityPlansPage,
    getActivityPlans: propsGetActivityPlans,
    getActivityPlanPriceComposite: propsGetPriceComposite,
    priceComposite: propsPriceComposite,
    resetPrice: propsResetPrice,
    onClose,
    onConfirm
  } = props;

  const [priceComposite, setPriceComposite] = useState<IPriceComposite>();
  const [withoutPayment, setWithoutPayment] = useState<boolean>(false);
  const [renewal, setRenewal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [activityPlan, setActivityPlan] = useState<ActivityPlan>();
  const [category, setCategory] = useState<IActivityCategory>();
  const [subCategory, setSubCategory] = useState<IActivitySubCategory>();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethodCardItem[]>();
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
  const [presetPaymentMethodsToCharge, setPresetPaymentMethodsToCharge] = useState<PresetPaymentMethodsToCharge>();

  useEffect(() => {
    if (activityPlan?.id != null && customer?.id != null) {
      const date = moment().format('yyyy-MM-DD');
      propsGetPriceComposite(activityPlan?.id, date, customer.id);
    }
  }, [customer, activityPlan]);

  useEffect(() => {
    if (partnerPlace?.id != null) {
      setActivityPlan(null);
      propsGetActivityPlans({ page: 0, size: 20 }, {
        ['partnerPlace.id']: partnerPlace.id
      });
    }
  }, [partnerPlace]);

  useEffect(() => {
    if (propsPriceComposite !== null) {
      setPriceComposite(propsPriceComposite);
      let activityCategory;
      if (propsPriceComposite?.coParticipationGroupActivityCategory?.activityCategory?.id != null) {
        activityCategory = propsPriceComposite?.coParticipationGroupActivityCategory?.activityCategory;
        setCategory(activityCategory);
      }

      setPaymentLoading(true);
      props.getCustomerPaymentMethods(customer.id, activityCategory?.id);

      propsResetPrice();
      setLoading(false);
    }
  }, [propsPriceComposite]);

  useEffect(() => {
    if (props.paymentMethods) {
      setPaymentMethods(props.paymentMethods);
      props.resetCustomer();
      setPaymentLoading(false);
    }
  }, [props.paymentMethods]);

  function handleClose() {
    if (loading) return;
    setCategory(null);
    setSubCategory(null);
    setPresetPaymentMethodsToCharge(null);
    setPaymentMethods(null);
    setPriceComposite(null);
    setActivityPlan(null);
    setWithoutPayment(false);
    setRenewal(false);
    onClose();
  }

  function handleConfirm() {
    if (loading) return;
    if (category == null || subCategory == null) {
      toast.error('selecione categoria e sub-categoria');
      return;
    }
    const presetRenewal: IPresetRenewalActivityPlan = renewal == true && presetPaymentMethodsToCharge
      ? {
        activityPlan: { id: activityPlan.id },
        activityCategory: { id: category.id },
        activitySubCategory: { id: subCategory.id },
        presetPaymentMethodsToCharge
      } : undefined;

    setLoading(true);
    onConfirm(
      category.id,
      subCategory.id,
      null,
      presetPaymentMethodsToCharge,
      priceComposite,
      withoutPayment,
      activityPlan.id,
      presetRenewal
    );
  }

  function handleSelectCategory(item: IActivityCategory) {
    setCategory(item);
  }

  function handleSelectSubCategory(item: IActivitySubCategory) {
    setSubCategory(item);
  }

  function handleOnClickRenewal() {
    setRenewal(prevState => !prevState);
  }

  function handleSelectActivityPlan(item: ActivityPlan) {
    setActivityPlan(item);
    setLoading(true);
  }

  const handleOnSelectedPaymentMethod = (item: PresetPaymentMethodsToCharge) => {
    setPresetPaymentMethodsToCharge(item);
  };

  return (
    <Modal
      className="modal-lg"
      backdrop
      isOpen={isOpen}
      toggle={handleClose}
    >
      <ModalHeader toggle={handleClose}>{translate('activityPlanCheckout.label.title')}</ModalHeader>
      <ModalBody>
        <Container fluid>
          <Row style={{ marginBottom: 40 }}>
            <Col>
              <h4>Selecionar plano:</h4>
              <ListGroup>
                {activityPlansPage?.content?.map?.((it, index) => (
                  <ListGroupItem
                    key={`li-plan-checkout-${it.id}-${index}`}
                    action
                    active={activityPlan?.id == it.id}
                    onClick={() => handleSelectActivityPlan(it)}
                    tag="a"
                  >
                    <Row>
                      {it?.image?.imageUrl &&
                        <div className={'action-column-container-image marginR2'}>
                          <img src={it?.image?.imageUrl} className={'action-column-image'} />
                        </div>
                      }
                      {it.name}
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
              {loading && <TailSpin color="black" height={30} width={30} />}
            </Col>
          </Row>
          {loading == false && (
            <>
              <Row>
                <Col md="6">
                  <Row>
                    {'Parceiro: ' + partnerPlace?.fantasyName ?? ''}
                  </Row>
                  <Row>
                    {'Atividade: ' + activityPlan?.name ?? 'Não selecionado'}
                  </Row>
                  <Row>
                    {'Cliente: ' + customer?.name ?? ''}
                  </Row>
                  <Row className="row-categories">
                    <SelectOptionsDropdown<IActivityCategory>
                      title="Categoria"
                      nameKey="name"
                      value={category}
                      options={activityPlan?.activityCategories ?? []}
                      onSelect={handleSelectCategory}
                    />
                  </Row>
                  <Row>
                    <SelectOptionsDropdown<IActivitySubCategory>
                      title="Sub-Categoria"
                      nameKey="name"
                      value={subCategory}
                      options={activityPlan?.activitySubCategories ?? []}
                      onSelect={handleSelectSubCategory}
                    />
                  </Row>
                  <Row>
                    <div className={'category-manage-inner-addon__radio'}>
                      <input checked={renewal} onClick={handleOnClickRenewal} type={'radio'} />
                      <label className={'company-manage-checkbox-label'}>{translate(`activityPlanCheckout.modal-confirm.renewal.${renewal ?? false}`)}</label>
                    </div>
                  </Row>
                  <Row>
                    <div className={'category-manage-inner-addon__radio'}>
                      <input checked={withoutPayment} onClick={() => setWithoutPayment(prev => !prev)} type={'radio'} />
                      <label className={'company-manage-checkbox-label'}>{translate('activityPlanCheckout.modal-confirm.free')}</label>
                    </div>
                    {renewal == true && (withoutPayment == true || presetPaymentMethodsToCharge == null) && (
                      <Alert color="danger">
                        <label>{translate('activityPlanCheckout.modal-confirm.alert')}</label>
                      </Alert>
                    )}
                  </Row>
                </Col>
                <Col md="6">
                  <Row>
                    <Col>{'preço:'}</Col>
                    <Col>{StringUtils.currencyPtBr(priceComposite?.price)}</Col>
                  </Row>
                  <Row>
                    <Col>{'preço com desconto:'}</Col>
                    <Col>{StringUtils.currencyPtBr(_.subtract(priceComposite?.price, priceComposite?.discountValue))}</Col>
                  </Row>
                  <Row>
                    <Col>{'benefício:'}</Col>
                    <Col>{StringUtils.currencyPtBr(priceComposite?.subsidyValue)}</Col>
                  </Row>
                  <Row>
                    <Col>{'preço final:'}</Col>
                    <Col>{StringUtils.currencyPtBr(priceComposite?.priceWithDiscountAndSubsidy)}</Col>
                  </Row>
                  <Row>
                    <Col>{'benefício categoria:'}</Col>
                    <Col>{priceComposite?.coParticipationGroupActivityCategory?.activityCategory?.name}</Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  {paymentLoading ? (
                    <TailSpin />
                  ) : !paymentLoading && paymentMethods?.length === 0 || !paymentMethods ? (
                    <p style={{ color: 'red' }}>Esse usuário não possui nenhum método de pagamento cadastrado.</p>
                  )
                    : (
                      <PaymentMethodCardItems
                        items={paymentMethods}
                        selected={presetPaymentMethodsToCharge}
                        onSelected={handleOnSelectedPaymentMethod}
                      />
                    )}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleConfirm}>
          {loading ? <TailSpin color="black" height={30} width={30} /> : translate('activityPlanCheckout.modal-confirm.confirm')}
        </Button>{' '}
        <Button color="danger" onClick={handleClose}>
          {loading ? <TailSpin color="black" height={30} width={30} /> : translate('activityPlanCheckout.modal-confirm.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  priceCompositeStatus: storeState.billing.statusActivityPlanPriceComposite,
  priceComposite: storeState.billing.priceComposite,
  activityPlansPage: storeState.activityPlans.page,
  paymentMethods: storeState.customer.paymentMethods
});
const mapDispatchToProps = {
  getActivityPlanPriceComposite,
  getActivityPlans,
  getCustomerPaymentMethods,
  resetCustomer,
  resetPrice
};
type DispatchProps = typeof mapDispatchToProps;

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityPlanCheckoutConfirmModal);
