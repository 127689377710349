import axios from 'axios';
import { Storage } from 'react-jhipster';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { setLocale } from 'app/shared/reducers/locale';
import jwt_decode from 'jwt-decode';
import { AUTHORITIES } from 'app/config/constants';

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  SESSION_NOT_AUTH: 'authentication/SESSION_NOT_AUTH',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE'
};

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
const PARTNER_ID = 'partner-id';
const PARTNER_NAME = 'partner-name';

const initialState = {
  loading: false,
  isAuthenticated: false,
  isPartner: false,
  isApplicationAdmin: false,
  isCustomer: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: null as string, // Errors returned from server side
  redirectMessage: null as string,
  sessionHasBeenFetched: false,
  redirectUrl: null as string
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (state: AuthenticationState = initialState, action): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        errorMessage: action.payload,
        showModalLogin: true,
        loginError: true
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        isPartner: false,
        isCustomer: false,
        isApplicationAdmin: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.payload
      };
    case ACTION_TYPES.SESSION_NOT_AUTH:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        isPartner: false,
        isCustomer: false,
        isApplicationAdmin: false,
        redirectUrl: action.payload
      };
    case SUCCESS(ACTION_TYPES.LOGIN):
      return {
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
        showModalLogin: true
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      let isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
      let isPartner = false;
      let isApplicationAdmin = false;
      let isCustomer = false;

      const payload = action.payload.data;
      if (!!payload.user) {
        if (payload.user.authorities.filter(it => it.name === AUTHORITIES.PARTNER).length > 0) {
          isAuthenticated = payload.user.activated;
          isPartner = true;
        }

        if (payload.user.authorities.filter(it => it.name === AUTHORITIES.APPLICATION_ADMIN).length > 0) {
          isAuthenticated = payload.user.activated;
          isApplicationAdmin = true;
        }

        if (payload.user.authorities.filter(it => it.name === AUTHORITIES.CUSTOMER).length > 0) {
          isAuthenticated = payload.user.activated;
          isCustomer = true;
        }
      }

      return {
        ...state,
        isAuthenticated,
        isPartner,
        isApplicationAdmin,
        isCustomer,
        loading: false,
        sessionHasBeenFetched: true,
        account: action.payload.data
      };
    }
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        showModalLogin: true,
        redirectMessage: action.message
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
        isPartner: false,
        isCustomer: false,
        isApplicationAdmin: false,
        redirectUrl: null
      };
    default:
      return state;
  }
};

export const displayAuthError = message => ({ type: ACTION_TYPES.ERROR_MESSAGE, message });

export const getSession = () => dispatch =>
  dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('api/account')
  });

export const getAdminSession = () => dispatch =>
  dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('api/account/admin/me')
  });

export const getPartnerSession = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SESSION_NOT_AUTH,
    payload: process.env.NODE_ENV === 'development' ? 'https://staging.partner.keiken.app' : 'https://partner.keiken.app'
  });

  // if (result.value.status === 200) {
  //   const partnerId = result.value.data.partnerPlaces[0].id;
  //   Storage.local.set(PARTNER_ID, partnerId);
  //   Storage.local.set(PARTNER_NAME, result.value.data.name);
  //   Storage.session.set(PARTNER_ID, partnerId);
  //   Storage.session.set(PARTNER_NAME, result.value.data.name);
  // }

  return result;
};

export const getApplicationAdminSession = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('api/application-admins/me')
  });
};

export const getCustomerSession = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('api/customers/me')
  });

  if (result.value.status !== 200) {
    return;
  }
  return result;
};

export const login = (username, password) => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post('api/authenticate', { username, password })
  });

  if (result.value.status !== 200) {
    return;
  }

  const jwt = result.value.data.tokenId;
  Storage.session.set(AUTH_TOKEN_KEY, jwt);
  Storage.local.set(AUTH_TOKEN_KEY, jwt);

  const decodeJwt = jwt_decode(jwt);

  if (decodeJwt.auth === AUTHORITIES.ADMIN) {
    await dispatch(getAdminSession());
    const account = getState().authentication.account;
    if (account && account.langKey) {
      await dispatch(setLocale(account.langKey));
    }

    return;
  }

  if (decodeJwt.auth === AUTHORITIES.PARTNER) {
    await dispatch(getPartnerSession());

    // const account = getState().authentication.account;
    // if (account && account.user && account.user.langKey) {
    //   await dispatch(setLocale(account.langKey));
    // }

    return;
  }

  if (decodeJwt.auth === AUTHORITIES.APPLICATION_ADMIN) {
    await dispatch(getApplicationAdminSession());

    const account = getState().authentication.account;
    if (account && account.user && account.user.langKey) {
      await dispatch(setLocale(account.langKey));
    }

    return;
  }

  if (decodeJwt.auth === AUTHORITIES.CUSTOMER) {
    await dispatch(getCustomerSession());

    const account = getState().authentication.account;
    if (account && account.user && account.user.langKey) {
      await dispatch(setLocale(account.langKey));
    }

    return;
  }
};

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }

  if (Storage.session.get(PARTNER_ID)) {
    Storage.session.remove(PARTNER_ID);
  }

  if (Storage.local.get(PARTNER_ID)) {
    Storage.local.remove(PARTNER_ID);
  }
};

export const logout = () => dispatch => {
  clearAuthToken();
  dispatch({
    type: ACTION_TYPES.LOGOUT
  });
};

export const clearAuthentication = messageKey => (dispatch, getState) => {
  clearAuthToken();
  if (messageKey) {
    dispatch(displayAuthError(messageKey));
  }
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH
  });
};
