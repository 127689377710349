import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from '../../../app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from '../../../app/util/redux-interface';
import { HttpRequestStatus } from '../../../app/shared/model/enum/HttpRequestStatus';
import qs from 'qs';
import { ITaxInvoice } from '../../shared/model/tax-invoice.model';

export const ACTION_TYPES = {
  GET_TAX_INVOICE: 'taxInvoice/GET_TAX_INVOICE',
  POST_NF_TO_PARTNER_PLACE: 'taxInvoice/POST_NF_TO_PARTNER_PLACE',
  RESET: 'taxInvoice/RESET'
};

const initialState = {
  taxInvoices: [] as ITaxInvoice[],

  getTaxInvoiceStatus: HttpRequestStatus.NOOP,
  postNfToPartnerPlaceStatus: HttpRequestStatus.NOOP
};

export type ITaxInvoiceState = Readonly<typeof initialState>;

// Reducer
export default (state: ITaxInvoiceState = initialState, action: IActionForResponseEntity<ITaxInvoiceState>): ITaxInvoiceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_TAX_INVOICE):
      return {
        ...state,
        getTaxInvoiceStatus: HttpRequestStatus.ONGOING
      };
    case SUCCESS(ACTION_TYPES.GET_TAX_INVOICE):
      return {
        ...state,
        taxInvoices: action.payload.data as unknown as ITaxInvoice[],
        getTaxInvoiceStatus: HttpRequestStatus.SUCCESS
      };
    case FAILURE(ACTION_TYPES.GET_TAX_INVOICE):
      return {
        ...state,
        getTaxInvoiceStatus: HttpRequestStatus.ERROR
      };
      case REQUEST(ACTION_TYPES.POST_NF_TO_PARTNER_PLACE):
        return {
          ...state,
          postNfToPartnerPlaceStatus: HttpRequestStatus.ONGOING
        };
      case FAILURE(ACTION_TYPES.POST_NF_TO_PARTNER_PLACE):
        return {
          ...state,
          postNfToPartnerPlaceStatus: HttpRequestStatus.ERROR
        };
      case SUCCESS(ACTION_TYPES.POST_NF_TO_PARTNER_PLACE):
        return {
          ...state,
          postNfToPartnerPlaceStatus: HttpRequestStatus.SUCCESS
        };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/tax-invoices';

// Actions

export const getTaxInvoices = (month: string, year: string) => {
  const requestUrl = `${apiUrl}?${qs.stringify({ month, year })}`;
  return {
    type: ACTION_TYPES.GET_TAX_INVOICE,
    payload: axios.get<ITaxInvoice[]>(requestUrl)
  };
};

export const postNfToPartnerPlace = (partnerPlaceId: number) => ({
  type: ACTION_TYPES.POST_NF_TO_PARTNER_PLACE,
  payload: axios.post(`/api/partner-places/${partnerPlaceId}/nf`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
