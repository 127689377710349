import { IDiscountFee, ISystemFee } from './system-fee.model';
import { IPriceTable } from './price-table';
import { ICompanyWallet } from './company-wallet';
import { IImage } from './image';
import { IActivityCategory } from './activity-category';

export enum ICompanyStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}
export interface ICompany {
  id?: number;
  name?: string;
  cnpj?: string;
  keikenPayEnabled?: boolean;
  discountValue?: number;
  companyDependent?: ICompany;
  discountFee?: IDiscountFee;
  keikenPayActivityCategories?: ICompanyKeikenPayActivityCategory[];
  priceTable?: IPriceTable;
  status?: ICompanyStatus;
  wallet?: ICompanyWallet;
  image?: IImage;
  promptAi?: string;
}

export interface ICompanyKeikenPayActivityCategory {
  id?: number;
  activityCategory?: IActivityCategory;
  enabled?: boolean;
  keikenPayFee?: ISystemFee;
}

export interface ICompanyReport {
  id: number;
  url: string;
  reportDate: string;
  status?: 'ENABLE' | 'DISABLE';
  company?: ICompany;
}
