import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { IPartnerPlace } from 'app/shared/model/partner-place.model';
import { IBankAccount } from 'app/shared/model/bank-account';

export const ACTION_TYPES = {
  GET_KEIKEN_BANK_ACCOUNTS: 'bankAccounts/GET_KEIKEN_BANK_ACCOUNTS',
  GET_PARTNER_BANK_ACCOUNT: 'bankAccounts/GET_PARTNER_BANK_ACCOUNT',
  SAVE_PARTNER_BANK_ACCOUNT: 'bankAccounts/SAVE_PARTNER_BANK_ACCOUNT',
  RESET: 'bankAccounts/RESET'
};

const initialState = {
  getPartnerBankAccountSuccess: false,
  getPartnerBankAccountError: false,
  savePartnerBankAccountSuccess: false,
  savePartnerBankAccountError: false,
  bankAccount: null,
  keikenBankAccounts: null
};

export type IBankAccountState = Readonly<typeof initialState>;

// Reducer
export default (state: IBankAccountState = initialState, action: IActionForResponseEntity<IBankAccount>): IBankAccountState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_PARTNER_BANK_ACCOUNT):
      return {
        ...initialState
      };
    case FAILURE(ACTION_TYPES.GET_PARTNER_BANK_ACCOUNT):
      return {
        ...initialState,
        getPartnerBankAccountError: true
      };
    case SUCCESS(ACTION_TYPES.GET_PARTNER_BANK_ACCOUNT):
      return {
        ...initialState,
        getPartnerBankAccountSuccess: true,
        bankAccount: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_KEIKEN_BANK_ACCOUNTS):
      return {
        ...initialState
      };
    case FAILURE(ACTION_TYPES.GET_KEIKEN_BANK_ACCOUNTS):
      return {
        ...initialState
      };
    case SUCCESS(ACTION_TYPES.GET_KEIKEN_BANK_ACCOUNTS):
      return {
        ...initialState,
        keikenBankAccounts: action.payload.data
      };
    case REQUEST(ACTION_TYPES.SAVE_PARTNER_BANK_ACCOUNT):
      return {
        ...initialState
      };
    case FAILURE(ACTION_TYPES.SAVE_PARTNER_BANK_ACCOUNT):
      return {
        ...initialState,
        savePartnerBankAccountError: true
      };
    case SUCCESS(ACTION_TYPES.SAVE_PARTNER_BANK_ACCOUNT):
      return {
        ...initialState,
        savePartnerBankAccountSuccess: true
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/bank-accounts';

export const getKeikenBankAccounts = () => ({
  type: ACTION_TYPES.GET_KEIKEN_BANK_ACCOUNTS,
  payload: axios.get(`/api/keiken-bank-accounts`)
});

export const getPartnerBankAccount = () => ({
  type: ACTION_TYPES.GET_PARTNER_BANK_ACCOUNT,
  payload: axios.get(`${apiUrl}/partner`)
});

export const savePartnerBankAccount = (bankAccount: IBankAccount) => ({
  type: ACTION_TYPES.SAVE_PARTNER_BANK_ACCOUNT,
  payload: axios.post(`${apiUrl}/partner`, bankAccount)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
