import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner, Row, Col, Container, Dropdown, Table, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { IActivity } from 'app/shared/model/activity';
import { ICustomer } from 'app/shared/model/customer/customer';
import { IActivitySchedule } from 'app/shared/model/activity-schedule/activity-schedule';
import { translate } from 'react-jhipster';
import { ICustomerActivity } from 'app/shared/model/customer-activity.model';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { IActivityCategory } from 'app/shared/model/activity-category';
import { IActivitySubCategory } from 'app/shared/model/activity-sub-category';
import CoParticipationCustomerToRenewal from 'app/components/CoParticipationCustomerToRenewal/CoParticipationCustomerToRenewal';
import { ICoParticipationGroupActivityCategory } from 'app/shared/model/CoParticipation/ICoParticipationGroupActivityCategory';
import { getCoParticipationCustomerToRenewal } from 'app/entities/renewal-activity-schedule/renewal-activity-schedule-redux';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { ICoParticipationCustomer } from 'app/shared/model/CoParticipation/ICoParticipationCustomer';
import { IPriceComposite } from 'app/shared/model/billing/price-composite';
import { getActivitySchedulePriceComposite, reset as resetPrice } from 'app/entities/billing/billing.reducer';
import StringUtils from 'app/util/StringUtils';
import { TailSpin } from 'react-loader-spinner';
import PaymentMethodCardItems from '../../customers-manage/components/payment-methods-card-itens';
import { IPresetRenewalActivityPlan } from 'app/shared/model/billing/preset-renewal-activity-plan';
import { getCustomerPaymentMethods, IPaymentMethodCardItem, resetCustomer } from 'app/entities/customer/customer-redux';
import { PresetPaymentMethodsToCharge } from 'app/shared/model/preset-payment-methods-to-charge';
import { PresetRenewalActivityPlan } from 'app/shared/model/customer-activity-plan';
import { IPage } from 'app/shared/model/page.model';
interface ActivityScheduleModalProps extends StateProps, DispatchProps {
  isOpen: boolean;
  activity: IActivity;
  customer: ICustomer;
  activitySchedule: IActivitySchedule;
  customerActivity: ICustomerActivity;
  isEdit: boolean;
  onClose: () => void;
  onConfirm: (
    categoryId: number,
    subCategoryId: number,
    intervalWeek?: number,
    presetPaymentMethodsToCharge?: PresetPaymentMethodsToCharge,
    priceComposite?: IPriceComposite,
    withoutPayment?: boolean,
    activityPlanId?: number,
    renewal?: IPresetRenewalActivityPlan) => void;
}

const ActivityScheduleConfirmModal: React.FC<ActivityScheduleModalProps> = props => {

  const {
    isOpen,
    activity,
    customer,
    activitySchedule,
    onClose,
    onConfirm,
    customerActivity,
    isEdit = false,
    getActivitySchedulePriceComposite: propsGetActivitySchedulePriceComposite,
    priceComposite: propsPriceComposite,
    resetPrice: propsResetPrice
  } = props;

  const [presetPaymentMethodsToCharge, setPresetPaymentMethodsToCharge] = useState<PresetPaymentMethodsToCharge>();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethodCardItem[]>();
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

  const [priceComposite, setPriceComposite] = useState<IPriceComposite>();
  const [withoutPayment, setWithoutPayment] = useState<boolean>(false);
  const [renewal, setRenewal] = useState<boolean>(false);
  const [weeklyInterval, setWeeklyInterval] = useState<IWeekly>(defaultWeeklyOptions[0]);
  const [category, setCategory] = useState(customerActivity?.activityCategory);
  const [subCategory, setSubCategory] = useState(customerActivity?.activitySubCategory);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (activitySchedule?.id != null && customer?.id != null) {
      propsGetActivitySchedulePriceComposite(activitySchedule?.id, activity?.id, customer.id);
    }
  }, [customer, activitySchedule]);

  useEffect(() => {
    if (propsPriceComposite !== null) {
      setPriceComposite(propsPriceComposite);
      let activityCategory;
      if (propsPriceComposite?.coParticipationGroupActivityCategory?.activityCategory?.id != null) {
        activityCategory = propsPriceComposite?.coParticipationGroupActivityCategory?.activityCategory;
        setCategory(activityCategory);
      }

      setPaymentLoading(true);

      propsResetPrice();
      setLoading(false);
    }
  }, [propsPriceComposite]);

  useEffect(() => {
    if (props.customer?.id && category?.id) {
      props.getCustomerPaymentMethods(props.customer.id, category?.id);
      setPaymentLoading(true);
    }
  }, [props.customer, category]);

  useEffect(() => {
    if (props.paymentMethods) {
      setPaymentMethods(props.paymentMethods);
      props.resetCustomer();
      setPaymentLoading(false);
    }
  }, [props.paymentMethods]);

  function handleClose() {
    if (loading) return;
    setCategory(null);
    setSubCategory(null);
    setLoading(false);
    onClose();
  }

  function handleConfirm() {
    if (loading) return;
    if (category == null || subCategory == null) {
      toast.error('selecione categoria e sub-categoria');
      return;
    }
    const hasAvailableValueInCoParticipationGroup = (presetPaymentMethodsToCharge?.coParticipationGroup?.maxSpendingGroupAvailable == 0) && presetPaymentMethodsToCharge.customerPaymentMethod == null;
    if (hasAvailableValueInCoParticipationGroup) {
      toast.warn('Usuário sem beneficio disponivel para esta atividade');
      return;
    }
    setLoading(true);
    const intervalWeek = renewal == true ? weeklyInterval?.value : null;
    onConfirm(category.id, subCategory.id, intervalWeek, presetPaymentMethodsToCharge, priceComposite, withoutPayment);
    setCategory(null);
    setSubCategory(null);
    setPriceComposite(null);
    setWithoutPayment(false);
    setLoading(true);
    setPaymentLoading(false);
    setPaymentMethods(null);
    setPresetPaymentMethodsToCharge(null);
  }

  function handleSelectCategory(item: IActivityCategory) {
    setCategory(item);
  }

  function handleSelectSubCategory(item: IActivitySubCategory) {
    setSubCategory(item);
  }

  function getDateFromActivitySchedule(item: IActivitySchedule) {
    if (item?.date == null) {
      return '';
    }
    const date = moment(item.date).format('dddd - DD/MM/yyyy');
    const time = moment(item.date).format('HH:mm');
    return `${date} - ${time}`;
  }

  function handleOnClickRenewal() {
    setRenewal(prevState => !prevState);
  }

  function handleSelectWeeklyOption(item: IWeekly) {
    setWeeklyInterval(item);
  }

  const handleOnSelectedPaymentMethod = (item: PresetPaymentMethodsToCharge) => {
    setPresetPaymentMethodsToCharge(item);
  };

  if (activitySchedule == null) {
    return (<div />);
  }

  const scheduleDate = getDateFromActivitySchedule(activitySchedule);
  const customerActivityDate = getDateFromActivitySchedule(customerActivity?.activitySchedule);

  const i18nTitle = isEdit ? 'edit-title' : 'title';

  const needCustomerPaymentMethod = priceComposite?.priceWithDiscountAndSubsidy >= 0 && !presetPaymentMethodsToCharge?.customerPaymentMethod;

  return (
    <Modal
      className="modal-lg"
      backdrop
      isOpen={isOpen}
      toggle={handleClose}
    >
      <ModalHeader toggle={handleClose}>{translate('scheduleManage.modal-confirm.' + i18nTitle)}</ModalHeader>
      <ModalBody>
        <Container fluid>
          <Row>
            <Col md="6">
              <Row>
                {'Parceiro: ' + activity?.partnerPlace?.fantasyName ?? ''}
              </Row>
              <Row>
                {'Atividade: ' + activity?.name ?? ''}
              </Row>
              <Row>
                {'Cliente: ' + customer?.name ?? ''}
              </Row>
              {
                isEdit &&
                <>
                  <Row>
                    {'De: '}
                  </Row>
                  <Row>
                    {customerActivityDate}
                  </Row>
                  <Row>
                    {'Para: '}
                  </Row>
                  <Row>
                    {scheduleDate}
                  </Row>
                  <Row className="row-categories">
                    <SelectOptionsDropdown<IActivityCategory>
                      title="Categoria"
                      nameKey="name"
                      value={category}
                      options={activity?.activityCategories}
                      onSelect={handleSelectCategory}
                    />
                  </Row>
                  <Row>
                    <SelectOptionsDropdown<IActivitySubCategory>
                      title="Sub-Categoria"
                      nameKey="name"
                      value={subCategory}
                      options={activity?.activitySubCategories}
                      onSelect={handleSelectSubCategory}
                    />
                  </Row>
                </>
              }
              {!isEdit &&
                <>
                  <Row>
                    {scheduleDate}
                  </Row>
                  <Row className="row-categories">
                    <SelectOptionsDropdown<IActivityCategory>
                      title="Categoria"
                      nameKey="name"
                      value={category}
                      options={activity?.activityCategories}
                      onSelect={handleSelectCategory}
                    />
                  </Row>
                  <Row>
                    <SelectOptionsDropdown<IActivitySubCategory>
                      title="Sub-Categoria"
                      nameKey="name"
                      value={subCategory}
                      options={activity?.activitySubCategories}
                      onSelect={handleSelectSubCategory}
                    />
                  </Row>
                  <Row>
                    <div className={'category-manage-inner-addon__radio'}>
                      <input checked={renewal} onClick={handleOnClickRenewal} type={'radio'} />
                      <label className={'company-manage-checkbox-label'}>{translate('scheduleManage.modal-confirm.renewal')}</label>
                    </div>
                  </Row>
                  <Row>
                    {renewal &&
                      <SelectOptionsDropdown<IWeekly>
                        title="Intervalo semanas"
                        nameKey="name"
                        value={weeklyInterval}
                        options={defaultWeeklyOptions}
                        onSelect={handleSelectWeeklyOption}
                      />
                    }
                  </Row>
                  <Row>
                    <div className={'category-manage-inner-addon__radio'}>
                      <input checked={withoutPayment} onClick={() => setWithoutPayment(prev => !prev)} type={'radio'} />
                      <label className={'company-manage-checkbox-label'}>{translate('scheduleManage.modal-confirm.free')}</label>
                    </div>
                  </Row>
                </>
              }
              <Row>
                <Col>{'preço:'}</Col>
                <Col>{StringUtils.currencyPtBr(priceComposite?.price)}</Col>
              </Row>
              <Row>
                <Col>{'preço com desconto:'}</Col>
                <Col>{StringUtils.currencyPtBr(_.subtract(priceComposite?.price, priceComposite?.discountValue))}</Col>
              </Row>
              <Row>
                <Col>{'benefício:'}</Col>
                <Col>{StringUtils.currencyPtBr(priceComposite?.subsidyValue)}</Col>
              </Row>
              <Row>
                <Col>{'preço final:'}</Col>
                <Col style={{ color: needCustomerPaymentMethod ? 'red' : 'black' }}>{StringUtils.currencyPtBr(priceComposite?.priceWithDiscountAndSubsidy)}</Col>
              </Row>
              <Row>
                <Col>{'benefício categoria:'}</Col>
                <Col>{priceComposite?.coParticipationGroupActivityCategory?.activityCategory?.name}</Col>
              </Row>
            </Col>
            {isEdit == false &&
              <Col md="6">
                {!paymentLoading && paymentMethods?.length === 0 || !paymentMethods ? (
                  <p style={{ color: 'red' }}>Esse usuário não possui nenhum método de pagamento cadastrado.</p>
                )
                  : (
                    <PaymentMethodCardItems
                      items={paymentMethods}
                      selected={presetPaymentMethodsToCharge}
                      onSelected={handleOnSelectedPaymentMethod}
                    />
                  )}
              </Col>
            }
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleConfirm}>
          {loading ? <TailSpin color="black" height={30} width={30} /> : translate('scheduleManage.modal-confirm.confirm')}
        </Button>{' '}
        <Button color="danger" onClick={handleClose}>
          {loading ? <TailSpin color="black" height={30} width={30} /> : translate('scheduleManage.modal-confirm.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
interface IWeekly {
  value: number;
  name: string;
}

// TODO - refactor and I18n
export const defaultWeeklyOptions: IWeekly[] = [
  {
    value: 1,
    name: 'semanal'
  },
  {
    value: 2,
    name: 'quizenal'
  },
  {
    value: 4,
    name: 'mensal'
  }
];

interface SelectOptionsDropdownProps<T> {
  title: string;
  nameKey: string;
  options: T[];
  value: T;
  optionsEmptyMessage?: string;
  onSelect: (item: T) => void;
}
export function SelectOptionsDropdown<T>({
  title,
  nameKey,
  options,
  onSelect,
  value
}: SelectOptionsDropdownProps<T>) {

  if (options == null) {
    return <div />;
  }

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const [selected, setSelected] = useState<T>();

  function handleOnSelect(item: T) {
    toggle();
    setSelected(item);
    onSelect(item);
  }

  // @ts-ignore
  const toggleTitle: string = value?.[nameKey] != null ? value[nameKey] : selected?.[nameKey] != null ? selected[nameKey] : title;
  return (
    <Dropdown direction="right" color="light" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        {toggleTitle}
      </DropdownToggle>
      <DropdownMenu >
        {!_.isEmpty(options) && options?.map((item, index) =>
          <DropdownItem key={`${title}-${index}`} eventkey={index} onClick={() => handleOnSelect(item)}>
            {item?.[nameKey]}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  priceCompositeStatus: storeState.billing.statusActivitySchedulePriceComposite,
  priceComposite: storeState.billing.priceComposite,
  paymentMethods: storeState.customer.paymentMethods
});
const mapDispatchToProps = {
  getCoParticipationCustomerToRenewal,
  getActivitySchedulePriceComposite,
  getCustomerPaymentMethods,
  resetCustomer,
  resetPrice
};
type DispatchProps = typeof mapDispatchToProps;

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityScheduleConfirmModal);
