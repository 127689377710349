import React from 'react';
import { getPartnerExternal, getPartnersExternals, createPartnerExternal, updatePartnerExternal, reset } from 'app/entities/keiken-pay/keiken-pay-partner-external-reducer';
import { IKeikenPayPartnerExternal, PartnerExternalStatus, PartnerExternalDocumentType } from 'app/shared/model/KeikenPay/KeikenPayPartnerExternal';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { IRootState } from 'app/shared/reducers';
import { Row, Button, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { translate, JhiPagination, getPaginationItemsNumber, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import qs from 'qs';
import './keiken-pay-partner-external.scss';
import InputField from 'app/components/input-field/InputField';
import StringUtils from 'app/util/StringUtils';

export interface IKeikenPayPartnerExternalProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IKeikenPayPartnerExternalListState {
  page: IPageRequest;
  partnerExternalPage?: IPage<IKeikenPayPartnerExternal>;
  partnerExternalList?: IKeikenPayPartnerExternal[];
  filter?: {
    partnerExternalStatus?: PartnerExternalStatus,
    name?: string,
    document?: string;
  };
}

export class IKeikenPayPartnerExternalList extends React.Component<IKeikenPayPartnerExternalProps, IKeikenPayPartnerExternalListState> {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        page: 0,
        size: 20
      },
      filter: {}
    };
    this.performAction = debounce(this.performAction, 500);
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { page, size, name, document } = params;
    let { status } = params;
    if (!Object.values(PartnerExternalStatus).includes(status)) {
      status = 'ENABLED';
    }

    this.setState({
      page: { page, size },
      filter: { partnerExternalStatus: status, name, document }
    }, () => {
      this.performAction();
    });

    this.props.getPartnersExternals({ page: 0, size: 20 });
  }
  componentWillReceiveProps(newProps: IKeikenPayPartnerExternalProps) {
    if (newProps?.pagePartnerExternal?.content != null) {
      this.setState({
        partnerExternalList: newProps.pagePartnerExternal?.content,
        partnerExternalPage: newProps.pagePartnerExternal
      });
      this.props.reset();
    }
  }

  private performAction = () => {
    this.props.getPartnersExternals(this.state.page, this.state.filter);
    const params = qs.stringify({ ...this.state.page, ...this.state.filter }, { skipNulls: true });
    this.props.history.push({ search: params });
  };

  private handleChangeFilterStatus = () => {
    const status = this.state.filter.partnerExternalStatus == PartnerExternalStatus.ENABLED ?
      PartnerExternalStatus.DISABLED :
      this.state.filter.partnerExternalStatus == PartnerExternalStatus.DISABLED ? undefined : PartnerExternalStatus.ENABLED;

    this.setState({
      page: { page: 0, size: this.state.page.size },
      filter: { ...this.state.filter, partnerExternalStatus: status }
    }, () => {
      this.performAction();
    });
  }
  private handlePagination = activePage => {
    const object = this.state.page;
    object.page = activePage - 1;
    this.setState({ page: object }, () => {
      this.performAction();
    });
  };

  private handleChangedName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    this.setState({
      filter: { ...this.state.filter, name }
    }, () => {
      this.performAction();
    });
  };

  private handleChangedDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    const document = event.target.value;
    this.setState({
      filter: { ...this.state.filter, document }
    }, () => {
      this.performAction();
    });
  };

  private handleEditPartnerExternal = (partnerExternal: IKeikenPayPartnerExternal | undefined) => {
    this.props.history.push({
      pathname: `keiken-pay-partner-external/${partnerExternal.id}`,
      state: {
        partnerExternal,
        isEdit: true
      }
    });
  };

  private handleCreatePartnerExternal = (partnerExternal: IKeikenPayPartnerExternal | undefined) => {
    this.props.history.push({
      pathname: `keiken-pay-partner-external/new`,
      state: {
        partnerExternal,
        isEdit: false
      }
    });
  };
  private renderActionColumn = (partnerExternal: IKeikenPayPartnerExternal | undefined): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <Button className={'centered-content marginB2'} onClick={this.handleEditPartnerExternal.bind(this, partnerExternal)}>
          <FontAwesomeIcon icon={'check'} className={'marginR1'} />
          <Translate contentKey={'companiesList.buttons.edit'} />
        </Button>
      </div>
    );
  };

  private handleTransformToTableContent = (content?: IKeikenPayPartnerExternal[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IKeikenPayPartnerExternal) => {
      const documentMask = item.documentType === PartnerExternalDocumentType.CPF ? StringUtils.addCpfMask : StringUtils.addCnpjMask;
      result.push([
        item?.name,
        documentMask?.(item?.document) ?? '',
        item?.phone,
        item?.email,
        this.renderActionColumn(item)
      ]);
    });
    return result;
  };
  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state.partnerExternalList);
    return (
      <Row>
        <Col>
          <div className={'keiken-pay-partner-external-container'}>
            <div className={'table-search-container-partner-external'}>
              <div onClick={this.handleCreatePartnerExternal} className={'create-partner-external'}>
                <FontAwesomeIcon icon={'plus'} className={'marginR1'} />
                <span className={'partner-dashboard-button-label'}>{translate('keikenPayPartnerExternal.addPartnerExternal')}</span>
              </div>
              <div className={'button-filter-status'} onClick={this.handleChangeFilterStatus}>
                <FontAwesomeIcon icon={'check'} className={'marginR1'} />
                <span className={'partner-external-text-label'}>{translate('companiesList.filter.status.' + this.state.filter.partnerExternalStatus)}</span>
              </div>
              <div className={'filter-by-partner-external'}>
                <InputField
                  title={'Pesquisar parceiros externos'}
                  onChange={this.handleChangedName}
                  inputClassName={'filter-by-partner-external'}
                  value={this.state.filter.name ?? ''}
                  placeholder={''}
                />
              </div>
              <div className={'filter-by-partner-external'}>
                <InputField
                  title={'Pesquisar cpf/cnpj do parceiro'}
                  onChange={this.handleChangedDocument}
                  inputClassName={'filter-by-partner-external'}
                  value={this.state.filter.document ?? ''}
                  placeholder={''}
                />
              </div>
            </div>
            <SimpleTableList
              rows={tableContent}
              columNameKeys={translate('keikenPayPartnerExternal.tableHeaders')}
              emptyTableMessage={this.props.status !== HttpRequestStatus.ONGOING ? translate('keikenPayPartnerExternal.emptyList') : ''}
            />
            {tableContent.length > 0 && (
              <Row className={'justify-content-center'}>
                <JhiPagination
                  items={
                    this.state.partnerExternalList
                      ? getPaginationItemsNumber(this.state.partnerExternalPage.totalElements, this.state.partnerExternalPage.size)
                      : 0
                  }
                  activePage={this.state.partnerExternalPage?.number ?? 0}
                  onSelect={this.handlePagination}
                  maxButtons={5}
                />
              </Row>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  pagePartnerExternal: storeState.keikenPayPartnerExternal.page,
  status: storeState.keikenPayPartnerExternal.getPartnersExternals
});
const mapDispatchToProps = { getPartnerExternal, getPartnersExternals, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IKeikenPayPartnerExternalList);
