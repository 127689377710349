import React from 'react';
import { IRootState } from '../../../shared/reducers';
import { connect } from 'react-redux';
import { getConferences, reset } from '../../../entities/conference/conference-redux';
import { HttpRequestStatus } from '../../../shared/model/enum/HttpRequestStatus';
import { Table } from 'reactstrap';
import { JhiPagination } from 'react-jhipster';
import { ICustomer } from '../../../shared/model/customer/customer';
import { IPartnerPlace } from '../../../shared/model/partner-place.model';
import { formatDate } from '../../../shared/util/date-utils';
import { IPage } from '../../../shared/model/page.model';
import moment from 'moment';
import { Row } from 'reactstrap';
import './styles.scss';
import ExtendConferenceModal from './extendConferenceModal';
import { TailSpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

interface IConferencesProps extends StateProps, DispatchProps { }

export interface ConferenceVM {
  activityScheduleId: number;
  roomUrl: string;
  endDate: string;
  scheduleDate: string;
  activityName: string;
  partnerPlace: IPartnerPlace;
  customer: ICustomer;
}

const Conferences = ({ getAllConferences, getConferencesStatus, conferences }: IConferencesProps) => {
  const [conferencesState, setConferencesState] = React.useState<{ page: number; conferences: IPage<ConferenceVM> | null; }>
    ({ page: 0, conferences: null });
  const [extendConfModal, setExtendConfModal] = React.useState<{ open: boolean, activityScheduleId: number | null }>();

  React.useEffect(() => {
    getAllConferences({ page: 0 });
  }, []);

  React.useEffect(
    () => {
      if (getConferencesStatus === HttpRequestStatus.SUCCESS) {
        setConferencesState({
          page: conferences.number + 1,
          conferences
        });
        reset();
      }
    },
    [getConferencesStatus]
  );

  const handlePagination = (activePage: number) => {
    getAllConferences({ page: activePage - 1 < 0 ? activePage : activePage - 1 });
  };

  const handleCopyToTransferAreaConferenceSessionUrl = (url: string) => {
    navigator.clipboard.writeText(url);
    toast.success('URL copiada para a área de transferência!', { autoClose: 3000 });
  };

  return (
    <>

      {getConferencesStatus === HttpRequestStatus.ONGOING && (
        <div className="loading-container">
          <TailSpin height="50" width="50" color="green" />
        </div>
      )}

      <Table className="conferencesTable">
        <thead>
          <tr>
            <th>{'Parceiro'}</th>
            <th>{'Cliente'}</th>
            <th>{'Data agendamento'}</th>
            <th className="confColumn">{'URL Conferência'}</th>
            <th>{'Data expiração URL'}</th>
            <th>{'Alterar data de expiração'}</th>
          </tr>
        </thead>
        <tbody>
          {conferencesState?.conferences?.content?.length! > 0 && conferencesState?.conferences?.content?.map((conference: ConferenceVM, index) => {
            const formattedDate = moment.utc(conference?.scheduleDate, 'YYYY-MM-DDTHH:mm:ss[Z]').local().format('DD/MM/YYYY - HH:mm');
            const formattedEndDate = conference?.endDate !== undefined ? moment.utc(conference?.endDate, 'DD/MM/YYYY HH:mm:ss[Z]').local().format('DD/MM/YYYY - HH:mm') : 'não disponível';

            return (
              <tr key={index} className={index % 2 === 0 ? 'even line' : 'odd line'}>
                <td className="column">{conference?.partnerPlace?.fantasyName}</td>
                <td className="column">{conference?.customer?.name}</td>
                <td className="column">{formattedDate}</td>
                <td className="column confColumn">
                  <div className="conferenceUrlAndIconContainer">
                    {conference?.roomUrl.substring(0, 35) + '...'}

                    <div
                      onClick={() => handleCopyToTransferAreaConferenceSessionUrl(conference?.roomUrl)}
                      className="icon"
                    >
                      <FontAwesomeIcon icon="copy" />
                    </div>

                  </div>
                </td>
                <td className="column">{formattedEndDate}</td>
                <td className="column">
                  <button
                    onClick={() => { setExtendConfModal({ open: true, activityScheduleId: conference?.activityScheduleId }); }}
                    className="extendConfButton">Estender duração conf</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {conferencesState?.conferences?.content?.length! > 0 && (
        <Row className={'justify-content-center'}>
          <JhiPagination
            items={
              conferences?.totalPages
            }
            activePage={conferencesState?.page}
            onSelect={number => handlePagination(number)}
            maxButtons={10}
          />
        </Row>
      )}
      <ExtendConferenceModal
        extendConfModal={extendConfModal!}
        setExtendConfModal={setExtendConfModal}
      />
    </>

  );
};

const mapStateToProps = (storeState: IRootState) => ({
  conferences: storeState.conference.conferences,
  getConferencesStatus: storeState.conference.getConferencesStatus
});

const mapDispatchToProps = {
  getAllConferences: getConferences
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Conferences);
