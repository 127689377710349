import Button from 'app/components/button/Button';
import { MODAL_TYPE_WARNING } from 'app/components/modal/modal-types';
import { fetchActivityCategories } from 'app/entities/activity-category/activity-category-reducer';
import { deleteActivity } from 'app/entities/activity/activity';
import { INewActivity } from 'app/shared/model/new-activity.model';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { hideModal, showModal } from '../../../../components/modal/modal-action';
import './partner-activity-details.css';

export interface IPartnerActivityDetailsProps extends StateProps, DispatchProps {
  oldActivity: INewActivity;
  close: (showDetails: boolean) => void;
  pushEditActivity: (activity: INewActivity) => void;
}

export interface IPartnerActivityDetailsState {
  activity: INewActivity;
  errors: IPartnerActivityDetailsError;
  valids: IPartnerActivityDetailsValid;
}

export interface IPartnerActivityDetailsError {
  activityNameError?: boolean;
  activityCategoryError?: boolean;
  activitySubCategoryError?: boolean;
  teacherError?: boolean;
  descriptionError?: boolean;
  priceError?: boolean;
}

export interface IPartnerActivityDetailsValid {
  activityNameValid?: boolean;
  activityCategoryValid?: boolean;
  activitySubCategoryValid?: boolean;
  teacherValid?: boolean;
  descriptionValid?: boolean;
  priceValid?: boolean;
}

export class PartnerActivityDetails extends React.Component<IPartnerActivityDetailsProps, IPartnerActivityDetailsState> {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      valids: {},
      activity: this.props.oldActivity
    };
  }

  componentWillReceiveProps(props: IPartnerActivityDetailsProps) {
    if (!!props.deleteActivitySuccess) {
      this.props.hideModal();
      this.props.close(true);
      return;
    }

    if (!!props.oldActivity) {
      this.setState({ activity: props.oldActivity });
      return;
    }
  }

  composeString = (plural: string, single: string, nullValue?: string, value?: Number) => {
    if (!value) {
      return translate(nullValue);
    }

    let compose = value + ' ';
    compose += value > 1 ? translate(plural) : translate(single);

    return compose;
  };

  getPrice = () => {
    if (this.state.activity.free) {
      return translate('calendar.labels.free');
    }

    return 'R$ ' + this.state.activity.price.toFixed(2).replace('.', ',');
  };

  handleOnDeletePressed = () => {
    this.props.showModal(MODAL_TYPE_WARNING, {
      title: translate('calendar.modal.warningTitle', { activityName: this.state.activity.name }),
      confirmButtonText: 'calendar.buttons.delete',
      cancelCallback: this.handleOnWarningCallbackPressed,
      confirmCallback: this.handleOnConfirmCallbackPressed
    });
  };

  handleOnWarningCallbackPressed = () => {
    this.props.hideModal();
  };

  handleOnConfirmCallbackPressed = () => {
    this.props.deleteActivity(this.state.activity.id);
  };

  handleOnEditPressed = () => {
    this.props.pushEditActivity(this.state.activity);
  };

  render() {
    const activitySubCategoriesNames =
      this.state.activity.activitySubCategories == null || this.state.activity.activitySubCategories.length === 0
        ? ''
        : this.state.activity.activitySubCategories
            .map(it => it.name)
            .join(', ')
            .substring(0, 70);
    const activityCategoriesNames =
      this.state.activity.activityCategories == null || this.state.activity.activityCategories.length === 0
        ? ''
        : this.state.activity.activityCategories
            .map(it => it.name)
            .join(', ')
            .substring(0, 70);
    return (
      <div className={'partner-activity-details-container'}>
        <div className={'partner-activity-details-dialog-container'}>
          <div className={'partner-activity-details-banner'}>
            <img
              className={'partner-activity-details-img-url'}
              src={!!this.state.activity.image ? this.state.activity.image.imageUrl : 'content/images/activity-details/banner.png'}
            />
            <div className={'partner-activity-details-close-container'} onClick={() => this.props.close(false)}>
              <img src={'content/images/activity-details/close-icon.png'} />
            </div>
          </div>
          <div className={'partner-activity-label-container'}>
            <label className={'partner-activity-details-label-name'}>{this.state.activity.name}</label>
            <label className={'partner-activity-details-label-professor'}>{this.state.activity.teacher}</label>
          </div>
          <div className={'partner-activity-details-label-divisor'} />
          <div>
            <div className={'partner-activity-details-item'}>
              <img className={'partner-activity-details-img'} src={'content/images/activity-details/category-icon.png'} />
              <label>{activityCategoriesNames}</label>
            </div>
            <div className={'partner-activity-details-item'}>
              <img className={'partner-activity-details-img'} src={'content/images/activity-details/subcategory-icon.png'} />
              <label>{activitySubCategoriesNames}</label>
            </div>
            <div className={'partner-activity-details-item'}>
              <img className={'partner-activity-details-img'} src={'content/images/activity-details/description-icon.png'} />
              <label>{this.state.activity.description}</label>
            </div>
            <div className={'partner-activity-details-item'}>
              <img className={'partner-activity-details-img'} src={'content/images/activity-details/price-icon.png'} />
              <label>{this.getPrice()}</label>
            </div>

            <div className={'partner-activity-details-item'}>
              <img className={'partner-activity-details-img'} src={'content/images/activity-details/vacancies-icon.png'} />
              <label>
                {this.composeString(
                  'calendar.labels.slots',
                  'calendar.labels.slot',
                  'calendar.labels.nullSlots',
                  this.state.activity.slots
                )}
              </label>
            </div>

            {!!this.state.activity.duration && (
              <div className={'partner-activity-details-item'}>
                <img className={'partner-activity-details-img'} src={'content/images/activity-details/duration-icon.png'} />
                <label>{this.composeString('calendar.labels.minutes', 'calendar.labels.minute', null, this.state.activity.duration)}</label>
              </div>
            )}
          </div>

          <div className={'partner-activity-details-buttons-container'}>
            <label onClick={this.handleOnDeletePressed} className={'partner-activity-details-delete-button'}>
              {translate('calendar.buttons.delete')}
            </label>
            <Button onClick={this.handleOnEditPressed} title={translate('calendar.buttons.edit')} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = storeState => ({
  deleteActivitySuccess: storeState.activities.deleteActivitySuccess
});

const mapDispatchToProps = { showModal, hideModal, fetchActivityCategories, deleteActivity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerActivityDetails);
