import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IPageRequest } from 'app/shared/model/page.model';
import { IFaq } from 'app/shared/model/faq';

export const ACTION_TYPES = {
  FETCH_FAQ: 'faq/FETCH_FAQ',
  INSERT_FAQ: 'faq/INSERT_FAQ',
  UPDATE_FAQ: 'faq/UPDATE_FAQ',
  DELETE_FAQ: 'faq/DELETE_FAQ',
  RESET: 'faq/RESET'
};

const initialState = {
  // status
  getFaq: HttpRequestStatus.NOOP,
  saveFaq: HttpRequestStatus.NOOP,
  updateFaq: HttpRequestStatus.NOOP,
  deleteFaq: false,
  // contents
  faqPage: undefined
};

export type IFaqState = Readonly<typeof initialState>;

export default (state: IFaqState = initialState, action): IFaqState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_FAQ):
      return {
        ...initialState,
        getFaq: HttpRequestStatus.ONGOING
      };
    case SUCCESS(ACTION_TYPES.FETCH_FAQ):
      return {
        ...initialState,
        getFaq: HttpRequestStatus.SUCCESS,
        faqPage: action.payload.data
      };
    case FAILURE(ACTION_TYPES.FETCH_FAQ):
      return {
        ...initialState,
        getFaq: HttpRequestStatus.ERROR
      };

    // INSERT
    case REQUEST(ACTION_TYPES.INSERT_FAQ):
      return {
        ...initialState,
        saveFaq: HttpRequestStatus.ONGOING
      };
    case SUCCESS(ACTION_TYPES.INSERT_FAQ):
      return {
        ...initialState,
        saveFaq: HttpRequestStatus.SUCCESS
      };
    case FAILURE(ACTION_TYPES.INSERT_FAQ):
      return {
        ...initialState,
        saveFaq: HttpRequestStatus.ERROR
      };

    // UPDATE
    case REQUEST(ACTION_TYPES.UPDATE_FAQ):
      return {
        ...initialState,
        updateFaq: HttpRequestStatus.ONGOING
      };
    case SUCCESS(ACTION_TYPES.UPDATE_FAQ):
      return {
        ...initialState,
        updateFaq: HttpRequestStatus.SUCCESS
      };
    case FAILURE(ACTION_TYPES.UPDATE_FAQ):
      return {
        ...initialState,
        updateFaq: HttpRequestStatus.ERROR
      };

    // DELETE
    case REQUEST(ACTION_TYPES.DELETE_FAQ):
      return {
        ...initialState
      };
    case SUCCESS(ACTION_TYPES.DELETE_FAQ):
      return {
        ...initialState,
        deleteFaq: true
      };
    case FAILURE(ACTION_TYPES.DELETE_FAQ):
      return {
        ...initialState
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/faq';

export const fetchFaq = (page: IPageRequest) => {
  const params = {
    size: page.size,
    page: page.page
  };

  return {
    type: ACTION_TYPES.FETCH_FAQ,
    payload: axios.get(`${apiUrl}`, { params })
  };
};

export const createActivityFaq = (faq: IFaq) => ({
  type: ACTION_TYPES.INSERT_FAQ,
  payload: axios.post(`${apiUrl}`, faq)
});

export const updateActivityFaq = (faq: IFaq) => ({
  type: ACTION_TYPES.UPDATE_FAQ,
  payload: axios.put(`${apiUrl}`, faq)
});

export const deleteActivityFaq = (id?: Number) => ({
  type: ACTION_TYPES.DELETE_FAQ,
  payload: axios.delete(`api/faq/delete/${id}`, {})
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
