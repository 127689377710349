import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'app/components/button/Button';
import InputField from 'app/components/input-field/InputField';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { fetchActivityCategories } from 'app/entities/activity-category/activity-category-reducer';
import { fetchActivitySubCategories, getInvalidActivitySubCategories, reset as subCategoriesReset } from 'app/entities/activity-sub-category/activity-sub-category-reducer';
import { getPartnerPlaceSystemFee, reset } from 'app/entities/partner-place/partner-place-redux';
import { IActivity } from 'app/shared/model/activity';
import { IActivityCategory } from 'app/shared/model/activity-category';
import { IActivitySubCategory } from 'app/shared/model/activity-sub-category';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { ISystemFee } from 'app/shared/model/system-fee.model';
import { IRootState } from 'app/shared/reducers';
import NumberUtils from 'app/util/NumberUtils';
import StringUtils from 'app/util/StringUtils';
import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Button as DeleteButton, Col, Container, Row } from 'reactstrap';
import { hideModal, showModal } from '../../../../components/modal/modal-action';
import './edit-activity-step1.css';

export interface IEditActivityStep1Props extends StateProps, DispatchProps {
  object: IActivity;
  updateEditActivityHandler: (activity: IActivity) => void;
}

export interface IEditActivityStep1State {
  activity: IActivity;
  errors?: IEditActivityStep1Error;
  valids?: IEditActivityStep1Valid;
  partnerPlaceSystemFee?: ISystemFee;
  invalidIds: number[];
}

export interface IEditActivityStep1Valid {
  activityNameValid?: boolean;
  activityCategoryValid?: boolean;
  activitySubCategoryValid?: boolean;
  teacherValid?: boolean;
  descriptionValid?: boolean;
  priceValid?: boolean;
  slotsValid?: boolean;
  durationValid?: boolean;
}

export interface IEditActivityStep1Error {
  activityNameError?: boolean;
  activityCategoryError?: boolean;
  activitySubCategoryError?: boolean;
  teacherError?: boolean;
  descriptionError?: boolean;
  priceError?: boolean;
}

export class EditActivityStep1 extends React.Component<IEditActivityStep1Props, IEditActivityStep1State> {
  constructor(props) {
    super(props);

    this.state = {
      activity: props.object,
      errors: {},
      valids: this.mapValidProperties(props.object),
      invalidIds: []
    };
  }

  componentDidMount() {
    this.props.getPartnerPlaceSystemFee();
    this.fetchInvalidSubCategories();
  }

  fetchInvalidSubCategories = () => {
    const activityCategoriesIds = this.state.activity?.activityCategories?.map(it => it.id);
    const activitySubCategoriesIds = this.state.activity?.activitySubCategories?.map(it => it.id);
    this.props.getInvalidActivitySubCategories(activityCategoriesIds, activitySubCategoriesIds);
  }

  componentWillReceiveProps(newProps: StateProps) {
    if (newProps.getPartnerPlaceSystemFeeStatus === HttpRequestStatus.SUCCESS) {
      this.setState({
        partnerPlaceSystemFee: newProps.partnerPlaceSystemFee
      });
      this.props.reset();
    }

    if (this.props.invalidIds != null) {
      this.setState({
        invalidIds: this.props.invalidIds?.map(it => it.id)
      }, () => {
        this.props.subCategoriesReset();
        this.forceUpdate();
      });
    }
  }

  mapValidProperties = (activity: IActivity) => {
    const validFields = {
      activityNameValid: !StringUtils.isStringInvalid(activity.name),
      activityCategoryValid: activity.activityCategories != null && activity.activityCategories.length > 0,
      activitySubCategoryValid: activity.activitySubCategories != null && activity.activitySubCategories.length > 0,
      teacherValid: !StringUtils.isStringInvalid(activity.teacher),
      descriptionValid: !StringUtils.isStringInvalid(activity.description),
      slotsValid: !!activity.slots,
      priceValid: !!activity.price,
      durationValid: !!activity.duration
    } as IEditActivityStep1Valid;

    return validFields;
  };

  handleTextChanged = (text: string, propertyToChange: string, errorToChange?: string, validToChange?: string) => {
    if (!!errorToChange) {
      this.setState(prevState => ({
        activity: { ...prevState.activity, [propertyToChange]: text },
        errors: { ...prevState.errors, [errorToChange]: false },
        valids: { ...prevState.valids, [validToChange]: false }
      }));
      return;
    }

    this.setState(prevState => ({ activity: { ...prevState.activity, [propertyToChange]: text } }));
  };

  handleOnCategoryPressed = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: translate('editActivity.labels.searchCategory'),
      action: this.props.fetchActivityCategories,
      placeholderInput: translate('editActivity.placeholders.searchCategory'),
      stateAction: 'activityCategories',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleCategorySelected(item)
    });
  };

  handleCategorySelected = (item: IActivityCategory) => {
    this.setState(prevState => ({
      activity: {
        ...prevState.activity,
        activityCategories: !prevState.activity.activityCategories
          ? [item]
          : prevState.activity.activityCategories.some(it => it.id === item.id)
            ? prevState.activity.activityCategories
            : prevState.activity.activityCategories.concat([item])
      },
      errors: { ...prevState.errors, activitySubCategoryError: false }
    }), () => {
      this.fetchInvalidSubCategories();
    });
  };

  handleOnSubCategoryPressed = () => {
    if (this.state.activity?.activityCategories == null || this.state.activity.activityCategories?.length === 0) {
      this.setState(prevState => ({ errors: { ...prevState.errors, activityCategoryError: true } }));
      toast.error(translate('editActivity.errors.selectCategoryFirst'));
      return;
    }
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: translate('editActivity.labels.searchSubCategory'),
      idToSearch: this.state.activity?.activityCategories?.map(it => it.id) ?? [],
      action: this.props.fetchActivitySubCategories,
      placeholderInput: translate('editActivity.placeholders.searchSubCategory'),
      stateAction: 'activitySubCategories',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: this.handleSubCategorySelected,
      waitToPerformRequest: false,
      infiniteScrolling: true
    });
  };

  handleSubCategorySelected = (item: IActivitySubCategory) => {
    this.setState(prevState => ({
      activity: {
        ...prevState.activity,
        activitySubCategories: !prevState.activity.activitySubCategories
          ? [item]
          : prevState.activity.activitySubCategories.some(it => it.id === item.id)
            ? prevState.activity.activitySubCategories
            : prevState.activity.activitySubCategories.concat([item])
      },
      errors: { ...prevState.errors, activitySubCategoryError: false }
    }));
  };

  handleNextPressed = () => {
    if (!this.validateFields()) {
      return;
    }

    this.props.updateEditActivityHandler(this.state.activity);
  };

  handleOnFreeChanged = () => {
    let price = this.state.activity.price;

    if (!this.state.activity.free) {
      price = undefined;
    }

    this.setState(prevState => ({ activity: { ...prevState.activity, free: !this.state.activity.free, price } }));
  };

  handlePriceChanged = (event: React.ChangeEvent<HTMLInputElement>, text: string, floatValue: number) => {
    this.setState(prevState => ({
      activity: { ...prevState.activity, price: floatValue === 0 ? null : floatValue },
      errors: { ...prevState.errors, priceError: false },
      valids: { ...prevState.valids, priceValid: false }
    }));
  };

  handleSlotsChanged = (text: string) => {
    if (StringUtils.isStringInvalid(text)) {
      this.setState(prevState => ({ activity: { ...prevState.activity, slots: null } }));
      return;
    }

    this.setState(prevState => ({ activity: { ...prevState.activity, slots: Number(text) } }));
  };

  handleDurationChanged = (text: string) => {
    if (StringUtils.isStringInvalid(text)) {
      this.setState(prevState => ({ activity: { ...prevState.activity, duration: null } }));
      return;
    }

    this.setState(prevState => ({ activity: { ...prevState.activity, duration: Number(text) } }));
  };

  handleImageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const newImage = this.state.activity.image || {};
      const contentType = selectedFile.type;
      newImage.contentType = contentType;
      newImage.fileName = selectedFile.name;
      newImage.file = ev.target.result.split(',')[1];
      this.setState(prevState => ({ activity: { ...prevState.activity, image: newImage } }));
    };

    reader.readAsDataURL(selectedFile);
  };

  validateFields = () => {
    let allFieldsValid = true;

    const fieldsErrors = this.state.errors;
    const fieldValids = this.state.valids;

    if (StringUtils.isStringInvalid(this.state.activity.name)) {
      allFieldsValid = false;
      fieldsErrors.activityNameError = true;
    } else {
      fieldValids.activityNameValid = true;
    }

    if (this.state.activity.activityCategories == null || this.state.activity.activityCategories.length === 0) {
      allFieldsValid = false;
      fieldsErrors.activityCategoryError = true;
    } else {
      fieldValids.activityCategoryValid = true;
    }

    if (this.state.activity.activitySubCategories == null || this.state.activity.activitySubCategories.length === 0 || !_.isEmpty(this.state.invalidIds)) {
      allFieldsValid = false;
      fieldsErrors.activitySubCategoryError = true;
    } else {
      fieldValids.activitySubCategoryValid = true;
    }

    if (StringUtils.isStringInvalid(this.state.activity.teacher)) {
      allFieldsValid = false;
      fieldsErrors.teacherError = true;
    } else {
      fieldValids.teacherValid = true;
    }

    if (StringUtils.isStringInvalid(this.state.activity.description)) {
      allFieldsValid = false;
      fieldsErrors.descriptionError = true;
    } else {
      fieldValids.descriptionValid = true;
    }

    if (!!this.state.activity.free && this.state.activity.price) {
      allFieldsValid = false;
      fieldsErrors.priceError = true;
    } else {
      fieldValids.priceValid = true;
    }

    this.setState({ errors: fieldsErrors, valids: fieldValids });

    return allFieldsValid;
  };

  private deleteSubCategory = (subCategory: IActivitySubCategory | undefined) => {
    const activity: IActivity = this.state.activity;
    activity.activitySubCategories = activity.activitySubCategories
      ? activity.activitySubCategories.filter(it => it.id !== subCategory.id)
      : Array<IActivitySubCategory>();

    this.setState(prevState => ({
      activity: { ...prevState.activity, activity },
      errors: { ...prevState.errors, activityCategoryError: false }
    }), () => {
      this.fetchInvalidSubCategories();
    });
  };

  private renderSubCategoryList = (subCategory: IActivitySubCategory): JSX.Element => {
    const isInvalid = this.state.invalidIds.includes(subCategory.id);
    const style = isInvalid ? { color: '#ff0062' } : {};
    return (
      <Row className={'action-manage-subcategory'} key={`sub-category-list-edit-activity-${subCategory.id}`}>
        <Col>
          <label style={style}>{subCategory.name}</label>
          {isInvalid == true && <label style={{ ...style, fontSize: 10 }}>{translate('editActivity.labels.errorActivitySubCategory')}</label>}
        </Col>
        <Col>
          <DeleteButton className={'action-manage-delete-button'} onClick={this.deleteSubCategory.bind(this, subCategory)}>
            <FontAwesomeIcon icon={'trash-alt'} />
            <Translate contentKey={'categoryList.buttons.delete'} />
          </DeleteButton>
        </Col>
      </Row>
    );
  };

  private deleteCategory = (category: IActivitySubCategory | undefined) => {
    const activity: IActivity = this.state.activity;

    activity.activityCategories = activity.activityCategories
      ? activity.activityCategories.filter(it => it.id !== category.id)
      : Array<IActivityCategory>();

    this.setState(prevState => ({
      activity: { ...prevState.activity, activity },
      errors: { ...prevState.errors, activityCategoryError: false }
    }), () => {
      this.fetchInvalidSubCategories();
    });
  };

  private renderCategoryList = (category: IActivityCategory): JSX.Element => {
    return (
      <Row className={'action-manage-subcategory'} key={`activity-category-edit-activity-${category.id}`}>
        <Col>
          <label>{category.name}</label>
        </Col>
        <Col>
          <DeleteButton className={'action-manage-delete-button'} onClick={this.deleteCategory.bind(this, category)}>
            <FontAwesomeIcon icon={'trash-alt'} />
            <Translate contentKey={'categoryList.buttons.delete'} />
          </DeleteButton>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div className={'edit-activity-step1-container'}>
        <div className={'edit-activity-step1-input-divisor'}>
          <InputField
            title={translate('editActivity.labels.activityName')}
            onChange={event => this.handleTextChanged(event.target.value, 'name', 'activityNameError', 'activityNameValid')}
            error={this.state.errors.activityNameError}
            valid={this.state.valids.activityNameValid}
            inputClassName={'edit-activity-step1-input'}
            value={!!this.state.activity.name ? this.state.activity.name : ''}
            placeholder={'editActivity.placeholders.activityName'}
          />
        </div>

        <div className={'edit-activity-step1-input-divisor'}>
          <div className={'edit-activity-step1-inner-addon'} onClick={this.handleOnCategoryPressed}>
            <InputField
              title={translate('editActivity.labels.activityCategory')}
              error={this.state.errors.activityCategoryError}
              valid={this.state.valids.activityCategoryValid}
              inputClassName={'edit-activity-step1-search-input'}
              value={''}
              placeholder={'editActivity.placeholders.activityCategory'}
              readOnly
              onClick={this.handleOnCategoryPressed}
            />

            <FontAwesomeIcon
              className={
                this.state.activity.activityCategories == null
                  ? 'edit-activity-step1-inner-icon'
                  : classnames('edit-activity-step1-inner-icon', 'edit-activity-step1-inner-icon-with-value')
              }
              icon={'search'}
            />
          </div>

          <label>
            <a
              href={'https://api.whatsapp.com/send?phone=5541996766498&text=Ol%C3%A1%20keiken,%20poderia%20me%20ajudar?'}
              className={'new-activity-step1-not-found-item'}
            >
              <Translate
                contentKey={'editActivity.labels.notFoundItem'}
                interpolate={{ itemName: translate('editActivity.interpolate.category') }}
              />
            </a>
          </label>
          <Container className={'action-manage-subcategories-list'}>
            {this.state.activity.activityCategories
              ? this.state.activity.activityCategories.map(item => this.renderCategoryList(item))
              : ''}
          </Container>
        </div>

        <div className={'edit-activity-step1-input-divisor'}>
          <div className={'edit-activity-step1-inner-addon'} onClick={this.handleOnSubCategoryPressed}>
            <InputField
              title={translate('editActivity.labels.activitySubCategory')}
              error={this.state.errors.activitySubCategoryError}
              valid={this.state.valids.activitySubCategoryValid}
              inputClassName={'edit-activity-step1-search-input'}
              value={''}
              placeholder={'editActivity.placeholders.activitySubCategory'}
              readOnly
              onClick={this.handleOnSubCategoryPressed}
            />

            <FontAwesomeIcon
              className={
                !this.state.activity.activitySubCategories || this.state.activity.activitySubCategories.length === 0
                  ? 'edit-activity-step1-inner-icon'
                  : classnames('edit-activity-step1-inner-icon', 'edit-activity-step1-inner-icon-with-value')
              }
              icon={'search'}
            />
          </div>

          <label>
            <a
              href={'https://api.whatsapp.com/send?phone=5541996766498&text=Ol%C3%A1%20keiken,%20poderia%20me%20ajudar?'}
              className={'new-activity-step1-not-found-item'}
            >
              <Translate
                contentKey={'editActivity.labels.notFoundItem'}
                interpolate={{ itemName: translate('editActivity.interpolate.subCategory') }}
              />
            </a>
          </label>
          <Container className={'action-manage-subcategories-list'}>
            {this.state.activity.activitySubCategories
              ? this.state.activity.activitySubCategories.map((item, index) => this.renderSubCategoryList(item))
              : ''}
          </Container>
        </div>

        <div className={'edit-activity-step1-input-divisor-smaller'}>
          <InputField
            onChange={event => this.handleTextChanged(event.target.value, 'teacher', 'teacherError', 'teacherValid')}
            error={this.state.errors.teacherError}
            valid={this.state.valids.teacherValid}
            title={translate('editActivity.labels.teacher')}
            inputClassName={'edit-activity-step1-input-smaller'}
            value={!!this.state.activity.teacher ? this.state.activity.teacher : ''}
            placeholder={'editActivity.placeholders.teacher'}
          />
        </div>

        <div className={'edit-activity-step1-textarea-divisor'}>
          <InputField
            onChange={event => this.handleTextChanged(event.target.value, 'description', 'descriptionError', 'descriptionValid')}
            title={translate('editActivity.labels.description')}
            error={this.state.errors.descriptionError}
            valid={this.state.valids.descriptionValid}
            inputClassName={'edit-activity-step1-input-description'}
            value={!!this.state.activity.description ? this.state.activity.description : ''}
            placeholder={'editActivity.placeholders.description'}
            maxLength={500}
            isTextArea
          />
        </div>

        {!!!this.state.activity.free && (
          <div className={'edit-activity-step1-input-price-slot-container'}>
            <div className={'edit-activity-step1-input-price-slot-label-and-input-container'}>
              <InputField
                onChange={this.handlePriceChanged}
                error={this.state.errors.priceError}
                valid={this.state.valids.priceValid}
                title={translate('editActivity.labels.price')}
                inputClassName={'edit-activity-step1-input-price-slot'}
                value={!!this.state.activity.price ? this.state.activity.price : null}
                placeholder={'editActivity.placeholders.price'}
                isCurrency
              />
            </div>
            <div className={'edit-activity-step1-input-price-slot-label-and-input-container'}>
              <InputField
                title={translate('newActivity.labels.priceActivity')}
                inputClassName={'new-activity-step1-input-price-slot'}
                value={NumberUtils.systemFeeCalculate(
                  this.state.partnerPlaceSystemFee,
                  this.state.activity.price != null ? Number(this.state.activity.price) : undefined
                )}
                placeholder={'newActivity.placeholders.slot'}
              />
            </div>
          </div>
        )}

        <div className={'edit-activity-step1-checkbox-container'}>
          <input checked={this.state.activity.free} onClick={this.handleOnFreeChanged} type={'radio'} />
          <label className={'edit-activity-step1-checkbox-label'}>{translate('editActivity.labels.free')}</label>
        </div>

        <div className={'edit-activity-step1-input-price-slot-label-and-input-container'}>
          <div className={'edit-activity-step1-input-price-slot-label-and-input-container'}>
            <InputField
              onChange={event => this.handleSlotsChanged(event.target.value)}
              title={translate('editActivity.labels.slot')}
              inputClassName={'edit-activity-step1-input-price-slot'}
              valid={this.state.valids.slotsValid}
              value={!!this.state.activity.slots ? this.state.activity.slots.toString() : ''}
              placeholder={'editActivity.placeholders.slot'}
            />
          </div>
        </div>

        <div className={'edit-activity-step1-input-price-slot-label-and-input-container'}>
          <InputField
            onChange={event => this.handleDurationChanged(event.target.value)}
            title={translate('editActivity.labels.duration')}
            inputClassName={'edit-activity-step1-input-price-slot'}
            type={'number'}
            value={!!this.state.activity.duration ? this.state.activity.duration.toString() : ''}
            placeholder={'editActivity.placeholders.duration'}
          />
        </div>

        <div className={'edit-activity-step1-image-container'}>
          <input
            id={'myFileInput'}
            className={'edit-activity-step1-image-input'}
            onChange={this.handleImageChanged}
            accept={'image/*'}
            type={'file'}
          />

          <Button
            onClick={() => document.getElementById('myFileInput').click()}
            title={translate('editActivity.buttons.addImage')}
            buttonLabelClassName={'edit-activity-step1-select-image-button-label'}
            outerClassName={'edit-activity-step1-select-image-button-background-outer-class-name'}
            innerClassName={'edit-activity-step1-select-image-button-background-inner-class-name'}
          />
        </div>
        <div className={'edit-activity-step1-image-name'}>
          <label>{this.state.activity.image ? this.state.activity.image.fileName : ''}</label>
        </div>

        <div className={'edit-activity-step1-button-container'}>
          <Button onClick={this.handleNextPressed} title={translate('editActivity.buttons.next')} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (storeState: IRootState) => ({
  invalidStatus: storeState.activitySubCategories.invalidStatus,
  invalidIds: storeState.activitySubCategories.invalidIds,
  getPartnerPlaceSystemFeeStatus: storeState.partnerPlaces.getPartnerPlaceSystemFeeStatus,
  partnerPlaceSystemFee: storeState.partnerPlaces.partnerPlaceSystemFee
});

const mapDispatchToProps = { showModal, hideModal, getPartnerPlaceSystemFee, fetchActivityCategories, getInvalidActivitySubCategories, subCategoriesReset, reset, fetchActivitySubCategories };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditActivityStep1);
