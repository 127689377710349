import React, { useEffect } from 'react';
import { getActiveActivities } from 'app/entities/activity-admin/activityAdmin';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { IActivity } from 'app/shared/model/activity';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StringUtils from 'app/util/StringUtils';
import { getPaginationItemsNumber, JhiPagination, translate } from 'react-jhipster';
import { RouteComponentProps } from 'react-router-dom';
import InputField from 'app/components/input-field/InputField';
import { debounce } from 'lodash';

interface ActivityManageProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

function ActivityManage({activitiesPage, getActivitiesList, history}: ActivityManageProps) {
  const [ pageRequest, setPageRequest ] = React.useState({
    page: 0,
    size: 20
  });
  const [ searchName, setSearchName ] = React.useState('');
  const [ searchEmail, setSearchEmail ] = React.useState('');

  function handlePagination(activePage: number) {
    setPageRequest({...pageRequest, page: activePage - 1});
  }

  const getActivitiesDebounced = debounce(getActivitiesList, 500);
  useEffect(() => {
    getActivitiesDebounced(pageRequest, searchName, searchEmail);

    return () => {
      getActivitiesDebounced.cancel();
    };
  }, [searchName, searchEmail]);

  useEffect(() => {
    getActivitiesList(pageRequest, searchName, searchEmail);
  }, [pageRequest]);

  function handleEditActivity(activity: IActivity | undefined) {
    history.push({
      pathname: `activities-manage/${activity.id}`,
      state: {
        activity
      }
    });
  }

  function renderActionColumn(activity: IActivity | undefined): JSX.Element {
    return (
      <div className={'action-column-container-flex'}>
        <Button color="info" className={'centered-content margin1'} onClick={() => handleEditActivity(activity)}>
          <FontAwesomeIcon icon={'eye'} />
        </Button>
      </div>
    );
  }

  function handleTransformToTableContent(content?: IActivity[]): Array<Array<string | JSX.Element>> {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map(item => {
      const { name: activityName, price, description, partnerPlace } = item ?? {};
      const descriptionText = description.length > 50 ? description.slice(0, 50) + '...' : description;

      result.push([
        activityName,
        partnerPlace.fantasyName,
        StringUtils.currencyPtBr(price),
        descriptionText,
        renderActionColumn(item)
      ]);
    });
    return result;
  }

  const tableContent = handleTransformToTableContent(activitiesPage?.content);

  return (
    <div style={{margin: '60px 30px'}}>
      <Row style={{padding: '40px'}}>
        <Col>
          <h3>{translate('activityList.tableTitle')}</h3>
        </Col>
        <Col>
          <InputField
            title={translate('activityList.labels.searchName')}
            onChange={e => setSearchName(e.target.value)}
            inputClassName={''}
            value={searchName}
            placeholder={'activityList.placeholders.name'}
          />
        </Col>
        <Col>
          <InputField
            title={translate('activityList.labels.searchEmail')}
            onChange={e => setSearchEmail(e.target.value)}
            inputClassName={''}
            value={searchEmail}
            placeholder={'activityList.placeholders.email'}
          />
        </Col>
      </Row>
      <SimpleTableList
        rows={tableContent}
        columNameKeys={translate('activityList.tableHeaders')}
        emptyTableMessage={translate('activityList.labels.emptyList')}
        textOverflow
      />
      <Row className={'justify-content-center'}>
        <JhiPagination
          items={
            activitiesPage
              ? getPaginationItemsNumber(activitiesPage.totalElements, activitiesPage.size)
              : 0
          }
          activePage={activitiesPage ? activitiesPage.number : 1}
          onSelect={handlePagination}
          maxButtons={5}
        />
      </Row>
    </div>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  getActivitiesStatus: storeState.activitiesAdmin.getActivityPageStatus,
  activitiesPage : storeState.activitiesAdmin.activityPage
});

const mapDispatchToProps = {
  getActivitiesList: getActiveActivities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityManage);
