import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './new-activity.css';
import NewActivityStep1 from './step1/new-activity-step1';
import NewActivityStep2 from './step2/new-activity-step2';
import NewActivityStep3 from './step3/new-activity-step3';
import { INewActivity } from 'app/shared/model/new-activity.model';
import classnames from 'classnames';
import { createNewActivity, reset } from 'app/entities/activity/activity';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';

export interface INewActivityProps extends StateProps, DispatchProps, RouteComponentProps {}

export interface INewActivityState {
  step: number;
  newActivity?: INewActivity;
}

export class NewActivity extends React.Component<INewActivityProps, INewActivityState> {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      newActivity: {}
    };
  }

  componentWillReceiveProps(newProps: INewActivityProps) {
    if (newProps.createActivitySuccess && this.props.createActivitySuccess !== newProps.createActivitySuccess) {
      toast.success(translate('newActivity.messages.createWithSuccess'), {
        onClose: this.handleCloseSuccessDialog,
        autoClose: 1500
      });
    }
  }

  handleCloseSuccessDialog = () => {
    this.props.reset();
    this.props.history.replace(`/partner/partner-dashboard`);
  };

  updateNewActivityHandlerStep1 = (newActivity: INewActivity) => {
    this.setState({ newActivity, step: 2 });
  };

  updateBackButtonNewActivityHandlerStep2 = (newActivity: INewActivity) => {
    this.setState({ newActivity, step: 1 });
  };

  updateBackButtonNewActivityHandlerStep3 = (newActivity: INewActivity) => {
    this.setState({ newActivity, step: 2 });
  };

  updateNextButtonNewActivityHandlerStep2 = (newActivity: INewActivity) => {
    this.setState({ newActivity, step: 3 });
  };

  saveButtonNewActivityHandlerStep3 = (newActivity: INewActivity) => {
    this.props.createNewActivity(newActivity);
  };

  render() {
    return (
      <div className={'new-activity-container'}>
        <label className={this.state.step !== 2 ? 'new-activity-title' : classnames('new-activity-title', 'new-activity-title-step-2')}>
          {this.state.step === 1 && translate('newActivity.titles.step1')}
          {this.state.step === 2 && translate('newActivity.titles.step2')}
          {this.state.step === 3 && translate('newActivity.titles.step3')}
        </label>
        <div>
          {this.state.step === 1 && (
            <NewActivityStep1 updateNewActivityHandler={this.updateNewActivityHandlerStep1} object={this.state.newActivity} />
          )}
          {this.state.step === 2 && (
            <NewActivityStep2
              nextButtonHandler={this.updateNextButtonNewActivityHandlerStep2}
              backButtonHandler={this.updateBackButtonNewActivityHandlerStep2}
              object={this.state.newActivity}
            />
          )}
          {this.state.step === 3 && (
            <NewActivityStep3
              saveButtonHandler={this.saveButtonNewActivityHandlerStep3}
              backButtonHandler={this.updateBackButtonNewActivityHandlerStep3}
              object={this.state.newActivity}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = storeState => ({
  createActivitySuccess: storeState.activities.createActivitySuccess
});

const mapDispatchToProps = { createNewActivity, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewActivity);
