import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IRootState } from 'app/shared/reducers';
import moment from 'moment';
import { getActivityActivesHistory, reset } from 'app/entities/activity/activity';
import { IPhone } from 'app/shared/model/phone.model';
import { IActivityActive, IActivityActiveHistory } from 'app/shared/model/activity-active';
import SimpleCardsList from 'app/components/simple-cards-list/simple-cards-list';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { isMobile } from 'react-device-detect';

import './activity-active.css';

export interface IActivityActiveProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IActivityActiveState {
  isVisible: boolean;
  data?: any[];
  historyActivity?: any[];
}

export class ActivityActive extends React.Component<IActivityActiveProps, IActivityActiveState> {
  constructor(props: IActivityActiveProps) {
    super(props);

    this.state = {
      isVisible: false
    };
  }

  componentDidMount() {
    this.props.getActivityActivesHistory();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.data) {
      this.setState({ data: newProps.data });
      this.props.reset();
    }
    if (newProps.historyActivity) {
      this.setState({ historyActivity: newProps.historyActivity });
      this.props.reset();
    }
  }

  private getPhoneNumber = (phones: IPhone[]) => {
    if (!phones || !phones.length || phones.length < 1 || !phones[0].number) {
      return '';
    }
    return phones[0].number;
  };
  private handleTransformToTableContent = (content?: IActivityActive[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IActivityActive) => {
      result.push([
        item.activityCategory ? item.activityCategory.name : '',
        item.activitySubCategory ? item.activitySubCategory.name : '',
        moment(item.date).format('DD/MM - HH:mm'),
        item.duration,
        item.customer.name,
        this.getPhoneNumber(item.customer.phones)
      ]);
    });
    return result;
  };
  private handleTransformToTableContentHistory = (content?: IActivityActiveHistory[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IActivityActive) => {
      result.push([
        item.activityCategory ? item.activityCategory.name : '',
        item.activitySubCategory ? item.activitySubCategory.name : '',
        moment(item.date).format('DD/MM - HH:mm'),
        item.duration,
        item.duration,
        item.customer.name,
        this.getPhoneNumber(item.customer.phones)
      ]);
    });
    return result;
  };

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state.data);
    const tableContentHistory: Array<Array<string | JSX.Element>> = this.handleTransformToTableContentHistory(this.state.historyActivity);
    const linksArr: Array<string | JSX.Element> = [];

    tableContent.map((item, index) => {
      linksArr[index] = item[4];
    });

    return (
      <div className={'activities'}>
        <label className={'activity-active-title'}>{translate('activity-active.labels.title')}</label>
        {(isMobile && (
          <SimpleCardsList
            rows={tableContent}
            links={linksArr}
            ignore={[4]}
            limit={3}
            columNameKeys={translate('activity-active.tableHeaders')}
            emptyTableMessage={this.state.data ? translate('activity-active.labels.emptyList') : ''}
          />
        )) || (
          <div className={'activity-active-container'}>
            <SimpleTableList
              rows={tableContent}
              columNameKeys={translate('activity-active.tableHeaders')}
              emptyTableMessage={this.state.data ? translate('activity-active.labels.emptyList') : ''}
            />
          </div>
        )}
        <label className={'activity-history-title'}>{translate('activity-history.labels.title')}</label>
        <div className={'activity-history-container'}>
          <SimpleTableList
            rows={tableContentHistory}
            columNameKeys={translate('activity-history.tableHeaders')}
            emptyTableMessage={this.state.data ? translate('activity-history.labels.emptyList') : ''}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  data: state['activities'].data,
  historyActivity: state['activities'].historyActivity,
  partner: state['partners'].partner
});

const mapDispatchToProps = { reset, getActivityActivesHistory };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityActive);
