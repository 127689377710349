import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../app/shared/reducers';
import { getCompanyUsers } from '../../../../entities/company/company-redux';
import { HttpRequestStatus } from '../../../../shared/model/enum/HttpRequestStatus';
import { translate } from 'react-jhipster';
import { IUserBasicInfo } from '../../../../shared/model/user.model';
import { Button } from 'reactstrap';
import SimpleTableList from '../../../../../app/components/simple-table-list/simple-table-list';
import moment from 'moment';
import { initNewPassword, reset } from '../../../../entities/user/user-redux';
import { BASE_LP_URL } from '../../../../../app/config/constants';
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';

interface CompanyUsersTableProps extends StateProps, DispatchProps {
  companyId: number;
}

const CompanyUsersTable = ({ companyId, getCompanyUsersProps, companyUsers, getCompanyUsersStatus, resetProps, initNewPasswordProps, initNewPasswordStatus }: CompanyUsersTableProps) => {
  const [companyUsersState, setCompanyUsersState] = React.useState<IUserBasicInfo[]>([]);
  const [getCompanyUsersStatusState, setGetCompanyUsersStatusState] = React.useState(HttpRequestStatus.NOOP);
  const [loadingStates, setLoadingStates] = React.useState<{ [key: number]: boolean }>({});

  React.useEffect(() => {
    if (companyId) {
      getCompanyUsersProps(companyId);
      resetProps();
    }
  }, [companyId, getCompanyUsersProps]);

  React.useEffect(() => {
    setGetCompanyUsersStatusState(getCompanyUsersStatus);

    if (getCompanyUsersStatus === HttpRequestStatus.SUCCESS && companyUsers) {
      setCompanyUsersState(companyUsers);
    }

    resetProps();
  }, [getCompanyUsersStatus]);

  React.useEffect(() => {
    if (initNewPasswordStatus === HttpRequestStatus.SUCCESS) {
      resetProps();
      toast.success('Email de reset de senha enviado com sucesso', {
        autoClose: 3000,
        onClose: () => {
          getCompanyUsersProps(companyId);
        }
      });
    }
  }, [initNewPasswordStatus]);

  const handleInitNewPassword = async (email: string, index: number) => {
    setLoadingStates(prevState => ({ ...prevState, [index]: true }));

    await initNewPasswordProps(email);

    setLoadingStates(prevState => ({ ...prevState, [index]: false }));
  };

  const renderActionColumn = (email: string, index: number): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        {loadingStates[index] ? (
          <span>
            <TailSpin height="30" width="30" color="black" />
          </span>
        ) : (
          <Button color="info" className={'centered-content margin1'} onClick={() => handleInitNewPassword(email, index)}>
            {translate('companyManage.companyUsersTable.resetKeyButton')}
          </Button>
        )}
      </div>
    );
  };

  const handleTransformToTableContent = (): Array<Array<string | JSX.Element>> => {
    if (companyUsersState == null) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];
    companyUsersState.forEach((item, index) => {
      const { id, email, resetKey, resetDate } = item;

      const urlNewPassword = `${BASE_LP_URL}/entrar/redefinir-senha/${resetKey}`;

      result.push([
        id !== undefined ? id.toString() : '',
        email ?? '',
        resetKey ? <a target="_blank" style={{ color: '#455561' }} href={urlNewPassword}>{urlNewPassword}</a> : '',
        resetDate ? moment(resetDate).format('DD/MM/YYYY HH:mm') : '',
        renderActionColumn(email !== undefined ? email : '', index)
      ]);
    });
    return result;
  };

  return (
    <div>
      <h1 className="tableTitle">{translate('companyManage.companyUsersTable.title')}</h1>
      {getCompanyUsersStatusState === HttpRequestStatus.ONGOING ? (
        <div className="centered-content">
          <TailSpin height="50" width="50" color="black" />
        </div>
      ) : (
        <SimpleTableList
          columNameKeys={translate('companyManage.companyUsersTable.headers')}
          rows={handleTransformToTableContent()}
          emptyTableMessage={translate('companyManage.companyUsersTable.empty')}
          textOverflow
        />
      )}
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  getCompanyUsersStatus: storeState.company.getCompanyUsersStatus,
  companyUsers: storeState.company.companyUsers,
  initNewPasswordStatus: storeState.user.initNewPasswordStatus
});

const mapDispatchToProps = {
  getCompanyUsersProps: getCompanyUsers,
  initNewPasswordProps: initNewPassword,
  resetProps: reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUsersTable);
