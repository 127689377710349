import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { fetchCompany } from 'app/entities/company/company-redux';
import { IPage } from 'app/shared/model/page.model';
import { ICompany } from 'app/shared/model/company';
import { translate } from 'react-jhipster';
import './styles.scss';

interface SimpleCompany {
  id: number;
  name?: string;
}

interface ActivityCompaniesModalProps extends StateProps, DispatchProps {
  setActivityCompanies: (companyArray: SimpleCompany[]) => void;
  activityCompanies: SimpleCompany[];
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ActivityCompaniesModal({
  setActivityCompanies,
  activityCompanies,
  page,
  dispatchFetchCompany,
  isModalOpen,
  setIsModalOpen
}: ActivityCompaniesModalProps) {
  const [ selectAllCompanies, setSelectAllCompanies ] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      dispatchFetchCompany({page: 0, size: 1000}, {status: 'ENABLED', sort: 'name'});
    }
  }, [isModalOpen]);

  const handleToggleCompany = (company: SimpleCompany) => {
    if (activityCompanies.some(activityCompany => activityCompany.id === company.id)) {
      const newActivityCompanies = activityCompanies.filter(activityCompany => activityCompany.id !== company.id);
      setActivityCompanies(newActivityCompanies);
    } else {
      const newActivityCompanies = [...activityCompanies, company];
      setActivityCompanies(newActivityCompanies);
    }
  };

  const handleToggleAllCompanies = () => {
    if (selectAllCompanies) {
      setActivityCompanies([]);
    } else {
      setActivityCompanies(page?.content.map((company: any) => ({id: company.id, name: company.name})));
    }
    setSelectAllCompanies(!selectAllCompanies);
  };

  return (
    <div>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} className="activity-company-modal">
        <ModalHeader toggle={() => setIsModalOpen(false)}>{translate('activityPlan.manage.companyModalHeader')}</ModalHeader>
        <ModalBody>
          <div className="companies-list">
            <div className="company" onClick={handleToggleAllCompanies}>
              {translate('activityPlan.manage.selectCompanies')}
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  checked={selectAllCompanies}
                  className="add-company"
                />
                <span className="checkmark"/>
              </div>
            </div>
            {page?.content?.map(company => (
              <div
                className="company"
                key={company.id}
                onClick={() => handleToggleCompany({id: company.id, name: company.name})}>
                  {company.name}
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={activityCompanies.some(activityCompany => activityCompany.id === company.id)}
                    className="add-company"
                  />
                  <span className="checkmark"/>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button style={{backgroundColor: '#2196f3'}} className={'approveButton'} onClick={() => setIsModalOpen(false)}>
           Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  getCompaniesList: storeState.company.getCompanies,
  page: storeState.company.companiesPage as IPage<ICompany>
});

const mapDispatchToProps = {
  dispatchFetchCompany: fetchCompany
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityCompaniesModal);
