import { ActivityPlan } from 'app/shared/model/activity-plan';
import { IActivity } from 'app/shared/model/activity';
import { ICustomer } from './customer/customer';
import { KeikenPayInvoice } from './KeikenPay/KeikenPayInvoice';

export interface IAssessment {
  id?: number;
  stars?: number;
  description?: string;
  createdDate?: string;
  customer?: ICustomer;
  activity?: IActivity;
  activityPlan?: ActivityPlan;
  keikenPayInvoice?: KeikenPayInvoice;
  assessmentStatus?: IAssessmentStatus;
}

export enum IAssessmentStatus {
  CREATED = 'CREATED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED'
}
