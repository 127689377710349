import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { fetchCustomerWalletByCustomerId, fetchCustomerById, resetCustomer } from 'app/entities/customer/customer-redux';
import { IKeikenTransaction, IKeikenTransactionUtils } from 'app/shared/model/billing/keiken-transactions';
import { ICustomer } from 'app/shared/model/customer/customer';
import { IWallet } from 'app/shared/model/wallet/wallet';
import { IWalletLog } from 'app/shared/model/wallet/wallet-log';
import { IRootState } from 'app/shared/reducers';
import { formatTimestamp } from 'app/shared/util/date-utils';
import StringUtils from 'app/util/StringUtils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Row } from 'reactstrap';
import '../customers-manage.scss';

export interface ICustomerWalletProps extends StateProps, DispatchProps, RouteComponentProps<{
  id?: string;
}, any, {} | any> { }

export interface ICustomerWalletState {
  wallet?: IWallet;
  customer?: ICustomer;
}

class ICustomerWallet extends React.Component<ICustomerWalletProps, ICustomerWalletState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.match.params?.id != null) {
      const id = parseInt(this.props.match.params.id, 10);
      this.props.fetchCustomerById(id);
      this.props.fetchCustomerWalletByCustomerId(id);
    }
  }

  componentWillReceiveProps(newProps: ICustomerWalletProps) {
    if (newProps.customer != null) {
      this.setState({
        customer: newProps.customer
      }, () => {
        this.props.reset();
      });
    }
    if (newProps.wallet != null) {
      this.setState({
        wallet: newProps.wallet
      }, () => {
        this.props.reset();
      });
    }
  }

  private handleWalletLogToTableContent = (content?: IWalletLog[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IWalletLog) => {

      const valueColor = item?.event == 'ADD' ? 'text-success' : item?.event == 'SUBTRACT' ? 'text-danger' : 'text-warning';
      const value = <p className={valueColor}>{StringUtils.currencyPtBr(item?.keikenTransaction?.value)}</p> ?? '';

      result.push([
        formatTimestamp(item?.date),
        StringUtils.currencyPtBr(item?.balanceFinal),
        item?.keikenTransaction?.type,
        value,
        formatTimestamp(item?.keikenTransaction?.processDate)
      ]);
    });

    return result;
  };

  private handleTransformToTableContent = (content?: IKeikenTransaction[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    content.sort(IKeikenTransactionUtils.compareByType);

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IKeikenTransaction) => {
      result.push([
        item.type,
        item.status,
        StringUtils.currencyPtBr(item.value ?? 0),
        item.scheduledDate ? formatTimestamp(item.scheduledDate) : '',
        <a key="billing-ref" href={`/#/application-admin/billings/${item?.billing?.id}`}>
          {item?.billing?.id}
        </a>
      ]);
    });
    return result;
  };

  render() {
    const content = this.handleWalletLogToTableContent(this.state.wallet?.walletLogs) ?? [];
    const transactionsContent = this.handleTransformToTableContent(this.state.wallet?.futureKeikenTransactions) ?? [];
    const count = transactionsContent?.length ?? 0;

    return (
      <div className={'customers-manage-container'}>
        <Row>
          <h1>Dados do customer</h1>
        </Row>
        <Row>
          <ul className="marginB3">
            <li>{`Nome: ${this.state.customer?.name}`}</li>
            <li>{`Email: ${this.state.customer?.user?.email}`}</li>
          </ul>
        </Row>
        <Row>
          <h1>Dados da carteira</h1>
        </Row>
        <Row>
          <ul className="marginB3">
            <li>{`Saldo: ${StringUtils.currencyPtBr(this.state.wallet?.balance)}`}</li>
            <li>{`Transações agendadas: ${count}`}</li>
          </ul>
        </Row>

        <Row className="marginB3">
          <h1>Histórico de transações</h1>
          <SimpleTableList
            rows={content}
            columNameKeys={translate('customerWallet.tableHeaders')}
            emptyTableMessage={translate('customerWallet.labels.emptyList')}
            textOverflow
          />
        </Row>

        <Row className="marginB3">
          <h1>Transações agendadas</h1>
          <SimpleTableList
            rows={transactionsContent}
            columNameKeys={translate('customerWallet.tableTransactionsHeaders')}
            emptyTableMessage={translate('billingManage.labels.emptyList')}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  customer: storeState.customer.customer,
  wallet: storeState.customer.wallet
});

const mapDispatchToProps = {
  fetchCustomerWalletByCustomerId,
  fetchCustomerById,
  reset: resetCustomer
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ICustomerWallet);
