export enum CustomerActivityStatus {
  APPROVED = 'APPROVED',
  IN_PROCESS = 'IN_PROCESS',
  DENIED = 'DENIED',
  NONE = 'NONE',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  WAITING_APPROVE = 'WAITING_APPROVE',
  PRE_SCHEDULED = 'PRE_SCHEDULED',
  PRE_SCHEDULED_BY_PARTNER = 'PRE_SCHEDULE_BY_PARTNER',
  REQUEST_EDIT_BY_PARTNER = 'REQUEST_EDIT_BY_PARTNER',
  PAYMENT_OVERDUE = 'PAYMENT_OVERDUE'
}
