import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CoolButton from 'app/components/button/Button';
import ColorPicker from 'app/components/ColorPicker/ColorPicker';
import InputField from 'app/components/input-field/InputField';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { createActivityCategory, reset, updateActivityCategory } from 'app/entities/activity-category/activity-category-reducer';
import { fetchListActivitySubCategories } from 'app/entities/activity-sub-category/activity-sub-category-reducer';
import { fetchCompany } from 'app/entities/company/company-redux';
import { ActivityCategoryStatus, ActivityCategoryType, IActivityCategory } from 'app/shared/model/activity-category';
import { IActivitySubCategory } from 'app/shared/model/activity-sub-category';
import { ICompany } from 'app/shared/model/company';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IRootState } from 'app/shared/reducers';
import _ from 'lodash';
import * as React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import './category-manage.scss';

export interface ICategoryManageProps
  extends StateProps,
  DispatchProps,
  RouteComponentProps<{}, any, { activityCategory: any; editCategory: any } | any> { }

export interface ICategoryManageState {
  //   categorie?: IActivityCategory;
  activityCategory?: IActivityCategory;
  buttonDisabled?: boolean;
  editCategory?: boolean;
  isErrors?: { [key: string]: boolean | undefined };
  isValids?: { [key: string]: boolean | undefined };
}

export class ICategoryManage extends React.Component<ICategoryManageProps, ICategoryManageState> {
  constructor(props) {
    super(props);
    this.state = {
      activityCategory: {
        name: undefined,
        companies: [],
        activitySubCategories: []
      },
      isErrors: {
        name: undefined
      },
      isValids: {
        name: undefined
      }
    };
  }

  componentDidMount() {
    if (this.props.location.state != null && this.props.location.state.activityCategory != null) {
      this.setState({
        activityCategory: this.props.location.state.activityCategory
      });
    }

    if (this.props.location.state != null && this.props.location.state.editCategory != null) {
      this.setState({
        editCategory: this.props.location.state.editCategory
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.createCategory === HttpRequestStatus.SUCCESS) {
      this.props.reset();
      toast.success(translate('categoryManage.messages.createSuccess'), {
        onClose: () => {
          this.props.history.push('categories-list');
        },
        autoClose: 2000
      });
      return;
    }

    if (newProps.updateCategory === HttpRequestStatus.SUCCESS) {
      this.props.reset();
      toast.success(translate('categoryManage.messages.editSuccess'), {
        onClose: () => {
          this.props.reset();
          this.props.history.push('categories-list');
        },
        autoClose: 2000
      });
      return;
    }

    if (newProps.updateCategory === HttpRequestStatus.ERROR || newProps.createCategory === HttpRequestStatus.ERROR) {
      this.setState({
        buttonDisabled: false
      });
    }
  }

  private handleChanged = (fieldKey: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const activityCategory = this.state.activityCategory;

    if (fieldKey == 'groupNumbers') {
      let newGroupNames = this.state.activityCategory?.groupNames ?? [];
      if (newGroupNames.length > _.parseInt(value)) {
        newGroupNames = newGroupNames?.slice(0, _.parseInt(value));
      } else {
        const tailGroupNames = _.range(newGroupNames.length, _.parseInt(value)).map(it => `Grupo ${it}`);
        newGroupNames = _.concat(newGroupNames, tailGroupNames);
      }
      activityCategory.groupNames = newGroupNames;
    }

    this.setState({
      activityCategory: { ...activityCategory, [fieldKey]: value },
      isErrors: { ...this.state.isErrors, [fieldKey]: false },
      isValids: { ...this.state.isValids, [fieldKey]: false }
    });
  };
  private handleChangeColor = (color: string) => {
    this.setState({
      activityCategory: { ...this.state.activityCategory, color }
    });
  };
  private handleCreate = () => {
    this.props.createActivityCategory(this.state.activityCategory);
  };

  private handleUpdate = () => {
    this.props.updateActivityCategory(this.state.activityCategory);
  };

  private handleChangedGroupNames = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const groupNames = this.state.activityCategory?.groupNames ?? [];
    groupNames[index] = value;
    this.setState({
      activityCategory: { ...this.state.activityCategory, groupNames }
    });
  }

  private validateForm = (): boolean => {
    let result = true;
    let validName = true;
    let errorName = false;
    let validSubCategories = true;
    let errorSubCategories = false;
    let validGroupNumbers = true;
    let errorGroupNumbers = false;

    if (this.state.activityCategory.name === undefined || this.state.activityCategory.name === '') {
      result = false;
      validName = false;
      errorName = true;
    }

    if (this.state.activityCategory.activitySubCategories === undefined || this.state.activityCategory.activitySubCategories.length === 0) {
      result = false;
      validSubCategories = false;
      errorSubCategories = true;
    }

    if (this.state.activityCategory.type == ActivityCategoryType.CHALLENGE && this.state.activityCategory.groupNumbers < 1) {
      result = false;
      validGroupNumbers = false;
      errorGroupNumbers = true;
    }

    this.setState({
      isErrors: { ...this.state.isErrors, ['name']: errorName, ['subCategories']: errorSubCategories, ['groupNumbers']: errorGroupNumbers },
      isValids: { ...this.state.isValids, ['name']: validName, ['subCategories']: validSubCategories, ['groupNumbers']: validGroupNumbers }
    });

    return result;
  };

  private handleSave = () => {
    if (!this.validateForm() || this.state.buttonDisabled) {
      return;
    }
    this.setState({
      buttonDisabled: true
    });

    if (this.state.editCategory) {
      this.handleUpdate();
    } else {
      this.handleCreate();
    }
  };

  private deleteSubCategory = (subCategory?: IActivitySubCategory) => {
    const category: IActivityCategory = this.state.activityCategory;

    category.activitySubCategories = category.activitySubCategories
      ? category.activitySubCategories.filter(it => it.id !== subCategory.id)
      : Array<IActivitySubCategory>();

    this.setState({
      activityCategory: { ...this.state.activityCategory, ...category }
    });
  };

  private deleteCompany = (company?: ICompany) => {
    const category: IActivityCategory = this.state.activityCategory;

    category.companies = category.companies ? category.companies.filter(it => it.id !== company.id) : [];

    this.setState({
      activityCategory: { ...this.state.activityCategory, ...category }
    });
  };

  private renderSubCategoryList = (subCategory: IActivitySubCategory): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <label>{subCategory.name}</label>
        <Button className={'category-manage-delete-button'} onClick={this.deleteSubCategory.bind(this, subCategory)}>
          <FontAwesomeIcon icon={'trash-alt'} className={'marginR1'} />
          <Translate contentKey={'categoryList.buttons.delete'} />
        </Button>
      </div>
    );
  };

  private renderCompany = (company: ICompany): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <label>{company.name}</label>
        <Button className={'category-manage-delete-button'} onClick={() => this.deleteCompany(company)}>
          <FontAwesomeIcon icon={'trash-alt'} className={'marginR1'} />
          <Translate contentKey={'categoryList.buttons.delete'} />
        </Button>
      </div>
    );
  };

  private handleSubCategoryList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: translate('categoryManage.labels.search'),
      action: this.props.fetchListActivitySubCategories,
      placeholderInput: translate('editActivity.placeholders.searchSubCategory'),
      stateAction: 'activitySubCategories',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleSubCategorySelected(item)
    });
  };

  private handleCompanyList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: translate('categoryManage.labels.searchCompany'),
      action: this.props.fetchCompany,
      placeholderInput: translate('editActivity.placeholders.searchCompany'),
      stateAction: 'company',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleCompanySelected(item)
    });
  };

  private handleSubCategorySelected = (item: IActivitySubCategory) => {
    const category = this.state.activityCategory;

    if (category.activitySubCategories == null) {
      category.activitySubCategories = Array<IActivitySubCategory>();
    }

    if (category.activitySubCategories.filter(it => it.id === item.id).length === 0) {
      category.activitySubCategories.push(item);
      this.setState({
        activityCategory: { ...this.state.activityCategory, ...category }
      });
    }
    this.props.hideModal();
  };

  private handleCompanySelected = (item: ICompany) => {
    const category = this.state.activityCategory;

    if (category.companies == null) {
      category.companies = [];
    }

    if (category.companies.filter(it => it.id === item.id).length === 0) {
      category.companies.push(item);
      this.setState({
        activityCategory: { ...this.state.activityCategory, ...category }
      });
    }
    this.props.hideModal();
  };

  private handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const newImage = this.state.activityCategory.image || {};
      const contentType = selectedFile.type;
      newImage.contentType = contentType;
      newImage.fileName = selectedFile.name;
      newImage.imageUrl = undefined;
      newImage.file = ev.target.result.split(',')[1];
      this.setState(prevState => ({ activityCategory: { ...prevState.activityCategory, image: newImage } }));
    };
    reader.readAsDataURL(selectedFile);
  };

  private renderImageToShow = (): JSX.Element => {
    if (this.state.activityCategory.image == null) {
      return (
        <div>
          <label className={'category-manage-title'}>
            <Translate contentKey={'categoryManage.labels.noneImage'} />
          </label>
        </div>
      );
    }

    return (
      <div>
        {this.state.activityCategory.image.imageUrl ? (
          <img src={this.state.activityCategory.image ? this.state.activityCategory.image.imageUrl : ''} />
        ) : (
            <img src={`data:image/jpeg;base64,${this.state.activityCategory.image.file}`} />
          )}
      </div>
    );
  };

  private handleChangeType = e => {
    const activityCategory = this.state.activityCategory;
    activityCategory.groupNumbers = 1;
    activityCategory.type = e?.target?.value;
    this.setState({ activityCategory });
  }

  private handleChangeStatus = e => {
    const activityCategory = this.state.activityCategory;
    activityCategory.status = e?.target?.value;
    this.setState({ activityCategory });
  }

  render() {
    return (
      <div className={'form-container'}>
        <div className={'title-screen-container'}>
          <div onClick={() => this.props.history.push('categories-list')}>
            <FontAwesomeIcon className={'back-arrow-icon'} icon={'arrow-left'} />
          </div>
          <label className={'category-manage-title'}>
            <Translate
              contentKey={this.state.editCategory ? 'categoryManage.labels.editCategory' : 'categoryManage.labels.createCategory'}
            />
          </label>
        </div>

        <div className={'category-manage-image-container'}>
          {this.renderImageToShow()}

          <div className={'category-manage-button-add-image'}>
            <input
              id={'myFileInput'}
              className={'edit-activity-step1-image-input'}
              onChange={this.handleImage}
              accept={'image/*'}
              type={'file'}
            />
            <CoolButton
              onClick={() => document.getElementById('myFileInput').click()}
              title={translate(this.state.activityCategory.image ? 'categoryManage.button.changeImage' : 'categoryManage.button.addImage')}
            />
          </div>
        </div>

        <div className={'input-container'}>
          <InputField
            title={translate('categoryManage.placeholders.name')}
            onChange={this.handleChanged.bind(this, 'name')}
            error={this.state.isErrors['name']}
            valid={this.state.isValids['name']}
            inputClassName={''}
            value={!!this.state.activityCategory.name ? this.state.activityCategory.name : ''}
            placeholder={'categoryManage.placeholders.name'}
          />
        </div>

        <div className={'category-manage-input-divisor'}>
          <div className={'category-manage-inner-addon'}>
            <div className={'category-manage-inner-addon'}>
              <InputField
                title={translate('categoryManage.placeholders.color')}
                onChange={this.handleChanged.bind(this, 'color')}
                error={this.state.isErrors['color']}
                valid={this.state.isValids['color']}
                inputClassName={''}
                value={!!this.state.activityCategory.color ? this.state.activityCategory.color : ''}
                placeholder={'categoryManage.placeholders.color'}
              />
            </div>
            <div className={'category-manage-inner-icon'}>
              {/* <FontAwesomeIcon className={'category-manage-inner-icon'} icon={'search'} /> */}
              <div className={'category-manage-color-container'} style={{
                backgroundColor: this.state.activityCategory?.color ?? '#000'
              }} />
            </div>
          </div>
          <div className={'category-manage-inner-addon'}>
            <ColorPicker
              value={this.state.activityCategory?.color}
              onChange={this.handleChangeColor} />
          </div>
        </div>

        <div className={'category-manage-input-divisor'}>
          <div className={'category-manage-inner-addon'} onClick={this.handleSubCategoryList}>
            <InputField
              title={translate('categoryManage.labels.search')}
              inputClassName={'category-manage-search-input'}
              value={''}
              error={this.state.isErrors['subCategories']}
              valid={this.state.isValids['subCategories']}
              placeholder={'categoryManage.placeHolders.search'}
              readOnly
              onClick={this.handleSubCategoryList}
            />

            <div className={'category-manage-inner-icon'}>
              <FontAwesomeIcon className={'category-manage-inner-icon'} icon={'search'} />
            </div>
          </div>
        </div>

        <div className={'category-manage-subcategories-list'}>
          {this.state.activityCategory.activitySubCategories != null &&
            this.state.activityCategory.activitySubCategories.map((item, index) => (
              <div className={'category-manage-subcategories-container'}>{this.renderSubCategoryList(item)}</div>
            ))}
        </div>

        <div className={'category-manage-input-divisor'}>
          <div className={'category-manage-inner-addon'} onClick={this.handleCompanyList}>
            <InputField
              title={translate('categoryManage.labels.searchCompany')}
              inputClassName={'category-manage-search-input'}
              value={''}
              placeholder={'categoryManage.placeHolders.searchCompany'}
              readOnly
              onClick={this.handleCompanyList}
            />

            <div className={'category-manage-inner-icon'}>
              <FontAwesomeIcon className={'category-manage-inner-icon'} icon={'search'} />
            </div>
          </div>
        </div>

        <div className={'category-manage-subcategories-list'}>
          {this.state.activityCategory.companies != null &&
            this.state.activityCategory.companies.map((item, index) => (
              <div key={item.id} className={'category-manage-subcategories-container'}>
                {this.renderCompany(item)}
              </div>
            ))}
        </div>
        <div className={'partner-register-input-divisor-selector'}>
          <label className={'partner-register-input-label'}>
            <Translate contentKey={'categoryManage.labels.type'} />
          </label>
          <select
            onChange={this.handleChangeType}
            value={this.state.activityCategory?.type}
            className={'partner-register-select'}
          >
            {Object.values(ActivityCategoryType).map((item, index) => (
              <option value={item} key={item + index}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className={'partner-register-input-divisor-selector'}>
          <label className={'partner-register-input-label'}>
            <Translate contentKey={'categoryManage.labels.status'} />
          </label>
          <select
            onChange={this.handleChangeStatus}
            value={this.state.activityCategory?.status}
            className={'partner-register-select'}
          >
            {Object.values(ActivityCategoryStatus).map((item, index) => (
              <option value={item} key={item + index}>
                {item}
              </option>
            ))}
          </select>
        </div>
        {this.state.activityCategory?.type == ActivityCategoryType.CHALLENGE && (
          <div className={'input-container'}>
            <InputField
              title={translate('categoryManage.labels.groupNumbers')}
              onChange={this.handleChanged.bind(this, 'groupNumbers')}
              error={this.state.isErrors['groupNumbers']}
              valid={this.state.isValids['groupNumbers']}
              inputClassName={''}
              value={!!this.state.activityCategory.groupNumbers ? this.state.activityCategory.groupNumbers : ''}
              placeholder={'categoryManage.labels.groupNumbers'}
              type={'number'}
            />
          </div>
        )}
        {this.state.activityCategory?.groupNumbers > 1 && this.state.activityCategory?.groupNames?.map?.((it, index) => (
          <div className={'input-container'}>
            <InputField
              title={translate('categoryManage.labels.groupNames')}
              onChange={this.handleChangedGroupNames.bind(this, index)}
              inputClassName={''}
              value={it ?? ''}
              placeholder={'categoryManage.labels.groupNames'}
              type={'text'}
            />
          </div>
        ))}
        <div className={'marginT15'}>
          <CoolButton
            onClick={this.handleSave}
            title={translate(this.state.editCategory ? 'categoryManage.button.edit' : 'categoryManage.button.create')}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  updateCategory: storeState.activityCategories.updateCategory,
  createCategory: storeState.activityCategories.createCategory
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  reset,
  fetchListActivitySubCategories,
  fetchCompany,
  createActivityCategory,
  updateActivityCategory
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ICategoryManage);
