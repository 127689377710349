import { ICustomer } from './customer/customer';
import { IActivitySchedule } from './activity-schedule/activity-schedule';
import { CustomerActivityStatus } from './enum/CustomerActivityStatus';
import moment from 'moment';
import { IActivitySubCategory } from './activity-sub-category';
import { IActivityCategory } from './activity-category';
import { IBilling } from 'app/shared/model/billing/billing';

export interface ICustomerActivity {
  id?: number;
  customer?: ICustomer;
  token?: string;
  activitySchedule?: IActivitySchedule;
  activityCategory?: IActivityCategory;
  activitySubCategory?: IActivitySubCategory;
  expirationDate?: Date | string;
  tokenUsed?: boolean;
  status?: CustomerActivityStatus;
  billing?: IBilling;
  tokenUsedUpdatedBy?: string;
  tokenUsedUpdatedDate?: string;
}

export class ICustomerActivityFunctions {

  static getTitle(item: ICustomerActivity) {
    const name = item?.activitySubCategory?.name;
    return name ?? '';
  }

  static getScheduleWeekDay(item: ICustomerActivity) {
    const date = item?.activitySchedule?.date;
    if (date == null) {
      return '';
    }
    return moment(date).locale('pt-br').format('dddd');
  }

  static getScheduleDate(item: ICustomerActivity) {
    const date = item?.activitySchedule?.date;
    if (date == null) {
      return '';
    }
    return moment(date).format('DD/MM/YY - HH:mm');
  }

  static getPartnerName(item: ICustomerActivity) {
    return item?.activitySchedule?.activity?.partnerPlace?.fantasyName ?? '';
  }

  static isApproved(item: ICustomerActivity) {
    return item?.status === CustomerActivityStatus.APPROVED;
  }

  static getThumbnail(item: ICustomerActivity) {
    return item.activitySchedule?.activity?.partnerPlace?.image?.imageUrl ??
      './content/images/default-customer-activity.png';
  }
}
