import React from 'react';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PartnerDashboard from './dashboard/partner-dashboard';
import NewActivity from './new-activity/new-activity';
import PartnerSettings from './settings/partner-settings';
import PartnerCalendarActivity from './calendar/partner-calendar-activity';
import EditActivity from './edit-activity/edit-activity';
import Financial from './financial/financial';
import ActivityActive from './activity-active/activity-active';

const Routes = ({ match }) => (
  <div>
    <ErrorBoundaryRoute path={`${match.url}/partner-dashboard`} component={PartnerDashboard} />
    <ErrorBoundaryRoute path={`${match.url}/new-activity`} component={NewActivity} />
    <ErrorBoundaryRoute path={`${match.url}/edit-activity`} component={EditActivity} />
    <ErrorBoundaryRoute path={`${match.url}/partner-settings`} component={PartnerSettings} />
    <ErrorBoundaryRoute path={`${match.url}/calendar`} component={PartnerCalendarActivity} />
    <ErrorBoundaryRoute path={`${match.url}/financial`} component={Financial} />
    <ErrorBoundaryRoute path={`${match.url}/activity-active`} component={ActivityActive} />
  </div>
);

export default Routes;
