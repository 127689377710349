import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';

export const ACTION_TYPES = {
  GET_ACTIVITY_SCHEDULES: 'activitySchedule/GET_ACTIVITY_SCHEDULES',
  RESET: 'activitySchedule/RESET'
};

const initialState = {
  getActivitiesSchedulesStatus: HttpRequestStatus.NOOP,
  activitySchedules: null
};

export type IActivityScheduleState = Readonly<typeof initialState>;

// Reducer
export default (state: IActivityScheduleState = initialState, action: IActionForResponseEntity<{}>): IActivityScheduleState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_ACTIVITY_SCHEDULES):
      return {
        ...initialState,
        getActivitiesSchedulesStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_ACTIVITY_SCHEDULES):
      return {
        ...initialState,
        getActivitiesSchedulesStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_ACTIVITY_SCHEDULES):
      return {
        ...initialState,
        getActivitiesSchedulesStatus: HttpRequestStatus.SUCCESS,
        activitySchedules: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/mobile/activity-schedules';

export const getActivitySchedulesForActivity = (id: number) => ({
  type: ACTION_TYPES.GET_ACTIVITY_SCHEDULES,
  payload: axios.get(`${apiUrl}/activity/${id}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
