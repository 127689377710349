import { IImage } from 'app/shared/model/image';
import React from 'react';
import { Row, Col } from 'reactstrap';
import CoolButton from 'app/components/button/Button';
import { translate } from 'react-jhipster';

interface KeykenPaynImgsProps {
  img: IImage;
  handleNewImg: (newImage: IImage, type: 'MAIN' | 'AUX' | 'USERAUX', replaceIdx?: number) => void;
  type: 'MAIN' | 'AUX' | 'USERAUX';
  idx?: number;
}

const KeykenPayImg = ({ img, handleNewImg, type, idx }: KeykenPaynImgsProps) => {
  const inputRefImage = React.useRef<HTMLInputElement>(null);

  const handleInputImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const newImage = img ?? {};
      const contentType = selectedFile.type;
      newImage.contentType = contentType;
      newImage.fileName = selectedFile.name;
      newImage.imageUrl = undefined;
      newImage.file = ev.target.result.split(',')[1];
      handleNewImg(newImage, type, idx);
    };
    reader.readAsDataURL(selectedFile);
  };

  return (
    <>
      <Row>
        <div className="keiken-pay-img-container">
          {img?.imageUrl ? (
            <img src={img?.imageUrl} alt="" />
            ) : img?.file && (
              <img src={`data:image/jpeg;base64,${img?.file}`} alt="" />
              )}
        </div>
        <button onClick={() => inputRefImage.current?.click()} style={{width: 212, marginLeft: 40, height: 48, color: '#25B379', backgroundColor: 'transparent', border: '1px solid #25B379', borderRadius: 8}}>Alterar imagem</button>
      </Row>
      <Row style={{display: 'none'}}>
        <input
          ref={inputRefImage}
          type="file"
          accept="image/*"
          onChange={e => handleInputImage(e)}
        />
      </Row>
    </>
  );
};

export default KeykenPayImg;
