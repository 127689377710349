import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import StringUtils from 'app/util/StringUtils';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IActivityCategory } from '../../shared/model/activity-category';

export const ACTION_TYPES = {
  FETCH_ACTIVITY_CATEGORIES: 'activityCategories/FETCH_ACTIVITY_CATEGORIES',
  CREATE_ACTIVITY_CATEGORY: 'activityCategories/CREATE_ACTIVITY_CATEGORY',
  UPDATE_ACTIVITY_CATEGORY: 'activityCategories/UPDATE_ACTIVITY_CATEGORY',
  DELETE_ACTIVITY_CATEGORIES: 'activityCategories/DELETE_ACTIVITY_CATEGORIES',
  RESET: 'activityCategories/RESET'
};

const initialState = {
  page: null,
  deleteCategory: false,
  getCategoryList: HttpRequestStatus.NOOP,
  updateCategory: HttpRequestStatus.NOOP,
  createCategory: HttpRequestStatus.NOOP
};

export type ActivityCategoryState = Readonly<typeof initialState>;

// Reducer
export default (state: ActivityCategoryState = initialState, action): ActivityCategoryState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ACTIVITY_CATEGORIES):
      return {
        ...state,
        getCategoryList: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_ACTIVITY_CATEGORIES):
      return {
        ...initialState,
        getCategoryList: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACTIVITY_CATEGORIES):
      return {
        ...initialState,
        page: action.payload.data,
        getCategoryList: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.DELETE_ACTIVITY_CATEGORIES):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.DELETE_ACTIVITY_CATEGORIES):
      return {
        ...initialState
      };
    case SUCCESS(ACTION_TYPES.DELETE_ACTIVITY_CATEGORIES):
      return {
        ...initialState,
        deleteCategory: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ACTIVITY_CATEGORY):
      return {
        ...initialState,
        createCategory: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.CREATE_ACTIVITY_CATEGORY):
      return {
        ...initialState,
        createCategory: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACTIVITY_CATEGORY):
      return {
        ...initialState,
        createCategory: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.UPDATE_ACTIVITY_CATEGORY):
      return {
        ...initialState,
        updateCategory: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_ACTIVITY_CATEGORY):
      return {
        ...initialState,
        updateCategory: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ACTIVITY_CATEGORY):
      return {
        ...initialState,
        updateCategory: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export const fetchActivityCategories = (page?: any, name?: string) => {
  let params;

  if (!StringUtils.isStringInvalid(name)) {
    params = {
      name,
      size: page.size,
      page: page.page
    };
  }

  return {
    type: ACTION_TYPES.FETCH_ACTIVITY_CATEGORIES,
    payload: !!params ? axios.get('api/activity-categories', { params }) : axios.get('api/activity-categories')
  };
};

export const fetchAllActivityCategories = (page?: any, name?: string, filter?: any) => {
  let params;

  if (!StringUtils.isStringInvalid(name)) {
    params = {
      name,
      size: page != null ? page.size : 20,
      page: page != null ? page.page : 0
    };
  } else {
    params = {
      size: page != null ? page.size : 20,
      page: page != null ? page.page : 0,
      ...filter
    };
  }

  return {
    type: ACTION_TYPES.FETCH_ACTIVITY_CATEGORIES,
    payload: !!params ? axios.get('api/activity-categories/all', { params }) : axios.get('api/activity-categories/all')
  };
};

export const createActivityCategory = (category: IActivityCategory) => ({
  type: ACTION_TYPES.CREATE_ACTIVITY_CATEGORY,
  payload: axios.post('api/activity-categories', category)
});

export const updateActivityCategory = (category: IActivityCategory) => ({
  type: ACTION_TYPES.UPDATE_ACTIVITY_CATEGORY,
  payload: axios.put('api/activity-categories', category)
});

export const deleteActivityCategory = (id?: Number) => ({
  type: ACTION_TYPES.DELETE_ACTIVITY_CATEGORIES,
  payload: axios.delete(`api/activity-categories/delete/${id}`, {})
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
