import { getActivityById, reset, updateActivity } from 'app/entities/activity-admin/activityAdmin';
import { toast } from 'react-toastify';
import { IActivity } from 'app/shared/model/activity';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import InputField from 'app/components/input-field/InputField';
import { Row, Col, Button } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import ActivityCompaniesModal from 'app/components/activityCompaniesModal';
import GradientButton from 'app/components/button/Button';
import { ActivityPlaceType } from 'app/shared/model/activity-plan';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { fetchActivityCategories } from 'app/entities/activity-category/activity-category-reducer';
import { fetchActivitySubCategories } from 'app/entities/activity-sub-category/activity-sub-category-reducer';
import { IActivityCategory } from 'app/shared/model/activity-category';
import './activity.scss';
import { IActivitySubCategory } from 'app/shared/model/activity-sub-category';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import CurrencyInput from 'react-currency-input';
import ApprovePriceTableActivities from '../../../components/ApprovePriceTableActivities/ApprovePriceTableActivities';
import { IPriceTableActivity } from '../../../../app/shared/model/price-table';

export interface IActivityManageProps extends StateProps, DispatchProps, RouteComponentProps<{
  id?: string;
}, any, { activity: IActivity } | any> {
  activity: IActivity;
  loading: boolean;
}

function ActivityManage({
  match: { params: { id } },
  activity,
  getById,
  update,
  dispatchShowModal,
  dispatchHideModal,
  dispatchFetchActivitySubCategories,
  dispatchFetchActivityCategories,
  resetActivities,
  updateStatus
}: IActivityManageProps) {
  const [ editingActivity, setEditingActivity ] = React.useState<IActivity | undefined>(activity);
  const [ isCompaniesModalOpen, setIsCompaniesModalOpen] = React.useState<boolean>(false);
  const [activityPrice, setActivityPrice] = React.useState<number>(activity?.price);

  useEffect(() => {
    if (activity?.price) {
      setActivityPrice(activity.price);
    }
  }, [activity?.price]);

  useEffect(() => {
    return () => {
      resetActivities();
    };
  }, []);

  useEffect(() => {
    if (!activity) {
      getById(id);
    }
    setEditingActivity(activity);
    dispatchFetchActivityCategories();
  }, [activity]);

  const handleSave = () => {
    update(editingActivity);
  };

  useEffect(() => {
    if (updateStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Atividade salva com sucesso!');
    } else if (updateStatus === HttpRequestStatus.ERROR) {
      toast.error('Erro ao salvar alteração!');
    } else if (updateStatus === HttpRequestStatus.ONGOING) {
      toast.info('Atividade sendo salva...');
    }
  }, [updateStatus]);

  const deleteCategory = (category: IActivityCategory) => {
    setEditingActivity(prevState => ({
      ...prevState,
      activityCategories: prevState.activityCategories.filter(it => it.id !== category.id)
    }));
    dispatchFetchActivityCategories();
  };

  const handleCategorySelected = (item: IActivityCategory) => {

    if (editingActivity.activityCategories == null) {
      editingActivity.activityCategories = Array<IActivityCategory>();
    }

    if (!editingActivity.activityCategories.some(it => it.id === item.id)) {
      setEditingActivity(prevState => ({
        ...prevState,
        activityCategories: [...prevState.activityCategories, item]
      }));
    }
    dispatchFetchActivityCategories();
    dispatchHideModal();
  };

  const handleSubCategorySelected = (item: IActivitySubCategory) => {

    if (editingActivity.activitySubCategories == null) {
      editingActivity.activitySubCategories = Array<IActivitySubCategory>();
    }

    if (editingActivity.activitySubCategories.filter(it => it.id === item.id).length === 0) {
      setEditingActivity(prevState => ({
        ...prevState,
        activitySubCategories: [...prevState.activitySubCategories, item]
      }));
    }

    dispatchHideModal();
  };

  const handleCategoryList = () => {
    dispatchShowModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: 'Categoria',
      action: dispatchFetchActivityCategories,
      placeholderInput: 'Adicionar categoria',
      stateAction: 'activityCategories',
      cancelCallback: () => dispatchHideModal(),
      saveCallback: (item: any) => handleCategorySelected(item)
    });
  };

  const deleteSubCategory = (subCategory?: IActivitySubCategory) => {
    const removedSubCategories = editingActivity.activitySubCategories?.filter?.(it => it.id !== subCategory.id) ?? [];
    setEditingActivity(prevState => ({
      ...prevState,
      activitySubCategories: removedSubCategories
    }));
  };

  const handleSubCategoryList = () => {
    dispatchShowModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: 'SubCategoria',
      action: dispatchFetchActivitySubCategories,
      idToSearch: editingActivity.activityCategories?.map(it => it.id) ?? [],
      placeholderInput: 'Adicionar sub categoria',
      stateAction: 'activitySubCategories',
      cancelCallback: () => dispatchHideModal(),
      saveCallback: (item: any) => handleSubCategorySelected(item)
    });
  };

  const handleChange = (field: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const value = event.target.value;
    setEditingActivity(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleChangePrice = (value: number) => {
    setActivityPrice(value);
    setEditingActivity(prevState => ({
      ...prevState,
      price: value
    }));
  };

  const { name, partnerPlace, type, description, activityCategories, activitySubCategories, allowedCompanies } = editingActivity ?? {};

  const imageUrl = activity?.image?.imageUrl ?? './content/images/default-customer-activity.png';

  return (
    <div style={{margin: '100px 0 30px 30px'}}>
      <Row className={'activity-plan-input-container'}>
        <img src={imageUrl} className={'customer-activity-img'} />
      </Row >
      <Row className={'activity-plan-input-container'}>
        <Col>
          <InputField
            title={translate('activityPlan.placeholders.name')}
            onChange={e => handleChange('name', e)}
            inputClassName={''}
            value={name}
            placeholder={'activityPlan.placeholders.name'}
          />
        </Col>
        <Col>
          <InputField
            title={translate('activityPlan.placeholders.fantasyName')}
            inputClassName={''}
            value={partnerPlace?.fantasyName}
            readOnly
            placeholder={'activityPlan.placeholders.fantasyName'}
          />
        </Col>
      </Row>
      <Row className={'activity-plan-input-container'}>
        <Col>
          <p>{translate('activityPlan.placeholders.type')}</p>
          <select
            onChange={e => handleChange('type', e)}
            value={type}
            className={'partner-register-select'}
          >
            {Object.values(ActivityPlaceType).map((it, index) => (
              <option value={it} key={`activity-plan-type-${it}-${index}`}>{it}</option>
            ))}
          </select>
        </Col>
      </Row>
      <Row className={'activity-plan-input-container'}>
        <Col>
          <InputField
            title={translate('activityPlan.placeholders.description')}
            inputClassName={'activity-plan-description-input'}
            value={description}
            onChange={e => handleChange('description', e)}
            isTextArea
            placeholder={'activityPlan.placeholders.description'}
          />
        </Col>
      </Row>
      <Row className={'activity-plan-input-container'}>
        <Col>
        <p>{translate('activityPlan.placeholders.price')}</p>
          <CurrencyInput
            style={{borderRadius: '8px', padding: '5px 10px'}}
            autoComplete={'off'}
            onChangeEvent={(e, masked, floatValue) => handleChangePrice(floatValue)}
            value={activityPrice}
            decimalSeparator={','}
            thousandSeparator={'.'}
            prefix={'R$ '}
          />
        </Col>
      </Row>
      <Row className={'activity-plan-input-container'}>
        <Col>
        <ApprovePriceTableActivities
          priceTableActivities={activity?.priceTableActivities ?? []}
          priceActivity={activity?.price}
          priceWithFee={activity?.priceWithFee}
          onChange={(items: IPriceTableActivity[]) => {
            setEditingActivity(prevState => ({
              ...prevState,
              priceTableActivities: items
            }));
          }}
        />
        </Col>
      </Row>
      <div className={'input-container'}>
        <div className={'category-manage-inner-addon'} onClick={handleCategoryList}>
          <InputField
            inputClassName={'partner-external-input-read-only'}
            title={translate('keikenPayPartnerExternalManage.placeholders.category')}
            value={''}
            placeholder={'keikenPayPartnerExternalManage.placeholders.category'}
            readOnly
            onClick={handleCategoryList}
          />
          <div className={'category-manage-inner-icon'}>
            <FontAwesomeIcon className={'category-manage-inner-icon'} icon={'search'} />
          </div>
        </div>
        <div className={''}>
            {activityCategories != null &&
              activityCategories.map((item, index) => (
              <div className={'partner-external-categories-sub-categories '} key={index}>
                <CategoryItem category={item} deleteCategory={deleteCategory} />
              </div>
            ))}
        </div>
      </div>
      <div className={'input-container'}>
        <div className={'category-manage-inner-addon'} onClick={handleSubCategoryList}>
          <InputField
            inputClassName={'partner-external-input-read-only'}
            title={translate('keikenPayPartnerExternalManage.placeholders.subCategory')}
            value={''}
            placeholder={'keikenPayPartnerExternalManage.placeholders.subCategory'}
            readOnly
            onClick={handleSubCategoryList}
          />
          <div className={'category-manage-inner-icon'}>
            <FontAwesomeIcon className={'category-manage-inner-icon'} icon={'search'} />
          </div>
        </div>
        <div className={''}>
            {activitySubCategories != null &&
              activitySubCategories.map((subCat, index) => (
              <div className={'partner-external-categories-sub-categories '} key={index}>
                <div className={'action-column-container-flex'}>
                  <label >{subCat.name}</label>
                  <Button className={'partner-external-delete-button'} onClick={() => deleteSubCategory(subCat)}>
                    <FontAwesomeIcon icon={'trash-alt'} className={'marginR1'} />
                    <Translate contentKey={'categoryList.buttons.delete'} />
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className={'input-container'}>
        <Button style={{backgroundColor: '#455561'}} onClick={() => setIsCompaniesModalOpen(true)}>
          {translate('activityPlan.manage.allowedCompanies')}
        </Button>
        <div style={{color: 'GrayText', padding: '0 6px'}}>
          {translate('activityPlan.manage.compDescription')}
        </div>
        <ActivityCompaniesModal
          activityCompanies={editingActivity?.allowedCompanies ?? []}
          setActivityCompanies={companies => {
            setEditingActivity(prevState => ({
              ...prevState,
              allowedCompanies: companies
            }));
          }}
          isModalOpen={isCompaniesModalOpen}
          setIsModalOpen={setIsCompaniesModalOpen}
        />
      </div>
      {allowedCompanies?.length > 0 && (
        <>
          <p style={{ fontWeight: 'bold' }}>
            {'Empresas selecionadas: '}
          </p>
          <div className="selected-companies">
            {allowedCompanies?.map(item => (
              <div className="company-tag" key={item.id}>{item.name}</div>
            ))}
          </div>
        </>
        )}
      <div className={'marginT15'}>
        <GradientButton onClick={handleSave} title={translate('companyManage.buttons.save')} />
      </div>
    </div>
  );
}

interface CategoryItemProps {
  category: IActivityCategory;
  deleteCategory: (category: IActivityCategory) => void;
}

function CategoryItem(props: CategoryItemProps) {
  const { category, deleteCategory } = props;
  return (
    <div className={'action-column-container-flex'}>
      <label >{category.name}</label>
      <Button className={'partner-external-delete-button'} onClick={() => deleteCategory(category)}>
        <FontAwesomeIcon icon={'trash-alt'} className={'marginR1'} />
        <Translate contentKey={'categoryList.buttons.delete'} />
      </Button>
    </div>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  activity: storeState.activitiesAdmin.activity,
  updateStatus: storeState.activitiesAdmin.updateActivityStatus
});

const mapDispatchToProps = {
  getById : getActivityById,
  update: updateActivity,
  dispatchFetchActivityCategories: fetchActivityCategories,
  dispatchFetchActivitySubCategories: fetchActivitySubCategories,
  dispatchShowModal: showModal,
  dispatchHideModal: hideModal,
  resetActivities: reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps)(ActivityManage);
