import React, { useEffect } from 'react';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { translate } from 'react-jhipster';
import { IBilling } from 'app/shared/model/billing/billing';
import StringUtils from 'app/util/StringUtils';
import { getCompanyExternalBillings } from 'app/entities/companyBillings/company-billing-reducer';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

type ExternalBillingsTableProps = StateProps & DispatchProps & {
  handleEdit: (billing: IBilling | undefined) => () => void;
  companyId: number;
};

const CompanyExternalBillingsTable = ({ externalBillings, handleEdit, getExternalBillings, companyId }: ExternalBillingsTableProps) => {

  useEffect(() => {
    if (companyId) {
      getExternalBillings(companyId, 0, 10);
    }
  }, [companyId]);

  const handleTransformToTableContent = () => {
    if (!externalBillings?.content || externalBillings?.content?.length === 0) {
      return [];
    }
    const result = [];
    externalBillings.content.map(item => {
      const { date, keikenTax, value, description, paymentProviderId, status, billingCompany: { paymentMethod} } = item;
      result.push([
        moment(date).format('DD/MM/YYYY'),
        StringUtils.currencyPtBr(value),
        StringUtils.currencyPtBr(keikenTax),
        description,
        status,
        paymentProviderId,
        paymentMethod,
        renderActionColumn(item)
      ]);
    });
    return result;
  };

  const renderActionColumn = (billing: IBilling | undefined) => {
    return (
      <div className={'action-column-container-flex'}>
        <Button color="info" className={'centered-content margin1'} onClick={handleEdit(billing)}>
          <FontAwesomeIcon icon={'eye'} />
        </Button>
      </div>
    );
  };

  return (
    <div className="componentContainer">
      <h1 className="tableTitle">{translate('companyManage.external-table.title')}</h1>
      <SimpleTableList
        columNameKeys={translate('companyManage.external-table.headers')}
        rows={handleTransformToTableContent()}
        emptyTableMessage={translate('companyManage.external-table.empty')}
        textOverflow
      />
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  externalBillings: storeState.companyBilling.externalBillingsPage
});

const mapDispatchToProps = {
  getExternalBillings: getCompanyExternalBillings
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps)(CompanyExternalBillingsTable);
