import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import './PartnerBillingsTable.scss';
import StringUtils from '../../util/StringUtils';
import { IRootState } from '../../shared/reducers';
import { getPartnerBillingsWithDraws, patchApprovePartnerWithDraw, postRequestWithDrawByAdmin } from '../../entities/billing/billing.reducer';
import { formatDate, formatLocalDate } from '../../shared/util/date-utils';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InputField from '../input-field/InputField';
import { toast } from 'react-toastify';
import { IPartnerPlace } from '../../shared/model/partner-place.model';
import { translate } from 'react-jhipster';
import { getPartnerPlaceById } from '../../entities/partner-place/partner-place-redux';

interface BillingsTableProps extends StateProps, DispatchProps {
  partnerBillingAccountId?: number;
  partnerPlace?: IPartnerPlace;
}

function BillingsTable({
  partnerBillingAccountId,
  approvePartnerWithdrawStatus,
  requestPartnerWithDrawByAdminStatus,
  billingsWithDraws,
  partnerPlace,
  fetchBillingWithdraws,
  approvePartnerWithDraw,
  requestWithDrawByAdmin,
  fetchPartnerPlace }: BillingsTableProps) {
  const [isApproveModalOpen, setApprovalModal] = useState(false);
  const [transactionId, setTransactionId] = useState(0);
  const [transationPartnerName, setTransationPartnerName] = useState('');
  const [transactionValue, setTransactionValue] = useState(0);
  const [transactionRequestDate, setTransactionRequestDate] = useState('');
  const [withDrawValue, setWithDrawValue] = useState(0);
  const [isRegisterPartnerWithDrawByAdminModalOpen, setRegisterPartnerWithDrawByAdminModalOpen] = useState(false);
  const [maxValuePartnerCanWithDraw, setMaxValuePartnerCanWithDraw] = useState(0);

  useEffect(
    () => {
      if (partnerBillingAccountId !== undefined) {
        fetchBillingWithdraws(partnerBillingAccountId);
      }
    },
    [partnerBillingAccountId]
  );

  useEffect(() => {
    if (partnerPlace?.id) {
      setMaxValuePartnerCanWithDraw(partnerPlace?.wallet?.balance! - (partnerPlace?.wallet?.scheduledWithDrawBalance! + partnerPlace?.wallet?.waitingWithDrawBalance!));
    }
  }, [partnerPlace]);

  useEffect(() => {
    if (isRegisterPartnerWithDrawByAdminModalOpen) {
      setWithDrawValue(maxValuePartnerCanWithDraw);
    }
  }, [isRegisterPartnerWithDrawByAdminModalOpen]);

  useEffect(() => {
    if (approvePartnerWithdrawStatus === 2) {
      fetchPartnerPlace(partnerPlace?.id!);
      fetchBillingWithdraws(partnerBillingAccountId);
      toast.success('Solicitação aprovada com sucesso!');
    }
  }, [approvePartnerWithdrawStatus]);

  useEffect(() => {
    if (requestPartnerWithDrawByAdminStatus === 2) {
      fetchPartnerPlace(partnerPlace?.id!);
      fetchBillingWithdraws(partnerBillingAccountId);
      toast.success('Saque agendado com sucesso!');
    }
  }, [requestPartnerWithDrawByAdminStatus]);

  const toggleApprovalModal = () => {
    setApprovalModal(!isApproveModalOpen);
  };

  const toggleRegisterPartnerWithDrawByAdminModal = () => {
    setRegisterPartnerWithDrawByAdminModalOpen(!isRegisterPartnerWithDrawByAdminModalOpen);
  };

  const openApprovalWithDrawModal = (transactionIdToApprove: number, createdDate, valor) => {
    setTransactionId(transactionIdToApprove);
    setTransationPartnerName(partnerPlace?.fantasyName!);
    setTransactionRequestDate(formatLocalDate(createdDate));
    setTransactionValue(valor);
    toggleApprovalModal();
  };

  const aproveRequest = (transactionToApproveId: number) => {
    approvePartnerWithDraw(partnerPlace?.id, transactionToApproveId);
    toggleApprovalModal();
  };

  const scheduleRequest = () => {
    if (withDrawValue > maxValuePartnerCanWithDraw) {
      toast.error('Valor de saque não pode ser maior que o saldo disponível');
      return;
    } else {
      requestWithDrawByAdmin(partnerPlace?.id, withDrawValue);
      toggleRegisterPartnerWithDrawByAdminModal();
    }
  };

  return (
    <div className="componentContainer">
      <h1 className="tableTitle">{translate('table.title')}</h1>
      <span className="partnerWallet">
        {translate('table.partnerBalance')}
        <strong>{StringUtils.currencyPtBr(maxValuePartnerCanWithDraw)}</strong>
      </span>
      <br />
      <span className="partnerWallet">
        {translate('table.partnerWithDrawAlreadyRequested')}
        <strong>{StringUtils.currencyPtBr(partnerPlace?.wallet?.scheduledWithDrawBalance! + partnerPlace?.wallet?.waitingWithDrawBalance!)}</strong>
      </span>
      <br />
      <button onClick={() => toggleRegisterPartnerWithDrawByAdminModal()} className="registerPartnerWithDrawButton">{translate('table.registerWithDraw')}</button>
      <Table className="billingsTable">
        <thead>
          <tr>
            <th>{translate('table.headers.requestDate')}</th>
            <th>{translate('table.headers.scheduledDate')}</th>
            <th>{translate('table.headers.value')}</th>
            <th>{translate('table.headers.status')}</th>
            <th>{translate('table.headers.approve')}</th>
          </tr>
        </thead>
        <tbody>
          {billingsWithDraws?.content && billingsWithDraws?.content?.map((billing, index) => {
            const transaction = billing?.keikenTransactions[0];
            return (
              <tr key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
                <td>{formatDate(transaction?.createDate)}</td>
                <td>{transaction?.scheduledDate ? formatDate(transaction?.scheduledDate) : translate('table.pendent')}</td>
                <td>{StringUtils.currencyPtBr(transaction?.value)}</td>
                <td>{transaction?.status}</td>
                <td>{<button className={transaction?.scheduledDate ? 'disabledButton' : 'approveButton'} onClick={() => !transaction?.scheduledDate && openApprovalWithDrawModal(transaction?.id, transaction?.createDate, transaction?.value)} >{translate('table.buttonApprove')}</button>}</td>
              </tr>
            );

          })}
        </tbody>
      </Table>

      {/* APPROVE MODAL */}
      <Modal isOpen={isApproveModalOpen} toggle={toggleApprovalModal}>
        <ModalHeader toggle={toggleApprovalModal}>{translate('table.approveModal.title')}</ModalHeader>
        <ModalBody>
          <p><strong>{translate('table.approveModal.partnerName')}</strong> {transationPartnerName}</p>
          <p><strong>{translate('table.approveModal.value')}</strong> {StringUtils.currencyPtBr(transactionValue)}</p>
          <p><strong>{translate('table.approveModal.requestDate')}</strong> {transactionRequestDate}</p>
        </ModalBody>
        <ModalFooter>
          <button className={'approveButton'} onClick={() => aproveRequest(transactionId)}>{translate('table.approveModal.buttonApprove')}</button>
          <Button className={'denyButton'} onClick={toggleApprovalModal}>{translate('table.approveModal.buttonCancel')}</Button>
        </ModalFooter>
      </Modal>

      {/* SCHEDULE MODAL */}
      <Modal isOpen={isRegisterPartnerWithDrawByAdminModalOpen} toggle={toggleRegisterPartnerWithDrawByAdminModal}>
        <ModalHeader toggle={toggleRegisterPartnerWithDrawByAdminModal}>{translate('table.scheduleModal.title')}</ModalHeader>
        <ModalBody>
          <p style={{ margin: 0 }}>{translate('table.scheduleModal.maxValueToWithDraw')}<strong>{StringUtils.currencyPtBr(maxValuePartnerCanWithDraw)}</strong> </p>
          <InputField onChange={(e, formatted, value) => setWithDrawValue(value!)} inputClassName="modalInput" isCurrency value={withDrawValue} />
        </ModalBody>
        <ModalFooter>
          <button className={'approveButton'} onClick={() => scheduleRequest()}>{translate('table.scheduleModal.makeWithDraw')}</button>
          <Button className={'denyButton'} onClick={toggleRegisterPartnerWithDrawByAdminModal}>{translate('table.scheduleModal.buttonCancel')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  billingsWithDraws: storeState.billing.billingsWithDraws,
  approvePartnerWithdrawStatus: storeState.billing.approvePartnerWithdrawStatus,
  requestPartnerWithDrawByAdminStatus: storeState.billing.requestPartnerWithDrawByAdminStatus
});

const mapDispatchToProps = {
  fetchBillingWithdraws: getPartnerBillingsWithDraws,
  approvePartnerWithDraw: patchApprovePartnerWithDraw,
  requestWithDrawByAdmin: postRequestWithDrawByAdmin,
  fetchPartnerPlace: getPartnerPlaceById
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingsTable);
