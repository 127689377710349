import React from 'react';
import './simple-table-list.scss';
import { Table, Row } from 'reactstrap';

export interface ISimpleTableListProps {
  emptyTableMessage?: string;
  columNameKeys: string[];
  rows?: Array<Array<string | JSX.Element>>;
  textOverflow?: boolean;
  indexSelected?: number;
}

export class SimpleTableList extends React.Component<ISimpleTableListProps, {}> {
  constructor(props) {
    super(props);
  }

  private renderCells = (row: Array<string | JSX.Element>, rowKey: string): JSX.Element[] => {
    const result: JSX.Element[] = [];
    row.map((item: string | JSX.Element, index: number) => {
      result.push(
        <td
          key={`${rowKey}[cell-${index}]`}
          className={`cell-text item-max-width ${this.props.textOverflow && index === 2 ? 'text-overflow' : ''}`}
          style={{padding: '20px 5px 20px 0'}}>
          {
            item === 'Em espera' || item === 'em análise' ? (
              <div style={{minWidth: 100, height: 30, marginRight: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px', backgroundColor: '#F6B330'}}>
                <span style={{color: '#fff'}}>{item}</span>
              </div>
            )
            :
            item === 'Aprovado' || item === 'Pago' ? (
              <div style={{minWidth: 100, height: 30, marginRight: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px', backgroundColor: item === 'Aprovado' ? '#02bd15' : '#005a0a'}}>
                <span style={{color: '#fff'}}>{item}</span>
              </div>
            )
            :
            item === 'Não pago' || item === 'Recusado' ? (
              <div style={{minWidth: 100, height: 30, marginRight: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px', backgroundColor: '#ff0015'}}>
                <span style={{color: '#fff'}}>{item}</span>
              </div>
            )
            :
            <span>{item}</span>
          }
        </td>
      );
    });

    return result;
  };

  private renderEmptyContentMessage = () => {
    return (
      <tr className={'row-container'}>
        <td colSpan={this.props.columNameKeys.length} className={'cell-text item-max-width empty-list-container'}>
          <span>{this.props.emptyTableMessage != null ? this.props.emptyTableMessage : ''}</span>
        </td>
      </tr>
    );
  };

  private rowClassname = (index: number) => {
    if (this.props.indexSelected != null && index == this.props.indexSelected) {
      return 'row-container row-selected-warning';
    }
    return 'row-container';
  };

  render() {
    return (
          <Table className={'table-container'}>
            <thead>
              <tr className={'thead-row-container'}>
                {this.props.columNameKeys != null &&
                  this.props.columNameKeys.map((name: string, index: number) => (
                    <th className={'item-max-width'} key={`column-header-${index}`} style={{color: '#B5B7C0', fontSize: 16, fontWeight: 400, borderTop: 'none'}}>
                      {name}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {this.props.rows != null && this.props.rows.length !== 0
                ? this.props.rows.map((row: Array<string | JSX.Element>, index: number) => (
                    <tr >
                      {this.renderCells(row, `row-${index}`)}
                    </tr>
                  ))
                : this.renderEmptyContentMessage()}
            </tbody>
          </Table>
    );
  }
}

export default SimpleTableList;
