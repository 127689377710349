import moment from 'moment';
import { ConferenceVM } from '../../modules/application-admin/conferences';
import { HttpRequestStatus } from '../../shared/model/enum/HttpRequestStatus';
import { IPage } from '../../shared/model/page.model';
import { FAILURE, REQUEST, SUCCESS } from '../../shared/reducers/action-type.util';
import axios from 'axios';

export const ACTION_TYPES = {
  GET_CONFERENCES: 'conferences/GET_CONFERENCES',
  UPDATE_CONFERENCE_END_DATE: 'conferences/UPDATE_CONFERENCE_END_DATE',
  RESET: 'conferences/RESET'
};

export const initialState = {
  conferences: ([] as unknown) as IPage<ConferenceVM>,
  updateStatus: HttpRequestStatus.NOOP,
  getConferencesStatus: HttpRequestStatus.NOOP
};

export type IConferenceState = Readonly<typeof initialState>;

export default (state: IConferenceState = initialState, action): IConferenceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPDATE_CONFERENCE_END_DATE):
      return {
        ...state,
        updateStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_CONFERENCE_END_DATE):
      return {
        ...state,
        updateStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_CONFERENCE_END_DATE):
      return {
        ...state,
        updateStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.GET_CONFERENCES):
      return {
        ...state,
        getConferencesStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_CONFERENCES):
      return {
        ...state,
        getConferencesStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_CONFERENCES):
      return {
        ...state,
        getConferencesStatus: HttpRequestStatus.SUCCESS,
        conferences: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/conference';

export const updateConferenceEndDate = (activityScheduleId: number, endDate: string) => {
  const formattedDate = moment(endDate).format();

  return {
    type: ACTION_TYPES.UPDATE_CONFERENCE_END_DATE,
    payload: axios.patch(`${apiUrl}/activity-schedule/${activityScheduleId}`, { endDate: formattedDate })
  };
};

export const getConferences = ({ page }) => ({
  type: ACTION_TYPES.GET_CONFERENCES,
  payload: axios.get(`${apiUrl}/all?size=20&page=${page}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
