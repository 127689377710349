import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IPartnerPlaceBillingAccountBalance } from 'app/shared/model/partner-place-billing-account-balance';

export const ACTION_TYPES = {
  GET_CURRENT_BALANCE: 'financial/GET_CURRENT_BALANCE',
  REQUEST_REDEMPTION: 'financial/REQUEST_REDEMPTION',
  RESET: 'financial/RESET'
};

const initialState = {
  getCurrentBalanceStatus: HttpRequestStatus.NOOP,
  requestRedemptionStatus: HttpRequestStatus.NOOP,
  currentBalance: null
};

export type IFinancialState = Readonly<typeof initialState>;

// Reducer
export default (
  state: IFinancialState = initialState,
  action: IActionForResponseEntity<IPartnerPlaceBillingAccountBalance>
): IFinancialState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_CURRENT_BALANCE):
      return {
        ...initialState,
        getCurrentBalanceStatus: HttpRequestStatus.ONGOING,
        currentBalance: null
      };
    case FAILURE(ACTION_TYPES.GET_CURRENT_BALANCE):
      return {
        ...initialState,
        getCurrentBalanceStatus: HttpRequestStatus.ERROR,
        currentBalance: null
      };
    case SUCCESS(ACTION_TYPES.GET_CURRENT_BALANCE):
      return {
        ...initialState,
        getCurrentBalanceStatus: HttpRequestStatus.SUCCESS,
        currentBalance: action.payload.data
      };
    case REQUEST(ACTION_TYPES.REQUEST_REDEMPTION):
      return {
        ...initialState,
        requestRedemptionStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.REQUEST_REDEMPTION):
      return {
        ...initialState,
        requestRedemptionStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.REQUEST_REDEMPTION):
      return {
        ...initialState,
        requestRedemptionStatus: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/partner-place-financial-areas';

export const getCurrentBalance = () => ({
  type: ACTION_TYPES.GET_CURRENT_BALANCE,
  payload: axios.get(`${apiUrl}/current-balance`)
});

export const requestRedemption = () => ({
  type: ACTION_TYPES.REQUEST_REDEMPTION,
  payload: axios.post(`${apiUrl}/request-redemption`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
