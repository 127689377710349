import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import SelectOptionModal from './selectOption/SelectOptionModal';
import SelectOptionMultipleModal from './selectOptionMultiple/SelectOptionMultipleModal';
import {
  MODAL_TYPE_SELECT_OPTION,
  MODAL_TYPE_SELECT_OPTION_MULTIPLE,
  MODAL_TYPE_WARNING,
  MODAL_TYPE_CONFIRM_TOKEN,
  MODAL_TYPE_DETAILS_ACTIVITY,
  MODAL_CHANGE_SCHEDULE,
  MODAL_DENY_KEIKEN_PAY,
  MODAL_APPROVE_KEIKEN_PAY,
  MODAL_TYPE_CREATE_BILLING_TRANSACTIONS
} from './modal-types';
import WarningModal from './warningModal/WarningModal';
import ActivityModal from '../activityModal/ActivityModal';
import ActivityDetailsModal from '../activityDetailsModal/activityDetailsModal';
import ChangeActivitySchedule from '../changeActivitySchedule/changeActivitySchedule';
import DenyKeikenPayModal from '../../modules/application-admin/keiken-pay/denyKeikenPayModal';
import ApproveKeikenPayModal from '../../modules/application-admin/keiken-pay/approveKeikenPayModal';
import CreateBillingTransactionsModal from '../../modules/application-admin/billings-manage/CreateBillingTransactionsModal';

const MODAL_COMPONENTS = {
  [MODAL_TYPE_SELECT_OPTION]: SelectOptionModal,
  [MODAL_TYPE_SELECT_OPTION_MULTIPLE]: SelectOptionMultipleModal,
  [MODAL_TYPE_WARNING]: WarningModal,
  [MODAL_TYPE_CONFIRM_TOKEN]: ActivityModal,
  [MODAL_TYPE_DETAILS_ACTIVITY]: ActivityDetailsModal,
  [MODAL_CHANGE_SCHEDULE]: ChangeActivitySchedule,
  [MODAL_DENY_KEIKEN_PAY]: DenyKeikenPayModal,
  [MODAL_APPROVE_KEIKEN_PAY]: ApproveKeikenPayModal,
  [MODAL_TYPE_CREATE_BILLING_TRANSACTIONS]: CreateBillingTransactionsModal
};

export interface IModalRootProps extends StateProps, DispatchProps {
  type: string;
  payload: {};
}

export interface IModalRootState {
  type?: string;
  payload: {};
}

export class ModalRoot extends React.Component<IModalRootProps, IModalRootState> {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      payload: null
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ type: newProps.type, payload: newProps.payload });
  }

  render() {
    const ModalComponent = MODAL_COMPONENTS?.[this.state.type] ?? null;
    return ModalComponent ? <ModalComponent config={this.state.payload} /> : <div />;
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  type: storeState.modal.type,
  payload: storeState.modal.props
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalRoot);
