export interface IUser {
  id?: any;
  login?: string;
  email?: string;
  uuid?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: any[];
  zoneId?: string;
  resetKey?: string;
  resetDate?: Date;
}
export interface IUserBasicInfo {
  id?: number;
  email?: string;
  resetKey?: string;
  resetDate?: Date;
}

export interface IUserPartnerCreate {
  email: string;
  password: string;
  resetKey?: string;
  resetDate?: Date;
}

export const defaultValue: Readonly<IUser> = {
  id: null,
  login: null,
  email: null,
  activated: false,
  langKey: null,
  authorities: null,
  zoneId: null
};
