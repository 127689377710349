import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Container, ModalFooter, Button } from 'reactstrap';
import { ICustomerActivity } from '../../../../shared/model/customer-activity.model';
import moment from 'moment';
import { IActivitySchedule } from '../../../../shared/model/activity-schedule/activity-schedule';
import { SelectOptionsDropdown } from '../activity-schedule-confirm-modal/activity-schedule-confirm-modal';
import { IActivityCategory } from '../../../../shared/model/activity-category';
import { IActivitySubCategory } from '../../../../shared/model/activity-sub-category';
import { translate } from 'react-jhipster';
import StringUtils from '../../../../util/StringUtils';
import { connect } from 'react-redux';
import { getActivityPlanPriceComposite, getActivitySchedulePriceComposite } from '../../../../../app/entities/billing/billing.reducer';
import { IRootState } from '../../../../shared/reducers';
import { IPriceComposite } from '../../../../shared/model/billing/price-composite';
import { PresetPaymentMethodsToCharge } from '../../../../shared/model/preset-payment-methods-to-charge';
import PaymentMethodCardItems from '../../customers-manage/components/payment-methods-card-itens';
import { IPaymentMethodCardItem } from '../../../../entities/customer/customer-redux';
import { getCustomerPaymentMethods } from '../../../../../app/entities/customer/customer-redux';
import { getNotifications, getNotificationByCustomerActivityId, reset } from '../../../../entities/notifications/notifications.reducer';
import { NotificationType } from '../../../../shared/model/enum/NotificationType';
import { INotification } from '../../../../shared/model/notification';
import './index.scss';
import _ from 'lodash';
import { TailSpin } from 'react-loader-spinner';
import { ICustomerActivityPlan } from '../../../../shared/model/customer-activity-plan';
import { approveCustomerActivitySuggestedByPartner } from '../../../../../app/entities/notifications/notifications.reducer';
import { toast } from 'react-toastify';
import { ICustomer } from '../../../../shared/model/customer/customer';
import SwitchButton from '../../../../components/SwitchButton';
import { HttpRequestStatus } from '../../../../shared/model/enum/HttpRequestStatus';
import { getAcitivtySchedulesWithRenewalPriceComposite } from '../../../../../app/entities/billing/billing.reducer';

interface IPartnerSuggestionConfirmModalProps extends StateProps, DispatchProps {
  isOpen: boolean;
  onClose: () => void;
  item: ICustomerActivity | ICustomerActivityPlan;
  customer: ICustomer;
}

const PartnerSuggestionConfirmModal = ({
  isOpen,
  onClose,
  item,
  customer,
  approveStatus,
  notificationByCustomerActivityId,
  notificationByCustomerActivityIdStatus,
  priceComposite: propsPriceComposite,
  paymentMethods: propsPaymentMethods,
  getNotifications: propsGetAllNotifications,
  getActivitySchedulePriceComposite: propsGetActivitySchedulePriceComposite,
  getCustomerPaymentMethods: propsGetCustomerPaymentMethods,
  getActivityPlanPriceComposite: propsGetActivityPlanPriceComposite,
  approveCustomerActivitySuggestedByPartner: propsApproveCustomerActivitySuggestedByPartner,
  getNotificationByCustomerActivityId: propsGetNotificationByCustomerActivityId,
  getAcitivtySchedulesWithRenewalPriceComposite: propsGetAcitivtySchedulesWithRenewalPriceComposite,
  reset: propsNotificationsReset
}: IPartnerSuggestionConfirmModalProps) => {
  const [category, setCategory] = useState(item?.activityCategory ?? null);
  const [subCategory, setSubCategory] = useState(item?.activityCategory?.activitySubCategories?.[0] ?? null);
  const [withoutPayment, setWithoutPayment] = useState<boolean>(false);
  const [isPayrollDiscount, setIsPayrollDiscount] = useState<boolean>(false);
  const [priceComposite, setPriceComposite] = useState<IPriceComposite>();
  const [presetPaymentMethodsToCharge, setPresetPaymentMethodsToCharge] = useState<PresetPaymentMethodsToCharge>();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethodCardItem[]>();
  const [notification, setNotification] = useState<INotification>();
  const [isItActivity, setIsItActivity] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [acceptDate, setAcceptDate] = useState<boolean | undefined>(undefined);
  const [acceptRenewal, setAcceptRenewal] = useState<boolean>(false);

  const scheduleDate = getDateFromActivitySchedule(notification?.customerActivity?.activitySchedule!);
  const itIsPartnerNewscheduleSuggestion = notification?.notificationType === NotificationType.SCHEDULE_CREATED;
  const itIsPartnerRescheduleSuggestion = notification?.notificationType === NotificationType.SCHEDULE_EDITED;
  const isPartnerNewScheduleExpired = notification?.notificationType === NotificationType.SCHEDULE_CREATED_EXPIRED;
  const isPartnerRescheduleExpired = notification?.notificationType === NotificationType.SCHEDULE_EDITED_EXPIRED;
  const activitySchedule = notification?.customerActivity?.activitySchedule;
  const needCustomerPaymentMethod = priceComposite?.priceWithDiscountAndSubsidy! >= 0 && !presetPaymentMethodsToCharge?.customerPaymentMethod;
  const billingStatus = isItActivity ? notification?.customerActivity?.billing?.status : notification?.customerActivityPlan?.billing?.status;
  const partner = isItActivity ? activitySchedule?.activity?.partnerPlace?.fantasyName : notification?.customerActivityPlan?.activityPlan?.partnerPlace?.fantasyName;
  const serviceName = isItActivity ? activitySchedule?.activity?.name : notification?.customerActivityPlan?.activityPlan?.name;
  const customerName = notification?.customer?.name;
  const period = !isItActivity ? moment(notification?.customerActivityPlan?.startPeriodDate).format('DD/MM/YYYY') + ' - ' + moment(notification?.customerActivityPlan?.endPeriodDate).format('DD/MM/YYYY') : null;
  const isAnActivityWithRenewal = Boolean(notification?.presetRenewalActivitySchedule);
  const intervalWeek = isAnActivityWithRenewal ? notification?.presetRenewalActivitySchedule?.intervalWeek === 1 ? 'semanal' : 'quinzenal' : null;

  useEffect(() => {
    setLoading(true);
    if (item?.id) {
      propsGetNotificationByCustomerActivityId(item.id!);
    }
  }, [item]);

  useEffect(() => {
    if (notificationByCustomerActivityId?.content && notificationByCustomerActivityId.content.length > 0) {
      const latestNotification = _.minBy(notificationByCustomerActivityId.content, 'id');
      setNotification(latestNotification);
    }
  }, [item, notificationByCustomerActivityId]);

  useEffect(() => {
    if (isAnActivityWithRenewal && notification?.renewalCustomerActivity?.length! > 0) {
      const activityschedulesId = notification?.renewalCustomerActivity
        ?.map((customerActivity: ICustomerActivity) => customerActivity?.activitySchedule?.id) as number[];

      const activityId = notification?.customerActivity?.activitySchedule?.activity?.id;
      propsGetAcitivtySchedulesWithRenewalPriceComposite(activityschedulesId, activityId);
      setIsItActivity(true);
      return;
    }

    if (item && 'activitySchedule' in item && notificationByCustomerActivityId?.content?.length! > 0) {
      propsGetActivitySchedulePriceComposite(
        notification?.customerActivity?.activitySchedule?.id!,
        notification?.customerActivity?.activitySchedule?.activity?.id,
        notification?.customer?.id
        );

        setIsItActivity(true);
        return;
    } else if (notificationByCustomerActivityIdStatus === HttpRequestStatus.SUCCESS && notificationByCustomerActivityId?.content?.length! > 0) {
      const uauDate = new Date(notification?.customerActivityPlan?.startPeriodDate!);
      const localDate = moment(uauDate).format('YYYY-MM-DD');
      propsGetActivityPlanPriceComposite(notification?.customerActivityPlan?.activityPlan?.id!, localDate, item?.customer?.id);
      setIsItActivity(false);
  }
  }, [notification]);

  useEffect(() => {
    if (propsPriceComposite !== null) {
      setPriceComposite(propsPriceComposite);
      setCategory(item?.activityCategory!);
    }
  }, [propsPriceComposite]);

  useEffect(() => {
    if (item?.customer?.id && item?.activityCategory?.id) {
      propsGetCustomerPaymentMethods(item?.customer.id, item?.activityCategory?.id);
    }
  }, [item]);

  useEffect(() => {
    if (propsPaymentMethods?.length > 0) {
      setPaymentMethods(propsPaymentMethods);
      setLoading(false);
    }
  }, [propsPaymentMethods]);

  useEffect(() => {
    if (approveStatus === HttpRequestStatus.SUCCESS) {
      toast.success(`Sugestão ${acceptDate ? 'aprovada' : 'recusada'} com sucesso!`);
      propsNotificationsReset();
    } else if (approveStatus === HttpRequestStatus.ERROR) {
      toast.error(`Erro ao ${acceptDate ? 'aprovar' : 'recusar'} sugestão!`);
      propsNotificationsReset();
    }
  }, [approveStatus]);

  function getDateFromActivitySchedule(schedule: IActivitySchedule) {
    if (schedule?.date == null) {
      return '';
    }
    const date = moment(schedule.date).format('dddd - DD/MM/yyyy');
    const time = moment(schedule.date).format('HH:mm');
    return `${date} - ${time}`;
  }

  function handleSelectSubCategory(subCategorySelected: IActivitySubCategory) {
    setSubCategory(subCategorySelected);
  }

  function handleSelectCategory(categorySelected: IActivityCategory) {
    setCategory(categorySelected);
  }

  function handleOnClickPayrollDiscount() {
    setIsPayrollDiscount(prevState => !prevState);
  }

  function handleOnSelectedPaymentMethod(paymentMethodsToCharge: PresetPaymentMethodsToCharge) {
    setPresetPaymentMethodsToCharge(paymentMethodsToCharge);
  }

  function handleApprovePartnerSuggestions() {

    if (acceptDate === undefined) {
      return toast.error('Escolha uma opção entre aceitar ou recusar.');
    }

    if (!presetPaymentMethodsToCharge && !isPayrollDiscount && !withoutPayment && billingStatus != 'PAID' && (acceptDate || acceptRenewal)) {
      return toast.error('Escolha um método de pagamento.');
    }

    const objToSend = {
      notificationId: notification?.id!,
      presetPaymentMethodsToCharge: presetPaymentMethodsToCharge!,
      withoutPayment,
      isPayrollDiscount,
      acceptDate,
      acceptRenewal
    };

    propsApproveCustomerActivitySuggestedByPartner(objToSend);
    onClose();
  }

  return (
    <>
     <Modal toggle={onClose} backdrop className="modal-lg" isOpen={isOpen}>
      <ModalHeader toggle={onClose}>
        {loading ? (
          <></>
        ) : (
          isItActivity && itIsPartnerNewscheduleSuggestion
            ? 'Aceite de Atividade'
            : isItActivity && itIsPartnerRescheduleSuggestion
              ? 'Aceite de edição de horário'
              : isPartnerNewScheduleExpired
                ? 'Aceite de Atividade Expirada'
                : isPartnerRescheduleExpired
                  ? 'Aceite de edição de horário Expirada'
              : 'Aceite de Plano'
        )}
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <div style={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <TailSpin color="green" />
          </div>
        ) : (
        <Container fluid>
          <Row className={'mb-3'}>
            <Col md="6">
              <Row>
                {'Parceiro:  ' + partner}
              </Row>
              <Row>
                {'Atividade:  ' + serviceName }
              </Row>
              <Row>
                {'Cliente:  ' + customerName}
              </Row>
                {isItActivity && itIsPartnerRescheduleSuggestion ? (
                  <>
                    <Row>
                      {'De: '}
                    </Row>
                    <Row>
                      {scheduleDate}
                    </Row>
                    <Row>
                      {'Para: '}
                    </Row>
                    <Row className="mb-4">
                      {getDateFromActivitySchedule(notification?.customerActivityRescheduled?.activitySchedule!)}
                    </Row>
                  </>
                ) : (
                  isItActivity && itIsPartnerNewscheduleSuggestion ? (
                    <Row className="mb-3">
                      {'Data:  ' + scheduleDate}
                    </Row>
                  ) : (
                    <Row className="mb-3">
                    {'Vigência:  ' + period}
                  </Row>
                  ))}
                <Row className="row-categories mb-4">
                    <SelectOptionsDropdown<IActivityCategory>
                      title="Categoria"
                      nameKey="name"
                      value={category!}
                      options={isItActivity ? activitySchedule?.activity?.activityCategories! : notification?.customerActivityPlan?.activityPlan?.activityCategories!}
                      onSelect={handleSelectCategory}
                  />
                </Row>
                <Row className="mb-2">
                  <SelectOptionsDropdown<IActivitySubCategory>
                    title="Sub-Categoria"
                    nameKey="name"
                    value={subCategory!}
                    options={activitySchedule?.activity?.activitySubCategories!}
                    onSelect={handleSelectSubCategory}
                  />
                </Row>

                <Row>
                  <div className={'radio'}>
                    <input checked={isPayrollDiscount} onClick={handleOnClickPayrollDiscount} type={'radio'} />
                    <label className={'company-manage-checkbox-label'}>{translate('scheduleManage.modal-confirm.payrollDiscount')}</label>
                  </div>
                </Row>
                <Row className={'mb-3'}>
                  <div className={'radio'}>
                    <input checked={withoutPayment} onClick={() => setWithoutPayment(prev => !prev)} type={'radio'} />
                    <label className={'company-manage-checkbox-label'}>{translate('scheduleManage.modal-confirm.free')}</label>
                  </div>
                </Row>
              <Row>
                <Col className={'p-0'}>{'preço:'}</Col>
                <Col className={'p-0'}>{StringUtils.currencyPtBr(priceComposite?.price)}</Col>
              </Row>
              <Row>
                <Col className={'p-0'}>{'preço com desconto:'}</Col>
                <Col className={'p-0'}>{StringUtils.currencyPtBr(_.subtract(priceComposite?.price!, priceComposite?.discountValue!))}</Col>
              </Row>
              <Row>
                <Col className={'p-0'}>{'benefício:'}</Col>
                <Col className={'p-0'}>{StringUtils.currencyPtBr(priceComposite?.subsidyValue)}</Col>
              </Row>
              <Row>
                <Col className={'p-0'}>{'preço final:'}</Col>
                <Col className={'p-0'} style={{ color: needCustomerPaymentMethod ? 'red' : 'black' }}>{StringUtils.currencyPtBr(priceComposite?.priceWithDiscountAndSubsidy)}</Col>
              </Row>
              <Row className={'mb-3'}>
                <Col className={'p-0'}>{'benefício categoria:'}</Col>
                <Col className={'p-0'}>{priceComposite?.coParticipationGroupActivityCategory?.activityCategory?.name}</Col>
              </Row>
            </Col>
            {itIsPartnerRescheduleSuggestion == false &&
                  <Col md="6">
                    {!loading && paymentMethods?.length === 0 || !paymentMethods ? (
                      <p style={{ color: 'red' }}>Esse usuário não possui nenhum método de pagamento cadastrado.</p>
                    )
                      : (
                        <PaymentMethodCardItems
                          items={paymentMethods}
                          selected={presetPaymentMethodsToCharge}
                          onSelected={handleOnSelectedPaymentMethod}
                        />
                      )}
                  </Col>
                }
          </Row>
          {isAnActivityWithRenewal && (
            <>
              <Row className={'mb-2'}>
              Aceitar renovação: <strong>&nbsp;{intervalWeek}</strong>
            </Row>
            <Row className={'mb-4'}>
              <SwitchButton
                leftLabel="Recusar"
                rightLabel="Aceitar"
                state={acceptRenewal!}
                setState={setAcceptRenewal}
              />
            </Row>
            </>
          )}
          <Row className={'mb-2'}>
            Aceitar agendamento
          </Row>
          <Row >
            <SwitchButton
              leftLabel="Recusar"
              rightLabel="Aceitar"
              state={acceptDate!}
              setState={setAcceptDate}
            />
          </Row>
        </Container>
        )}

      </ModalBody>
      <ModalFooter>
        {loading ? (
          <></>
        ) : (
          <>
            <Button color="success" onClick={handleApprovePartnerSuggestions}>
              {translate('scheduleManage.modal-confirm.confirm')}
            </Button>{' '}
            <Button color="danger" onClick={onClose}>
              {translate('scheduleManage.modal-confirm.cancel')}
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
    </>

  );
};

const mapDispatchToProps = {
  getActivitySchedulePriceComposite,
  getCustomerPaymentMethods,
  getNotifications,
  getActivityPlanPriceComposite,
  approveCustomerActivitySuggestedByPartner,
  getNotificationByCustomerActivityId,
  getAcitivtySchedulesWithRenewalPriceComposite,
  reset
};

const mapStateToProps = (storeState: IRootState) => ({
  priceCompositeStatus: storeState.billing.statusActivitySchedulePriceComposite,
  priceComposite: storeState.billing.priceComposite,
  paymentMethods: storeState.customer.paymentMethods,
  notifications: storeState.notifications.notifications,
  notificationsStatus: storeState.notifications.notificationsStatus,
  notificationByCustomerActivityId: storeState.notifications.notificationByCustomerActivityId,
  approveStatus: storeState.notifications.approveStatus,
  notificationByCustomerActivityIdStatus: storeState.notifications.notificationByCustomerActivityIdStatus
});

type DispatchProps = typeof mapDispatchToProps;

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerSuggestionConfirmModal);
