import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import './login.css';
import { IRootState } from 'app/shared/reducers';
import { clearAuthentication, login } from 'app/shared/reducers/authentication';
import InputField from 'app/components/input-field/InputField';
import { translate } from 'react-jhipster';
import Button from 'app/components/button/Button';
import StringUtils from 'app/util/StringUtils';
import { toast } from 'react-toastify';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}, any, { from: any } | any> { }

export interface ILoginState {
  email?: string;
  password?: string;
  errors?: ILoginError;
}

export interface ILoginError {
  emailError?: boolean;
  passwordError?: boolean;
}

export class Login extends React.Component<ILoginProps, ILoginState> {
  state: ILoginState = {
    email: '',
    password: '',
    errors: {
      emailError: false,
      passwordError: false
    }
  };

  constructor(props) {
    super(props);
    this.handleTextChanged = this.handleTextChanged.bind(this);
  }

  handleOnForgotPasswordPressed = () => {
    this.props.history.push('forgot-password/init');
  };

  handleLoginPressed = () => {
    if (!this.validateFields()) {
      return;
    }

    this.props.login(this.state.email, this.state.password);
  };

  validateFields = () => {
    let allFieldsIsValid = true;

    if (!StringUtils.isEmailValid(this.state.email)) {
      allFieldsIsValid = false;
      this.setState(prevState => ({ ...prevState, errors: { ...prevState.errors, emailError: true } }));
    }

    if (StringUtils.isStringInvalid(this.state.password) || this.state.password.length < 4) {
      allFieldsIsValid = false;
      this.setState(prevState => ({ ...prevState, errors: { ...prevState.errors, passwordError: true } }));
    }

    return allFieldsIsValid;
  };

  handleTextChanged = (text: string, propertyToChange: string, errorToChange?: string) => {
    if (!!errorToChange) {
      this.setState(prevState => ({
        ...prevState,
        [propertyToChange]: text,
        errors: { ...prevState.errors, [errorToChange]: false }
      }));
      return;
    }
    this.setState(prevState => ({ ...prevState, [propertyToChange]: text }));
  };

  componentWillReceiveProps(newProps) {
    if (newProps.redirectUrl) {
      toast.success(translate('login.messages.redirect'), {
        autoClose: 3000,
        onClose: () => {
          this.props.clearAuthentication(null);
          window.location.href = newProps.redirectUrl;
        }
      });
    } else if (newProps.loginError) {
      toast.error(translate('login.messages.error.authentication'), {
        autoClose: 3000
      });
    }
  }
  render() {
    const { location, isAuthenticated } = this.props;
    const { from } = location.state || { from: { pathname: '/', search: location.search } };
    if (isAuthenticated) {
      return <Redirect to={from} />;
    }
    return (
      <div className={'login-container'}>
        <img className={'login-banner-img'} src="content/images/bg-login.png" alt="banner" />

        <div className={'login-center-modal'}>
          <img className={'login-logo'} src="content/images/logo-keiken-login.png" />

          <div className={'login-input-field-container'}>
            <img className={'login-input-field-img'} src={'content/images/ic-email-grey.png'} />

            <form>
              <InputField
                placeholder={'login.labels.email'}
                onChange={event => {
                  this.handleTextChanged(event.target.value, 'email', 'emailError');
                }}
                error={this.state.errors.emailError}
                value={!!this.state.email ? this.state.email : null}
                inputClassName={'input'}
              />
            </form>
          </div>

          <div className={'login-input-field-container mb-5'}>
            <img className={'login-input-field-img'} src={'content/images/ic-lock-grey.png'} />

            <InputField
              placeholder={'login.labels.password'}
              onChange={event => {
                this.handleTextChanged(event.target.value, 'password', 'passwordError');
              }}
              error={this.state.errors.passwordError}
              value={!!this.state.password ? this.state.password : ''}
              inputClassName={'input'}
              type={'password'}
              onKeyPress={e => {
                if (e.key == 'Enter') {
                  this.handleLoginPressed();
                }
              }}
            />
          </div>

          <Button onClick={() => this.handleLoginPressed()} title={translate('login.buttons.singIn')} />

          <label onClick={() => this.handleOnForgotPasswordPressed()} className={'login-forgot-password'}>
            {translate('login.buttons.forgotPassword')}
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  redirectUrl: authentication.redirectUrl,
  showModal: authentication.showModalLogin
});

const mapDispatchToProps = { login, clearAuthentication };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
