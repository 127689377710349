import axios from 'axios';
import _ from 'lodash';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import StringUtils from 'app/util/StringUtils';

export const ACTION_TYPES = {
  FETCH_CITIES: 'city/FETCH_CITIES',
  RESET: 'city/RESET'
};

const initialState = {
  page: null
};

export type CityState = Readonly<typeof initialState>;

// Reducer
export default (state: CityState = initialState, action): CityState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CITIES):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.FETCH_CITIES):
      return {
        ...initialState
      };
    case SUCCESS(ACTION_TYPES.FETCH_CITIES):
      return {
        ...initialState,
        page: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export const fetchCities = (page?: any, name?: string) => {
  let params;

  if (!StringUtils.isStringInvalid(name)) {
    params = {
      name,
      size: page.size,
      page: page.page
    };
  }

  return {
    type: ACTION_TYPES.FETCH_CITIES,
    payload: !!params ? axios.get('api/cities', { params }) : axios.get('api/cities')
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
