import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import './company-manage.scss';
import { ICompany, ICompanyKeikenPayActivityCategory, ICompanyStatus } from 'app/shared/model/company';
import {
  createCompanyUser, getWallet, createCompanyWallet,
  increaseWallet, insertCompany, updateCompany, reset, fetchCompany,
  getCompanyById,
  saveCompanyKeikenPayFee
} from 'app/entities/company/company-redux';
import { fetchPriceTable } from 'app/entities/price-table/price-table-redux';
import InputField from 'app/components/input-field/InputField';
import { translate, Translate } from 'react-jhipster';
import NumberUtils from 'app/util/NumberUtils';
// tslint:disable-next-line:no-duplicate-imports
import { ChangeEvent } from 'react';
import Button from 'app/components/button/Button';
import { Row, Col, Button as RButton } from 'reactstrap';
import { toast } from 'react-toastify';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StringUtils from 'app/util/StringUtils';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import { compose } from 'redux';
import { isEmpty, sum } from 'lodash';
import { ICompanyWallet } from 'app/shared/model/company-wallet';
import CompanyDiscountFee from './company-discount-fee';
import CompanyBillingsTable from './companyBillings';
import { ISystemFee } from 'app/shared/model/system-fee.model';
import { IPriceTable } from 'app/shared/model/price-table';
import { getBillings } from 'app/entities/billing/billing.reducer';
import { IPage } from 'app/shared/model/page.model';
import { IBilling } from 'app/shared/model/billing/billing';
import { createCompanyReport, getCompanyReports } from 'app/entities/company-report/company-report-reducer';
import CompanyAddReport from './company-add-report';
import CompanyReportsTable from './company-reports-table';
import CompanyCreateBillingExternal from './company_add_external-billing';
import CompanyExternalBillingsTable from './external-billings-list';
import CompanyUsersTable from './company-users-table';
import { Label, Input } from 'reactstrap';

export interface ICompanyManageProps extends StateProps, DispatchProps, RouteComponentProps<{
  id?: string;
}, any, { company: any } | any> { }

export interface ICompanyManageState {
  company?: ICompany;
  addUser: boolean;
  addBalance: boolean;
  walletMissing: boolean;
  wallet?: ICompanyWallet;
  isErrors?: { [key: string]: boolean | undefined };
  isValids?: { [key: string]: boolean | undefined };
  isExternalBillingModalOpen?: boolean;
  isEditExternalBilling?: boolean;
  externalBillingToEdit?: IBilling;
}

export class CompanyManage extends React.Component<ICompanyManageProps, ICompanyManageState> {
  constructor(props) {
    super(props);
    this.state = {
      addUser: false,
      addBalance: false,
      walletMissing: false,
      isExternalBillingModalOpen: false,
      isEditExternalBilling: false,
      externalBillingToEdit: undefined,
      company: {
        name: undefined,
        discountValue: undefined,
        cnpj: undefined,
        companyDependent: undefined,
        promptAi: undefined
      },
      isErrors: {
        name: undefined,
        discountValue: undefined,
        cnpj: undefined,
        companyDependent: undefined
      },
      isValids: {
        name: undefined,
        discountValue: undefined,
        cnpj: undefined,
        companyDependent: undefined
      }
    };
  }

  componentDidMount() {
    if (this.props.location.state != null && this.props.location.state.company != null) {
      const company = this.props.location.state.company;
      this.setState({ company });
      this.props.getWallet(company.id);
      // @ts-ignore
    } else if (this.props.match.params?.id != null) {
      this.props.getCompanyById(this.props.match.params.id);
    }
  }

  componentWillReceiveProps(newProps: StateProps) {
    if (newProps.getCompany === HttpRequestStatus.SUCCESS) {
      this.setState({ company: newProps.company });
      this.props.getWallet(newProps.company.id);
    }

    if (newProps.saveCompany === HttpRequestStatus.SUCCESS) {
      toast.success(translate('companyManage.messages.success'), {
        onClose: () => this.props.history.replace('/application-admin/companies-list'),
        autoClose: 2000
      });
      this.props.reset();
    }

    if (newProps.saveCompanyUser === HttpRequestStatus.SUCCESS) {
      toast.success(translate('companyManage.messages.successAddUser'), {
        autoClose: 2000
      });
      this.props.reset();
    } else if (newProps.saveCompanyUser === HttpRequestStatus.ERROR) {
      toast.error(translate('companyManage.messages.errorAddUser'), {
        autoClose: 2000
      });
      this.props.reset();
    }

    if (newProps.walletStatus === HttpRequestStatus.SUCCESS) {
      this.setState({
        wallet: newProps.wallet
      });
      this.handleGetCompanyDeposits();
      this.props.reset();
    } else if (newProps.walletStatus === HttpRequestStatus.ERROR) {
      this.setState({ walletMissing: true });
      this.props.reset();
    }

    if (newProps.createWalletStatus === HttpRequestStatus.SUCCESS) {
      toast.success(translate('companyManage.messages.createWalletSuccess'), {
        autoClose: 2000
      });
      this.setState({
        wallet: newProps.wallet,
        walletMissing: false
      });
      this.props.reset();
    } else if (newProps.createWalletStatus === HttpRequestStatus.ERROR) {
      toast.error(translate('companyManage.messages.createWalletError'), {
        autoClose: 2000
      });
      this.props.reset();
    }

    if (newProps.increaseWalletStatus === HttpRequestStatus.SUCCESS) {
      toast.success(translate('companyManage.messages.increaseWalletSuccess'), {
        autoClose: 2000
      });
      this.setState({
        addBalance: false
      });
      this.props.reset();
    } else if (newProps.increaseWalletStatus === HttpRequestStatus.ERROR) {
      toast.error(translate('companyManage.messages.increaseWalletError'), {
        autoClose: 2000
      });
      this.props.reset();
    }
  }

  private validateForm = (): boolean => {
    const nameIsValid: boolean = !StringUtils.isStringInvalid(this.state.company.name);
    const discountValueIsValid: boolean = NumberUtils.isValidNumber(this.state.company.discountValue);
    const cnpjIsValid: boolean = !StringUtils.isStringInvalid(this.state.company.cnpj);

    this.setState({
      isErrors: {
        ...this.state.isErrors,
        ['discountValue']: !discountValueIsValid,
        ['name']: !nameIsValid,
        ['cnpj']: !cnpjIsValid
      },
      isValids: {
        ...this.state.isValids,
        ['discountValue']: discountValueIsValid,
        ['name']: nameIsValid,
        ['cnpj']: cnpjIsValid
      }
    });
    return nameIsValid && discountValueIsValid && cnpjIsValid;
  };

  private handleSaveCompany = () => {
    if (!this.validateForm()) {
      return;
    }
    return this.state.company.id == null ? this.props.insertCompany(this.state.company) : this.props.updateCompany(this.state.company);
  };

  private handleReturnValidStateValue = (company: ICompany, fieldKey: string) => {
    return company != null ? company[fieldKey] : '';
  };

  private handleChanged = (fieldKey: string, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      company: { ...this.state.company, [fieldKey]: value },
      isErrors: { ...this.state.isErrors, [fieldKey]: false },
      isValids: { ...this.state.isValids, [fieldKey]: false }
    });
  };

  private handleCompanyList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: translate('companyManage.labels.dependents.search'),
      action: this.props.fetchCompany,
      placeholderInput: translate('companyManage.labels.search'),
      stateAction: 'company',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleCompanySelected(item)
    });
  };
  private handlePriceTableList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: translate('companyManage.labels.addPriceTable'),
      action: this.props.fetchPriceTable,
      placeholderInput: translate('companyManage.labels.searchPriceTable'),
      stateAction: 'priceTable',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handlePriceTableSelected(item)
    });
  };
  private handleCompanySelected = (item: ICompany) => {
    this.setState(prevState => ({
      company: {
        ...prevState.company,
        companyDependent: item
      }
    }));
    this.props.hideModal();
  };
  private handlePriceTableSelected = (item: IPriceTable) => {
    this.setState(prevState => ({
      company: {
        ...prevState.company,
        priceTable: item
      }
    }));
    this.props.hideModal();
  };

  private deleteDependentCompany = () => {
    this.handleCompanySelected(undefined);
  }

  private handleAddCompanyUser = companyUser => {
    const company = this.state.company;
    this.props.createCompanyUser(company.id, companyUser);
  }

  private handleCreateWallet = () => {
    const company = this.state.company;
    this.props.createCompanyWallet(company.id);
  }

  private handleAddBalance = (amount: number, description: string, paymentProviderId: string, alreadyPaid: boolean) => {
    const company = this.state.company;
    const billing = {
      value: amount,
      description,
      paymentProviderId,
      alreadyPaid
    };
    this.props.increaseWallet(company.id, billing);
    setTimeout(() => this.props.getWallet(company.id), 500);
  }

  private handleEditExternalBilling = (billing: IBilling | undefined) => () => {
    this.setState({ externalBillingToEdit: billing, isExternalBillingModalOpen: true, isEditExternalBilling: true });
  }

  private handleGetCompanyDeposits = () => {
    const companyId = this.state.company?.id;
    if (!companyId) return;
    const pageRequest = {
      page: 0,
      size: 10,
      sort: ['createDate,desc']
    };

    const params = {
      paymentMethod: 'DEPOSIT_BY_COMPANY',
      companyId
    };

    this.props.getBillings(pageRequest, params);
  }

  render() {
    const imageUrl = this.state.company?.image?.imageUrl;
    const companyBillings = this.props.billings?.content;
    return (
      <div className={'form-container'}>
        <Row re>
          <Col md="6">
            <div className={'title-screen-container'}>
              <div onClick={() => this.props.history.replace('/application-admin/companies-list')}>
                <FontAwesomeIcon className={'back-arrow-icon'} icon={'arrow-left'} />
              </div>
              <label className={'system-fee-manage-title'}>
                <Translate contentKey={'companyManage.labels.company'} />
              </label>
            </div>
            <div className={'company-image-container'}>
              <img src={imageUrl} className={'company-image'} />
            </div>
            <div className={'input-container'}>
              <InputField
                title={translate('companyManage.placeholders.name')}
                error={this.state.isErrors['name']}
                valid={this.state.isValids['name']}
                onChange={this.handleChanged.bind(this, 'name')}
                inputClassName={''}
                maxLength={40}
                value={this.handleReturnValidStateValue(this.state.company, 'name')}
                type={'text'}
                placeholder={'companyManage.placeholders.name'}
              />
            </div>
            <div className={'input-container'}>
              <label className={'partner-register-input-label'}>
                <Translate contentKey={'companyManage.enum.label'} />
              </label>
              <select
                onChange={this.handleChanged.bind(this, 'status')}
                value={this.state.company?.status ?? 'invalid'}
                className={'partner-register-select'}
                style={this.state.company?.status == null ? { borderColor: 'red' } : {}}
              >
                <option value={'invalid'} disabled>
                  {translate(`companyManage.enum.invalid`)}
                </option>
                {Object.values(ICompanyStatus).map((item, index) => (
                  <option value={item} key={`company-status${item}-${index}`}>
                    {translate(`companyManage.enum.${item}`)}
                  </option>
                ))}
              </select>
            </div>
            <div className={'input-container'}>
              <InputField
                title={translate('companyManage.placeholders.cnpj')}
                error={this.state.isErrors['cnpj']}
                valid={this.state.isValids['cnpj']}
                onChange={this.handleChanged.bind(this, 'cnpj')}
                inputClassName={''}
                value={this.handleReturnValidStateValue(this.state.company, 'cnpj')}
                maskFormat={'99.999.999/9999-99'}
                placeholder={'companyManage.placeholders.cnpj'}
                isMaskRequired
              />
            </div>
            <div className={'input-container'}>
              <InputField
                title={translate('companyManage.placeholders.discountValue')}
                error={this.state.company.discountValue == 100 || this.state.isErrors['discountValue']}
                valid={this.state.isValids['discountValue']}
                onChange={this.handleChanged.bind(this, 'discountValue')}
                inputClassName={''}
                maxLength={4}
                value={this.handleReturnValidStateValue(this.state.company, 'discountValue')}
                type={'number'}
                placeholder={'companyManage.placeholders.discountValue'}
              />
            </div>
            <div className={'input-container'}>
              <div className={'category-manage-inner-addon'}>
                <InputField
                  title={translate('companyManage.labels.search')}
                  inputClassName={'category-manage-search-input'}
                  value={this.state.company?.companyDependent?.name || ''}
                  placeholder={'companyManage.labels.dependents.add'}
                  onClick={this.handleCompanyList}
                />

                <div className={'category-manage-inner-icon'} onClick={this.deleteDependentCompany}>
                  <FontAwesomeIcon className={'category-manage-inner-icon'} icon={'trash'} />
                </div>
              </div>
            </div>
            <div className={'input-container'}>
              <div className={'category-manage-inner-addon'}>
                <InputField
                  title={translate('companyManage.labels.searchPriceTable')}
                  inputClassName={'category-manage-search-input'}
                  value={this.state.company?.priceTable?.name ?? ''}
                  placeholder={'companyManage.labels.priceTable'}
                  onClick={this.handlePriceTableList}
                />
              </div>
            </div>
            <div>
              <label>Prompt para leitura de recibos pela IA</label>
              <div className={'containerTextArea'}>
                <textarea id="prompt" name="prompt" value={this.handleReturnValidStateValue(this.state.company, 'promptAi')} onChange={this.handleChanged.bind(this, 'promptAi')} />
              </div>
            </div>
            <div className={'marginT15'}>
              <Button onClick={this.handleSaveCompany} title={translate('partner.register.buttons.confirm')} />
            </div>
          </Col>
          <Col md="6">
            <div>
              <div style={{ marginTop: '36px' }} className={'title-screen-container'}>
                <label className={'system-fee-manage-title'}>
                  <a className={'wallet-ref'} href={`/#/application-admin/companies-manage/${this.state.company?.id}/wallet`}>
                    Carteira
                  </a>
                </label>
              </div>
              {this.state.walletMissing == true && (
                <>
                  <label style={{ fontSize: 24 }} className={'label-with-error'}>
                    {translate('companyManage.labels.walletMissing')}
                  </label>
                  <RButton className={'centered-content marginB2'} onClick={this.handleCreateWallet}>
                    <FontAwesomeIcon icon={'check'} className={'marginR1'} />
                    <Translate contentKey={'companyManage.buttons.createWallet'} />
                  </RButton>
                </>
              )}
              {`saldo: R$ ${this.state.wallet?.balance}`}<br />
              {`limite: R$ ${this.state.wallet?.creditLimit}`}
              <div style={{ marginTop: 20, marginBottom: 20 }} className={'input-container'}>
                <RButton className={'centered-content'} onClick={() => this.setState(prev => ({ addBalance: !prev.addBalance }))}>
                  <FontAwesomeIcon icon={this.state.addBalance == true ? 'times' : 'check'} className={'marginR1'} />{' '}
                  <Translate contentKey={'companyManage.buttons.increaseBalance'} />
                </RButton>
                {this.state.addBalance == true && (<CompanyAddBalance initial={this.state.wallet?.balance} onConfirm={this.handleAddBalance} />)}
              </div>
              <div style={{ marginBottom: 10 }} className={'input-container'}>
                <RButton className={'centered-content marginB2'} onClick={() => this.setState(prev => ({ addUser: !prev.addUser }))}>
                  <FontAwesomeIcon icon={this.state.addUser == true ? 'times' : 'check'} className={'marginR1'} />{' '}
                  <Translate contentKey={'companyManage.buttons.addUser'} />
                </RButton>
                {this.state.addUser == true && (<CompanyNewCompanyUser onConfirm={this.handleAddCompanyUser} />)}
              </div>
            </div>
            <CompanyDiscountFee company={this.state.company} />
            <div className={'input-container'}>
              <RButton className={'centered-content marginB2'} color="info" onClick={() =>
                this.props.history.replace(`/application-admin/companies-manage/${this.state.company.id}/keiken-pay-fee`)
              }>
                <FontAwesomeIcon icon={'eye'} className={'marginR1'} />{' '}
                <Translate contentKey={'companyManage.buttons.editKeikenPayFee'} />
              </RButton>
            </div>
            <CompanyAddReport companyId={this.state.company.id} />
            <CompanyCreateBillingExternal
              companyId={this.state.company?.id}
              isModalOpen={this.state.isExternalBillingModalOpen}
              setIsModalOpen={isExternalBillingModalOpen => this.setState({
                isExternalBillingModalOpen,
                isEditExternalBilling: false,
                externalBillingToEdit: undefined
              })}
              isEdit={this.state.isEditExternalBilling}
              billingToEdit={this.state.externalBillingToEdit}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col md="12">
            <CompanyUsersTable companyId={this.state.company?.id} />
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col md="12">
            <CompanyReportsTable companyId={this.state.company?.id} />
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col md="12">
            <CompanyBillingsTable billings={companyBillings} />
          </Col>
        </Row>

        <Row style={{ marginTop: '16px' }}>
          <Col md="12">
            <CompanyExternalBillingsTable companyId={this.props.company?.id} handleEdit={this.handleEditExternalBilling} />
          </Col>
        </Row>
      </div >
    );
  }
}

const CompanyAddBalance = ({ initial, onConfirm }) => {
  const [balance, setBalance] = React.useState<number>(0);
  const [description, setDescription] = React.useState<string>('');
  const [paymentProviderId, setPaymentProviderId] = React.useState<string>('');
  const [alreadyPaid, setAlreadyPaid] = React.useState<boolean>(false);

  const checkIfCanContinue = () => {
    if (!alreadyPaid && paymentProviderId == '') {
      return toast.error('Se o boleto ainda não foi pago, o id do pagamento é obrigatório');
    }
    onConfirm(balance, description, paymentProviderId, alreadyPaid);
  };

  return (
    <>
      <div className={'input-container'}>
        <InputField
          value={balance}
          onChange={event => setBalance(Number(event.target.value))}
          title={translate('companyManage.placeholders.sumBalance')}
          inputClassName={''}
          maxLength={40}
          type={'number'}
          placeholder={'companyManage.placeholders.sumBalance'}
        />
      </div>
      <div className={'input-container'}>
        <InputField
          value={description}
          onChange={event => setDescription(event.target.value)}
          title={'Descrição (opcional)'}
          inputClassName={''}
          maxLength={400}
          type={'string'}
          placeholder={'companyManage.placeholders.description'}
          isTextArea
        />
      </div>
      <div className={'input-container'}>
        <InputField
          value={paymentProviderId}
          onChange={event => setPaymentProviderId(event.target.value)}
          title={'Id do pagamento'}
          inputClassName={''}
          maxLength={400}
          type={'string'}
          placeholder={'companyManage.placeholders.paymentProviderId'}
        />
      </div>

      <div className="checkbox-container">
        <Label className="checkbox-label" check>
          <Input
            className="checkbox-input"
            type="checkbox"
            checked={alreadyPaid}
            onChange={() => setAlreadyPaid(!alreadyPaid)}
          />{' '}
          Já foi pago?
        </Label>
      </div>

      <div style={{ marginBottom: 20 }}>
        {`saldo após: ${sum([initial, balance])}`}
      </div>
      <div className={'marginT15'}>
        <Button onClick={() => checkIfCanContinue()} title={translate('companyManage.buttons.save')} />
      </div>
    </>
  );
};

const CompanyNewCompanyUser = ({ onConfirm }) => {

  const [name, setName] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();

  const [error, setError] = React.useState<boolean>(false);

  const handleSaveCompanyUser = () => {
    setError(false);

    if (isEmpty(name) || isEmpty(email) || isEmpty(password)) {
      setError(true);
      return;
    }

    onConfirm({
      name,
      user: {
        email,
        password
      }
    });
  };

  return (
    <>
      <div className={'input-container'}>
        <InputField
          value={name}
          onChange={event => setName(event.target.value)}
          error={error}
          title={translate('companyManage.placeholders.name')}
          inputClassName={''}
          maxLength={40}
          type={'text'}
          placeholder={'companyManage.placeholders.name'}
        />
      </div>
      <div className={'input-container'}>
        <InputField
          value={email}
          onChange={event => setEmail(event.target.value)}
          error={error}
          title={translate('partner.register.labels.email')}
          inputClassName={'partner-register-input'}
          placeholder={'partner.register.placeholders.email'}
        />
      </div>
      <div className={'input-container'}>
        <InputField
          title={translate('partner.register.labels.password')}
          onChange={event => setPassword(event.target.value)}
          error={error}
          inputClassName={'partner-register-input'}
          value={password}
          type={'text'}
          placeholder={'partner.register.placeholders.password'}
        />
      </div>
      <div className={'marginT15'}>
        <Button onClick={handleSaveCompanyUser} title={translate('companyManage.buttons.save')} />
      </div>
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  saveCompany: storeState.company.saveCompany,
  saveCompanyUser: storeState.company.saveCompanyUser,
  wallet: storeState.company.wallet,
  walletStatus: storeState.company.walletStatus,
  createWalletStatus: storeState.company.createWalletStatus,
  increaseWalletStatus: storeState.company.increaseWalletStatus,
  getCompany: storeState.company.getCompany,
  company: storeState.company.company,
  billings: storeState.billing.billings as IPage<IBilling>
});

const mapDispatchToProps = {
  insertCompany,
  updateCompany,
  reset,
  showModal,
  hideModal,
  fetchCompany,
  createCompanyUser,
  getWallet,
  increaseWallet,
  createCompanyWallet,
  getCompanyById,
  fetchPriceTable,
  getBillings,
  createCompanyReport
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyManage);
