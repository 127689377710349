import React from 'react';
import './styles.scss';

interface SwitchButtonProps {
  state: boolean;
  setState: (state: boolean) => void;
  leftLabel?: string;
  rightLabel?: string;
}

const SwitchButton = ({ state, setState, leftLabel, rightLabel }: SwitchButtonProps) => {
  const handleAccept = () => {
    setState(true);
  };

  const handleReject = () => {
    setState(false);
  };

  return (
    <div className="switch-button-container">
      <button
        onClick={handleReject}
        className={`switch-button switch-button-left ${state === false ? 'switch-button-left-active' : ''}`}
      >
        {leftLabel}
      </button>
      <button
        onClick={handleAccept}
        className={`switch-button switch-button-right ${state === true ? 'switch-button-right-active' : ''}`}
      >
        {rightLabel}
      </button>
    </div>
  );
};

export default SwitchButton;
