export enum PhoneType {
  PROFESSIONAL = 'PROFESSIONAL',
  CELLULAR = 'CELLULAR',
  OTHER = 'OTHER'
}

export interface IPhone {
  id?: number;
  number?: string;
  type?: PhoneType;
}

export const defaultValue: Readonly<IPhone> = {};
