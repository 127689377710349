import React from 'react';
import { IPaymentMethodCardItem } from '../../../../../app/entities/customer/customer-redux';
import { PresetPaymentMethodsToCharge } from '../../../../../app/shared/model/preset-payment-methods-to-charge';
import { Button, Card, CardBody, CardHeader, CardText } from 'reactstrap';
import { IPaymentMethod } from '../../../../../app/shared/model/payment-method';
import { ICoParticipationGroup } from '../../../../../app/shared/model/CoParticipation/ICoParticipationGroup';
import _ from 'lodash';
import { ICustomer } from '../../../../shared/model/customer/customer';

interface PaymentMethodProps {
  items?: IPaymentMethodCardItem[];
  selected?: PresetPaymentMethodsToCharge;
  onSelected: (item: PresetPaymentMethodsToCharge) => void;
  customer?: ICustomer;
}
const PaymentMethodCardItems: React.FC<PaymentMethodProps> = ({
  items,
  selected,
  onSelected,
  customer
}) => {

  const handleOnSelectedPaymentMethod = (item: IPaymentMethodCardItem) => {
    const presetPaymentMethodsToCharge = _.cloneDeep(selected) ?? {};
    const objCustomer = { id: customer?.id, email: customer?.user?.email };
    presetPaymentMethodsToCharge['coParticipationCustomer'] = objCustomer;
    if (item.type == 'credit_card') {
      const paymentMethod = item.item as IPaymentMethod;
      if (
        presetPaymentMethodsToCharge?.customerPaymentMethod?.id &&
        presetPaymentMethodsToCharge.customerPaymentMethod.id == paymentMethod.id) {
        presetPaymentMethodsToCharge['customerPaymentMethod'] = undefined;
      } else {
        presetPaymentMethodsToCharge['customerPaymentMethod'] = paymentMethod;
      }
    } else {
      const paymentMethod = item.item as ICoParticipationGroup;
      if (
        presetPaymentMethodsToCharge?.coParticipationGroupActivityCategory?.id &&
        presetPaymentMethodsToCharge?.coParticipationGroupActivityCategory?.id == paymentMethod?.activityCategories?.[0].id) {
        presetPaymentMethodsToCharge['coParticipationGroupActivityCategory'] = undefined;
        presetPaymentMethodsToCharge['coParticipationGroup'] = undefined;
      } else {
        presetPaymentMethodsToCharge['coParticipationGroup'] = paymentMethod;
        presetPaymentMethodsToCharge['coParticipationGroupActivityCategory'] = paymentMethod?.activityCategories?.[0];
      }
    }

    onSelected(presetPaymentMethodsToCharge);
  };

  if (items == null) {
    return null;
  }

  return (
    <div className="d-flex flex-wrap">
      {items?.map?.((paymentMethodCardItem, index) => {
        const type = paymentMethodCardItem?.type;
        let description = '';
        let isSelected = false;
        if (type == 'credit_card') {
          const paymentMethod = paymentMethodCardItem.item as IPaymentMethod;
          description = `${paymentMethod.brand} - final: ${paymentMethod.lastFourNumbers}`;
          isSelected = selected?.customerPaymentMethod?.id && selected.customerPaymentMethod.id == paymentMethod.id;
        } else if (type == 'co_participation') {
          const paymentMethod = paymentMethodCardItem.item as ICoParticipationGroup;
          const groupActivityCategory = paymentMethod?.activityCategories?.[0];
          description = `${paymentMethod.id}-${paymentMethod.nameGroup} / ${groupActivityCategory?.id}-${groupActivityCategory?.activityCategory?.name}`;
          isSelected =
            !selected?.coParticipationGroupActivityCategory?.id && !selected?.coParticipationGroup?.id ? false :
              selected.coParticipationGroupActivityCategory?.id == paymentMethod?.activityCategories?.[0].id &&
              selected.coParticipationGroup?.id == paymentMethod.id;
        }

        const title = type == 'credit_card' ? 'Cartão' : 'Benefício';
        return (
          <Card
            className="m-2"
            key={`payment-method-card-${index}`}
            color={isSelected ? 'info' : undefined}
            inverse={isSelected}
            style={{ width: '18rem', borderRadius: '10px' }}
          >
            <CardHeader tag="h5">{title}</CardHeader>
            <CardBody>
              <CardText>{description}</CardText>
              <Button
                color={isSelected ? 'success' : 'secondary'}
                inverse={isSelected}
                onClick={() => handleOnSelectedPaymentMethod(paymentMethodCardItem)}>
                {isSelected ? 'Selecionado' : '+'}
              </Button>
            </CardBody>
          </Card>
        );
      })}
    </div>
  );
};

export default PaymentMethodCardItems;
