import axios from 'axios';
import { translate } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IPartnerCreate } from 'app/shared/model/partner';

export const ACTION_TYPES = {
  CREATE_ACCOUNT: 'partner-register/CREATE_ACCOUNT',
  RESET: 'partner-register/RESET'
};

const initialState = {
  loading: false,
  registrationSuccess: false,
  registrationFailure: false,
  error: null
};

export type PartnerRegisterState = Readonly<typeof initialState>;

// Reducer
export default (state: PartnerRegisterState = initialState, action): PartnerRegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...initialState,
        registrationFailure: true,
        error: action.payload.response.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...initialState,
        registrationSuccess: true
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
const apiUrl = 'api/partners/partner-place';

export const createPartner = (partner: IPartnerCreate) => ({
  type: ACTION_TYPES.CREATE_ACCOUNT,
  payload: axios.post(`${apiUrl}`, partner)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
