import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { getPaginationItemsNumber, JhiPagination, translate, Translate } from 'react-jhipster';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import './activity-plan.scss';
import { ActivityPlan } from 'app/shared/model/activity-plan';
import { getActivityPlans, reset } from 'app/entities/activity-plan/activity-plan.reducer';
import { SimpleTableList } from 'app/components/simple-table-list/simple-table-list';
import { Row, Button } from 'reactstrap';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StringUtils from 'app/util/StringUtils';

export interface IActivityPlanListProps extends StateProps, DispatchProp, RouteComponentProps<{}> { }

export interface IActivityPlanListState {
  pageRequest: IPageRequest;
  page?: IPage<ActivityPlan>;
  search: {
    email?: string;
    name?: string;
    // status?: KeikenPayInvoiceStatus
  };
}

export class IActivityPlanList extends React.Component<IActivityPlanListProps, IActivityPlanListState> {

  constructor(props) {
    super(props);
    this.state = {
      search: {},
      pageRequest: {
        page: 0,
        size: 20
      }
    };
    this.performFilterAction = debounce(this.performFilterAction, 500);
  }

  private performFilterAction = () => {
    this.props.getActivityPlans(this.state.pageRequest);
  }

  componentDidMount() {
    this.props.getActivityPlans(this.state.pageRequest);
  }

  componentWillReceiveProps(newProps) {
    if (newProps?.page?.content != null) {
      this.setState({
        page: newProps.page
      });
      this.props.reset();
    }
  }

  private handleEdit = (actvityPlan: ActivityPlan | undefined) => {
    this.props.history.push({
      pathname: `activity-plan/${actvityPlan?.id}`,
      state: {
        actvityPlan,
        edit: true
      }
    });
  };

  private renderActionColumn = (actvityPlan: ActivityPlan | undefined): JSX.Element => {
    return (
      <div className={'action-column-container'}>
        <Button className={'centered-content'} onClick={this.handleEdit.bind(this, actvityPlan)}>
          <FontAwesomeIcon icon={'check'} className={'marginR1'} />
          <Translate contentKey={'keikenPayList.buttons.edit'} />
        </Button>
        {/* <Button className={'centered-content marginL2'} onClick={this.handleDeleteFaq.bind(this, faq)}>
          <FontAwesomeIcon icon={'trash'} className={'marginR1'} />
          <Translate contentKey={'keikenPayList.buttons.delete'} />
        </Button> */}
      </div>
    );
  };

  private handleTransformToTableContent = (content?: ActivityPlan[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: ActivityPlan) => {
      result.push([
        String(item.name),
        item?.partnerPlace?.fantasyName,
        StringUtils.currencyPtBr(item?.price),
        item?.approved ? 'Sim' : 'Não',
        item?.enabledB2b ? 'Sim' : 'Não',
        this.renderActionColumn(item)
      ]);
    });

    return result;
  };

  private handlePagination = activePage => {
    const object = {
      ...this.state.pageRequest,
      ...this.state.search
    };
    object.page = activePage - 1;
    this.setState({ pageRequest: object });
    this.props.getActivityPlans(object);
  };

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state.page?.content);
    return (
      <div className={'partner-place-list-container'}>
        <div>
          {/* TODO: find by name */}
          {/* TODO: filter by b2b, by approve */}
        </div>
        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('activityPlan.list.tableHeaders')}
          emptyTableMessage={translate('activityPlan.list.emptyList')}
          textOverflow
        />
        {tableContent.length > 0 && (
          <Row className={'justify-content-center'}>
            <JhiPagination
              items={this.state.page ? getPaginationItemsNumber(this.state.page.totalElements, this.state.page.size) : 0}
              onSelect={this.handlePagination}
              activePage={this.state.page ? this.state.page.number : 0}
              maxButtons={5}
            />
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storageState: IRootState) => ({
  page: storageState.activityPlans.page,
  getActivityPlanStatus: storageState.activityPlans.getActivityPlanStatus
});

const mapDispatchToProps = { reset, getActivityPlans };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProp = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IActivityPlanList);
