import React from 'react';
import { translate } from 'react-jhipster';
import './header.scss';

interface IHeaderTableProps {

    title: string;
    onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    valueSearch: string;
    onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
    valueEmail: string;
    statusOptionsFilter: string[];
    onChangeSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    valueSelect: string;
}

export const HeaderTable = ({ title, onChangeSearch, valueSearch, onChangeEmail, valueEmail, statusOptionsFilter, onChangeSelect, valueSelect }: IHeaderTableProps) => {

    return(
        <div className="filter-container">
            <strong>{title}</strong>
            <div className="filter-options">
                <div className="search-input-container">
                    <img src="content/images/search.png" alt="search icon" />
                    <input
                        type="text"
                        placeholder="Buscar por nome"
                        value={valueSearch}
                        onChange={onChangeSearch}
                    />
                </div>
                <div className="search-input-container">
                    <img src="content/images/search.png" alt="search icon" />
                    <input
                        type="text"
                        placeholder="Buscar por email"
                        value={valueEmail}
                        onChange={onChangeEmail}
                    />
                </div>
                <div className="dropdown-container">
                    <img src="content/images/arrowDown.png" alt="dropdown icon" />
                    <select
                        name="keiken-pay-filter-status"
                        onChange={onChangeSelect}
                        value={valueSelect}
                    >
                        <option value={'NULL'}>{translate(`keikenPayList.filter.status`)}</option>
                        {statusOptionsFilter.map((it, index) => (
                            <option key={`${it}-${index}`} value={it}>
                                {translate(`keikenPayList.keikenPayStatus.${it}`)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};
