import { PresetRenewalActivityPlan } from 'app/shared/model/customer-activity-plan';
import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { PresetPaymentMethodsToCharge } from 'app/shared/model/preset-payment-methods-to-charge';

export const ACTION_TYPES = {
  FETCH_RENEWAL_ACTIVITY_PLAN: 'renewalActivityPlan/FETCH_RENEWAL_ACTIVITY_PLAN',
  DELETE_RENEWAL_ACTIVITY_PLAN: 'renewalActivityPlan/DELETE_RENEWAL_ACTIVITY_PLAN',
  UPDATE_PAYMENT_RENEWAL_ACTIVITY_PLAN: 'renewalActivityPlan/UPDATE_PAYMENT_RENEWAL_ACTIVITY_PLAN',
  CREATE_PAYMENT_RENEWAL_ACTIVITY_PLAN: 'renewalActivityPlan/CREATE_PAYMENT_RENEWAL_ACTIVITY_PLAN',
  RESET: 'renewalActivityPlan/RESET'
};

const initialState = {
  page: null,
  status: HttpRequestStatus.NOOP,
  statusDelete: HttpRequestStatus.NOOP,
  statusUpdatePayment: HttpRequestStatus.NOOP,
  statusCreatePresetRenewal: HttpRequestStatus.NOOP
};

export type IRenewalActivityPlanState = Readonly<typeof initialState>;

// Reducer
export default (state: IRenewalActivityPlanState = initialState, action: IActionForResponseEntity<any>): IRenewalActivityPlanState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RENEWAL_ACTIVITY_PLAN):
      return {
        ...state,
        status: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_RENEWAL_ACTIVITY_PLAN):
      return {
        ...initialState,
        status: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_RENEWAL_ACTIVITY_PLAN):
      return {
        ...initialState,
        page: action.payload.data,
        status: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.DELETE_RENEWAL_ACTIVITY_PLAN):
      return {
        ...state,
        statusDelete: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.DELETE_RENEWAL_ACTIVITY_PLAN):
      return {
        ...state,
        statusDelete: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.DELETE_RENEWAL_ACTIVITY_PLAN):
      return {
        ...state,
        statusDelete: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.UPDATE_PAYMENT_RENEWAL_ACTIVITY_PLAN):
      return {
        ...state,
        statusUpdatePayment: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_PAYMENT_RENEWAL_ACTIVITY_PLAN):
      return {
        ...state,
        statusUpdatePayment: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PAYMENT_RENEWAL_ACTIVITY_PLAN):
      return {
        ...state,
        statusUpdatePayment: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.CREATE_PAYMENT_RENEWAL_ACTIVITY_PLAN):
      return {
        ...state,
        statusCreatePresetRenewal: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.CREATE_PAYMENT_RENEWAL_ACTIVITY_PLAN):
      return {
        ...state,
        statusCreatePresetRenewal: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.CREATE_PAYMENT_RENEWAL_ACTIVITY_PLAN):
      return {
        ...state,
        statusCreatePresetRenewal: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/preset-renewal-activity-plan';

export const fetchRenewalActivityPlan = (page = { size: 20, page: 0 }, predicate: any = {}) => {
  const params = {
    size: page?.size || 20,
    page: page?.page || 0,
    sort: 'status,desc',
    ...predicate
  };

  return {
    type: ACTION_TYPES.FETCH_RENEWAL_ACTIVITY_PLAN,
    payload: axios.get(apiUrl, { params })
  };
};

export const deleteRenewalActivityPlan = (id?: Number) => ({
  type: ACTION_TYPES.DELETE_RENEWAL_ACTIVITY_PLAN,
  payload: axios.delete(`${apiUrl}/${id}`)
});

export const updatePresetPaymentToRenewalActivityPlan = (id: number, customerId: number, item: PresetPaymentMethodsToCharge) => ({
  type: ACTION_TYPES.UPDATE_PAYMENT_RENEWAL_ACTIVITY_PLAN,
  payload: axios.put(`/api/admin/preset-renewal-activity-plan/${id}/payment-methods/customer/${customerId}`, item)
});

export const createRenewalActivityPlanFromCustomerPlan = (id: number, item: PresetRenewalActivityPlan) => ({
  type: ACTION_TYPES.CREATE_PAYMENT_RENEWAL_ACTIVITY_PLAN,
  payload: axios.post(`/api/admin/preset-renewal-activity-plan/customer-activity-plan/${id}`, item)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
