import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { getPaginationItemsNumber, JhiPagination, translate, Translate } from 'react-jhipster';
import { Row, Col, Button } from 'reactstrap';
import { debounce } from 'lodash';
import {
  fetchRenewalActivityScheduleById,
  updateRenewalActivitySchedule,
  updateRenewalActivitySchedulePaymentMethod,
  getCoParticipationCustomerToRenewal,
  reset,
  updatePresetPaymentToRenewalActivitySchedule
} from 'app/entities/renewal-activity-schedule/renewal-activity-schedule-redux';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import { toast } from 'react-toastify';
import { IRenewalActivitySchedule, PresetRenewalActivityScheduleStatus } from 'app/shared/model/renewal-activity-schedule';
import { defaultWeeklyOptions, SelectOptionsDropdown } from '../schedule-manage/activity-schedule-confirm-modal/activity-schedule-confirm-modal';
import _ from 'lodash';
import InputField from 'app/components/input-field/InputField';
import { BillingCoParticipation } from 'app/shared/model/KeikenPay/KeikenPayInvoice';
import { ICoParticipationCustomer } from 'app/shared/model/CoParticipation/ICoParticipationCustomer';
import classnames from 'classnames';
import { ICoParticipationGroupActivityCategory } from 'app/shared/model/CoParticipation/ICoParticipationGroupActivityCategory';
import { IActivityCategory } from 'app/shared/model/activity-category';
import { IActivitySubCategory } from 'app/shared/model/activity-sub-category';
import CoParticipationCustomerToRenewal from 'app/components/CoParticipationCustomerToRenewal/CoParticipationCustomerToRenewal';
import RenewalItemRow from 'app/components/CoParticipationCustomerToRenewal/RenewalItemRow';
import { PresetPaymentMethodsToCharge } from 'app/shared/model/preset-payment-methods-to-charge';

export interface IRenewalActivityScheduleManageScreenProps extends StateProps,
  DispatchProps, RouteComponentProps<any, any, { renewal: IRenewalActivitySchedule } | any> { }

export interface IRenewalActivityScheduleManageScreenState {
  renewal?: IRenewalActivitySchedule;
  updating: boolean;
  loading: boolean;
  coParticipationCustomer?: ICoParticipationCustomer;
}

class RenewalActivityScheduleManageScreen extends React.Component<IRenewalActivityScheduleManageScreenProps, IRenewalActivityScheduleManageScreenState> {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      loading: false
    };
    this.performAction = debounce(this.performAction, 500);
  }

  componentDidMount() {
    if (this.props.location.state?.invoice != null) {
      this.setState({
        renewal: this.props.location.state.renewal
      }, () => {
        this.getCoParticipationCustomerToRenewal();
      });
    } else {
      if (this.props.match.params?.id != null) {
        this.props.fetchRenewalActivityScheduleById(this.props.match.params?.id);
      }
    }
  }

  componentWillReceiveProps(newProps: IRenewalActivityScheduleManageScreenProps) {
    if (newProps.getRenewalStatus === HttpRequestStatus.SUCCESS) {
      this.setState({
        renewal: newProps.renewal
      }, () => {
        this.getCoParticipationCustomerToRenewal();
      });
      this.props.reset();
      this.setState({ loading: false });
    }
    if (newProps.coParticipationCustomerStatus === HttpRequestStatus.SUCCESS) {
      this.setState({
        coParticipationCustomer: newProps.coParticipationCustomer
      });
      this.setState({ loading: false });
      this.props.reset();
    }
    if (newProps.updateStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Atualizado com sucesso!');
      this.props.reset();
      this.performAction();
      this.setState({ loading: false });
    }
  }

  private performAction = () => {
    this.props.fetchRenewalActivityScheduleById(this.props.match.params?.id);
  }

  private getCoParticipationCustomerToRenewal = () => {
    if (this.state.renewal.customer?.id == null) {
      toast.error('renewal.customer.id não carregado', {
        autoClose: 3000
      });
      return;
    }
    this.props.getCoParticipationCustomerToRenewal(this.state.renewal.customer?.id);
  }

  private submitUpdateRenewal = () => {
    this.props.updateRenewalActivitySchedule(this.state.renewal);
  }

  // TODO - Utils
  private paymentMethodLabel = (presetPaymentMethod?: PresetPaymentMethodsToCharge): string => {
    if (presetPaymentMethod == null) {
      return 'sem pagamento';
    }
    if (presetPaymentMethod?.coParticipationCustomer != null && presetPaymentMethod?.customerPaymentMethod != null) {
      return 'benefício e cartão';
    }
    if (presetPaymentMethod?.coParticipationCustomer != null) {
      return 'com benefício';
    }
    if (presetPaymentMethod?.customerPaymentMethod != null) {
      return 'com cartão';
    }
    return '..';
  }

  handleOnWarningCallbackPressed = () => {
    this.props.hideModal();
  };

  handleOnChangePaymentMethod = (item: ICoParticipationGroupActivityCategory) => {
    if (this.state.loading == true) {
      return;
    }
    this.setState({ loading: true }, () => {
      const renewalActivityCategories: number[] = this.state.renewal.activity.activityCategories.map(it => it.id);
      if (!renewalActivityCategories.includes(item.activityCategory.id)) {
        toast.warn('Benefício selecionado não contém categoria válida');
        return;
      }

      const customerId = this.state.renewal.customer.id;
      // this.props.updateRenewalActivitySchedulePaymentMethod(this.state.renewal.id, item);
      this.props.updatePresetPaymentToRenewalActivitySchedule(
        this.state.renewal.id,
        customerId,
        {
          coParticipationGroupActivityCategory: item
        });
    });
  }

  private handleChangeStatus = e => {
    const renewal = this.state.renewal;
    renewal.status = e?.target?.value;
    this.setState({ renewal });
  }

  render() {
    const item = this.state.renewal;
    const dayOfWeek = translate(`renewalList.daysOfWeek.${item?.dayOfWeek}`);
    const interval = item?.intervalWeek != null ?
      _.find(defaultWeeklyOptions, ['value', item?.intervalWeek])?.name || '' : '';
    const payment = this.paymentMethodLabel(item?.presetPaymentMethodsToCharge);
    const status = item?.status ? translate(`renewalList.status.${item?.status}`) : '';
    const activityInfo = `${item?.activity?.name} - ${item?.activity?.status} - ${item?.activity?.type}`;
    const activityCategories = item?.activity?.activityCategories?.map(it => it.name)?.join(', ');
    const customerPhones = item?.customer?.phones?.map?.(it => it.number)?.join(', ');
    const customerInfo = `${item?.customer?.name} - ${item?.customer?.user?.email} - ${customerPhones}`;
    return (
      <div className={'form-container'}>
        <div className={'title-screen-container'}>
          <div onClick={() => this.props.history.goBack()}>
            <FontAwesomeIcon className={'back-arrow-icon'} icon={'arrow-left'} />
          </div>
        </div>
        <Row>
          <Col md="6">
            <div className={'input-container'}>
              <RenewalItemRow
                title={translate('renewalManage.labels.customer')}
                value={customerInfo}
              />
              <RenewalItemRow
                title={translate('renewalManage.labels.activity')}
                value={activityInfo ?? ''}
              />
              <RenewalItemRow
                title={translate('renewalManage.labels.activityCategories')}
                value={activityCategories ?? ''}
              />
              <RenewalItemRow
                title={translate('renewalManage.labels.partner')}
                value={`${item?.activity?.partnerPlace?.fantasyName ?? ''} - ${item?.activity?.partnerPlace?.email ?? ''}`}
              />
              <RenewalItemRow
                title={translate('renewalManage.labels.daysOfWeek')}
                value={item?.dayOfWeek ? dayOfWeek : ''}
              />
              <RenewalItemRow
                title={translate('renewalManage.labels.date')}
                value={item?.startAt ?? ''}
              />
              <RenewalItemRow
                title={translate('renewalManage.labels.interval')}
                value={item?.intervalWeek ? interval : ''}
              />
              <RenewalItemRow
                title={translate('renewalManage.labels.payment')}
                value={payment}
              />
              <RenewalItemRow
                title={translate('renewalManage.labels.status')}
                value={status ?? ''}
              />
              <p>

                <select
                  onChange={this.handleChangeStatus}
                  value={item?.status}
                  className={'partner-register-select'}
                >
                  {[PresetRenewalActivityScheduleStatus.ENABLED, PresetRenewalActivityScheduleStatus.DISABLED, PresetRenewalActivityScheduleStatus.PRESET_PAYMENT_METHOD_MISSING].map((it, index) => (
                    <option value={it} key={`preset-status-${it + index}`}>
                      {it}
                    </option>
                  ))}
                </select>
              </p>
              <p>

                <SelectOptionsDropdown<IActivityCategory>
                  title="Categoria"
                  nameKey="name"
                  value={item?.activityCategory}
                  options={item?.activity?.activityCategories ?? []}
                  onSelect={(activityCategory: IActivityCategory) => {
                    this.setState(prevState => ({
                      renewal: {
                        ...prevState.renewal,
                        activityCategory
                      }
                    }));
                  }}
                />
              </p>
              <p>
                <SelectOptionsDropdown<IActivitySubCategory>
                  title="Sub-Categoria"
                  nameKey="name"
                  value={item?.activitySubCategory}
                  options={item?.activity?.activitySubCategories ?? []}
                  onSelect={(activitySubCategory: IActivitySubCategory) => {
                    this.setState(prevState => ({
                      renewal: {
                        ...prevState.renewal,
                        activitySubCategory
                      }
                    }));
                  }}
                />
              </p>
              <p>
                <button onClick={this.submitUpdateRenewal}>
                  {'Atualizar dados'}
                </button>
              </p>
            </div>
          </Col>
          <Col md="6">
            <div className={'input-container'}>
              {this.state.coParticipationCustomer == null ? (
                <button className={'renewal-activity-category-button'} onClick={this.getCoParticipationCustomerToRenewal}>
                  {'Carregar benefícios de '}
                  <span className={'renewal-label-value'}>
                    {item?.customer?.name}
                  </span>
                </button>
              ) : (
                <CoParticipationCustomerToRenewal
                  coParticipationGroupActivityCategory={this.state.renewal?.presetPaymentMethodsToCharge?.coParticipationGroupActivityCategory}
                  item={this.state.coParticipationCustomer}
                  onClick={(it?: ICoParticipationGroupActivityCategory) => {
                    const presetPaymentMethodsToCharge = {
                      ...this.state.renewal?.presetPaymentMethodsToCharge,
                      coParticipationGroupActivityCategory: it
                    };
                    this.setState({
                      renewal: {
                        ...this.state.renewal,
                        presetPaymentMethodsToCharge
                      }
                    });
                  }}
                  onSubmit={this.handleOnChangePaymentMethod} />
              )}
            </div>
          </Col>
        </Row>
      </div >
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  renewal: storeState.renewalActivitySchedule.renewal,
  getRenewalStatus: storeState.renewalActivitySchedule.getRenewalActivitySchedule,
  updateStatus: storeState.renewalActivitySchedule.updateRenewalActivitySchedule,
  coParticipationCustomer: storeState.renewalActivitySchedule.coParticipationCustomer,
  coParticipationCustomerStatus: storeState.renewalActivitySchedule.coParticipationCustomerStatus
});

const mapDispatchToProps = {
  fetchRenewalActivityScheduleById,
  updateRenewalActivitySchedule,
  updateRenewalActivitySchedulePaymentMethod,
  updatePresetPaymentToRenewalActivitySchedule,
  getCoParticipationCustomerToRenewal,
  showModal,
  hideModal,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenewalActivityScheduleManageScreen);
