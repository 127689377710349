import axios from 'axios';
import qs from 'qs';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IPageRequest } from 'app/shared/model/page.model';
import { ActivityPlan } from 'app/shared/model/activity-plan';

export const ACTION_TYPES = {
  REQUEST_ACTIVITY_PLAN: 'activities/REQUEST_ACTIVITY_PLAN',
  REQUEST_ACTIVITY_PLAN_LIST: 'activities/REQUEST_ACTIVITY_PLAN_LIST',
  REQUEST_ACTIVITY_PLAN_BY_ID: 'activities/REQUEST_ACTIVITY_PLAN_BY_ID',
  UPDATE_ACTIVITY_PLAN_PRICE: 'activities/UPDATE_ACTIVITY_PLAN_PRICE',
  RESET: 'activities/RESET'
};

const initialState = {
  getActivityPlanStatus: HttpRequestStatus.NOOP,
  updateActivityPlanStatus: HttpRequestStatus.NOOP,
  activityPlan: null,
  activityPlans: null,
  page: null
};

export type IActivityPlanState = Readonly<typeof initialState>;

export default (state: IActivityPlanState = initialState, action: IActionForResponseEntity<any>): IActivityPlanState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPDATE_ACTIVITY_PLAN_PRICE):
      return {
        ...state,
        updateActivityPlanStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_ACTIVITY_PLAN_PRICE):
      return {
        ...state,
        updateActivityPlanStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ACTIVITY_PLAN_PRICE):
      return {
        ...state,
        updateActivityPlanStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.REQUEST_ACTIVITY_PLAN):
      return { ...state, getActivityPlanStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.REQUEST_ACTIVITY_PLAN):
      return { ...state, getActivityPlanStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.REQUEST_ACTIVITY_PLAN):
      return { ...state, page: action.payload.data, getActivityPlanStatus: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.REQUEST_ACTIVITY_PLAN_LIST):
      return { ...state };
    case FAILURE(ACTION_TYPES.REQUEST_ACTIVITY_PLAN_LIST):
      return { ...state };
    case SUCCESS(ACTION_TYPES.REQUEST_ACTIVITY_PLAN_LIST):
      return { ...state, activityPlans: action.payload.data };
    case REQUEST(ACTION_TYPES.REQUEST_ACTIVITY_PLAN_BY_ID):
      return { ...state, getActivityPlanStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.REQUEST_ACTIVITY_PLAN_BY_ID):
      return { ...state, getActivityPlanStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.REQUEST_ACTIVITY_PLAN_BY_ID):
      return {
        ...state,
        getActivityPlanStatus: HttpRequestStatus.SUCCESS,
        activityPlan: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return initialState;
    default:
      return state;
  }
};

const apiUrl = '/api/activity-plan';

export const getActivityPlans = (page: IPageRequest, params?: any) => ({
  type: ACTION_TYPES.REQUEST_ACTIVITY_PLAN,
  payload: axios.get(`${apiUrl}?size=${page.size}&page=${page.page}${params ? `&${qs.stringify(params)}` : ''}`)
});

export const getActivityPlansList = () => ({
  type: ACTION_TYPES.REQUEST_ACTIVITY_PLAN_LIST,
  payload: axios.get(`${apiUrl}/list`)
});

export const getActivityPlanById = (id: Number) => ({
  type: ACTION_TYPES.REQUEST_ACTIVITY_PLAN_BY_ID,
  payload: axios.get(`${apiUrl}/${id}`)
});

export const approveActivityPlan = (activityPlan: ActivityPlan) => ({
  type: ACTION_TYPES.UPDATE_ACTIVITY_PLAN_PRICE,
  payload: axios.put(`${apiUrl}/${activityPlan.id}/approve`, activityPlan)
});

export const reset = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.RESET
  });
};
