import { IWallet } from './../../shared/model/wallet/wallet';
import { ICoParticipationGroup } from './../../shared/model/CoParticipation/ICoParticipationGroup';
import { ICoParticipationCustomer } from '../../../app/shared/model/CoParticipation/ICoParticipationCustomer';
import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from '../../../app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from '../../../app/util/redux-interface';
import { HttpRequestStatus } from '../../../app/shared/model/enum/HttpRequestStatus';
import StringUtils from '../../../app/util/StringUtils';
import { ICustomerActivity } from '../../../app/shared/model/customer-activity.model';
import { IPage } from '../../../app/shared/model/page.model';
import { CustomerUpdateStatusVM, ICustomer } from '../../../app/shared/model/customer/customer';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { IPaymentMethod } from '../../../app/shared/model/payment-method';

export const ACTION_TYPES = {
  POST_CSV_FILE: 'customer/POST_CSV_FILE',
  FETCH_BY_ID: 'customer/FETCH_BY_ID',
  FETCH_LIST: 'customer/FETCH_LIST',
  GET_CUSTOMER_ACTIVITIES_BY_CUSTOMER: 'customer/GET_CUSTOMER_ACTIVITIES_BY_CUSTOMER',
  RESET: 'customer/RESET',
  RESET_CUSTOMER_ACTIVITIES: 'customer/RESET_CUSTOMER_ACTIVITIES',
  GET_PAYMENT_METHODS: 'customer/GET_PAYMENT_METHODS',
  PUT_UPDATE_CUSTOMER_AND_PLAN_STATUS: 'customer/PUT_UPDATE_CUSTOMER_AND_PLAN_STATUS',
  FETCH_CUSTOMER_WALLET: 'customer/FETCH_CUSTOMER_WALLET',
  UPDATE_BLOCK_COPARTICIPATION_CUSTOMER: 'customer/UPDATE_BLOCK_COPARTICIPATION_CUSTOMER'
};

export interface IPaymentMethodCardItem {
  item: IPaymentMethod | ICoParticipationGroup;
  type: 'credit_card' | 'co_participation';
}

interface ICustomerStore {
  status: HttpRequestStatus;
  postCsvFileStatus: HttpRequestStatus;
  customerActivitiesStatus: HttpRequestStatus;
  getCustomers: HttpRequestStatus;
  customerActivities: IPage<ICustomerActivity> | undefined;
  page: IPage<ICustomer> | undefined;
  customer?: ICustomer;
  paymentMethods?: IPaymentMethodCardItem[];
  paymentMethodsStatus?: HttpRequestStatus;
  coParticipationCustomer?: ICoParticipationCustomer;
  updateCustomerStatus?: HttpRequestStatus;
  wallet?: IWallet;
  walletStatus?: HttpRequestStatus;
}

const initialState: ICustomerStore = {
  status: HttpRequestStatus.NOOP,
  postCsvFileStatus: HttpRequestStatus.NOOP,
  customerActivitiesStatus: HttpRequestStatus.NOOP,
  paymentMethodsStatus: HttpRequestStatus.NOOP,
  getCustomers: HttpRequestStatus.NOOP,
  customerActivities: undefined,
  page: undefined,
  customer: undefined,
  paymentMethods: undefined,
  updateCustomerStatus: HttpRequestStatus.NOOP,
  wallet: undefined,
  walletStatus: HttpRequestStatus.NOOP
};

export type ICustomerState = Readonly<typeof initialState>;

// Reducer
export default (state: ICustomerState = initialState, action: IActionForResponseEntity<{}>): ICustomerState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CUSTOMER_WALLET):
      return {
        ...state,
        wallet: undefined,
        walletStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_CUSTOMER_WALLET):
      return {
        ...state,
        walletStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_CUSTOMER_WALLET):
      return {
        ...state,
        wallet: action.payload.data,
        walletStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.POST_CSV_FILE):
      return {
        ...initialState,
        postCsvFileStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.POST_CSV_FILE):
      return {
        ...initialState,
        postCsvFileStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.POST_CSV_FILE):
      return {
        ...initialState,
        postCsvFileStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.FETCH_LIST):
      return {
        ...initialState,
        getCustomers: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_LIST):
      return {
        ...initialState,
        getCustomers: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_LIST):
      return {
        ...initialState,
        getCustomers: HttpRequestStatus.SUCCESS,
        // @ts-ignore
        page: action.payload.data
      };
    case REQUEST(ACTION_TYPES.FETCH_BY_ID):
      return {
        ...state,
        status: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_BY_ID):
      return {
        ...state,
        customer: undefined,
        status: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_BY_ID):
      return {
        ...state,
        status: HttpRequestStatus.SUCCESS,
        customer: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_CUSTOMER_ACTIVITIES_BY_CUSTOMER):
      return {
        ...state,
        customerActivitiesStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_CUSTOMER_ACTIVITIES_BY_CUSTOMER):
      return {
        ...state,
        customerActivities: undefined,
        customerActivitiesStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_CUSTOMER_ACTIVITIES_BY_CUSTOMER):
      return {
        ...state,
        customerActivitiesStatus: HttpRequestStatus.SUCCESS,
        // @ts-ignore
        customerActivities: action.payload.data
      };

    case REQUEST(ACTION_TYPES.PUT_UPDATE_CUSTOMER_AND_PLAN_STATUS):
      return {
        ...initialState,
        updateCustomerStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.PUT_UPDATE_CUSTOMER_AND_PLAN_STATUS):
      return {
        ...initialState,
        updateCustomerStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.PUT_UPDATE_CUSTOMER_AND_PLAN_STATUS):
      return {
        ...initialState,
        updateCustomerStatus: HttpRequestStatus.SUCCESS
      };
    case SUCCESS(ACTION_TYPES.UPDATE_BLOCK_COPARTICIPATION_CUSTOMER):
      return {
        ...state
      };
    case REQUEST(ACTION_TYPES.GET_PAYMENT_METHODS):
      return {
        ...state,
        paymentMethodsStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_PAYMENT_METHODS):
      return {
        ...state,
        paymentMethodsStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_PAYMENT_METHODS):
      return {
        ...state,
        paymentMethodsStatus: HttpRequestStatus.SUCCESS,
        ...action.payload
      };
    case ACTION_TYPES.RESET_CUSTOMER_ACTIVITIES:
      return {
        ...state,
        customerActivitiesStatus: HttpRequestStatus.NOOP
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/customers';

export const postCsvFile = (file: FormData, companyId: number, isPhones?: boolean) => ({
  type: ACTION_TYPES.POST_CSV_FILE,
  payload: axios.post(`${apiUrl}/relate-company${isPhones != null && isPhones === true ? '/phones' : ''}?companyId=${companyId}`, file)
});

export const putUpdateCustomerAndPlanStatus = (customerUpdateStatusVM: CustomerUpdateStatusVM) => ({
  type: ACTION_TYPES.PUT_UPDATE_CUSTOMER_AND_PLAN_STATUS,
  payload: axios.put(`/api/customer-status`, customerUpdateStatusVM)
});

export const fetchCustomerList = (page?: any, email?: string, cpf?: string,  sort?: string, customerStatus?: string, blocked?: string) => {
  const params = {
    size: page != null ? page.size : 20,
    page: page != null ? page.page : 0,
    sort: 'name,asc',
    status: customerStatus,
    unblockDate: blocked ? 'isNotNull' : null
  };

  if (!StringUtils.isStringInvalid(email)) {
    params['search'] = email; //  email or name
  }

  if (!StringUtils.isStringInvalid(cpf)) {
    params['cpf'] = cpf;
  }

  return {
    type: ACTION_TYPES.FETCH_LIST,
    payload: axios.get('api/customers', { params })
  };
};
export const fetchCustomerById = (id: number) => {
  return {
    type: ACTION_TYPES.FETCH_BY_ID,
    payload: axios.get(`api/customers/${id}`)
  };
};

export const getCustomerActivities = (customerId: number, page?: any) => {
  const params = {
    size: page != null ? page.size : 5,
    page: page != null ? page.page : 0
  };

  return {
    type: ACTION_TYPES.GET_CUSTOMER_ACTIVITIES_BY_CUSTOMER,
    payload: axios.get(`api/customers/${customerId}/customer-activities`, { params })
  };
};
export const fetchCustomerWalletByCustomerId = (customerId: number) => {
  return {
    type: ACTION_TYPES.FETCH_CUSTOMER_WALLET,
    payload: axios.get(`api/customers/${customerId}/wallet/logs`)
  };
};

export const getCustomerPaymentMethods = (customerId: number, activityCategoryId: number) => async (dispatch, getState) => {
  let customerPaymentMethods: ICoParticipationGroup[] = [];
  try {
    await dispatch({
      type: REQUEST(ACTION_TYPES.GET_PAYMENT_METHODS)
    });
    const resultCoParticipation = await axios.get(`/api/co-participation-customers/customer/${customerId}`);
    const coParticipationGroup: ICoParticipationGroup[] = resultCoParticipation.data?.groups
      ?.filter?.((it: ICoParticipationGroup) =>
        it?.activityCategories?.some(coGroupCategory => coGroupCategory?.activityCategory?.id == activityCategoryId))
      ?.map?.((item: ICoParticipationGroup) => ({
        item: {
          ...item,
          activityCategories: item.activityCategories?.filter?.(coGroupCategory => coGroupCategory?.activityCategory?.id == activityCategoryId)
        },
        type: 'co_participation'
      })) ?? [];
    if (coParticipationGroup) {
      customerPaymentMethods = coParticipationGroup;
    }
  } catch (error) {
    await dispatch({
      type: FAILURE(ACTION_TYPES.GET_PAYMENT_METHODS)
    });
    toast.error('Falha ao carregar benefício');
  }

  try {
    const resultPaymentMethods = await axios.get(`/api/admin/customer/${customerId}/customer-payment-methods`);
    const paymentMethods = resultPaymentMethods?.data?.map?.(item => ({ item, type: 'credit_card' })) ?? [];
    customerPaymentMethods.push(...paymentMethods);

  } catch (error) {
    toast.error('Falha ao carregar os cartões de crédito');
    await dispatch({
      type: FAILURE(ACTION_TYPES.GET_PAYMENT_METHODS)
    });
  }

  await dispatch({
    type: SUCCESS(ACTION_TYPES.GET_PAYMENT_METHODS),
    payload: {
      paymentMethods: customerPaymentMethods
    }
  });
};

export const updateBlockCoparticipationCustomer = (customerId: number, block: boolean, unblockDate?: string, description?: string) => {
  return {
    type: ACTION_TYPES.UPDATE_BLOCK_COPARTICIPATION_CUSTOMER,
    payload: axios.put(`/api/co-participation-customers/customer/block`, { id: customerId, block, unblockDate, description })
  };
};
export const resetCustomer = () => ({
  type: ACTION_TYPES.RESET
});

export const resetCustomerActivitiesStatus = () => ({
  type: ACTION_TYPES.RESET_CUSTOMER_ACTIVITIES
});
