import axios from 'axios';
import qs from 'qs';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForPage } from 'app/util/redux-interface';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IKeikenPayPartnerExternal } from 'app/shared/model/KeikenPay/KeikenPayPartnerExternal';
import { IPageRequest } from 'app/shared/model/page.model';

export const ACTION_TYPES = {
  GET_KEIKEN_PAY_PARTNER_EXTERNAL: 'keiken-pay-partner-external/GET_KEIKEN_PAY_PARTNER_EXTERNAL',
  GET_KEIKEN_PAY_PARTNERS_EXTERNALS: 'keiken-pay-partner-external/GET_KEIKEN_PAY_PARTNERS_EXTERNALS',
  CREATE_KEIKEN_PAY_PARTNER_EXTERNAL: 'keiken-pay-partner-external/CREATE_KEIKEN_PAY_PARTNER_EXTERNAL',
  UPDATE_KEIKEN_PAY_PARTNER_EXTERNAL: 'keiken-pay-partner-external/UPDATE_KEIKEN_PAY_PARTNER_EXTERNAL',
  RESET: 'keiken-pay-partner-external/RESET'
};

const initialState = {
  page: null,
  partnerExternal: null,
  partnerExternalPage: null,
  getPartnerExternal: HttpRequestStatus.NOOP,
  getPartnersExternals: HttpRequestStatus.NOOP,
  createPartnerExternal: HttpRequestStatus.NOOP,
  updatePartnerExternal: HttpRequestStatus.NOOP
};

export type IKeikenPayPartnerExternalState = Readonly<typeof initialState>;

// Reducer
export default (state: IKeikenPayPartnerExternalState = initialState, action: IActionForPage<IKeikenPayPartnerExternal>): IKeikenPayPartnerExternalState => {
  switch (action.type) {
      case REQUEST(ACTION_TYPES.GET_KEIKEN_PAY_PARTNER_EXTERNAL):
        return {...initialState, getPartnerExternal: HttpRequestStatus.ONGOING};
      case FAILURE(ACTION_TYPES.GET_KEIKEN_PAY_PARTNER_EXTERNAL):
        return {...initialState, getPartnerExternal: HttpRequestStatus.ERROR};
      case SUCCESS(ACTION_TYPES.GET_KEIKEN_PAY_PARTNER_EXTERNAL):
        return {...initialState, getPartnerExternal: HttpRequestStatus.SUCCESS, partnerExternal: action.payload.data};
      case REQUEST(ACTION_TYPES.GET_KEIKEN_PAY_PARTNERS_EXTERNALS):
        return {...initialState, getPartnersExternals: HttpRequestStatus.ONGOING};
      case FAILURE(ACTION_TYPES.GET_KEIKEN_PAY_PARTNERS_EXTERNALS):
        return {...initialState, getPartnersExternals: HttpRequestStatus.ERROR};
      case SUCCESS(ACTION_TYPES.GET_KEIKEN_PAY_PARTNERS_EXTERNALS):
        return {...initialState, getPartnersExternals: HttpRequestStatus.SUCCESS, page: action.payload.data, partnerExternalPage: action.payload.data};
      case REQUEST(ACTION_TYPES.CREATE_KEIKEN_PAY_PARTNER_EXTERNAL):
        return {...initialState, createPartnerExternal: HttpRequestStatus.ONGOING};
      case FAILURE(ACTION_TYPES.CREATE_KEIKEN_PAY_PARTNER_EXTERNAL):
        return {...initialState, createPartnerExternal: HttpRequestStatus.ERROR};
      case SUCCESS(ACTION_TYPES.CREATE_KEIKEN_PAY_PARTNER_EXTERNAL):
        return {...initialState, createPartnerExternal: HttpRequestStatus.SUCCESS};
      case REQUEST(ACTION_TYPES.UPDATE_KEIKEN_PAY_PARTNER_EXTERNAL):
        return {...initialState, updatePartnerExternal: HttpRequestStatus.ONGOING};
      case FAILURE(ACTION_TYPES.UPDATE_KEIKEN_PAY_PARTNER_EXTERNAL):
        return {...initialState, updatePartnerExternal: HttpRequestStatus.ERROR};
      case SUCCESS(ACTION_TYPES.UPDATE_KEIKEN_PAY_PARTNER_EXTERNAL):
        return {...initialState, updatePartnerExternal: HttpRequestStatus.SUCCESS};
      case ACTION_TYPES.RESET: return {...initialState};
      default:
        return state;
  }
};

const apiUrl = '/api/keiken-pay-partner-external';

export const getPartnersExternals = (page: IPageRequest , queryParams?: any) => ({
    type: ACTION_TYPES.GET_KEIKEN_PAY_PARTNERS_EXTERNALS,
    payload: axios.get(`${apiUrl}?size=${page.size}&page=${page.page}&${qs.stringify(queryParams)}`)
});
export const getPartnerExternal = partnerExternalId => {
  return {
    type: ACTION_TYPES.GET_KEIKEN_PAY_PARTNER_EXTERNAL,
    payload: axios.get(`${apiUrl}/${partnerExternalId}`)
  };
};

export const createPartnerExternal = (partnerExternal: IKeikenPayPartnerExternal) => {
  return {
    type: ACTION_TYPES.CREATE_KEIKEN_PAY_PARTNER_EXTERNAL,
    payload: axios.post(`${apiUrl}`, partnerExternal)
  };
};

export const updatePartnerExternal = (partnerExternal: IKeikenPayPartnerExternal) => {
  return {
    type: ACTION_TYPES.UPDATE_KEIKEN_PAY_PARTNER_EXTERNAL,
    payload: axios.put(`${apiUrl}`, partnerExternal)
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
