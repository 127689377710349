import { IPartnerPlace } from './partner-place.model';
import { ActivityCategoryStatus, IActivityCategory } from './activity-category';
import { IImage } from './image';
import { IActivitySubCategory } from './activity-sub-category';
import { IPriceTableActivity } from './price-table';

export interface IPriceTableActivityVM {
  priceTableActivities: IPriceTableActivity[];
}

export interface SimpleCompany {
  id: number;
  name?: string;
}
export class ActivityPlan {
  id?: number;
  name?: string;
  partnerPlace?: IPartnerPlace;
  price?: number;
  priceWithFee?: number;
  enrolmentFee?: number;
  maintenanceFee?: number;
  period?: ActivityPlanRenewingPeriod;
  type?: ActivityPlaceType;
  approved?: boolean;
  enabledB2b?: boolean;
  specificPriceB2b?: SpecificPriceB2b[];
  description?: string;
  teacher?: string;
  slots?: number;
  status?: ActivityStatus;
  image?: IImage;
  activityCategories?: IActivityCategory[];
  activitySubCategories?: IActivitySubCategory[];
  priceTableActivities?: IPriceTableActivity[];
  typeDescription?: string;
  isEnrolmentNeeded?: boolean;
  minTimeTurnRecurrenceOff?: number;
  allowedCompanies?: SimpleCompany[];
}

export interface SpecificPriceB2b {
  price?: number;
  enrolmentFee?: number;
  maintenanceFee?: number;
}

export enum ActivityPlaceType {
  IN_COMPANY = 'IN_COMPANY',
  IN_PLACE = 'IN_PLACE',
  ONLINE = 'ONLINE',
  TO_CUSTOMER = 'TO_CUSTOMER'
}

export enum ActivityPlanRenewingPeriod {
  WEEKLY_PERIOD = 'WEEKLY_PERIOD',
  BI_WEEKLY_PERIOD = 'BI_WEEKLY_PERIOD',
  MONTH_PERIOD = 'MONTH_PERIOD',
  TWO_MONTHS_PERIOD = 'TWO_MONTHS_PERIOD',
  THREE_MONTHS_PERIOD = 'THREE_MONTHS_PERIOD',
  SIX_MONTHS_PERIOD = 'SIX_MONTHS_PERIOD',
  YEARLY = 'YEARLY'
}

export enum ActivityStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}
