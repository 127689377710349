import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { getSession, getAdminSession, getPartnerSession, getApplicationAdminSession } from 'app/shared/reducers/authentication';
import { RouteComponentProps } from 'react-router';

export interface IHomeProp extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export class ApplicationDashboard extends React.Component<IHomeProp> {
  render() {
    return (
      <div id="content">
        <div className={'header'}>
          <div className={'home-banner-mask home-banner'}>
            <img src="content/images/home/home-screen-banner.png" alt="banner" className={'home-banner-img'} />
          </div>
          <div className={'container'}>
            <div className={'home-title-banner'}>
              <label>
                <Translate contentKey="applicationDashboard.labels.welcome" />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated
});

const mapDispatchToProps = { getSession, getAdminSession, getPartnerSession, getApplicationAdminSession };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationDashboard);
