import { ICustomer } from './customer/customer';
import { formatTimestamp } from '../util/date-utils';
import { IActivityCategory } from './activity-category';
import { ActivityPlan } from './activity-plan';
import { IActivitySubCategory } from './activity-sub-category';
import { IBilling } from './billing/billing';
import { CustomerActivityStatus } from 'app/shared/model/enum/CustomerActivityStatus';
import { PresetPaymentMethodsToCharge } from './preset-payment-methods-to-charge';

export interface ICustomerActivityPlan {
  id?: number;
  token?: string;
  price?: number;
  hideToken?: boolean;
  customer?: ICustomer;
  autoRenewal?: boolean;
  status?: CustomerActivityStatus;
  activityPlan?: ActivityPlan;
  startPeriodDate?: string;
  endPeriodDate?: string;
  activityCategory?: IActivityCategory;
  activitySubCategories?: IActivitySubCategory[];
  presetRenewalActivityPlan: PresetRenewalActivityPlan;
  billing?: IBilling;
}

export interface PresetRenewalActivityPlan {
  id?: number;
  zoneId?: string;
  lastRenewalDate?: string;
  activityPlan?: ActivityPlan;
  activityCategory?: IActivityCategory;
  activitySubCategory?: IActivitySubCategory;
  status?: PresetRenewalActivityPlanStatus;
  presetPaymentMethodsToCharge?: PresetPaymentMethodsToCharge;
}

export enum PresetRenewalActivityPlanStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  DISABLED_BY_CUSTOMER = 'DISABLED_BY_CUSTOMER',
  PRE_SCHEDULE_BY_PARTNER = 'PRE_SCHEDULE_BY_PARTNER',
  PRESET_PAYMENT_METHOD_MISSING = 'PRESET_PAYMENT_METHOD_MISSING'
}

export class ICustomerActivityPlan {

  static getTitle(item: ICustomerActivityPlan) {
    const name = item?.activityPlan?.name;
    return name ?? '';
  }

  static getPeriodDates(item: ICustomerActivityPlan) {
    const start = item?.startPeriodDate;
    const end = item?.endPeriodDate;
    if (start == null || end == null) {
      return ['', ''];
    }
    return [
      formatTimestamp(start),
      formatTimestamp(end)
    ];
  }

  static getPartnerName(item: ICustomerActivityPlan) {
    return item?.activityPlan?.partnerPlace?.fantasyName ?? '';
  }

  static isApproved(item: ICustomerActivityPlan) {
    return item?.status === CustomerActivityStatus.APPROVED;
  }

  static getThumbnail(item: ICustomerActivityPlan) {
    return item.activityPlan?.partnerPlace?.image?.imageUrl ??
      './content/images/default-customer-activity.png';
  }
}
