import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './edit-activity-step2.css';
import { hideModal, showModal } from '../../../../components/modal/modal-action';
import { IDayOfWeek } from 'app/shared/model/day-of-week';
import moment from 'moment';
import { ITimeSlot, ITimeSlotItem } from 'app/shared/model/time-slots';
import classnames from 'classnames';
import Button from 'app/components/button/Button';
import { IPresetActivitySchedule } from 'app/shared/model/preset-activity-schedule';
import { IActivity } from 'app/shared/model/activity';

export interface IEditActivityStep2Props extends StateProps, DispatchProps {
  object: IActivity;
  backButtonHandler: (activity: IActivity) => void;
  nextButtonHandler: (activity: IActivity) => void;
}

export interface IEditActivityStep2State {
  activity: IActivity;
  daysOfWeek: IDayOfWeek[];
  timeSlots: ITimeSlot[];
}

export class EditActivityStep2 extends React.Component<IEditActivityStep2Props, IEditActivityStep2State> {
  constructor(props) {
    super(props);

    const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

    this.state = {
      activity: props.object,
      daysOfWeek: this.mapDaysOfWeek(days),
      timeSlots: this.createTimeSlots(days, props.object)
    };
  }

  mapDaysOfWeek = (days: string[]) => {
    return Array(7)
      .fill(null)
      .map((item, index) => {
        return {
          name: translate(`editActivity.daysOfWeek.name${index + 1}`),
          value: days[index]
        };
      });
  };

  createTimeSlots = (days: string[], activity: IActivity) => {
    const startTime = moment('05:00', 'HH:mm');
    const endTime = moment('23:45', 'HH:mm');

    const timeSlots = [] as ITimeSlot[];

    while (startTime <= endTime) {
      const timeSlotItems = Array(7)
        .fill(null)
        .map((item, index) => {
          if (!!activity.presetActivitySchedules && activity.presetActivitySchedules.length > 0) {
            const enabled =
              activity.presetActivitySchedules.filter(it => {
                return it.dayOfWeek === days[index] && moment(it.startAt, 'HH:mm').format('HH:mm') === moment(startTime).format('HH:mm');
              }).length > 0;

            return {
              dayOfWeek: { value: days[index] } as IDayOfWeek,
              enabled
            };
          }

          return {
            dayOfWeek: { value: days[index] } as IDayOfWeek
          };
        });

      const timeSlot = {
        time: moment(startTime).format('HH:mm'),
        timeSlotItem: timeSlotItems,
        hasItemsEnabled: timeSlotItems.filter(it => it.enabled).length > 0
      } as ITimeSlot;

      timeSlots.push(timeSlot);

      startTime.add(15, 'minutes');
    }

    return timeSlots;
  };

  mapActivityItemContainerClassName = (item: ITimeSlot) => {
    const minute = Number(moment(item.time, 'HH:mm').format('mm'));

    if (minute === 0) {
      return classnames(
        'edit-activity-step2-calendar-activity-item-container',
        'edit-activity-step2-calendar-activity-item-container-border-top-color'
      );
    }

    return classnames('edit-activity-step2-calendar-activity-item-container');
  };

  handleItemPressed = (timeSlot: ITimeSlot, timeSlotIndex: number, timeSlotItem: ITimeSlotItem, timeSlotItemIndex: number) => {
    const newTimeSlotItem = timeSlotItem;
    newTimeSlotItem.enabled = !timeSlotItem.enabled;

    const newTimeSlot = timeSlot;
    newTimeSlot[timeSlotItemIndex] = newTimeSlotItem;

    newTimeSlot.hasItemsEnabled = newTimeSlot.timeSlotItem.filter(it => it.enabled).length > 0;
    const newSlots = this.state.timeSlots;
    newSlots[timeSlotIndex] = newTimeSlot;

    this.setState({ timeSlots: newSlots });
  };

  handleOnMouseOver = (index: number) => {
    const newTimeSlot = this.state.timeSlots[index];
    newTimeSlot.showQuicklyActionBar = true;

    const newSlots = this.state.timeSlots;
    newSlots[index] = newTimeSlot;

    this.setState({ timeSlots: newSlots });
  };

  handleOnMouseOut = (index: number) => {
    const newTimeSlot = this.state.timeSlots[index];
    newTimeSlot.showQuicklyActionBar = false;

    const newSlots = this.state.timeSlots;
    newSlots[index] = newTimeSlot;

    this.setState({ timeSlots: newSlots });
  };

  handleQuicklyActionBarPressed = (item: ITimeSlot, index: number) => {
    const newTimeSlot = item;

    const booleanToChange = newTimeSlot.timeSlotItem.filter(it => it.enabled).length > 0;

    const values = newTimeSlot.timeSlotItem.map(it => {
      it.enabled = !booleanToChange;
      return it;
    });

    newTimeSlot.timeSlotItem = values;

    newTimeSlot.hasItemsEnabled = !booleanToChange;

    const newSlots = this.state.timeSlots;
    newSlots[index] = newTimeSlot;

    this.setState({ timeSlots: newSlots });
  };

  handleNextButtonPressed = () => {
    const updatedActivity = this.updatePresetActivitySchedule();
    this.props.nextButtonHandler(updatedActivity);
  };

  handleBackButtonPressed = () => {
    const updatedActivity = this.updatePresetActivitySchedule();
    this.props.backButtonHandler(updatedActivity);
  };

  updatePresetActivitySchedule = () => {
    let presetActivitySchedules = [];

    this.state.timeSlots.filter(it => it.hasItemsEnabled).forEach(it => {
      const timeSlotItems = it.timeSlotItem.filter(timeSlotItem => timeSlotItem.enabled);

      timeSlotItems.forEach(item => {
        presetActivitySchedules.push({
          dayOfWeek: item.dayOfWeek.value,
          startAt: it.time
        } as IPresetActivitySchedule);
      });
    });

    if (presetActivitySchedules.length === 0) {
      presetActivitySchedules = undefined;
    }

    const updatedActivity = { ...this.state.activity, presetActivitySchedules };
    this.setState({ activity: updatedActivity });
    return updatedActivity;
  };
  render() {
    return (
      <div className={'edit-activity-step2-container'}>
        <label className={'edit-activity-step2-first-label'}>{translate('editActivity.labels.scheduleEditActivityStep2')}</label>

        <div className={'edit-activity-step2-calendar-container'}>
          <div className={'edit-activity-step2-calendar-days-of-week'}>
            {this.state.daysOfWeek.map((item, index) => (
              <div className={'edit-activity-step2-calendar-days-of-week-item'} key={`daysOfWeek${index}`}>
                <label className={'edit-activity-step2-calendar-days-of-week-item-label'}>{item.name}</label>
              </div>
            ))}
          </div>

          <div className={'edit-activity-step2-calendar-days-of-week-divisor'} />

          <div className={'edit-activity-step2-calendar-items-container'}>
            {this.state.timeSlots.map((item, index) => (
              <div className={'edit-activity-step2-calendar-item-container'} key={`calendarTimeSlotTime${index}`}>
                <div className={'edit-activity-step2-calendar-item-time-container'}>
                  <label className={'edit-activity-step2-calendar-item-time-label'}>{item.time}</label>
                </div>

                <div
                  className={'edit-activity-step2-calendar-item-divisor-container'}
                  onMouseLeave={() => this.handleOnMouseOut(index)}
                  onMouseOver={() => this.handleOnMouseOver(index)}
                >
                  <div className={'edit-activity-step2-calendar-item-divisor'} />

                  {item.showQuicklyActionBar && (
                    <div
                      onClick={() => this.handleQuicklyActionBarPressed(item, index)}
                      className={
                        !!!item.hasItemsEnabled
                          ? 'edit-activity-step2-calendar-item-divisor-image-container'
                          : classnames(
                              'edit-activity-step2-calendar-item-divisor-image-container',
                              'edit-activity-step2-calendar-item-divisor-image-container-remove'
                            )
                      }
                    >
                      <img
                        className={'edit-activity-step2-calendar-item-divisor-image-icon'}
                        src={!!!item.hasItemsEnabled ? 'content/images/ic-plus-grey.png' : 'content/images/ic-remove-grey.png'}
                      />
                    </div>
                  )}
                </div>

                {item.timeSlotItem.map((timeSlotItem, timeSlotItemIndex) => (
                  <div
                    onClick={() => this.handleItemPressed(item, index, timeSlotItem, timeSlotItemIndex)}
                    className={this.mapActivityItemContainerClassName(item)}
                    key={`timeSlotItem${timeSlotItemIndex}`}
                  >
                    {!!timeSlotItem.enabled && (
                      <div className={'edit-activity-step2-calendar-item'}>
                        <label className={'edit-activity-step2-calendar-item-name'}>{this.state.activity.name}</label>
                        <label className={'edit-activity-step2-calendar-item-hour'}>{item.time}</label>
                      </div>
                    )}

                    {item.showQuicklyActionBar &&
                      !item.hasItemsEnabled && (
                        <div className={classnames('edit-activity-step2-calendar-item', 'edit-activity-step2-calendar-item-less-opacity')}>
                          <label
                            className={classnames(
                              'edit-activity-step2-calendar-item-name',
                              'edit-activity-step2-calendar-item-name-less-opacity'
                            )}
                          >
                            {this.state.activity.name}
                          </label>
                          <label
                            className={classnames(
                              'edit-activity-step2-calendar-item-hour',
                              'edit-activity-step2-calendar-item-name-less-opacity'
                            )}
                          >
                            {item.time}
                          </label>
                        </div>
                      )}
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className={'edit-activity-step2-calendar-days-of-week-divisor'} />

          <div className={'edit-activity-step2-button-container'}>
            <label className={'edit-activity-step2-back-button-label'} onClick={this.handleBackButtonPressed}>
              {translate('editActivity.buttons.back')}
            </label>

            <Button onClick={this.handleNextButtonPressed} title={translate('editActivity.buttons.next')} />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = storeState => ({});

const mapDispatchToProps = { showModal, hideModal };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditActivityStep2);
