import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './partner-edit-password.css';
import InputField from 'app/components/input-field/InputField';
import { toast } from 'react-toastify';
import StringUtils from 'app/util/StringUtils';
import Button from 'app/components/button/Button';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { reset, savePartnerPassword } from 'app/entities/partner/partner-reducer';
import { IChangePassword } from 'app/shared/model/change-password';

export interface IPartnerEditPasswordProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IPartnerEditPasswordState {
  errors?: IPartnerEditPasswordError;
  changePassword?: IChangePassword;
}

export interface IPartnerEditPasswordError {
  currentPasswordError?: boolean;
  newPasswordError?: boolean;
  confirmPasswordError?: boolean;
}

export class PartnerEditPassword extends React.Component<IPartnerEditPasswordProps, IPartnerEditPasswordState> {
  constructor(props: IPartnerEditPasswordProps) {
    super(props);

    this.state = {
      errors: {},
      changePassword: {}
    };
  }

  componentWillReceiveProps(newProps: IPartnerEditPasswordProps) {
    if (newProps.savePartnerPasswordSuccess) {
      toast.success(translate('partnerEditPassword.messages.saveWithSuccess'), {
        autoClose: 2000
      });

      this.props.reset();
      this.setState({ changePassword: { confirmPassword: '', currentPassword: '', newPassword: '' } });
      return;
    }
  }

  handleBackButtonPressed = () => {
    this.props.history.replace(`/partner/partner-dashboard`);
  };

  handleConfirmButtonPressed = () => {
    if (!this.validateFields()) {
      return;
    }

    this.props.savePartnerPassword(this.state.changePassword);
  };

  validateFields = () => {
    let allFieldsValid = true;

    if (StringUtils.isStringInvalid(this.state.changePassword.currentPassword)) {
      this.setState(prevState => ({ errors: { ...prevState.errors, currentPasswordError: true } }));
      allFieldsValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.changePassword.newPassword)) {
      this.setState(prevState => ({ errors: { ...prevState.errors, newPasswordError: true } }));
      allFieldsValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.changePassword.confirmPassword)) {
      this.setState(prevState => ({ errors: { ...prevState.errors, confirmPasswordError: true } }));
      allFieldsValid = false;
    }

    if (this.state.changePassword.newPassword !== this.state.changePassword.confirmPassword) {
      this.setState(prevState => ({ errors: { ...prevState.errors, confirmPasswordError: true } }));
      allFieldsValid = false;
    }

    return allFieldsValid;
  };

  handleTextChanged = (text: string, propertyToChange: string, errorToChange?: string) => {
    if (!!errorToChange) {
      this.setState(prevState => ({
        changePassword: { ...prevState.changePassword, [propertyToChange]: text },
        errors: { ...prevState.errors, [errorToChange]: false }
      }));
      return;
    }

    this.setState(prevState => ({ changePassword: { ...prevState.changePassword, [propertyToChange]: text } }));
  };

  render() {
    return (
      <div className={'partner-edit-password-container'}>
        <label className={'partner-edit-password-header'}>{translate('partnerEditPassword.labels.header')}</label>

        <div>
          <form>
            <InputField
              onChange={event => this.handleTextChanged(event.target.value, 'currentPassword', 'currentPasswordError')}
              error={this.state.errors.currentPasswordError}
              inputClassName={'partner-edit-password-input'}
              value={!!this.state.changePassword.currentPassword ? this.state.changePassword.currentPassword : null}
              type={'password'}
              title={translate('partnerEditPassword.labels.currentPassword')}
              placeholder={'partnerEditPassword.labels.currentPassword'}
            />
          </form>
        </div>

        <div className={'mt-4'}>
          <form>
            <InputField
              onChange={event => this.handleTextChanged(event.target.value, 'newPassword', 'newPasswordError')}
              error={this.state.errors.newPasswordError}
              inputClassName={'partner-edit-password-input'}
              value={!!this.state.changePassword.newPassword ? this.state.changePassword.newPassword : null}
              type={'password'}
              title={translate('partnerEditPassword.labels.password')}
              placeholder={'partnerEditPassword.labels.password'}
            />
          </form>
        </div>

        <div className={'mt-4'}>
          <form>
            <InputField
              onChange={event => this.handleTextChanged(event.target.value, 'confirmPassword', 'confirmPasswordError')}
              error={this.state.errors.confirmPasswordError}
              inputClassName={'partner-edit-password-input'}
              value={!!this.state.changePassword.confirmPassword ? this.state.changePassword.confirmPassword : null}
              type={'password'}
              title={translate('partnerEditPassword.labels.confirmPassword')}
              placeholder={'partnerEditPassword.labels.confirmPassword'}
            />
          </form>
        </div>

        <div className={'partner-edit-password-buttons-container'}>
          <Button
            onClick={this.handleBackButtonPressed}
            outerClassName={'partner-edit-password-back-button-background-outer-class-name'}
            buttonLabelClassName={'partner-edit-password-back-button-label'}
            title={translate('partnerEditPassword.buttons.back')}
          />

          <Button onClick={this.handleConfirmButtonPressed} title={translate('partnerEditPassword.buttons.save')} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = storeState => ({
  savePartnerPasswordSuccess: storeState.partners.savePartnerPasswordSuccess
});

const mapDispatchToProps = { reset, savePartnerPassword };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PartnerEditPassword)
);
