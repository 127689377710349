import * as React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { getPaginationItemsNumber, JhiPagination, translate, Translate, getUrlParameter } from 'react-jhipster';
import { Row, Button, Col } from 'reactstrap';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { debounce } from 'lodash';
import './companies-list.scss';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { ICompany, ICompanyStatus } from 'app/shared/model/company';
import { fetchCompany, reset } from 'app/entities/company/company-redux';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputField from 'app/components/input-field/InputField';
import StringUtils from 'app/util/StringUtils';
import { postCsvFile, resetCustomer } from 'app/entities/customer/customer-redux';
import { toast } from 'react-toastify';
import { fetchPriceTable } from 'app/entities/price-table/price-table-redux';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import { IPriceTable } from 'app/shared/model/price-table';
import { IImage } from 'app/shared/model/image';

export interface ICompaniesListProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface ICompaniesListState {
  pageRequest: IPageRequest;
  companies?: ICompany[];
  companiesPage?: IPage<ICompany>;
  priceTableName?: string;
  filter: {
    status?: ICompanyStatus;
    priceTableId?: number;
    name?: string;
  };
}

export class ICompaniesList extends React.Component<ICompaniesListProps, ICompaniesListState> {
  filesInput = null;
  constructor(props) {
    super(props);
    this.state = {
      pageRequest: {
        page: 0,
        size: 20
      },
      filter: {}
    };
    this.performAction = debounce(this.performAction, 500);
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { page, size, name } = params;
    let { status } = params;
    if (!Object.values(ICompanyStatus).includes(status)) {
      status = 'ENABLED';
    }

    this.setState({
      pageRequest: { page, size },
      filter: { status, name }
    }, () => {
      this.performAction();
    });

    this.props.fetchPriceTable({ page: 0, size: 50 });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.getCompaniesList === HttpRequestStatus.SUCCESS) {
      this.setState({
        companies: newProps.page.content,
        companiesPage: newProps.page
      });
      this.props.reset();
    }

    if (newProps.postCsvFileStatus === HttpRequestStatus.SUCCESS) {
      toast.success(translate('companiesList.labels.uploadCsvComplete'), {
        autoClose: 2000
      });
      this.props.resetCustomer();
    }
  }

  private performAction = () => {
    this.props.fetchCompany(this.state.pageRequest, this.state.filter);
    const params = qs.stringify({ ...this.state.pageRequest, ...this.state.filter }, { skipNulls: true });
    this.props.history.push({ search: params });
  };

  private handleChangeFilterStatus = () => {
    const status = this.state.filter.status == ICompanyStatus.ENABLED ?
      ICompanyStatus.DISABLED :
      this.state.filter.status == ICompanyStatus.DISABLED ? undefined : ICompanyStatus.ENABLED;

    this.setState({
      pageRequest: { page: 0, size: this.state.pageRequest.size },
      filter: { ...this.state.filter, status }
    }, () => {
      this.performAction();
    });
  }

  private handlePriceTableList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name']],
      title: translate('companyManage.labels.addPriceTable'),
      action: this.props.fetchPriceTable,
      placeholderInput: translate('companyManage.labels.searchPriceTable'),
      stateAction: 'priceTable',
      cancelCallback: () => this.handlePriceTableSelected(null),
      saveCallback: (item: any) => this.handlePriceTableSelected(item)
    });
  };

  private handlePriceTableSelected = (item: IPriceTable) => {
    this.setState({
      filter: {
        ...this.state.filter,
        priceTableId: item?.id
      },
      priceTableName: item?.name
    }, () => {
      this.performAction();
    });
    this.props.hideModal();
  };

  private handlePagination = activePage => {
    const pageRequest = this.state.pageRequest;
    pageRequest.page = activePage - 1;
    this.setState({
      pageRequest
    }, () => {
      this.performAction();
    });
  };

  private handleTransformToTableContent = (content?: ICompany[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: ICompany) => {
      result.push([
        this.renderImageColumn(item?.image),
        item.name,
        item.cnpj != null && StringUtils.addCpfMask(item.cnpj),
        item?.priceTable?.name,
        StringUtils.currencyPtBr(item?.wallet?.balance),
        StringUtils.currencyPtBr(item?.wallet?.creditLimit),
        this.renderActionColumn(item)
      ]);
    });
    return result;
  };

  private handleEditCompanies = (company: ICompany | undefined) => {
    this.props.history.push({
      pathname: `companies-manage/${company.id}`,
      state: {
        company,
        editCompanies: true
      }
    });
  };

  private handleCreateCompanies = () => {
    this.props.history.push({
      pathname: 'companies-manage',
      state: {
        editCompanies: false
      }
    });
  };

  private handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    this.setState({
      filter: { ...this.state.filter, name }
    }, () => {
      this.performAction();
    });
  };

  private handleUploadCsv = (event: React.ChangeEvent<HTMLInputElement>, companyId: number, isPhones = false) => {
    const csvfile = event.target.files[0];
    if (csvfile == null) {
      return;
    }
    const formData = new FormData();
    formData.append('file', csvfile);
    this.props.postCsvFile(formData, companyId, isPhones);
  };

  private renderImageColumn = (image?: IImage): JSX.Element => {

    if (image?.imageUrl == null) {
      return <div />;
    }

    return (
      <div className={'action-column-container-image'}>
        <img src={image?.imageUrl} className={'action-column-image'} />
      </div>
    );
  }

  private renderActionColumn = (company: ICompany | undefined): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <Button className={'centered-content marginB2'} onClick={this.handleEditCompanies.bind(this, company)}>
          <FontAwesomeIcon icon={'check'} className={'marginR1'} />
          <Translate contentKey={'companiesList.buttons.edit'} />
        </Button>
        <label htmlFor={`file-input-${company.id}-phones`} className={'centered-content btn btn-secondary marginL1'}>
          {this.props.postCsvFileStatus !== HttpRequestStatus.ONGOING
            ? translate('companiesList.buttons.uploadPhonesCsv')
            : translate('companiesList.buttons.loading')}
        </label>
        <input
          className={'file-input'}
          id={`file-input-${company.id}-phones`}
          type={'file'}
          ref={input => {
            this.filesInput = input;
          }}
          onChange={event => this.handleUploadCsv(event, company.id, true)}
        />
      </div>
    );
  };

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state.companies);
    return (
      <div className={'partner-place-list-container'}>
        <div className={'table-search-container'}>
          <div onClick={this.handleCreateCompanies} className={'subcompanies-list-button-display-container'}>
            <img className={'partner-dashboard-img'} src={'content/images/ic-plus-grassgreen.png'} />
            <label className={'partner-dashboard-button-label'}>{translate('companiesList.buttons.create')}</label>
          </div>

        </div>
        <Row style={{ marginBottom: 20 }} className={'centered-content'}>
          <Col md="3" >
            <Button style={{ width: '100%' }} onClick={this.handleChangeFilterStatus}>
              <FontAwesomeIcon icon={'check'} className={'marginR1'} />
              <Translate contentKey={'companiesList.filter.status.' + this.state.filter.status} />
            </Button>
          </Col>
          <Col md="3" >
            <InputField
              title={translate('companiesList.filter.table')}
              inputClassName={'category-manage-search-input'}
              value={this.state.priceTableName ?? ''}
              placeholder={'companiesList.filter.table'}
              onClick={this.handlePriceTableList}
            />
          </Col>
          <Col md="2" />
          <Col md="4">
            <InputField
              title={translate('companiesList.placeHolders.search')}
              onChange={this.handleChanged}
              inputClassName={''}
              value={this.state.filter.name ?? ''}
              placeholder={'companiesList.placeHolders.search'}
            />
          </Col>
        </Row>
        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('companiesList.tableHeaders')}
          emptyTableMessage={this.props.getCompaniesList !== HttpRequestStatus.ONGOING ? translate('companiesList.labels.emptyList') : ''}
        />
        {tableContent.length > 0 && (
          <Row className={'justify-content-center'}>
            <JhiPagination
              items={
                this.state.companiesPage
                  ? getPaginationItemsNumber(this.state.companiesPage.totalElements, this.state.companiesPage.size)
                  : 0
              }
              activePage={this.state.companiesPage ? this.state.companiesPage.number : 0}
              onSelect={this.handlePagination}
              maxButtons={5}
            />
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  page: storeState.company.companiesPage,
  postCsvFileStatus: storeState.customer.postCsvFileStatus,
  getCompaniesList: storeState.company.getCompanies,
  priceTables: storeState.priceTable.page?.content
});

const mapDispatchToProps = {
  fetchCompany,
  postCsvFile,
  reset,
  resetCustomer,
  fetchPriceTable,
  showModal,
  hideModal
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ICompaniesList);
