import React from 'react';
import { IRootState } from '../../../../shared/reducers';
import { connect } from 'react-redux';
import './createBillingTransactionsModal.css';
import { HttpRequestStatus } from '../../../../shared/model/enum/HttpRequestStatus';
import { Modal, ModalHeader, ModalBody, Button  } from 'reactstrap';
import { TailSpin } from 'react-loader-spinner';
import StringUtils from '../../../../util/StringUtils';
import { IBilling } from '../../../../shared/model/billing/billing';
import { postBillingTransactions } from '../../../../entities/keiken-transaction/keiken-transaction';

export interface ICreateBillingTransactionsModalProps extends StateProps,
  DispatchProps {
  config: {
    billing: IBilling;
    onClose: () => void;
  };
}

const CreateBillingTransactionsModal: React.FC<ICreateBillingTransactionsModalProps> = props => {
  const { config } = props;

  const [loading, setLoading] = React.useState(false);

  const postBillingTransactionsStatus = props.postBillingTransactionsStatus;
  React.useEffect(() => {
    if (postBillingTransactionsStatus === HttpRequestStatus.SUCCESS) {
      setLoading(false);
      props?.config?.onClose();
    }
  }, [postBillingTransactionsStatus]);

  const handleSubmit = () => {
    if (!config.billing) return;

    setLoading(true);
    return props.postBillingTransactions(config.billing?.id!);
  };

  return (
    <Modal
      isOpen
      toggle={props?.config?.onClose}
      centered
    >

      <ModalHeader>
        Se a compra foi paga com pix externo e não foi criada as transações, crie aqui!
      </ModalHeader>

      <ModalBody
        className="body"
      >
        {loading ? (
          <span>
            <TailSpin height="30" width="30" color="black" />
          </span>
        ) : (
          <>
            <section>
              <div>
                <p>Nome do cliente: {config?.billing?.customer?.name ?? 'Customer não identificado'}</p>
              </div>

                <div>
                    <p>Repasse parceiro: {StringUtils.currencyPtBr(config?.billing?.partnerProfit)}</p>
                </div>

            </section>
            <div className="button-container">
              <Button
                color="primary"
                onClick={handleSubmit}
              >
                Criar
              </Button>
              <Button
                color="secondary"
                onClick={props?.config?.onClose}
              >
                Voltar
              </Button>
            </div>
          </>
        )}

      </ModalBody>

    </Modal>
  );
};

const mapStateToProps = (storaState: IRootState, ownProps: any) => ({
    postBillingTransactionsStatus: storaState.keikenTransaction.postBillingTransactionsStatus
});

const mapDispatchToProps = { postBillingTransactions };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBillingTransactionsModal);
