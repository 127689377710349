import './home.scss';

import React from 'react';
import { Storage, translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  getSession,
  getAdminSession,
  getPartnerSession,
  getApplicationAdminSession,
  getCustomerSession,
  clearAuthentication
} from 'app/shared/reducers/authentication';
import jwt_decode from 'jwt-decode';
import { AUTHORITIES } from 'app/config/constants';
import { RouteComponentProps } from 'react-router';
import './home.css';
import { toast } from 'react-toastify';
const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

export interface IHomeProp extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export class Home extends React.Component<IHomeProp> {
  componentDidMount() {
    const token = Storage.session.get(AUTH_TOKEN_KEY) || Storage.local.get(AUTH_TOKEN_KEY);
    if (!!token) {
      const decodeJwt = jwt_decode(token);
      if (decodeJwt.auth === AUTHORITIES.ADMIN) {
        this.props.getAdminSession();
        return;
      }

      if (decodeJwt.auth === AUTHORITIES.PARTNER) {
        this.props.getPartnerSession();
        // this.props.history.replace(`/partner/partner-dashboard`);
        return;
      }

      if (decodeJwt.auth === AUTHORITIES.APPLICATION_ADMIN) {
        this.props.getApplicationAdminSession();
        this.props.history.replace(`/application-admin/application-dashboard`);
        return;
      }
      // holding
      // if (decodeJwt.auth === AUTHORITIES.CUSTOMER) {
      //   this.props.getCustomerSession();
      //   this.props.history.replace(`/customer/customer-dashboard`);
      //   return;
      // }
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.redirectUrl) {
      toast.success(translate('login.messages.redirect'), {
        autoClose: 3000,
        onClose: () => {
          this.props.clearAuthentication(null);
          window.location.href = this.props.redirectUrl;
        }
      });
    } else if (newProps.isAuthenticated) {
      const token = Storage.session.get(AUTH_TOKEN_KEY);

      if (!!token) {
        const decodeJwt = jwt_decode(token);

        if (decodeJwt.auth === AUTHORITIES.PARTNER) {
          this.props.history.replace(`${this.props.location.pathname}partner/partner-dashboard`);
          return;
        }

        if (decodeJwt.auth === AUTHORITIES.APPLICATION_ADMIN) {
          this.props.history.replace(`${this.props.location.pathname}/application-admin/application-dashboard`);
          return;
        }

        // holding
        // if (decodeJwt.auth === AUTHORITIES.CUSTOMER) {
        //   this.props.history.replace(`${this.props.location.pathname}/customer/customer-dashboard`);
        //   return;
        // }
      }
    }
  }

  handlePartnerClicked = () => {
    this.props.history.push('partner-register');
  };

  render() {
    return (
      <div id="content">
        <div className={'header'}>
          <div className={'home-banner-mask home-banner'}>
            <img src="content/images/home/home-screen-banner.png" alt="banner" className={'home-banner-img'} />
          </div>
          <div className={'container'}>
            <div className={'home-title-banner'}>
              <label>
                <Translate contentKey="home.bannerTitle" />
              </label>
            </div>
            <div className={'home-text-banner'}>
              <label>
                <Translate contentKey="home.bannerText" />
              </label>
            </div>
            <button className="home-button home-select-image-button-label" onClick={this.handlePartnerClicked}>
              <label>
                <Translate contentKey="home.homePartner" />
              </label>
            </button>
          </div>
        </div>

        <div className={'home-items'}>
          <div className={'search-item'}>
            <img src="content/images/home/home-screen-search-icon.png" alt="search-icon" />
            <div className={'home-search-text'}>
              <label>
                <Translate contentKey="home.searchHeader" />
              </label>
            </div>
          </div>
          <div className={'map-item'}>
            <img src="content/images/home/home-screen-map-icon.png" alt="map-icon" />
            <div className={'home-map-text'}>
              <label>
                <Translate contentKey="home.mapHeader" />
              </label>
            </div>
          </div>
          <div className={'phone-item'}>
            <img src="content/images/home/home-screen-phone-icon.png" alt="phone-icon" />
            <div className={'home-phone-text'}>
              <label>
                <Translate contentKey="home.phoneHeader" />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
  redirectUrl: storeState.authentication.redirectUrl
});

const mapDispatchToProps = { clearAuthentication, getSession, getAdminSession, getPartnerSession, getApplicationAdminSession, getCustomerSession };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
