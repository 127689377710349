import _ from 'lodash';

class StringUtils {
  convertFlag(enabled: boolean): string | JSX.Element {
    if (enabled == true) {
      return 'ativado';
    }
    return 'desativado';
  }

  isStringInvalid(text) {
    return _.isNil(text) || text === '';
  }

  isOnlyNumber(text) {
    const regex = /^[0-9]*$/;
    return regex.test(text);
  }

  isCustomUrl(text) {
    const regex = /^[0-9A-Za-z\-]*$/g;
    return regex.test(text);
  }

  convertToPercentString(n: number | string) {
    let result = '0%';

    if (n == null || n === 0 || n === '') {
      return result;
    }

    switch (typeof n) {
      case 'string':
        result = `${n}%`;
        break;
      case 'number':
        result = `${Number(n).toFixed(2)}%`;
        break;
      default:
    }
    return result;
  }

  isEmailValid(text) {
    if (this.isStringInvalid(text)) {
      return false;
    }

    const re = /\S+@\S+\.\S+/;
    return re.test(text);
  }

  addCnpjMask(cnpj: string): string {
    return cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }

  addCpfMask(cpf: string): string {
    return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  addPhoneMask(phone: string): string {
    return phone.replace(/(\d{2})(\d)/, '($1) $2');
  }

  currencyPtBr(value?: number): string {
    if (value == null) {
      return '';
    }
    return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  currencyTwoZeros(value?: number): string {
    if (value == null) {
      return '';
    }
    return value.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  validateCpf(cpf?: string): boolean {
    const invalidCpfs: string[] = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999'
    ];

    if (cpf == null || cpf === '' || cpf.length < 11 || invalidCpfs.includes(cpf)) {
      return false;
    }

    cpf = cpf.replace(/[^\d]+/g, '');

    try {
      // Valida 1o digito
      let add = 0;
      for (let i = 0; i < 9; i++) {
        add += Number(cpf.charAt(i)) * (10 - i);
      }
      let rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) {
        rev = 0;
      }
      if (rev !== Number(cpf.charAt(9))) {
        return false;
      }
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i++) {
        add += Number(cpf.charAt(i)) * (11 - i);
      }
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) {
        rev = 0;
      }
      if (rev !== Number(cpf.charAt(10))) {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  validateCnpj(cnpj?: string): boolean {
    const invalidCnpjs: string[] = [
      '00000000000000',
      '11111111111111',
      '22222222222222',
      '33333333333333',
      '44444444444444',
      '55555555555555',
      '66666666666666',
      '77777777777777',
      '88888888888888',
      '99999999999999'
    ];

    if (cnpj == null) {
      return false;
    }

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '' || cnpj.length !== 14 || invalidCnpjs.includes(cnpj)) {
      return false;
    }

    try {
      let size = cnpj.length - 2;
      let numbers: string = cnpj.substring(0, size);
      const digits = cnpj.substring(size);
      let sum = 0;
      let pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += Number(numbers.charAt(size - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result.toString() !== digits.charAt(0)) {
        return false;
      }

      size = size + 1;
      numbers = cnpj.substring(0, size);
      sum = 0;
      pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += Number(numbers.charAt(size - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result.toString() !== digits.charAt(1)) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  }

  unmaskText = (value?: string) => {
    if (value === undefined || value === null) return '';
    return value?.replace(/\D/g, '');
  };
}

export default new StringUtils();
