import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { Navbar, Nav, DropdownItem, NavItem, Collapse } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { Brand, NavDropdown } from '../header/header-components';
import { RouteComponentProps } from 'react-router';
import { withRouter, Link } from 'react-router-dom';
import './header-application.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faStar } from '@fortawesome/fontawesome-free-solid';
import { faCalendarCheck, faEdit, faMoneyBillAlt, faAddressBook } from '@fortawesome/fontawesome-free-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IHeaderApplicationAdminProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IHeaderApplicationAdminState {
  menuOpen: boolean;
}

export class HeaderApplicationAdmin extends React.Component<IHeaderApplicationAdminProps, IHeaderApplicationAdminState> {
  private renderApplicationAdminMenus = () => {
    return (
      <div>
        <DropdownItem tag={Link} to={'/application-admin/customers'}>
          <FontAwesomeIcon icon={faAddressBook as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.customers'}>Customer List</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/price-table'}>
          <FontAwesomeIcon icon={faMoneyBillAlt as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.priceTable'}>Price Table list</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/billings'}>
          <FontAwesomeIcon icon={faMoneyBillAlt as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.billings'}>Billings List</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/partner-place-list'}>
          <FontAwesomeIcon icon={'book'} /> <Translate contentKey={'application-admin.header.menuItems.partners'}>Partners</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/activities-manage'}>
          <FontAwesomeIcon icon={faList as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.activities'}>Atividades aprovadas</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/approve-activities'}>
          <FontAwesomeIcon icon={'check'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.approveActivities'}>Approve Activities</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/approve-customer-activities'}>
          <FontAwesomeIcon icon={'check'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.approveCustomerActivities'}>Approve Activities</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/approved-customer-activities'}>
          <FontAwesomeIcon icon={'check'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.approvedCustomerActivities'}>Approve Activities</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/categories-list'}>
          <FontAwesomeIcon icon={'book'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.categoriesList'}>Category List</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/subcategories-list'}>
          <FontAwesomeIcon icon={'book'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.subcategoriesList'}>SubCategory List</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/companies-list'}>
          <FontAwesomeIcon icon={'book'} /> <Translate contentKey={'application-admin.header.menuItems.companies'}>Companies</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/faq-list'}>
          <FontAwesomeIcon icon={'book'} /> <Translate contentKey={'application-admin.header.menuItems.faqs'}>faqs</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/schedule-manage'}>
          <FontAwesomeIcon icon={faCalendarCheck as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.scheduleManage'}>Schedule Manage</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/schedule-manage/edit'}>
          <FontAwesomeIcon icon={faEdit as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.editScheduleManage'}>Edit Schedule</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/renewal-activity-schedule'}>
          <FontAwesomeIcon icon={faList as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.renewalActivitySchedule'}>Renewal List</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/keiken-pay-invoice'}>
          <FontAwesomeIcon icon={faList as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.keikenPay'}>Keiken Pay list</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/keiken-pay-partner-external'}>
          <FontAwesomeIcon icon={'book'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.keikenPayPartnerExternal'}>Partner External</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/activity-plan'}>
          <FontAwesomeIcon icon={faList as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.activityPlan'}>Activity Plan list</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/assessments'}>
          <FontAwesomeIcon icon={faStar as IconProp} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.assessments'}>Assessments list</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/conferences'}>
          <FontAwesomeIcon icon={'video'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.conferences'}>Conferências</Translate>
        </DropdownItem>
        <DropdownItem tag={Link} to={'/application-admin/partnersPayment'}>
          <FontAwesomeIcon icon={'money-bill'} />{' '}
          <Translate contentKey={'application-admin.header.menuItems.partnersPayment'}>Repasse Parceiros</Translate>
        </DropdownItem>
      </div>
    );
  };

  private handleLogout = () => {
    this.props.history.replace('/logout');
  };

  render() {
    return (
      <div id={'app-header'}>
        <LoadingBar className={'loading-bar'} />
        <Navbar dark expand={'sm'} fixed={'top'} className={'jh-navbar'}>
          <Brand />
          <Collapse navbar>
            <Nav id={'header-tabs'} className={'ml-auto-header'} navbar>
              <div className={'marginT3'}>
                <NavDropdown icon={'list'} name={translate('application-admin.header.labels.menu')} id={'admin-menu'}>
                  {this.renderApplicationAdminMenus()}
                </NavDropdown>
              </div>
              <NavItem className={'text-enter'}>
                <label className={'application-logout-label'} onClick={this.handleLogout}>
                  <Translate contentKey={'application-admin.header.labels.logout'} />
                </label>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(HeaderApplicationAdmin);
