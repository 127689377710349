import React, { ChangeEvent } from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { createPartner, reset } from './partner-register.reducer';
import './partner-register.scss';
import InputField from 'app/components/input-field/InputField';
import { IPartnerRegister } from 'app/shared/model/partner.register';
import { PhoneType } from 'app/shared/model/phone.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { fetchAddresses } from 'app/entities/address/address-reducer';
import StringUtils from 'app/util/StringUtils';
import { IPartnerCreate } from 'app/shared/model/partner';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import Button from 'app/components/button/Button';
import { IAddress, IAddressUtils } from 'app/shared/model/address.model';
import moment from 'moment';

export interface IPartnerRegisterProps extends StateProps, DispatchProps, RouteComponentProps { }

export interface IPartnerRegisterState {
  fields: IPartnerRegister;
  valids: IPartnerRegisterValids;
  errors: IPartnerRegisterFieldsError;
  idNumberMustBeCnpjMask: boolean;
  fullAddress: string;
  idNumberMask: string;
}

export interface IPartnerRegisterValids {
  nameValid?: boolean;
  emailValid?: boolean;
  passwordValid?: boolean;
  confirmPasswordValid?: boolean;
  birthDateValid?: boolean;
  fantasyNameValid?: boolean;
  descriptionValid?: boolean;
  numberValid?: boolean;
  addressValid?: boolean;
  identificationNumberValid?: boolean;
  cpfValid?: boolean;
}

export interface IPartnerRegisterFieldsError {
  nameError?: boolean;
  emailError?: boolean;
  passwordError?: boolean;
  confirmPasswordError?: boolean;
  birthDateError?: boolean;
  fantasyNameError?: boolean;
  descriptionError?: boolean;
  numberError?: boolean;
  addressError?: boolean;
  identificationNumberError?: boolean;
  cpfError?: boolean;
}

export class PartnerRegisterScreen extends React.Component<IPartnerRegisterProps, IPartnerRegisterState> {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        fantasyName: '',
        socialReason: '',
        description: '',
        identificationNumber: '',
        cref: '',
        phone: {
          number: '',
          type: PhoneType.PROFESSIONAL
        },
        address: {
          street: '',
          number: '',
          zipcode: '',
          district: '',
          complement: ''
        }
      },
      fullAddress: '',
      errors: {},
      valids: {},
      idNumberMustBeCnpjMask: false,
      idNumberMask: '99.999.999/9999-99'
    };
  }

  componentWillUnmount() { }

  componentWillReceiveProps(newProps) {
    if (newProps.registrationSuccess) {
      toast.success(translate('partner.register.messages.success'), {
        onClose: this.handleCloseSuccessDialog,
        autoClose: 5000
      });
    }
  }

  handleCloseSuccessDialog = () => {
    this.props.history.push('login');
  };

  handleNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    this.setState(prevState => ({
      fields: { ...prevState.fields, name },
      errors: { ...prevState.errors, nameError: false },
      valids: { ...prevState.valids, nameValid: false }
    }));
  };

  handleEmailChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    this.setState(prevState => ({
      fields: { ...prevState.fields, email },
      errors: { ...prevState.errors, emailError: false },
      valids: { ...prevState.valids, emailValid: false }
    }));
  };

  handlePasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    this.setState(prevState => ({
      fields: { ...prevState.fields, password },
      errors: { ...prevState.errors, passwordError: false },
      valids: { ...prevState.valids, passwordValid: false }
    }));
  };

  handleConfirmPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const confirmPassword = event.target.value;
    this.setState(prevState => ({
      fields: { ...prevState.fields, confirmPassword },
      errors: { ...prevState.errors, confirmPasswordError: false },
      valids: { ...prevState.valids, confirmPasswordValid: false }
    }));
  };

  handleBirthDateChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const birthDate = event.target.value;

    this.setState(prevState => ({
      fields: { ...prevState.fields, birthDate },
      errors: { ...prevState.errors, birthDateError: false },
      valids: { ...prevState.valids, birthDateValid: false }
    }));
  };

  handleFantasyNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const fantasyName = event.target.value;
    this.setState(prevState => ({
      fields: { ...prevState.fields, fantasyName },
      errors: { fantasyNameError: false },
      valids: { ...prevState.valids, fantasyNameValid: false }
    }));
  };

  handleCpfChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const cpf = event.target.value;
    this.setState(prevState => ({
      fields: { ...prevState.fields, cpf },
      errors: { ...prevState.errors, cpfError: false },
      valids: { ...prevState.valids, cpfValid: false }
    }));
  };

  handleIdentificationNumberChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const identificationNumber = event.target.value;
    this.setState(prevState => ({
      fields: { ...prevState.fields, identificationNumber },
      errors: { ...prevState.errors, identificationNumberError: false },
      valids: { ...prevState.valids, identificationNumberValid: false }
    }));
  };

  handleIdentificationNumberOnKeyPressed = () => {
    const onlyNumbersIdNumber = this.state.fields.identificationNumber.replace(/\D/g, '');

    if (onlyNumbersIdNumber.length < 10) {
      this.setState({
        idNumberMustBeCnpjMask: false,
        idNumberMask: '999.999.999-99',
        errors: { ...this.state.errors, identificationNumberError: false },
        valids: { ...this.state.valids, identificationNumberValid: false }
      });
      return;
    }

    if (onlyNumbersIdNumber.length === 10 && !this.state.idNumberMustBeCnpjMask) {
      this.setState({
        idNumberMustBeCnpjMask: true,
        errors: { ...this.state.errors, identificationNumberError: false },
        valids: { ...this.state.valids, identificationNumberValid: false }
      });
      return;
    }

    if (this.state.idNumberMustBeCnpjMask) {
      this.setState({
        idNumberMask: '99.999.999/9999-99',
        errors: { ...this.state.errors, identificationNumberError: false },
        valids: { ...this.state.valids, identificationNumberValid: false }
      });
      return;
    }
  };

  handleIdentificationNumberOnKeyDownPressed = event => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      const onlyNumbersIdNumber = this.state.fields.identificationNumber.replace(/\D/g, '');
      if (this.state.idNumberMustBeCnpjMask && onlyNumbersIdNumber.length === 12) {
        this.setState({ idNumberMustBeCnpjMask: false, idNumberMask: '999.999.999-99' });
        return;
      }
    }
  };

  handleSocialReasonChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const socialReason = event.target.value;
    this.setState(prevState => ({ fields: { ...prevState.fields, socialReason } }));
  };

  handleDescriptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const description = event.target.value;
    this.setState(prevState => ({ fields: { ...prevState.fields, description } }));
  };

  handleCrefChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const cref = event.target.value;
    this.setState(prevState => ({
      fields: { ...prevState.fields, cref }
    }));
  };

  handlePhoneNumberChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const phone = event.target.value;
    this.setState(prevState => ({
      fields: { ...prevState.fields, phone: { ...prevState.fields.phone, number: phone } },
      errors: { numberError: false },
      valids: { ...prevState.valids, numberValid: false }
    }));
  };

  handleOnPhoneTypeChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    const phoneType = Object.keys(PhoneType).find(key => PhoneType[key] === event.target.value) as PhoneType;
    this.setState(prevState => ({ fields: { ...prevState.fields, phone: { ...prevState.fields.phone, type: phoneType } } }));
  };

  handleOnAddressPressed = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['street'], ['number'], ['district'], ['city'], ['zipcode']],
      title: translate('partner.register.labels.modal.address'),
      waitToPerformRequest: true,
      action: this.props.fetchAddresses,
      placeholderInput: translate('partner.register.placeholders.modal.address'),
      stateAction: 'addresses',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleAddressSelected(item)
    });
  };

  handleAddressSelected = (item: IAddress) => {
    const fullAddress = IAddressUtils.mapAddressToString(item);

    this.props.hideModal();

    this.setState(prevState => ({
      fields: { ...prevState.fields, address: item },
      errors: { ...prevState.errors, addressError: false },
      valids: { ...prevState.valids, addressValid: false },
      fullAddress
    }));
  };

  handleConfirmButtonPressed = () => {
    if (!this.isFieldsValid()) {
      return;
    }

    const partnerCreate: IPartnerCreate = {
      name: this.state.fields.name,
      birthDate: moment(this.state.fields.birthDate, 'DD/MM/YYYY').toDate(),
      cpf: this.state.fields.cpf.replace(/\D/g, ''),
      user: {
        email: this.state.fields.email,
        password: this.state.fields.password
      },
      partnerPlace: {
        fantasyName: this.state.fields.fantasyName,
        socialReason: this.state.fields.socialReason,
        cref: this.state.fields.cref,
        phone: this.state.fields.phone,
        address: this.state.fields.address,
        image: this.state.fields.image,
        description: this.state.fields.description,
        coverImage: this.state.fields.coverImage
      }
    };

    const onlyNumbersIdNumber = this.state.fields.identificationNumber.replace(/\D/g, '');

    if (onlyNumbersIdNumber.length <= 11) {
      partnerCreate.partnerPlace.cpf = onlyNumbersIdNumber;
      this.props.createPartner(partnerCreate);
      return;
    }

    partnerCreate.partnerPlace.cnpj = onlyNumbersIdNumber;

    this.props.createPartner(partnerCreate);
  };

  handleBackButtonPressed = () => {
    this.props.history.goBack();
  };

  handleImageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const newImage = this.state.fields.image || {};
      const contentType = selectedFile.type;
      newImage.contentType = contentType;
      newImage.fileName = selectedFile.name;
      newImage.file = ev.target.result.split(',')[1];
      this.setState(prevState => ({ fields: { ...prevState.fields, image: newImage } }));
    };

    reader.readAsDataURL(selectedFile);
  };

  handleCoverImageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const newImage = this.state.fields.coverImage || {};
      const contentType = selectedFile.type;
      newImage.contentType = contentType;
      newImage.fileName = selectedFile.name;
      newImage.file = ev.target.result.split(',')[1];
      this.setState(prevState => ({ fields: { ...prevState.fields, coverImage: newImage } }));
    };

    reader.readAsDataURL(selectedFile);
  };

  isFieldsValid = () => {
    let isAllFieldsValid = true;

    const fieldValids: IPartnerRegisterValids = {
      nameValid: true,
      emailValid: true,
      passwordValid: true,
      confirmPasswordValid: true,
      fantasyNameValid: true,
      descriptionValid: true,
      numberValid: true,
      addressValid: true,
      identificationNumberValid: true,
      birthDateValid: true,
      cpfValid: true
    };

    if (StringUtils.isStringInvalid(this.state.fields.name)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, nameError: true } }));
      fieldValids.nameValid = false;
    }

    if (!StringUtils.isEmailValid(this.state.fields.email) || StringUtils.isStringInvalid(this.state.fields.email)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, emailError: true } }));
      fieldValids.emailValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.fields.password) || this.state.fields.password.length < 3) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, passwordError: true } }));
      fieldValids.passwordValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.fields.confirmPassword) || this.state.fields.confirmPassword.length < 3) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, confirmPasswordError: true } }));
      fieldValids.confirmPasswordValid = false;
    }

    if (
      !this.state.errors.passwordError &&
      !this.state.errors.confirmPasswordError &&
      this.state.fields.password !== this.state.fields.confirmPassword
    ) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, confirmPasswordError: true } }));
    }

    if (StringUtils.isStringInvalid(this.state.fields.fantasyName)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, fantasyNameError: true } }));
      fieldValids.fantasyNameValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.fields.description)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, descriptionError: true } }));
      fieldValids.descriptionValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.fields.phone.number)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, numberError: true } }));
      fieldValids.numberValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.fields.address.zipcode)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, addressError: true } }));
      fieldValids.addressValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.fields.address.street)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, addressError: true } }));
      fieldValids.addressValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.fields.address.number) || !StringUtils.isOnlyNumber(this.state.fields.address.number)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, addressError: true } }));
      fieldValids.addressValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.fields.identificationNumber)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, identificationNumberError: true } }));
      fieldValids.identificationNumberValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.fields.cpf)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, cpfError: true } }));
      fieldValids.cpfValid = false;
    }

    // if (StringUtils.isStringInvalid(this.state.fields.address.district)) {
    //   isAllFieldsValid = false;
    //   this.setState(prevState => ({ errors: { ...prevState.errors, addressError: true } }));
    //   fieldValids.addressValid = false;
    // }

    if (!!!this.state.fields.address.city) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, addressError: true } }));
      fieldValids.addressValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.fields.birthDate) || !moment(this.state.fields.birthDate, 'DD/MM/YYYY').isValid()) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, birthDateError: true } }));
      fieldValids.birthDateValid = false;
    }

    this.setState({ valids: fieldValids });

    return isAllFieldsValid;
  };

  render() {
    return (
      <div className={'partner-register-center'}>
        <label className={'partner-register-header-label'}>
          <Translate contentKey="partner.register.labels.user" />
        </label>

        <div className={'partner-register-input-divisor'}>
          <InputField
            title={translate('partner.register.labels.name')}
            onChange={this.handleNameChanged}
            valid={this.state.valids.nameValid}
            inputClassName={'partner-register-input'}
            error={this.state.errors.nameError}
            value={this.state.fields.name}
            placeholder={'partner.register.placeholders.name'}
          />
        </div>

        <div className={'partner-register-input-divisor'}>
          <InputField
            title={translate('partner.register.labels.email')}
            onChange={this.handleEmailChanged}
            error={this.state.errors.emailError}
            valid={this.state.valids.emailValid}
            inputClassName={'partner-register-input'}
            value={this.state.fields.email}
            placeholder={'partner.register.placeholders.email'}
          />
        </div>

        <div className={'partner-register-input-divisor'}>
          <InputField
            title={translate('partner.register.labels.password')}
            onChange={this.handlePasswordChanged}
            error={this.state.errors.passwordError}
            valid={this.state.valids.passwordValid}
            inputClassName={'partner-register-input'}
            value={this.state.fields.password}
            type={'password'}
            placeholder={'partner.register.placeholders.password'}
          />
        </div>

        <div className={'partner-register-input-divisor'}>
          <InputField
            title={translate('partner.register.labels.confirmPassword')}
            error={this.state.errors.confirmPasswordError}
            valid={this.state.valids.confirmPasswordValid}
            onChange={this.handleConfirmPasswordChanged}
            inputClassName={'partner-register-input'}
            value={this.state.fields.confirmPassword}
            type={'password'}
            placeholder={'partner.register.placeholders.confirmPassword'}
          />
        </div>

        <div className={'partner-register-input-divisor'}>
          <InputField
            onChange={this.handleCpfChanged}
            inputClassName={'partner-register-input'}
            title={translate('partner.register.labels.cpf')}
            value={this.state.fields.cpf}
            error={this.state.errors.cpfError}
            valid={this.state.valids.cpfValid}
            placeholder={'partner.register.placeholders.cpf'}
            maskFormat={'999.999.999-99'}
            isMaskRequired
          />
        </div>

        <div className={'partner-register-input-divisor'}>
          <InputField
            title={translate('partner.register.labels.birthDate')}
            error={this.state.errors.birthDateError}
            valid={this.state.valids.birthDateValid}
            onChange={this.handleBirthDateChanged}
            inputClassName={'partner-register-input'}
            value={this.state.fields.birthDate}
            placeholder={'partner.register.placeholders.birthDate'}
            maskFormat={'99/99/9999'}
            isMaskRequired
          />
        </div>

        <label className={'partner-register-header-label'}>
          <Translate contentKey="partner.register.labels.partnerPlace" />
        </label>

        <label className={'partner-register-h2-label'}>
          <Translate contentKey={'partner.register.labels.identify'} />
        </label>

        <div className={'partner-register-input-divisor-horizontal-container'}>
          <div className={'partner-register-input-divisor-horizontal-higher'}>
            <InputField
              title={translate('partner.register.labels.fantasyName')}
              onChange={this.handleFantasyNameChanged}
              error={this.state.errors.fantasyNameError}
              valid={this.state.valids.fantasyNameValid}
              inputClassName={'partner-register-two-inputs'}
              value={this.state.fields.fantasyName}
              placeholder={'partner.register.placeholders.fantasyName'}
            />
          </div>

          <div className={'partner-register-input-divisor-horizontal-lower'}>
            <InputField
              onChange={this.handleIdentificationNumberChanged}
              onKeyPress={this.handleIdentificationNumberOnKeyPressed}
              onKeyDownPress={this.handleIdentificationNumberOnKeyDownPressed}
              inputClassName={'partner-register-two-inputs'}
              title={translate('partner.register.labels.identificationNumber')}
              value={this.state.fields.identificationNumber}
              error={this.state.errors.identificationNumberError}
              valid={this.state.valids.identificationNumberValid}
              placeholder={'partner.register.placeholders.identificationNumber'}
              maskFormat={this.state.idNumberMask}
              isMaskRequired
            />
          </div>
        </div>

        <div className={'partner-register-input-divisor'}>
          <InputField
            title={translate('partner.register.labels.socialReason')}
            onChange={this.handleSocialReasonChanged}
            inputClassName={'partner-register-input'}
            value={this.state.fields.socialReason}
            placeholder={'partner.register.placeholders.socialReason'}
            maxLength={512}
          />
        </div>

        <div className={'partner-register-input-divisor'}>
          <div className={'partner-register-input-divisor-cref'}>
            <InputField
              title={translate('partner.register.labels.cref')}
              onChange={this.handleCrefChanged}
              inputClassName={'partner-register-input-cref'}
              value={this.state.fields.cref}
              placeholder={'partner.register.placeholders.cref'}
            />
          </div>
        </div>

        <div className={'partner-register-input-divisor'}>
          <InputField
            title={translate('partner.register.labels.description')}
            onChange={this.handleDescriptionChanged}
            inputClassName={'partner-register-input'}
            value={this.state.fields.description}
            error={this.state.errors.descriptionError}
            valid={this.state.valids.descriptionValid}
            maxLength={4000}
            isTextArea
            placeholder={'partner.register.placeholders.description'}
          />
        </div>

        <div className={'partner-register-image-container'}>
          <input
            id={'myFileCoverImageInput'}
            className={'partner-register-image-input'}
            onChange={this.handleCoverImageChanged}
            accept={'image/*'}
            type={'file'}
          />

          <Button
            onClick={() => document.getElementById('myFileCoverImageInput').click()}
            title={translate('partner.register.labels.coverImage')}
            buttonLabelClassName={'partner-register-select-image-button-label'}
            outerClassName={'partner-register-select-image-button-background-outer-class-name'}
            innerClassName={'partner-register-select-image-button-background-inner-class-name'}
          />
        </div>

        {this.state.fields?.coverImage?.file != null && (
          <div style={{ marginTop: 20 }}>
            <img src={`data:image/png;base64, ${this.state.fields.coverImage.file}`} />
          </div>
        )}

        <label className={'partner-register-h2-label'}>
          <Translate contentKey={'partner.register.labels.address'} />
        </label>

        <div className={'partner-register-input-divisor'}>
          <div className={'partner-register-inner-addon'} onClick={this.handleOnAddressPressed}>
            <InputField
              title={translate('partner.register.labels.address')}
              inputClassName={'partner-register-search-input'}
              error={this.state.errors.addressError}
              valid={this.state.valids.addressValid}
              value={!!this.state.fullAddress ? this.state.fullAddress : ''}
              placeholder={'partner.register.placeholders.address'}
              readOnly
              onClick={this.handleOnAddressPressed}
            />

            <FontAwesomeIcon
              className={
                !!!this.state.fields.address.city
                  ? 'partner-register-inner-icon'
                  : classnames('partner-register-inner-icon', 'partner-register-inner-icon-with-value')
              }
              icon="search"
            />
          </div>

          <label className={'partner-register-h2-label'}>
            <Translate contentKey={'partner.register.labels.lastDetails'} />
          </label>

          <div className={'partner-register-input-divisor-horizontal-container'}>
            <div className={'partner-register-input-divisor-horizontal-higher-without-label'}>
              <InputField
                title={translate('partner.register.labels.phone')}
                onChange={this.handlePhoneNumberChanged}
                error={this.state.errors.numberError}
                valid={this.state.valids.numberValid}
                inputClassName={'partner-register-two-inputs'}
                value={this.state.fields.phone.number}
                placeholder={'partner.register.placeholders.number'}
                maskFormat={'(99) 999999999'}
                isMaskRequired
              />
            </div>

            <div className={'partner-register-input-divisor-selector'}>
              <label className={'partner-register-input-label'}>
                <Translate contentKey={'partner.register.labels.phoneType'} />
              </label>
              <select onChange={this.handleOnPhoneTypeChanged} className={'partner-register-select'}>
                {Object.values(PhoneType).map((item, index) => (
                  <option value={item} key={index}>
                    {translate(`partner.register.options.${item}`)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className={'partner-register-image-container'}>
          <input
            id={'myFileInput'}
            className={'partner-register-image-input'}
            onChange={this.handleImageChanged}
            accept={'image/*'}
            type={'file'}
          />

          <Button
            onClick={() => document.getElementById('myFileInput').click()}
            title={translate('editActivity.buttons.addImage')}
            buttonLabelClassName={'partner-register-select-image-button-label'}
            outerClassName={'partner-register-select-image-button-background-outer-class-name'}
            innerClassName={'partner-register-select-image-button-background-inner-class-name'}
          />
        </div>

        <div className={'partner-register-image-name'}>
          <label>{!!this.state.fields.image ? this.state.fields.image.fileName : ''}</label>
        </div>

        <div className={'partner-register-buttons-container'}>
          <Button
            onClick={this.handleBackButtonPressed}
            outerClassName={'partner-register-back-button-background-outer-class-name'}
            buttonLabelClassName={'partner-register-back-button-label'}
            title={translate('newActivity.buttons.back')}
          />

          <Button onClick={this.handleConfirmButtonPressed} title={translate('partner.register.buttons.confirm')} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  currentLocale: state.locale.currentLocale,
  registrationSuccess: state.partnerRegister.registrationSuccess
});

const mapDispatchToProps = { reset, showModal, hideModal, fetchAddresses, createPartner };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerRegisterScreen);
