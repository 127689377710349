import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { getPaginationItemsNumber, JhiPagination, translate, Translate } from 'react-jhipster';
import { Row, Button } from 'reactstrap';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import './faq-list.scss';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { IFaq } from 'app/shared/model/faq';
import { fetchFaq, deleteActivityFaq, reset } from 'app/entities/faq/faq-redux';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MODAL_TYPE_WARNING } from 'app/components/modal/modal-types';
import { showModal, hideModal } from 'app/components/modal/modal-action';

export interface IFaqListProps extends StateProps, DispatchProp, RouteComponentProps<{}> { }

export interface IFaqListState {
  pageRequest: IPageRequest;
  faqs?: IFaq[];
  faqsPage?: IPage<IFaq>;
}

export class IFaqList extends React.Component<IFaqListProps, IFaqListState> {
  constructor(props) {
    super(props);
    this.state = {
      pageRequest: {
        page: 0,
        size: 20
      }
    };
  }

  componentDidMount() {
    this.props.fetchFaq(this.state.pageRequest);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.getFaqList === HttpRequestStatus.SUCCESS) {
      this.setState({
        faqs: newProps.page.content,
        faqsPage: newProps.page
      });
      this.props.reset();
    }

    if (newProps.deleteFaqList === true) {
      this.props.fetchFaq(this.state.pageRequest);
      this.props.hideModal();
      this.props.reset();
    }
  }

  private handlePagination = activePage => {
    const object = this.state.pageRequest;
    object.page = activePage - 1;
    this.setState({ pageRequest: object });
    this.props.fetchFaq(object);
  };

  private handleTransformToTableContent = (content?: IFaq[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];

    content.map((item: IFaq) => {
      result.push([item.question, item.answer, this.renderActionColumn(item)]);
    });

    return result;
  };

  private handleCreateFaq = () => {
    this.props.history.push({
      pathname: 'faq-manage'
    });
  };

  private handleEditFaq = (faq: IFaq | undefined) => {
    this.props.history.push({
      pathname: 'faq-manage',
      state: {
        faq,
        editFaq: true
      }
    });
  };

  private handleDeleteFaq = (faq: IFaq | undefined) => {
    this.props.showModal(MODAL_TYPE_WARNING, {
      title: translate('faqList.modal.message', { faqName: faq.question }),
      confirmButtonText: 'faqList.buttons.delete',
      cancelCallback: this.handleOnWarningCallbackPressed,
      confirmCallback: () => this.handleOnConfirmCallbackPressed(faq)
    });
  };

  handleOnWarningCallbackPressed = () => {
    this.props.hideModal();
  };

  handleOnConfirmCallbackPressed = (faq: IFaq | undefined) => {
    this.props.deleteActivityFaq(faq.id);
  };

  private renderActionColumn = (faq: IFaq | undefined): JSX.Element => {
    return (
      <div className={'action-column-container'}>
        <Button className={'centered-content'} onClick={this.handleEditFaq.bind(this, faq)}>
          <FontAwesomeIcon icon={'check'} className={'marginR1'} />
          <Translate contentKey={'faqList.buttons.edit'} />
        </Button>
        <Button className={'centered-content marginL2'} onClick={this.handleDeleteFaq.bind(this, faq)}>
          <FontAwesomeIcon icon={'trash'} className={'marginR1'} />
          <Translate contentKey={'faqList.buttons.delete'} />
        </Button>
      </div>
    );
  };

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state.faqs);
    return (
      <div className={'partner-place-list-container'}>
        <div className={'subfaq-place-create-container'}>
          <div onClick={this.handleCreateFaq} className={'subfaq-list-button-display-container'}>
            <img className="partner-dashboard-img" src="content/images/ic-plus-grassgreen.png" />
            <label className={'partner-dashboard-button-label'}>{translate('faqList.buttons.create')}</label>
          </div>
        </div>

        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('faqList.tableHeaders')}
          emptyTableMessage={this.props.getFaqList !== HttpRequestStatus.ONGOING ? translate('faqList.labels.emptyList') : ''}
          textOverflow
        />
        {tableContent.length > 0 && (
          <Row className={'justify-content-center'}>
            <JhiPagination
              items={this.state.faqsPage ? getPaginationItemsNumber(this.state.faqsPage.totalElements, this.state.faqsPage.size) : 0}
              onSelect={this.handlePagination}
              activePage={this.state.faqsPage ? this.state.faqsPage.number : 0}
              maxButtons={5}
            />
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storageState: IRootState) => ({
  page: storageState.faq.faqPage,
  getFaqList: storageState.faq.getFaq,
  deleteFaqList: storageState.faq.deleteFaq
});

const mapDispatchToProps = { fetchFaq, reset, deleteActivityFaq, showModal, hideModal };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProp = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IFaqList);
