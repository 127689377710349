import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
import partnerRegister, { PartnerRegisterState } from 'app/modules/account/partner-register/partner-register.reducer';
import modal, { ModalState } from 'app/components/modal/modal-reducer';
import cities, { CityState } from 'app/entities/city/city-reducer';
import activityCategories, { ActivityCategoryState } from 'app/entities/activity-category/activity-category-reducer';
import activitySubCategories, { ActivitySubCategoryState } from 'app/entities/activity-sub-category/activity-sub-category-reducer';
import activities, { IActivityState } from 'app/entities/activity/activity';
import activitiesAdmin, { IActivityAdminState } from 'app/entities/activity-admin/activityAdmin';
import partnerPlaces, { IPartnerPlaceState } from 'app/entities/partner-place/partner-place-redux';
import taxInvoices , { ITaxInvoiceState } from 'app/entities/tax-invoice/tax-invoice-redux';
import bankAccounts, { IBankAccountState } from 'app/entities/bank-account/bank-account-reducer';
import banks, { IBankState } from 'app/entities/bank/bank-reducer';
import partners, { IPartnerState } from 'app/entities/partner/partner-reducer';
import systemFee, { ISystemFeeState } from 'app/entities/system-fee/system-fee-reducer';
import addresses, { AddressState } from 'app/entities/address/address-reducer';
import customerActivity, { ICustomerActivityState } from 'app/entities/customer-activity/customer-activity-redux';
import financial, { IFinancialState } from 'app/modules/partner/financial/financial-redux';
import company, { ICompanyState } from 'app/entities/company/company-redux';
import customer, { ICustomerState } from 'app/entities/customer/customer-redux';
import faq, { IFaqState } from 'app/entities/faq/faq-redux';
import assessments, { IAssessmentState } from './../../entities/assessment/assessment.reducer';
import activitySchedule, { IActivityScheduleState } from 'app/entities/activity-schedule/activity-schedule-redux';
import renewalActivitySchedule, {
  IRenewalActivityScheduleState
} from 'app/entities/renewal-activity-schedule/renewal-activity-schedule-redux';
import keikenPay, { IKeikenPayState } from 'app/entities/keiken-pay/keiken-pay-reducer';
import priceTable, { IPriceTableState } from 'app/entities/price-table/price-table-redux';
import activityPlans, { IActivityPlanState } from 'app/entities/activity-plan/activity-plan.reducer';
import keikenPayPartnerExternal, { IKeikenPayPartnerExternalState } from 'app/entities/keiken-pay/keiken-pay-partner-external-reducer';
import economicActivity, { IEconomicActivityState } from 'app/entities/economic-activity/economic-activity-reducer';
import billing, { IBillingState } from 'app/entities/billing/billing.reducer';
import deleteAccount, { DeleteAccountState } from 'app/modules/deleteAccount/delete-account.reducer';
import customerActivityPlan, { ICustomerActivityPlanState } from 'app/entities/customer-activity-plan/customer-activity-plan-redux';
import renewalActivityPlan, { IRenewalActivityPlanState } from 'app/entities/renewal-activity-plan/renewal-activity-plan-redux';
import notifications, { NotificationsState } from '../../entities/notifications/notifications.reducer';
import companyReport, { ICompanyReportState } from 'app/entities/company-report/company-report-reducer';
import companyBilling, { ICompanyBillingState } from 'app/entities/companyBillings/company-billing-reducer';
import conference, { IConferenceState } from '../../entities/conference/conference-redux';
import { IUserState } from '../../entities/user/user-redux';
import user from '../../entities/user/user-redux';
import keikenTransaction, { KeikenTransactionState } from '../../entities/keiken-transaction/keiken-transaction';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly activitySchedule: IActivityScheduleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
  readonly partnerRegister: PartnerRegisterState;
  readonly modal: ModalState;
  readonly cities: CityState;
  readonly addresses: AddressState;
  readonly activityCategories: ActivityCategoryState;
  readonly activitySubCategories: ActivitySubCategoryState;
  readonly activities: IActivityState;
  readonly activitiesAdmin: IActivityAdminState;
  readonly activityPlans: IActivityPlanState;
  readonly partnerPlaces: IPartnerPlaceState;
  readonly taxInvoices: ITaxInvoiceState;
  readonly company: ICompanyState;
  readonly companyReport: ICompanyReportState;
  readonly companyBilling: ICompanyBillingState;
  readonly bankAccounts: IBankAccountState;
  readonly banks: IBankState;
  readonly partners: IPartnerState;
  readonly customer: ICustomerState;
  readonly customerActivity: ICustomerActivityState;
  readonly customerActivityPlan: ICustomerActivityPlanState;
  readonly systemFee: ISystemFeeState;
  readonly financial: IFinancialState;
  readonly faq: IFaqState;
  readonly renewalActivitySchedule: IRenewalActivityScheduleState;
  readonly renewalActivityPlan: IRenewalActivityPlanState;
  readonly keikenPay: IKeikenPayState;
  readonly priceTable: IPriceTableState;
  readonly keikenPayPartnerExternal: IKeikenPayPartnerExternalState;
  readonly economicActivity: IEconomicActivityState;
  readonly billing: IBillingState;
  readonly deleteAccount: DeleteAccountState;
  readonly assessments: IAssessmentState;
  readonly notifications: NotificationsState;
  readonly conference: IConferenceState;
  readonly user: IUserState;
  readonly keikenTransaction: KeikenTransactionState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  activitiesAdmin,
  activitySchedule,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  partnerRegister,
  modal,
  cities,
  addresses,
  activityCategories,
  activitySubCategories,
  activities,
  activityPlans,
  customer,
  partnerPlaces,
  taxInvoices,
  company,
  companyReport,
  companyBilling,
  bankAccounts,
  banks,
  partners,
  customerActivity,
  customerActivityPlan,
  systemFee,
  financial,
  faq,
  renewalActivitySchedule,
  renewalActivityPlan,
  keikenPay,
  priceTable,
  keikenPayPartnerExternal,
  economicActivity,
  billing,
  deleteAccount,
  assessments,
  notifications,
  conference,
  user,
  keikenTransaction
});

export default rootReducer;
