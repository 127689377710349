import { IBilling } from 'app/shared/model/billing/billing';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IPage } from 'app/shared/model/page.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';

export const ACTION_TYPES = {
  CREATE_COMPANY_EXTERNAL_BILLING: 'companyBilling/CREATE_COMPANY_EXTERNAL_BILLING',
  UPDATE_COMPANY_EXTERNAL_BILLING: 'companyBilling/UPDATE_COMPANY_EXTERNAL_BILLING',
  GET_COMPANY_EXTERNAL_BILLINGS: 'companyBilling/GET_COMPANY_EXTERNAL_BILLING',
  RESET: 'companyBilling/RESET'
};

export interface ICompanyBillingState {
  createCompanyExternalBillingStatus: HttpRequestStatus;
  updateCompanyExternalBillingStatus: HttpRequestStatus;
  getCompanyExternalBillingStatus: HttpRequestStatus;
  externalBillingsPage: IPage<IBilling>;
}

const initialState: ICompanyBillingState = {
  createCompanyExternalBillingStatus: HttpRequestStatus.NOOP,
  updateCompanyExternalBillingStatus: HttpRequestStatus.NOOP,
  getCompanyExternalBillingStatus: HttpRequestStatus.NOOP,
  externalBillingsPage: null
};

export default (state: ICompanyBillingState = initialState, action: any): ICompanyBillingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_COMPANY_EXTERNAL_BILLING):
      return { ...state, createCompanyExternalBillingStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.CREATE_COMPANY_EXTERNAL_BILLING):
      return { ...state, createCompanyExternalBillingStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.CREATE_COMPANY_EXTERNAL_BILLING):
      return { ...state, createCompanyExternalBillingStatus: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.UPDATE_COMPANY_EXTERNAL_BILLING):
      return { ...state, updateCompanyExternalBillingStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.UPDATE_COMPANY_EXTERNAL_BILLING):
      return { ...state, updateCompanyExternalBillingStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.UPDATE_COMPANY_EXTERNAL_BILLING):
      return { ...state, updateCompanyExternalBillingStatus: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.GET_COMPANY_EXTERNAL_BILLINGS):
      return { ...state, getCompanyExternalBillingStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.GET_COMPANY_EXTERNAL_BILLINGS):
      return { ...state, getCompanyExternalBillingStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.GET_COMPANY_EXTERNAL_BILLINGS):
      return { ...state, getCompanyExternalBillingStatus: HttpRequestStatus.SUCCESS, externalBillingsPage: action.payload.data };
    case ACTION_TYPES.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export const createCompanyExternalBilling = (companyId: number, billing: Partial<IBilling>) => ({
  type: ACTION_TYPES.CREATE_COMPANY_EXTERNAL_BILLING,
  payload: axios.post(`/api/companies/${companyId}/billing-external/`, billing)
});

export const updateCompanyExternalBilling = (companyId: number, billingId: number, billing: IBilling) => ({
  type: ACTION_TYPES.UPDATE_COMPANY_EXTERNAL_BILLING,
  payload: axios.put(`/api/companies/${companyId}/billing-external/${billingId}`, billing)
});

export const getCompanyExternalBillings = (companyId: number, page: number, size: number) => ({
  type: ACTION_TYPES.GET_COMPANY_EXTERNAL_BILLINGS,
  payload: axios.get(`/api/companies/${companyId}/billing-external?page=${page}&size=${size}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
