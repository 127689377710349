import React from 'react';
import { KeikenPayInvoice } from '../../../../../shared/model/KeikenPay/KeikenPayInvoice';
import { RefundAccountType } from '../../../../../shared/model/KeikenPay/RefundAccountType';
import { translate } from 'react-jhipster';
import './menus.scss';

interface Props {

    data: KeikenPayInvoice;

}

export const Client = ({ data }: Props) => {

    return(
        <>
            <div className="details-container">
                <div className="details-column">
                    <span>Cliente:</span>
                    <div className="details-content">{data.customer?.name}</div>
                </div>
                <div className="details-column">
                    <span>Email:</span>
                    <div className="details-content-wide">{data.customer?.user?.email}</div>
                </div>
                {data.refundAccount?.type === RefundAccountType.PIX && (
                    <div className="details-column">
                        <span>Pix:</span>
                        <div className="details-content">{data.refundAccount.pixKey}</div>
                    </div>
                )}
            </div>
            <div className="details-container">
                <div className="details-column">
                    <span>Telefone:</span>
                    <div className="details-content">{data.customer?.phones && data.customer?.phones[0].number}</div>
                </div>
                <div className="details-column">
                    <span>Empresa:</span>
                    <div className="details-content-wide">{data.customer?.company?.name}</div>
                </div>
            </div>
            {data.refundAccount?.type !== RefundAccountType.PIX && (
                <div className="details-container">
                    <div className="details-column">
                        <span>Banco:</span>
                        <div className="details-content">{data.refundAccount?.bank?.id} - {data.refundAccount?.bank?.name}</div>
                    </div>
                    <div className="details-column">
                        <span>número da conta:</span>
                        <div className="details-content">{data.refundAccount?.accountNumber}</div>
                    </div>
                </div>
            )}
            {data.refundAccount?.type !== RefundAccountType.PIX && (
                <div className="details-container">
                    <div className="details-column">
                        <span>agência:</span>
                        <div className="details-content">{data.refundAccount?.agencyNumber}</div>
                    </div>
                    <div className="details-column">
                        <span>titular:</span>
                        <div className="details-content">{data.refundAccount?.holderName}</div>
                    </div>
                </div>
            )}
            {data.refundAccount?.type !== RefundAccountType.PIX && (
                <div className="details-container">
                    <div className="details-column">
                        <span>cpf do titular:</span>
                        <div className="details-content">{data.refundAccount?.agencyNumber}</div>
                    </div>
                    <div className="details-column">
                        <span>conta:</span>
                        <div className="details-content">{`conta ${translate('keikenPayList.customerInfo.refund.' + data?.refundAccount?.type)}`}</div>
                    </div>
                </div>
            )}
        </>
    );
};
