import { IKeikenBankAccount } from './keiken-bank-accounts';

export interface IKeikenTransactionLog {
  id?: number;
  event?: string;
  date?: string;
  keikenTransaction?: IKeikenTransaction;
}

export interface IKeikenTransaction {
  id?: number;
  value?: number;
  createDate?: string;
  scheduledDate?: string;
  processDate?: string;
  inputExternalPixName?: string;
  inputExternalPixDocument?: string;
  status?: KeikenTransactionStatus;
  type?: KeikenTransactionType;
  inputAccount?: IKeikenBankAccount;
  outputAccount?: IKeikenBankAccount;
  keikenTransactionLogs?: IKeikenTransactionLog[];
  billing: { id: number };
}

export class IKeikenTransactionUtils {
  static compareByType = (a: IKeikenTransaction, b: IKeikenTransaction) => {
    return KeikenTransactionTypeOrderPriority[a.type] - KeikenTransactionTypeOrderPriority[b.type];
  };
}

export enum KeikenTransactionStatus {
  CREATED = 'CREATED',
  SCHEDULED = 'SCHEDULED',
  CANCELED = 'CANCELED',
  PAID = 'PAID',
  REVERTED = 'REVERTED',
  DENIED = 'DENIED',
  WAITING = 'WAITING',
  WAITING_INTER_APPROVAL = 'WAITING_INTER_APPROVAL'
}

export enum KeikenTransactionType {
  COMPANY_INPUT = 'COMPANY_INPUT',
  PAYROLL_DISCOUNT = 'PAYROLL_DISCOUNT',
  CO_PARTICIPATION = 'CO_PARTICIPATION',
  KEIKEN_EXTERNAL = 'KEIKEN_EXTERNAL',
  KEIKEN_CUSTOMER = 'KEIKEN_CUSTOMER',
  KEIKEN_REVENUE = 'KEIKEN_REVENUE',
  PARTNER_PROFIT = 'PARTNER_PROFIT',
  PARTNER_WITHDRAW = 'PARTNER_WITHDRAW',
  PARTNER_EXTERNAL = 'PARTNER_EXTERNAL',
  CUSTOMER_EXTERNAL = 'CUSTOMER_EXTERNAL'
}

export enum KeikenTransactionTypeOrderPriority {
  CO_PARTICIPATION = 0,
  KEIKEN_EXTERNAL = 1,
  KEIKEN_CUSTOMER = 5,
  PAYROLL_DISCOUNT = 6,
  KEIKEN_REVENUE = 10,
  PARTNER_PROFIT = 11,
  CUSTOMER_EXTERNAL = 20,
  COMPANY_INPUT = 21,
  PARTNER_WITHDRAW = 22,
  PARTNER_EXTERNAL = 23
}
