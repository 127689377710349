import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { IPriceTable } from 'app/shared/model/price-table';

export const ACTION_TYPES = {
  FETCH_PRICE_TABLES: 'priceTable/FETCH_PRICE_TABLES',
  GET_PRICE_TABLE: 'priceTable/GET_PRICE_TABLE',
  GET_PRICE_TABLE_ACTIVITIES_LIST: 'priceTable/GET_PRICE_TABLE_ACTIVITIES_LIST',
  CREATE_PRICE_TABLE: 'priceTable/CREATE_PRICE_TABLE',
  UPDATE_PRICE_TABLE: 'priceTable/UPDATE_PRICE_TABLE',
  DELETE_PRICE_TABLE: 'priceTable/DELETE_PRICE_TABLE',
  RESET: 'priceTable/RESET'
};

const initialState = {
  page: null,
  priceTableActivitiesList: null,
  priceTable: null,
  getPriceTableStatus: HttpRequestStatus.NOOP,
  fetchPriceTableStatus: HttpRequestStatus.NOOP,
  updatePriceTableStatus: HttpRequestStatus.NOOP,
  createPriceTableStatus: HttpRequestStatus.NOOP,
  deletePriceTableStatus: HttpRequestStatus.NOOP,
  fetchPriceTableActivitiesStatus: HttpRequestStatus.NOOP
};

export type IPriceTableState = Readonly<typeof initialState>;

// Reducer
export default (state: IPriceTableState = initialState, action: IActionForResponseEntity<any>): IPriceTableState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PRICE_TABLES):
      return {
        ...state,
        fetchPriceTableStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_PRICE_TABLES):
      return {
        ...initialState,
        fetchPriceTableStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_PRICE_TABLES):
      return {
        ...initialState,
        page: action.payload.data,
        fetchPriceTableStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.GET_PRICE_TABLE_ACTIVITIES_LIST):
      return {
        ...state,
        fetchPriceTableActivitiesStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_PRICE_TABLE_ACTIVITIES_LIST):
      return {
        ...initialState,
        fetchPriceTableActivitiesStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_PRICE_TABLE_ACTIVITIES_LIST):
      return {
        ...initialState,
        priceTableActivitiesList: action.payload.data,
        fetchPriceTableActivitiesStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.GET_PRICE_TABLE):
      return {
        ...state,
        getPriceTableStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_PRICE_TABLE):
      return {
        ...initialState,
        getPriceTableStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_PRICE_TABLE):
      return {
        ...initialState,
        priceTable: action.payload.data,
        getPriceTableStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.DELETE_PRICE_TABLE):
      return {
        ...state,
        deletePriceTableStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.DELETE_PRICE_TABLE):
      return {
        ...initialState,
        deletePriceTableStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.DELETE_PRICE_TABLE):
      return {
        ...initialState,
        deletePriceTableStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.CREATE_PRICE_TABLE):
      return {
        ...initialState,
        createPriceTableStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.CREATE_PRICE_TABLE):
      return {
        ...initialState,
        createPriceTableStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.CREATE_PRICE_TABLE):
      return {
        ...initialState,
        createPriceTableStatus: HttpRequestStatus.SUCCESS,
        priceTable: action.payload.data
      };
    case REQUEST(ACTION_TYPES.UPDATE_PRICE_TABLE):
      return {
        ...initialState,
        updatePriceTableStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_PRICE_TABLE):
      return {
        ...initialState,
        updatePriceTableStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PRICE_TABLE):
      return {
        ...initialState,
        updatePriceTableStatus: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/price-tables';

export const fetchPriceTable = (page?: any, predicate?: any) => {

  const params = {
    size: page?.size || 20,
    page: page?.page || 0,
    sort: 'status,desc'
  };

  return {
    type: ACTION_TYPES.FETCH_PRICE_TABLES,
    payload: axios.get(apiUrl, { params })
  };
};

export const createPriceTable = (priceTable: IPriceTable) => ({
  type: ACTION_TYPES.CREATE_PRICE_TABLE,
  payload: axios.post(apiUrl, priceTable)
});

export const updatePriceTable = (priceTable: IPriceTable) => ({
  type: ACTION_TYPES.UPDATE_PRICE_TABLE,
  payload: axios.put(apiUrl, priceTable)
});

export const deletePriceTable = (id?: Number) => ({
  type: ACTION_TYPES.DELETE_PRICE_TABLE,
  payload: axios.delete(`${apiUrl}/${id}`, {})
});

export const getPriceTableById = (id?: Number) => ({
  type: ACTION_TYPES.GET_PRICE_TABLE,
  payload: axios.get(`${apiUrl}/${id}`, {})
});

export const getPriceTableByActivity = (activityId?: number) => ({
    type: ACTION_TYPES.GET_PRICE_TABLE_ACTIVITIES_LIST,
    payload: axios.get(`/api/price-tables-activities/activity/${activityId}`)
});

export const getPriceTableByActivityPlan = (activityPlanId?: number) => ({
    type: ACTION_TYPES.GET_PRICE_TABLE_ACTIVITIES_LIST,
    payload: axios.get(`/api/price-tables-activities/activity-plan/${activityPlanId}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
