import { HttpRequestStatus } from './../../shared/model/enum/HttpRequestStatus';
import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { IPartner, IPartnerUpdate, IPartnerUpdatePerfil } from 'app/shared/model/partner';
import { IChangePassword } from 'app/shared/model/change-password';

export const ACTION_TYPES = {
  GET_PARTNER_PERFIL: 'partners/GET_PARTNER_PERFIL',
  SAVE_ADMIN_PARTNER: 'partners/SAVE_ADMIN_PARTNER',
  SAVE_PARTNER_PERFIL: 'partners/SAVE_PARTNER_PERFIL',
  SAVE_PARTNER_PASSWORD: 'partners/SAVE_PARTNER_PASSWORD',
  APPROVE_PARTNER: 'partners/APPROVE_PARTNER',
  RESET: 'partners/RESET'
};

const initialState = {
  getPartnerPerfilSuccess: false,
  getPartnerPerfilError: false,
  savePartnerPerfilSuccess: false,
  savePartnerPerfilError: false,
  savePartnerPasswordSuccess: false,
  savePartnerPasswordError: false,
  approvePartnerSuccess: false,
  approvePartnerError: false,
  partner: null,
  saveAdminPartnerStatus: HttpRequestStatus.NOOP
};

export type IPartnerState = Readonly<typeof initialState>;

// Reducer
export default (state: IPartnerState = initialState, action: IActionForResponseEntity<IPartner>): IPartnerState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_PARTNER_PERFIL):
      return {
        ...initialState
      };
    case FAILURE(ACTION_TYPES.GET_PARTNER_PERFIL):
      return {
        ...initialState,
        getPartnerPerfilError: true
      };
    case SUCCESS(ACTION_TYPES.GET_PARTNER_PERFIL):
      return {
        ...initialState,
        getPartnerPerfilSuccess: true,
        partner: action.payload.data
      };
    case REQUEST(ACTION_TYPES.APPROVE_PARTNER):
      return {
        ...initialState
      };
    case FAILURE(ACTION_TYPES.APPROVE_PARTNER):
      return {
        ...initialState,
        approvePartnerError: true
      };
    case SUCCESS(ACTION_TYPES.APPROVE_PARTNER):
      return {
        ...initialState,
        approvePartnerSuccess: true
      };
    case REQUEST(ACTION_TYPES.SAVE_PARTNER_PERFIL):
      return {
        ...initialState
      };
    case FAILURE(ACTION_TYPES.SAVE_PARTNER_PERFIL):
      return {
        ...initialState,
        savePartnerPerfilError: true
      };
    case SUCCESS(ACTION_TYPES.SAVE_PARTNER_PERFIL):
      return {
        ...initialState,
        savePartnerPerfilSuccess: true
      };
    case REQUEST(ACTION_TYPES.SAVE_ADMIN_PARTNER):
      return { ...initialState, saveAdminPartnerStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.SAVE_ADMIN_PARTNER):
      return {
        ...initialState,
        saveAdminPartnerStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.SAVE_ADMIN_PARTNER):
      return { ...initialState, saveAdminPartnerStatus: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.SAVE_PARTNER_PASSWORD):
      return {
        ...initialState
      };
    case FAILURE(ACTION_TYPES.SAVE_PARTNER_PASSWORD):
      return {
        ...initialState,
        savePartnerPasswordError: true
      };
    case SUCCESS(ACTION_TYPES.SAVE_PARTNER_PASSWORD):
      return {
        ...initialState,
        savePartnerPasswordSuccess: true
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/partners';

export const getPartnerPerfil = () => ({
  type: ACTION_TYPES.GET_PARTNER_PERFIL,
  payload: axios.get(`${apiUrl}/me`)
});

export const savePartnerPerfil = (partner: IPartnerUpdatePerfil) => ({
  type: ACTION_TYPES.SAVE_PARTNER_PERFIL,
  payload: axios.put(`${apiUrl}/perfil`, partner)
});

export const updatePartner = (partner: IPartnerUpdate) => ({
  type: ACTION_TYPES.SAVE_ADMIN_PARTNER,
  payload: axios.put(`/api/admin/partners/partner-place`, partner)
});

export const savePartnerPassword = (changePassword: IChangePassword) => ({
  type: ACTION_TYPES.SAVE_PARTNER_PASSWORD,
  payload: axios.put(`${apiUrl}/password`, changePassword)
});

export const approvePartner = (id: number) => ({
  type: ACTION_TYPES.APPROVE_PARTNER,
  payload: axios.put(`${apiUrl}/approve/${id}`, id)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
