import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './styles.scss';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { updateConferenceEndDate } from '../../../../entities/conference/conference-redux';
import { HttpRequestStatus } from '../../../../shared/model/enum/HttpRequestStatus';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';

interface IExtendConfModal extends StateProps, DispatchProps {
  extendConfModal: { open: boolean, activityScheduleId: number | null };
  setExtendConfModal: (extendConfModal: { open: boolean, activityScheduleId: number | null }) => void;
}

const ExtendConferenceModal = ({ extendConfModal, setExtendConfModal, updateEndDate, updateEndDateStatus }: IExtendConfModal) => {
  const [newConferenceEndDate, setNewConferenceEndDate] = React.useState<string>('');

  React.useEffect(() => {
    if (updateEndDateStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Operação realizada com sucesso!', { autoClose: 2500, onClose: () => window.location.reload() });
      setExtendConfModal({ open: false, activityScheduleId: null });
    }
  }, [updateEndDateStatus]);

  const validateNewConferenceDateAndupdateEndDate = (activityScheduleId: number, newEndDate: string) => {
    if (validateNewConferenceEndDate(newEndDate)) {
      updateEndDate(activityScheduleId, newEndDate);
    }
  };

  const validateNewConferenceEndDate = (newEndDate: string) => {
    if (newEndDate === '') {
      toast.error('Escolha uma data válida!');
      return false;
    }

    if (new Date(newEndDate).getTime() < new Date().getTime()) {
      toast.error('Data não pode ser no passado!');
      return false;
    }

    return true;
  };

  return (
    <div>
      <Modal
        isOpen={extendConfModal?.open}
        toogle={() => setExtendConfModal({ open: false, activityScheduleId: null })}
        className="extend-conf-modal"
      >
        <ModalHeader toggle={() => setExtendConfModal({ open: false, activityScheduleId: null })}>
          {'Estender duração da conferência'}
        </ModalHeader>
        <ModalBody
          className="modal-content"
        >
          {updateEndDateStatus === HttpRequestStatus.ONGOING ? (
            <TailSpin height="50" width="50" color="green" />
          ) : (
            <>
              <p className="titleChooseDateTime">Escolha a data e hora para a qual quer extender a duração da conferência: </p>
              <p className="warningEarlyDate">Observação: Caso a data escolhida seja antes da data de expiração atual da conferência, nenhuma alteração será feita.</p>
              <input value={newConferenceEndDate} onChange={e => setNewConferenceEndDate(e.target.value)} type="datetime-local" />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <button className="deny-extend-conf" onClick={() => setExtendConfModal({ open: false, activityScheduleId: null })}>
            Cancelar
          </button>
          <button className="accept-extend-conf" onClick={() => validateNewConferenceDateAndupdateEndDate(extendConfModal?.activityScheduleId!, newConferenceEndDate)}>
            Estender
          </button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  updateEndDateStatus: storeState.conference.updateStatus
});

const mapDispatchToProps = {
  updateEndDate: updateConferenceEndDate
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtendConferenceModal);
