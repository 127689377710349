import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { createActivityFaq, updateActivityFaq, reset } from 'app/entities/faq/faq-redux';
import { IFaq } from 'app/shared/model/faq';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import { toast } from 'react-toastify';
import InputField from 'app/components/input-field/InputField';
import CoolButton from 'app/components/button/Button';

export interface IFaqManageProps extends StateProps, DispatchProp, RouteComponentProps<{}, any, { editFaq: any; faq: any } | any> { }

export interface IFaqManageState {
  faq?: IFaq;
  buttonDisable?: boolean;
  editFaq?: boolean;
  isErrors?: { [key: string]: boolean | undefined };
  isValids?: { [key: string]: boolean | undefined };
}

export class IFaqManage extends React.Component<IFaqManageProps, IFaqManageState> {
  constructor(props) {
    super(props);
    this.state = {
      faq: {
        question: undefined,
        answer: undefined
      },
      isErrors: {
        question: undefined,
        answer: undefined
      },
      isValids: {
        question: undefined,
        answer: undefined
      }
    };
  }

  componentDidMount() {
    if (this.props.location.state != null && this.props.location.state.faq != null) {
      this.setState({
        faq: this.props.location.state.faq
      });
    }

    if (this.props.location.state != null && this.props.location.state.editFaq != null) {
      this.setState({
        editFaq: this.props.location.state.editFaq
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.createFaq === HttpRequestStatus.SUCCESS) {
      this.props.reset();
      toast.success(translate('faqManage.messages.createSuccess'), {
        onClose: () => {
          this.props.history.push('faq-list');
        },
        autoClose: 2000
      });
    }

    if (newProps.updateFaq === HttpRequestStatus.SUCCESS) {
      this.props.reset();
      toast.success(translate('faqManage.messages.editSuccess'), {
        onClose: () => {
          this.props.history.push('faq-list');
        },
        autoClose: 2000
      });
    }

    if (newProps.updateFaq === HttpRequestStatus.ERROR) {
      this.setState({
        buttonDisable: true
      });
    }
  }

  private handleChangeQuestion = (fieldKey: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    this.setState({
      faq: { ...this.state.faq, [fieldKey]: value },
      isErrors: { ...this.state.isErrors, [fieldKey]: false },
      isValids: { ...this.state.isValids, [fieldKey]: false }
    });
  };

  private handleChangedAnswer = (fieldKey: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    this.setState({
      faq: { ...this.state.faq, [fieldKey]: value },
      isErrors: { ...this.state.isErrors, [fieldKey]: false },
      isValids: { ...this.state.isValids, [fieldKey]: false }
    });
  };

  private handleCreate = () => {
    this.props.createActivityFaq(this.state.faq);
  };

  private handleUpdate = () => {
    this.props.updateActivityFaq(this.state.faq);
  };

  private handleSave = () => {
    this.setState({
      buttonDisable: true
    });

    if (this.state.editFaq) {
      this.handleUpdate();
    } else {
      this.handleCreate();
    }
  };

  render() {
    return (
      <div className={'form-container'}>
        <div className={'title-screen-container'}>
          <div onClick={() => this.props.history.push('faq-list')}>
            <FontAwesomeIcon className={'back-arrow-icon'} icon={'arrow-left'} />
          </div>
          <label className={'faq-manage-title'}>
            <Translate contentKey={this.state.editFaq ? 'faqManage.labels.editFaq' : 'faqManage.labels.createFaq'} />
          </label>
        </div>

        <div className={'input-container'}>
          <InputField
            title={translate('faqManage.placeholders.question')}
            onChange={this.handleChangeQuestion.bind(this, 'question')}
            error={this.state.isErrors['question']}
            valid={this.state.isValids['question']}
            placeholder={'faqManage.placeholders.question'}
            maxLength={300}
            isTextArea
            inputClassName={''}
            value={!!this.state.faq.question ? this.state.faq.question : ''}
          />
          <InputField
            title={translate('faqManage.placeholders.answer')}
            onChange={this.handleChangedAnswer.bind(this, 'answer')}
            error={this.state.isErrors['answer']}
            valid={this.state.isValids['answer']}
            placeholder={'faqManage.placeholders.answer'}
            maxLength={300}
            isTextArea
            inputClassName={''}
            value={!!this.state.faq.answer ? this.state.faq.answer : ''}
          />
        </div>

        <div className={'marginT15'}>
          <CoolButton
            onClick={this.handleSave}
            title={translate(this.state.editFaq ? 'faqManage.button.edit' : 'faqManage.button.create')}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  updateFaq: storeState.faq.updateFaq,
  createFaq: storeState.faq.saveFaq
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  reset,
  createActivityFaq,
  updateActivityFaq
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProp = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IFaqManage);
