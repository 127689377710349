import { IActivitySchedule } from 'app/shared/model/activity-schedule/activity-schedule';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Translate } from 'react-jhipster';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal } from 'reactstrap';
import './change-activity-schedule.css';

export interface IChangeActivityScheduleState {
  activitySchedules: IActivitySchedule[];
  cancelCallback: () => void;
  selectedActivitySchedule?: IActivitySchedule;
  customerActivityId: number;
  rescheduleActivitySchedules: (customerActivityId: number, activityScheduleId: number) => void;
}

export interface IChangeActivityScheduleProps extends RouteComponentProps<{}> {}

class ChangeActivitySchedule extends React.Component<IChangeActivityScheduleProps, IChangeActivityScheduleState> {
  constructor(props) {
    super(props);

    const activitySchedules = _.sortBy(props.config.activitySchedules, ['date']);
    this.state = {
      cancelCallback: props.config.cancelCallback,
      activitySchedules,
      customerActivityId: props.config.customerActivityId,
      rescheduleActivitySchedules: props.config.rescheduleActivitySchedules
    };
  }

  pressChangeButton = () => {
    if (this.state.customerActivityId != null && this.state.selectedActivitySchedule != null) {
      this.state.rescheduleActivitySchedules(this.state.customerActivityId, this.state.selectedActivitySchedule.id);
      this.state.cancelCallback();
    }
  };

  render() {
    return (
      <Modal isOpen className={'select-option-modal-card-style'}>
        <div className={'select-option-modal-content-container'}>
          <div>
            <label className={'select-option-modal-title-style'}>{'Alterar horários'}</label>
          </div>

          <div className={'select-option-modal-div-style'}>
            {this.state.activitySchedules.filter(it => it != null && it.date != null).map((item: any, index: number) => (
              <div className={'select-option-modal-item-container'} key={index}>
                <label
                  className={'select-option-modal-label'}
                  onClick={() =>
                    this.setState({
                      selectedActivitySchedule: item
                    })
                  }
                  style={{
                    backgroundColor:
                      this.state.selectedActivitySchedule != null && this.state.selectedActivitySchedule.id === item.id
                        ? '#24b379'
                        : 'transparent'
                  }}
                >
                  {moment(item.date).format('DD/MM/YYYY HH:mm')}
                </label>
              </div>
            ))}
          </div>
          <div className={'select-buttons-modal'}>
            <Button className={'centered-content'} color={'danger'} onClick={this.state.cancelCallback}>
              <Translate contentKey={'entity.action.back'} />
            </Button>
            <Button className={'centered-content marginL2'} color={'success'} onClick={this.pressChangeButton}>
              <Translate contentKey={'entity.action.change'} />
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ChangeActivitySchedule;
