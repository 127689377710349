import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { getCustomerActivityApproved, reset } from 'app/entities/customer-activity/customer-activity-redux';
import { ICustomerActivity } from 'app/shared/model/customer-activity.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { IRootState } from 'app/shared/reducers';
import moment from 'moment';
import * as React from 'react';
import { getPaginationItemsNumber, JhiPagination, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Row } from 'reactstrap';
import '../../home/home.css';

export interface IApprovedCustomerActivitiesProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IApprovedCustomerActivitiesState {
  pageRequest: IPageRequest;
  activities?: ICustomerActivity[];
  activityPage?: IPage<ICustomerActivity>;
}

export class ApprovedCustomerActivities extends React.Component<IApprovedCustomerActivitiesProps, IApprovedCustomerActivitiesState> {
  constructor(props) {
    super(props);
    this.state = {
      pageRequest: {
        page: 0,
        size: 20
      }
    };
  }

  componentDidMount() {
    this.props.getCustomerActivityApproved(this.state.pageRequest);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.getCustomerActivityApprovedStatus === HttpRequestStatus.SUCCESS) {
      this.setState({
        activities: newProps.approvedActivities.content,
        activityPage: newProps.approvedActivities
      });
      this.props.reset();
    }

    if (newProps.status === HttpRequestStatus.ERROR) {
      this.props.reset();
    }
  }

  private handlePagination = activePage => {
    const object = this.state.pageRequest;
    object.page = activePage - 1;
    this.setState({ pageRequest: object });
    this.props.getCustomerActivityApproved(object);
  };

  private handleTransformToTableContent = (content?: ICustomerActivity[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];

    content.map((item: ICustomerActivity) => {
      result.push([
        item.customer != null && item.customer.user != null && item.customer.user.email != null ? item.customer.user.email : '',
        item.activitySchedule != null &&
          item.activitySchedule.activity.partnerPlace != null &&
          item.activitySchedule.activity.partnerPlace.email != null
          ? item.activitySchedule.activity.partnerPlace.email
          : '',
        item.customer != null && item.customer.company != null && item.customer.company.name != null ? item.customer.company.name : '',
        item.activitySchedule != null && item.activitySchedule.date != null ? moment(item.activitySchedule.date).format('DD/MM/YYYY') : '',
        item.activitySchedule != null && item.activitySchedule.date != null ? moment(item.activitySchedule.date).format('HH:mm') : '',
        item.status != null ? translate(`approvedCustomerActivities.customerActivityStatus.${item.status}`) : ''
      ]);
    });

    return result;
  };

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state.activities);
    return (
      <div className={'partner-place-list-container'}>
        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('approvedCustomerActivities.tableHeaders')}
          emptyTableMessage={
            this.props.getCustomerActivityApprovedStatus !== HttpRequestStatus.ONGOING
              ? translate('approvedCustomerActivities.labels.emptyList')
              : ''
          }
        />
        {tableContent.length > 0 && (
          <Row className={'justify-content-center'}>
            <JhiPagination
              items={
                this.state.activityPage ? getPaginationItemsNumber(this.state.activityPage.totalElements, this.state.activityPage.size) : 0
              }
              activePage={this.state.activityPage ? this.state.activityPage.number : 0}
              onSelect={this.handlePagination}
              maxButtons={5}
            />
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  getCustomerActivityApprovedStatus: storeState.customerActivity.getCustomerActivityApprovedStatus,
  approvedActivities: storeState.customerActivity.customerActivities
});

const mapDispatchToProps = {
  getCustomerActivityApproved,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovedCustomerActivities);
