import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from '../../shared/reducers/action-type.util';
import { HttpRequestStatus } from '../../shared/model/enum/HttpRequestStatus';
import { INotification } from '../../shared/model/notification';
import { ManageCustomerActivityAdminVM } from '../../shared/model/activity';
import { IPage } from '../../shared/model/page.model';

export const ACTION_TYPES = {
  FETCH_NOTIFICATIONS: 'notifications/FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_BY_CUSTOMER_ACTIVITY_ID: 'notifications/FETCH_NOTIFICATIONS_BY_CUSTOMER_ACTIVITY_ID',
  APPROVE_CUSTOMER_ACTIVITY_SUGGESTED_BY_PARTNER: 'notifications/APPROVE_CUSTOMER_ACTIVITY_SUGGESTED_BY_PARTNER',
  RESET: 'notifications/RESET'
};

const initialState: {
  notificationsStatus: HttpRequestStatus;
  notifications: IPage<INotification> | null;
  notificationByCustomerActivityIdStatus: HttpRequestStatus;
  notificationByCustomerActivityId: IPage<INotification> | null;
  approveStatus: HttpRequestStatus;
} = {
  notificationsStatus: HttpRequestStatus.NOOP,
  notificationByCustomerActivityIdStatus: HttpRequestStatus.NOOP,
  approveStatus: HttpRequestStatus.NOOP,
  notifications: null,
  notificationByCustomerActivityId: null
};

export type NotificationsState = Readonly<typeof initialState>;

// Reducer

export default (state: NotificationsState = initialState, action): NotificationsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_NOTIFICATIONS):
      return {
        ...state,
        notificationsStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_NOTIFICATIONS):
      return {
        ...state,
        notificationsStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTIFICATIONS):
      return {
        ...state,
        notificationsStatus: HttpRequestStatus.SUCCESS,
        notifications: action.payload.data
      };
    case REQUEST(ACTION_TYPES.FETCH_NOTIFICATIONS_BY_CUSTOMER_ACTIVITY_ID):
      return {
        ...state,
        notificationByCustomerActivityIdStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_NOTIFICATIONS_BY_CUSTOMER_ACTIVITY_ID):
      return {
        ...state,
        notificationByCustomerActivityIdStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTIFICATIONS_BY_CUSTOMER_ACTIVITY_ID):
      return {
        ...state,
        notificationByCustomerActivityIdStatus: HttpRequestStatus.SUCCESS,
        notificationByCustomerActivityId: action.payload.data
      };
    case REQUEST(ACTION_TYPES.APPROVE_CUSTOMER_ACTIVITY_SUGGESTED_BY_PARTNER):
      return {
        ...state,
        approveStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.APPROVE_CUSTOMER_ACTIVITY_SUGGESTED_BY_PARTNER):
      return {
        ...state,
        approveStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.APPROVE_CUSTOMER_ACTIVITY_SUGGESTED_BY_PARTNER):
      return {
        ...state,
        approveStatus: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/notifications';

// Actions
export const getNotifications = (params = '') => ({
  type: ACTION_TYPES.FETCH_NOTIFICATIONS,
  payload: axios.get(`${apiUrl}${params ? '?' + params : ''}`)
});

export const getNotificationByCustomerActivityId = (id: number) => ({
  type: ACTION_TYPES.FETCH_NOTIFICATIONS_BY_CUSTOMER_ACTIVITY_ID,
  payload: axios.get(apiUrl + '/customer-activity/' + id)
});

export const approveCustomerActivitySuggestedByPartner = (manageCustomerActivityAdminVM: ManageCustomerActivityAdminVM) => ({
  type: ACTION_TYPES.APPROVE_CUSTOMER_ACTIVITY_SUGGESTED_BY_PARTNER,
  payload: axios.put('api/admin/customer-activity/manage-notification', manageCustomerActivityAdminVM)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
