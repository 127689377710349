import React from 'react';
import { updateKeikenPayInvoiceStatus } from '../../../../entities/keiken-pay/keiken-pay-reducer';
import { IRootState } from '../../../../shared/reducers';
import { connect } from 'react-redux';
import './approveKeikenPayModal.css';
import { KeikenPayInvoice, KeikenPayInvoiceStatus } from '../../../../shared/model/KeikenPay/KeikenPayInvoice';
import { HttpRequestStatus } from '../../../../shared/model/enum/HttpRequestStatus';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import { TailSpin } from 'react-loader-spinner';
import StringUtils from '../../../../util/StringUtils';

export interface IApproveKeikenPayModalProps extends StateProps,
  DispatchProps {
  config: {
    invoice: KeikenPayInvoice;
    onClose: () => void;
  };
}

const ApproveKeikenPayModal: React.FC<IApproveKeikenPayModalProps> = props => {
  const { config } = props;

  const [loading, setLoading] = React.useState(false);
  const [shouldPay, setShouldPay] = React.useState(true);

  const [shouldAppearCustomKeyPixInput, setShouldAppearCustomKeyPixInput] = React.useState(false);
  const [customKeyPix, setCustomKeyPix] = React.useState<string | undefined>('');

  const updateKeikenPayStatus = props.updateInvoiceStatus;
  React.useEffect(() => {
    if (updateKeikenPayStatus === HttpRequestStatus.SUCCESS) {
      setLoading(false);
      props?.config?.onClose();
    }
  }, [updateKeikenPayStatus]);

  const handleCustomPixCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShouldAppearCustomKeyPixInput(event.target.checked);
  };

  const handleShouldPayCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShouldPay(event.target.checked);
  };

  const handleSubmit = () => {
    props.updateKeikenPayInvoiceStatus(
      config?.invoice?.id!,
      KeikenPayInvoiceStatus.PAID,
      undefined,
      shouldPay,
      customKeyPix
    );
  };

  return (
    <Modal
      isOpen
      toggle={props?.config?.onClose}
      centered
    >

      <ModalHeader>
        Tem certeza que deseja aprovar este keiken pay?
      </ModalHeader>

      <ModalBody
        className="body"
      >
        {loading ? (
          <span>
            <TailSpin height="30" width="30" color="black" />
          </span>
        ) : (
          <>
            <section className="invoice-info">
              <div>
                <p>Nome do cliente: {config?.invoice?.customer?.name ?? 'Customer não identificado'}</p>
              </div>
              <div>
                <p>Valor: {StringUtils.currencyPtBr(config?.invoice?.subsidyValue) ?? 'Não identificado'}</p>
              </div>

              <div className="checkbox-container">
                <Label className="checkbox-label" check>
                  <Input
                    className="checkbox-input"
                    type="checkbox"
                    checked={shouldPay}
                    onChange={handleShouldPayCheckBoxChange}
                  />{' '}
                  Realizar pagamento
                </Label>
              </div>

              <div className="checkbox-container">
                <Label className="checkbox-label" check>
                  <Input
                    className="checkbox-input"
                    type="checkbox"
                    checked={shouldAppearCustomKeyPixInput}
                    onChange={handleCustomPixCheckboxChange}
                  />{' '}
                  Pagar em outra chave PIX
                </Label>
              </div>

              {shouldAppearCustomKeyPixInput && (
                <FormGroup style={{ marginTop: '10px' }}>
                  <Label for="customKeyPix">Chave PIX</Label>
                  <Input
                    type="text"
                    name="customKeyPix"
                    id="customKeyPix"
                    placeholder="Digite a chave PIX"
                    value={customKeyPix}
                    onChange={e => setCustomKeyPix(e.target.value)}
                  />
                </FormGroup>
              )}

            </section>
            <div className="button-container">
              <Button
                color="primary"
                onClick={handleSubmit}
              >
                Aprovar
              </Button>
              <Button
                color="secondary"
                onClick={props?.config?.onClose}
              >
                Cancelar
              </Button>
            </div>
          </>
        )}

      </ModalBody>

    </Modal>
  );
};

const mapStateToProps = (storaState: IRootState, ownProps: any) => ({
  updateInvoiceStatus: storaState.keikenPay.updateInvoiceStatus
});

const mapDispatchToProps = { updateKeikenPayInvoiceStatus };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveKeikenPayModal);
