import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './partner-bank-account.css';
import InputField from 'app/components/input-field/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideModal, showModal } from '../../../../components/modal/modal-action';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { toast } from 'react-toastify';
import StringUtils from 'app/util/StringUtils';
import classnames from 'classnames';
import Button from 'app/components/button/Button';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { BankAccountType, IBank, IBankAccount } from 'app/shared/model/bank-account';
import { getPartnerBankAccount, reset, savePartnerBankAccount } from 'app/entities/bank-account/bank-account-reducer';
import { getBanks } from 'app/entities/bank/bank-reducer';

export interface IPartnerBankAccountProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IPartnerBankAccountState {
  errors?: IPartnerBankAccountError;
  valids?: IPartnetBankAccountValid;
  bankAccount?: IBankAccount;
}

export interface IPartnerBankAccountError {
  accountOwnerError?: boolean;
  bankError?: boolean;
  agencyError?: boolean;
  numberError?: boolean;
  identificationNumberError?: boolean;
}

export interface IPartnetBankAccountValid {
  accountOwnerValid?: boolean;
  bankValid?: boolean;
  agencyValid?: boolean;
  numberValid?: boolean;
  identificationNumberValid?: boolean;
}

export class PartnerBankAccount extends React.Component<IPartnerBankAccountProps, IPartnerBankAccountState> {
  constructor(props: IPartnerBankAccountProps) {
    super(props);

    this.state = {
      bankAccount: {
        bank: {},
        owner: true,
        bankAccountType: BankAccountType.SAVING_ACCOUNT
      } as IBankAccount,
      errors: {},
      valids: {}
    };
  }

  componentDidMount() {
    this.props.getPartnerBankAccount();
  }

  componentWillReceiveProps(newProps: IPartnerBankAccountProps) {
    if (newProps.getPartnerBankAccountSuccess) {
      if (newProps.bankAccount) {
        this.setState({ bankAccount: newProps.bankAccount });
      }
      this.props.reset();
      return;
    }

    if (newProps.savePartnerBankAccountSuccess) {
      toast.success(translate('partnerPlaceBankAccount.messages.saveWithSuccess'), {
        autoClose: 2000
      });
      this.props.reset();
      this.props.getPartnerBankAccount();
      return;
    }
  }

  handleTextChanged = (text: string, propertyToChange: string, errorToChange?: string, validToChange?: string) => {
    if (!!errorToChange) {
      this.setState(prevState => ({
        bankAccount: { ...prevState.bankAccount, [propertyToChange]: text },
        errors: { ...prevState.errors, [errorToChange]: false },
        valids: { ...prevState.valids, [validToChange]: false }
      }));
      return;
    }

    this.setState(prevState => ({ bankAccount: { ...prevState.bankAccount, [propertyToChange]: text } }));
  };

  handleOnBankPressed = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      hideInput: true,
      displayProperty: [['name']],
      title: translate('partnerPlaceBankAccount.labels.bankDialogTitle'),
      placeholderInput: translate('partnerPlaceBankAccount.placeholders.bankDialog'),
      action: this.props.getBanks,
      infiniteScrolling: true,
      stateAction: 'banks',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleBankSelected(item)
    });
  };

  handleBankSelected = (item: IBank) => {
    this.setState(prevState => ({
      bankAccount: { ...prevState.bankAccount, bank: item },
      errors: { ...prevState.errors, bankError: false }
    }));

    this.props.hideModal();
  };

  handleOnBankTypeChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const bankAccountType = Object.keys(BankAccountType).find(key => BankAccountType[key] === event.target.value) as BankAccountType;
    this.setState(prevState => ({ bankAccount: { ...prevState.bankAccount, bankAccountType } }));
  };

  handleOnOwnerChanged = () => {
    this.setState(prevState => ({
      bankAccount: { ...prevState.bankAccount, owner: !this.state.bankAccount.owner, ownerIdentificationNumber: null }
    }));
  };

  handleBackButtonPressed = () => {
    this.props.history.replace(`/partner/partner-dashboard`);
  };

  handleConfirmButtonPressed = () => {
    if (!this.validateFields()) {
      return;
    }

    const bankAccount = this.state.bankAccount;

    if (!!bankAccount.ownerIdentificationNumber) {
      bankAccount.ownerIdentificationNumber = bankAccount.ownerIdentificationNumber.replace(/\D/g, '');
    }

    this.props.savePartnerBankAccount(this.state.bankAccount);
  };

  validateFields = () => {
    let allFieldsIsValid = true;

    const fieldsValid: IPartnetBankAccountValid = {
      accountOwnerValid: true,
      bankValid: true,
      agencyValid: true,
      numberValid: true,
      identificationNumberValid: true
    };

    if (StringUtils.isStringInvalid(this.state.bankAccount.accountOwner)) {
      allFieldsIsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, accountOwnerError: true } }));
      fieldsValid.accountOwnerValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.bankAccount.bank.name)) {
      allFieldsIsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, bankError: true } }));
      fieldsValid.bankValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.bankAccount.agency)) {
      allFieldsIsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, agencyError: true } }));
      fieldsValid.agencyValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.bankAccount.number)) {
      allFieldsIsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, numberError: true } }));
      fieldsValid.numberValid = false;
    }

    if (!this.state.bankAccount.owner && StringUtils.isStringInvalid(this.state.bankAccount.ownerIdentificationNumber)) {
      allFieldsIsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, identificationNumberError: true } }));
      fieldsValid.identificationNumberValid = false;
    }

    if (!!this.state.bankAccount.ownerIdentificationNumber) {
      const ownerIdentificationNumber = this.state.bankAccount.ownerIdentificationNumber.replace(/\D/g, '');
      if (ownerIdentificationNumber.length < 11) {
        this.setState(prevState => ({ errors: { ...prevState.errors, identificationNumberError: true } }));
        allFieldsIsValid = false;
        fieldsValid.identificationNumberValid = false;
      }
    }
    this.setState({ valids: fieldsValid });
    return allFieldsIsValid;
  };

  render() {
    return (
      <div className={'partner-place-bank-account-container'}>
        <label className={'partner-place-bank-account-header'}>{translate('partnerPlaceBankAccount.labels.header')}</label>

        <div className={'row row-input-container'}>
          <div className={'col-md-12'}>
            <InputField
              title={translate('partnerPlaceBankAccount.labels.accountOwner')}
              onChange={event => this.handleTextChanged(event.target.value, 'accountOwner', 'accountOwnerError', 'accountOwnerValid')}
              error={this.state.errors.accountOwnerError}
              valid={this.state.valids.accountOwnerValid}
              value={!!this.state.bankAccount.accountOwner ? this.state.bankAccount.accountOwner : null}
              inputClassName={'partner-place-bank-account-input'}
              placeholder={'partnerPlaceBankAccount.labels.accountOwner'}
            />
          </div>
        </div>

        <div className={'partner-place-bank-account-input-divisor row row-input-container'}>
          <div className={'partner-place-bank-account-inner-addon col-md-7'}>
            <InputField
              title={translate('partnerPlaceBankAccount.labels.bank')}
              inputClassName={'partner-place-bank-account-input'}
              error={this.state.errors.bankError}
              valid={this.state.valids.bankValid}
              placeholder={'partnerPlaceBankAccount.placeholders.bank'}
              value={!!this.state.bankAccount.bank.name ? this.state.bankAccount.bank.name : ''}
              onClick={this.handleOnBankPressed}
              readOnly
            />

            <FontAwesomeIcon
              className={
                !!!this.state.bankAccount.bank.name
                  ? 'partner-place-bank-account-inner-icon'
                  : classnames('partner-place-bank-account-inner-icon', 'partner-place-bank-account-inner-icon-with-value')
              }
              icon="search"
            />
          </div>

          <div className={'col-md-5'}>
            <InputField
              title={translate('partnerPlaceBankAccount.labels.agency')}
              onChange={event => this.handleTextChanged(event.target.value, 'agency', 'agencyError', 'agencyValid')}
              error={this.state.errors.agencyError}
              valid={this.state.valids.agencyValid}
              value={!!this.state.bankAccount.agency ? this.state.bankAccount.agency : null}
              inputClassName={'partner-place-bank-account-input'}
              placeholder={'partnerPlaceBankAccount.labels.agency'}
            />
          </div>
        </div>

        <div className={'partner-place-bank-account-input-divisor row row-input-container'}>
          <div className={'partner-place-bank-account-divisor-selector col-md-5'}>
            <label className={'partner-place-bank-account-input-label'}>
              <Translate contentKey={'partnerPlaceBankAccount.labels.accountType'} />
            </label>
            <select
              value={this.state.bankAccount.bankAccountType}
              onChange={this.handleOnBankTypeChanged}
              className={'partner-place-bank-account-select'}
            >
              {Object.values(BankAccountType).map((item, index) => (
                <option value={item} key={item + index}>
                  <Translate contentKey={`partnerPlaceBankAccount.options.${item}`} />
                </option>
              ))}
            </select>
          </div>

          <div className={'col-md-7'}>
            <InputField
              title={translate('partnerPlaceBankAccount.labels.accountNumber')}
              onChange={event => this.handleTextChanged(event.target.value, 'number', 'numberError', 'numberValid')}
              error={this.state.errors.numberError}
              valid={this.state.valids.numberValid}
              value={!!this.state.bankAccount.number ? this.state.bankAccount.number : null}
              inputClassName={'partner-place-bank-account-input'}
              placeholder={'partnerPlaceBankAccount.labels.accountNumber'}
            />
          </div>
        </div>

        <div className={'partner-place-bank-account-owner-container'}>
          <div className={'col-md-11 ml-4'}>
            <input onClick={this.handleOnOwnerChanged} type={'radio'} checked={!this.state.bankAccount.owner} />

            <label className={'partner-place-bank-account-owner-radio-label'}>{translate('partnerPlaceBankAccount.labels.notOwner')}</label>
          </div>

          {!this.state.bankAccount.owner && (
            <div className={'col-md-11 ml-4 pt-3'}>
              <InputField
                title={translate('partnerPlaceBankAccount.labels.identificationNumber')}
                onChange={event =>
                  this.handleTextChanged(
                    event.target.value,
                    'ownerIdentificationNumber',
                    'identificationNumberError',
                    'identificationNumberValid'
                  )
                }
                error={this.state.errors.identificationNumberError}
                valid={this.state.valids.identificationNumberValid}
                value={!!this.state.bankAccount.ownerIdentificationNumber ? this.state.bankAccount.ownerIdentificationNumber : null}
                inputClassName={'partner-place-bank-account-input'}
                placeholder={'partnerPlaceBankAccount.labels.identificationNumber'}
                maskFormat={'999.999.999-99'}
                isMaskRequired
              />
            </div>
          )}
        </div>

        <div className={'partner-register-buttons-container'}>
          <Button
            onClick={this.handleBackButtonPressed}
            outerClassName={'partner-register-back-button-background-outer-class-name'}
            buttonLabelClassName={'partner-register-back-button-label'}
            title={translate('partnerPlaceBankAccount.buttons.back')}
          />

          <Button onClick={this.handleConfirmButtonPressed} title={translate('partnerPlaceBankAccount.buttons.save')} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = storeState => ({
  bankAccount: storeState.bankAccounts.bankAccount,
  getPartnerBankAccountSuccess: storeState.bankAccounts.getPartnerBankAccountSuccess,
  savePartnerBankAccountSuccess: storeState.bankAccounts.savePartnerBankAccountSuccess
});

const mapDispatchToProps = { showModal, hideModal, getPartnerBankAccount, getBanks, savePartnerBankAccount, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PartnerBankAccount)
);
