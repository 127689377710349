import React from 'react';
import './button.css';
import classnames from 'classnames';
import { TailSpin } from 'react-loader-spinner';

export interface IButtonProps {
  innerClassName?: string;
  outerClassName?: string;
  backgroundClassName?: string;
  buttonLabelClassName?: string;
  buttonDisabled?: boolean;
  title?: string;
  variant?: 'problem' | 'warning';
  width?: string;
  onClick?: () => void;
  loading?: boolean;
}

const Button: React.FC<IButtonProps> = ({ loading = false, variant, width, onClick, buttonDisabled, outerClassName, innerClassName, buttonLabelClassName, title }) => {

  const variantClassName = variant != null ? `button-${variant}-outer avoid-select` : 'button-default-outer avoid-select';

  return (
    <div
      // @ts-ignore
      style={{ '--button-width': width ?? '180px', opacity: buttonDisabled == true ? .2 : 1 }}
      onClick={!!onClick && !buttonDisabled ? onClick : void 0}
      className={!!outerClassName ? classnames(variantClassName, outerClassName) : variantClassName}
    >
      <div
        className={!!innerClassName ? classnames('button-default-inner', innerClassName) : 'button-default-inner'}
      >
        {loading == true && (<TailSpin height="30" width="30" color="white" />)}
        {loading == false && <label
          className={
            !!buttonLabelClassName
              ? classnames('button-label-default', buttonLabelClassName)
              : 'button-label-default'
          }
        >
          {title}
        </label>}
      </div>
    </div>
  );
};

export default Button;
