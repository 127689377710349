import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { MODAL_APPROVE_KEIKEN_PAY, MODAL_DENY_KEIKEN_PAY } from 'app/components/modal/modal-types';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { getKeikenPayInvoices, reset } from 'app/entities/keiken-pay/keiken-pay-reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { KeikenPayInvoice, KeikenPayInvoiceStatus } from '../../../../app/shared/model/KeikenPay/KeikenPayInvoice';
import { RefundAccountType } from 'app/shared/model/KeikenPay/RefundAccountType';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { IRootState } from 'app/shared/reducers';
import StringUtils from 'app/util/StringUtils';
import { debounce } from 'lodash';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { getPaginationItemsNumber, translate } from 'react-jhipster';
import { TailSpin } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HeaderTable } from '../../../components/headerTable';
import { PaginationTable } from '../../../components/paginationTable';
import './keiken-pay-list.scss';
import { BillingStatus } from '../../../shared/model/billing/billing';

export interface IKeikenPayListProps extends StateProps, DispatchProp, RouteComponentProps<{}> {}

export interface IKeikenPayListState {
  pageRequest: IPageRequest;
  page?: IPage<KeikenPayInvoice>;
  updating: boolean;
  showDenyKeikenPayModal: {open: boolean, invoice: KeikenPayInvoice | undefined};
  search: {
    email?: string;
    name?: string;
    status?: KeikenPayInvoiceStatus
  };
}

export class IKeikenPayList extends React.Component<IKeikenPayListProps, IKeikenPayListState> {
  constructor(props) {
    super(props);
    this.state = {
      search: {},
      updating: false,
      showDenyKeikenPayModal: {open: false, invoice: undefined},
      pageRequest: {
        page: 0,
        size: 20
      }
    };
    this.performFilterAction = debounce(this.performFilterAction, 500);
  }

  private performFilterAction = () => {
    this.props.getKeikenPayInvoices({
      ...this.state.search,
      ...this.state.pageRequest
    });
  }
  componentDidMount() {
    this.props.getKeikenPayInvoices(this.state.pageRequest);
  }

  componentWillReceiveProps(newProps) {
    if (newProps?.page?.content != null) {
      this.setState({
        page: newProps.page
      });
      this.props.reset();
    }

    if (newProps.updateInvoiceStatus === HttpRequestStatus.SUCCESS) {
      toast.success(translate('keikenPayList.toast.update-success'));
      this.props.reset();
      this.setState({
        updating: false
      }, () => {
        this.performFilterAction();
      });
    } else if (newProps.updateInvoiceStatus === HttpRequestStatus.ERROR) {
      this.props.reset();
      this.setState({
        updating: false
      }, () => {
        this.performFilterAction();
      });
      toast.error(translate('keikenPayList.toast.update-error'));
    }

  }

  private handleOnSelectFilterStatus = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    const value = event.target.value as KeikenPayInvoiceStatus | 'NULL';
    this.setState({
      search: {
        ...this.state.search,
        status: value != 'NULL' ? value : undefined
      }
    }, () => this.performFilterAction());
  }

  private cleanFilter = () => {
    this.setState({
      search: {}
    }, () => this.performFilterAction());
  }

  private handlePagination = activePage => {
    const object = {
      ...this.state.pageRequest,
      ...this.state.search
    };
    object.page = activePage - 1;
    this.setState({ pageRequest: object });
    this.props.getKeikenPayInvoices(object);
  };

  private handleEditKeikenPayInvoice = (invoice: KeikenPayInvoice | undefined) => {
    this.props.history.push({
      pathname: `keiken-pay-invoice/${invoice?.id}`,
      state: {
        invoice,
        edit: true
      }
    });
  };

  private handleAcceptKeikenPayModal = (invoice: KeikenPayInvoice | undefined) => {
    this.props.showModal(MODAL_APPROVE_KEIKEN_PAY, {
      invoice,
      onClose: () => this.props.hideModal()
    });
  }

  private handleOpenDenyKeikenPayModal = (invoice: KeikenPayInvoice | undefined) => {
    this.props.showModal(MODAL_DENY_KEIKEN_PAY, {
      invoice,
      onClose: () => this.props.hideModal()
    });
  }

  private twoLines = (a: string, b: string) => {
    if (!b) {
      return a;
    }
    return <><>{a}</><br /><>{b}</></>;
  }

  private handleTransformToTableContent = (content?: KeikenPayInvoice[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: KeikenPayInvoice) => {
      const customerName = item.customer?.name;
      const customerEmail = item.customer?.user?.email;
      const customer = this.twoLines(customerName!, customerEmail!);
      const partnerName = item.partnerExternal?.fantasyName ?? item.partnerExternal?.name ?? item.partnerExternal?.document;
      const partnerEmail = item.partnerExternal?.email;
      const partner = this.twoLines(partnerName!, partnerEmail!);
      const refund = item?.refundAccount!.type == RefundAccountType.PIX ?
        item.refundAccount.pixKey : translate(`keikenPayList.refundAccountType.${item?.refundAccount?.type}`);
      result.push([
        // StringUtils.currencyPtBr(item.subsidyValueWithFee),
        StringUtils.currencyPtBr(item.subsidyValue),
        customer,
        partner,
        refund,
        item?.billingCoParticipation?.billing?.status === BillingStatus.WAITING_INTER_APPROVAL ? translate(`keikenPayList.keikenPayStatus.AUTHORIZED`) : translate(`keikenPayList.keikenPayStatus.${item.status}`),
        this.renderActionColumn(item)]);
    });

    return result;
  };

  private renderActionColumn = (invoice: KeikenPayInvoice | undefined): JSX.Element => {
    const disabled = invoice?.status !== KeikenPayInvoiceStatus.WAITING || this.state.updating;
    return (
      <div className={'action-column-container'} style={{display: 'flex', maxWidth: 260}}>
        <button disabled={disabled} style={{width: 80, height: 44, cursor: 'pointer', marginRight: 10, borderRadius: 8, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent', border: '2px solid #3afaad'}} onClick={this.handleAcceptKeikenPayModal.bind(this, invoice)}>
          {this.state.updating && <TailSpin color="white" height={20} width={20} />}
          {!this.state.updating && (
            <>
              <FontAwesomeIcon icon={'check'} color="#3afaad" className={'marginR1'} />
              <span style={{color: '#3afaad'}}>{translate('keikenPayList.buttons.accept')}</span>
            </>
          )}
        </button>
        <button disabled={disabled} style={{width: 80, height: 44, cursor: 'pointer', marginRight: 10, borderRadius: 8, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent', border: '2px solid #ff0015'}} onClick={this.handleOpenDenyKeikenPayModal.bind(this, invoice)}>
          {this.state.updating && <TailSpin color="white" height={20} width={20} />}
          {!this.state.updating && (
            <>
              <FontAwesomeIcon icon={'trash'} color="#ff0015" className={'marginR1'} />
              <span style={{color: '#ff0015'}}>{translate('keikenPayList.buttons.denied')}</span>
            </>
          )}
        </button>
        <button style={{width: 80, height: 44, cursor: 'pointer', borderRadius: 8, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent', border: '2px solid #00aeff'}} onClick={this.handleEditKeikenPayInvoice.bind(this, invoice)}>
          <FontAwesomeIcon icon={'edit'} color="#00aeff"/>
        </button>
      </div>
    );
  };

  private handleSearch = (fieldKey: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: string = event.target.value;
    this.setState({
      search: {
        ...this.state.search,
        [fieldKey]: value
      }
    }, () => this.performFilterAction());
  }

  render() {
    const statusOptionsFilter = [
      KeikenPayInvoiceStatus.WAITING,
      KeikenPayInvoiceStatus.IN_ANALYSIS,
      KeikenPayInvoiceStatus.PAID,
      KeikenPayInvoiceStatus.NOT_PAID,
      KeikenPayInvoiceStatus.DENIED
    ];
    const tableContent = this.handleTransformToTableContent(this.state.page?.content);
    return (
      <>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '100px'}}>
          <div style={{width: '80%', minWidth: '1300px', backgroundColor: '#fff', border: '2px solid #9cffd7', borderRadius: '10px', paddingBottom: 40}}>
            <HeaderTable
              title={'Todos os recibos'}
              valueSearch={this.state.search?.name ?? ''}
              onChangeSearch={event => this.handleSearch('name', event)}
              onChangeEmail={event => this.handleSearch('email', event)}
              valueEmail={this.state.search?.email ?? ''}
              statusOptionsFilter={statusOptionsFilter}
              onChangeSelect={this.handleOnSelectFilterStatus}
              valueSelect={this.state.search?.status}
              />
            <SimpleTableList
              rows={tableContent}
              columNameKeys={translate('keikenPayList.tableHeaders')}
              emptyTableMessage={translate('keikenPayList.labels.emptyList')}
              textOverflow
            />
            <PaginationTable
              currentP={this.state.page ? this.state.page.number : 1}
              totalPages={this.state.page ? getPaginationItemsNumber(this.state.page.totalElements, this.state.page.size) : 0}
              onSelect={this.handlePagination}
              />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (storageState: IRootState) => ({
  page: storageState.keikenPay.page,
  getKeikenPaySuccess: storageState.keikenPay.getKeikenPaySuccess,
  updateInvoiceStatus: storageState.keikenPay.updateInvoiceStatus
});

const mapDispatchToProps = { getKeikenPayInvoices, reset, showModal, hideModal };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProp = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IKeikenPayList);
