import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import StringUtils from 'app/util/StringUtils';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IActivityCategory } from '../../shared/model/activity-category';
import { IActivitySubCategory } from '../../shared/model/activity-sub-category';

export const ACTION_TYPES = {
  FETCH_ACTIVITY_SUB_CATEGORIES: 'activitySubCategories/FETCH_ACTIVITY_SUB_CATEGORIES',
  FETCH_INVALID_ACTIVITY_SUB_CATEGORIES: 'activitySubCategories/FETCH_INVALID_ACTIVITY_SUB_CATEGORIES',
  CREATE_ACTIVITY_SUB_CATEGORY: 'activitySubCategories/CREATE_ACTIVITY_SUB_CATEGORY',
  UPDATE_ACTIVITY_SUB_CATEGORY: 'activitySubCategories/UPDATE_ACTIVITY_SUB_CATEGORY',
  DELETE_ACTIVITY_SUB_CATEGORIES: 'activitySubCategories/DELETE_ACTIVITY_SUB_CATEGORIES',
  RESET: 'activitySubCategories/RESET'
};

const initialState = {
  page: null,
  deleteSubCategory: false,
  invalidIds: null,
  invalidStatus: HttpRequestStatus.NOOP,
  getSubCategoryList: HttpRequestStatus.NOOP,
  updateSubCategory: HttpRequestStatus.NOOP,
  createSubCategory: HttpRequestStatus.NOOP
};

export type ActivitySubCategoryState = Readonly<typeof initialState>;

// Reducer
export default (state: ActivitySubCategoryState = initialState, action): ActivitySubCategoryState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ACTIVITY_SUB_CATEGORIES):
      return {
        ...state,
        getSubCategoryList: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_ACTIVITY_SUB_CATEGORIES):
      return {
        ...initialState,
        getSubCategoryList: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACTIVITY_SUB_CATEGORIES):
      return {
        ...initialState,
        page: action.payload.data,
        getSubCategoryList: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.FETCH_INVALID_ACTIVITY_SUB_CATEGORIES):
      return {
        ...state,
        invalidIds: [],
        invalidStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_INVALID_ACTIVITY_SUB_CATEGORIES):
      return {
        ...state,
        invalidStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVALID_ACTIVITY_SUB_CATEGORIES):
      return {
        ...state,
        invalidIds: action.payload.data,
        invalidStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.DELETE_ACTIVITY_SUB_CATEGORIES):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.DELETE_ACTIVITY_SUB_CATEGORIES):
      return {
        ...initialState
      };
    case SUCCESS(ACTION_TYPES.DELETE_ACTIVITY_SUB_CATEGORIES):
      return {
        ...initialState,
        deleteSubCategory: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ACTIVITY_SUB_CATEGORY):
      return {
        ...initialState,
        createSubCategory: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.CREATE_ACTIVITY_SUB_CATEGORY):
      return {
        ...initialState,
        createSubCategory: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACTIVITY_SUB_CATEGORY):
      return {
        ...initialState,
        createSubCategory: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.UPDATE_ACTIVITY_SUB_CATEGORY):
      return {
        ...initialState,
        updateSubCategory: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_ACTIVITY_SUB_CATEGORY):
      return {
        ...initialState,
        updateSubCategory: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ACTIVITY_SUB_CATEGORY):
      return {
        ...initialState,
        updateSubCategory: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export const fetchActivitySubCategories = (page?: any, name?: string, activityCategoriesId?: number[]) => {
  let params = {} as any;
  if (name != null && !StringUtils.isStringInvalid(name)) {
    params = {
      name,
      size: page.size,
      page: page.page
    };
  } else {
    params = {
      size: page.size,
      page: page.page
    };
  }

  params['activityCategoriesId'] = activityCategoriesId?.join(',') ?? [];

  return {
    type: ACTION_TYPES.FETCH_ACTIVITY_SUB_CATEGORIES,
    payload: axios.get('api/activity-sub-categories', { params })
  };
};

export const fetchListActivitySubCategories = (page?: any, name?: string) => {
  let params = {} as any;

  if (!StringUtils.isStringInvalid(name)) {
    params = {
      name,
      size: page != null ? page.size : 20,
      page: page != null ? page.page : 0
    };
  } else {
    params = {
      size: page != null ? page.size : 20,
      page: page != null ? page.page : 0
    };
  }

  return {
    type: ACTION_TYPES.FETCH_ACTIVITY_SUB_CATEGORIES,
    payload: axios.get('api/activity-sub-categories/list', { params })
  };
};

export const fetchListNoRelatedActivitySubCategories = (page?: any, name?: string) => {
  let params = {} as any;

  if (!StringUtils.isStringInvalid(name)) {
    params = {
      name,
      size: page.size,
      page: page.page
    };
  }

  return {
    type: ACTION_TYPES.FETCH_ACTIVITY_SUB_CATEGORIES,
    payload: axios.get('api/activity-sub-categories/not-related', { params })
  };
};

export const createActivitySubCategory = (subCategory: IActivitySubCategory) => ({
  type: ACTION_TYPES.CREATE_ACTIVITY_SUB_CATEGORY,
  payload: axios.post('api/activity-sub-categories/', subCategory)
});

export const getInvalidActivitySubCategories = (activityCategoriesIds: number[],  activitySubCategoriesIds: number[]) => ({
  type: ACTION_TYPES.FETCH_INVALID_ACTIVITY_SUB_CATEGORIES,
  payload: axios.get('api/activity-sub-categories/invalid', {
    params: {
      activityCategoriesIds: activityCategoriesIds.join(','),
      activitySubCategoriesIds: activitySubCategoriesIds.join(',')
    }
  })
});

export const updateActivitySubCategory = (subCategory: IActivitySubCategory) => ({
  type: ACTION_TYPES.UPDATE_ACTIVITY_SUB_CATEGORY,
  payload: axios.put('api/activity-sub-categories/', subCategory)
});

export const deleteActivitySubCategory = (id?: Number) => ({
  type: ACTION_TYPES.DELETE_ACTIVITY_SUB_CATEGORIES,
  payload: axios.delete(`api/activity-sub-categories/delete/${id}`, {})
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
