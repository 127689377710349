import Button from 'app/components/button/Button';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { MODAL_TYPE_CONFIRM_TOKEN } from 'app/components/modal/modal-types';
import OpenMenuItem from 'app/components/open-menu-item/OpenMenuItem';
import { getCustomerActivityByToken, reset, useCustomerActivityToken } from 'app/entities/customer-activity/customer-activity-redux';
import { ICustomerActivity } from 'app/shared/model/customer-activity.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IItemOfOpenMenu } from 'app/shared/model/header-partner-menu-item';
import StringUtils from 'app/util/StringUtils';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { isMobile } from 'react-device-detect';
import { Storage, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import './header-partner.css';

export interface IHeaderPartnerProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IHeaderPartnerState {
  firstOpenMenuItems?: IItemOfOpenMenu[];
  secondOpenMenuItems?: IItemOfOpenMenu[];
  collapsed: boolean;
  token?: string;
  customerActivity?: ICustomerActivity;
}

class HeaderPartner extends React.Component<IHeaderPartnerProps, IHeaderPartnerState> {
  constructor(props: IHeaderPartnerProps) {
    super(props);

    const locations = isMobile
      ? [['activity-active']]
      : [['partner-dashboard'], ['calendar', 'new-activity', 'edit-activity'], ['financial'], ['activity-active']];
    const firstMenuItemsOnPressMethods = isMobile
      ? [this.handleActivityActivePressed]
      : [this.handleDashboardPressed, this.handleCalendarPressed, this.handleFinancialPressed, this.handleActivityActivePressed];

    this.state = {
      collapsed: isMobile,
      firstOpenMenuItems: this.mapFirstItemsOfOpenMenu(locations, firstMenuItemsOnPressMethods),
      secondOpenMenuItems: this.mapSecondItemsOfOpenMenu()
    };
  }

  componentDidMount() {
    this.props.history.listen((location, action) => {
      const firstOpenMenuItems = this.state.firstOpenMenuItems;

      firstOpenMenuItems.forEach((it, index) => {
        let pressed = false;

        it.pathname.forEach(pathname => {
          if (location.pathname.includes(pathname)) {
            pressed = true;
            return;
          }
        });

        it.pressed = pressed;
      });

      this.setState({ firstOpenMenuItems });
    });
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.getCustomerActivityByTokenStatus === HttpRequestStatus.SUCCESS &&
      newProps.getCustomerActivityByTokenStatus !== this.props.getCustomerActivityByTokenStatus
    ) {
      if (this.validateCustomerActivity(newProps.customerActivity)) {
        this.props.showModal(MODAL_TYPE_CONFIRM_TOKEN, {
          customerActivity: newProps.customerActivity,
          onClose: this.props.hideModal,
          onPressButton: this.handleUseToken
        });

        this.setState({
          customerActivity: newProps.customerActivity
        });
      }

      this.props.reset();
      return;
    }

    if (
      newProps.useCustomerActivityTokenStatus === HttpRequestStatus.SUCCESS &&
      newProps.useCustomerActivityTokenStatus !== this.props.useCustomerActivityTokenStatus
    ) {
      this.props.hideModal();
      toast.success(translate('partnerDashboard.successMessages.tokenSuccess'));
      this.props.reset();
      this.setState({ token: '' });
      return;
    }
  }

  private validateCustomerActivity = (customerActivity: ICustomerActivity): boolean => {
    if (customerActivity.tokenUsed === true) {
      toast.error(translate('partnerDashboard.errorMessages.tokenUsed'));
      return false;
    }
    if (moment(customerActivity.expirationDate, 'YYYY-MM-DD HH:mm').isBefore(moment(new Date(), 'YYYY-MM-DD HH:mm'))) {
      toast.error(translate('partnerDashboard.errorMessages.tokenExpired'));
      return false;
    }

    return true;
  };

  mapFirstItemsOfOpenMenu = (locations: string[][], onPressMethods: any) => {
    return Array(isMobile ? 1 : 4)
      .fill(null)
      .map((item, index) => {
        let pressed = false;

        locations[index].forEach(it => {
          if (this.props.location.pathname.includes(it)) {
            pressed = true;
            return;
          }
        });

        return {
          title: translate(`header.partner.menu.open.firstItem${index + (isMobile ? 4 : 1)}`),
          image: `content/images/partner-header/item-not-active-${index + (isMobile ? 4 : 1)}.png`,
          imageActive: `content/images/partner-header/item-active-${index + (isMobile ? 4 : 1)}.png`,
          pathname: locations[index],
          onPress: onPressMethods[index],
          pressed
        };
      });
  };

  mapSecondItemsOfOpenMenu = () => {
    return Array(1)
      .fill(null)
      .map((item, index) => {
        return {
          title: translate(`header.partner.menu.open.secondItem${index + 1}`),
          imageActive: `content/images/partner-header/second-item-active-${index + 1}.png`,
          image: `content/images/partner-header/second-item-not-active-${index + 1}.png`,
          pressed: false
        };
      });
  };

  handleUseToken = (id: number) => {
    this.props.useCustomerActivityToken(id);
  };

  handleDashboardPressed = () => {
    this.props.history.replace('/partner/partner-dashboard');
  };

  handleCalendarPressed = () => {
    this.props.history.replace('/partner/calendar');
  };

  handleActivityActivePressed = () => {
    this.props.history.replace('/partner/activity-active');
  };

  handleFinancialPressed = () => {
    this.props.history.replace('/partner/financial');
  };

  handleOnUserSettingsPressed = () => {
    this.props.history.push('/partner/partner-settings');
  };

  handleOnHamburgerIconPressed = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  handleLogoutPressed = () => {
    this.props.history.replace('/logout');
  };

  handleValidateToken = () => {
    if (StringUtils.isStringInvalid(this.state.token)) {
      return;
    }

    this.props.getCustomerActivityByToken(this.state.token);
  };

  renderNotCollapsedMenu = () => {
    return (
      <div className={'navbar-header-partner-side-menu-container'}>
        <div className={'navbar-header-partner-side-menu-open-logo-container'}>
          <img src={'content/images/ic-user-partner-header.png'} className={'navbar-header-partner-side-menu-open-user-img'} />

          <label className={'navbar-header-partner-side-menu-open-user-label mt-2'}>{Storage.session.get('partner-name')}</label>

          <img
            onClick={this.handleOnUserSettingsPressed}
            src={'content/images/ic-user-settings.png'}
            className={'navbar-header-partner-side-menu-open-user-settings-img'}
          />
        </div>

        <div className={'navbar-header-partner-side-menu-open-items-container'}>
          {this.state.firstOpenMenuItems.map((item, index) => {
            return (
              <OpenMenuItem
                onPress={item.onPress}
                key={`first${index}`}
                title={item.title}
                image={item.pressed ? item.imageActive : item.image}
                isPressed={item.pressed}
                showOnlyIcon={this.state.collapsed}
              />
            );
          })}

          <div className={'navbar-header-partner-side-menu-open-item-divisor'} />

          {this.state.secondOpenMenuItems.map((item, index) => (
            <OpenMenuItem
              key={index}
              title={item.title}
              isPressed={item.pressed}
              image={item.pressed ? item.imageActive : item.image}
              showOnlyIcon={this.state.collapsed}
            />
          ))}

          <div className={'navbar-header-partner-side-menu-open-logout-container'}>
            <OpenMenuItem title={translate('header.partner.menu.open.logout')} onPress={this.handleLogoutPressed} image={undefined} />
          </div>
        </div>
      </div>
    );
  };

  renderCollapsedMenu = () => {
    return (
      <div className={'navbar-header-partner-side-menu-container navbar-header-partner-side-menu-container-collapsed'}>
        <div className={'navbar-header-partner-side-menu-collapsed-logo-container'}>
          <img
            className={'navbar-header-partner-side-menu-collapsed-user-img'}
            onClick={this.handleOnUserSettingsPressed}
            src={'content/images/ic-user-partner-header.png'}
          />
        </div>

        <div className={'navbar-header-partner-side-menu-open-items-container'}>
          {this.state.firstOpenMenuItems.map((item, index) => {
            return (
              <OpenMenuItem
                onPress={item.onPress}
                key={`first${index}`}
                title={item.title}
                image={item.pressed ? item.imageActive : item.image}
                isPressed={item.pressed}
                showOnlyIcon={this.state.collapsed}
              />
            );
          })}
          <div className={'navbar-header-partner-side-menu-open-item-divisor'} />
          {this.state.secondOpenMenuItems.map(item => (
            <OpenMenuItem title={item.title} image={item.pressed ? item.imageActive : item.image} showOnlyIcon={this.state.collapsed} />
          ))}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id={'app-header'}>
        <div className={'navbar-header-partner-container'}>
          {!this.state.collapsed && this.renderNotCollapsedMenu()}

          {this.state.collapsed && this.renderCollapsedMenu()}

          <div className={!this.state.collapsed ? 'navbar-header-partner' : 'navbar-header-partner navbar-header-partner-collapsed'}>
            <div className={'navbar-header-partner-hamburger-container'}>
              <img
                onClick={() => this.handleOnHamburgerIconPressed()}
                src={'content/images/ic-menu-grey.png'}
                className={'navbar-header-partner-hamburger-icon'}
              />
              <img className={'navbar-header-partner-logo-keiken'} src={'content/images/logo-keiken-login.png'} />
            </div>

            <div className={'navbar-header-partner-token-container'}>
              <input
                onChange={(event: ChangeEvent<HTMLInputElement>) => this.setState({ token: event.target.value })}
                value={!!this.state.token ? this.state.token : ''}
                placeholder={translate('global.headerPartner.placeholders.token')}
                className={'navbar-header-partner-input'}
              />

              <Button
                onClick={this.handleValidateToken}
                title={translate('global.headerPartner.buttons.next')}
                outerClassName={'navbar-header-partner-input-button'}
                buttonLabelClassName={'navbar-header-partner-input-button-label'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = storeState => ({
  getCustomerActivityByTokenStatus: storeState.customerActivity.getCustomerActivityByTokenStatus,
  customerActivity: storeState.customerActivity.customerActivity,
  useCustomerActivityTokenStatus: storeState.customerActivity.useCustomerActivityTokenStatus
});

const mapDispatchToProps = { useCustomerActivityToken, getCustomerActivityByToken, showModal, hideModal, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderPartner)
);
