import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { translate } from 'react-jhipster';
import { debounce } from 'lodash';
import {
  getPriceTableById,
  updatePriceTable,
  createPriceTable,
  reset
} from 'app/entities/price-table/price-table-redux';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import { toast } from 'react-toastify';
import _ from 'lodash';
import InputField from 'app/components/input-field/InputField';
import { IPriceTable, IPriceTableStatus, IPriceTableActivity, IPriceTableActivityUtils } from 'app/shared/model/price-table';
import CoolButton from 'app/components/button/Button';
import { Table, Badge } from 'reactstrap';
import {
  faCheckSquare, faMinusSquare, faSquare,
  faArrowAltCircleLeft, faArrowAltCircleRight, faArrowAltCircleDown
} from '@fortawesome/fontawesome-free-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import StringUtils from 'app/util/StringUtils';
import CurrencyInput from 'react-currency-input';
import './price-table-screen-list.scss';

export interface IPriceTableManageScreenProps extends StateProps,
  DispatchProps, RouteComponentProps<any, any, { priceTable: IPriceTable } | any> { }

export interface IPriceTableManageScreenState {
  priceTable?: IPriceTable;
  updating: boolean;
  loading: boolean;
  variation: 'create' | 'edit';
}

class PriceTableManageScreen extends React.Component<IPriceTableManageScreenProps, IPriceTableManageScreenState> {
  constructor(props: IPriceTableManageScreenProps) {
    super(props);
    this.state = {
      updating: false,
      loading: false,
      variation: 'edit'
    };
    this.performAction = debounce(this.performAction, 500);
  }

  private performAction = (id: number) => {
    this.props.getPriceTableById(id);
  }

  componentDidMount() {
    if (this.props.match.params?.id != null) {
      this.performAction(this.props.match.params?.id);
      this.setState({
        variation: 'edit'
      });
    } else {
      this.performAction(0);
      this.setState({
        priceTable: {},
        variation: 'create'
      });
    }
  }

  componentWillReceiveProps(newProps: IPriceTableManageScreenProps) {
    if (newProps.getPriceTableStatus === HttpRequestStatus.SUCCESS) {
      const priceTableActivities = newProps.priceTable?.priceTableActivities?.map?.(it => ({
        ...it,
        partnerName: it.activity?.partnerPlace?.fantasyName ?? it.activityPlan?.partnerPlace?.fantasyName,
        selected: it?.id == null
      })) ?? [];

      this.setState({
        priceTable: {
          ...newProps.priceTable,
          priceTableActivities: _.sortBy(priceTableActivities, it => it?.id == null ? '  ' : it.partnerName)
        }
      });
      this.props.reset();
      this.setState({ loading: false });
    }
    if (newProps.updatePriceTableStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Atualizado com sucesso!', {
        onClose: () => {
          this.props.history.replace('/application-admin/price-table');
        },
        autoClose: 2000
      });
      this.performAction(this.props.match.params?.id);
      this.setState({ loading: false });
      this.props.reset();
    } else if (newProps.updatePriceTableStatus === HttpRequestStatus.ERROR) {
      toast.success('Error ao atualizar tabela!', {
        autoClose: 2000
      });
      this.setState({ loading: false });
      this.props.reset();
    }
    if (newProps.createPriceTableStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Criado com sucesso!', {
        onClose: () => {
          this.props.history.replace('/application-admin/price-table');
        },
        autoClose: 2000
      });
      this.setState({ loading: false });
      this.props.reset();
    } else if (newProps.createPriceTableStatus === HttpRequestStatus.ERROR) {
      toast.success('Error ao criar tabela!', {
        autoClose: 2000
      });
      this.setState({ loading: false });
      this.props.reset();
    }
    if (newProps.priceTable != null) {
      this.setState({ loading: false, priceTable: newProps.priceTable });
      this.props.reset();
    }
  }

  private validate = (): boolean => {
    let error = false;
    const priceTableActivities = this.state.priceTable?.priceTableActivities?.map?.(it => {
      if (it.price === 0 || it.priceWithFee === 0) {
        it.valid = false;
        error = true;
      } else if (
        _.lt(it.price, it.activity?.price ?? it.activityPlan?.price) ||
        _.gt(it.price, it.priceWithFee)
      ) {
        it.valid = false;
        error = true;
      }
      return it;
    });

    if (error) {
      this.setState(prevState => ({
        ...prevState,
        priceTable: {
          ...prevState.priceTable,
          priceTableActivities
        }
      }));
    }

    return !error;
  }
  private submitUpdate = () => {
    if (!this.validate()) {
      toast.error('preço errado');
      return;
    }
    if (this.state.variation == 'edit') {
      this.setState({ loading: true });
      this.props.updatePriceTable(this.state.priceTable);
    } else {
      this.setState({ loading: true });
      this.props.createPriceTable(this.state.priceTable);
    }
  }

  handleOnWarningCallbackPressed = () => {
    this.props.hideModal();
  };

  private handleChanged = (fieldKey: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState(prevState => ({
      priceTable: {
        ...prevState.priceTable,
        [fieldKey]: value
      }
    }));
  };

  private handlePressSquareHeaderIcon = () => {
    const priceTableActivities = this.state.priceTable?.priceTableActivities;

    if (priceTableActivities.every(it => it.selected == true)) {
      priceTableActivities.forEach(it => { it.selected = false; });
    } else if (priceTableActivities.some(it => it.selected == true)) {
      priceTableActivities.forEach(it => { it.selected = true; });
    } else if (priceTableActivities.some(it => it.id == null)) {
      priceTableActivities.forEach(it => { it.selected = it?.id == null; });
    } else {
      priceTableActivities.forEach(it => { it.selected = true; });
    }
    this.setState(prev => ({
      priceTable: {
        ...prev,
        priceTableActivities
      }
    }));
  };

  private handleChangeValues = (value: number) => {
    const priceTableActivities = this.state.priceTable?.priceTableActivities?.map?.(prevItem => {
      if (prevItem?.selected == true) {
        const priceActivity = prevItem.activity?.price ?? prevItem.activityPlan?.price;
        const newValue = value == 0 ? priceActivity : prevItem.price + value;
        prevItem.price = newValue;
        prevItem.valid = _.gt(newValue, priceActivity) && _.lt(newValue, prevItem.priceWithFee);
      }
      return prevItem;
    });
    this.setState(prev => ({
      priceTable: {
        ...prev,
        priceTableActivities
      }
    }));
  };

  private handleChangeValuesWithFee = (value: number) => {
    const priceTableActivities = this.state.priceTable?.priceTableActivities?.map?.(prevItem => {
      if (prevItem?.selected == true) {
        const priceActivity = prevItem.activity?.price ?? prevItem.activityPlan?.price;
        const newValue = value == 0 ? (prevItem.price ?? priceActivity) : prevItem.priceWithFee ? prevItem.priceWithFee + value : value;
        prevItem.priceWithFee = newValue;
        prevItem.valid = _.gt(newValue, priceActivity) && _.lt(newValue, prevItem.priceWithFee);
      }
      return prevItem;
    });

    this.setState(prev => ({
      priceTable: {
        ...prev.priceTable,
        priceTableActivities
      }
    }));
  };

  private handleSelectedItem = (it: IPriceTableActivity, index: number) => {
    const priceTableActivities = this.state.priceTable?.priceTableActivities;
    priceTableActivities[index].selected = !priceTableActivities[index].selected;
    this.setState(prev => ({
      priceTable: {
        ...prev.priceTable,
        priceTableActivities
      }
    }));
  };

  private handleChangePrice = (index: number, value: number) => {
    const priceTableActivities = this.state.priceTable?.priceTableActivities;
    const prevItem = priceTableActivities[index];
    prevItem.price = value;
    prevItem.valid = _.gt(value, prevItem.priceActivity) && _.lt(value, prevItem.priceWithFee);
    priceTableActivities[index] = prevItem;
    this.setState(prev => ({
      priceTable: {
        ...prev.priceTable,
        priceTableActivities
      }
    }));
  };

  private handleChangePriceWithFee = (index: number, value: number) => {
    const priceTableActivities = this.state.priceTable?.priceTableActivities;
    const prevItem = priceTableActivities[index];
    prevItem.priceWithFee = value;
    priceTableActivities[index] = prevItem;
    this.setState(prev => ({
      priceTable: {
        ...prev.priceTable,
        priceTableActivities
      }
    }));
  };

  render() {
    const priceTableActivities = this.state.priceTable?.priceTableActivities ?? [];
    const squareHeaderIcon = priceTableActivities?.length == 0
      ? faSquare
      : _.every(priceTableActivities, it => it.selected == true)
        ? faCheckSquare
        : _.some(priceTableActivities, it => it.selected == true)
          ? faMinusSquare
          : faSquare;
    const headers = translate('priceTableManage.placeholders.headers');
    const item = this.state.priceTable;
    const variation = this.state.variation;
    return (
      <div className={'form-container'}>
        <div className={'title-screen-container'}>
          <div onClick={() => this.props.history.goBack()}>
            <FontAwesomeIcon className={'back-arrow-icon'} icon={'arrow-left'} />
          </div>
        </div>
        <div className={'input-container'}>
          <InputField
            title={translate('priceTableManage.placeholders.name')}
            onChange={this.handleChanged.bind(this, 'name')}
            inputClassName={''}
            value={item?.name ?? ''}
            placeholder={'priceTableManage.placeholders.name'}
          />
        </div>
        {/* <div className={'input-container'}>
          <p>
            <select
              onChange={this.handleChanged.bind(this, 'status')}
              value={item?.status}
              className={'partner-register-select'}
            >
              {Object.values(IPriceTableStatus).map((it, index) => (
                <option value={it} key={`price-table-status-${it + index}`}>
                  {it}
                </option>
              ))}
            </select>
          </p>
        </div> */}
        <Table className="table table-striped table-bordered table-responsive d-table">
          <thead>
            <tr>
              {headers?.map?.((name: string, index: number) => (
                <th className={'item-max-width'} key={`table-price-header-${index}`}>
                  {index == 0 && (
                    <div onClick={this.handlePressSquareHeaderIcon} className={'icon-change-values'}>
                      <FontAwesomeIcon icon={squareHeaderIcon as IconProp} />
                    </div>
                  )}
                  {name}
                  {index == 5 && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div onClick={() => this.handleChangeValuesWithFee(-1)} className={'icon-change-values'}>
                        <FontAwesomeIcon icon={faArrowAltCircleLeft as IconProp} />{'    '}
                      </div>
                      <div onClick={() => this.handleChangeValuesWithFee(0)} className={'icon-change-values'}>
                        <FontAwesomeIcon icon={faArrowAltCircleDown as IconProp} />{' '}
                      </div>
                      <div onClick={() => this.handleChangeValuesWithFee(+1)} className={'icon-change-values'}>
                        <FontAwesomeIcon icon={faArrowAltCircleRight as IconProp} />{' '}
                      </div>
                    </div>
                  )}
                  {index == 4 && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div onClick={() => this.handleChangeValues(-1)} className={'icon-change-values'}>
                        <FontAwesomeIcon icon={faArrowAltCircleLeft as IconProp} />{'    '}
                      </div>
                      <div onClick={() => this.handleChangeValues(0)} className={'icon-change-values'}>
                        <FontAwesomeIcon icon={faArrowAltCircleDown as IconProp} />{' '}
                      </div>
                      <div onClick={() => this.handleChangeValues(+1)} className={'icon-change-values'}>
                        <FontAwesomeIcon icon={faArrowAltCircleRight as IconProp} />{' '}
                      </div>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {priceTableActivities?.map?.((it: IPriceTableActivity, index: number) => {
              const valid = _.gt(it.price, it?.activity?.price ?? it.activityPlan?.price) && _.lt(it.price, it.priceWithFee);
              if (it?.activityPlan?.status === 'ENABLED' || it?.activity?.status === 'ENABLED') {
                return <tr key={`table-price-row-${index}`} style={{ width: '80%', backgroundColor: index % 2 ? 'white' : '#e7e9ea' }}>
                  <td>
                    <div onClick={() => this.handleSelectedItem(it, index)} className={'icon-change-values'}>
                      <FontAwesomeIcon icon={(it?.selected ? faCheckSquare : faSquare) as IconProp} />
                    </div>
                  </td>
                  <td>
                    {it?.id == null && (<><Badge className="badge-success break">{'Novo'}</Badge><br /></>)}
                    {IPriceTableActivityUtils.getPartnerFantasyName(it)}
                  </td>
                  <td>
                    {it?.activity != null && (<Badge className="badge-info break">{'Atividade'}</Badge>)}
                    {it?.activityPlan != null && (<Badge className="badge-warning break">{'Plano'}</Badge>)}
                    <br />
                    {IPriceTableActivityUtils.getActivityName(it)}
                  </td>
                  <td>{StringUtils.currencyPtBr(it?.activity?.price ?? it?.activityPlan?.price)}</td>
                  <td>
                    <CurrencyInput
                      style={valid != true ? { borderColor: 'red' } : {}}
                      autoComplete={'off'}
                      onChangeEvent={(e, masked, floatValue) => this.handleChangePrice(index, floatValue)}
                      value={it.price}
                      decimalSeparator={','}
                      thousandSeparator={'.'}
                      prefix={'R$ '}
                    />
                  </td>
                  <td style={it?.priceWithFee == null ? { borderWidth: 1, borderColor: 'red' } : {}}>
                    <CurrencyInput
                      style={valid != true ? { borderColor: 'red' } : {}}
                      autoComplete={'off'}
                      onChangeEvent={(e, masked, floatValue) => this.handleChangePriceWithFee(index, floatValue)}
                      value={it?.priceWithFee}
                      decimalSeparator={','}
                      thousandSeparator={'.'}
                      prefix={'R$ '}
                    />
                  </td>
                  <td style={{ color: valid == true ? '#00c87f' : 'red', fontWeight: 'bold' }}>
                    {StringUtils.currencyPtBr(_.subtract(it?.priceWithFee, it.price))}
                    <br />
                    {_.multiply(100, _.divide(_.subtract(it?.priceWithFee, it.price), it?.priceWithFee)).toFixed(2) + '%'}
                  </td>
                </tr>;
              }
            })}
          </tbody>
        </Table>
        <div className={'marginT15'}>
          <CoolButton
            buttonDisabled={this.state.loading}
            loading={this.state.loading}
            onClick={this.submitUpdate}
            title={translate(`priceTableList.buttons.${variation}`)}
          />
        </div>
      </div >
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  page: storeState.priceTable.page,
  priceTable: storeState.priceTable.priceTable,
  getPriceTableStatus: storeState.priceTable.getPriceTableStatus,
  updatePriceTableStatus: storeState.priceTable.updatePriceTableStatus,
  createPriceTableStatus: storeState.priceTable.createPriceTableStatus
});

const mapDispatchToProps = {
  getPriceTableById,
  updatePriceTable,
  createPriceTable,
  showModal,
  hideModal,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceTableManageScreen);
