import { IAddress } from './../address.model';
import { IUser } from './../user.model';
import { ICompany } from './../company';
import { IPhone } from '../phone.model';

export interface ICustomer {
  id?: number;
  name?: string;
  user?: IUser;
  phones?: IPhone[];
  company?: ICompany;
  cpf?: string;
  address?: IAddress;
  birthDate?: string;
  customerDependents?: ICustomer;
  pixAccount?: {
    pixKey?: string;
    pixType?: string;
  };
  status?: CustomerStatus;
}

export enum CustomerStatus {
  REGULAR = 'REGULAR',
  PAYMENT_OVERDUE = 'PAYMENT_OVERDUE'
}

export interface CustomerUpdateStatusVM {
  id?: number;
  customerActivityPlanId?: number;
  status?: CustomerStatus;
}
