import React from 'react';
import KeykenPayImg from '../../keiken-pay-img';
import { IImage } from '../../../../../shared/model/image';
import './menus.scss';

interface Props {

    concatenedImgs: IImage[];
    handleNewImg: (newImage: IImage, type: 'MAIN' | 'AUX' | 'USERAUX', replaceIdx?: number | undefined) => void;

}

export const Receipt = ({ concatenedImgs, handleNewImg }: Props) => {
    return (
        <div className="flex-wrap-container">
            {concatenedImgs.map((img, idx) => (
                <KeykenPayImg
                    key={`${idx}`}
                    img={img}
                    handleNewImg={handleNewImg}
                    type={idx === 0 ? 'MAIN' : 'USERAUX'}
                    idx={idx === 0 ? undefined : idx - 1}
                />
            ))}
        </div>
    );
};
