import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IRenewalActivitySchedule } from '../../shared/model/renewal-activity-schedule';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { ICoParticipationGroupActivityCategory } from 'app/shared/model/CoParticipation/ICoParticipationGroupActivityCategory';
import { PresetPaymentMethodsToCharge } from 'app/shared/model/preset-payment-methods-to-charge';

export const ACTION_TYPES = {
  GET_RENEWAL_ACTIVITY_SCHEDULES: 'renewalActivitySchedule/GET_RENEWAL_ACTIVITY_SCHEDULES',
  GET_CO_PARTICIPATION_CUSTOMER_TO_RENEWAL: 'renewalActivitySchedule/GET_CO_PARTICIPATION_CUSTOMER_TO_RENEWAL',
  RELATED_CO_PARTICIPATION_CUSTOMER: 'renewalActivitySchedule/RELATED_CO_PARTICIPATION_CUSTOMER',
  FETCH_RENEWAL_ACTIVITY_SCHEDULES: 'renewalActivitySchedule/FETCH_RENEWAL_ACTIVITY_SCHEDULES',
  CREATE_RENEWAL_ACTIVITY_SCHEDULE: 'renewalActivitySchedule/CREATE_RENEWAL_ACTIVITY_SCHEDULE',
  UPDATE_RENEWAL_ACTIVITY_SCHEDULE: 'renewalActivitySchedule/UPDATE_RENEWAL_ACTIVITY_SCHEDULE',
  UPDATE_RENEWAL_ACTIVITY_SCHEDULE_PAYMENT_METHOD: 'renewalActivitySchedule/UPDATE_RENEWAL_ACTIVITY_SCHEDULE_PAYMENT_METHOD',
  DELETE_RENEWAL_ACTIVITY_SCHEDULES: 'renewalActivitySchedule/DELETE_RENEWAL_ACTIVITY_SCHEDULES',
  RESET: 'renewalActivitySchedule/RESET'
};

const initialState = {
  page: null,
  renewal: null,
  coParticipationCustomer: null,
  patchRelatedCustomerStatus: HttpRequestStatus.NOOP,
  coParticipationCustomerStatus: HttpRequestStatus.NOOP,
  deleteRenewalActivitySchedule: HttpRequestStatus.NOOP,
  getRenewalActivitySchedule: HttpRequestStatus.NOOP,
  getRenewalActivityScheduleList: HttpRequestStatus.NOOP,
  updateRenewalActivitySchedule: HttpRequestStatus.NOOP,
  createRenewalActivitySchedule: HttpRequestStatus.NOOP
};

export type IRenewalActivityScheduleState = Readonly<typeof initialState>;

// Reducer
export default (state: IRenewalActivityScheduleState = initialState, action: IActionForResponseEntity<any>): IRenewalActivityScheduleState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RENEWAL_ACTIVITY_SCHEDULES):
      return {
        ...state,
        getRenewalActivityScheduleList: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.FETCH_RENEWAL_ACTIVITY_SCHEDULES):
      return {
        ...state,
        getRenewalActivityScheduleList: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.FETCH_RENEWAL_ACTIVITY_SCHEDULES):
      return {
        ...state,
        page: action.payload.data,
        getRenewalActivityScheduleList: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.RELATED_CO_PARTICIPATION_CUSTOMER):
      return {
        ...state,
        patchRelatedCustomerStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.RELATED_CO_PARTICIPATION_CUSTOMER):
      return {
        ...state,
        patchRelatedCustomerStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.RELATED_CO_PARTICIPATION_CUSTOMER):
      return {
        ...state,
        patchRelatedCustomerStatus: HttpRequestStatus.SUCCESS,
        coParticipationCustomer: action.payload.data,
        coParticipationCustomerStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.GET_RENEWAL_ACTIVITY_SCHEDULES):
      return {
        ...state,
        getRenewalActivitySchedule: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_RENEWAL_ACTIVITY_SCHEDULES):
      return {
        ...initialState,
        getRenewalActivitySchedule: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_RENEWAL_ACTIVITY_SCHEDULES):
      return {
        ...initialState,
        renewal: action.payload.data,
        getRenewalActivitySchedule: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.GET_CO_PARTICIPATION_CUSTOMER_TO_RENEWAL):
      return {
        ...state,
        coParticipationCustomerStatus: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_CO_PARTICIPATION_CUSTOMER_TO_RENEWAL):
      return {
        ...initialState,
        coParticipationCustomerStatus: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_CO_PARTICIPATION_CUSTOMER_TO_RENEWAL):
      return {
        ...initialState,
        coParticipationCustomer: action.payload.data,
        coParticipationCustomerStatus: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.DELETE_RENEWAL_ACTIVITY_SCHEDULES):
      return {
        ...state,
        deleteRenewalActivitySchedule: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.DELETE_RENEWAL_ACTIVITY_SCHEDULES):
      return {
        ...state,
        deleteRenewalActivitySchedule: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.DELETE_RENEWAL_ACTIVITY_SCHEDULES):
      return {
        ...state,
        deleteRenewalActivitySchedule: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.CREATE_RENEWAL_ACTIVITY_SCHEDULE):
      return {
        ...initialState,
        createRenewalActivitySchedule: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.CREATE_RENEWAL_ACTIVITY_SCHEDULE):
      return {
        ...initialState,
        createRenewalActivitySchedule: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.CREATE_RENEWAL_ACTIVITY_SCHEDULE):
      return {
        ...initialState,
        createRenewalActivitySchedule: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.UPDATE_RENEWAL_ACTIVITY_SCHEDULE):
      return {
        ...initialState,
        updateRenewalActivitySchedule: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_RENEWAL_ACTIVITY_SCHEDULE):
      return {
        ...initialState,
        updateRenewalActivitySchedule: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_RENEWAL_ACTIVITY_SCHEDULE):
      return {
        ...initialState,
        updateRenewalActivitySchedule: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.UPDATE_RENEWAL_ACTIVITY_SCHEDULE_PAYMENT_METHOD):
      return {
        ...initialState,
        updateRenewalActivitySchedule: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.UPDATE_RENEWAL_ACTIVITY_SCHEDULE_PAYMENT_METHOD):
      return {
        ...initialState,
        updateRenewalActivitySchedule: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.UPDATE_RENEWAL_ACTIVITY_SCHEDULE_PAYMENT_METHOD):
      return {
        ...initialState,
        updateRenewalActivitySchedule: HttpRequestStatus.SUCCESS
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/preset-renewal-activity-schedule';

export const fetchRenewalActivitySchedule = (page = { size: 20, page: 0 }, predicate: any = {}) => {

  const params = {
    size: page?.size || 20,
    page: page?.page || 0,
    sort: 'status,desc',
    ...predicate
  };

  if (predicate?.email != null) {
    params['customer.user.email'] = predicate.email;
  }

  if (predicate?.name != null) {
    params['customer.name'] = predicate.name;
  }

  return {
    type: ACTION_TYPES.FETCH_RENEWAL_ACTIVITY_SCHEDULES,
    payload: axios.get(apiUrl, { params })
  };
};

// export const createRenewalActivitySchedule = (renewal: IRenewalActivitySchedule) => ({
//   type: ACTION_TYPES.CREATE_RENEWAL_ACTIVITY_SCHEDULE,
//   payload: axios.post('api/preset-renewal-activity-schedule', renewal)
// });

export const updateRenewalActivitySchedule = (renewal: IRenewalActivitySchedule) => ({
  type: ACTION_TYPES.UPDATE_RENEWAL_ACTIVITY_SCHEDULE,
  payload: axios.put(apiUrl, renewal)
});

export const updateRenewalActivitySchedulePaymentMethod = (id: number, coParticipationGroupActivityCategory: ICoParticipationGroupActivityCategory) => ({
  type: ACTION_TYPES.UPDATE_RENEWAL_ACTIVITY_SCHEDULE,
  payload: axios.put(`${apiUrl}/${id}/payment-methods`, { coParticipationGroupActivityCategory })
});

export const updatePresetPaymentToRenewalActivitySchedule = (
  id: number,
  customerId: number,
  item: PresetPaymentMethodsToCharge
) => ({
  type: ACTION_TYPES.UPDATE_RENEWAL_ACTIVITY_SCHEDULE_PAYMENT_METHOD,
  payload: axios.put(`/api/admin/preset-renewal-activity-schedule/${id}/payment-methods/customer/${customerId}`, item)
});

export const deleteRenewalActivitySchedule = (id?: Number) => ({
  type: ACTION_TYPES.DELETE_RENEWAL_ACTIVITY_SCHEDULES,
  payload: axios.delete(`${apiUrl}/${id}`, {})
});

export const fetchRenewalActivityScheduleById = (id?: Number) => ({
  type: ACTION_TYPES.GET_RENEWAL_ACTIVITY_SCHEDULES,
  payload: axios.get(`${apiUrl}/${id}`, {})
});

export const getCoParticipationCustomerToRenewal = (customerId?: Number) => ({
  type: ACTION_TYPES.GET_CO_PARTICIPATION_CUSTOMER_TO_RENEWAL,
  payload: axios.get(`/api/co-participation-customers/customer/${customerId}`, {})
});

export const tryFindCoParticipationCustomer = (customerId?: Number) => ({
  type: ACTION_TYPES.RELATED_CO_PARTICIPATION_CUSTOMER,
  payload: axios.patch(`/api/co-participation-customers/customer/${customerId}`, {})
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
