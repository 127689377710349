import Button from 'app/components/button/Button';
import InputField from 'app/components/input-field/InputField';
import { IActivity } from 'app/shared/model/activity';
import { IDayOfWeek } from 'app/shared/model/day-of-week';
import { ITimeSlotStep3Header } from 'app/shared/model/time-slots';
import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { hideModal, showModal } from '../../../../components/modal/modal-action';
import './edit-activity-step3.css';

export interface IEditActivityStep3Props extends StateProps, DispatchProps {
  object: IActivity;
  backButtonHandler: (activity: IActivity) => void;
  saveButtonHandler: (activity: IActivity) => void;
}

export interface IEditActivityStep3State {
  activity: IActivity;
  daysOfWeek: IDayOfWeek[];
  timeSlots?: ITimeSlotStep3Header[];
}

export class EditActivityStep3 extends React.Component<IEditActivityStep3Props, IEditActivityStep3State> {
  constructor(props) {
    super(props);

    const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

    this.state = {
      activity: props.object,
      daysOfWeek: this.mapDaysOfWeek(days),
      timeSlots: this.mapTimeSlots(days, props.object)
    };
  }

  mapDaysOfWeek = (days: string[]) => {
    return Array(8)
      .fill(null)
      .map((item, index) => {
        if (index === 0) {
          return {};
        }

        return {
          name: translate(`editActivity.daysOfWeek.name${index}`),
          value: days[index - 1]
        };
      });
  };

  mapTimeSlots = (days: string[], activity: IActivity) => {
    if (!!!activity.presetActivitySchedules || activity.presetActivitySchedules.length === 0) {
      return null;
    }

    const uniqueTimes = _.uniqBy(activity.presetActivitySchedules, it => {
      return it.startAt;
    });

    const newItems = uniqueTimes.map(it => {
      const items = Array(7)
        .fill(null)
        .map((item, index) => {
          return {
            dayOfWeek: { value: days[index] },
            enabled:
              activity.presetActivitySchedules.filter(preset => preset.startAt === it.startAt && preset.dayOfWeek === days[index]).length >
              0
          };
        });

      return {
        time: it.startAt,
        timeSlots: items
      };
    });

    return newItems;
  };

  handleBackButtonPressed = () => {
    this.props.backButtonHandler(this.state.activity);
  };

  handleSaveButtonPressed = () => {
    this.props.saveButtonHandler(this.state.activity);
  };

  render() {
    return (
      <div className={'edit-activity-step3-container'}>
        <div className={'edit-activity-step3-input-divisor'}>
          <InputField
            title={translate('editActivity.labels.activityName')}
            value={this.state.activity.name}
            inputClassName={'edit-activity-step3-input'}
            readOnly
          />
        </div>

        <div className={'edit-activity-step3-input-divisor'}>
          <InputField
            title={translate('editActivity.labels.activityCategory')}
            value={
              this.state.activity.activityCategories != null
                ? _.truncate(this.state.activity.activityCategories.map(it => it.name).join(','), { length: 80 })
                : ''
            }
            inputClassName={'edit-activity-step3-input'}
            readOnly
          />
        </div>

        <div className={'edit-activity-step3-input-divisor'}>
          <InputField
            title={translate('editActivity.labels.activitySubCategory')}
            value={
              this.state.activity.activitySubCategories != null
                ? _.truncate(this.state.activity.activitySubCategories.map(it => it.name).join(','), { length: 80 })
                : ''
            }
            inputClassName={'edit-activity-step3-input'}
            readOnly
          />
        </div>

        <div className={'edit-activity-step3-input-divisor'}>
          <InputField
            title={translate('editActivity.labels.teacher')}
            value={this.state.activity.teacher}
            inputClassName={'edit-activity-step3-input'}
            readOnly
          />
        </div>

        <div className={classnames('edit-activity-step3-input-divisor', 'edit-activity-step3-input-divisor-text-area')}>
          <InputField
            title={translate('editActivity.labels.description')}
            value={this.state.activity.description}
            inputClassName={'edit-activity-step3-input-textarea'}
            readOnly
            isTextArea
          />
        </div>

        <div className={'edit-activity-step3-input-price-and-slot-divisor'}>
          <div className={'edit-activity-step3-input-price-and-slot-container'}>
            <InputField
              title={translate('editActivity.labels.price')}
              value={!!this.state.activity.price ? this.state.activity.price : translate('editActivity.messages.free')}
              inputClassName={'edit-activity-step3-input'}
              isCurrency={!!this.state.activity.price}
              readOnly
            />
          </div>

          <div className={'edit-activity-step3-input-price-and-slot-container'}>
            <InputField
              title={translate('editActivity.labels.slot')}
              value={!!this.state.activity.slots ? this.state.activity.slots.toString() : translate('editActivity.messages.unlimited')}
              inputClassName={'edit-activity-step3-input'}
              readOnly
            />
          </div>
        </div>

        <div className={'edit-activity-step3-input-duration-divisor'}>
          <InputField
            title={translate('editActivity.labels.duration')}
            value={
              !!this.state.activity.duration
                ? this.state.activity.duration.toString() + translate('editActivity.messages.minutes')
                : translate('editActivity.messages.notFilled')
            }
            inputClassName={'edit-activity-step3-input'}
            readOnly
          />
        </div>

        {!!this.state.timeSlots &&
          this.state.timeSlots.length > 0 && (
            <div className={'edit-activity-step3-input-calendar-divisor'}>
              <div className={'edit-activity-step3-row'}>
                {
                  <div className={'edit-activity-step3-calendar-days-of-week'}>
                    {this.state.daysOfWeek.map((item, index) => {
                      return (
                        <div
                          className={
                            index === 0
                              ? classnames(
                                  'edit-activity-step3-calendar-days-of-week-item',
                                  'edit-activity-step3-calendar-days-of-week-item-without-border-top'
                                )
                              : 'edit-activity-step3-calendar-days-of-week-item'
                          }
                          key={`daysOfWeek${index}`}
                        >
                          {index === 0 && <div className={'edit-activity-step3-calendar-green-line'} />}
                          {index !== 0 && <label className={'edit-activity-step3-calendar-days-of-week-item-label'}>{item.name}</label>}
                        </div>
                      );
                    })}
                  </div>
                }
                <div className={'edit-activity-step3-calendar-container'}>
                  {this.state.timeSlots.map((item, index) => {
                    return (
                      <div key={`timeSlots${index}`} className={'edit-activity-step3-calendar-time-container'}>
                        <div className={'edit-activity-step3-calendar-time-header'}>
                          <label className={'edit-activity-step3-calendar-time-header-label'}>{item.time}</label>
                          <div className={'edit-activity-step3-calendar-green-line'} />
                        </div>

                        {item.timeSlots.map((timeSlotItem, timeSlotIndex) => {
                          return (
                            <div
                              key={`timeSlotItem${timeSlotIndex}`}
                              className={
                                timeSlotIndex === item.timeSlots.length - 1
                                  ? classnames(
                                      'edit-activity-step3-calendar-time-item-container',
                                      'edit-activity-step3-calendar-time-item-container-border-bottom'
                                    )
                                  : 'edit-activity-step3-calendar-time-item-container'
                              }
                            >
                              {!!timeSlotItem.enabled && (
                                <div className={'edit-activity-step3-calendar-time-item-background'}>
                                  <img className={'edit-activity-step3-calendar-item-image'} src={'content/images/ic-check-grey.png'} />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={'edit-activity-step3-calendar-green-bar'} />
            </div>
          )}

        <div className={'edit-activity-step3-button-container'}>
          <label className={'edit-activity-step3-back-button-label'} onClick={this.handleBackButtonPressed}>
            {translate('editActivity.buttons.back')}
          </label>

          <Button
            onClick={this.props.updateActivityLoading || this.props.updateActivitySuccess ? () => undefined : this.handleSaveButtonPressed}
            title={translate('editActivity.buttons.finish')}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = storeState => ({
  updateActivityLoading: storeState.activities.updateActivityLoading,
  updateActivitySuccess: storeState.activities.updateActivitySuccess
});

const mapDispatchToProps = { showModal, hideModal };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditActivityStep3);
