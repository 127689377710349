import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './partner-information.css';
import InputField from 'app/components/input-field/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideModal, showModal } from '../../../../components/modal/modal-action';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { toast } from 'react-toastify';
import StringUtils from 'app/util/StringUtils';
import classnames from 'classnames';
import Button from 'app/components/button/Button';
import { getPartnerPlacePerfil, reset, savePartnerPlace } from 'app/entities/partner-place/partner-place-redux';
import { IPartnerPlace } from 'app/shared/model/partner-place.model';
import { fetchAddresses } from 'app/entities/address/address-reducer';
import { PhoneType } from 'app/shared/model/phone.model';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IAddress } from 'app/shared/model/address.model';

export interface IPartnerInformationProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IPartnerInformationState {
  partnerPlacePerfil?: IPartnerPlace;
  errors?: IPartnerInformationError;
  idNumberMustBeCnpjMask?: boolean;
  idNumberMask?: string;
  fullAddress: string;
  valids?: IPartnerInformationValid;
}

export interface IPartnerInformationError {
  fantasyNameError?: boolean;
  identificationNumberError?: boolean;
  addressError?: boolean;
  phoneNumberError?: boolean;
}

export interface IPartnerInformationValid {
  fantasyNameValid?: boolean;
  identificationNumberValid?: boolean;
  addressValid?: boolean;
  phoneNumberValid?: boolean;
}

export class PartnerInformation extends React.Component<IPartnerInformationProps, IPartnerInformationState> {
  constructor(props: IPartnerInformationProps) {
    super(props);

    this.state = {
      errors: {},
      valids: {},
      fullAddress: '',
      partnerPlacePerfil: {
        address: {
          complement: ''
        },
        phone: {}
      }
    };
  }

  componentDidMount() {
    this.props.getPartnerPlacePerfil();
  }

  componentWillReceiveProps(newProps: IPartnerInformationProps) {
    if (newProps.getPartnerPlacePerfil && newProps.partnerPlacePerfil) {
      this.props.reset();
      const partnerPlacePerfil = newProps.partnerPlacePerfil as IPartnerPlace;
      partnerPlacePerfil.identificationNumber = !!partnerPlacePerfil.cnpj ? partnerPlacePerfil.cnpj : partnerPlacePerfil.cpf;
      this.mapAddressToString(partnerPlacePerfil.address);
      this.mapValidFields();
      this.setState({ partnerPlacePerfil });
      return;
    }

    if (newProps.updatePartnerPlacePerfilSuccess) {
      this.props.reset();
      toast.success(translate('partnerPlacePerfil.messages.saveWithSuccess'), {
        autoClose: 2000
      });
      return;
    }
  }

  mapValidFields = () => {
    const fieldValids: IPartnerInformationValid = {
      fantasyNameValid: true,
      phoneNumberValid: true,
      addressValid: true,
      identificationNumberValid: true
    };

    this.setState({ valids: fieldValids });
  };

  handleTextChanged = (text: string, propertyToChange: string, errorToChange?: string, validToChange?: string) => {
    if (!!errorToChange) {
      this.setState(prevState => ({
        partnerPlacePerfil: { ...prevState.partnerPlacePerfil, [propertyToChange]: text },
        errors: { ...prevState.errors, [errorToChange]: false },
        valids: { ...prevState.valids, [validToChange]: false }
      }));
      return;
    }

    this.setState(prevState => ({ partnerPlacePerfil: { ...prevState.partnerPlacePerfil, [propertyToChange]: text } }));
  };

  handleAddressTextChange = (text: string, propertyToChange: string, errorToChange?: string, validToChange?: string) => {
    if (!!errorToChange) {
      this.setState(prevState => ({
        partnerPlacePerfil: {
          ...prevState.partnerPlacePerfil,
          address: { ...prevState.partnerPlacePerfil.address, [propertyToChange]: text }
        },
        errors: { ...prevState.errors, [errorToChange]: false },
        valids: { ...prevState.valids, [validToChange]: false }
      }));
      return;
    }

    this.setState(prevState => ({
      partnerPlacePerfil: {
        ...prevState.partnerPlacePerfil,
        address: { ...prevState.partnerPlacePerfil.address, [propertyToChange]: text }
      }
    }));
  };

  handleIdentificationNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const identificationNumber = event.target.value;
    this.setState(prevState => ({ partnerPlacePerfil: { ...prevState.partnerPlacePerfil, identificationNumber } }));
  };

  handleIdentificationNumberOnKeyPressed = () => {
    const onlyNumbersIdNumber = this.state.partnerPlacePerfil.identificationNumber.replace(/\D/g, '');

    if (onlyNumbersIdNumber.length < 10) {
      this.setState({
        idNumberMustBeCnpjMask: false,
        idNumberMask: '999.999.999-99',
        errors: { ...this.state.errors, identificationNumberError: false },
        valids: { ...this.state.valids, identificationNumberValid: false }
      });
      return;
    }

    if (onlyNumbersIdNumber.length === 10 && !this.state.idNumberMustBeCnpjMask) {
      this.setState({
        idNumberMustBeCnpjMask: true,
        errors: { ...this.state.errors, identificationNumberError: false },
        valids: { ...this.state.valids, identificationNumberValid: false }
      });
      return;
    }

    if (this.state.idNumberMustBeCnpjMask) {
      this.setState({
        idNumberMask: '99.999.999/9999-99',
        errors: { ...this.state.errors, identificationNumberError: false },
        valids: { ...this.state.valids, identificationNumberValid: true }
      });
      return;
    }
  };

  handleIdentificationNumberOnKeyDownPressed = event => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      const onlyNumbersIdNumber = this.state.partnerPlacePerfil.identificationNumber.replace(/\D/g, '');
      if (this.state.idNumberMustBeCnpjMask && onlyNumbersIdNumber.length === 12) {
        this.setState({ idNumberMustBeCnpjMask: false, idNumberMask: '999.999.999-99' });
        return;
      }
    }
  };

  handleOnAddressPressed = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['street'], ['number'], ['district'], ['city'], ['zipcode']],
      title: translate('partner.register.labels.modal.address'),
      waitToPerformRequest: true,
      action: this.props.fetchAddresses,
      placeholderInput: translate('partner.register.placeholders.modal.address'),
      stateAction: 'addresses',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleAddressSelected(item)
    });
  };

  mapAddressToString = (item: IAddress) => {
    const firstSeparator = ', ';
    const secondSeparator = ' - ';
    let stringMapped = '';
    stringMapped += item.street ? item.street : '';
    stringMapped += item.number ? firstSeparator + item.number : '';
    stringMapped += item.city ? secondSeparator + item.city.name : '';
    stringMapped = stringMapped.replace(/^\s-\s/, '');
    this.setState({ fullAddress: stringMapped });
  };

  handleAddressSelected = (item: IAddress) => {
    this.mapAddressToString(item);
    const sameId = this.state.partnerPlacePerfil.address.id;
    this.setState(prevState => ({
      partnerPlacePerfil: {
        ...prevState.partnerPlacePerfil,
        address: {
          id: sameId,
          street: item.street,
          number: item.number,
          zipcode: item.zipcode,
          district: item.district,
          complement: item.complement ? item.complement : '',
          city: item.city,
          geoPoint: item.geoPoint,
          geolocated: item.geolocated
        }
      },
      errors: { ...prevState.errors, cityError: false }
    }));

    this.props.hideModal();
  };

  handleOnPhoneTypeChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const phoneType = Object.keys(PhoneType).find(key => PhoneType[key] === event.target.value) as PhoneType;
    this.setState(prevState => ({
      partnerPlacePerfil: { ...prevState.partnerPlacePerfil, phone: { ...prevState.partnerPlacePerfil.phone, type: phoneType } }
    }));
  };

  handlePhoneNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phone = event.target.value;
    this.setState(prevState => ({
      partnerPlacePerfil: { ...prevState.partnerPlacePerfil, phone: { ...prevState.partnerPlacePerfil.phone, number: phone } },
      errors: { phoneNumberError: false },
      valids: { phoneNumberValid: false }
    }));
  };

  handleConfirmButtonPressed = () => {
    if (!this.validateFields()) {
      return;
    }

    const partnerPlace = this.state.partnerPlacePerfil;

    const onlyNumbersIdNumber = this.state.partnerPlacePerfil.identificationNumber.replace(/\D/g, '');

    if (onlyNumbersIdNumber.length <= 11) {
      partnerPlace.cpf = onlyNumbersIdNumber;
      this.props.savePartnerPlace(partnerPlace);
      return;
    }

    partnerPlace.cnpj = onlyNumbersIdNumber;

    this.props.savePartnerPlace(partnerPlace);
  };

  handleBackButtonPressed = () => {
    this.props.history.replace(`/partner/partner-dashboard`);
  };

  handleImageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const newImage = this.state.partnerPlacePerfil.image || {};
      const contentType = selectedFile.type;
      newImage.contentType = contentType;
      newImage.fileName = selectedFile.name;
      newImage.file = ev.target.result.split(',')[1];
      this.setState(prevState => ({ partnerPlacePerfil: { ...prevState.partnerPlacePerfil, image: newImage } }));
    };

    reader.readAsDataURL(selectedFile);
  };

  validateFields = () => {
    let isAllFieldsValid = true;

    const fieldValids: IPartnerInformationValid = {
      fantasyNameValid: true,
      phoneNumberValid: true,
      addressValid: true,
      identificationNumberValid: true
    };

    if (StringUtils.isStringInvalid(this.state.partnerPlacePerfil.fantasyName)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, fantasyNameError: true } }));
      fieldValids.fantasyNameValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.partnerPlacePerfil.phone.number)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, phoneNumberError: true } }));
      fieldValids.phoneNumberValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.partnerPlacePerfil.address.zipcode)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, addressError: true } }));
      fieldValids.addressValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.partnerPlacePerfil.address.street)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, addressError: true } }));
      fieldValids.addressValid = false;
    }

    if (
      !StringUtils.isOnlyNumber(this.state.partnerPlacePerfil.address.number) &&
      StringUtils.isStringInvalid(this.state.partnerPlacePerfil.address.number)
    ) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, addressError: true } }));
      fieldValids.addressValid = false;
    }

    if (StringUtils.isStringInvalid(this.state.partnerPlacePerfil.identificationNumber)) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, addressError: true } }));
      fieldValids.identificationNumberValid = false;
    }

    // if (StringUtils.isStringInvalid(this.state.partnerPlacePerfil.address.district)) {
    //   isAllFieldsValid = false;
    //   this.setState(prevState => ({ errors: { ...prevState.errors, addressError: true } }));
    //   fieldValids.addressValid = false;
    // }

    if (!!!this.state.partnerPlacePerfil.address.city) {
      isAllFieldsValid = false;
      this.setState(prevState => ({ errors: { ...prevState.errors, addressError: true } }));
      fieldValids.addressValid = false;
    }

    this.setState({ valids: fieldValids });

    return isAllFieldsValid;
  };

  private identificationSection(): JSX.Element {
    return (
      <div>
        <label className={'partner-place-perfil-section-form-title'}>{translate('partnerPlacePerfil.labels.firstSectionForm')}</label>

        <div className={'row row-input-container'}>
          <div className={'col-md-7'}>
            <InputField
              title={translate('partnerPlacePerfil.labels.fantasyName')}
              onChange={event => this.handleTextChanged(event.target.value, 'fantasyName', 'fantasyNameError', 'fantasyNameValid')}
              error={this.state.errors.fantasyNameError}
              valid={this.state.valids.fantasyNameValid}
              inputClassName={'partner-place-perfil-input'}
              value={!!this.state.partnerPlacePerfil.fantasyName ? this.state.partnerPlacePerfil.fantasyName : null}
              placeholder={'partnerPlacePerfil.labels.fantasyName'}
            />
          </div>

          <div className={'col-md-5'}>
            <InputField
              onChange={this.handleIdentificationNumberChanged}
              onKeyPress={this.handleIdentificationNumberOnKeyPressed}
              onKeyDownPress={this.handleIdentificationNumberOnKeyDownPressed}
              inputClassName={'partner-place-perfil-input'}
              title={translate('partnerPlacePerfil.labels.identificationNumber')}
              value={!!this.state.partnerPlacePerfil.identificationNumber ? this.state.partnerPlacePerfil.identificationNumber : null}
              error={this.state.errors.identificationNumberError}
              valid={this.state.valids.identificationNumberValid}
              placeholder={'partnerPlacePerfil.labels.identificationNumber'}
              maskFormat={this.state.idNumberMask}
              isMaskRequired
            />
          </div>
        </div>

        <div className={'partner-place-perfil-input-divisor row row-input-container'}>
          <div className={'col-md-12'}>
            <InputField
              title={translate('partnerPlacePerfil.labels.socialReason')}
              onChange={event => this.handleTextChanged(event.target.value, 'socialReason')}
              inputClassName={'partner-place-perfil-input'}
              value={!!this.state.partnerPlacePerfil.socialReason ? this.state.partnerPlacePerfil.socialReason : null}
              placeholder={'partnerPlacePerfil.labels.socialReason'}
            />
          </div>
        </div>

        <div className={'partner-place-perfil-input-divisor row row-input-container'}>
          <div className={'col-md-5'}>
            <InputField
              title={translate('partnerPlacePerfil.labels.cref')}
              onChange={event => this.handleTextChanged(event.target.value, 'cref', 'crefError', 'crefValid')}
              inputClassName={'partner-place-perfil-input'}
              value={!!this.state.partnerPlacePerfil.cref ? this.state.partnerPlacePerfil.cref : null}
              placeholder={'partnerPlacePerfil.labels.cref'}
            />
          </div>
        </div>

        <div className={'partner-information-image-container'}>
          <input
            id={'myFileInput'}
            className={'partner-information-image-input'}
            onChange={this.handleImageChanged}
            accept={'image/*'}
            type={'file'}
          />

          <Button
            onClick={() => document.getElementById('myFileInput').click()}
            title={translate('editActivity.buttons.addImage')}
            buttonLabelClassName={'partner-information-select-image-button-label'}
            outerClassName={'partner-information-select-image-button-background-outer-class-name'}
            innerClassName={'partner-information-select-image-button-background-inner-class-name'}
          />
        </div>

        <div className={'partner-information-image-name'}>
          <label>{!!this.state.partnerPlacePerfil.image ? this.state.partnerPlacePerfil.image.fileName : ''}</label>
        </div>
      </div>
    );
  }

  private addressSection(): JSX.Element {
    return (
      <div>
        <label className={'partner-place-perfil-section-form-title'}>{translate('partnerPlacePerfil.labels.secondSectionForm')}</label>
        <div className={'partner-place-perfil-inner-addon'} onClick={this.handleOnAddressPressed}>
          <InputField
            title={translate('partnerPlacePerfil.labels.address')}
            inputClassName={'partner-place-perfil-search-input'}
            error={this.state.errors.addressError}
            valid={this.state.valids.addressValid}
            value={!!this.state.fullAddress ? this.state.fullAddress : ''}
            placeholder={translate('partnerPlacePerfil.labels.address')}
            readOnly
            onClick={this.handleOnAddressPressed}
          />

          <FontAwesomeIcon
            className={
              !!!this.state.partnerPlacePerfil.address.city
                ? 'partner-place-perfil-inner-icon'
                : classnames('partner-place-perfil-inner-icon', 'partner-place-perfil-inner-icon-with-value')
            }
            icon="search"
          />
        </div>
      </div>
    );
  }

  private detailsSection(): JSX.Element {
    return (
      <div>
        <label className={'partner-place-perfil-section-form-title'}>{translate('partnerPlacePerfil.labels.thirdSectionForm')}</label>

        <div className={'row row-input-container'}>
          <div className={'partner-place-perfil-divisor-selector col-md-5'}>
            <label className={'partner-place-perfil-input-label'}>
              <Translate contentKey={'partnerPlacePerfil.labels.phoneType'} />
            </label>
            <select
              value={this.state.partnerPlacePerfil.phone.type}
              onChange={this.handleOnPhoneTypeChanged}
              className={'partner-place-perfil-select'}
            >
              {Object.values(PhoneType).map((item, index) => (
                <option value={item} key={item + index}>
                  <Translate contentKey={`partnerPlacePerfil.options.${item}`} />
                </option>
              ))}
            </select>
          </div>

          <div className={'col-md-7'}>
            <InputField
              title={translate('partnerPlacePerfil.labels.phone')}
              placeholder={translate('partnerPlacePerfil.labels.phone')}
              onChange={this.handlePhoneNumberChanged}
              error={this.state.errors.phoneNumberError}
              valid={this.state.valids.phoneNumberValid}
              inputClassName={'partner-register-two-inputs'}
              value={this.state.partnerPlacePerfil.phone.number}
              maskFormat={'(99) 999999999'}
              isMaskRequired
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={'partner-place-perfil-container'}>
        <label className={'partner-place-perfil-header'}>{translate('partnerPlacePerfil.labels.partnerDate')}</label>
        {this.identificationSection()}
        {this.addressSection()}
        {this.detailsSection()}

        <div className={'new-activity-step1-button-container'}>
          <div className={'partner-register-buttons-container'}>
            <Button
              onClick={this.handleBackButtonPressed}
              outerClassName={'partner-register-back-button-background-outer-class-name'}
              buttonLabelClassName={'partner-register-back-button-label'}
              title={translate('newActivity.buttons.back')}
            />

            <Button onClick={this.handleConfirmButtonPressed} title={translate('partner.register.buttons.confirm')} />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = storeState => ({
  getPartnerPlacePerfilSuccess: storeState.partnerPlaces.getPartnerPlacePerfilSuccess,
  partnerPlacePerfil: storeState.partnerPlaces.partnerPlacePerfil,
  updatePartnerPlacePerfilSuccess: storeState.partnerPlaces.updatePartnerPlacePerfilSuccess
});

const mapDispatchToProps = { getPartnerPlacePerfil, reset, fetchAddresses, showModal, hideModal, savePartnerPlace };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PartnerInformation)
);
