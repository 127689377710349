import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import { IRootState } from '../../../../shared/reducers';
import { connect } from 'react-redux';
import { postPartnerPlacePayment } from '../../../../entities/partner-place/partner-place-redux';
import { TailSpin } from 'react-loader-spinner';
import { HttpRequestStatus } from '../../../../shared/model/enum/HttpRequestStatus';
import CurrencyInput from 'react-currency-input';
import { IPartnerPlacePaymentInfo } from '../../../../shared/model/partner-place.model';
import './payment-modal.scss';

export interface PaymentModalInfo {
    open: boolean;
    partnerPlace?: {
        id?: number, name?: string, value?: number, pixKey?: string
    };

}

interface IPartnersPaymentModalProps extends StateProps, DispatchProps {
    paymentModal: PaymentModalInfo;
    setPartnersPaymentModal: (partnersPaymentModal: PaymentModalInfo) => void;
}

const PartnersPaymentModal = (props: IPartnersPaymentModalProps) => {
    const [shouldPay, setShouldPay] = React.useState(true);
    const [customKeyPix, setCustomKeyPix] = React.useState<string | undefined>('');
    const [customValue, setCustomValue] = React.useState<number | undefined>(undefined);

    const [shouldAppearCustomKeyPixInput, setShouldAppearCustomKeyPixInput] = React.useState(false);
    const [shouldAppearCustomValueInput, setShouldAppearCustomValueInput] = React.useState(false);

    const pixKey = props.paymentModal?.partnerPlace?.pixKey;
    React.useEffect(() => {
        if (!pixKey) {
            setShouldAppearCustomKeyPixInput(true);
        } else {
            setShouldAppearCustomKeyPixInput(false);
        }
    }, [pixKey]);

    const postStatus = props.postPartnerPlacePaymentStatus;
    React.useEffect(() => {
        if (postStatus === HttpRequestStatus.SUCCESS) {
            handleCloseModal();
        }
    }, [postStatus]);

    const handleCustomPixCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShouldAppearCustomKeyPixInput(event.target.checked);
    };

    const handleShouldPayCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShouldPay(event.target.checked);
    };

    const handleCustomValueCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShouldAppearCustomValueInput(event.target.checked);
    };

    const handleSubmit = () => {

        const values = {
            id: props.paymentModal?.partnerPlace?.id!,
            value: shouldAppearCustomValueInput ? customValue! : props?.paymentModal?.partnerPlace?.value!,
            customPixKey: shouldAppearCustomKeyPixInput ? customKeyPix : undefined,
            shouldPay
        };

        const arrayObjToSend: IPartnerPlacePaymentInfo[] = [values];

        props.postPartnerPlacePayment(arrayObjToSend);
    };

    const handleToggleModal = () => {
        handleCloseModal();
    };

    const handleCloseModal = () => {
        props?.setPartnersPaymentModal({ open: false, partnerPlace: {} });
        setShouldAppearCustomValueInput(false);
        setShouldAppearCustomKeyPixInput(false);
        setCustomKeyPix('');
        setCustomValue(undefined);
        setShouldPay(true);
    };

    return (
        <div>
            <Modal
                isOpen={props.paymentModal?.open}
                toogle={() => handleToggleModal()}
                className="partners-payment-modal"
            >
                <ModalHeader>
                    {'Pagamento de parceiros'}
                </ModalHeader>
                <ModalBody
                    className="modal-content"
                >
                    {
                        props.getPartnerPlacePaymentStatus === HttpRequestStatus.ONGOING
                            || props.postPartnerPlacePaymentStatus === HttpRequestStatus.ONGOING ? (
                            <span>
                                <TailSpin height="30" width="30" color="black" />
                            </span>
                        ) : (
                            <section className="invoice-info">

                                <div>
                                    <div>
                                        <label>Nome do parceiro:</label>
                                        <span>{' ' + props.paymentModal?.partnerPlace?.name}</span>
                                    </div>
                                </div>

                                <div className="checkbox-container">
                                    <Label className="checkbox-label" check>
                                        <Input
                                            className="checkbox-input"
                                            type="checkbox"
                                            checked={shouldPay}
                                            onChange={handleShouldPayCheckBoxChange}
                                        />{' '}
                                        Realizar pagamento
                                    </Label>
                                </div>

                                <div className="checkbox-container">
                                    <Label className="checkbox-label" check>
                                        <Input
                                            className="checkbox-input"
                                            type="checkbox"
                                            checked={shouldAppearCustomKeyPixInput}
                                            onChange={handleCustomPixCheckboxChange}
                                        />{' '}
                                        Pagar em outra chave pix
                                    </Label>
                                </div>

                                {shouldAppearCustomKeyPixInput && (
                                    <>
                                        <FormGroup style={{ marginTop: '10px' }}>
                                            <Label for="customKeyPix">Chave PIX</Label>
                                            <Input
                                                type="text"
                                                name="customKeyPix"
                                                id="customKeyPix"
                                                placeholder="Digite a chave PIX"
                                                value={customKeyPix}
                                                onChange={e => setCustomKeyPix(e.target.value)}
                                            />
                                        </FormGroup>

                                        <p className="phone--format--note">Se for celular, o formato é: +55DD9XXXXXXXX, CPF e CNPJ pode manter só os números.</p>
                                    </>
                                )}

                                <div className="checkbox-container">
                                    <Label className="checkbox-label" check>
                                        <Input
                                            className="checkbox-input"
                                            type="checkbox"
                                            checked={shouldAppearCustomValueInput}
                                            onChange={handleCustomValueCheckboxChange}
                                        />{' '}
                                        Pagar outro valor
                                    </Label>
                                </div>

                                {shouldAppearCustomValueInput && (
                                    <FormGroup style={{ marginTop: '10px', gap: '10px', display: 'flex', alignItems: 'center' }}>
                                        <Label for="customValue">Valor</Label>
                                        <CurrencyInput
                                            autoComplete={'off'}
                                            onChangeEvent={(e, masked, floatValue) => setCustomValue(floatValue)}
                                            value={customValue}
                                            decimalSeparator={','}
                                            thousandSeparator={'.'}
                                            prefix={'R$ '}
                                        />

                                    </FormGroup>
                                )}

                            </section>
                        )}
                </ModalBody>

                <ModalFooter className="button-container">
                    <Button
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Pagar
                    </Button>
                    <Button
                        color="secondary"
                        onClick={() => handleCloseModal()}
                    >
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    getPartnerPlacePaymentStatus: storeState.partnerPlaces.getPartnersPaymentStatus,
    postPartnerPlacePaymentStatus: storeState.partnerPlaces.postPartnerPlacePaymentStatus
});

const mapDispatchToProps = {
    postPartnerPlacePayment
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PartnersPaymentModal);
