import { faEdit, faTrashAlt, faFlag } from '@fortawesome/fontawesome-free-regular';
import { faCheck } from '@fortawesome/fontawesome-free-solid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDollarSign, faDolly, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputField from '../../../../app/components/input-field/InputField';
import { hideModal, showModal } from '../../../../app/components/modal/modal-action';
import { MODAL_TYPE_SELECT_OPTION } from '../../../../app/components/modal/modal-types';
import SimpleTableList from '../../../../app/components/simple-table-list/simple-table-list';
import TableCustomerActivity from '../../../../app/components/table-customer-activity/TableCustomerActivity';
import TableCustomerActivityPlan from '../../../../app/components/table-customer-activity/TableCustomerActivityPlan';
import { APP_LOCAL_DATE_FORMAT, BASE_LP_URL } from '../../../../app/config/constants';
import { getActivityByPartnerPlaceToCustomer, resetActivitiesStatus } from '../../../../app/entities/activity/activity';
import { createCustomerBilling, forceProcessFutureCharge, getBillings, reset as resetBilling } from '../../../../app/entities/billing/billing.reducer';
import { deleteCustomerActivityPlan, getCustomerActivityPlan, reset as resetCustomerActivityPlan, updateCustomerActivityPlan } from '../../../../app/entities/customer-activity-plan/customer-activity-plan-redux';
import {
  deleteCustomerActivity, rescheduleActivitySchedules, reset as resetCustomerActivity, updateTokenUsedByAdmin
} from '../../../../app/entities/customer-activity/customer-activity-redux';
import { fetchCustomerById, fetchCustomerWalletByCustomerId, putUpdateCustomerAndPlanStatus, resetCustomer, updateBlockCoparticipationCustomer } from '../../../../app/entities/customer/customer-redux';
import { getKeikenPayInvoices, reset as resetKeikenPay } from '../../../../app/entities/keiken-pay/keiken-pay-reducer';
import { fetchPartnerPlacesFantasyName } from '../../../../app/entities/partner-place/partner-place-redux';
import { getCoParticipationCustomerToRenewal, reset as resetCoParticipationCustomer, tryFindCoParticipationCustomer } from '../../../../app/entities/renewal-activity-schedule/renewal-activity-schedule-redux';
import { IActivity } from '../../../../app/shared/model/activity';
import { IActivitySchedule } from '../../../../app/shared/model/activity-schedule/activity-schedule';
import { IAddressUtils } from '../../../../app/shared/model/address.model';
import { BillingStatus, IBilling, IBillingCreateVM } from '../../../../app/shared/model/billing/billing';
import { IPresetRenewalActivityPlan } from '../../../../app/shared/model/billing/preset-renewal-activity-plan';
import { IPriceComposite } from '../../../../app/shared/model/billing/price-composite';
import { ICoParticipationCustomer } from '../../../../app/shared/model/CoParticipation/ICoParticipationCustomer';
import { ICustomerActivityPlan } from '../../../../app/shared/model/customer-activity-plan';
import { ICustomerActivity } from '../../../../app/shared/model/customer-activity.model';
import { ICustomer } from '../../../../app/shared/model/customer/customer';
import { CustomerActivityStatus } from '../../../../app/shared/model/enum/CustomerActivityStatus';
import { HttpRequestStatus } from '../../../../app/shared/model/enum/HttpRequestStatus';
import { KeikenPayInvoice } from '../../../../app/shared/model/KeikenPay/KeikenPayInvoice';
import { IPage } from '../../../../app/shared/model/page.model';
import { IPartnerPlace } from '../../../../app/shared/model/partner-place.model';
import { PresetPaymentMethodsToCharge } from '../../../../app/shared/model/preset-payment-methods-to-charge';
import { IRootState } from '../../../../app/shared/reducers';
import { formatTimestamp } from '../../../../app/shared/util/date-utils';
import StringUtils from '../../../../app/util/StringUtils';
import moment from 'moment';
import * as React from 'react';
import { translate } from 'react-jhipster';
import { TailSpin } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Collapse, Row, UncontrolledTooltip, Input } from 'reactstrap';
import ActivityScheduleConfirmModal from '../schedule-manage/activity-schedule-confirm-modal/activity-schedule-confirm-modal';
import PartnerActivityScheduleCalendar from '../schedule-manage/partner-activty-scheduled-calendar/partner-activity-scheduled-calendar';
import ActivityPlanCheckoutConfirmModal from './components/activity-plan-checkout-confirm-modal';
import EditCustomerActivitModalPlan from './components/customer-plan-edit-modal';
import './customers-manage.scss';
import PartnerSuggestionConfirmModal from '../schedule-manage/confirmPartnerSuggestionModal';
import { Button as ModalButton, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { initNewPassword, reset } from '../../../entities/user/user-redux';

export interface ICustomersManageProps extends StateProps, DispatchProps, RouteComponentProps<{
  id?: string;
}, any, {} | any> { }

export interface ICustomersManageState {
  customer?: ICustomer;
  coParticipationCustomer?: ICoParticipationCustomer;
  billings?: IPage<IBilling>;
  keikenPayInvoices?: IPage<KeikenPayInvoice>;
  loading?: boolean;
  loadingBilling?: boolean;
  loadingCustomerPlan?: boolean;
  reload?: boolean;
  newSchedule?: boolean;
  newPlan?: boolean;
  editPlan?: boolean;
  approvingPlan?: boolean;
  allowTrySearchCoParticipationButton?: boolean;
  partnerPlace?: IPartnerPlace;
  customerActivityPlanSelected?: ICustomerActivityPlan;
  customerActivitySelected?: ICustomerActivity;
  activityScheduleToRescheduled?: IActivitySchedule;
  activities?: IActivity[];
  getActivitiesStatus?: HttpRequestStatus;
  showPartnerSuggestionConfirmModal?: boolean;
  partnerSuggestionItemToShowInModal?: ICustomerActivity | ICustomerActivityPlan | null;
  noShowBlockModalOpen?: boolean;
  noShowUnblockDate?: Date;
  noShowSetUnblockDate?: boolean;
  noShowDescription?: string;
  requestingReset: boolean;
}

export class ICustomersManage extends React.Component<ICustomersManageProps, ICustomersManageState> {
  filesInput = null;
  scheduleTitleRef = null;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingBilling: false,
      newSchedule: false,
      showPartnerSuggestionConfirmModal: false,
      partnerSuggestionItemToShowInModal: null,
      reload: false,
      newPlan: false,
      approvingPlan: false,
      editPlan: false,
      allowTrySearchCoParticipationButton: false,
      customer: {
        id: parseInt(this.props.match.params.id, 10)
      },
      noShowBlockModalOpen: false,
      noShowUnblockDate: moment().toDate(),
      noShowSetUnblockDate: false,
      noShowDescription: '',
      requestingReset: false

    };
    this.scheduleTitleRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.match.params?.id != null) {
      const id = parseInt(this.props.match.params.id, 10);
      this.props.fetchCustomerById(id);
      this.props.fetchCustomerWalletByCustomerId(id);
      this.props.getCoParticipationCustomerToRenewal(id);
      this.props.getKeikenPayInvoices({
        'customerId': id,
        size: 20
      });
      this.props.getBillings({ page: 0, size: 20 }, {
        ['customerId']: id,
        sort: 'id,desc'
      });
    }
  }

  componentWillReceiveProps(newProps: ICustomersManageProps) {
    if (newProps.billingStatus == HttpRequestStatus.SUCCESS || newProps.billingStatus == HttpRequestStatus.ERROR) {
      if (newProps.billingStatus == HttpRequestStatus.SUCCESS) {
        if (this.state.newPlan == false) {
          toast.success(translate('scheduleManage.billing.success'));
          this.searchSchedules();
        } else {
          toast.success(translate('scheduleManage.billing.success-plan'));
        }
      }
      this.props.resetBilling();
      this.setState(prev => ({
        loading: false,
        activityScheduleToRescheduled: undefined,
        newSchedule: false,
        partnerPlace: undefined,
        newPlan: false,
        reload: !prev.reload
      }), () => {
        this.props.getBillings({ page: 0, size: 20 }, {
          ['customerId']: this.state.customer.id,
          sort: 'id,desc'
        });
      });
    }

    if (newProps.customer != null) {
      this.setState({
        customer: newProps.customer
      }, () => {
        this.props.resetCustomer();
      });
    }
    if (newProps.coParticipationCustomer != null) {
      this.setState({
        coParticipationCustomer: newProps.coParticipationCustomer
      }, () => {
        this.props.resetCoParticipationCustomer();
      });
    }
    if (newProps.billings != null) {
      this.setState({
        billings: newProps.billings
      }, () => {
        this.props.resetBilling();
      });
    }
    if (newProps.getActivitiesStatus == HttpRequestStatus.SUCCESS) {
      this.setState({
        activities: newProps.activities
      }, () => {
        this.props.resetActivitiesStatus();
        this.scheduleTitleRef?.current?.scrollIntoView?.();
      });
    }
    if (newProps.getKeikenPaySuccess) {
      this.setState({
        keikenPayInvoices: newProps.keikenPayPage
      }, () => {
        this.props.resetKeikenPay();
      });
    }
    if (newProps.statusProcessFutureCharge == HttpRequestStatus.SUCCESS) {
      this.props.resetBilling();
      this.setState(prev => ({ billings: undefined, loadingBilling: false, reload: !prev.reload }));
      this.props.getBillings({ page: 0, size: 20 }, {
        ['customerId']: this.state.customer.id,
        sort: 'id,desc'
      });
    } else if (newProps.statusProcessFutureCharge == HttpRequestStatus.ERROR) {
      this.setState(prev => ({ loadingBilling: false, reload: !prev.reload }));
      this.props.resetBilling();
    }
    if (newProps.deleteStatus == HttpRequestStatus.SUCCESS) {
      this.props.resetCustomerActivity();
      this.setState({ customerActivitySelected: undefined });
      this.reloadActivities();
      this.props.getBillings({ page: 0, size: 20 }, {
        ['customerId']: this.state.customer.id,
        sort: 'id,desc'
      });
      this.props.getCoParticipationCustomerToRenewal(this.state.customer.id);
    } else if (newProps.deleteStatus == HttpRequestStatus.ERROR) {
      this.props.resetCustomerActivity();
    }
    if (newProps.approveStatus == HttpRequestStatus.SUCCESS) {
      this.props.resetCustomerActivity();
      this.setState({ customerActivitySelected: undefined });
      this.reloadActivities();
    } else if (newProps.approveStatus == HttpRequestStatus.ERROR) {
      this.props.resetCustomerActivity();
    }
    if (newProps.coParticipationCustomerStatus == HttpRequestStatus.SUCCESS) {
      if (!this.state.customer?.company?.id) {
        this.setState({ allowTrySearchCoParticipationButton: true });
      }
    } else if (newProps.coParticipationCustomerStatus == HttpRequestStatus.ERROR) {
      this.setState({ allowTrySearchCoParticipationButton: true });
    }
    if (newProps.relatedCustomerStatus == HttpRequestStatus.SUCCESS) {
      this.props.resetCoParticipationCustomer();
      this.setState({ allowTrySearchCoParticipationButton: false });
    } else if (newProps.relatedCustomerStatus == HttpRequestStatus.ERROR) {
      this.props.resetCoParticipationCustomer();
    }

    if (
      (this.state.approvingPlan && newProps.updateStatusPlan == HttpRequestStatus.SUCCESS) ||
      newProps.deleteStatusPlan == HttpRequestStatus.SUCCESS
    ) {
      this.setState(prev => ({ ...prev, reload: !prev.reload, loadingCustomerPlan: false, approvingPlan: false, loadingBilling: false }));
      this.props.resetCustomerActivityPlan();
    } else if (
      (this.state.approvingPlan && newProps.updateStatusPlan == HttpRequestStatus.ERROR) ||
      newProps.deleteStatusPlan == HttpRequestStatus.ERROR
    ) {
      this.setState(prev => ({ ...prev, reload: !prev.reload, loadingCustomerPlan: false, approvingPlan: false, loadingBilling: false }));
      this.props.resetCustomerActivityPlan();
    }

    if (newProps.rescheduleStatus === HttpRequestStatus.SUCCESS) {
      this.props.resetCustomerActivity();
      toast.success(translate('scheduleManage.reschedule.success'));
      this.reloadActivities();
      this.setState({
        loading: false,
        customerActivitySelected: undefined,
        activityScheduleToRescheduled: undefined
      });
    } else if (newProps.rescheduleStatus === HttpRequestStatus.ERROR) {
      this.props.resetCustomerActivity();
      toast.error(translate('scheduleManage.reschedule.error'));
      this.setState({
        loading: false,
        customerActivitySelected: undefined,
        activityScheduleToRescheduled: undefined
      });
      this.reloadActivities();
      this.setState({ loading: false });
    }
  }

  private reloadActivities = () => {
    this.props.resetCustomer();
    this.searchSchedules();
    this.setState({ reload: true });
  };

  private searchSchedules = (partnerPlace?: IPartnerPlace) => {
    const item = this.state.customerActivitySelected;
    const customerId = partnerPlace?.id ? this.state.customer?.id : item?.customer?.id;
    const partnerPlaceId = partnerPlace?.id ?? item?.activitySchedule?.activity?.partnerPlace?.id;
    if (customerId != null && partnerPlaceId != null) {
      this.props.getActivityByPartnerPlaceToCustomer(partnerPlaceId, customerId);
    }
  };

  private handleapproveCustomerActivitySuggestedByPartner = (item?: ICustomerActivity, index?: number) => {
    this.setState({
      showPartnerSuggestionConfirmModal: true,
      partnerSuggestionItemToShowInModal: item
    });
  };

  private handleDeleteCustomerActivity = (item?: ICustomerActivity, index?: number) => {
    this.props.deleteCustomerActivity(item);
    this.setState({ customerActivitySelected: item });
  };

  private handleEditCustomerActivity = (item?: ICustomerActivity, index?: number) => {
    if (this.state.customerActivitySelected?.id == item?.id) {
      this.setState({ customerActivitySelected: undefined, activities: undefined });
      return;
    }
    const customerId = item?.customer?.id;
    const partnerPlaceId = item?.activitySchedule?.activity?.partnerPlace?.id;

    this.setState({
      customerActivitySelected: item
    });

    this.props.getActivityByPartnerPlaceToCustomer(partnerPlaceId, customerId);
  };

  private handleChangePresence(customerActivityId: number, tokenUsed: boolean) {
    this.props.updateTokenUsedByAdmin(customerActivityId, tokenUsed);
  }

  private renderCustomerActivityActionColumn = (item?: ICustomerActivity, index?: number): JSX.Element => {
    const shouldBeAbleToApprove = item?.status == CustomerActivityStatus.PRE_SCHEDULED_BY_PARTNER || item?.status == CustomerActivityStatus.REQUEST_EDIT_BY_PARTNER;
    return (
      <div>
        <Button id={`approve-customer-activity-${item?.id}-${index}`} color={shouldBeAbleToApprove ? 'primary' : 'darkgray'} className={'centered-content margin1'} onClick={shouldBeAbleToApprove && this.handleapproveCustomerActivitySuggestedByPartner.bind(this, item, index)}>
          <FontAwesomeIcon icon={faCheck as IconProp} />
        </Button>
        <Button id={`edit-customer-activity-${item?.id}-${index}`} color="warning" className={'centered-content margin1'} onClick={this.handleEditCustomerActivity.bind(this, item, index)}>
          <FontAwesomeIcon icon={faEdit as IconProp} />
        </Button>
        <Button id={`delete-customer-activity-${item?.id}-${index}`} color="danger" className={'centered-content margin1'} onClick={this.handleDeleteCustomerActivity.bind(this, item, index)}>
          <FontAwesomeIcon icon={faTrashAlt as IconProp} />
        </Button>
        <UncontrolledTooltip target={`approve-customer-activity-${item?.id}-${index}`}>Aprovar horário</UncontrolledTooltip>
        <UncontrolledTooltip target={`edit-customer-activity-${item?.id}-${index}`}>Alterar horário</UncontrolledTooltip>
        <UncontrolledTooltip target={`delete-customer-activity-${item?.id}-${index}`}>Deletar e reembolsar</UncontrolledTooltip>
        {item?.tokenUsed ? (
          <>
            <UncontrolledTooltip target={`token-used-false-${item?.id}-${index}`}>Marcar Falta</UncontrolledTooltip>
            <span style={{color: '#218838'}}>Presente</span>
            <Button id={`token-used-false-${item?.id}-${index}`} style={{backgroundColor: '#138496', color: 'white'}} className={'centered-content margin1'} onClick={this.handleChangePresence.bind(this, item.id, false)}>
              N
              <FontAwesomeIcon icon={faFlag as IconProp} style={{marginLeft: '6px'}}/>
            </Button>
          </>
        ) : (
          <>
            <UncontrolledTooltip target={`token-used-true-${item?.id}-${index}`}>Confirmar Presença</UncontrolledTooltip>
            <span style={{color: 'red'}}>Falta</span>
            <Button id={`token-used-true-${item?.id}-${index}`} style={{backgroundColor: '#138496', color: 'white'}} className={'centered-content margin1'} onClick={this.handleChangePresence.bind(this, item.id, true)}>
              P
              <FontAwesomeIcon icon={faCheck as IconProp} style={{marginLeft: '6px'}}/>
            </Button>
          </>
        )}
      </div>
    );
  };

  private handleApproveCustomerPlan = (item?: ICustomerActivityPlan, index?: number) => {
    // TODO: waiting api - when we do the planApprove on the api

    // this.setState({
    //   showPartnerSuggestionConfirmModal: true,
    //   partnerSuggestionItemToShowInModal: item
    // });

  }

  private handleEditCustomerPlan = (item?: ICustomerActivityPlan, index?: number) => {
    if (this.state.loadingCustomerPlan == true) return;
    this.setState({
      loadingCustomerPlan: true,
      customerActivityPlanSelected: item,
      editPlan: true
    });
  };

  private handleDeleteCustomerPlan = (item?: ICustomerActivityPlan, index?: number) => {
    if (this.state.loadingCustomerPlan == true) return;
    this.setState({
      loadingCustomerPlan: true
    }, () => {
      this.props.deleteCustomerActivityPlan(item);
    });
  };

  private handleForceProcessFutureCharge = (item: ICustomerActivityPlan) => {
    if (this.state.loadingBilling == true) {
      return;
    }

    this.setState({
      loadingBilling: true
    }, () => {
      this.props.forceProcessFutureCharge(item?.billing.id);
    });
  }

  private renderCustomerActivityPlanActionColumn = (item?: ICustomerActivityPlan, index?: number): JSX.Element => {
    return (
      <div>
        <Button id={`approve-customer-plan-${item?.id}`} color="primary" className={'centered-content margin1'} onClick={() => this.handleApproveCustomerPlan(item, index)}>
          <FontAwesomeIcon icon={faCheck as IconProp} />
        </Button>
        {(item?.billing?.status == BillingStatus.FUTURE_CHARGE &&
          <Button id={`force-process-future-billing-${item.id}-${index}`} color="success" className={'centered-content margin1'} onClick={this.handleForceProcessFutureCharge.bind(this, item, index)}>
            <FontAwesomeIcon icon={faDollarSign as IconProp} />
          </Button>
        )}
        <Button id={`edit-customer-plan-${item?.id}`} color="warning" className={'centered-content margin1'} onClick={() => this.handleEditCustomerPlan(item, index)}>
          <FontAwesomeIcon icon={faEdit as IconProp} />
        </Button>
        <Button id={`delete-customer-plan-${item?.id}`} color="danger" className={'centered-content margin1'} onClick={() => this.handleDeleteCustomerPlan(item, index)}>
          <FontAwesomeIcon icon={faTrashAlt as IconProp} />
        </Button>
        <UncontrolledTooltip target={`approve-customer-plan-${item?.id}`}>Aprovar plano e confirmar pagamento</UncontrolledTooltip>
        <UncontrolledTooltip target={`edit-customer-plan-${item?.id}`}>Editar plano</UncontrolledTooltip>
        <UncontrolledTooltip target={`delete-customer-plan-${item?.id}`}>Deletar plano e reembolsar</UncontrolledTooltip>
        {item?.billing?.status == BillingStatus.FUTURE_CHARGE && <UncontrolledTooltip target={`force-process-future-billing-${item.id}-${index}`}>Forçar pagamento</UncontrolledTooltip>}
      </div>
    );
  };

  private handleSelectActivitySchedule = (activity: IActivity, activitySchedule: IActivitySchedule) => {
    this.setState({
      activityScheduleToRescheduled: {
        ...activitySchedule,
        activity
      }
    });
  };

  private handleSelectBilling = (billing: IBilling | undefined) => {
    this.props.history.push({
      pathname: `/application-admin/billings/${billing?.id}`,
      state: {
        billing,
        edit: true
      }
    });
  };

  private renderBillingActionColumn = (billing: IBilling | undefined): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <Button color={'info'} className={'centered-content margin1'} onClick={this.handleSelectBilling.bind(this, billing)}>
          <FontAwesomeIcon icon={'eye'} />
        </Button>
      </div>
    );
  };

  private handleTransformBillingToTableContent = (content?: IBilling[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IBilling) => {
      const date = formatTimestamp(item?.date);
      const name = item?.activityPlan != null ? item.activityPlan?.name : item.activitySchedules?.[0]?.activity?.name;
      const partnerPlace = item?.activityPlan != null ? item.activityPlan?.partnerPlace : item.activitySchedules?.[0]?.activity?.partnerPlace;
      const fantasyName = partnerPlace?.fantasyName;

      const billingStatusColor = item.status == 'PAID' ? 'text-success' : item.status == 'WAITING' ? 'text-warning' : 'text-danger';
      const billingStatus = <p className={billingStatusColor}>{item?.status?.toString?.()}</p> ?? '';

      let paymentInfo = item?.paymentMethod;
      if (item?.paymentProviderId) {
        paymentInfo += ' ' + item?.paymentProviderId;
      }

      result.push([
        date,
        fantasyName,
        item?.activityPlan != null ? 'Plano' : 'Agendamento',
        name,
        paymentInfo,
        StringUtils.currencyPtBr(item?.value),
        StringUtils.currencyPtBr(item?.billingCoParticipation?.subsidyValue ?? 0),
        billingStatus,
        this.renderBillingActionColumn(item)
      ]);
    });
    return result;
  };

  private handleEditKeikenPayInvoice = (invoice: KeikenPayInvoice | undefined) => {
    this.props.history.push({
      pathname: `/application-admin/keiken-pay-invoice/${invoice?.id}`,
      state: {
        invoice,
        edit: true
      }
    });
  };

  private renderKeikenPayActionColumn = (invoice: KeikenPayInvoice | undefined): JSX.Element => {
    return (
      <div className={'action-column-container'}>
        <Button color="info" className={'centered-content margin1'} onClick={this.handleEditKeikenPayInvoice.bind(this, invoice)}>
          <FontAwesomeIcon icon={'eye'} />
        </Button>
      </div>
    );
  };

  private handleTransformKeikenPayToTableContent = (content?: KeikenPayInvoice[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const twoLines = (a: string, b: string) => !b ? a : <><>{a}</><br /><>{b}</></>;
    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: KeikenPayInvoice) => {
      const customerName = item.customer?.name;
      const customerEmail = item.customer?.user?.email;
      const customer = twoLines(customerName, customerEmail);
      const partnerName = item.partnerExternal?.fantasyName ?? item.partnerExternal?.name ?? item.partnerExternal?.document;
      const partnerEmail = item.partnerExternal?.email;
      const partner = twoLines(partnerName, partnerEmail);

      const statusColor = item?.status == 'PAID' ? 'text-success' : item?.status == 'WAITING' ? 'text-warning' : 'text-danger';
      const status = <p className={statusColor}>{translate(`keikenPayList.keikenPayStatus.${item.status}`)}</p> ?? '';

      result.push([
        formatTimestamp(item?.billingCoParticipation?.date),
        StringUtils.currencyPtBr(item.subsidyValue),
        customer,
        partner,
        status,
        this.renderKeikenPayActionColumn(item)
      ]);
    });

    return result;
  };

  private handlePartnerList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['fantasyName']],
      title: translate('scheduleManage.placeHolder.search-partner'),
      action: this.props.fetchPartnerPlacesFantasyName,
      placeholderInput: translate('scheduleManage.placeHolder.search-partner'),
      stateAction: 'partnerPlaces',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handlePartnerSelected(item)
    });
  };

  private handlePartnerSelected = (partnerPlace: IPartnerPlace) => {
    if (this.state.newPlan) {
      this.setState({ partnerPlace, newSchedule: false });
    } else {
      this.setState({ partnerPlace }, () => this.searchSchedules(this.state.partnerPlace));
    }
    this.props.hideModal();
  };

  private handleNewActivitySchedule = () => {
    this.setState({
      newSchedule: true,
      customerActivitySelected: undefined,
      activities: undefined
    });
  }
  private handleConfirmActivityScheduleModal = async (
    categoryId: number,
    subCategoryId: number,
    intervalWeek?: number,
    presetPaymentMethodsToCharge?: PresetPaymentMethodsToCharge,
    priceComposite?: IPriceComposite,
    withoutPayment?: boolean,
    activityPlanId?: number,
    renewal?: IPresetRenewalActivityPlan
  ) => {
    const customerId = this.state.customer.id;
    if (categoryId == null || subCategoryId == null) {
      toast.warn('Não possui categoria e subcategoria');
      return;
    }
    if (this.state.loading == true) {
      toast.warn('loading');
      return;
    }
    if (this.state.newPlan == false && !this.state.newSchedule) {
      const customerActivityId = this.state.customerActivitySelected.id;
      const activityScheduleId = this.state.activityScheduleToRescheduled.id;
      this.props.rescheduleActivitySchedules(customerActivityId, activityScheduleId);
      this.setState({ loading: true });
      return;
    }
    const presetRenewalActivitySchedule = intervalWeek != null && activityPlanId == null ? {
      intervalWeek,
      activity: { id: this.state.activityScheduleToRescheduled.activity.id },
      activityCategory: { id: categoryId },
      activitySubCategory: { id: subCategoryId },
      presetPaymentMethodsToCharge
    } : null;

    const activitySchedules = activityPlanId == null
      ? [{ id: this.state.activityScheduleToRescheduled.id, activity: { id: this.state.activityScheduleToRescheduled.activity.id } }]
      : undefined;

    const activityPlan = activityPlanId != null
      ? { id: activityPlanId }
      : undefined;

    const presetRenewalActivityPlan = activityPlanId != null && renewal != null ? renewal : undefined;

    const billing: IBillingCreateVM = {
      billing: {
        activitySchedules,
        activityPlan
      },
      activityCategory: { id: categoryId },
      activitySubCategory: { id: subCategoryId },
      presetRenewalActivitySchedule,
      presetPaymentMethodsToCharge,
      priceComposite,
      withoutPayment,
      presetRenewalActivityPlan
    };

    this.setState({
      loading: true
    }, () => {
      this.props.createCustomerBilling(customerId, billing);
    });
  };

  private handleUpdateBlockCoparticipationCustomer = () => {
    const isBlocked = this.state.coParticipationCustomer?.unblockDate ? true : false;
    const block = !isBlocked;
    const dateToUnblock = isBlocked ? null : moment(this.state.noShowUnblockDate).isAfter(moment().add(1, 'd')) ? moment(this.state.noShowUnblockDate).toISOString() : null;
    this.props.updateBlockCoparticipationCustomer(this.state.coParticipationCustomer.id, block, dateToUnblock, this.state.noShowDescription);
    this.setState({ noShowBlockModalOpen: false });
    setTimeout(() => {
      this.props.getCoParticipationCustomerToRenewal(this.state.customer.id);
    }, 1000);
  }

  private handleSearchAndRelatedCoParticipation = () => {
    this.props.tryFindCoParticipationCustomer(this.state.customer.id);
  }

  private handleInitNewPassword = async () => {
    this.setState({ requestingReset: true });

    await this.props.initNewPassword(this.state.customer?.user?.email!);

    if (this.props.initNewPasswordStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Reset key solicitada com sucesso', {
        autoClose: 3000, onClose: () => {
          this.props.resetNewPassword();
          this.props.fetchCustomerById(this.state.customer?.id!);
        }
      });
    }

    this.setState({ requestingReset: false });
  };

  render() {
    const customer = this.state.customer;
    const address = IAddressUtils.mapAddressToString(customer?.address);
    const coParticipationCustomer = this.state.coParticipationCustomer;
    const showPartnerSuggestionConfirmModal = this.state.showPartnerSuggestionConfirmModal;
    const partnerSuggestionItemToShowInModal = this.state.partnerSuggestionItemToShowInModal;
    const noShowBlockModalOpen = this.state.noShowBlockModalOpen;

    const billingTableContent = this.handleTransformBillingToTableContent(this.state.billings?.content);
    const keikenPayTableContent: Array<Array<string | JSX.Element>> = this.handleTransformKeikenPayToTableContent(this.state.keikenPayInvoices?.content);
    const resetKey = this.state.customer?.user?.resetKey;
    const resetDate = this.state.customer?.user?.resetDate;
    const urlNewPassword = `${BASE_LP_URL}/entrar/redefinir-senha/${resetKey}`;

    return (
      <div className={'customers-manage-container'}>
        <Row style={{ marginBottom: 30 }}>
          <Col>
            <h2>{`Dados do cliente`}</h2>
            <p>{`name: ${customer?.name}`}</p>
            <p>{`email: ${customer?.user?.email}`}</p>
            <p>{`telefone: ${customer?.phones?.[0]?.number}`}</p>
            <p>{`birthDate: ${customer?.birthDate ? moment(customer.birthDate).format(APP_LOCAL_DATE_FORMAT) : ''}`}</p>
            <p>{`cpf: ${customer?.cpf ?? ''}`}</p>
            <p>{`endereço: ${address}`}</p>
            <p>{`companhia: ${customer?.company?.name ?? ''}`}</p>
            <p>{`status: ${translate(`customerList.status.${customer?.status}`)}` ?? ''}</p>

            {resetKey && (<p>{`URL Reset senha: `} <a target="_blank" style={{ color: 'blue' }} href={urlNewPassword}>{urlNewPassword}</a></p>)}
            {resetDate && (<p>{`Data de expiração: ${moment(resetDate).format(APP_LOCAL_DATE_FORMAT)}`}</p>)}
            <button disabled={this.state.requestingReset} onClick={this.handleInitNewPassword} className="button--reset__key">
              {this.state.requestingReset ? 'Solicitando...' : 'Solicitar novo reset key'}
            </button>
          </Col>
          <Col>
            <Row className="marginB3">
              <h2 className="my-auto">Dados da carteira</h2>
            </Row>
            <Row className="marginB3">
              <div>
                <p>{`saldo: ${this.props.wallet?.balance ?? ''}`}</p>
                <p>
                  <a href={`/#/application-admin/customers/${this.state.customer?.id}/wallet`}>Ver carteira</a>
                </p>
              </div>
            </Row>
            <Row className="marginB3">
              {this.state.allowTrySearchCoParticipationButton && (
                <>
                  <Button id={`find-customer-co-participation-${customer?.id}`} color="primary" className={'centered-content margin1'} onClick={this.handleSearchAndRelatedCoParticipation}>
                    <FontAwesomeIcon icon={faSearch as IconProp} />
                  </Button>
                  <UncontrolledTooltip target={`find-customer-co-participation-${customer?.id}`}>Procurar e vincular benefício</UncontrolledTooltip>
                </>
              )}
              <h2 className="my-auto">{`Dados do benefício`}</h2>
            </Row>
            {coParticipationCustomer?.unblockDate ? (
              <>
                <p style={{ color: 'red', border: '1px solid red', borderRadius: '4px', padding: '5px' }}>
                  {`usuário bloqueado até: ${moment(coParticipationCustomer?.unblockDate).format(APP_LOCAL_DATE_FORMAT)}`}
                </p>
                <Button
                  color="success"
                  className={'centered-content margin3'}
                  onClick={() => {
                    this.setState({ noShowBlockModalOpen: !noShowBlockModalOpen });
                  }}>
                  Desbloquear
                </Button>
              </>
            ) : (
              <Button
                color="danger"
                className={'centered-content margin3'}
                onClick={() => {
                  this.setState({ noShowBlockModalOpen: !noShowBlockModalOpen });
                }}>
                Bloquear utilização do benefício
              </Button>
            )}
            <p>{`segmento: ${coParticipationCustomer?.coParticipationSegment?.nameSegment ?? ''}`}</p>
            {coParticipationCustomer?.groups?.map(it => (
              <div key={`groups-${it.id}`} className="group-container">
                <h3>{`benefício: ${it?.id} - ${it?.nameGroup}`}</h3>
                {it?.maxSpendingGroup && <p>{`valor disponível: ${StringUtils.currencyPtBr(it?.maxSpendingGroupAvailable)} de ${StringUtils.currencyPtBr(it?.maxSpendingGroup)}`}</p>}
                {it?.maxSpendingPerPurchase && <p>{`limite por compra: ${StringUtils.currencyPtBr(it?.maxSpendingPerPurchase)}`}</p>}
                {it?.annualLimit && <p>{`limite anual: ${StringUtils.currencyPtBr(it?.annualLimit)}`}</p>}
                {it.activityCategories?.slice(0, 3)?.map(ac => (
                  <li key={`group-category-${ac.id}`}>{`${ac?.id} - ${ac?.activityCategory?.name} - ${StringUtils.currencyPtBr(ac?.maxSpendingCategoryAvailable)} de ${StringUtils.currencyPtBr(ac?.maxSpendingCategory)}`}</li>
                ))}
                <SeeMore list={it.activityCategories?.slice?.(3, it.activityCategories?.length + 1)} />
              </div>
            ))}
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Button color="success" className={'centered-content margin3'} onClick={this.handleNewActivitySchedule}>
                <FontAwesomeIcon icon={'plus'} />
              </Button>
              <h1>Agendamentos</h1>
            </Row>
            {this.state.newSchedule && (
              <div className={'schedule-manage-input-divisor'}>
                <div className={'schedule-manage-inner-addon'} onClick={this.handlePartnerList}>
                  <InputField
                    title={translate('scheduleManage.placeHolder.search-partner')}
                    inputClassName={'schedule-manage-search-input'}
                    value={this.state.partnerPlace?.fantasyName || ''}
                    placeholder={'scheduleManage.placeHolder.search-partner'}
                    readOnly
                    onClick={this.handlePartnerList}
                  />

                  <div className={'schedule-manage-inner-icon'}>
                    <FontAwesomeIcon className={'schedule-manage-inner-icon'} icon={'search'} />
                  </div>
                </div>
              </div>
            )}
            {this.state.newSchedule && (
              <>
                <h1>Agendar horário</h1>
                <PartnerActivityScheduleCalendar
                  activities={this.state.activities}
                  onSelectEvent={this.handleSelectActivitySchedule}
                />
              </>
            )}
            <TableCustomerActivity
              reload={this.state.reload}
              customer={customer}
              renderActionColumn={this.renderCustomerActivityActionColumn}
              selectedId={this.state.customerActivitySelected?.id}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.customerActivitySelected != null && !this.state.newSchedule && (
              <>
                <div ref={this.scheduleTitleRef}>
                  {this.state.customerActivitySelected?.activitySchedule?.date &&
                    <h1>Editar horário {formatTimestamp(this.state.customerActivitySelected?.activitySchedule?.date)}</h1>
                  }
                </div>
                <PartnerActivityScheduleCalendar
                  activities={this.state.activities}
                  onSelectEvent={this.handleSelectActivitySchedule}
                />
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <Row>
              <Button color="success" className={'centered-content margin3'} onClick={() => this.setState({ newPlan: true })}>
                <FontAwesomeIcon icon={'plus'} />
              </Button>
              <h1>Planos</h1>
            </Row>
            {this.state.newPlan && (
              <div className={'schedule-manage-input-divisor'}>
                <div className={'schedule-manage-inner-addon'} onClick={this.handlePartnerList}>
                  <InputField
                    title={translate('scheduleManage.placeHolder.search-partner')}
                    inputClassName={'schedule-manage-search-input'}
                    value={this.state.partnerPlace?.fantasyName || ''}
                    placeholder={'scheduleManage.placeHolder.search-partner'}
                    readOnly
                    onClick={this.handlePartnerList}
                  />

                  <div className={'schedule-manage-inner-icon'}>
                    <FontAwesomeIcon className={'schedule-manage-inner-icon'} icon={'search'} />
                  </div>
                </div>
              </div>
            )}
            {(this.state.loadingCustomerPlan == true || this.state.loadingBilling == true) ? (
              <TailSpin height={35} />
            ) : (
              <TableCustomerActivityPlan
                customer={customer}
                reload={this.state.reload}
                renderActionColumn={this.renderCustomerActivityPlanActionColumn}
                selectedId={this.state.customerActivityPlanSelected?.id}
              />
            )}
            <EditCustomerActivitModalPlan
              isOpen={this.state.customerActivityPlanSelected != null && this.state.editPlan}
              customerActivityPlan={this.state.customerActivityPlanSelected}
              onClose={() => {
                this.setState({
                  editPlan: false,
                  customerActivityPlanSelected: undefined,
                  loadingCustomerPlan: false
                });
              }}
              onConfirm={() => {
                this.setState(prev => ({
                  ...prev,
                  reload: !prev.reload,
                  editPlan: false,
                  customerActivityPlanSelected: undefined,
                  loadingCustomerPlan: false
                }));
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <h1>Últimos KeikenPay</h1>
            <SimpleTableList
              rows={keikenPayTableContent}
              columNameKeys={translate('customerManage.keikenPayInvoices.tableHeaders')}
              emptyTableMessage={translate('keikenPayList.labels.emptyList')}
              textOverflow
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <h1>Últimas compras</h1>
            <SimpleTableList
              rows={billingTableContent}
              columNameKeys={translate('customerManage.billings.tableHeaders')}
              emptyTableMessage={''}
            />
          </Col>
        </Row>

        <ActivityPlanCheckoutConfirmModal
          isOpen={this.state.newPlan && this.state.partnerPlace != null}
          customer={this.state.customer}
          partnerPlace={this.state.partnerPlace}
          onClose={() => {
            this.setState({
              partnerPlace: null,
              newPlan: false
            });
          }}
          onConfirm={this.handleConfirmActivityScheduleModal}
        />

        <PartnerSuggestionConfirmModal
          isOpen={showPartnerSuggestionConfirmModal!}
          item={partnerSuggestionItemToShowInModal!}
          customer={this.state.customer!}
          onClose={() => {
            this.setState({
              showPartnerSuggestionConfirmModal: false
            });
          }}
        />
        <Modal isOpen={noShowBlockModalOpen} toggle={() => {
          this.setState({ noShowBlockModalOpen: !noShowBlockModalOpen });
        }}>
          <ModalHeader toggle={() => {
            this.setState({ noShowBlockModalOpen: !noShowBlockModalOpen });
          }}>
            {coParticipationCustomer?.unblockDate ? 'Tem certeza que quer desbloquear o usuário?' : 'Tem certeza que quer bloquear o usuário?'}
          </ModalHeader>
          <ModalBody>
            {coParticipationCustomer?.unblockDate ? 'O usuário poderá utilizar o benefício normalmente' : 'O usuário não poderá utilizar o benefício até a data definida, caso não seja definida, o usuário ficará bloqueado pelo tempo definido em seu segmento'}
            {!coParticipationCustomer?.unblockDate &&
              <div>
                <label style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <input
                    type="checkbox"
                    checked={this.state.noShowSetUnblockDate}
                    onChange={() => {
                      this.setState({ noShowSetUnblockDate: !this.state.noShowSetUnblockDate, noShowUnblockDate: moment().toDate() });
                    }}
                  />
                  Definir data de desbloqueio
                </label>
              </div>
            }
            {!coParticipationCustomer?.unblockDate && this.state.noShowSetUnblockDate &&
              <Input
                type="date"
                value={moment(this.state.noShowUnblockDate).format('YYYY-MM-DD')}
                onChange={e => {
                  const value = e.target.value;
                  this.setState({ noShowUnblockDate: moment(value).toDate() });
                }}
              />
            }
            <InputField
              title={'Descrição'}
              inputClassName={'schedule-manage-search-input'}
              value={this.state.noShowDescription}
              type="text"
              onChange={e => this.setState({ noShowDescription: e.target.value })}
            />
            {/* <InputField
              title={"Data de desbloqueio"}
              inputClassName={'schedule-manage-search-input'}
              value={this.state.noShowBlockTime}
              placeholder={'scheduleManage.placeHolder.search-partner'}
              type="date"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({noShowBlockTime: moment(e.target.value).format(APP_LOCAL_DATE_FORMAT)})}
            /> */}
          </ModalBody>
          <ModalFooter>
            <ModalButton className={'approveButton'} onClick={() => this.handleUpdateBlockCoparticipationCustomer()}>
              {coParticipationCustomer?.unblockDate ? 'Desbloquear' : 'Bloquear'}
            </ModalButton>
            <ModalButton className={'denyButton'} onClick={() => { this.setState({ noShowBlockModalOpen: !noShowBlockModalOpen }); }}>{translate('table.approveModal.buttonCancel')}</ModalButton>
          </ModalFooter>
        </Modal>

        <ActivityScheduleConfirmModal
          isEdit={!this.state.newSchedule}
          isOpen={
            this.state.newSchedule
              ? this.state.activityScheduleToRescheduled != null
              : this.state.customerActivitySelected != null}
          customer={this.state.customer}
          activity={this.state.activityScheduleToRescheduled?.activity}
          activitySchedule={this.state.activityScheduleToRescheduled}
          customerActivity={this.state.customerActivitySelected}
          onClose={() => {
            this.setState({
              customerActivitySelected: undefined,
              activityScheduleToRescheduled: undefined
            });
          }}
          onConfirm={this.handleConfirmActivityScheduleModal}
        />
      </div>
    );
  }
}

const SeeMore = ({ list }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  if (!list?.length || list?.length == 0) {
    return <div />;
  }
  return (
    <div>
      <Collapse isOpen={isOpen}>
        {list?.map(ac => (
          <li key={`seemore-${ac.id}`}>{`${ac.id} - ${ac?.activityCategory?.name} - ${StringUtils.currencyPtBr(ac?.maxSpendingCategoryAvailable)} de ${StringUtils.currencyPtBr(ac?.maxSpendingCategory)}`}</li>
        ))}
      </Collapse>
      <Button color="info" onClick={toggle} size="small">
        Ver {isOpen ? 'menos' : `mais ${list?.length ?? 0} categorias`}
      </Button>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  customer: storeState.customer.customer,
  wallet: storeState.customer.wallet,
  coParticipationCustomer: storeState.renewalActivitySchedule.coParticipationCustomer,
  coParticipationCustomerStatus: storeState.renewalActivitySchedule.coParticipationCustomerStatus,
  relatedCustomerStatus: storeState.renewalActivitySchedule.patchRelatedCustomerStatus,
  billings: storeState.billing.billings,
  statusBillings: storeState.billing.status,
  activities: storeState.activities?.page?.content,
  getActivitiesStatus: storeState.activities?.getActivitiesStatus,
  keikenPayPage: storeState.keikenPay.page,
  getKeikenPaySuccess: storeState.keikenPay.getKeikenPaySuccess,
  rescheduleStatus: storeState.customerActivity.changeScheduleStatus,
  deleteStatus: storeState.customerActivity.deleteStatus,
  approveStatus: storeState.notifications.approveStatus,
  updateStatusPlan: storeState.customerActivityPlan.updateStatus,
  deleteStatusPlan: storeState.customerActivityPlan.deleteStatus,
  billingStatus: storeState.billing.statusAdminCustomerBilling,
  statusProcessFutureCharge: storeState.billing.statusProcessFutureCharge,
  billingProcessFutureCharge: storeState.billing.billing,
  updateCustomerStatus: storeState.customer.updateCustomerStatus,
  initNewPasswordStatus: storeState.user.initNewPasswordStatus
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  resetCustomer,
  fetchCustomerById,
  getCoParticipationCustomerToRenewal,
  tryFindCoParticipationCustomer,
  resetCoParticipationCustomer,
  getBillings,
  resetBilling,
  rescheduleActivitySchedules,
  resetCustomerActivity,
  getActivityByPartnerPlaceToCustomer,
  resetActivitiesStatus,
  getCustomerActivityPlan,
  resetCustomerActivityPlan,
  getKeikenPayInvoices,
  resetKeikenPay,
  deleteCustomerActivity,
  updateCustomerActivityPlan,
  deleteCustomerActivityPlan,
  fetchPartnerPlacesFantasyName,
  forceProcessFutureCharge,
  createCustomerBilling,
  putUpdateCustomerAndPlanStatus,
  fetchCustomerWalletByCustomerId,
  updateBlockCoparticipationCustomer,
  updateTokenUsedByAdmin,
  initNewPassword,
  resetNewPassword: reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ICustomersManage);
