import axios from 'axios';
import _ from 'lodash';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import StringUtils from 'app/util/StringUtils';
import { IAddress } from '../../shared/model/address.model';
import { IPageRequest } from 'app/shared/model/page.model';

export const ACTION_TYPES = {
  FETCH_ADDRESS: 'address/FETCH_ADDRESS',
  RESET: 'address/RESET'
};

const initialState = { data: null };

export type AddressState = Readonly<typeof initialState>;

export default (state: AddressState = initialState, action): AddressState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ADDRESS):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.FETCH_ADDRESS):
      return {
        ...initialState
      };
    case SUCCESS(ACTION_TYPES.FETCH_ADDRESS):
      return {
        ...initialState,
        data: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export const fetchAddresses = (page?: IPageRequest, address?: string) => {
  let params;

  if (!StringUtils.isStringInvalid(address)) {
    params = {
      address
    };
  }

  return {
    type: ACTION_TYPES.FETCH_ADDRESS,
    payload: !!params ? axios.get('api/address/geolocalize', { params }) : axios.get('api/address/geolocalize')
  };
};
