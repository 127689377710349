import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IPageRequest } from 'app/shared/model/page.model';
import { IAssessment } from 'app/shared/model/assessment';

export const ACTION_TYPES = {
  FETCH_ASSESSMENT: 'assessment/FETCH_ASSESSMENT',
  UPDATE_ASSESSMENT: 'assessment/UPDATE_ASSESSMENT',
  RESET: 'assessment/RESET'
};

const initialState = {
  fetchStatus: HttpRequestStatus.NOOP,
  updateStatus: HttpRequestStatus.NOOP,
  page: undefined
};

export type IAssessmentState = Readonly<typeof initialState>;

export default (state: IAssessmentState = initialState, action): IAssessmentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ASSESSMENT):
      return {
        ...initialState,
        fetchStatus: HttpRequestStatus.ONGOING
      };
    case SUCCESS(ACTION_TYPES.FETCH_ASSESSMENT):
      return {
        ...initialState,
        fetchStatus: HttpRequestStatus.SUCCESS,
        page: action.payload.data
      };
    case FAILURE(ACTION_TYPES.FETCH_ASSESSMENT):
      return {
        ...initialState,
        fetchStatus: HttpRequestStatus.ERROR
      };

    // UPDATE
    case REQUEST(ACTION_TYPES.UPDATE_ASSESSMENT):
      return {
        ...initialState,
        updateStatus: HttpRequestStatus.ONGOING
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ASSESSMENT):
      return {
        ...initialState,
        updateStatus: HttpRequestStatus.SUCCESS
      };
    case FAILURE(ACTION_TYPES.UPDATE_ASSESSMENT):
      return {
        ...initialState,
        updateStatus: HttpRequestStatus.ERROR
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/admin/assessment';

export const fetchAssessments = (page: IPageRequest, filter?: any) => {
  const params = {
    size: page?.size ?? 20,
    page: page?.page ?? 0,
    ...filter,
    sort: 'createdDate,desc'
  };

  return {
    type: ACTION_TYPES.FETCH_ASSESSMENT,
    payload: axios.get(apiUrl, {
      params
    })
  };
};

export const updateAssessmentStatus = (assessment: IAssessment) => ({
  type: ACTION_TYPES.UPDATE_ASSESSMENT,
  payload: axios.patch(`${apiUrl}/${assessment.id}/status`, assessment)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
