import { IPage } from './../../shared/model/page.model';
import { ICustomerActivityPlan } from 'app/shared/model/customer-activity-plan';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import axios from 'axios';

export const ACTION_TYPES = {
  GET_CUSTOMER_ACTIVITY_PLAN: 'customerActivityPlan/GET_CUSTOMER_ACTIVITY_PLAN',
  DELETE_CUSTOMER_ACTIVITY_PLAN: 'customerActivityPlan/DELETE_CUSTOMER_ACTIVITY_PLAN',
  UPDATE_CUSTOMER_ACTIVITY_PLAN: 'customerActivityPlan/UPDATE_CUSTOMER_ACTIVITY_PLAN',
  CREATE_FUTURE_CHARGE_BILLING: 'customerActivityPlan/CREATE_FUTURE_CHARGE_BILLING',
  RESET: 'customerActivityPlan/RESET'
};

const initialState = {
  status: HttpRequestStatus.NOOP,
  deleteStatus: HttpRequestStatus.NOOP,
  updateStatus: HttpRequestStatus.NOOP,
  statusFutureCharge: HttpRequestStatus.NOOP,
  page: null as IPage<ICustomerActivityPlan>,
  customerActivityPlan: null as ICustomerActivityPlan
};

export type ICustomerActivityPlanState = Readonly<typeof initialState>;

// Reducer
export default (
  state: ICustomerActivityPlanState = initialState,
  action: IActionForResponseEntity<any>
): ICustomerActivityPlanState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_PLAN):
      return { ...state, status: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_PLAN):
      return { ...state, status: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.GET_CUSTOMER_ACTIVITY_PLAN):
      return { ...state, status: HttpRequestStatus.SUCCESS, page: action.payload.data };
    case REQUEST(ACTION_TYPES.DELETE_CUSTOMER_ACTIVITY_PLAN):
      return { ...state, deleteStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.DELETE_CUSTOMER_ACTIVITY_PLAN):
      return { ...state, deleteStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.DELETE_CUSTOMER_ACTIVITY_PLAN):
      return { ...state, deleteStatus: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.UPDATE_CUSTOMER_ACTIVITY_PLAN):
      return { ...state, updateStatus: HttpRequestStatus.ONGOING };
    case FAILURE(ACTION_TYPES.UPDATE_CUSTOMER_ACTIVITY_PLAN):
      return { ...state, updateStatus: HttpRequestStatus.ERROR };
    case SUCCESS(ACTION_TYPES.UPDATE_CUSTOMER_ACTIVITY_PLAN):
      return { ...state, updateStatus: HttpRequestStatus.SUCCESS };
    case REQUEST(ACTION_TYPES.CREATE_FUTURE_CHARGE_BILLING):
      return {
        ...initialState,
        statusFutureCharge: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.CREATE_FUTURE_CHARGE_BILLING):
      return {
        ...initialState,
        statusFutureCharge: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.CREATE_FUTURE_CHARGE_BILLING):
      return {
        ...initialState,
        statusFutureCharge: HttpRequestStatus.SUCCESS,
        customerActivityPlan: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        status: HttpRequestStatus.NOOP,
        deleteStatus: HttpRequestStatus.NOOP,
        updateStatus: HttpRequestStatus.NOOP,
        statusFutureCharge: HttpRequestStatus.NOOP
      };
    default:
      return state;
  }
};

const apiUrl = '/api/admin/customer-activity-plan';

export const getCustomerActivityPlan = (params: any) => ({
  type: ACTION_TYPES.GET_CUSTOMER_ACTIVITY_PLAN,
  payload: axios.get(`${apiUrl}`, { params })
});

export const deleteCustomerActivityPlan = (item: ICustomerActivityPlan) => ({
  type: ACTION_TYPES.DELETE_CUSTOMER_ACTIVITY_PLAN,
  payload: axios.delete(`${apiUrl}/${item.id}`)
});

export const updateCustomerActivityPlan = (item: ICustomerActivityPlan) => ({
  type: ACTION_TYPES.UPDATE_CUSTOMER_ACTIVITY_PLAN,
  payload: axios.patch(`${apiUrl}/${item.id}`, item)
});

export const createFutureChargeBilling = (id: number, renewalId: number) => ({
  type: ACTION_TYPES.CREATE_FUTURE_CHARGE_BILLING,
  payload: axios.post(`/api/admin/customer-activity-plan/${id}/preset-renewal-activity-plan/${renewalId}/future-charge`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
