import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import './system-fee-manage.scss';
import { ISystemFee } from 'app/shared/model/system-fee.model';
import { insertSystemFee, updateSystemFee, reset } from 'app/entities/system-fee/system-fee-reducer';
import InputField from 'app/components/input-field/InputField';
import { translate, Translate } from 'react-jhipster';
import NumberUtils from 'app/util/NumberUtils';
import { SystemFeeType } from 'app/shared/model/enum/SystemFeeType';
// tslint:disable-next-line:no-duplicate-imports
import { ChangeEvent } from 'react';
import Button from 'app/components/button/Button';
import { toast } from 'react-toastify';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ISystemFeeManageProps
  extends StateProps,
  DispatchProps,
  RouteComponentProps<{}, any, { systemFee: any; parterPlaceId: any } | any> { }

export interface ISystemFeeManageState {
  systemFee?: ISystemFee;
  partnerPlaceId?: number;
  isErrors?: { [key: string]: boolean | undefined };
  isValids?: { [key: string]: boolean | undefined };
}

export class SystemFeeManage extends React.Component<ISystemFeeManageProps, ISystemFeeManageState> {
  constructor(props) {
    super(props);
    this.state = {
      systemFee: {
        fixed: undefined,
        percentage: undefined,
        type: SystemFeeType.PERCENTAGE
      },
      isErrors: {
        fixed: undefined,
        percentage: undefined
      },
      isValids: {
        fixed: undefined,
        percentage: undefined
      }
    };
  }

  componentDidMount() {
    if (this.props.location.state != null && this.props.location.state.systemFee != null) {
      this.setState({
        systemFee: this.props.location.state.systemFee
      });
    }

    if (this.props.location.state != null && this.props.location.state.partnerPlaceId != null) {
      this.setState({
        partnerPlaceId: this.props.location.state.partnerPlaceId
      });
    } else {
      this.props.history.push('partner-place-list');
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.saveSystemFee === HttpRequestStatus.SUCCESS) {
      toast.success(translate('systemFeeManage.messages.success'), {
        onClose: () => this.props.history.push('partner-place-list'),
        autoClose: 2000
      });
      this.props.reset();
    }
  }

  private validateForm = (): boolean => {
    let result = true;
    let fixedError = false;
    let fixedIsValid = true;
    let percentageError = false;
    let percentageIsValid = true;

    if (!NumberUtils.isValidNumber(this.state.systemFee.fixed) && this.state.systemFee.type !== SystemFeeType.PERCENTAGE) {
      fixedError = true;
      fixedIsValid = false;
      result = false;
    }
    if (!NumberUtils.isValidNumber(this.state.systemFee.percentage) && this.state.systemFee.type !== SystemFeeType.FIXED) {
      percentageError = true;
      percentageIsValid = false;
      result = false;
    }

    this.setState({
      isErrors: { ...this.state.isErrors, ['fixed']: fixedError, ['percentage']: percentageError },
      isValids: { ...this.state.isValids, ['fixed']: fixedIsValid, ['percentage']: percentageIsValid }
    });
    return result;
  };

  private handleSaveSystemFee = () => {
    if (!this.validateForm()) {
      return;
    }
    return this.state.systemFee.id == null
      ? this.props.insertSystemFee(this.state.systemFee, this.state.partnerPlaceId)
      : this.props.updateSystemFee(this.state.systemFee);
  };

  private handleReturnValidStateValue = (systemFee: ISystemFee, fieldKey: string) => {
    return systemFee != null ? systemFee[fieldKey] : '';
  };

  private handleChanged = (fieldKey: string, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      systemFee: { ...this.state.systemFee, [fieldKey]: value },
      isErrors: { ...this.state.isErrors, [fieldKey]: false },
      isValids: { ...this.state.isValids, [fieldKey]: false }
    });
  };

  private renderFixedInput = () => {
    return (
      <div className={'input-container'}>
        <InputField
          title={translate('systemFeeManage.placeholders.fixed')}
          error={this.state.isErrors['fixed']}
          valid={this.state.isValids['fixed']}
          onChange={this.handleChanged.bind(this, 'fixed')}
          inputClassName={''}
          maxLength={10}
          value={this.handleReturnValidStateValue(this.state.systemFee, 'fixed')}
          type={'number'}
          placeholder={'systemFeeManage.placeholders.fixed'}
        />
      </div>
    );
  };

  private renderPercentageInput = () => {
    return (
      <div className={'input-container'}>
        <InputField
          title={translate('systemFeeManage.placeholders.percentage')}
          error={this.state.isErrors['percentage']}
          valid={this.state.isValids['percentage']}
          onChange={this.handleChanged.bind(this, 'percentage')}
          inputClassName={''}
          maxLength={10}
          value={this.handleReturnValidStateValue(this.state.systemFee, 'percentage')}
          type={'number'}
          placeholder={'systemFeeManage.placeholders.percentage'}
        />
      </div>
    );
  };

  render() {
    return (
      <div className={'form-container'}>
        <div className={'title-screen-container'}>
          <div onClick={() => this.props.history.push('partner-place-list')}>
            <FontAwesomeIcon className={'back-arrow-icon'} icon={'arrow-left'} />
          </div>
          <label className={'system-fee-manage-title'}>
            <Translate contentKey={'systemFeeManage.labels.editSystemFee'} />
          </label>
        </div>
        {this.state.systemFee.type !== SystemFeeType.FIXED && this.renderPercentageInput()}
        {this.state.systemFee.type !== SystemFeeType.PERCENTAGE && this.renderFixedInput()}
        <div className={'partner-register-input-divisor-selector'}>
          <label className={'partner-register-input-label'}>
            <Translate contentKey={'systemFeeManage.labels.type'} />
          </label>
          <select
            onChange={this.handleChanged.bind(this, 'type')}
            value={this.handleReturnValidStateValue(this.state.systemFee, 'type')}
            className={'partner-register-select'}
          >
            {Object.values(SystemFeeType).map((item, index) => (
              <option value={item} key={item + index}>
                {translate(`systemFeeManage.types.${item}`)}
              </option>
            ))}
          </select>
        </div>
        <div className={'marginT3'}>
          <Button onClick={this.handleSaveSystemFee} title={translate('partner.register.buttons.confirm')} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  saveSystemFee: storeState.systemFee.saveSystemFee
});

const mapDispatchToProps = { insertSystemFee, updateSystemFee, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemFeeManage);
