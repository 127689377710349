import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import './subcategory-manage.scss';
import {
  createActivitySubCategory,
  updateActivitySubCategory,
  reset
} from 'app/entities/activity-sub-category/activity-sub-category-reducer';
import { IActivitySubCategory } from 'app/shared/model/activity-sub-category';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'app/components/button/Button';
import InputField from 'app/components/input-field/InputField';
import { toast } from 'react-toastify';

export interface ISubCategoryManageProps
  extends StateProps,
  DispatchProps,
  RouteComponentProps<{}, any, { editSubCategory: any; activitySubCategory: any } | any> { }

export interface ISubCategoryManageState {
  activitySubCategory?: IActivitySubCategory;
  editSubCategory?: boolean;
  buttonDisabled?: boolean;
  isErrors?: { [key: string]: boolean | undefined };
  isValids?: { [key: string]: boolean | undefined };
}

export class ISubCategoryManage extends React.Component<ISubCategoryManageProps, ISubCategoryManageState> {
  constructor(props) {
    super(props);
    this.state = {
      activitySubCategory: {
        name: undefined
      },
      isErrors: {
        name: undefined
      },
      isValids: {
        name: undefined
      }
    };
  }

  private validateForm = (): boolean => {
    let result = true;
    let validName = true;
    let errorName = false;

    if (this.state.activitySubCategory.name === undefined || this.state.activitySubCategory.name === '') {
      result = false;
      validName = false;
      errorName = true;
    }

    this.setState({
      isErrors: { ...this.state.isErrors, ['name']: errorName },
      isValids: { ...this.state.isValids, ['name']: validName }
    });

    return result;
  };

  componentDidMount() {
    if (this.props.location.state != null && this.props.location.state.activitySubCategory != null) {
      this.setState({
        activitySubCategory: this.props.location.state.activitySubCategory
      });
    }

    if (this.props.location.state != null && this.props.location.state.editSubCategory != null) {
      this.setState({
        editSubCategory: this.props.location.state.editSubCategory
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.createSubCategory === HttpRequestStatus.SUCCESS) {
      this.props.reset();
      toast.success(translate('subCategoryManage.messages.createSuccess'), {
        onClose: () => this.props.history.push('subcategories-list'),
        autoClose: 2000
      });
      return;
    }

    if (newProps.updateSubCategory === HttpRequestStatus.SUCCESS) {
      this.props.reset();
      toast.success(translate('subCategoryManage.messages.editSuccess'), {
        onClose: () => this.props.history.push('subcategories-list'),
        autoClose: 2000
      });
      return;
    }
  }

  private handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const newImage = this.state.activitySubCategory.image || {};
      const contentType = selectedFile.type;
      newImage.contentType = contentType;
      newImage.fileName = selectedFile.name;
      newImage.imageUrl = undefined;
      newImage.file = ev.target.result.split(',')[1];
      this.setState(prevState => ({ activitySubCategory: { ...prevState.activitySubCategory, image: newImage } }));
    };
    reader.readAsDataURL(selectedFile);
  };

  private renderImageToShow = (): JSX.Element => {
    if (this.state.activitySubCategory.image == null) {
      return (
        <div>
          <label className={'category-manage-title'}>
            <Translate contentKey={'categoryManage.labels.noneImage'} />
          </label>
        </div>
      );
    }

    return (
      <div>
        {this.state.activitySubCategory.image.imageUrl ? (
          <img src={this.state.activitySubCategory.image ? this.state.activitySubCategory.image.imageUrl : ''} />
        ) : (
            <img src={`data:image/jpeg;base64,${this.state.activitySubCategory.image.file}`} />
          )}
      </div>
    );
  };

  private handleCreate = () => {
    this.props.createActivitySubCategory(this.state.activitySubCategory);
  };

  private handleUpdate = () => {
    this.props.updateActivitySubCategory(this.state.activitySubCategory);
  };

  private handleSave = () => {
    if (!this.validateForm() || this.state.buttonDisabled) {
      return;
    }

    this.setState({
      buttonDisabled: true
    });

    if (this.state.editSubCategory) {
      this.handleUpdate();
    } else {
      this.handleCreate();
    }
  };

  private handleChanged = (fieldKey: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      activitySubCategory: { ...this.state.activitySubCategory, [fieldKey]: value },
      isErrors: { ...this.state.isErrors, [fieldKey]: false },
      isValids: { ...this.state.isValids, [fieldKey]: false }
    });
  };

  handleTextChanged = (text: string, propertyToChange: string) => {
    this.setState(prevState => ({ activitySubCategory: { ...prevState.activitySubCategory, [propertyToChange]: text } }));
  };

  render() {
    return (
      <div className={'form-container'}>
        <div className={'title-screen-container'}>
          <div onClick={() => this.props.history.push('subcategories-list')}>
            <FontAwesomeIcon className={'back-arrow-icon'} icon={'arrow-left'} />
          </div>
          <label className={'subcategory-manage-title'}>
            <Translate
              contentKey={
                this.state.editSubCategory ? 'subCategoryManage.labels.editSubCategory' : 'subCategoryManage.labels.createSubCategory'
              }
            />
          </label>
        </div>

        <div className={'category-manage-image-container'}>
          {this.renderImageToShow()}

          <div className={'category-manage-button-add-image'}>
            <input
              id={'myFileInput'}
              className={'edit-activity-step1-image-input'}
              onChange={this.handleImage}
              accept={'image/*'}
              type={'file'}
            />
            <Button
              onClick={() => document.getElementById('myFileInput').click()}
              title={translate(
                this.state.activitySubCategory.image ? 'categoryManage.button.changeImage' : 'categoryManage.button.addImage'
              )}
            />
          </div>
        </div>

        <div className={'input-container'}>
          <InputField
            title={translate('subCategoryManage.placeholders.name')}
            onChange={this.handleChanged.bind(this, 'name')}
            error={this.state.isErrors['name']}
            valid={this.state.isValids['name']}
            inputClassName={''}
            value={!!this.state.activitySubCategory.name ? this.state.activitySubCategory.name : ''}
            placeholder={'subCategoryManage.placeholders.name'}
          />
        </div>

        <div className={'input-container'}>
          <InputField
            onChange={event => this.handleTextChanged(event.target.value, 'titleDescription')}
            title={translate('subCategoryManage.placeholders.titleDescription')}
            inputClassName={'title-description-screen-container'}
            value={!!this.state.activitySubCategory.titleDescription ? this.state.activitySubCategory.titleDescription : ''}
            placeholder={'subCategoryManage.placeholders.titleDescription'}
            maxLength={100}
          />
        </div>

        <div className={'input-container'}>
          <InputField
            onChange={event => this.handleTextChanged(event.target.value, 'description')}
            title={translate('subCategoryManage.placeholders.description')}
            inputClassName={'description-screen-container'}
            value={!!this.state.activitySubCategory.description ? this.state.activitySubCategory.description : ''}
            placeholder={'subCategoryManage.placeholders.description'}
            maxLength={1500}
            isTextArea
          />
        </div>
        <div className={'marginT1'}>
          <Button
            onClick={this.handleSave}
            title={translate(this.state.editSubCategory ? 'subCategoryManage.button.edit' : 'subCategoryManage.button.create')}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  updateSubCategory: storeState.activitySubCategories.updateSubCategory,
  createSubCategory: storeState.activitySubCategories.createSubCategory
});

const mapDispatchToProps = { updateActivitySubCategory, createActivitySubCategory, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ISubCategoryManage);
