import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'app/components/button/Button';

import appConfig from 'app/config/constants';
import './header.css';

export const NavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id}>
    <DropdownToggle nav caret className="d-flex align-items-center">
      <FontAwesomeIcon className={'dropdown-navbar-text'} icon={props.icon} />
      <span className={'dropdown-navbar-text'}>{props.name}</span>
    </DropdownToggle>
    <DropdownMenu right style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/header/logo-keiken.png" alt="Logo" />
  </div>
);

export const Brand = props => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <div style={{ height: '50px', display: 'flex', alignItems: 'center' }}>
      <BrandIcon />
    </div>
  </NavbarBrand>
);

export const Home = props => (
  <a className="text-enter" href="#login">
    <Translate contentKey="global.menu.login" />
  </a>
);
export const Partner = props => (
  <Button
    onClick={props.onClick}
    title={translate('global.menu.ispartner')}
    buttonLabelClassName={'header-select-image-button-label'}
    outerClassName={'header-select-image-button-background-outer-class-name'}
    innerClassName={'header-select-image-button-background-inner-class-name'}
  />
);
