import * as React from 'react';
import { connect } from 'react-redux';
import { ICompany } from 'app/shared/model/company';
import { saveCompanyDiscountFee, getCompanyById } from 'app/entities/company/company-redux';
import { IRootState } from 'app/shared/reducers';
import { Row, Col, Button as RButton } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate, Translate } from 'react-jhipster';
import { useEffect, useState } from 'react';
import InputField from 'app/components/input-field/InputField';
import { DiscountFeeType } from 'app/shared/model/enum/SystemFeeType';
import { IDiscountFee } from 'app/shared/model/system-fee.model';
import Button from 'app/components/button/Button';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { toast } from 'react-toastify';

interface Props extends StateProps, DispatchProps {
  company?: ICompany;
}

const CompanyDiscountFeeManage = (props: Props) => {

  const [show, setShow] = useState<boolean>(false);
  const discountFee = props.company?.discountFee;

  function handleOnConfirm(item: IDiscountFee) {
    props.saveCompanyDiscountFee(props?.company?.id, item);
  }

  useEffect(() => {
    if (props.discountFeeStatus === HttpRequestStatus.SUCCESS) {
      toast.success(translate('companyDiscountFee.messages.success'));
      setTimeout(() => setShow(false), 500);
      props.getCompanyById(props?.company?.id);
    }
  }, [props.discountFeeStatus]);

  return (
    <div className={'input-container'}>
      <RButton className={'centered-content marginB2'} onClick={() => setShow(prev => !prev)}>
        <FontAwesomeIcon icon={show == true ? 'times' : 'check'} className={'marginR1'} />{' '}
        <Translate contentKey={'companyManage.buttons.editDiscountFee'} />
      </RButton>
      {show == true && (
        <CompanyDiscountFee discountFee={discountFee} onConfirm={handleOnConfirm} />
      )}
    </div>
  );
};

const CompanyDiscountFee = ({ discountFee, onConfirm }: { discountFee: IDiscountFee, onConfirm?: (item: IDiscountFee) => void }) => {
  const [currDiscountFee, setCurrDiscountFee] = useState<IDiscountFee>(discountFee ?? {
    type: DiscountFeeType.PERCENTAGE,
    percentage: 12.51
  });

  function handleChangeType(e) {
    setCurrDiscountFee(prev => ({
      ...prev,
      type: e?.target?.value
    }));
  }

  function handleChanged(e, field) {
    setCurrDiscountFee(prev => ({
      ...prev,
      [field]: e?.target?.value
    }));
  }

  function handleOnConfirm() {
    onConfirm && onConfirm(currDiscountFee);
  }

  return (
    <>
      <div className={'partner-register-input-divisor-selector'}>
        <label className={'partner-register-input-label'}>
          <Translate contentKey={'systemFeeManage.labels.type'} />
        </label>
        <select
          onChange={handleChangeType}
          value={currDiscountFee?.type}
          className={'partner-register-select'}
        >
          {Object.values(DiscountFeeType).map((item, index) => (
            <option value={item} key={item + index}>
              {translate(`companyDiscountFee.types.${item}`)}
            </option>
          ))}
        </select>
      </div>
      {(currDiscountFee?.type == DiscountFeeType.FIXED || currDiscountFee?.type == DiscountFeeType.FIXED_AND_PERCENTAGE) && (
        <div className={'input-container'}>
          <InputField
            title={translate('companyDiscountFee.placeholders.fixed')}
            onChange={e => handleChanged(e, 'fixed')}
            inputClassName={''}
            value={currDiscountFee?.fixed}
            type={'number'}
            placeholder={'companyDiscountFee.placeholders.fixed'}
          />
        </div>
      )}
      {(currDiscountFee?.type == DiscountFeeType.PERCENTAGE || currDiscountFee?.type == DiscountFeeType.FIXED_AND_PERCENTAGE) && (
        <div className={'input-container'}>
          <InputField
            title={translate('companyDiscountFee.placeholders.percentage')}
            onChange={e => handleChanged(e, 'percentage')}
            inputClassName={''}
            value={currDiscountFee?.percentage}
            type={'number'}
            placeholder={'companyDiscountFee.placeholders.percentage'}
          />
        </div>
      )}
      {(currDiscountFee?.type == DiscountFeeType.FINAL_PRICE) && (
        <div className={'input-container'}>
          <InputField
            title={translate('companyDiscountFee.placeholders.finalPrice')}
            onChange={e => handleChanged(e, 'finalPrice')}
            inputClassName={''}
            value={currDiscountFee?.finalPrice}
            type={'number'}
            placeholder={'companyDiscountFee.placeholders.finalPrice'}
          />
        </div>
      )}
      <div className={'marginT15'}>
        <Button onClick={handleOnConfirm} title={translate('companyDiscountFee.buttons.saveDiscountValue')} />
      </div>
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  discountFeeStatus: storeState.company?.discountFeeStatus
});

const mapDispatchToProps = {
  saveCompanyDiscountFee,
  getCompanyById
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDiscountFeeManage);
