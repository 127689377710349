import React from 'react';
import './sectionMenu.css';
import { translate } from 'react-jhipster';
import { IMenuSection, IMenuStyles, IMenuItem } from './sectionMenuModels';

export interface ISectionMenuItemProps {
  menuSections: IMenuSection[];
  menuStyles?: IMenuStyles;
}

export interface ISectionMenuItemState {
  currentMenuStyles: IMenuStyles;
}

export class SectionMenu extends React.Component<ISectionMenuItemProps, ISectionMenuItemState> {
  constructor(props) {
    super(props);

    this.state = {
      currentMenuStyles: this.setCurrentMenuStyles(this.props.menuStyles)
    };
  }

  componentWillReceiveProps(newProps: ISectionMenuItemProps) {
    this.setCurrentMenuStyles(newProps.menuStyles);
  }

  private setCurrentMenuStyles = (newMenuStyles?: IMenuStyles): IMenuStyles => {
    let newStyle: IMenuStyles;
    if (newMenuStyles) {
      newStyle = {
        itemActiveTitle: newMenuStyles.itemActiveTitle || 'item-title-label-active',
        itemTitle: newMenuStyles.itemTitle || 'item-title-label',
        sectionTitle: newMenuStyles.sectionTitle || 'section-title-label'
      };
    } else {
      newStyle = {
        itemTitle: 'item-title-label',
        sectionTitle: 'section-title-label'
      };
    }

    return newStyle;
  };

  private renderItemMenu = (menuItem: IMenuItem) => {
    const itemTitleStyle: string = menuItem.active ? this.state.currentMenuStyles.itemActiveTitle : this.state.currentMenuStyles.itemTitle;
    return (
      <div className={'menu-item-container'} key={menuItem.title}>
        <label className={itemTitleStyle} onClick={menuItem.onPressItem}>
          {menuItem.title}
        </label>
      </div>
    );
  };

  private renderSectionMenu = (menuSection: IMenuSection, index: number) => {
    const showLine: boolean = this.props.menuSections.length - 1 !== index;
    return (
      <div key={menuSection.title}>
        <div className={'menu-section-title-container'}>
          <label className={'section-title-label'}>{menuSection.title}</label>
        </div>

        {menuSection.items.map((item: IMenuItem) => this.renderItemMenu(item))}

        {showLine && <div className={'menu-section-line'} />}
      </div>
    );
  };

  render() {
    return (
      <div className={'menu-main-container'}>
        <div className={'menu-list-container'}>
          {this.props.menuSections.map((section: IMenuSection, index: number) => this.renderSectionMenu(section, index))}
        </div>
      </div>
    );
  }
}

export default SectionMenu;
