import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { KeikenPayInvoice, KeikenPayInvoiceStatus } from '../../../../shared/model/KeikenPay/KeikenPayInvoice';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import './denyKeikenPayModal.css';
import StringUtils from '../../../../util/StringUtils';
import { updateKeikenPayInvoiceStatus } from '../../../../entities/keiken-pay/keiken-pay-reducer';
import { HttpRequestStatus } from '../../../../shared/model/enum/HttpRequestStatus';
import { TailSpin } from 'react-loader-spinner';

export interface IDenyKeikenPayModalProps extends StateProps,
  DispatchProps {
  config: {
    invoice: KeikenPayInvoice;
    onClose: () => void;
  };

}

const DenyKeikenPayModal: React.FC<IDenyKeikenPayModalProps> = props => {
  const [selectedReason, setSelectedReason] = React.useState('');
  const [customReason, setCustomReason] = React.useState('');
  const [isCustomReasonSelected, setIsCustomReasonSelected] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const updateKeikenPayStatus = props.updateInvoiceStatus;
  React.useEffect(() => {
    if (updateKeikenPayStatus === HttpRequestStatus.SUCCESS) {
      setLoading(false);
      props.config.onClose();
    }
  }, [updateKeikenPayStatus]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedReason(event.target.value);
    setIsCustomReasonSelected(false);
  };

  const handleCustomReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedReason('');
    setCustomReason(event.target.value);
    setIsCustomReasonSelected(true);
  };

  const handleSubmit = () => {
    setLoading(true);
    const reason = isCustomReasonSelected ? customReason : selectedReason;
    props.updateKeikenPayInvoiceStatus(props.config.invoice?.id!, KeikenPayInvoiceStatus.DENIED, reason, undefined, undefined);
  };

  const possibleReasons = [
    'A data da nota fiscal ou recibo não confere com o mês da solicitação do reembolso. Apenas notas do mês vigente serão reembolsadas.',
    'O número de registro do profissional não consta no comprovante (CRP, CRN, CREFITO e afins). Solicite ao profissional um comprovante que contenha o registro do profissional ou carimbo do respectivo conselho e peça o reembolso novamente.',
    'O comprovante de pagamento não foi anexado (comprovante de PIX, comprovante de pagamento no cartão). Por favor, solicite o reembolso novamente, anexando o comprovante corretamente.',
    'A Nota Fiscal ou recibo de pagamento não foi anexado. Por favor, solicite o reembolso novamente e anexe a Nota Fiscal corretamente.',
    'O ramo de atuação do CNPJ informado não confere com as regras e categorias de utilização do seu benefício.',
    'Não conseguimos identificar a sua chave PIX, ela pode estar incorreta ou os dados do destinatário não correspondem com o solicitante. Por favor, atualize a chave no aplicativo e solicite o reembolso novamente.',
    'A foto ou anexo está ilegível. Por favor, solicite o reembolso novamente.',
    'O recibo não está em seu nome.'
  ];

  const { config } = props;
  return (
    <Modal
      isOpen
      toggle={props.config.onClose}
      centered
      size="lg"
    >

      <ModalHeader>
        <h1 style={{ fontSize: 18 }}>Escolha uma das alternativas ou descreva o motivo pelo qual o keiken pay foi negado.</h1>
      </ModalHeader>

      <ModalBody className="body">

        {loading ? (
          <span>
            <TailSpin height="30" width="30" color="black" />
          </span>
        ) : (
          <div>
            <h3 className="title">Informações do keiken pay:</h3>

            <p className="mt">Nome: {config.invoice.customer?.name}</p>
            <p>Email: {config.invoice.customer?.user?.email}</p>
            <p>Valor: {StringUtils.currencyPtBr(config.invoice.price)}</p>

            <h3 className="title">Motivo da negação:</h3>

            <div className="mt">
              {possibleReasons.map((reason, index) => (
                <FormGroup key={index} className="formGroup">
                  <Label check>
                    <Input className="radioInput" type="radio" name="reason" value={reason} onChange={handleRadioChange} checked={selectedReason === reason} />{' '}
                    {reason}
                  </Label>
                </FormGroup>
              ))}
            </div>

            <FormGroup className="formGroup">
              <Label check>
                <Input className="radioInput" type="radio" name="reason" value="Outro motivo" onChange={handleCustomReasonChange} checked={isCustomReasonSelected} />{' '}
                Outro motivo
              </Label>
            </FormGroup>

            {isCustomReasonSelected && (
              <FormGroup className="formGroup">
                <Label for="customReason">Descreva o motivo:</Label>
                <Input className="textArea" type="textarea" name="customReason" id="customReason" onChange={handleCustomReasonChange} value={customReason} />
              </FormGroup>
            )}

          </div>
        )}

      </ModalBody>

      <ModalFooter>
        <Button onClick={handleSubmit}>Enviar</Button>
      </ModalFooter>

    </Modal>
  );
};

const mapStateToProps = (storaState: IRootState, ownProps: any) => ({
  updateInvoiceStatus: storaState.keikenPay.updateInvoiceStatus
});

const mapDispatchToProps = { updateKeikenPayInvoiceStatus };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DenyKeikenPayModal);
