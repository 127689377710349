import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { getPaginationItemsNumber, JhiPagination, translate, Translate } from 'react-jhipster';
import { Row, Button } from 'reactstrap';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { debounce } from 'lodash';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import './price-table-screen-list.scss';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import InputField from 'app/components/input-field/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPriceTable } from 'app/shared/model/price-table';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import {
  fetchPriceTable,
  getPriceTableById,
  updatePriceTable,
  deletePriceTable,
  reset
} from 'app/entities/price-table/price-table-redux';
import { MODAL_TYPE_WARNING } from 'app/components/modal/modal-types';

export interface IPriceTablePageScreenProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IPriceTablePageScreenState {
  pageRequest: IPageRequest;
  priceTablePage?: IPage<IPriceTable>;
  searchEmail?: string;
  searchName?: string;
}

class PriceTablePageScreen extends React.Component<IPriceTablePageScreenProps, IPriceTablePageScreenState> {
  constructor(props: IPriceTablePageScreenProps) {
    super(props);
    this.state = {
      pageRequest: {
        page: 0,
        size: 20
      }
    };
    this.performAction = debounce(this.performAction, 500);
  }

  componentDidMount() {
    this.props.fetchPriceTable(this.state.pageRequest);
  }

  componentWillReceiveProps(newProps: IPriceTablePageScreenProps) {
    if (newProps.fetchPriceTableStatus === HttpRequestStatus.SUCCESS) {
      this.setState({
        priceTablePage: newProps.page
      });
      this.props.reset();
    }
    if (newProps.deletePriceTableStatus === HttpRequestStatus.SUCCESS) {
      this.setState({
        priceTablePage: newProps.page
      });
      this.props.reset();
    }
  }

  private performAction = () => {
    this.props.fetchPriceTable(this.state.pageRequest);
  }

  private handlePagination = (activePage: number) => {
    const object = this.state.pageRequest;
    object.page = activePage - 1;
    this.setState({ pageRequest: object });
    this.props.fetchPriceTable(object);
  };

  private handleEdit = (item: IPriceTable | undefined) => {
    this.props.history.push({
      pathname: `price-table-manage/${item?.id}`,
      state: {
        priceTable: item,
        edit: true
      }
    });
  };

  private handleDelete = (priceTable: IPriceTable | undefined) => {
    this.props.showModal(MODAL_TYPE_WARNING, {
      title: translate('priceTableList.modal.message', { name: priceTable.name }),
      confirmButtonText: 'priceTableList.buttons.delete',
      cancelCallback: () => this.props.hideModal(),
      confirmCallback: () => this.handleOnConfirmCallbackPressed(priceTable)
    });
  };

  handleOnConfirmCallbackPressed = (item: IPriceTable | undefined) => {
    this.props.deletePriceTable(item.id);
  };

  private handleCreate = () => {
    this.props.history.push({
      pathname: 'price-table-manage'
    });
  };

  private handleTransformToTableContent = (content?: IPriceTable[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];
    content?.map?.((item: IPriceTable) => {
      result.push([
        item?.id?.toString() ?? '',
        item?.name ?? '',
        item?.type?.toLowerCase?.() ?? '',
        item?.status?.toLowerCase?.() ?? '',
        this.renderActionColumn(item)]);
    });
    return result;
  };

  private renderActionColumn = (item: IPriceTable | undefined): JSX.Element => {
    return (
      <div className={'action-column-container'}>
        <Button className={'centered-content'} onClick={this.handleEdit.bind(this, item)}>
          <FontAwesomeIcon icon={'check'} className={'marginR1'} />
          <Translate contentKey={'priceTableList.buttons.edit'} />
        </Button>
        <Button className={'centered-content marginL2'} onClick={this.handleDelete.bind(this, item)}>
          <FontAwesomeIcon icon={'trash'} className={'marginR1'} />
          <Translate contentKey={'priceTableList.buttons.delete'} />
        </Button>
      </div>
    );
  };

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state.priceTablePage?.content ?? []);
    return (
      <div className={'partner-place-list-container'}>
        <div className={'table-search-container'} style={{ marginTop: 70, marginBottom: 20 }}>
          <div onClick={this.handleCreate} className={'subcompanies-list-button-display-container'}>
            <img className={'partner-dashboard-img'} src={'content/images/ic-plus-grassgreen.png'} />
            <label className={'partner-dashboard-button-label'}>{translate('priceTableList.buttons.create')}</label>
          </div>
          <div className={'table-search-input-container'}>
            {/* <InputField
              title={translate('companiesList.placeHolders.search')}
              onChange={this.handleChanged}
              inputClassName={''}
              value={!!this.state.searchText ? this.state.searchText : ''}
              placeholder={'companiesList.placeHolders.search'}
            /> */}
          </div>
        </div>
        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('priceTableList.tableHeaders')}
          emptyTableMessage={this.props.fetchPriceTableStatus !== HttpRequestStatus.ONGOING ? translate('priceTableList.labels.emptyList') : ''}
        />
        {tableContent.length > 0 && (
          <Row className={'justify-content-center'}>
            <JhiPagination
              items={
                this.state.priceTablePage ? getPaginationItemsNumber(this.state.priceTablePage.totalElements, this.state.priceTablePage.size) : 0
              }
              activePage={this.state.priceTablePage?.number ?? 0}
              onSelect={this.handlePagination}
              maxButtons={5}
            />
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  page: storeState.priceTable.page,
  fetchPriceTableStatus: storeState.priceTable.fetchPriceTableStatus,
  deletePriceTableStatus: storeState.priceTable.deletePriceTableStatus
});

const mapDispatchToProps = {
  fetchPriceTable,
  getPriceTableById,
  updatePriceTable,
  deletePriceTable,
  showModal,
  hideModal,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceTablePageScreen);
