import React from 'react';
import { Modal } from 'reactstrap';
import './activityDetailsModal.css';
import { translate, Translate } from 'react-jhipster';
import { Button, Row, Col } from 'reactstrap';
import { IActivity } from 'app/shared/model/activity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApprovePriceTableActivities from '../ApprovePriceTableActivities/ApprovePriceTableActivities';
import { IPriceTableActivity } from 'app/shared/model/price-table';
import { IRootState } from 'app/shared/reducers';
import { getPriceTableByActivity, reset as resetPriceTable } from 'app/entities/price-table/price-table-redux';
import { connect } from 'react-redux';
import _ from 'lodash';

export interface IActivityDetailsModalProps extends StateProps,
  DispatchProp {
  config: {
    activity: IActivity;
    onClose: () => void;
    onSave: (priceTableActivities: IPriceTableActivity[]) => void;
    onApprovePartner: () => void;
  };
}

const ActivityDetailsModal: React.FC<IActivityDetailsModalProps> = props => {

  const [priceTableActivities, setPriceTableActivities] = React.useState<IPriceTableActivity[]>();
  React.useEffect(() => {
    if (props.priceTableActivitiesList != null) {
      const table = props.priceTableActivitiesList.map(it => {
        if (it.price == null || it.price == 0) {
          it.new = true;
          it.price = props.config?.activity?.priceWithFee ?? 0;
        }
        return it;
      });
      setPriceTableActivities(table);
      props.resetPriceTable();
    }
  }, [props.priceTableActivitiesList]);

  React.useEffect(() => {
    if (props.config.activity.id != null) {
      props.getPriceTableByActivity(props.config.activity.id);
    }
  }, [props.config.activity.id]);

  const handleOnSave = () => {
    const newPriceTableActivities: IPriceTableActivity[] = _.cloneDeep(priceTableActivities);
    newPriceTableActivities.forEach((element, index) => {
      element.priceWithFee = priceTableActivities[index]?.priceWithFee ? priceTableActivities[index]?.priceWithFee : props.config.activity?.priceWithFee;
    });
    props.config.onSave != null && props.config?.onSave(newPriceTableActivities);
  };
  const handleOnApprovePartner = () => {
    props.config?.onApprovePartner?.();
  };
  const activityTitle: string =
    props.config.activity != null && props.config.activity.name != null ? props.config.activity.name : '';
  const price: string =
    props.config.activity != null && props.config.activity.price != null
      ? `R$ ${String(props.config.activity.price.toFixed(2).replace('.', ','))}`
      : translate('approveActivities.labels.free');
  const slots: string =
    props.config.activity != null && props.config.activity.slots != null
      ? `${String(props.config.activity.slots)} vagas`
      : '';
  const description: string =
    props.config.activity != null && props.config.activity.description != null ? props.config.activity.description : '';
  const duration: string =
    props.config.activity != null && props.config.activity.duration != null
      ? `${String(props.config.activity.duration)} minutos`
      : '';
  const partnerPlaceName: string =
    props.config.activity != null &&
      props.config.activity.partnerPlace != null &&
      props.config.activity.partnerPlace.fantasyName != null
      ? props.config.activity.partnerPlace.fantasyName
      : '';
  const category: string =
    props.config.activity != null &&
      props.config.activity.activityCategories[0] != null &&
      props.config.activity.activityCategories[0].name != null
      ? props.config.activity.activityCategories[0].name
      : '';
  const subcategory: string =
    props.config.activity != null &&
      props.config.activity.activitySubCategories[0] != null &&
      props.config.activity.activitySubCategories[0].name != null
      ? props.config.activity.activitySubCategories[0].name
      : '';
  const imageUrl: string =
    props.config.activity != null && props.config.activity.image != null && props.config.activity.image.imageUrl != null
      ? props.config.activity.image.imageUrl
      : '';

  return (
    <Modal isOpen className={'activity-main-container'}>
      <div className={'activity-image-container'}>
        <div className={'activity-cross-container'} onClick={() => props.config.onClose()}>
          <img src={'./content/images/close-white.png'} className={'cross-img'} />
        </div>
        <img src={imageUrl !== '' ? imageUrl : './content/images/default-customer-activity.png'} className={'customer-activity-img'} />
      </div>
      <div className={'activity-title-container'}>
        <label className={'customer-activity-title'}>{activityTitle}</label>
      </div>
      <div className={'activity-line'} />
      <div className={'activity-list-container'}>
        <div className={'activity-icon-label-container'}>
          <img src={'./content/images/activity-details/user_grassgreen.png'} className={'icon-list-img'} />
          <label className={'activity-icon-label-text'}>{partnerPlaceName}</label>
        </div>
        <div className={'activity-icon-label-container'}>
          <img src={'./content/images/activity-details/category-icon.png'} className={'icon-list-img'} />
          <label className={'activity-icon-label-text'}>{category}</label>
        </div>
        <div className={'activity-icon-label-container'}>
          <img src={'./content/images/activity-details/subcategory-icon.png'} className={'icon-list-img'} />
          <label className={'activity-icon-label-text'}>{subcategory}</label>
        </div>
        <div className={'activity-icon-label-container'}>
          <img src={'./content/images/activity-details/vacancies-icon.png'} className={'icon-list-img'} />
          <label className={'activity-icon-label-text'}>{slots}</label>
        </div>
        <div className={'activity-icon-label-container'}>
          <img src={'./content/images/activity-details/duration_grassgreen.png'} className={'icon-list-img'} />
          <label className={'activity-icon-label-text'}>{duration}</label>
        </div>
        <div className={'activity-icon-label-container'}>
          <img src={'./content/images/activity-details/price-icon.png'} className={'icon-list-img'} />
          <label className={'activity-icon-label-text'}>{price}</label>
        </div>
        <div className={'activity-icon-label-container'}>
          <img src={'./content/images/activity-details/description-icon.png'} className={'icon-list-img'} />
          <label className={'activity-icon-label-text'}>{description}</label>
        </div>

        <ApprovePriceTableActivities
          priceTableActivities={priceTableActivities ?? []}
          priceActivity={props.config.activity?.price}
          priceWithFee={props.config.activity?.priceWithFee}
          onChange={(items: IPriceTableActivity[]) => {
            setPriceTableActivities(items);
          }}
        />
        <Row>
          <Col md="6">
            <Button onClick={handleOnApprovePartner} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FontAwesomeIcon icon={'check'} className={'marginR1'} />
              <Translate contentKey={'approveActivities.buttons.approvePartner'} />
            </Button>
          </Col>
          <Col md="6">
            <Button onClick={handleOnSave} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FontAwesomeIcon icon={'check'} className={'marginR1'} />
              <Translate contentKey={'approveActivities.buttons.approve'} />
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

const mapStateToProps = (root: IRootState) => ({
  priceTableActivitiesList: root.priceTable.priceTableActivitiesList
});

const mapDispatchToProps = { getPriceTableByActivity, resetPriceTable };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProp = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityDetailsModal);
