import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './partner-calendar-activity.css';
import { RouteComponentProps } from 'react-router';
import { IDayOfWeek } from 'app/shared/model/day-of-week';
import { ITimeSlot, ITimeSlotItem } from 'app/shared/model/time-slots';
import moment from 'moment';
import classnames from 'classnames';
import { INewActivity } from 'app/shared/model/new-activity.model';
import { IRootState } from 'app/shared/reducers';
import { getPartnerActivities, getActivityDetails, reset } from 'app/entities/activity/activity';
import { ISimpleActivity } from 'app/shared/model/simple-activity';
import PartnerActivityDetails from './details/partner-activity-details';
import { fetchActivityCategories } from 'app/entities/activity-category/activity-category-reducer';
import { fetchActivitySubCategories } from 'app/entities/activity-sub-category/activity-sub-category-reducer';
import { hideModal, showModal } from '../../../components/modal/modal-action';

export interface IPartnerCalendarActivityProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IPartnerCalendarActivityState {
  data?: any;
  details?: any;
  daysOfWeek: IDayOfWeek[];
  timeSlotsSet: ITimeSlot[];
  activities: INewActivity[];
  isLoaded: boolean;
  showDetails: boolean;
  activity: INewActivity;
}

export class PartnerCalendarActivity extends React.Component<IPartnerCalendarActivityProps, IPartnerCalendarActivityState> {
  constructor(props: IPartnerCalendarActivityProps) {
    super(props);

    const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

    this.state = {
      activity: null,
      activities: null,
      daysOfWeek: this.mapDaysOfWeek(days),
      timeSlotsSet: null,
      isLoaded: false,
      showDetails: false
    };
  }

  componentDidMount() {
    this.props.getPartnerActivities();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.details) {
      this.setState({ activity: newProps.details as INewActivity, showDetails: true });
      this.props.reset();
      return;
    }

    if (newProps.data) {
      this.setState({ activities: newProps.data as INewActivity[] }, () => this.createTimeSlots(this.state.activities));
      this.props.reset();
      return;
    }
  }

  mapDaysOfWeek = (days: string[]) => {
    return Array(7)
      .fill(null)
      .map((item, index) => {
        return {
          name: translate(`calendar.daysOfWeek.name${index + 1}`),
          value: days[index]
        };
      });
  };

  getListOfActivities = (activities: INewActivity[], index, days: string[], startTime) => {
    let enabled = false;
    const listOfActivities: ISimpleActivity[] = [];
    activities.forEach(activity => {
      if (!!activity.presetActivitySchedules && activity.presetActivitySchedules.length > 0) {
        enabled =
          activity.presetActivitySchedules.filter(it => {
            return it.dayOfWeek === days[index] && it.startAt === moment(startTime).format('HH:mm:ss');
          }).length > 0;
        if (enabled) {
          const activityEnabled: ISimpleActivity = {
            name: activity.name,
            id: activity.id
          };
          listOfActivities.push(activityEnabled);
        }
      }
    });
    return listOfActivities;
  };

  createTimeSlots = (activities: INewActivity[]) => {
    const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    const startTime = moment('05:00', 'HH:mm');
    const endTime = moment('23:45', 'HH:mm');

    const timeSlots = [] as ITimeSlot[];

    while (startTime <= endTime) {
      const timeSlotItems = Array(7)
        .fill(null)
        .map((item, index) => {
          const listOfActivities: ISimpleActivity[] = this.getListOfActivities(activities, index, days, startTime);
          const enabled = listOfActivities.length > 0;
          if (enabled) {
            return {
              dayOfWeek: { value: days[index] } as IDayOfWeek,
              activities: listOfActivities,
              enabled
            };
          }

          return {
            dayOfWeek: { value: days[index] } as IDayOfWeek
          };
        });

      const timeSlot = {
        time: moment(startTime).format('HH:mm'),
        timeSlotItem: timeSlotItems,
        hasItemsEnabled: timeSlotItems.filter(it => it.enabled).length > 0
      } as ITimeSlot;

      timeSlots.push(timeSlot);

      startTime.add(15, 'minutes');
    }

    this.setState({ timeSlotsSet: timeSlots, isLoaded: true });
  };

  mapActivitiesToDiv = (item: ITimeSlot, timeSlotItem: ITimeSlotItem, timeSlotItemIndex: Number) => {
    const div = [];

    if (timeSlotItem.enabled) {
      timeSlotItem.activities.forEach(activity => {
        div.push(
          <div className={'partner-calendar-activity-item'} onClick={() => this.handleItemPressed(activity.id)}>
            <label className={'partner-calendar-activity-item-name'}>{activity.name}</label>
            <label className={'partner-calendar-activity-item-hour'}>{item.time}</label>
          </div>
        );
      });
    }

    return div;
  };

  handleItemPressed = (id: Number) => {
    this.props.getActivityDetails(id);
  };

  mapActivityItemContainerClassName = (item: ITimeSlot) => {
    const minute = Number(moment(item.time, 'HH:mm').format('mm'));

    if (minute === 0) {
      return classnames(
        'partner-calendar-activity-activity-item-container',
        'partner-calendar-activity-activity-item-container-border-top-color'
      );
    }

    return classnames('partner-calendar-activity-activity-item-container');
  };

  hideDetails = (refreshScreen: boolean) => {
    if (refreshScreen) {
      this.setState({ showDetails: false });
      this.props.getPartnerActivities();
      return;
    }

    this.setState({ showDetails: false });
  };

  pushEditActivity = (activity: INewActivity) => {
    this.props.history.push({
      pathname: '/partner/edit-activity',
      state: { activity }
    });
  };

  render() {
    return (
      <div className={'partner-calendar-activity'}>
        {this.state.showDetails && (
          <PartnerActivityDetails
            oldActivity={this.state.activity}
            showModal={this.props.showModal}
            hideModal={this.props.hideModal}
            close={this.hideDetails}
            fetchActivityCategories={this.props.fetchActivityCategories}
            fetchActivitySubCategories={this.props.fetchActivitySubCategories}
            pushEditActivity={this.pushEditActivity}
          />
        )}
        <label className={'partner-calendar-title'}>{translate('calendar.labels.title')}</label>
        <label className={'partner-calendar-first-label'}>{translate('calendar.labels.activity')}</label>
        <div className={'partner-calendar-activity-container'}>
          <div className={'partner-calendar-activity-calendar-container'}>
            <div className={'partner-calendar-activity-days-of-week'}>
              {this.state.daysOfWeek.map((item, index) => (
                <div className={'partner-calendar-activity-days-of-week-item'} key={`daysOfWeek${index}`}>
                  <label className={'partner-calendar-activity-days-of-week-item-label'}>{item.name}</label>
                </div>
              ))}
            </div>

            <div className={'partner-calendar-activity-days-of-week-divisor'} />

            <div className={'partner-calendar-activity-items-container'}>
              {this.state.isLoaded &&
                this.state.timeSlotsSet.map((item, index) => (
                  <div className={'partner-calendar-activity-item-container'} key={`calendarTimeSlotTime${index}`}>
                    <div className={'partner-calendar-activity-item-time-container'}>
                      <label className={'partner-calendar-activity-item-time-label'}>{item.time}</label>
                    </div>

                    <div className={'partner-calendar-activity-item-divisor-container'}>
                      <div className={'partner-calendar-activity-item-divisor'} />
                    </div>

                    {item.timeSlotItem.map((timeSlotItem, timeSlotItemIndex) => (
                      <div className={this.mapActivityItemContainerClassName(item)} key={`timeSlotItem${timeSlotItemIndex}`}>
                        {this.mapActivitiesToDiv(item, timeSlotItem, timeSlotItemIndex)}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  data: storeState['activities'].data,
  details: storeState['activities'].details
});

const mapDispatchToProps = {
  getActivityDetails,
  getPartnerActivities,
  reset,
  showModal,
  hideModal,
  fetchActivityCategories,
  fetchActivitySubCategories
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerCalendarActivity);
