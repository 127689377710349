import * as React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { getPaginationItemsNumber, JhiPagination, translate, Translate, getUrlParameter } from 'react-jhipster';
import { Row, Button, Col } from 'reactstrap';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { debounce } from 'lodash';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StringUtils from 'app/util/StringUtils';
import { getBillingById, reset, getBillingPaymentReceipt, forceProcessFutureCharge } from 'app/entities/billing/billing.reducer';
import { BillingStatus, IBilling } from 'app/shared/model/billing/billing';
import { formatDate, formatTimestamp } from 'app/shared/util/date-utils';
import './billings-manage.scss';
import { IKeikenTransaction, KeikenTransactionStatus, IKeikenTransactionUtils, IKeikenTransactionLog } from 'app/shared/model/billing/keiken-transactions';
import { ToastContainer, toast } from 'react-toastify';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import _ from 'lodash';
import { MODAL_TYPE_CREATE_BILLING_TRANSACTIONS } from '../../../components/modal/modal-types';
import { showModal, hideModal } from '../../../../app/components/modal/modal-action';

export interface IBillingManageProps extends StateProps, DispatchProps, RouteComponentProps<{
  id?: string;
}, any, { billing: IBilling } | any> { }

export interface IBillingManageState {
  billing?: IBilling;
  downloading?: boolean;
  loading?: boolean;
}

export class IBillingManage extends React.Component<IBillingManageProps, IBillingManageState> {
  filesInput = null;
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      loading: false
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);

    if (this.props.location?.state?.billing != null) {
      const billing = this.props.location.state.billing;
      this.setState({ billing });
    } else if (this.props.match.params?.id != null) {
      this.props.getBillingById(this.props.match.params.id);
    }
  }

  componentWillReceiveProps(newProps: IBillingManageProps) {
    if (newProps.billing != null) {
      this.setState({
        billing: newProps.billing
      }, () => {
        this.props.reset();
      });
    }

    if (newProps.receipt != null) {
      const file = new Blob([newProps.receipt], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.props.reset();
      this.setState({ downloading: false });
    }

    if (newProps.statusProcessFutureCharge == HttpRequestStatus.SUCCESS) {
      this.props.reset();
      this.setState({ loading: false });
      this.props.getBillingById(this.props.match.params.id);
    } else if (newProps.statusProcessFutureCharge == HttpRequestStatus.ERROR) {
      this.setState({ loading: false });
      this.props.reset();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  private handleTransformToTableContent = (content?: IKeikenTransaction[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    content.sort(IKeikenTransactionUtils.compareByType);

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IKeikenTransaction) => {
      result.push([
        item.type,
        item.status,
        StringUtils.currencyPtBr(item.value ?? 0),
        item.outputAccount?.type,
        item.inputAccount?.type,
        // item.createDate ? formatDate(item.createDate) : '',
        item.processDate ? formatTimestamp(item.processDate) : '',
        item.scheduledDate ? formatTimestamp(item.scheduledDate) : '',
        item.inputExternalPixName,
        item.inputExternalPixDocument
      ]);
    });
    return result;
  };

  private handleTransformToTableLogsContent = (content?: IKeikenTransactionLog[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    content = _.sortBy(content, ['date', 'status']);

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IKeikenTransactionLog) => {
      result.push([
        item?.keikenTransaction?.type ?? '',
        item.event ?? '',
        item.date ? formatTimestamp(item.date) : ''
      ]);
    });
    return result;
  };

  private handleProcessBilling = () => {
    if (this.state?.billing?.id && this.state.loading == false) {
      this.setState({
        loading: true
      }, () => {
        this.props.forceProcessFutureCharge(this.state.billing.id);
      });
    }
  }

  private handleDownloadPaymentReceipt = () => {
    if (this.state.billing?.id && this.state.downloading == false) {
      this.setState({ downloading: true });
      this.props.getBillingPaymentReceipt(this.state.billing?.id);
    }
  }

  handleKeyDown = event => {
    const { ctrlKey, key } = event;

    if (ctrlKey && key === 't') {
      this.handleCtrlT();
    }
  };

  private handleCtrlT = () => {
    this.handleOpenAcceptKeikenPayModal(this.state.billing);

  };

  private handleOpenAcceptKeikenPayModal = (billing: IBilling) => {
    this.props.showModal(MODAL_TYPE_CREATE_BILLING_TRANSACTIONS, {
      billing,
      onClose: () => this.props.hideModal()
    });
  }

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state?.billing?.keikenTransactions ?? []);
    const logs = this.state?.billing?.keikenTransactions?.flatMap(it => {
      return it.keikenTransactionLogs?.map(log => ({
        ...log,
        keikenTransaction: it
      }));
    }) ?? [];

    const tableContentTransactionLogs: Array<Array<string | JSX.Element>> = this.handleTransformToTableLogsContent(logs);
    const billing = this.state.billing;
    return (
      <div className={'billing-manage-container'}>
        <Row>
          <Col>
            <p>{`id: ${billing?.id}`}</p>
            <p>{`date: ${billing?.date ? formatTimestamp(billing?.date) : ''}`}</p>
            <p>{`paymentMethod: ${billing?.paymentMethod}`}</p>
            <p>{`providerId: ${billing?.paymentProviderId}`}</p>
            <p>{`status: ${billing?.status}`}</p>
          </Col>
          <Col>
            <p className="billing-title">Valores pagos</p>
            <p>{`valor total: ${StringUtils.currencyPtBr(billing?.value + (billing?.billingCoParticipation?.subsidyValue ?? 0))}`}</p>
            <p>{`${billing?.paymentMethod?.toLocaleLowerCase()}: ${StringUtils.currencyPtBr(billing?.value)}`}</p>
            <p>{`benefício: ${StringUtils.currencyPtBr(billing?.billingCoParticipation?.subsidyValue)}`}</p>
          </Col>
          <Col>
            <p className="billing-title">Valores internos</p>
            <p>{`repasse: ${StringUtils.currencyPtBr(billing?.partnerProfit)}`}</p>
            <p>{`receita: ${StringUtils.currencyPtBr(billing?.keikenTax)}`}</p>
            <p>{`desconto: ${StringUtils.currencyPtBr(billing?.discountAmount)}`}</p>
          </Col>
          <Col>
            <p className="billing-title">Detalhes da compra</p>
            <a href={`/#/application-admin/customers/${billing?.customer?.id}`}>
              <p title="ver cliente">{`customer: ${billing?.customer?.name}`}</p>
            </a>
            <p>{`email: ${billing?.customer?.user?.email}`}</p>
            <p>{`phone: ${billing?.customer?.phones?.[0]?.number}`}</p>
            {billing?.activityPlan != null ? (
              <>
                <p>{`parceiro: ${billing?.activityPlan?.partnerPlace?.fantasyName}`}</p>
                <p>{`plano: ${billing?.activityPlan?.name}`}</p>
              </>
            ) : (
              <>
                <p>{`parceiro: ${billing?.activitySchedules?.[0]?.activity?.partnerPlace?.fantasyName}`}</p>
                {billing?.activitySchedules?.map?.(it => (
                  <p key={`activity-schedule-item-${it.id}`}>{`agendamento: ${formatDate(it.date)}`}</p>
                ))}
              </>
            )}
            <p>{`Descrição: ${billing?.description}`}</p>
          </Col>
        </Row>

        {this.state.loading == true ? (
          <TailSpin />
        ) : (
          <>
            <div className={'action-column-container-flex'}>
              <Button disabled={billing?.status != BillingStatus.FUTURE_CHARGE} className={'centered-content marginB2'} color="success" onClick={this.handleProcessBilling}>
                <FontAwesomeIcon icon={faDollarSign} className={'marginR1'} />
                <Translate contentKey={'billingManage.buttons.processFutureCharge'} />
              </Button>
            </div>
            <div className={'action-column-container-flex'}>
              <Button className={'centered-content marginB2'} onClick={this.handleDownloadPaymentReceipt}>
                <FontAwesomeIcon icon={'check'} className={'marginR1'} />
                <Translate contentKey={'billingManage.buttons.paymentReceipt'} />
              </Button>
            </div>
          </>
        )}
        <div className="billing-title big">Transações</div>
        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('billingManage.transactionTableHeaders')}
          emptyTableMessage={translate('billingManage.labels.emptyList')}
        />
        <div className="billing-title big">Histórico das transações</div>
        <SimpleTableList
          rows={tableContentTransactionLogs}
          columNameKeys={translate('billingManage.transactionLogsTableHeaders')}
          emptyTableMessage={translate('billingManage.labels.emptyList')}
        />
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  billing: storeState.billing.billing,
  receipt: storeState.billing.receipt,
  statusProcessFutureCharge: storeState.billing.statusProcessFutureCharge
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  reset,
  getBillingById,
  getBillingPaymentReceipt,
  forceProcessFutureCharge
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IBillingManage);
