import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { getPaginationItemsNumber, JhiPagination, translate, Translate } from 'react-jhipster';
import { Row, Button, Col } from 'reactstrap';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { debounce } from 'lodash';
import './partner-place-list.scss';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { fetchPartnerPlaces } from 'app/entities/partner-place/partner-place-redux';
import { IPartnerPlace } from 'app/shared/model/partner-place.model';
import StringUtils from 'app/util/StringUtils';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/fontawesome-free-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IImage } from 'app/shared/model/image';
import InputField from 'app/components/input-field/InputField';
import GreenButton from 'app/components/button/Button';
import { toast } from 'react-toastify';

export interface IPartnerPlaceListProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IPartnerPlaceListState {
  pageRequest: IPageRequest;
  partnerPlaces?: IPartnerPlace[];
  partnerPlacesPage?: IPage<IPartnerPlace>;
  searchText?: string;
  filters: {
    approved: 'true' | 'false' | 'all',
    email?: string;
    fantasyName?: string;
  };
}

export class PartnerPlaceList extends React.Component<IPartnerPlaceListProps, IPartnerPlaceListState> {
  constructor(props) {
    super(props);
    this.state = {
      pageRequest: {
        page: 0,
        size: 20
      },
      filters: {
        approved: 'all'
      }
    };

    this.performAction = debounce(this.performAction, 500);
  }

  componentDidMount() {
    this.props.fetchPartnerPlaces(this.state.pageRequest);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.getPartnerPlaces === HttpRequestStatus.SUCCESS) {
      this.setState({
        partnerPlacesPage: newProps.partnerPlacesPage,
        partnerPlaces: newProps.partnerPlacesPage.content
      });
    }
  }

  private performAction = () => {
    this.props.fetchPartnerPlaces(this.state.pageRequest, this.state.filters);
  };

  private handlePagination = activePage => {
    const object = this.state.pageRequest;
    object.page = activePage - 1;
    this.setState({ pageRequest: object });
    this.props.fetchPartnerPlaces(object, this.state.filters);
  };

  private handleChangeFilterStatus = () => {

    // this.setState({
    //   pageRequest: { page: 0, size: this.state.pageRequest.size },
    //   filter: { ...this.state.filter, status }
    // }, () => {
    //   this.performAction();
    // });
  }

  private renderImageColumn = (image?: IImage): JSX.Element => {
    if (image?.imageUrl == null) {
      return <div />;
    }
    return (
      <div className={'action-column-container-image'}>
        <img src={image?.imageUrl} className={'action-column-image'} />
      </div>
    );
  }

  private handleTransformToTableContent = (content?: IPartnerPlace[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: Array<Array<string | JSX.Element>> = [];

    content.map((item: IPartnerPlace) => {
      result.push([
        this.renderImageColumn(item?.image),
        item.partners != null && item.partners.length > 0
          ? item.partners
            .filter(it => it.user != null)
            .map(it => it.user.email)
            .join(', ')
          : '',
        item?.fantasyName ?? '',
        item?.socialReason ?? '',
        item.cpf != null ? StringUtils.addCpfMask(item.cpf) : StringUtils.addCpfMask(item.cnpj),
        item?.phones?.[0]?.number ?? '',
        item?.partners?.[0]?.approved ? 'Sim' : 'Não',
        this.renderActionColumn(item)
      ]);
    });

    return result;
  };

  private handleSystemFee = (partnerPlace: IPartnerPlace | undefined) => {
    this.props.history.push({
      pathname: 'system-fee-manage',
      state: {
        systemFee: partnerPlace.systemFee,
        partnerPlaceId: partnerPlace.id
      }
    });
  };
  private handleEdit = (partnerPlace: IPartnerPlace | undefined) => {
    this.props.history.push({
      pathname: `partner-place/${partnerPlace?.id}`
    });
  };

  private handleCreate = () => {
    // this.props.history.push({
    //   pathname: 'partner-place/new'
    // });
    toast.warn('não implementado');
  }

  private renderActionColumn = (partnerPlace: IPartnerPlace | undefined): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <Button color="warning" className={'centered-content marginR2'} onClick={this.handleSystemFee.bind(this, partnerPlace)}>
          <FontAwesomeIcon icon={'donate'} />
          {/* <Translate contentKey={'partnerPlaceList.buttons.systemFee'} /> */}
        </Button>
        <Button color="info" className={'centered-content'} onClick={this.handleEdit.bind(this, partnerPlace)}>
          <FontAwesomeIcon icon={faEdit as IconProp} />
          {/* <Translate contentKey={'partnerPlaceList.buttons.edit'} /> */}
        </Button>
      </div>
    );
  };

  private handleChangeFilter = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    const value = event?.target?.value;
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        [key]: value
      }
    }), () => {
      this.performAction();
    });
  }

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state.partnerPlaces);
    return (
      <div className={'partner-place-list-container'}>
        <Row style={{ marginBottom: 20, marginTop: 60 }}>
          <Col md="2">
            <GreenButton
              outerClassName={'marginR2'}
              onClick={this.handleCreate}
              title={translate('partnerPlaceList.buttons.create')}
            />
          </Col>
          <Col md="2">
            <div className={'partner-register-input-divisor-selector'}>
              <label className={'partner-register-input-label'}>
                <Translate contentKey={'partnerPlaceList.filters.approved'} />
              </label>
              <select
                onChange={e => this.handleChangeFilter(e, 'approved')}
                value={this.state.filters.approved}
                className={'partner-register-select'}
              >
                {['all', 'true', 'false'].map((item, index) => (
                  <option value={item} key={item + index}>
                    {translate(`partnerPlaceList.enum.approved.${item}`)}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col md="3">
            <InputField
              title={translate('partnerPlaceList.filters.email')}
              onChange={e => this.handleChangeFilter(e, 'email')}
              inputClassName={''}
              value={this.state.filters.email ?? ''}
              placeholder={'partnerPlaceList.filters.email'}
            />
          </Col>
          <Col md="3">
            <InputField
              title={translate('partnerPlaceList.filters.fantasyName')}
              onChange={e => this.handleChangeFilter(e, 'fantasyName')}
              inputClassName={''}
              value={this.state.filters.fantasyName ?? ''}
              placeholder={'partnerPlaceList.filters.fantasyName'}
            />
          </Col>
        </Row>
        <SimpleTableList rows={tableContent} columNameKeys={translate('partnerPlaceList.tableHeaders')} />
        <Row className={'justify-content-center'}>
          <JhiPagination
            items={
              this.state.partnerPlacesPage != null
                ? getPaginationItemsNumber(this.state.partnerPlacesPage.totalElements, this.state.partnerPlacesPage.size)
                : 0
            }
            activePage={this.state.partnerPlacesPage ? this.state.partnerPlacesPage.number : 0}
            onSelect={this.handlePagination}
            maxButtons={5}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  partnerPlacesPage: storeState.partnerPlaces.partnerPlacesPage,
  getPartnerPlaces: storeState.partnerPlaces.getPartnerPlaces
});

const mapDispatchToProps = { fetchPartnerPlaces };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerPlaceList);
