import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './partner-settings.css';
import SectionMenu from 'app/components/section-menu/sectionMenu';
import { IMenuSection } from 'app/components/section-menu/sectionMenuModels';
import PartnerInformation from './partner-information/partner-information';
import PartnerBankAccount from './partner-bank-account/partner-bank-account';
import PartnerEditLogin from './partner-edit-login/partner-edit-login';
import PartnerEditPassword from './partner-edit-password/partner-edit-password';

export interface IPartnerSettingsProps extends StateProps, DispatchProps {}

export interface IPartnerSettingsState {
  menuSections: IMenuSection[];
  currentContent: JSX.Element;
}
export class PartnerSettings extends React.Component<IPartnerSettingsProps, IPartnerSettingsState> {
  constructor(props) {
    super(props);

    this.state = {
      menuSections: [
        {
          title: translate('partnerSettings.menuSections.register'),
          items: [
            {
              title: translate('partnerSettings.menuItem.register.partnerInformation'),
              onPressItem: this.handleOnPartnerInformationPressed,
              active: true
            },
            {
              title: translate('partnerSettings.menuItem.register.bankAccount'),
              onPressItem: this.handleOnBankAccountPressed
            }
          ]
        },
        {
          title: translate('partnerSettings.menuSections.user'),
          items: [
            {
              title: translate('partnerSettings.menuItem.user.login'),
              onPressItem: this.handleOnLoginPressed
            },
            {
              title: translate('partnerSettings.menuItem.user.password'),
              onPressItem: this.handleOnEditPasswordPressed
            }
          ]
        }
      ],
      currentContent: <PartnerInformation />
    };
  }

  handleOnPartnerInformationPressed = () => {
    let menuSections = this.state.menuSections;
    menuSections = this.disableButtons(menuSections);
    const newObject = menuSections[0].items[0];
    newObject.active = true;

    menuSections[0].items[0] = newObject;
    this.setState({ menuSections, currentContent: <PartnerInformation /> });
  };

  handleOnBankAccountPressed = () => {
    let menuSections = this.state.menuSections;
    menuSections = this.disableButtons(menuSections);
    const newObject = menuSections[0].items[1];
    newObject.active = true;

    menuSections[0].items[1] = newObject;
    this.setState({ menuSections, currentContent: <PartnerBankAccount /> });
  };

  handleOnLoginPressed = () => {
    let menuSections = this.state.menuSections;
    menuSections = this.disableButtons(menuSections);
    const newObject = menuSections[1].items[0];
    newObject.active = true;

    menuSections[1].items[0] = newObject;
    this.setState({ menuSections, currentContent: <PartnerEditLogin /> });
  };

  handleOnEditPasswordPressed = () => {
    let menuSections = this.state.menuSections;
    menuSections = this.disableButtons(menuSections);
    const newObject = menuSections[1].items[1];
    newObject.active = true;

    menuSections[1].items[1] = newObject;
    this.setState({ menuSections, currentContent: <PartnerEditPassword /> });
  };

  disableButtons = (menuSections: IMenuSection[]) => {
    menuSections.forEach(it => {
      it.items.forEach(item => {
        item.active = false;
      });
    });

    return menuSections;
  };

  render() {
    return (
      <div className={'main-container'}>
        <div className={'menu-navigation-container'}>
          <SectionMenu menuSections={this.state.menuSections} />
        </div>
        <div className={'content-container'}>{this.state.currentContent}</div>
      </div>
    );
  }
}
const mapStateToProps = storeState => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerSettings);
