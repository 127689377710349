import React, { ChangeEvent } from 'react';
import { translate } from 'react-jhipster';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import './input-field.css';
import _ from 'lodash';

export interface IInputFieldProps {
  title?: string;
  placeholder?: string;
  value: string | Number;
  inputClassName: string;
  type?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, text?: string, floatValue?: number) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  maxLength?: number;
  error?: boolean;
  valid?: boolean;
  isMaskRequired?: boolean;
  maskFormat?: string;
  onKeyPress?: (event) => void;
  onKeyDownPress?: (event) => void;
  isCurrency?: boolean;
  isTextArea?: boolean;
  readOnly?: boolean;
  withoutLineBelow?: boolean;
}

export interface IInputFieldState {
  fieldActivated?: boolean;
  lineBelowInputClassName?: string;
  validationIcon?: string;
  labelClassName?: string;
  userIsTyping?: boolean;
}

const returnMapLabelClassName = (error?: boolean, valid?: boolean) => {
  if (!!error) {
    return {
      labelClassName: classnames('label-without-error', 'label-with-error'),
      lineBelowInputClassName: classnames('input-field-line-below-input-inactive-and-empty', 'input-field-line-below-input-error'),
      validationIcon: 'content/images/error-icon.png'
    };
  }
  if (!!valid) {
    return {
      labelClassName: classnames('label-without-error', 'label-with-success'),
      lineBelowInputClassName: classnames('input-field-line-below-input-inactive-and-empty', 'input-field-line-below-input-success'),
      validationIcon: 'content/images/valid-icon.png'
    };
  }
  return {
    labelClassName: classnames('label-without-error'),
    lineBelowInputClassName: classnames('input-field-line-below-input-inactive-and-empty'),
    validationIcon: ''
  };
};

class InputField extends React.Component<IInputFieldProps, IInputFieldState> {

  state: IInputFieldState = {
    userIsTyping: false,
    lineBelowInputClassName: 'input-field-line-below-input-inactive-and-empty',
    ...returnMapLabelClassName(),
    fieldActivated: false
  };

  static getDerivedStateFromProps(newProps, state) {
    const lineStyle = returnMapLabelClassName(newProps.error, newProps.valid);

    if (!!newProps.error) {
      return {
        ...lineStyle,
        lineBelowInputClassName: classnames('input-field-line-below-input-inactive-and-empty', 'input-field-line-below-input-error'),
        validationIcon: 'content/images/error-icon.png'
      };
    }

    if (!!newProps.valid) {
      return {
        ...lineStyle,
        lineBelowInputClassName: classnames('input-field-line-below-input-inactive-and-empty', 'input-field-line-below-input-success'),
        validationIcon: 'content/images/valid-icon.png'
      };
    }

    return null;
  }

  cleanIcon = () => {
    this.setState({ validationIcon: '' });
  };

  mapClassName = () => {
    return this.props.inputClassName ? classnames([this.props.inputClassName, 'input-field']) : classnames('input-field');
  };

  private activateField = () => {
    if (!this.props.onClick) {
      this.setState({ fieldActivated: true });
    }
  };

  disableField = () => {
    if (!this.props.onClick) {
      this.setState({ fieldActivated: false });
    }
  };

  handleOnInput = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      labelClassName: classnames('label-without-error'),
      lineBelowInputClassName: 'input-field-line-below-input-inactive-and-empty',
      validationIcon: ''
    });
  };

  // tslint:disable-next-line: cyclomatic-complexity
  render() {
    return (
      <div className={'input-field-container'}>
        <label
          className={
            this.state.fieldActivated || this.props.value != null
              ? classnames([this.state.labelClassName, 'transition'])
              : this.state.labelClassName
          }
        >
          {(this.state.fieldActivated || this.props.value != null) && this.props.title != null ? this.props.title : ''}
        </label>

        <div className={'input-field-wrapper'}>
          {this.props.isMaskRequired &&
            !!!this.props.isTextArea &&
            !!!this.props.isCurrency && (
              <InputMask
                autoComplete={'off'}
                readOnly={!!this.props.readOnly ? this.props.readOnly : false}
                onKeyUp={this.handleOnInput}
                onFocus={this.activateField}
                onBlur={this.disableField}
                onChange={!!this.props.onChange ? this.props.onChange : () => void 0}
                value={this.props.value}
                placeholder={!this.state.fieldActivated && !!this.props.placeholder ? translate(this.props.placeholder) : ''}
                mask={this.props.maskFormat}
                onKeyPress={!!this.props.onKeyPress ? this.props.onKeyPress : () => void 0}
                className={this.mapClassName()}
                onKeyDown={!!this.props.onKeyDownPress ? this.props.onKeyDownPress : () => void 0}
              />
            )}

          {!this.props.isMaskRequired &&
            !!!this.props.isTextArea &&
            !!!this.props.isCurrency && (
              <input
                autoComplete={'off'}
                onKeyUp={this.handleOnInput}
                onFocus={this.activateField}
                onBlur={this.disableField}
                maxLength={!!this.props.maxLength ? this.props.maxLength : 50}
                type={!!this.props.type ? this.props.type : 'text'}
                className={this.mapClassName()}
                value={this.props.value ? this.props.value.toString() : ''}
                onChange={!!this.props.onChange ? this.props.onChange : () => void 0}
                placeholder={!this.state.fieldActivated && !!this.props.placeholder ? translate(this.props.placeholder) : ''}
                readOnly={!!this.props.readOnly ? this.props.readOnly : false}
                onClick={!!this.props.onClick ? this.props.onClick : () => void 0}
                onKeyPress={!!this.props.onKeyPress ? this.props.onKeyPress : () => void 0}
              />
            )}

          {this.props.isCurrency &&
            !!!this.props.isTextArea && (
              <CurrencyInput
                prefix={'R$ '}
                autoComplete={'off'}
                onKeyUp={this.handleOnInput}
                onFocus={this.activateField}
                onBlur={this.disableField}
                onChangeEvent={!!this.props.onChange ? this.props.onChange : () => void 0}
                className={this.mapClassName()}
                value={this.props.value}
                readOnly={!!this.props.readOnly ? this.props.readOnly : false}
                placeholder={!this.state.fieldActivated && !!this.props.placeholder ? translate(this.props.placeholder) : ''}
                decimalSeparator={','}
                thousandSeparator={'.'}
              />
            )}

          {this.props.isTextArea && (
            <textarea
              autoComplete={'off'}
              onKeyUp={this.handleOnInput}
              onFocus={this.activateField}
              maxLength={!!this.props.maxLength ? this.props.maxLength : 50}
              onBlur={this.disableField}
              onChange={!!this.props.onChange ? this.props.onChange : () => void 0}
              className={this.mapClassName()}
              value={this.props.value ? this.props.value.toString() : ''}
              readOnly={!!this.props.readOnly ? this.props.readOnly : false}
              placeholder={!this.state.fieldActivated && !!this.props.placeholder ? translate(this.props.placeholder) : ''}
            />
          )}

          <img src={this.state.validationIcon} className={'icon-img'} />

          {!this.props.withoutLineBelow && <div className={this.state.lineBelowInputClassName} />}
        </div>
      </div>
    );
  }
}

export default InputField;
