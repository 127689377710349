import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './financial.css';
import { RouteComponentProps } from 'react-router';
import Button from 'app/components/button/Button';
import { getCurrentBalance, reset, requestRedemption } from './financial-redux';
import { IPartnerPlaceBillingAccountBalance } from 'app/shared/model/partner-place-billing-account-balance';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { toast } from 'react-toastify';

export interface IFinancialProps extends StateProps, DispatchProps, RouteComponentProps {}

export interface IFinancialState {
  currentBalance?: IPartnerPlaceBillingAccountBalance;
}

export class Financial extends React.Component<IFinancialProps, IFinancialState> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.getCurrentBalance();
  }

  componentWillReceiveProps(newProps: IFinancialProps) {
    if (newProps.currentBalance !== null && newProps.getCurrentBalanceStatus === HttpRequestStatus.SUCCESS) {
      this.setState({ currentBalance: newProps.currentBalance });
      this.props.reset();
      return;
    }

    if (newProps.requestRedemptionStatus === HttpRequestStatus.SUCCESS) {
      toast.success(translate('financial.success.requestRedemptionSuccess'), {
        autoClose: 5000
      });

      this.props.reset();
      return;
    }
  }

  handleOnRequestRedemptionPressed = () => {
    this.props.requestRedemption();
  };

  render() {
    return (
      <div className={'financial-container'}>
        <label className={'financial-title'}>{translate('financial.labels.title')}</label>

        <div className={'financial-balance-container'}>
          <div className={'financial-current-balance-container'}>
            <div>
              <label className={'financial-current-balance-container-title-label'}>
                <Translate contentKey="financial.labels.currentBalance">Current Balance</Translate>
              </label>
              <div>
                <label className={'financial-current-balance-current-balance-value'}>
                  R$ {this.state.currentBalance ? this.state.currentBalance.current.toFixed(2) : null}
                </label>
              </div>
            </div>

            <Button
              onClick={this.props.getCurrentBalanceStatus === HttpRequestStatus.NOOP ? this.handleOnRequestRedemptionPressed : () => void 0}
              outerClassName={'financial-token-button'}
              title={translate('financial.buttons.requestRedemption')}
            />
          </div>

          <div className={'financial-future-and-blocked-container'}>
            <div className={'financial-future-container'}>
              <div>
                <label className={'financial-current-balance-container-title-label'}>
                  <Translate contentKey="financial.labels.futureBalance">Future Balance</Translate>
                </label>
              </div>

              <div>
                <label className={'financial-current-balance-future-balance-value'}>
                  R$ {this.state.currentBalance ? this.state.currentBalance.future.toFixed(2) : null}
                </label>
              </div>
            </div>

            <div className={'financial-blocked-container'}>
              <div>
                <label className={'financial-current-balance-container-title-label'}>
                  <Translate contentKey="financial.labels.blockedBalance">Blocked Balance</Translate>
                </label>
              </div>
              <div>
                <label className={'financial-current-balance-future-balance-value'}>
                  R$ {this.state.currentBalance ? this.state.currentBalance.unavailable.toFixed(2) : null}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = storeState => ({
  currentBalance: storeState.financial.currentBalance,
  getCurrentBalanceStatus: storeState.financial.getCurrentBalanceStatus,
  requestRedemptionStatus: storeState.financial.requestRedemptionStatus
});

const mapDispatchToProps = { getCurrentBalance, reset, requestRedemption };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Financial);
