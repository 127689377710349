import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './edit-activity.css';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import { reset, updateActivity } from 'app/entities/activity/activity';
import { IActivity } from 'app/shared/model/activity';
import EditActivityStep1 from './step1/edit-activity-step1';
import EditActivityStep2 from './step2/edit-activity-step2';
import EditActivityStep3 from './step3/edit-activity-step3';
import { IRootState } from 'app/shared/reducers';

export interface IEditActivityProps extends StateProps, DispatchProps, RouteComponentProps { }

export interface IEditActivityState {
  step: number;
  activity: IActivity;
}

export class EditActivity extends React.Component<IEditActivityProps, IEditActivityState> {
  constructor(props) {
    super(props);
    if (!props.location.state || !props.location.state.activity) {
      this.props.history.replace('calendar');
    }
    this.state = {
      step: 1,
      activity: props.location.state.activity as IActivity
    };
  }

  componentWillReceiveProps(newProps: IEditActivityProps) {
    if (newProps.updateActivitySuccess && newProps.updateActivitySuccess !== this.props.updateActivitySuccess) {
      toast.success(translate('editActivity.messages.updateWithSuccess'), {
        onClose: this.handleCloseSuccessDialog,
        autoClose: 1500
      });
    }
  }

  handleCloseSuccessDialog = () => {
    this.props.reset();
    this.props.history.replace(`/partner/partner-dashboard`);
  };

  updateEditActivityHandlerStep1 = (activity: IActivity) => {
    this.setState({ activity, step: 2 });
  };

  updateNextButtonEditActivityHandlerStep2 = (activity: IActivity) => {
    this.setState({ activity, step: 3 });
  };

  updateBackButtonEditActivityHandlerStep2 = (activity: IActivity) => {
    this.setState({ activity, step: 1 });
  };

  saveButtonEditActivityHandlerStep3 = (activity: IActivity) => {
    this.props.updateActivity(activity);
  };

  updateBackButtonEditActivityHandlerStep3 = (activity: IActivity) => {
    this.setState({ activity, step: 2 });
  };
  render() {
    return (
      <div className={'edit-activity-container'}>
        <label className={this.state.step !== 2 ? 'edit-activity-title' : classnames('edit-activity-title', 'edit-activity-title-step-2')}>
          {this.state.step === 1 && translate('editActivity.titles.step1')}
          {this.state.step === 2 && translate('editActivity.titles.step2')}
          {this.state.step === 3 && translate('editActivity.titles.step3')}
        </label>
        <div>
          {this.state.step === 1 && (
            <EditActivityStep1 object={this.state.activity} updateEditActivityHandler={this.updateEditActivityHandlerStep1} />
          )}
          {this.state.step === 2 && (
            <EditActivityStep2
              nextButtonHandler={this.updateNextButtonEditActivityHandlerStep2}
              backButtonHandler={this.updateBackButtonEditActivityHandlerStep2}
              object={this.state.activity}
            />
          )}
          {this.state.step === 3 && (
            <EditActivityStep3
              saveButtonHandler={this.saveButtonEditActivityHandlerStep3}
              backButtonHandler={this.updateBackButtonEditActivityHandlerStep3}
              object={this.state.activity}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  activities: storeState.activities,
  updateActivitySuccess: storeState.activities.updateActivitySuccess
});

const mapDispatchToProps = { reset, updateActivity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditActivity);
