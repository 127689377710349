import * as React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { getPaginationItemsNumber, JhiPagination, translate, Translate, getUrlParameter } from 'react-jhipster';
import { Row, Col, Button } from 'reactstrap';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { debounce } from 'lodash';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StringUtils from 'app/util/StringUtils';
import {
  fetchAssessments,
  updateAssessmentStatus,
  reset
} from 'app/entities/assessment/assessment.reducer';
import './assessment-list.scss';
import { IAssessment, IAssessmentStatus } from 'app/shared/model/assessment';
import { formatTimestamp } from 'app/shared/util/date-utils';
import { IActivity } from 'app/shared/model/activity';
import { ActivityPlan } from 'app/shared/model/activity-plan';

export interface IAssessmentListProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IAssessmentListState {
  pageRequest?: IPageRequest;
  assessmentsPage?: IPage<IAssessment>;
  filter?: {};
}

export class IAssessmentList extends React.Component<IAssessmentListProps, IAssessmentListState> {
  filesInput = null;
  constructor(props) {
    super(props);
    this.state = {
    };
    this.performAction = debounce(this.performAction, 500);
  }

  private performAction = () => {
    this.props.fetchAssessments(this.state.pageRequest, this.state.filter);
    const params = qs.stringify({ ...this.state.pageRequest, ...this.state.filter }, { skipNulls: true });
    this.props.history.push({ search: params });
  };

  componentDidMount() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { page, size } = params;

    this.setState({
      pageRequest: {
        page,
        size
      }
    }, () => {
      this.performAction();
    });
  }

  componentWillReceiveProps(newProps: IAssessmentListProps) {
    if (newProps.page != null) {
      this.setState({
        assessmentsPage: newProps.page
      }, () => {
        this.props.reset();
      });
    }

    if (newProps.updateStatus === HttpRequestStatus.SUCCESS) {
      this.performAction();
      this.props.reset();
    }
  }

  private handlePagination = activePage => {
    const pageRequest = this.state.pageRequest;
    pageRequest.page = activePage - 1;
    this.setState({
      pageRequest
    }, () => {
      this.performAction();
    });
  };

  private handleTransformToTableContent = (content?: IAssessment[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: IAssessment) => {
      const date = formatTimestamp(item?.createdDate);
      const customerName = item.customer?.name;
      const customerEmail = item.customer?.user?.email;
      const customer = customerEmail ? `${customerName} - ${customerEmail}` : customerName;
      const activity: IActivity | ActivityPlan = item?.activity ?? item?.activityPlan;
      const activityType = item?.activity ? 'Atividade' : item?.activityPlan ? 'Plano' : 'invoice';
      const fantasyName = activity?.partnerPlace?.fantasyName ?? 'external'; // todo: waiting keiken invoice
      const activityName = activity?.name ?? 'invoice'; // todo: waiting keiken invoice
      result.push([
        date,
        customer,
        item.stars?.toString() ?? '',
        item.description,
        fantasyName,
        `${activityType}: ${activityName}`,
        item.assessmentStatus,
        this.renderActionColumn(item)
      ]);
    });
    return result;
  };

  private handleUpdateStatus = (item: IAssessment | undefined, assessmentStatus: IAssessmentStatus) => {
    this.props.updateAssessmentStatus({
      ...item,
      assessmentStatus
    });
  };

  private renderActionColumn = (item: IAssessment | undefined): JSX.Element => {
    return (
      <div className={'action-column-container-flex'}>
        <Button color="success" className={'centered-content marginB2 marginR2'} onClick={this.handleUpdateStatus.bind(this, item, IAssessmentStatus.APPROVED)}>
          <FontAwesomeIcon icon={'thumbs-up'} className={'marginR1'} />
          {/* <Translate contentKey={'assessmentList.buttons.approve'} /> */}
        </Button>
        <Button color="danger" className={'centered-content marginB2'} onClick={this.handleUpdateStatus.bind(this, item, IAssessmentStatus.DENIED)}>
          <FontAwesomeIcon icon={'times'} className={'marginR1'} />
          {/* <Translate contentKey={'assessmentList.buttons.deny'} /> */}
        </Button>
      </div>
    );
  };

  render() {
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.state?.assessmentsPage?.content ?? []);
    return (
      <div className={'partner-place-list-container'}>
        {/* <Row style={{ marginBottom: 20 }} className={'centered-content'}>
          <Col md="3" >
            <Button style={{ width: '100%' }} onClick={this.handleChangeFilterStatus}>
              <FontAwesomeIcon icon={'check'} className={'marginR1'} />
              <Translate contentKey={'billingsList.filter.status.' + this.state.filter.status} />
            </Button>
          </Col>
          <Col md="3" >
            <InputField
              title={translate('billingsList.filter.table')}
              inputClassName={'category-manage-search-input'}
              value={this.state.priceTableName ?? ''}
              placeholder={'billingsList.filter.table'}
              onClick={this.handlePriceTableList}
            />
          </Col>
          <Col md="2" />
          <Col md="4">
            <InputField
              title={translate('billingsList.placeHolders.search')}
              onChange={this.handleChanged}
              inputClassName={''}
              value={this.state.filter.name ?? ''}
              placeholder={'billingsList.placeHolders.search'}
            />
          </Col>
        </Row> */}
        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('assessmentList.tableHeaders')}
          emptyTableMessage={this.props.fetchStatus !== HttpRequestStatus.ONGOING ? translate('assessmentList.labels.emptyList') : ''}
        />
        {tableContent.length > 0 && (
          <Row className={'justify-content-center'}>
            <JhiPagination
              items={
                this.state.assessmentsPage
                  ? getPaginationItemsNumber(this.state.assessmentsPage.totalElements, this.state.assessmentsPage.size)
                  : 0
              }
              activePage={this.state.assessmentsPage ? this.state.assessmentsPage.number + 1 : 1}
              onSelect={this.handlePagination}
              maxButtons={10}
            />
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  page: storeState.assessments.page,
  fetchStatus: storeState.assessments.fetchStatus,
  updateStatus: storeState.assessments.updateStatus
});

const mapDispatchToProps = {
  reset,
  fetchAssessments,
  updateAssessmentStatus
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IAssessmentList);
