import React from 'react';
import { KeikenPayInvoice } from '../../../../../shared/model/KeikenPay/KeikenPayInvoice';
import StringUtils from '../../../../../util/StringUtils';
import './menus.scss';

interface Props {

    data: KeikenPayInvoice;

}

export const PartnerExternal = ({ data }: Props) => {

    const partnerExtraFields = [
        { label: 'crp', value: data?.partnerExternal?.crp },
        { label: 'cref', value: data?.partnerExternal?.cref },
        { label: 'crn', value: data?.partnerExternal?.crn },
        { label: 'documento', value: data?.partnerExternal?.document }
    ];

    const { label: labelPartnerExtraDocument, value: valuePartnerExtraDocument } =
        partnerExtraFields.find(field => field.value !== undefined) || { label: 'documento', value: undefined };

    return (
        <>
            <div className="details-container">
                <div className="details-column">
                    <span>nome fantasia:</span>
                    <div className="details-content">{data.partnerExternal?.fantasyName}</div>
                </div>
                <div className="details-column">
                    <span>nome:</span>
                    <div className="details-content-wide">{data.partnerExternal?.name}</div>
                </div>
                <div className="details-column">
                    <span>email:</span>
                    <div className="details-content-wide">{data.partnerExternal?.email}</div>
                </div>
                <div className="details-column">
                    <span>{labelPartnerExtraDocument}:</span>
                    <div className="details-content">{valuePartnerExtraDocument}</div>
                </div>
            </div>
            <div className="details-container">
                <div className="details-column">
                    <span>documento:</span>
                    <div className="details-content">{data.partnerExternal?.document}</div>
                </div>
                <div className="details-column">
                    <span>contato:</span>
                    <div className="details-content">{StringUtils.addPhoneMask(data.partnerExternal?.phone ?? '')}</div>
                </div>
                <div className="details-column">
                    <span>atividade principal:</span>
                    <div className="details-content-wide">{data.partnerExternal?.principalActivity?.code} - {data.partnerExternal?.principalActivity?.text}</div>
                </div>
            </div>
        </>
    );
};
