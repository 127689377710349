import React, { ChangeEvent } from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './partner-dashboard.css';
import { RouteComponentProps } from 'react-router';
import InputField from 'app/components/input-field/InputField';
import Button from 'app/components/button/Button';
import { hideModal, showModal } from '../../../components/modal/modal-action';
import { getCustomerActivityByToken, useCustomerActivityToken, reset } from 'app/entities/customer-activity/customer-activity-redux';
import { IRootState } from 'app/shared/reducers';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import StringUtils from 'app/util/StringUtils';
import { ActivityModal } from 'app/components/activityModal/ActivityModal';
import { ICustomerActivity } from 'app/shared/model/customer-activity.model';
import moment from 'moment';
import { toast } from 'react-toastify';
import { MODAL_TYPE_CONFIRM_TOKEN } from 'app/components/modal/modal-types';

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

export interface IPartnerDashboardProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IPartnerDashboardState {
  token?: string;
  getByTokenStatus: HttpRequestStatus;
  isVisible: boolean;
  customerActivity?: ICustomerActivity;
}

export class PartnerDashboard extends React.Component<IPartnerDashboardProps, IPartnerDashboardState> {
  constructor(props: IPartnerDashboardProps) {
    super(props);

    this.state = {
      getByTokenStatus: HttpRequestStatus.NOOP,
      isVisible: false
    };
  }
  componentDidMount() {}

  componentWillReceiveProps(newProps) {
    if (
      newProps.getCustomerActivityByTokenStatus === HttpRequestStatus.SUCCESS &&
      newProps.getCustomerActivityByTokenStatus !== this.props.getCustomerActivityByTokenStatus
    ) {
      if (this.validateCustomerActivity(newProps.customerActivity)) {
        this.props.showModal(MODAL_TYPE_CONFIRM_TOKEN, {
          customerActivity: newProps.customerActivity,
          onClose: this.props.hideModal,
          onPressButton: this.handleUseToken
        });

        this.setState({
          customerActivity: newProps.customerActivity
        });
      }

      this.props.reset();
      return;
    }

    if (
      newProps.useCustomerActivityTokenStatus === HttpRequestStatus.SUCCESS &&
      newProps.useCustomerActivityTokenStatus !== this.props.useCustomerActivityTokenStatus
    ) {
      this.props.hideModal();
      toast.success(translate('partnerDashboard.successMessages.tokenSuccess'));
      this.props.reset();
      this.setState({ token: '' });
      return;
    }
  }

  handleNewActivityPressed = () => {
    this.props.history.push('/partner/new-activity');
  };

  handleMyActivitiesPressed = () => {
    this.props.history.push('/partner/calendar/');
  };

  handleValidateToken = () => {
    if (StringUtils.isStringInvalid(this.state.token)) {
      return;
    }
    this.props.getCustomerActivityByToken(this.state.token);
  };

  handleUseToken = (id: number) => {
    this.props.useCustomerActivityToken(id);
  };

  private validateCustomerActivity = (customerActivity: ICustomerActivity): boolean => {
    if (customerActivity.tokenUsed === true) {
      toast.error(translate('partnerDashboard.errorMessages.tokenUsed'));
      return false;
    }
    if (moment(customerActivity.expirationDate, 'YYYY-MM-DD HH:mm').isBefore(moment(new Date(), 'YYYY-MM-DD HH:mm'))) {
      toast.error(translate('partnerDashboard.errorMessages.tokenExpired'));
      return false;
    }

    return true;
  };

  render() {
    return (
      <div className={'partner-dashboard-container'}>
        <label className={'partner-dashboard-title'}>{translate('partnerDashboard.labels.dashboard')}</label>

        <div className={'partner-dashboard-token-container'}>
          <label className={'partner-dashboard-token-label'}>{translate('partnerDashboard.labels.insertToken')}</label>

          <div className={'w-75'}>
            <InputField
              value={!!this.state.token ? this.state.token : ''}
              inputClassName={'partner-dashboard-input'}
              onChange={(event: ChangeEvent<HTMLInputElement>) => this.setState({ token: event.target.value })}
              placeholder={'partnerDashboard.placeholders.token'}
            />
          </div>
          <Button
            onClick={this.handleValidateToken}
            outerClassName={'partner-dashboard-token-button'}
            title={translate('partnerDashboard.buttons.next')}
          />
        </div>

        <div onClick={this.handleNewActivityPressed} className={'partner-dashboard-button-container'}>
          <img className={'partner-dashboard-img'} src={'content/images/ic-plus-grassgreen.png'} />

          <label className={'partner-dashboard-button-label'}>{translate('partnerDashboard.buttons.newActivity')}</label>
        </div>

        <div onClick={this.handleMyActivitiesPressed} className={'partner-dashboard-button-container'}>
          <img className={'partner-dashboard-img'} src={'content/images/ic-calendar-grassgreen.png'} />

          <label className={'partner-dashboard-button-label'}>{translate('partnerDashboard.buttons.myActivities')}</label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  getCustomerActivityByTokenStatus: state.customerActivity.getCustomerActivityByTokenStatus,
  customerActivity: state.customerActivity.customerActivity,
  useCustomerActivityTokenStatus: state.customerActivity.useCustomerActivityTokenStatus
});

const mapDispatchToProps = { showModal, hideModal, getCustomerActivityByToken, useCustomerActivityToken, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerDashboard);
