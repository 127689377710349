import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IActionForResponseEntity } from 'app/util/redux-interface';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IBillingCreateVM } from 'app/shared/model/billing/billing';
import qs from 'qs';
import { IPageRequest } from 'app/shared/model/page.model';

export const ACTION_TYPES = {
  GET_BILLING_BY_ID: 'billing/GET_BILLING_BY_ID',
  GET_BILLINGS: 'billing/GET_BILLINGS',
  GET_BILLING_PDF_RECEIPT: 'billing/GET_BILLING_PDF_RECEIPT',
  POST_ADMIN_CUSTOMER_BILLING: 'billing/POST_ADMIN_CUSTOMER_BILLING',
  GET_ACTIVITY_SCHEDULE_PRICE_COMPOSITE: 'billing/GET_ACTIVITY_SCHEDULE_PRICE_COMPOSITE',
  GET_ACTIVITY_SCHEDULES_PRICE_COMPOSITE: 'billing/GET_ACTIVITY_SCHEDULES_PRICE_COMPOSITE',
  GET_ACTIVITY_PLAN_PRICE_COMPOSITE: 'billing/GET_ACTIVITY_PLAN_PRICE_COMPOSITE',
  POST_ADMIN_PROCESS_FUTURE_CHARGE: 'billing/POST_ADMIN_PROCESS_FUTURE_CHARGE',
  GET_BILLINGS_WITH_DRAWS: 'billing/GET_BILLINGS_WITH_DRAWS',
  PATCH_APPROVE_PARTNER_WITHDRAW: 'billing/PATCH_APPROVE_PARTNER_WITHDRAW',
  POST_PARTNER_WITHDRAW_BY_ADMIN: 'billing/POST_PARTNER_WITHDRAW_BY_ADMIN',
  RESET: 'billing/RESET'
};

const initialState = {
  status: HttpRequestStatus.NOOP,
  statusPdf: HttpRequestStatus.NOOP,
  statusAdminCustomerBilling: HttpRequestStatus.NOOP,
  statusProcessFutureCharge: HttpRequestStatus.NOOP,
  statusActivitySchedulePriceComposite: HttpRequestStatus.NOOP,
  statusActivityPlanPriceComposite: HttpRequestStatus.NOOP,
  getBillingsWithDrawsStatus: HttpRequestStatus.NOOP,
  approvePartnerWithdrawStatus: HttpRequestStatus.NOOP,
  requestPartnerWithDrawByAdminStatus: HttpRequestStatus.NOOP,
  receipt: null,
  priceComposite: null,
  billings: null,
  billing: null,
  billingsWithDraws: null
};

export type IBillingState = Readonly<typeof initialState>;

// Reducer
export default (state: IBillingState = initialState, action: IActionForResponseEntity<{}>): IBillingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_BILLINGS):
      return {
        ...initialState,
        status: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_BILLINGS):
      return {
        ...initialState,
        status: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_BILLINGS):
      return { ...initialState, status: HttpRequestStatus.SUCCESS, billings: action.payload.data };
    case REQUEST(ACTION_TYPES.POST_ADMIN_PROCESS_FUTURE_CHARGE):
      return {
        ...initialState,
        statusProcessFutureCharge: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.POST_ADMIN_PROCESS_FUTURE_CHARGE):
      return {
        ...initialState,
        statusProcessFutureCharge: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.POST_ADMIN_PROCESS_FUTURE_CHARGE):
      return {
        ...initialState,
        statusProcessFutureCharge: HttpRequestStatus.SUCCESS,
        billing: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_BILLING_BY_ID):
      return {
        ...initialState,
        status: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_BILLING_BY_ID):
      return {
        ...initialState,
        status: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_BILLING_BY_ID):
      return { ...initialState, status: HttpRequestStatus.SUCCESS, billing: action.payload.data };
    case REQUEST(ACTION_TYPES.POST_ADMIN_CUSTOMER_BILLING):
      return {
        ...initialState,
        statusAdminCustomerBilling: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.POST_ADMIN_CUSTOMER_BILLING):
      return {
        ...initialState,
        statusAdminCustomerBilling: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.POST_ADMIN_CUSTOMER_BILLING):
      return {
        ...initialState,
        statusAdminCustomerBilling: HttpRequestStatus.SUCCESS
      };
    case REQUEST(ACTION_TYPES.GET_ACTIVITY_SCHEDULE_PRICE_COMPOSITE):
      return {
        ...initialState,
        statusActivitySchedulePriceComposite: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_ACTIVITY_SCHEDULE_PRICE_COMPOSITE):
      return {
        ...initialState,
        statusActivitySchedulePriceComposite: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_ACTIVITY_SCHEDULE_PRICE_COMPOSITE):
      return {
        ...initialState,
        statusActivitySchedulePriceComposite: HttpRequestStatus.SUCCESS,
        priceComposite: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_ACTIVITY_SCHEDULES_PRICE_COMPOSITE):
      return {
        ...state,
        statusActivitySchedulePriceComposite: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_ACTIVITY_SCHEDULES_PRICE_COMPOSITE):
      return {
        ...state,
        statusActivitySchedulePriceComposite: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_ACTIVITY_SCHEDULES_PRICE_COMPOSITE):
      return {
        ...state,
        statusActivitySchedulePriceComposite: HttpRequestStatus.SUCCESS,
        priceComposite: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_ACTIVITY_PLAN_PRICE_COMPOSITE):
      return {
        ...state,
        statusActivityPlanPriceComposite: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_ACTIVITY_PLAN_PRICE_COMPOSITE):
      return {
        ...state,
        statusActivityPlanPriceComposite: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_ACTIVITY_PLAN_PRICE_COMPOSITE):
      return {
        ...state,
        statusActivityPlanPriceComposite: HttpRequestStatus.SUCCESS,
        priceComposite: action.payload.data
      };
    case REQUEST(ACTION_TYPES.GET_BILLING_PDF_RECEIPT):
      return {
        ...initialState,
        statusPdf: HttpRequestStatus.ONGOING
      };
    case FAILURE(ACTION_TYPES.GET_BILLING_PDF_RECEIPT):
      return {
        ...initialState,
        statusPdf: HttpRequestStatus.ERROR
      };
    case SUCCESS(ACTION_TYPES.GET_BILLING_PDF_RECEIPT):
      return {
        ...initialState,
        statusPdf: HttpRequestStatus.SUCCESS,
        receipt: action.payload.data
      };
      case REQUEST(ACTION_TYPES.GET_BILLINGS_WITH_DRAWS):
        return {
          ...state,
          getBillingsWithDrawsStatus: HttpRequestStatus.ONGOING
        };
      case FAILURE(ACTION_TYPES.GET_BILLINGS_WITH_DRAWS):
        return {
          ...state,
          getBillingsWithDrawsStatus: HttpRequestStatus.ERROR
        };
      case SUCCESS(ACTION_TYPES.GET_BILLINGS_WITH_DRAWS):
        return {
          ...state,
          getBillingsWithDrawsStatus: HttpRequestStatus.SUCCESS,
          billingsWithDraws: action.payload.data
        };
        case REQUEST(ACTION_TYPES.PATCH_APPROVE_PARTNER_WITHDRAW):
          return {
            ...state,
            approvePartnerWithdrawStatus: HttpRequestStatus.ONGOING
          };
        case FAILURE(ACTION_TYPES.PATCH_APPROVE_PARTNER_WITHDRAW):
          return {
            ...state,
            approvePartnerWithdrawStatus: HttpRequestStatus.ERROR
          };
        case SUCCESS(ACTION_TYPES.PATCH_APPROVE_PARTNER_WITHDRAW):
          return {
            ...state,
            approvePartnerWithdrawStatus: HttpRequestStatus.SUCCESS
          };
        case REQUEST(ACTION_TYPES.POST_PARTNER_WITHDRAW_BY_ADMIN):
          return {
            ...state,
            requestPartnerWithDrawByAdminStatus: HttpRequestStatus.ONGOING
          };
        case FAILURE(ACTION_TYPES.POST_PARTNER_WITHDRAW_BY_ADMIN):
          return {
            ...state,
            requestPartnerWithDrawByAdminStatus: HttpRequestStatus.ERROR
          };
        case SUCCESS(ACTION_TYPES.POST_PARTNER_WITHDRAW_BY_ADMIN):
          return {
            ...state,
            requestPartnerWithDrawByAdminStatus: HttpRequestStatus.SUCCESS
          };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export const getBillings = (page: IPageRequest, params?: any) => {
  const queryParams = qs.stringify(
    { ...params, size: page?.size, page: page?.page },
    { skipNulls: true }
  );
  return ({
    type: ACTION_TYPES.GET_BILLINGS,
    payload: axios.get(`/api/billings?${queryParams}`)
  });
};

export const getPartnerBillingsWithDraws = id => ({
  type: ACTION_TYPES.GET_BILLINGS_WITH_DRAWS,
  payload: axios.get(`/api/billings?paymentMethod=WITHDRAW_BY_PARTNER&partnerPlaceBillingAccount.id=${id}`)
});

export const patchApprovePartnerWithDraw = (partnerId, transactionId) => ({
  type: ACTION_TYPES.PATCH_APPROVE_PARTNER_WITHDRAW,
  payload: axios.patch(`/api/partner-place-financial-areas/${partnerId}/withdraw`, {id: transactionId, status: 'SCHEDULED'})
});

export const postRequestWithDrawByAdmin = (partnerId, value) => ({
  type: ACTION_TYPES.POST_PARTNER_WITHDRAW_BY_ADMIN,
  payload: axios.post(`/api/partner-place-financial-areas/${partnerId}/withdraw`, {value})
});

export const getBillingById = (id?: number | string) => ({
  type: ACTION_TYPES.GET_BILLING_BY_ID,
  payload: axios.get(`/api/billings/${id}`)
});

export const getBillingPaymentReceipt = (id?: number | string) => ({
  type: ACTION_TYPES.GET_BILLING_PDF_RECEIPT,
  payload: axios.get(`/api/billings/${id}/payment-receipt`, { responseType: 'blob' })
});

export const createCustomerBilling = (customerId: number, billing: IBillingCreateVM) => ({
  type: ACTION_TYPES.POST_ADMIN_CUSTOMER_BILLING,
  payload: axios.post(`/api/billings/customer/${customerId}`, billing)
});

export const getActivitySchedulePriceComposite = (activityScheduleId: number, activityId?: number, customerId?: number) => ({
  type: ACTION_TYPES.GET_ACTIVITY_SCHEDULE_PRICE_COMPOSITE,
  payload: axios.get(`/api/activity-schedules/${activityScheduleId}/customer/${customerId}/price-composite?activityId=${activityId}`)
});

export const getAcitivtySchedulesWithRenewalPriceComposite = (activityScheduleIds: number[], activityId?: number) => {
  const ids = activityScheduleIds?.join(',');

  return {
    type: ACTION_TYPES.GET_ACTIVITY_SCHEDULES_PRICE_COMPOSITE,
    payload: axios.get(`/api/mobile/activity-schedules/${ids}/price-composite`, {
      params: {
        activityId
      }
    })
  };
};

export const getActivityPlanPriceComposite = (activityPlanId: number, date?: string, customerId?: number) => ({
  type: ACTION_TYPES.GET_ACTIVITY_PLAN_PRICE_COMPOSITE,
  payload: axios.get(`/api/activity-plan/${activityPlanId}/customer/${customerId}/price-composite?date=${date}`)
});

export const forceProcessFutureCharge = (billingId: number) => ({
  type: ACTION_TYPES.POST_ADMIN_PROCESS_FUTURE_CHARGE,
  payload: axios.post(`/api/billing/${billingId}/process-future-charge`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
