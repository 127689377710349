import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import './company-manage.scss';
import { getCompanyById, getCompanyKeikenPayFee, reset, saveCompanyKeikenPayFee } from 'app/entities/company/company-redux';
import { ICompany, ICompanyKeikenPayActivityCategory } from 'app/shared/model/company';
import { Table, Label, Input, Button as RButton } from 'reactstrap';
import InputField from 'app/components/input-field/InputField';
import { Translate, translate } from 'react-jhipster';
import _ from 'lodash';
import Button from 'app/components/button/Button';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SystemFeeType } from 'app/shared/model/enum/SystemFeeType';
import { fetchAllActivityCategories, reset as resetActivityCategories } from 'app/entities/activity-category/activity-category-reducer';
import { IActivityCategory } from 'app/shared/model/activity-category';
import { v4 as uuid } from 'uuid';
export interface ICompanyKeikenPayFeeManageProps extends StateProps, DispatchProps, RouteComponentProps<{
  id?: string;
}, any, {} | any> { }

export interface ICompanyKeikenPayFeeManageState {
  company?: ICompany;
  keikenPayFees?: ICompanyKeikenPayActivityCategory[];
  loading: boolean;
}

class CompanyKeikenPayFeeManage extends React.Component<ICompanyKeikenPayFeeManageProps, ICompanyKeikenPayFeeManageState> {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    if (this.props.match.params?.id != null) {
      const id = parseInt(this.props.match.params.id, 10);
      this.props.getCompanyById(id);
      this.props.getCompanyKeikenPayFee(id);
    }
  }

  componentWillReceiveProps(nextProps: Readonly<ICompanyKeikenPayFeeManageProps>, nextContext: any): void {
    if (nextProps.company != null) {
      this.setState({
        company: nextProps.company
      });
      this.props.reset();
    }
    if (nextProps.keikenPayFees != null) {
      this.setState({
        keikenPayFees: _.orderBy(
          nextProps.keikenPayFees,
          ['enabled', 'activityCategory.name'],
          ['desc', 'asc']
        ).map((item: ICompanyKeikenPayActivityCategory, index) => {
          return {
            ...item,
            keikenPayFee: {
              ...item.keikenPayFee,
              type: item.keikenPayFee.type ?? SystemFeeType.FIXED,
              fixed: item.keikenPayFee.fixed ?? 0,
              percentage: item.keikenPayFee.percentage ?? 0
            }
          };
        })
      });
      this.props.reset();
    }

    if (nextProps.updateStatus === HttpRequestStatus.SUCCESS) {
      this.setState({
        loading: false
      });
      toast.success('Salvo com sucesso!', {
        autoClose: 3000,
        onClose: () => {
          this.props.history.push(`/application-admin/companies-manage/${this.props.match.params.id}`);
        }
      });
    } else if (nextProps.updateStatus === HttpRequestStatus.ERROR) {
      this.setState({
        loading: false
      });
      toast.error('Erro ao salvar!');
    }

    if (nextProps.activityCategories != null) {
      // merge activity categories with keiken pay fees
      const keikenPayFees = this.state.keikenPayFees ?? [];

      const activityCategoriesMissing = nextProps.activityCategories.filter(
        category => !keikenPayFees?.some(fee => fee.activityCategory?.id == category.id));

      activityCategoriesMissing.forEach((category, index) => {
        keikenPayFees.push({
          id: keikenPayFees.length + index + 1,
          activityCategory: category,
          enabled: false,
          keikenPayFee: {
            type: SystemFeeType.FIXED,
            fixed: 0,
            percentage: 0
          }
        });
      });
      this.setState({
        keikenPayFees: _.orderBy(keikenPayFees, ['activityCategory.name', 'enabled'], ['asc', 'asc'])
      });
      this.props.resetActivityCategories();
    }
  }

  handleChangeItemValue = (id: number, key: string, value: any) => {
    const keikenPayFees = this.state.keikenPayFees?.map(item => {
      if (item.id == id) {
        return {
          ...item,
          [key]: value
        };
      }
      return item;
    });
    this.setState({
      keikenPayFees
    });
  }

  handleOnConfirm = () => {
    if (this.state.keikenPayFees != null) {
      const companyId = this.props.match.params.id;
      this.setState({
        loading: true
      }, () => {
        this.props.saveCompanyKeikenPayFee(companyId, this.state.keikenPayFees);
      });
    }
  }

  loadActivityCategories = () => {
    this.props.fetchAllActivityCategories({ page: 0, size: 100 }, null, {
      type: 'REGULAR',
      status: 'ENABLED'
    });
  }

  render(): React.ReactNode {
    return (
      <div className={'company-system-fee-main-container'}>
        <h3>
          {this.state.company?.name ?? 'Companhia'}
        </h3>
        <p>
          Taxas do KeikenPay por categoria
        </p>

        <div className="table-fee-container">
          <Table striped bordered hover className="table-fee">
            <thead>
              <tr>
                <th>Ativo</th>
                <th>Categoria</th>
                <th>Tipo</th>
                <th>Taxa fixo</th>
                <th>Taxa porcentagem</th>
                <th />

              </tr>
            </thead>
            <tbody>
              {this.state.keikenPayFees?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className={'edit-activity-step1-checkbox-container'} onClick={() => {
                      this.handleChangeItemValue(item.id, 'enabled', !item.enabled);
                    }}>
                      <input defaultChecked={item.enabled} checked={item.enabled} type={'radio'} />
                      <label className={'edit-activity-step1-checkbox-label'}>
                        {translate('companyDiscountFee.buttons.checkbox.' + item.enabled)}
                      </label>
                    </div>
                    {/* <Label check className="table-fee-label">
                      <Input type="checkbox" id={`checkbox-${index}`} value={item.enabled} onChange={() => {
                        this.handleChangeItemValue(item.id, 'enabled', !item.enabled);
                      }} />{' '}
                      {item.enabled == true ? 'Ligado' : 'Desligado'}
                    </Label> */}
                  </td>
                  <td>{item.activityCategory?.name}</td>
                  <td>
                    <Input type="select" name="select" id={`selecttype-${index}`} value={item.keikenPayFee?.type} onChange={e =>
                      this.handleChangeItemValue(item.id, 'keikenPayFee', {
                        ...item.keikenPayFee,
                        type: e.target.value
                      })
                    }>
                      <option value="FIXED">Fixo</option>
                      <option value="PERCENTAGE">Porcentagem</option>
                      <option value="FIXED_AND_PERCENTAGE">Fixo + Porcentagem</option>
                    </Input>
                  </td>
                  <td>
                    <InputField
                      onChange={e => {
                        this.handleChangeItemValue(item.id, 'keikenPayFee', {
                          ...item.keikenPayFee,
                          fixed: e.target.value
                        });
                      }}
                      inputClassName={''}
                      value={item.keikenPayFee?.fixed ?? 0}
                      type={'number'}
                      placeholder={'companyDiscountFee.placeholders.fixed'}
                    />
                  </td>
                  <td>
                    <InputField
                      onChange={e => {
                        this.handleChangeItemValue(item.id, 'keikenPayFee', {
                          ...item.keikenPayFee,
                          percentage: e.target.value
                        });
                      }}
                      inputClassName={''}
                      value={item.keikenPayFee?.percentage ?? 0}
                      type={'number'}
                      placeholder={'companyDiscountFee.placeholders.percentage'}
                    />
                  </td>
                  <td>
                    <div className="table-action.icon">
                      <RButton className={'centered-content marginB2'} color="danger" onClick={() => {
                        this.setState({
                          keikenPayFees: this.state.keikenPayFees?.filter(i => i.id != item.id)
                        });
                      }}>
                        <FontAwesomeIcon icon={'trash'} />
                      </RButton>

                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}>
          <Button
            width="400px"
            onClick={this.handleOnConfirm} title={translate('companyDiscountFee.buttons.saveKeikenPay')} />
        </div>

        <div style={{
          marginTop: '35px',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <RButton color="info" onClick={this.loadActivityCategories}>
            Carregar categorias ativas
          </RButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  status: storeState.company.getKeikenPayFeeStatus,
  keikenPayFees: storeState.company.keikenPayFees,
  company: storeState.company.company,
  updateStatus: storeState.company.keikenPayFeeStatus,
  activityCategories: storeState.activityCategories.page?.content as IActivityCategory[]
});

const mapDispatchToProps = {
  getCompanyById,
  getCompanyKeikenPayFee,
  saveCompanyKeikenPayFee,
  reset,
  fetchAllActivityCategories,
  resetActivityCategories
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyKeikenPayFeeManage);
