import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { showModal, hideModal } from 'app/components/modal/modal-action';
import { getPaginationItemsNumber, JhiPagination, translate, Translate } from 'react-jhipster';
import './schedule-manage.scss';
import { Row, Button, Col } from 'reactstrap';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { ICustomer } from 'app/shared/model/customer/customer';
import { fetchCustomerList, getCustomerActivities, resetCustomer, resetCustomerActivitiesStatus } from 'app/entities/customer/customer-redux';
import { reset as resetBilling, createCustomerBilling } from 'app/entities/billing/billing.reducer';
import { fetchPartnerPlacesFantasyName } from 'app/entities/partner-place/partner-place-redux';
import { getActivityByPartnerPlaceToCustomer, resetActivitiesStatus } from 'app/entities/activity/activity';
import InputField from 'app/components/input-field/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPartnerPlace } from 'app/shared/model/partner-place.model';
import CustomerActivityComponentList from './customer-activity-list/customer-activity-list';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IActivity } from 'app/shared/model/activity';
import { ICustomerActivity, ICustomerActivityFunctions } from 'app/shared/model/customer-activity.model';
import { getActivitySchedulesForActivity } from 'app/entities/activity-schedule/activity-schedule-redux';
import PartnerActivityScheduleCalendar from './partner-activty-scheduled-calendar/partner-activity-scheduled-calendar';
import { IActivitySchedule } from 'app/shared/model/activity-schedule/activity-schedule';
import { IBillingCreateVM } from 'app/shared/model/billing/billing';
import { toast } from 'react-toastify';
import ActivityScheduleConfirmModal from './activity-schedule-confirm-modal/activity-schedule-confirm-modal';
import _ from 'lodash';
import {
  rescheduleActivitySchedules,
  deleteCustomerActivity,
  reset as resetCustomerActivity
} from 'app/entities/customer-activity/customer-activity-redux';
import { ICoParticipationGroupActivityCategory } from 'app/shared/model/CoParticipation/ICoParticipationGroupActivityCategory';
import { IPriceComposite } from 'app/shared/model/billing/price-composite';
import SimpleTableList from 'app/components/simple-table-list/simple-table-list';
import { faEdit, faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TailSpin } from 'react-loader-spinner';
import StringUtils from 'app/util/StringUtils';
import { IPage, IPageRequest } from 'app/shared/model/page.model';
import { PresetPaymentMethodsToCharge } from 'app/shared/model/preset-payment-methods-to-charge';

enum ScheduleOperationType {
  EDIT = 'edit',
  CREATE = 'create'
}

export interface IScheduleManageProps
  extends StateProps,
  DispatchProps,
  RouteComponentProps<{}, any, { systemFee: any; parterPlaceId: any } | any> { }

export interface IScheduleManageState {
  customer: ICustomer;
  pageRequest: IPageRequest;
  partnerPlace: IPartnerPlace;
  activities: IActivity[];
  customerActivity: ICustomerActivity;
  loading: boolean;
  // To Schedule
  activitySelected: IActivity;
  activityScheduleSelected: IActivitySchedule;
  operation: ScheduleOperationType;
  indexSelected?: number;
}

class ScheduleManage extends React.Component<IScheduleManageProps, IScheduleManageState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageRequest: {
        page: 0,
        size: 5
      },
      customer: undefined,
      partnerPlace: undefined,
      activities: undefined,
      customerActivity: undefined,
      activityScheduleSelected: undefined,
      activitySelected: undefined,
      operation: ScheduleOperationType.CREATE
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const operation = match?.url?.endsWith('/edit') ?
      ScheduleOperationType.EDIT : ScheduleOperationType.CREATE;

    this.setState({ operation });
  }

  componentWillReceiveProps(newProps: IScheduleManageProps) {
    if (newProps.billingStatus == HttpRequestStatus.SUCCESS) {
      toast.success(translate('scheduleManage.billing.success'));
    }
    if (newProps.billingStatus == HttpRequestStatus.SUCCESS || newProps.billingStatus == HttpRequestStatus.ERROR) {
      this.props.resetCustomer();
      this.props.resetBilling();
      this.handleCloseActivityScheduleModal();
      this.searchSchedules();
      this.setState({ loading: false });
    }
    if (newProps.getActivitiesStatus == HttpRequestStatus.SUCCESS) {
      this.setState({
        activities: newProps.activities
      }, () => {
        this.props.resetActivitiesStatus();
        window.scrollTo(0, document.body.scrollHeight);
      });
    }
    if (newProps.customerActivitiesStatus == HttpRequestStatus.SUCCESS) {
      this.props.resetCustomerActivitiesStatus();
    }
    if (newProps.deleteStatus == HttpRequestStatus.SUCCESS) {
      this.props.resetRescheduleStatus();
      this.setState({ indexSelected: undefined });
      if (this.state.customer?.id != null) {
        this.props.getCustomerActivities(this.state.customer.id, this.state.pageRequest);
      }
    } else if (newProps.deleteStatus == HttpRequestStatus.ERROR) {
      this.props.resetRescheduleStatus();
    }

    if (newProps.rescheduleStatus === HttpRequestStatus.SUCCESS) {
      this.props.resetRescheduleStatus();
      toast.success(translate('scheduleManage.reschedule.success'));
      this.handleCloseActivityScheduleModal();
      this.reloadActivities();
      this.setState({ loading: false });
    }

    if (newProps.rescheduleStatus === HttpRequestStatus.ERROR) {
      this.props.resetRescheduleStatus();
      toast.error(translate('scheduleManage.reschedule.error'));
      this.handleCloseActivityScheduleModal();
      this.reloadActivities();
      this.setState({ loading: false });
    }
  }

  private reloadActivities() {
    this.props.resetCustomer();
    this.searchSchedules();
    if (this.state.customer?.id != null) {
      this.props.getCustomerActivities(this.state.customer.id, this.state.pageRequest);
    }
  }
  private searchSchedules = () => {
    if (this.state.customer?.id != null && this.state.partnerPlace?.id != null) {
      this.props.getActivityByPartnerPlaceToCustomer(this.state.partnerPlace.id, this.state.customer.id);
    }
  }

  private handleCustomerSelected = (item: ICustomer) => {
    this.setState({
      customer: item
    }, () => {
      this.props.getCustomerActivities(this.state.customer.id, this.state.pageRequest);
    });
    this.props.hideModal();
  };

  private handlePagination = activePage => {
    const object = this.state.pageRequest;
    object.page = activePage - 1;
    this.setState({ pageRequest: object });
    this.props.getCustomerActivities(this.state.customer.id, object);
  };

  private handleCustomerList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['name'], ['user.email']],
      title: translate('scheduleManage.placeHolder.search-customer'),
      action: this.props.fetchCustomerList,
      waitToPerformRequest: true,
      placeholderInput: translate('scheduleManage.placeHolder.search-customer'),
      stateAction: 'customer',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handleCustomerSelected(item)
    });
  };

  private handlePartnerSelected = (item: IPartnerPlace) => {
    this.setState({
      partnerPlace: item
    }, () => this.searchSchedules());
    this.props.hideModal();
  };

  private handlePartnerList = () => {
    this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
      displayProperty: [['fantasyName']],
      title: translate('scheduleManage.placeHolder.search-partner'),
      action: this.props.fetchPartnerPlacesFantasyName,
      placeholderInput: translate('scheduleManage.placeHolder.search-partner'),
      stateAction: 'partnerPlaces',
      cancelCallback: () => this.props.hideModal(),
      saveCallback: (item: any) => this.handlePartnerSelected(item)
    });
  };

  private handleSelectCustomerActivity = (item: ICustomerActivity) => {
    const activity = item.activitySchedule?.activity;
    this.setState({
      partnerPlace: activity?.partnerPlace,
      customerActivity: item
    }, () => {
      this.searchSchedules();
    });
  }

  private handleSelectEvent = (activity: IActivity, activitySchedule: IActivitySchedule) => {
    this.setState({
      activitySelected: activity,
      activityScheduleSelected: activitySchedule
    });
  }

  private handleCloseActivityScheduleModal = () => {
    this.setState({
      activitySelected: undefined,
      activityScheduleSelected: undefined
    });
  }

  private isEdit = () => this.state.operation == ScheduleOperationType.EDIT;

  private handleConfirmActivityScheduleModal = async (
    categoryId: number,
    subCategoryId: number,
    intervalWeek?: number,
    presetPaymentMethodsToCharge?: PresetPaymentMethodsToCharge,
    priceComposite?: IPriceComposite,
    withoutPayment?: boolean
  ) => {
    const customerId = this.state.customer.id;
    if (categoryId == null || subCategoryId == null) {
      toast.warn('não possui categoria e subcategoria');
      return;
    }

    if (this.state.loading == true) {
      return;
    }

    if (this.isEdit()) {
      const customerActivityId = this.state.customerActivity.id;
      const activityScheduleId = this.state.activityScheduleSelected.id;
      this.props.rescheduleActivitySchedules(customerActivityId, activityScheduleId);
      return;
    }

    const presetRenewalActivitySchedule = intervalWeek != null ? {
      intervalWeek,
      activity: { id: this.state.activitySelected.id },
      activityCategory: { id: categoryId },
      activitySubCategory: { id: subCategoryId }
    } : null;

    const billing: IBillingCreateVM = {
      billing: { activitySchedules: [{ id: this.state.activityScheduleSelected.id, activity: { id: this.state.activitySelected.id } }] },
      activityCategory: { id: categoryId },
      activitySubCategory: { id: subCategoryId },
      presetRenewalActivitySchedule,
      presetPaymentMethodsToCharge,
      priceComposite,
      withoutPayment
    };

    this.setState({
      loading: true
    }, () => {
      this.props.createCustomerBilling(customerId, billing);
    });
  };

  private handleDeleteItem = (item?: ICustomerActivity, index?: number) => {
    this.props.deleteCustomerActivity(item);
    this.setState({ indexSelected: index });
  }
  private handleEditItem = (item?: ICustomerActivity, index?: number) => {
    if (this.state.indexSelected == index) {
      this.setState({
        indexSelected: undefined,
        partnerPlace: undefined,
        customerActivity: undefined,
        activities: []
      });
      return;
    }
    this.handleSelectCustomerActivity(item);
    this.setState({ indexSelected: index });
  }

  private handleTransformToTableContent = (content?: ICustomerActivity[]): Array<Array<string | JSX.Element>> => {
    if (content == null || content.length === 0) {
      return [];
    }

    const result: Array<Array<string | JSX.Element>> = [];
    content.map((item: ICustomerActivity, index: number) => {
      const date = ICustomerActivityFunctions.getScheduleDate(item);
      const weekDay = ICustomerActivityFunctions.getScheduleWeekDay(item);
      const partnerName = ICustomerActivityFunctions.getPartnerName(item);

      const billingValue = StringUtils.currencyPtBr(item?.billing?.value);
      const subsidyValue = StringUtils.currencyPtBr(item?.billing?.billingCoParticipation?.subsidyValue);
      const status = item?.status?.toString?.()?.toLocaleLowerCase?.() ?? '';
      result.push([
        partnerName,
        weekDay,
        date,
        status,
        item?.billing?.paymentMethod ?? '',
        item?.billing?.paymentProviderId ?? '',
        billingValue,
        subsidyValue,
        this.renderActionColumn(item, index)
      ]);
    });
    return result;
  };

  private renderActionColumn = (item?: ICustomerActivity, index?: number): JSX.Element => {
    return (
      <div>
        <Button className={'centered-content marginB2'} onClick={this.handleEditItem.bind(this, item, index)}>
          <FontAwesomeIcon icon={faEdit as IconProp} className={'marginR1'} />
          <Translate contentKey={'scheduleManage.buttons.edit'} />
        </Button>
        <Button className={'centered-content marginB2'} onClick={this.handleDeleteItem.bind(this, item, index)}>
          <FontAwesomeIcon icon={faTrashAlt as IconProp} className={'marginR1'} />
          <Translate contentKey={'scheduleManage.buttons.delete'} />
        </Button>
      </div>
    );
  };

  render() {
    const customerSelected = this.state.customer ? `${this.state.customer?.name} - ${this.state.customer?.user?.email}` : '';
    const isEdit = this.isEdit();
    const i18nTitle = isEdit ? 'edit-title' : 'title';
    const tableContent: Array<Array<string | JSX.Element>> = this.handleTransformToTableContent(this.props.customerActivities?.content);

    return (
      <div className={'schedule-manage-container'}>
        <div className={'schedule-manage-title'}>
          <label>{translate('scheduleManage.label.' + i18nTitle)}</label>
        </div>

        <div className={'schedule-manage-input-divisor'}>
          <div className={'schedule-manage-inner-addon'} onClick={this.handleCustomerList}>
            <InputField
              title={translate('scheduleManage.placeHolder.search-customer')}
              inputClassName={'schedule-manage-search-input'}
              value={customerSelected || ''}
              placeholder={'scheduleManage.placeHolder.search-customer'}
              readOnly
              onClick={this.handleCustomerList}
            />

            <div className={'schedule-manage-inner-icon'}>
              <FontAwesomeIcon className={'schedule-manage-inner-icon'} icon={'search'} />
            </div>
          </div>
        </div>

        <SimpleTableList
          rows={tableContent}
          columNameKeys={translate('scheduleManage.tableHeaders')}
          emptyTableMessage={''}
          indexSelected={this.state.indexSelected}
        />

        {tableContent.length > 0 && (
          <Row className={'justify-content-center'}>
            <JhiPagination
              items={
                this.props.customerActivities ? getPaginationItemsNumber(this.props.customerActivities.totalElements, this.props.customerActivities.size) : 0
              }
              activePage={this.props.customerActivities ? this.props.customerActivities.number : 0}
              onSelect={this.handlePagination}
              maxButtons={5}
            />
          </Row>
        )}

        {/* <CustomerActivityComponentList
          customerActivities={this.props.customerActivities}
          onSelect={this.handleSelectCustomerActivity}
          isEdit={isEdit}
        /> */}

        <div className={'schedule-manage-input-divisor'}>
          <div className={'schedule-manage-inner-addon'} onClick={this.handlePartnerList}>
            <InputField
              title={translate('scheduleManage.placeHolder.search-partner')}
              inputClassName={'schedule-manage-search-input'}
              value={this.state.partnerPlace?.fantasyName || ''}
              placeholder={'scheduleManage.placeHolder.search-partner'}
              readOnly
              onClick={this.handlePartnerList}
            />

            <div className={'schedule-manage-inner-icon'}>
              <FontAwesomeIcon className={'schedule-manage-inner-icon'} icon={'search'} />
            </div>
          </div>
        </div>

        {this.state.partnerPlace != null && (this.props.getActivitiesStatus == HttpRequestStatus.ONGOING ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 15 }}>
              <Translate contentKey={'scheduleManage.loading'} />
            </div>
            <TailSpin color="black" height={30} width={30} />
          </div>
        ) : (_.isEmpty(this.state.activities) ? (
          translate('scheduleManage.notFound')
        ) : (
          <PartnerActivityScheduleCalendar
            activities={this.state.activities}
            onSelectEvent={this.handleSelectEvent}
          />
        )))}

        <ActivityScheduleConfirmModal
          isEdit={isEdit}
          customer={this.state.customer}
          activity={this.state.activitySelected}
          activitySchedule={this.state.activityScheduleSelected}
          customerActivity={this.state.customerActivity}
          isOpen={this.state.activityScheduleSelected != null}
          onClose={this.handleCloseActivityScheduleModal}
          onConfirm={this.handleConfirmActivityScheduleModal}
        />

        <div style={{ marginBottom: '50px' }} />
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  customerActivities: storeState.customer?.customerActivities,
  customerActivitiesStatus: storeState.customer?.customerActivitiesStatus,
  activities: storeState.activities?.page?.content,
  getActivitiesStatus: storeState.activities?.getActivitiesStatus,
  getActivitiesSchedulesStatus: storeState.activitySchedule.getActivitiesSchedulesStatus,
  activitySchedules: storeState.activitySchedule.activitySchedules,
  rescheduleStatus: storeState.customerActivity.changeScheduleStatus,
  deleteStatus: storeState.customerActivity.deleteStatus,
  billingStatus: storeState.billing.statusAdminCustomerBilling
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  fetchCustomerList,
  fetchPartnerPlacesFantasyName,
  getCustomerActivities,
  getActivityByPartnerPlaceToCustomer,
  resetActivitiesStatus,
  resetCustomerActivitiesStatus,
  getActivitySchedulesForActivity,
  rescheduleActivitySchedules,
  resetRescheduleStatus: resetCustomerActivity,
  resetCustomer,
  createCustomerBilling,
  resetBilling,
  deleteCustomerActivity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleManage);
